import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { routes } from '../common/routes'
import * as Pages from './pages'

export const ReconnectApp = () => {
  return (
    <Routes>
      <Route index element={<Pages.Reconnect />} />
      <Route path={routes.reconnect.children.workflowPrompt} element={<Pages.Reconnect />} />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}
