// eslint-disable-next-line no-restricted-imports
import {
  Box,
  Flex,
  FocusTrap,
  Drawer as MantineDrawer,
  DrawerProps as MantineDrawerProps,
  ModalBaseStylesNames,
  Styles,
  useMantineTheme,
} from '@mantine/core'
import { useViewportSize } from '@mantine/hooks'
import React, { PropsWithChildren, ReactNode, forwardRef } from 'react'
import { Divider } from './Divider'
import { ScrollArea } from './ScrollArea'
import { TertiaryButton } from './buttons'
import { ArrowLeftCircleIcon, XIcon } from './icons'
import { TitleThree } from './typography'

type AllowedSizes = 'sm' | 'lg' | 'xl' | '80%'
type AllowedHeights = '50vh' | '70vh'

export type DrawerProps = Omit<
  MantineDrawerProps,
  'padding' | 'styles' | 'sx' | 'withCloseButton' | 'title' | 'size'
> & {
  title: ReactNode
  footer?: JSX.Element
  onBack?: () => void
  size: AllowedSizes
  bottomHeight?: AllowedHeights
}

export type BetterDrawerProps = Omit<
  MantineDrawerProps,
  'padding' | 'styles' | 'sx' | 'withCloseButton' | 'title' | 'size' | 'trapFocus'
> & {
  size: AllowedSizes
  bottomHeight?: AllowedHeights
  float?: boolean
}

const sizes: Record<AllowedSizes, string> = {
  sm: '24rem',
  lg: '32rem',
  xl: '42rem',
  '80%': '80%',
}

export const Drawer = React.forwardRef<HTMLDivElement, DrawerProps>(
  ({ children, footer, title, onClose, onBack, trapFocus = true, ...rest }, scrollableRef) => {
    return (
      <BetterDrawer onClose={onClose} {...rest}>
        <DrawerHeader position={rest.position} onClose={onClose} onBack={onBack}>
          {title}
        </DrawerHeader>
        <DrawerContent position={rest.position} trapFocus={trapFocus} ref={scrollableRef}>
          {children}
        </DrawerContent>
        <DrawerFooter>{footer}</DrawerFooter>
      </BetterDrawer>
    )
  },
)

Drawer.displayName = 'Drawer'

export const BetterDrawer = ({
  children,
  position = 'right',
  size = 'lg',
  bottomHeight = '70vh',
  ...props
}: BetterDrawerProps) => {
  const { height: fullScreenHeight } = useViewportSize()
  const theme = useMantineTheme()

  const drawerHeight = position === 'bottom' ? bottomHeight : fullScreenHeight

  const styles: Styles<ModalBaseStylesNames> = {
    overlay: { backgroundColor: `${theme.other.colors.background[6]}B3` },
    body: { backgroundColor: theme.other.colors.background[0] },
  }

  if (position === 'bottom') {
    styles.root = {
      '.mantine-Drawer-content': {
        borderRadius: `32px 32px 0 0`,
        height: drawerHeight,
      },
    }
  }

  const getTransition = () => {
    switch (position) {
      case 'bottom':
        return 'slide-up'
      case 'left':
        return 'slide-right'
      case 'right':
        return 'slide-left'
      default:
        return 'pop'
    }
  }

  return (
    <MantineDrawer
      padding={0}
      transitionProps={{ transition: getTransition(), duration: 500, timingFunction: 'ease' }}
      withCloseButton={false}
      trapFocus={false}
      size={sizes[size]}
      styles={styles}
      position={position}
      h={props.opened ? drawerHeight : undefined}
      {...props}
      onClose={props.opened ? props.onClose : () => {}}
    >
      <Flex direction='column' h={drawerHeight}>
        {children}
      </Flex>
    </MantineDrawer>
  )
}

BetterDrawer.displayName = 'BetterDrawer'

export const DrawerHeader = ({
  onBack,
  onClose,
  position,
  children,
}: PropsWithChildren<{
  onBack?: (() => void) | undefined
  onClose: () => void
  position?: BetterDrawerProps['position']
}>) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  return (
    <>
      <Flex
        justify='space-between'
        align='flex-start'
        p={position === 'bottom' ? sizes.padding.xl : sizes.gap.xl}
      >
        <Flex align='flex-start' gap={sizes.gap.xl}>
          {onBack && (
            <TertiaryButton size='sm' onClick={onBack} leftIcon={<ArrowLeftCircleIcon styled />} />
          )}
          <TitleThree color={colors.text[0]}>{children}</TitleThree>
        </Flex>
        <TertiaryButton size='sm' onClick={onClose} leftIcon={<XIcon color={colors.text[0]} />} />
      </Flex>
      <Divider />
    </>
  )
}

export const DrawerContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ trapFocus?: boolean; position?: BetterDrawerProps['position'] }>
>(({ children, position, trapFocus = true }, scrollableRef) => {
  const {
    other: { sizes },
  } = useMantineTheme()

  return (
    <ScrollArea
      styles={{ root: { flex: 1, padding: position === 'bottom' ? sizes.padding.sm : 'initial' } }}
      ref={scrollableRef}
    >
      <FocusTrap active={trapFocus}>{children}</FocusTrap>
    </ScrollArea>
  )
})

DrawerContent.displayName = 'DrawerContent'

export const DrawerFooter = ({ children }: PropsWithChildren) => {
  return (
    <Box
      sx={({ other: { sizes, colors } }) => ({
        padding: sizes.gap.xl,
        backgroundColor: colors.background[1],
      })}
    >
      {children}
    </Box>
  )
}

export default Drawer
