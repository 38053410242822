import { FormFieldValue, FormValue } from '../form'
import { ReferralOption } from '../referrals'
import { WorkflowType } from '../workflows'
import { PatientFormTaskType, PatientTaskType } from './patientTasks'

export const TASK_WORKFLOW_DICTIONARY: Partial<Record<PatientTaskType, WorkflowType>> = {
  'medical-intake-form': 'medical_intake_form',
  'phq-9-form': 'phq_9_form',
  'phq-8-form': 'phq_8_form',
  'gad-7-form': 'gad_7_form',
  'barc-10-form': 'barc_10_form',
  'asq-form': 'asq_form',
}

export type TaskFormStepType<T extends PatientTaskType = PatientTaskType> = T extends 'profile'
  ? 'personal' | 'address'
  : T extends 'insurance'
  ? 'insurance' | 'card'
  : T extends 'billing'
  ? 'stripe-payment' | 'agreement'
  : T extends 'zoom'
  ? 'test-zoom' | 'is-zoom-working'
  : ''

export namespace TaskPayload {
  export type Profile = {
    legalFirstName: FormFieldValue<'string'>
    legalLastName: FormFieldValue<'string'>
    preferredName: FormFieldValue<'string'>
    birthday: FormFieldValue<'date'>
    pronouns: FormFieldValue<'select'>
    sex: FormFieldValue<'select'>
    homeAddress: FormFieldValue<'address'>
    shippingAddress: FormFieldValue<'address'>
    useHomeAsShipping: FormFieldValue<'checkbox'>
    timezone: FormFieldValue<'select'>
  }

  export type EmergencyContact = {
    firstName?: FormFieldValue<'string'>
    lastName?: FormFieldValue<'string'>
    fullName?: FormFieldValue<'string'>
    relationship: FormFieldValue<'string'>
    phoneNumber: FormFieldValue<'phone-number'>
    reasoning: FormFieldValue<'checkbox-group'>
  }

  export type Billing = {
    cardNumber: FormFieldValue<'string'>
    expirationMonth: FormFieldValue<'string'>
    expirationYear: FormFieldValue<'string'>
    cvvCode: FormFieldValue<'string'>
    firstName: FormFieldValue<'string'>
    lastName: FormFieldValue<'string'>
    billingAddress: FormFieldValue<'address'>
    signature: FormFieldValue<'string'>
    paymentMethodId: FormFieldValue<'string'>
  }

  export type FinancialAgreement = {
    signature: FormFieldValue<'string'>
  }

  export type UploadId = {
    idFront: FormFieldValue<'photo'>
    idBack: FormFieldValue<'photo'>
  }

  export type Zoom = {
    zoomLastTested: FormFieldValue<'string'>
    isZoomWorking: FormFieldValue<'string'>
  }

  export type Insurance = {
    insurancePlan: FormFieldValue<'insurance-plan'>
    cardholderFirstName: FormFieldValue<'string'>
    cardholderLastName: FormFieldValue<'string'>
    cardholderBirthday: FormFieldValue<'date'>
    isPrimarySubscriber: FormFieldValue<'checkbox'>
    insuranceMemberId: FormFieldValue<'string'>
    insuranceGroupId: FormFieldValue<'string'>
    insuranceRxBin: FormFieldValue<'string'>
    frontInsuranceCard: FormFieldValue<'photo'>
    backInsuranceCard: FormFieldValue<'photo'>
    primarySubscriberFirstName: FormFieldValue<'string'>
    primarySubscriberLastName: FormFieldValue<'string'>
    primarySubscriberBirthday: FormFieldValue<'date'>
    secondaryInsurancePlan: FormFieldValue<'insurance-plan'>
    secondaryCardholderFirstName: FormFieldValue<'string'>
    secondaryCardholderLastName: FormFieldValue<'string'>
    secondaryCardholderBirthday: FormFieldValue<'date'>
    secondaryIsPrimarySubscriber: FormFieldValue<'checkbox'>
    secondaryInsuranceMemberId: FormFieldValue<'string'>
    secondaryInsuranceGroupId: FormFieldValue<'string'>
    secondaryInsuranceRxBin: FormFieldValue<'string'>
    secondaryFrontInsuranceCard: FormFieldValue<'photo'>
    secondaryBackInsuranceCard: FormFieldValue<'photo'>
    secondaryPrimarySubscriberFirstName: FormFieldValue<'string'>
    secondaryPrimarySubscriberLastName: FormFieldValue<'string'>
    secondaryPrimarySubscriberBirthday: FormFieldValue<'date'>
  }

  export type HowDidYouHearAboutUs = {
    howTheyHeardAboutUs: FormFieldValue<'select', ReferralOption>
    nameOfHealthcareProvider: FormFieldValue<'string'>
    nameOfOrganization: FormFieldValue<'string'>
    whichConference: FormFieldValue<'string'>
    shareHelpfulInfo: FormFieldValue<'string'>
  }

  export type PreferredPharmacy = {
    name: FormFieldValue<'string'>
    address: FormFieldValue<'string'>
    city: FormFieldValue<'string'>
    state: FormFieldValue<'string'>
    zip: FormFieldValue<'string'>
    phone: FormFieldValue<'string'>
  }

  export type IntakeVisitStandbyList = {
    optIn: FormFieldValue<'select'>
    preference: FormFieldValue<'select'>
    availabilities: FormFieldValue<'availabilities'>
  }

  export type TreatmentConsent = {
    signature: FormFieldValue<'string'>
    checks: FormFieldValue<'checkbox-group'>
  }

  export type TreatmentConsentAndFinancialAgreement = {
    signature: FormFieldValue<'string'>
    checks: FormFieldValue<'checkbox-group'>
  }

  export type TpoConsent = {
    signature: FormFieldValue<'string'>
    checks: FormFieldValue<'checkbox-group'>
  }

  export namespace IntakeVisitStandbyList {
    export enum Preference {
      Set = 'set_preferences',
      Unset = 'no_preferences',
    }

    export enum OptIn {
      Yes = 'yes',
      No = 'no',
    }
  }

  export type ThankYouNote = {
    name: FormFieldValue<'string'>
    message: FormFieldValue<'string'>
  }
}

export type TaskField<T extends PatientFormTaskType = PatientFormTaskType> = Extract<
  keyof TaskPayload<T>,
  string
>

export type TaskPayload<T extends PatientFormTaskType = PatientFormTaskType> = T extends 'profile'
  ? TaskPayload.Profile
  : T extends 'billing'
  ? TaskPayload.Billing
  : T extends 'financial-agreement'
  ? TaskPayload.FinancialAgreement
  : T extends 'insurance'
  ? TaskPayload.Insurance
  : T extends 'emergency-contact'
  ? TaskPayload.EmergencyContact
  : T extends 'how-did-you-hear-about-us'
  ? TaskPayload.HowDidYouHearAboutUs
  : T extends 'upload-id'
  ? TaskPayload.UploadId
  : T extends 'zoom'
  ? TaskPayload.Zoom
  : T extends 'preferred-pharmacy'
  ? TaskPayload.PreferredPharmacy
  : T extends 'intake-visit-standby-list'
  ? TaskPayload.IntakeVisitStandbyList
  : T extends 'treatment-consent'
  ? TaskPayload.TreatmentConsent
  : T extends 'treatment-consent-and-financial-agreement'
  ? TaskPayload.TreatmentConsentAndFinancialAgreement
  : T extends 'thank-you-note'
  ? TaskPayload.ThankYouNote
  : T extends 'tpo-consent'
  ? TaskPayload.TpoConsent
  : FormValue
