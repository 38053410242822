import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { routes } from '../common/routes'
import * as Pages from './pages'

export const WellnessCheckApp = () => {
  return (
    <Routes>
      <Route index element={<Pages.Workflows />} />
      {/* The link patients click on has the session ID in it - so matches this route first. */}
      <Route path={routes.wellnessCheck.children.workflowPrompt} element={<Pages.Workflows />} />
      {/* After mounting with the session ID, the WorkFlowPrompt component redirects to this path with the prompt ID. */}
      <Route path={routes.wellnessCheck.children.workflowSession} element={<Pages.Workflows />} />
      <Route path={routes.wellnessCheck.children.start} element={<Pages.Start />} />
      <Route path={routes.wellnessCheck.children.completed} element={<Pages.Completed />} />
      <Route path={routes.wellnessCheck.children.expired} element={<Pages.Expired />} />
      <Route path={routes.wellnessCheck.children.asqMessage} element={<Pages.AsqMessage />} />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}
