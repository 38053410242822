import { useForm } from '@mantine/form'
import { Pill, PrimaryButton, Radio, RadioGroup, Stack, Text, TitleTwo } from '@shared/components'
import { AcuityAppointment, OptIn } from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import React from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { currentUserApi } from '../../../common/api'
import { useAuth, usePortalDims } from '../../../common/hooks'
import { routes } from '../../../common/routes'

export const RescheduledIntakeVisitConfirmation = (props: {
  rescheduledVisit: AcuityAppointment
}) => {
  const authContext = useAuth()
  const { isMobile } = usePortalDims()
  const navigate = useNavigate()

  const visitDate = dayjs(props.rescheduledVisit.datetime)

  const clinicianName =
    props.rescheduledVisit?.calendar.split(',')[0] ?? props.rescheduledVisit.calendar

  const isWithin24Hours = visitDate.isBetween(dayjs(), dayjs().add(toTime('1 day').hour(), 'hour'))

  const { getInputProps, values } = useForm({
    initialValues: {
      optIn: authContext.currentUser?.data?.account.settings?.notifications?.standbyList
        ?.initialVisit?.optIn
        ? OptIn.Yes
        : OptIn.No,
      availabilities:
        authContext.currentUser?.data?.account.settings?.notifications?.standbyList?.initialVisit
          ?.availabilities,
    },
  })

  const updateCurrentUser = useMutation(currentUserApi.update)

  function onConfirm() {
    if (authContext.currentUser) {
      updateCurrentUser.mutate(
        {
          account: {
            settings: {
              notifications: {
                standbyList: {
                  initialVisit: {
                    optIn: values.optIn === OptIn.Yes,
                  },
                },
              },
            },
          },
        },
        {
          onSuccess: async () => {
            await authContext.currentUser?.refetch()
            navigate(routes.portal.index)
          },
        },
      )
    }
  }

  // the visit is confirmed (if visit is <24 hours from now)
  if (isWithin24Hours) {
    return (
      <Stack>
        <Pill status='success'>Confirmed</Pill>
        <Stack spacing='xs'>
          <TitleTwo>
            {`Your intake visit for ${visitDate.format('MMM D YYYY')} at ${visitDate.format(
              'h:mma z',
            )} with ${clinicianName} is confirmed`}
          </TitleTwo>
          <Text>
            If you don&apos;t attend, or if you cancel less than 12 hours before your visit, you may
            be charged $20.
          </Text>
        </Stack>
      </Stack>
    )
  }

  // the visit is scheduled (if visit is >24 hours from now)
  return (
    <Stack spacing='lg'>
      <TitleTwo>
        {`Your intake visit is scheduled for ${visitDate.format(
          'MMM D YYYY',
        )} at ${visitDate.format('h:mma z')} with ${clinicianName}`}
      </TitleTwo>
      <Stack>
        <Text>Should continue to notify you if something even sooner opens up?</Text>
        <RadioGroup {...getInputProps('optIn')}>
          <Radio value={OptIn.Yes} label='Yes' />
          <Radio value={OptIn.No} label='No, thanks' />
        </RadioGroup>
        <PrimaryButton
          onClick={onConfirm}
          fullWidth={isMobile}
          loading={updateCurrentUser.isLoading}
        >
          Confirm
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}
