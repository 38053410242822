// eslint-disable-next-line no-restricted-imports
import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
  useMantineTheme,
} from '@mantine/core'
import { PolymorphicComponentProps } from '@mantine/utils'
import React, { PropsWithRef } from 'react'
import { getAttributes, getOutlineFocused, getOutlinePressed } from '../inputs'
import { ButtonChildren, ButtonProps } from './buttons.helpers'

type PrimaryButtonProps<T extends ButtonChildren = string, C = 'div'> = PolymorphicComponentProps<
  C,
  ButtonProps<T> & {
    size?: 'xs' | 'sm' | 'md' | 'lg'
  }
>

function PrimaryButtonInner<T extends ButtonChildren, C = 'div'>(
  { size = 'md', fullWidth, children, disabled, ...props }: PrimaryButtonProps<T, C>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const {
    other: { colors, fontFamilies, sizes },
    spacing,
    fontSizes,
  } = useMantineTheme()

  const fontColor = colors.actionText
  const height = sizes.button[size]
  const px = size === 'md' ? spacing.md : spacing.sm

  return (
    <MantineButton
      ref={ref}
      variant='filled'
      px={px}
      h={height}
      miw={height}
      w={fullWidth ? '100%' : 'fit-content'}
      bg={colors.actions[0]}
      fz={fontSizes[size]}
      ff={fontFamilies.bold}
      lh='normal'
      disabled={Boolean(disabled || props.loading)}
      {...(props as MantineButtonProps)}
      {...getAttributes({ ['data-icon']: !children })}
      loaderProps={{ color: fontColor, size: sizes.icon.md }}
      styles={{
        rightIcon: { margin: 'unset' },
        leftIcon: { margin: 'unset' },
        inner: { gap: spacing.sm },
        label: { display: children ? 'flex' : 'none' },
        root: {
          color: fontColor,
          border: 'none',
          ':focus': { outline: 'none', boxShadow: getOutlineFocused({ colors, sizes }) },
          ':before': { display: 'none' },
          ':hover': { backgroundColor: colors.actions[1] },
          ':active': { transform: 'unset', boxShadow: getOutlinePressed({ colors, sizes }) },
          ':disabled': { color: fontColor, backgroundColor: colors.actions[3] },
          '&[data-icon]': {
            padding: 'unset',
            '.mantine-Button-leftIcon': {
              margin: 'unset',
            },
          },
        },
      }}
    >
      {children}
    </MantineButton>
  )
}

// Type assertion to allow passing a generic into forwardRef
export const PrimaryButton = React.forwardRef<HTMLButtonElement, PrimaryButtonProps>(
  PrimaryButtonInner,
) as <T extends ButtonChildren, C = 'div'>(
  props: PropsWithRef<PrimaryButtonProps<T, C>>,
) => ReturnType<typeof PrimaryButtonInner>
