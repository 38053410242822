import { Box } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { TaskHeader } from './TaskHeader'
import { ThankYouNoteForm } from './ThankYouNoteForm'
import { usePatientFormTask } from './use-patient-task'

export const ThankYouNoteTask = () => {
  const { payload, status, isSaving, submit } = usePatientFormTask({
    type: 'thank-you-note',
    breadcrumb: 'Thank you note',
    stateOnComplete: { showAnimation: 'thank-you-note-sent' },
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <Box test-id='page:thank-you-note'>
      <TaskHeader title='Send a thank you note to the person who referred you' />
      <ThankYouNoteForm initialValues={payload} saving={isSaving} submit={submit} />
    </Box>
  )
}
