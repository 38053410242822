import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Group,
  PrimaryButton,
  RadioGroup,
  ResponsiveBox,
  RoundRadio,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import {
  PromptContentLikertScale as PromptContentLikertScaleType,
  PromptResponseContextKey,
  PromptResponseModel,
} from '@shared/types'
import React from 'react'
import { isRequired } from '../../forms'

type LikertConfig = {
  level: number
  leftText: string
  rightText: string
}

type Props = {
  value: PromptResponseContextKey | undefined
  config: LikertConfig
  options: PromptContentLikertScaleType['options']
  onSubmit: (data: PromptResponseModel['payload']) => void
  onBack: (() => void) | undefined
}

type Form = {
  contextKey: PromptResponseContextKey | undefined
}

export const PromptContentLikertScale = (props: Props) => {
  const form = useForm<Form>({
    initialValues: { contextKey: props.value },
    validate: { contextKey: isRequired },
  })

  const onSubmit = () => {
    const { hasErrors } = form.validate()
    if (hasErrors) {
      return
    }
    if (!form.values.contextKey) {
      return
    }

    props.onSubmit({ value: { contextKey: form.values.contextKey } })
  }
  return (
    <Box test-id='content:likert'>
      <Stack sx={{ maxWidth: '300px' }}>
        <RadioGroup orientation='horizontal' {...form.getInputProps('contextKey')}>
          {props.options.map(option => (
            <RoundRadio key={option.contextKey} value={option.contextKey}>
              {option.text}
            </RoundRadio>
          ))}
        </RadioGroup>
        <Group position='apart'>
          <Text size='xs'>{props.config.leftText}</Text>
          <Text size='xs'>{props.config.rightText}</Text>
        </Group>
      </Stack>
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={onSubmit}>
              Next
            </PrimaryButton>
            {props.onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                style={{ margin: 'auto' }}
                onClick={props.onBack}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          props.onBack ? (
            <Group position='apart'>
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={props.onBack}
              >
                Back
              </TertiaryButton>
              <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
                Next
              </PrimaryButton>
            </Group>
          ) : (
            <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
              Next
            </PrimaryButton>
          )
        }
      />
    </Box>
  )
}
