import { ArrowLeftIcon, Group, ResponsiveBox, Stack, TertiaryButton } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../routes'

export const NotReadyConfirmation = () => {
  const navigate = useNavigate()
  const onBack = () => navigate(`${routes.welcome.index}`)

  return (
    <ResponsiveBox
      mt='lg'
      mobile={
        <Stack spacing='lg' align='center'>
          <TertiaryButton
            test-id='button:back@mobile'
            leftIcon={<ArrowLeftIcon />}
            onClick={onBack}
          >
            Back
          </TertiaryButton>
        </Stack>
      }
      desktop={
        <Group position='apart'>
          <TertiaryButton
            test-id='button:back@desktop'
            leftIcon={<ArrowLeftIcon />}
            onClick={onBack}
          >
            Back
          </TertiaryButton>
        </Group>
      }
    />
  )
}
