import { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { BreadcrumbsSettings, PortalBreadcrumbsContext } from '../components'

export function usePortalBreadcrumbs(breadcrumbs: BreadcrumbsSettings) {
  const { setBreadcrumbs } = useContext(PortalBreadcrumbsContext)

  const location = useLocation()

  useEffect(() => {
    setBreadcrumbs(breadcrumbs)
    // Clean up all state when page changes
    return () => {
      setBreadcrumbs(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
}
