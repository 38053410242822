/* eslint-disable no-magic-numbers */
// eslint-disable-next-line no-restricted-imports
import { MantineSizes, MantineThemeOther, MantineThemeOverride } from '@mantine/core'
import { Colors, Fonts, baseTheme, createHomogenousColorList } from './base'

export const DAYBREAK_COLORS: Colors = {
  success: ['#00B0AD', '#CCEFEF'],
  warning: ['#FFB656', '#FFF0DD'],
  error: ['#DF0101', '#F9CCCC'],
  actions: ['#A0ADD8', '#7B88B7', '#808194', '#B3B1BD'],
  actionText: '#122048',
  text: ['#122048', '#49516E', '#E0E0E5', '#FFFFFF'],
  background: [
    '#FFFFFF',
    '#FBFAFB',
    '#F6F4F6',
    '#E5E1E6',
    '#C0C0CA',
    '#808194',
    '#49516E',
    '#122048',
  ],
}

// These are imported in the <Global> component.
export const DAYBREAK_FONT_FAMILIES: Fonts = {
  regular: 'Helvetica Now Text Regular',
  bold: 'Helvetica Now Text Bold',
  pro: 'Helvetica Neue LT Pro',
}

export const DAYBREAK_FONT_FILE_NAMES: Fonts = {
  regular: 'HelveticaNowTextRegular.woff2',
  bold: 'HelveticaNowTextBold.woff2',
  pro: 'HelveticaNeueLTPro.woff2',
}

export const DAYBREAK_FONT_SIZES: MantineSizes = {
  xs: '0.75rem',
  sm: '0.75rem',
  md: '0.875rem',
  lg: '1rem',
  xl: '1rem',
}

export const DAYBREAK_SIZES: MantineThemeOther['sizes'] = {
  iconbutton: { xs: '1rem', sm: '1rem', md: '1.5rem', lg: '2rem' },
  button: { xs: '1.625rem', sm: '1.625rem', md: '2.313rem', lg: '3.5rem' },
  input: { md: '2.25rem' },
  padding: { xs: '0.125rem', sm: '0.25rem', md: '0.5rem', lg: '0.75rem', xl: '1rem' },
  gap: { sm: '0.25rem', md: '0.5rem', lg: '0.75rem', xl: '1rem' },
  icon: { sm: '1rem', md: '1rem', lg: '1.5rem', xl: '2rem' },
  border: { md: '0.094rem', lg: '0.125rem' },
  outline: { sm: '0.094rem', md: '0.125rem', lg: '0.188rem' },
}

export const DAYBREAK_HEADING_SIZES = {
  h1: {
    fontSize: '2rem',
  },
  h2: {
    fontSize: '1.5rem',
  },
  h3: {
    fontSize: '1.25rem',
  },
  h4: {
    fontSize: '0.875rem',
  },
} as const

export const DAYBREAK_THEME: MantineThemeOverride = {
  ...baseTheme,
  colorScheme: 'light',
  fontFamily: `${DAYBREAK_FONT_FAMILIES.regular}, Helvetica, Arial, sans-serif`,
  colors: {
    // Dark is the default dark, with the exception of the first two.
    dark: [
      '#FFFFFF',
      '#F6F4F6',
      '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5c5f66',
      '#373A40',
      '#2C2E33',
      '#25262b',
      '#1A1B1E',
    ],
    primary: createHomogenousColorList(DAYBREAK_COLORS.actions[0]),
    // NOTE: this overrides all the RED colors
    red: createHomogenousColorList(DAYBREAK_COLORS.error[0]),
    // NOTE: this overrides all the GREEN colors
    green: createHomogenousColorList(DAYBREAK_COLORS.success[0]),
    // NOTE: this overrides all the YELLOW colors
    yellow: createHomogenousColorList(DAYBREAK_COLORS.warning[0]),
  },
  headings: {
    fontFamily: DAYBREAK_FONT_FAMILIES.pro,
    sizes: DAYBREAK_HEADING_SIZES,
  },
  fontSizes: DAYBREAK_FONT_SIZES,
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
    xl: '4rem',
  },
  other: {
    colors: DAYBREAK_COLORS,
    fontFamilies: DAYBREAK_FONT_FAMILIES,
    themeName: 'daybreak',
    sizes: DAYBREAK_SIZES,
  },
}
