import { useClipboard } from '@mantine/hooks'
import { Patient, PatientApi, Referral, ReferralSource } from '@shared/types'
import { toTime } from '@shared/utils'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { logger } from '..//logger'
import { patientApi, referralsApi } from '../api'
import * as FullStory from '../fullstory'
import { analytics, sendTrackEvent } from '../rudderstack'

export type UseFriendReferralArgs = {
  source: ReferralSource
  referrerPatientId?: Patient['oid']
}

export const useFriendReferral = ({ source, referrerPatientId }: UseFriendReferralArgs) => {
  const clipboard = useClipboard({ timeout: toTime('5 sec').ms() })

  const [referralData, setReferralData] = useState<{
    referralLink: string
    referralId: string
  } | null>()

  const { sessionId } = useParams()
  const [shareLoading, setShareLoading] = useState(false)
  const [copyLoading, setCopyLoading] = useState(false)

  const createReferralForSessionMutation = useMutation(
    () => referralsApi.createReferralForSessionIfNotExists({ sessionId: sessionId as string }),
    { onSuccess: data => setReferralData(data) },
  )

  const createReferralFriendOrFamilyReferralMutation = useMutation(
    patientApi.getMutation('POST /referral/friend-or-family'),
    { onSuccess: data => setReferralData(data) },
  )

  const createReferral = (personalizedReferral?: Referral['personalizedReferral']) => {
    if (referralData) {
      return referralData
    }

    if (sessionId) {
      return createReferralForSessionMutation.mutateAsync()
    }

    const data: PatientApi['POST /referral/friend-or-family']['req']['data'] = {
      referrerPatientId,
      anonymousId: analytics.getAnonymousId(),
      source,
    }

    if (personalizedReferral) {
      data.personalizedReferral = personalizedReferral
    }

    return createReferralFriendOrFamilyReferralMutation.mutateAsync({
      data,
    })
  }

  const sendEvents = (referralId: string) => {
    const data: analytics.apiObject = {}
    data.referralId = referralId
    sendTrackEvent('Friend Referral Link Copied', data)
    FullStory.event('Friend Referral Link Copied')
  }

  const handleShare = async (personalizedReferral?: Referral['personalizedReferral']) => {
    setShareLoading(true)
    const { referralId, referralLink } = await createReferral(personalizedReferral)
    setShareLoading(false)

    const shareTextContent = [
      `I found a great resource that makes getting help easier than you'd imagine — and I wanted to share it with you. `,
      personalizedReferral ? `Click the link to read the message I left you. ` : '',
      referralLink,
    ].join('')

    try {
      void navigator.share({
        text: shareTextContent,
      })
    } catch (error) {
      logger.error(`Error sharing referral link: ${error}`)
    }

    sendEvents(referralId)
  }

  const handleCopy = async (personalizedReferral?: Referral['personalizedReferral']) => {
    setCopyLoading(true)
    const { referralId, referralLink } = await createReferral(personalizedReferral)
    setCopyLoading(false)

    /*
     * iOS Safari does not allow for clipboard writes as a result of an asynchronous operation
     * it considers this as potentially malicious because clipboard writes should be the result
     * of user interaction and not asynchronous
     *
     * Since we don't want to generate a referral until the user has finished configuring it,
     * We keep it asynchronous, using this setTimeout workaround (which also works on any browser)
     * https://stackoverflow.com/a/77517883
     *
     * Note: There is also the navigator.clipboard.write() API which accepts a ClipboardItem, which
     * is allowed to be asynchronous on iOS. Howerver, ClipboardItem only accepts blob MIME types, not
     * plaintext.
     */
    setTimeout(() => clipboard.copy(referralLink), 0)

    sendEvents(referralId)
  }

  return {
    referralLinkCopied: clipboard.copied,
    handleCopy,
    handleShare,
    shareLoading,
    copyLoading,
  }
}
