export type PatientReferralFormPromptContextKey =
  | 'type_of_referral'
  | 'referring_provider_info'
  | 'referral_info'
  | 'provider_referral_email_sent'
  | 'provider_referral_email_scheduled'
  | 'share_friend_referral_link'
  | 'provider_organization'
  | 'referral_email'
  | 'referral_first_name'
  | 'referral_phone'
  | 'referral_state'
  | 'referring_partner_info'
  | 'email_acknowledgement'
  | 'provider_full_name'
  | 'provider_email'
  | 'provider_role'
  | 'provider_phone'
  | 'partnership_interest'

export type PartnerReferralFormPromptContextKey = 'referring_partner_info' | 'partner_referral_sent'

export type PatientReferralFormPromptResponseContextKey =
  | TypeOfReferralResponseContextKey
  | 'custom'

type TypeOfReferralResponseContextKey =
  | 'type_of_referral_provider'
  | 'type_of_referral_provider_with_patient'
  | 'type_of_referral_friend'
  | 'type_of_referral_spouse_partner'
  | 'type_of_referral_colleague'
  | 'type_of_referral_family'
  | 'type_of_referral_self'
