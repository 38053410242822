/* eslint-disable no-magic-numbers */
import { useForm } from '@mantine/form'
import {
  Alert,
  ArrowLeftIcon,
  ArrowRightIcon,
  Checkbox,
  DailyCalendar,
  DailyCalendarRadioGroup,
  ExternalLinkText,
  Group,
  PhoneCallIcon,
  PhoneInput,
  PrimaryButton,
  Radio,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  TitleTwo,
  isAnySelected,
  skipIfOtherField,
  useBanner,
  useLifecycle,
  validateWith,
} from '@shared/components'
import { PromptResponsePayload } from '@shared/types'
import { dayjs, toTime, validCharacters } from '@shared/utils'
import last from 'lodash/last'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { SMS_SUPPORT, Skeletons } from '../..'
import * as FullStory from '../../../../common/fullstory'
import { appointmentsApi, patientApi } from '../../../api'
import { isAtleastOneWord, isPhone, isRequired } from '../../../forms'
import { useAuth, useFlags } from '../../../hooks'
import { useCallMeNowQueueStatus } from '../../../hooks/use-call-me-now-queue-status'
import { useReferralPartner } from '../../../hooks/use-partner'
import { sendPageEvent } from '../../../rudderstack'
import {
  getSessionStorageItem,
  removeSessionStorageItem,
  setSessionStorageItem,
} from '../../../storage'
import { CalendarMessage } from '../../CalendarMessage'
import { WidgetProps } from '../PromptContentWidget'
import { CallMeNowPage } from './CallMeNowPage'
import { EnrollmentCoordinatorSubmit } from './EnrollmentCoordinatorSubmit'

export const WelcomeCallCalendar = ({
  reconnect = false,
  ...props
}: WidgetProps & { reconnect?: boolean }) => {
  const { authenticationMethod } = useAuth()

  const callMeNowQuery = useQuery(
    ['appointmentsApi', 'isConsultationCallQueueAvailable'],
    appointmentsApi.isConsultationCallQueueAvailable,
  )

  const isConsultationCallQueueAvailable: boolean = callMeNowQuery?.data?.isAvailable || false

  /*
   * For deciding whether to show the Call Me Now page,
   * since the queue may be available but the user may want to skip Call Me Now
   */
  const [showCallMeNow, setShowCallMeNow] = useState<boolean>(true)

  const isEcCompletingWorkflow = authenticationMethod === 'impersonation'
  if (isEcCompletingWorkflow) {
    return <EnrollmentCoordinatorSubmit {...props} />
  }

  if (callMeNowQuery.isLoading) {
    return <Skeletons />
  }

  if (showCallMeNow && isConsultationCallQueueAvailable) {
    return (
      <CallMeNowPage
        disableCallMeNow={() => setShowCallMeNow(false)}
        reconnect={reconnect}
        {...props}
      />
    )
  }

  const enableCallMeNow = () => {
    setShowCallMeNow(true)
    FullStory.event('Clicked From ConsultationCallCalendar To CallMeNowPage')
  }

  return (
    <ConsultationCallCalendar
      {...props}
      enableCallMeNow={enableCallMeNow}
      isConsultationCallQueueAvailable={isConsultationCallQueueAvailable}
    />
  )
}

const CallMeNowAlert = ({ enableCallMeNow }: { enableCallMeNow: () => void }) => {
  return (
    <Alert
      variant='primary'
      title='Have 15 minutes right now?'
      actionIcon={<ArrowRightIcon />}
      icon={<PhoneCallIcon size='sm' />}
      onClick={enableCallMeNow}
    >
      We have someone available to give you a call to answer your questions right now.
    </Alert>
  )
}

type ConsultationCallCalendarProps = WidgetProps & {
  enableCallMeNow: () => void
  isConsultationCallQueueAvailable: boolean
}

const ConsultationCallCalendar = ({ ...props }: ConsultationCallCalendarProps) => {
  const { currentUser, isAuthorized } = useAuth()
  const [date, setDate] = useState(dayjs())
  const { showBanner, hideBanner } = useBanner()
  const { isPartnerRoute } = useReferralPartner()
  const { welcomeCallCustomPhone, welcomeCallStandby, defaultWelcomeCallStandby } = useFlags()
  const { isQueueAvailable } = useCallMeNowQueueStatus()

  const patientIsDischarged = currentUser?.data?.statuses?.patient === 'discharged'

  const patientInPa =
    defaultWelcomeCallStandby.includes(getSessionStorageItem('residence_state')) ||
    defaultWelcomeCallStandby.includes(currentUser?.data?.homeData?.state)

  const [dateSlotsKey, dateSlotsFn] = patientApi.getQuery(
    patientIsDischarged
      ? 'GET /appointments/calendar/returning-welcome-call'
      : 'GET /appointments/calendar/free-consultation-call',
  )

  const { data: range, isLoading: isRangeLoading } = useQuery(
    dateSlotsKey,
    async () => {
      const availableRange = await dateSlotsFn()
      const now = dayjs()

      if (availableRange.length === 0) {
        return now.getBetween(now.add(5, 'days'), 'days').map(date => ({ date, available: false }))
      }

      const start = dayjs(availableRange[0]?.date).isToday() ? now : now.add(1, 'day')
      const range = start.getBetween(start.add(5, 'days'), 'days')

      return range.map(date => ({
        date,
        available: availableRange.some(slot => dayjs(slot.date).isSame(date)),
      }))
    },
    {
      cacheTime: toTime('10 sec').ms(),
      onSuccess: dates => {
        const slot = dates.find(slot => slot.available)
        setDate(dayjs(slot?.date ?? dates[0]?.date))
      },
      onError: () => showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
    },
  )

  const isLastDay = dayjs(last(range)?.date).isSame(date, 'day')
  const isAvailable = Boolean(range?.find(slot => date.isSame(slot.date, 'day'))?.available)
  const {
    data: timeslots,
    isLoading: isTimeSlotsLoading,
    refetch: refetchTimeSlots,
  } = useQuery(
    ...patientApi.getQuery(
      patientIsDischarged
        ? 'GET /appointments/calendar/returning-welcome-call/:date'
        : 'GET /appointments/calendar/free-consultation-call/:date',
      {
        params: { date: date.format('YYYY-MM-DD') },
      },
    ),
    {
      cacheTime: toTime('10 sec').ms(),
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      // Don't fetch for last date, we show a message of booking too far
      enabled: isAvailable && !isLastDay,
    },
  )

  const { clearErrors, getInputProps, setValues, validate, values } = useForm({
    initialValues: {
      datetime: '',
      firstName:
        currentUser?.data?.personalData?.preferredName ||
        currentUser?.data?.personalData?.firstName ||
        '',
      lastName: currentUser?.data?.personalData?.lastName || '',
      // Force with custom phone if not authorized
      withCustomPhone: !isAuthorized,
      phone: '',
      ext: '',
      earlyNotification: false,
    },
    validate: {
      datetime: isAnySelected(timeslots?.map(slot => slot.time) ?? [], 'Required'),
      firstName: validateWith(isRequired, isAtleastOneWord),
      lastName: validateWith(isRequired, isAtleastOneWord),
      phone: validateWith(skipIfOtherField('withCustomPhone', 'is', false), isRequired, isPhone),
    },
  })

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }

    const payload: PromptResponsePayload<'onboarding'> = {
      value: {
        contextKey: 'custom',
      },
      schedule_welcome_call: {
        contextKey: 'custom',
        value: values.datetime,
      },
      schedule_welcome_call_type: {
        contextKey: 'call_me_later',
      },
      schedule_welcome_call_reconnect: {
        contextKey: 'custom',
        value: patientIsDischarged,
      },
      schedule_welcome_call_first_name: {
        contextKey: 'custom',
        value: values.firstName,
      },
      schedule_welcome_call_last_name: {
        contextKey: 'custom',
        value: values.lastName,
      },
      schedule_welcome_call_timezone: {
        contextKey: 'custom',
        value: dayjs.tz.guess(),
      },
      schedule_welcome_call_address_state: {
        contextKey: 'custom',
        // Reconnect has no residence_state
        value: getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state || '',
      },
      schedule_welcome_call_early_notification_interest: {
        contextKey: 'custom',
        // If patient is in PA default to true
        value: patientInPa ?? values.earlyNotification,
      },
    }

    if (values.withCustomPhone) {
      payload.schedule_welcome_call_phone = {
        contextKey: 'custom',
        value: values.phone,
      }
      payload.schedule_welcome_call_ext = {
        contextKey: 'custom',
        value: values.ext,
      }
    }
    if (isQueueAvailable) {
      setSessionStorageItem('scheduled_for_later_while_cmn_available_survey', 'show')
    } else {
      /**
       * If for some reason someone has returned to schedule another
       * call for later, and they still have this key in their session
       * storage while the queue is off, remove the key
       */
      const scheduledForLaterSurveyStorageItem = getSessionStorageItem(
        'scheduled_for_later_while_cmn_available_survey',
      )
      if (scheduledForLaterSurveyStorageItem) {
        removeSessionStorageItem('scheduled_for_later_while_cmn_available_survey')
      }
    }

    props.onSubmit(payload, { onError: refetchTimeSlots })
  }

  useLifecycle({ onMount: () => sendPageEvent('Welcome Call Calender') })

  if (isRangeLoading) {
    return <Skeletons />
  }

  const welcomeCallSubtitleText = () => {
    if (patientIsDischarged) {
      return `After your welcome call, we'll get you back into treatment.`
    } else if (isAuthorized) {
      return `After your welcome call, we'll schedule your first visit with a licensed clinician. No charge if we're not a fit.`
    }

    return `After your welcome call, we'll schedule your first visit with a licensed clinician. No charge if we're not a fit. Since you don't have your phone on you, we'll need a phone number where you can currently receive a call.`
  }

  return (
    <Stack spacing='lg' test-id='content:schedule-welcome-call'>
      <Stack spacing='sm'>
        <TitleTwo>Choose a time for your free 15-minute welcome call</TitleTwo>
        <Text>{welcomeCallSubtitleText()}</Text>
      </Stack>
      <DailyCalendar
        nextDayPulse={timeslots?.length === 0}
        previousDayPulse={isLastDay}
        value={date.toString()}
        range={range?.map(slot => slot.date) ?? []}
        onChange={value => {
          clearErrors()
          setValues({ datetime: '' })
          setDate(dayjs(value))
          hideBanner()
        }}
      >
        {isLastDay ? (
          <CalendarMessage>
            Sorry, we can&apos;t book this far out right now. If you can&apos;t find a time, text{' '}
            <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>(215) 585-2144</ExternalLinkText>
          </CalendarMessage>
        ) : (
          <DailyCalendarRadioGroup
            size={6}
            loading={isTimeSlotsLoading}
            empty={<CalendarMessage>Sorry, we&apos;re booked on this day.</CalendarMessage>}
            {...getInputProps('datetime')}
          >
            {timeslots?.map(timeslot => {
              return (
                <Radio
                  key={timeslot.time}
                  value={timeslot.time}
                  label={dayjs(timeslot.time).format('h:mma z')}
                />
              )
            })}
          </DailyCalendarRadioGroup>
        )}
      </DailyCalendar>
      {values.datetime && (
        <Stack>
          <TextInput
            test-id='input:first-name'
            label='First name'
            placeholder='First name'
            formatter={validCharacters.name}
            {...getInputProps('firstName')}
          />
          <TextInput
            test-id='input:last-name'
            label='Last name'
            placeholder='Last name'
            formatter={validCharacters.name}
            {...getInputProps('lastName')}
          />
          {isPartnerRoute && welcomeCallCustomPhone && isAuthorized && (
            <Checkbox
              test-id='checkbox:with-custom-phone'
              label={`I won't have access to my phone at this time`}
              {...getInputProps('withCustomPhone', { type: 'checkbox' })}
            />
          )}
          {values.withCustomPhone && (
            <Stack>
              <PhoneInput
                test-id='input:custom-phone'
                label='Where can we call you?'
                {...getInputProps('phone')}
                explanation={`Most patients use the phone number of the facility where they're currently staying`}
              />
              <TextInput
                test-id='input:custom-extension'
                label='Extension (optional)'
                placeholder='e.g. 104'
                formatter={validCharacters.numbers}
                {...getInputProps('ext')}
              />
            </Stack>
          )}
        </Stack>
      )}
      {/* @welcomeCallStandbyExperiment */}
      {/* If patient is in PA, don't show checkbox*/}
      {welcomeCallStandby && !patientInPa && (
        <Checkbox
          {...getInputProps('earlyNotification', { type: 'checkbox' })}
          label='Please text me if an earlier appointment time becomes available'
        />
      )}
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            {props.isConsultationCallQueueAvailable && (
              <CallMeNowAlert enableCallMeNow={props.enableCallMeNow} />
            )}
            <PrimaryButton test-id='button:book-call@mobile' fullWidth onClick={onSubmit}>
              Book welcome call
            </PrimaryButton>
            {props.onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                style={{ margin: 'auto' }}
                leftIcon={<ArrowLeftIcon />}
                onClick={props.onBack}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Stack>
            {props.isConsultationCallQueueAvailable && (
              <CallMeNowAlert enableCallMeNow={props.enableCallMeNow} />
            )}
            <Group position='apart'>
              {props.onBack && (
                <TertiaryButton
                  test-id='button:back@desktop'
                  leftIcon={<ArrowLeftIcon />}
                  onClick={props.onBack}
                >
                  Back
                </TertiaryButton>
              )}
              <Group position='right'>
                <PrimaryButton test-id='button:book-call@desktop' onClick={onSubmit}>
                  Book welcome call
                </PrimaryButton>
              </Group>
            </Group>
          </Stack>
        }
      />
    </Stack>
  )
}
