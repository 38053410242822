import { PatientStatus } from '@shared/types'
import { useAuth, usePortalQuery } from '../../../common/hooks'

type PaymentPlanSettings = {
  isEligibleForPaymentPlan: boolean
  refetchPaymentPlanData: () => Promise<void>
}

/*
 * Note: we're running an experiment to see if patients will opt in to payment plans for subscriptions.
 * Before we build out a way for patients to generate payment plans themselves, we're going to notify
 * FCs when patients "sign up for payment plans" so that the FC can follow up with the patient to set
 * up the payment plan. These notifications will be sent via the issue tracker. So, for now, we'll check
 * whether the patient has requested a payment plan by checking for an open issue in the issue tracker.
 * We'll only show the payment plan section to the patient if the patient is eligible for a payment plan
 * and has not already requested a payment plan.
 */
export function usePaymentPlan({
  pastBalanceDueInDollars,
}: {
  pastBalanceDueInDollars: number
}): PaymentPlanSettings {
  const { currentUser } = useAuth()
  const patient = currentUser?.data

  const {
    data: subscriptionData,
    isLoading: subscriptionIsLoading,
    refetch: subscriptionRefetch,
  } = usePortalQuery('GET /subscription')

  const {
    data: hasRequestedPaymentPlan,
    isLoading: paymentPlanIsLoading,
    refetch: paymentPlanRefetch,
  } = usePortalQuery('GET /payment-plan')

  const hasSubscription = Boolean(subscriptionData)

  const isOnInsurancePay = Boolean(patient?.statuses.insuranceAllowList)

  const PAST_DUE_BALANCE_CUTOFF = 50
  const hasSufficientPastDueBalance = pastBalanceDueInDollars >= PAST_DUE_BALANCE_CUTOFF

  const activePatientStatuses: PatientStatus[] = ['in treatment']
  const isActivePatient = Boolean(
    patient?.statuses.patient && activePatientStatuses.includes(patient?.statuses.patient),
  )

  const isEligibleForPaymentPlan =
    (hasSubscription || isOnInsurancePay) &&
    hasSufficientPastDueBalance &&
    isActivePatient &&
    !hasRequestedPaymentPlan &&
    !subscriptionIsLoading &&
    !paymentPlanIsLoading

  return {
    isEligibleForPaymentPlan,
    refetchPaymentPlanData: async () => {
      await subscriptionRefetch()
      await paymentPlanRefetch()
    },
  }
}
