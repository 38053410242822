import { GetPatientInsuranceDataResponse, PatientProvidedInsuranceData } from '@shared/types'
import { BaseApi } from '@shared/utils'

export class InsuranceApi extends BaseApi {
  getPatientInsuranceData = () => {
    return this.json<GetPatientInsuranceDataResponse>({
      method: 'GET',
      url: '/insurance',
    })
  }

  updatePatientInsuranceData = (data: PatientProvidedInsuranceData) => {
    return this.json<void>({
      method: 'PUT',
      url: '/insurance',
      data,
    })
  }
}
