import { Box, TitleTwo } from '@shared/components'
import {
  TOKEN_EXPIRED_ERROR_MESSAGE,
  WELLNESS_CHECK_SEARCH_PARAMS,
  WellnessCheckWorkflowType,
  WorkflowType,
} from '@shared/types'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { WorkflowPrompt } from '../../common/components/prompt/WorkflowPrompt'
import { logger } from '../../common/logger'
import { routes } from '../../common/routes'
import { validateZoomUrl } from '../../common/validateZoom'

export const WorkflowsTitleDictionary: Record<WellnessCheckWorkflowType, string> = {
  phq_8_form: 'Depression wellness assessment',
  phq_9_form: 'Depression wellness assessment',
  barc_10_form: 'Substance use wellness assessment',
  gad_7_form: 'Anxiety wellness assessment',
  asq_form: '',
}

const { index, children } = routes.wellnessCheck
const { Token, Type, ZoomUrl, AdditionalChecks } = WELLNESS_CHECK_SEARCH_PARAMS

const redirectToZoomUrl = ({ zoomUrl }: { zoomUrl: string }) => {
  logger.info('Redirecting to zoom meeting', { zoomUrl })
  window.location.replace(zoomUrl)
}

const isValidUrl = (url: string) => {
  try {
    // will throw if url is invalid
    const { pathname } = new URL(url)
    // only allow urls from same origin, as we don't want to redirect to external sites for additional checks
    return pathname.includes(routes.wellnessCheck.index)
  } catch {
    return false
  }
}

const getAdditionalChecks = (additionalChecks: string | null) => {
  if (!additionalChecks) {
    return []
  }

  // eg: 'https://my.ophelia.com/1,https://my.ophelia.com/2' => ['https://my.ophelia.com/1', 'https://my.ophelia.com/2']
  return additionalChecks.split(',').filter(isValidUrl)
}

export const Workflows = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const type = searchParams.get(Type)
  const token = searchParams.get(Token)
  const additionalChecks = searchParams.get(AdditionalChecks)
  const zoomUrl = validateZoomUrl(searchParams.get(ZoomUrl))
  const title = WorkflowsTitleDictionary[type as WellnessCheckWorkflowType]

  const redirectOnCompletion = ({
    redirectUrl,
    zoomUrl,
  }: {
    redirectUrl: string | undefined
    zoomUrl: string | null
  }) => {
    // Scenario: User gets the ASQ message upon completing the ASQ workflow AND has a zoom url.
    if (
      redirectUrl === `${routes.wellnessCheck.index}${routes.wellnessCheck.children.asqMessage}` &&
      zoomUrl
    ) {
      // Skip the asq message page if there is a zoom url.
      return redirectToZoomUrl({ zoomUrl })
    }

    /*
     * Scenario: User response triggers ASQ workflow AND there is a zoom url in the search params.
     * The case above will ensure that the ASQ message page is skipped if there is a zoom url.
     */
    if (redirectUrl && zoomUrl) {
      // Carry over all the search params from the redirect URL (redirectUrl is a relative path)
      const url = new URL(redirectUrl, window.location.origin)
      // And add the zoom url as another search param
      url.searchParams.append(ZoomUrl, zoomUrl)

      return navigate({
        pathname: url.pathname,
        search: url.search,
      })
    }

    /*
     * Scenario: User response triggers ASQ workflow while completing the PHQ9 workflow.
     * In a chained workflow, the PHQ9 workflow will always be last so we can safely redirect
     * to the ASQ message page without worrying about skipping any other workflows.
     */
    if (redirectUrl) {
      return navigate(redirectUrl)
    }

    // For now, a `zoomUrl` takes precedence over additional checks.
    if (zoomUrl) {
      return redirectToZoomUrl({ zoomUrl })
    }

    // If there are additional checks, redirect to the next one.
    if (additionalChecks) {
      // Remove the first check from the list.
      const [nextWellnessCheck, ...rest] = getAdditionalChecks(additionalChecks)
      if (nextWellnessCheck) {
        const url = new URL(nextWellnessCheck)

        /*
         * Pass along the remaining checks.
         * This will be used to redirect to the next wellness check.
         * When all checks are completed, this will be empty.
         */
        url.searchParams.append(AdditionalChecks, rest.toString())

        return navigate({
          pathname: url.pathname,
          search: url.search,
        })
      }
    }

    const { index, children } = routes.wellnessCheck
    return navigate(`${index}${children.completed}`)
  }

  return (
    <Box test-id='page:workflows'>
      <TitleTwo mb='lg'>{title}</TitleTwo>
      <WorkflowPrompt
        workflowApi='wellness'
        key={type}
        showBackButton
        workflowType={type as WorkflowType}
        token={token as string}
        prefix={routes.wellnessCheck.index}
        onError={message => {
          logger.error('wellness check error', {
            message,
            zoomUrl,
          })

          /*
           * If this wellness check precedes a zoom meeting, just redirect to the zoom meeting
           * to avoid the patient missing their appointment.
           */
          if (zoomUrl) {
            redirectToZoomUrl({
              zoomUrl,
            })
          } else if (message === TOKEN_EXPIRED_ERROR_MESSAGE) {
            navigate(`${index}${children.expired}`)
          }
        }}
        onComplete={redirectUrl => {
          return redirectOnCompletion({
            redirectUrl,
            zoomUrl,
          })
        }}
      />
    </Box>
  )
}
