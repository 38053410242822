import { useForm } from '@mantine/form'
import {
  Checkbox,
  Flex,
  PrimaryButton,
  SecondaryButton,
  Stack,
  useBanner,
} from '@shared/components'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
  usePortalDims,
  usePortalMutation,
  useSubmitPaymentInfoMutation,
} from '../../../common/hooks'
import { routes } from '../../../common/routes'

type EditPaymentMethodFormProps = {
  paymentMethodId?: string
  totalNumberOfPaymentMethods: number
}

export const EditPaymentMethodForm = ({
  paymentMethodId,
  totalNumberOfPaymentMethods,
}: EditPaymentMethodFormProps) => {
  const { isMobile } = usePortalDims()
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const { showBanner } = useBanner()
  const editingPaymentMethod = Boolean(paymentMethodId)
  const alwaysSetDefault = editingPaymentMethod
    ? totalNumberOfPaymentMethods <= 1
    : totalNumberOfPaymentMethods > 0

  const form = useForm<{ shouldSetDefault: boolean }>({
    initialValues: {
      shouldSetDefault: true,
    },
  })
  const setDefaultPaymentMethodMutation = usePortalMutation('POST /payment/methods/default')
  const updatePaymentMethodMutation = usePortalMutation('PUT /payment/methods')
  const submitPaymentInfoMutation = useSubmitPaymentInfoMutation({
    stripe,
    elements,
    submit: newPaymentMethodId => {
      if (paymentMethodId) {
        return updatePaymentMethodMutation.mutateAsync({
          data: {
            newPaymentMethodId,
            oldPaymentMethodId: paymentMethodId,
            setAsDefault: alwaysSetDefault || form.values.shouldSetDefault,
          },
        })
      }
      if (!paymentMethodId && form.values.shouldSetDefault) {
        return setDefaultPaymentMethodMutation.mutateAsync({
          data: { paymentMethodId: newPaymentMethodId },
        })
      }
      // No action otherwise. The payment method will be added directly with stripe, but not set as default.
    },
    options: {
      onSuccess: () => {
        const previousPage = -1
        navigate(previousPage)
      },
      onError: error => {
        if (error.message) {
          showBanner({
            type: 'error',
            label: error.message,
          })
        }
      },
    },
  })

  return (
    <Stack>
      <PaymentElement />
      {!alwaysSetDefault && (
        <Checkbox
          label='Set as default payment card'
          {...form.getInputProps('shouldSetDefault', { type: 'checkbox' })}
        />
      )}
      <Flex gap='sm' direction={isMobile ? 'column' : 'row'}>
        <PrimaryButton
          fullWidth={isMobile}
          loading={submitPaymentInfoMutation.isLoading}
          disabled={submitPaymentInfoMutation.isLoading}
          onClick={() => submitPaymentInfoMutation.mutate()}
        >
          Save
        </PrimaryButton>
        <SecondaryButton
          fullWidth={isMobile}
          component={Link}
          disabled={submitPaymentInfoMutation.isLoading}
          to={`${routes.portal.index}/${routes.portal.children.billing.index}`}
        >
          Cancel
        </SecondaryButton>
      </Flex>
    </Stack>
  )
}
