import { ArrowLeftIcon, Space, Stack, TertiaryButton, TitleTwo } from '@shared/components'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { patientApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { FinancialAgreementV3 } from '../../../documents/FinancialAgreementV3'
import { SignedAgreementInfo } from './SignedAgreementInfo'

export const FinancialAgreementV3Page = () => {
  const navigate = useNavigate()
  const [consentQueryKey, consentsQueryFunction] = patientApi.getQuery(
    'GET /consents/:type/:version',
    {
      params: {
        type: 'financial',
        version: '3',
      },
    },
  )

  const consentQuery = useQuery(consentQueryKey, consentsQueryFunction)

  if (consentQuery.isLoading) {
    return <Skeletons />
  }

  return (
    <Stack>
      <TitleTwo>Financial agreement</TitleTwo>
      <FinancialAgreementV3 />
      {consentQuery.data && (
        <>
          <Space h='sm' />
          <SignedAgreementInfo
            signature={consentQuery.data?.signature}
            date={consentQuery.data?.date}
            checkboxLabel="I have read and understand Ophelia's financial policies and this Financial Agreement"
          />
        </>
      )}
      <TertiaryButton
        leftIcon={<ArrowLeftIcon />}
        onClick={() => {
          navigate(-1)
        }}
      >
        Back
      </TertiaryButton>
    </Stack>
  )
}
