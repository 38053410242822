import { isEmail, useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TextInput,
  skipIfEmptyPhone,
  validateWith,
} from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { isPhone, isRequired } from '../../../forms'
import { WidgetProps } from '../PromptContentWidget'

type NotReadyContactFormType = {
  phone: string
  email: string
}

export const NotReadyContactForm = ({ onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const onBack = () => navigate(-1)

  const form = useForm<NotReadyContactFormType>({
    validateInputOnBlur: ['phone', 'email'],
    initialValues: {
      phone: '',
      email: '',
    },
    validate: {
      phone: validateWith(skipIfEmptyPhone, isPhone),
      email: validateWith(isRequired, isEmail),
    },
  })

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    onSubmit({
      value: {
        contextKey: 'custom',
        value: form.values.email || '',
      },
      contact_info_email: {
        contextKey: 'contact_info_email',
        value: form.values.email || '',
      },
      contact_info_phone: {
        contextKey: 'contact_info_phone',
        value: form.values.phone || '',
      },
    })
  }

  return (
    <Stack spacing='md'>
      <TextInput
        label='Email address'
        placeholder='Email address'
        type='email'
        {...form.getInputProps('email')}
      />
      <PhoneInput
        label='Phone number (optional)'
        {...form.getInputProps('phone')}
        explanation='By clicking Submit below, you consent for us to send you texts. Standard rates apply.'
      />
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' align='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Submit
            </PrimaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
              Submit
            </PrimaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
