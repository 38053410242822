import {
  ArrowLeftIcon,
  Box,
  PhoneInput,
  PrimaryButton,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import React from 'react'
import { SignupProps } from '.'
import { ContactSupport } from '../../../common/components'
import { setUserVars } from '../../../common/fullstory'
import { sendPageEvent } from '../../../common/rudderstack'

export const CallUsNowNextStep = ({
  form,
  setCurrentStep,
  signInWithPhoneNumber,
  onBack,
}: SignupProps & { onBack: () => void }) => {
  const { error: phoneInputError, ...phoneInputProps } = form.getInputProps('phoneNumber')

  const onSubmit = () => {
    signInWithPhoneNumber.reset()
    setUserVars({ phoneUsedForLogin: form.values.phoneNumber })

    if (!form.validateField('phoneNumber').hasError) {
      signInWithPhoneNumber.mutate(form.values.phoneNumber, {
        onSuccess: () => setCurrentStep('verification'),
      })
    }
  }

  useLifecycle({
    onMount: () => sendPageEvent('Phone Collection'),
  })

  return (
    <Box test-id='content:phone-number' component='div'>
      <Stack spacing='lg'>
        <Stack spacing='xs'>
          <TitleTwo>Thanks for giving us a call</TitleTwo>
          <Text>Next step: sign in with your phone number to move on to the next steps</Text>
        </Stack>
        <PhoneInput
          test-id='input:phone-number'
          label='Phone number'
          error={
            phoneInputError ||
            getOpheliaHttpError(signInWithPhoneNumber.error, 'Something went wrong')
          }
          {...phoneInputProps}
        />
        <Stack spacing='lg'>
          <PrimaryButton
            test-id='button:submit@mobile'
            fullWidth
            onClick={onSubmit}
            loading={signInWithPhoneNumber.isLoading}
          >
            Sign in
          </PrimaryButton>
          <TertiaryButton fullWidth leftIcon={<ArrowLeftIcon />} onClick={onBack}>
            Back
          </TertiaryButton>
          <ContactSupport />
        </Stack>
      </Stack>
    </Box>
  )
}
