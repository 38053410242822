import React from 'react'
import { InputError } from './InputWrapper'
import { RoundCheckbox } from './RoundCheckboxSelector'
import { CheckboxGroup } from './checkbox'

export const DAYS_READONLY = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
] as const
export type DayOfWeek = (typeof DAYS_READONLY)[number]
export type Day = (typeof DAYS_READONLY)[number]

export const DaysSelector = ({
  disabledDays,
  disabled = false,
  onChange,
  ...props
}: {
  value: DayOfWeek[]
  disabledDays?: DayOfWeek[]
  disabled?: boolean
  onChange: (days: DayOfWeek[]) => void
} & InputError) => {
  return (
    <CheckboxGroup
      {...props}
      onChange={days => onChange(days as DayOfWeek[])}
      orientation='horizontal'
    >
      {DAYS_READONLY.map(day => (
        <RoundCheckbox key={day} value={day} disabled={disabledDays?.includes(day) || disabled}>
          {dayNameAbbreviation(day)}
        </RoundCheckbox>
      ))}
    </CheckboxGroup>
  )
}

const dayNameAbbreviation = (day: DayOfWeek) => {
  switch (day) {
    case 'sunday':
      return 'Sun'
    case 'monday':
      return 'Mon'
    case 'tuesday':
      return 'Tue'
    case 'wednesday':
      return 'Wed'
    case 'thursday':
      return 'Thr'
    case 'friday':
      return 'Fri'
    case 'saturday':
      return 'Sat'
  }
}
