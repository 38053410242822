import { PatientTaskType, TASK_WORKFLOW_DICTIONARY } from '@shared/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Fallback } from '../../../common/components'
import { WorkflowPrompt } from '../../../common/components/prompt/WorkflowPrompt'
import { useAuth } from '../../../common/hooks'
import { routes } from '../../../common/routes'

export const TaskWorkflowPrompt = ({
  taskType,
}: {
  taskType: PatientTaskType
  onWorkflowComplete?: () => Promise<void>
}) => {
  const workflowType = TASK_WORKFLOW_DICTIONARY[taskType]
  const navigate = useNavigate()
  const auth = useAuth()

  const onComplete = async (redirectPath?: string) => {
    await auth.currentUser?.refetch()
    navigate(redirectPath || routes.portal.index)
  }

  if (!workflowType) {
    return <Fallback />
  }

  return (
    <WorkflowPrompt
      workflowApi='workflow'
      workflowType={workflowType}
      prefix={`${routes.portal.index}/${routes.portal.children.task.index}/${taskType}`}
      onComplete={onComplete}
      showProgressNumbers={workflowType !== 'medical_intake_form'}
    />
  )
}
