import { useForm } from '@mantine/form'
import {
  Box,
  PinInput,
  PrimaryButton,
  ResponsiveBox,
  SecureInput,
  Space,
  Stack,
  Text,
  TitleTwo,
  useBanner,
} from '@shared/components'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { authApi, currentUserApi } from '../common/api'
import { Skeletons } from '../common/components'
import { useAuth, useQueryParams } from '../common/hooks'
import { validateConfirmPassword, validatePassword } from '../common/password'
import { routes } from '../common/routes'

type ResetPasswordForm = {
  password: string
  confirmPassword: string
}

export const ResetPasswordApp = () => {
  const navigate = useNavigate()
  const query = useQueryParams()
  const { showBanner } = useBanner()
  const { currentUser, authenticationMethod, isAuthorized, isLoading } = useAuth()
  const isPincode = currentUser?.data?.account.passwordType === 'pin'

  const tokenId = query.get('alt')
  const signInFirebaseAuth = useMutation(authApi.signInWithToken, {
    onError: error => showBanner({ type: 'error', label: authApi.getSanitizedErrorMessage(error) }),
  })

  useQuery(
    ['currentUserApi.getPasswordResetToken', tokenId],
    async () => currentUserApi.getPasswordResetToken(tokenId || ''),
    {
      onSuccess: data => signInFirebaseAuth.mutate(data?.token),
      onError: () => showBanner({ type: 'error', label: 'Your reset password token is invalid' }),
      enabled: !isLoading && authenticationMethod !== 'passwordReset',
    },
  )

  const resetPassword = useMutation(currentUserApi.resetPassword)

  const onResetPassword = async () => {
    if (form.validate().hasErrors) {
      return
    }

    try {
      const { token } = await resetPassword.mutateAsync({
        plainNewPassword: form.values.password,
        passwordType: isPincode ? 'pin' : 'password',
      })

      await signInFirebaseAuth.mutateAsync(token)
      navigate(routes.portal.index)
    } catch {
      showBanner({ type: 'error', label: 'Something went wrong, try again later' })
    }
  }

  const form = useForm<ResetPasswordForm>({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validate: {
      password: value => validatePassword(value, isPincode),
      confirmPassword: (value, values) => validateConfirmPassword(values.password, value),
    },
  })

  const { error: passwordInputError, ...passwordInputProps } = form.getInputProps('password')
  const { error: confirmPasswordInputError, ...confirmPasswordInputProps } =
    form.getInputProps('confirmPassword')

  if (authenticationMethod !== 'passwordReset' || !isAuthorized) {
    return <Skeletons />
  }

  return (
    <Box test-id='page:password-reset'>
      <TitleTwo>Reset your {isPincode ? 'PIN' : 'password'}</TitleTwo>
      <Space h='sm' />
      <Text>
        {isPincode
          ? "First, let's set your 4-digit PIN. You'll use this PIN to sign in to your patient portal moving forward."
          : "You'll use this password to sign in to your patient portal moving forward."}
      </Text>
      <Space h='lg' />
      {isPincode && (
        <Stack spacing='lg'>
          <PinInput
            test-id='input:pin-reset'
            length={4}
            error={passwordInputError}
            mask
            {...passwordInputProps}
          />
          <PinInput
            test-id='input:confirm-pin-reset'
            length={4}
            label='Confirm PIN'
            error={confirmPasswordInputError}
            mask
            {...confirmPasswordInputProps}
          />
        </Stack>
      )}
      {!isPincode && (
        <Stack spacing='lg'>
          <SecureInput
            label='Password'
            test-id='input:password-reset'
            placeholder='Password'
            error={passwordInputError}
            {...passwordInputProps}
          />
          <SecureInput
            label='Confirm Password'
            test-id='input:confirm-password-reset'
            placeholder='Re-type password'
            error={confirmPasswordInputError}
            {...confirmPasswordInputProps}
          />
        </Stack>
      )}
      <ResponsiveBox
        mt='lg'
        mobile={
          <PrimaryButton
            test-id='button:confirm@mobile'
            fullWidth
            loading={resetPassword.isLoading}
            onClick={onResetPassword}
          >
            {`Set ${isPincode ? 'PIN' : 'password'} and sign in`}
          </PrimaryButton>
        }
        desktop={
          <PrimaryButton
            test-id='button:submit@desktop'
            loading={resetPassword.isLoading}
            onClick={onResetPassword}
          >
            {`Set ${isPincode ? 'PIN' : 'password'} and sign in`}
          </PrimaryButton>
        }
      />
    </Box>
  )
}
