import {
  Box,
  Center,
  ChevronLeftIcon,
  Container,
  Group,
  Header,
  MyOpheliaWordmark,
  NotificationBellIcon,
  TertiaryButton,
} from '@shared/components'
import React from 'react'
import { Link } from 'react-router-dom'
import { usePortalDims } from '../../hooks'
import { routes } from '../../routes'
import { PortalBreadcrumbs } from './PortalBreadcrumbs'

type Props = {
  toggleNavbar: () => void
  isNavbarOpen: boolean
  hasNotifications: boolean
}

export const PortalHeader = (props: Props) => {
  const onToggleNavbar = () => props.toggleNavbar()
  const { isMobile, desktopMaxWidth, headerHeight, navbarWidth } = usePortalDims()

  if (isMobile) {
    return (
      <Header
        height={headerHeight}
        styles={({ other: { colors }, spacing }) => ({
          root: {
            backgroundColor: colors.background[0],
            paddingLeft: spacing.lg,
            paddingRight: spacing.lg,
            borderWidth: 0,
            display: 'flex',
            justifyContent: 'space-between',
          },
        })}
      >
        <Center
          sx={({ spacing, other: { colors } }) => ({
            color: colors.actions[0],
            width: `calc(${spacing.lg} * 3)`,
          })}
        >
          <MyOpheliaWordmark />
        </Center>
        <Group sx={({ spacing }) => ({ gap: `calc(${spacing.sm} * 3)` })}>
          <NotificationBell display={props.hasNotifications} />
          <TertiaryButton
            test-id='button:navbar-open'
            onClick={() => onToggleNavbar()}
            leftIcon={<ChevronLeftIcon />}
          />
        </Group>
      </Header>
    )
  }

  return (
    <Header
      height={headerHeight}
      styles={({ other: { colors }, spacing }) => ({
        root: {
          backgroundColor: colors.background[0],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: 0,
          borderWidth: 0,
          paddingRight: spacing.lg,
        },
      })}
    >
      <Box
        sx={({ other: { colors } }) => ({
          backgroundColor: colors.background[1],
          width: navbarWidth,
          height: '100%',
          boxSizing: 'border-box',
        })}
      >
        <Center
          sx={({ spacing, other: { colors } }) => ({
            height: '100%',
            color: colors.actions[0],
            width: `calc(${spacing.lg} * 3)`,
            marginLeft: spacing.lg,
          })}
        >
          <MyOpheliaWordmark />
        </Center>
      </Box>
      <Container
        sx={theme => ({
          paddingLeft: `calc(${theme.spacing.md} * 3)`,
          maxWidth: desktopMaxWidth,
          flexGrow: 1,
        })}
      >
        <Group position='apart'>
          <PortalBreadcrumbs />
          <NotificationBell display={props.hasNotifications} />
        </Group>
      </Container>
      <div />
    </Header>
  )
}

export const NotificationBell = ({ display }: { display: boolean }) => {
  if (!display) {
    return null
  }

  return (
    <TertiaryButton component={Link} to={routes.portal.index} leftIcon={<NotificationBellIcon />} />
  )
}
