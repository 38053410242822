export type MilestoneType =
  | '3_months_in_treatment'
  | '6_months_in_treatment'
  | '12_months_in_treatment'

export type MilestoneContentType = {
  label: string
  value: MilestoneType
  description: string
  imageUrl: string
  iconUrl: string
  message: string
}

const milestonesUrl = 'milestones/monthsInTreatment/'

export const milestonesMap: MilestoneContentType[] = [
  {
    label: '3 months',
    value: '3_months_in_treatment',
    description: "That's something to celebrate",
    imageUrl: `${milestonesUrl}3_share.png`,
    iconUrl: `${milestonesUrl}3_icon.png`,
    message:
      'Guess what?! I’ve been in care with Ophelia for 3 months now. Learn more about my program:',
  },
  {
    label: '6 months',
    value: '6_months_in_treatment',
    description: 'Reflect on one positive way your life has changed',
    imageUrl: `${milestonesUrl}6_share.png`,
    iconUrl: `${milestonesUrl}6_icon.png`,
    message: 'Still grooving! Learn more about my program:',
  },
  {
    label: 'One year',
    value: '12_months_in_treatment',
    description: 'You made a powerful decision — and stuck with it for 365 days',
    imageUrl: `${milestonesUrl}12_share.png`,
    iconUrl: `${milestonesUrl}12_icon.png`,
    message: 'Can you believe it’s been a whole year?! Check out my program - it works!',
  },
]
