import { AlertIcon, Text, Group, Stack } from '@shared/components'
import React from 'react'

type FormLabelProps = {
  title?: string | undefined
  children: string | undefined | (string | undefined)[]
}

export const FormLabel = ({ title, children }: FormLabelProps) => {
  const content = (Array.isArray(children) ? children : [children]).filter(Boolean)

  return (
    <Stack spacing='xs'>
      {title && <Text bold>{title}</Text>}
      {content.length === 0 ? (
        <Group sx={theme => ({ color: theme.other.colors.actions[2] })} spacing='xs'>
          <AlertIcon />
          <Text>No data</Text>
        </Group>
      ) : (
        content.map(text => <Text key={text}>{text}</Text>)
      )}
    </Stack>
  )
}
