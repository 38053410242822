import {
  ArrowLeftIcon,
  Checkbox,
  ExternalLinkText,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  emojiMap,
  useLifecycle,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SignupProps } from '.'
import { EMAIL_SUPPORT } from '../../../common/components'
import { setUserVars } from '../../../common/fullstory'
import { useAuth, useFlags, useScrollIntoViewOnLayoutEffect } from '../../../common/hooks'
import { useReferralPartner } from '../../../common/hooks/use-partner'
import { logger } from '../../../common/logger'
import { routes } from '../../../common/routes'
import { sendPageEvent } from '../../../common/rudderstack'

export const Phone = ({ form, setCurrentStep, signInWithPhoneNumber }: SignupProps) => {
  const { authenticationMethod } = useAuth()
  const navigate = useNavigate()
  const { partnerPath, isPartnerRoute } = useReferralPartner()
  const { partnerWelcomeFlow } = useFlags()
  const { error: phoneInputError, ...phoneInputProps } = form.getInputProps('phoneNumber')
  const { ...phoneNumberSkippedProps } = form.getInputProps('phoneNumberSkipped')
  const phoneNumberSkipped = phoneNumberSkippedProps.value

  const { targetRef } = useScrollIntoViewOnLayoutEffect({ dependencyList: [form.values.state] })

  const isEcCompletingWorkflow = authenticationMethod === 'impersonation'

  const onSubmit = () => {
    if (isEcCompletingWorkflow) {
      navigate(routes.onboarding.index)
      return
    }

    if (phoneNumberSkipped) {
      navigate(`${routes.onboarding.index}${partnerPath}`)
    }

    signInWithPhoneNumber.reset()
    setUserVars({ phoneUsedForLogin: form.values.phoneNumber })

    if (!form.validateField('phoneNumber').hasError) {
      signInWithPhoneNumber.mutate(form.values.phoneNumber, {
        onSuccess: () => setCurrentStep('verification'),
      })
    }
  }

  useLifecycle({
    onMount: () => {
      logger.info('Phone Collection', { tags: { workflow: 'onboarding' } })
      sendPageEvent('Phone Collection')
    },
  })

  const loading = signInWithPhoneNumber.status === 'loading'

  return (
    <Stack spacing='lg' test-id='content:phone-number' ref={targetRef}>
      {!isEcCompletingWorkflow && (
        <>
          <Stack spacing='sm'>
            <TitleTwo>{`${emojiMap['party popper']} We serve your state`}</TitleTwo>
            {isPartnerRoute ? (
              <Text>Next, we need your phone number so we can send you texts about your care.</Text>
            ) : (
              <Text>
                Next, we need your phone number so we can send you texts about your care. Don&apos;t
                have your own phone number? Email{' '}
                <ExternalLinkText href={`mailto:${EMAIL_SUPPORT}`}>
                  {EMAIL_SUPPORT}
                </ExternalLinkText>
              </Text>
            )}
          </Stack>
          <Stack spacing='sm'>
            <PhoneInput
              test-id='input:phone-number'
              label='Phone number'
              explanation='By clicking Next, you consent for us to send you texts. Standard rates apply.'
              error={
                phoneInputError ||
                getOpheliaHttpError(signInWithPhoneNumber.error, 'Something went wrong')
              }
              disabled={phoneNumberSkipped}
              {...phoneInputProps}
            />
            {isPartnerRoute && partnerWelcomeFlow && (
              <Checkbox
                test-id='checkbox:no-phone'
                label={`I don't currently have my phone`}
                {...phoneNumberSkippedProps}
              />
            )}
          </Stack>
        </>
      )}
      <ResponsiveBox
        mobile={
          <Stack spacing='lg'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={onSubmit}>
              Next
            </PrimaryButton>
            <TertiaryButton
              loading={loading}
              disabled={loading}
              test-id='button:back@mobile'
              component={Link}
              fullWidth
              leftIcon={<ArrowLeftIcon />}
              to={`${routes.welcome.index}${partnerPath}`}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart' id='desktop-button-section'>
            <TertiaryButton
              loading={loading}
              disabled={loading}
              test-id='button:back@desktop'
              component={Link}
              leftIcon={<ArrowLeftIcon />}
              to={`${routes.welcome.index}${partnerPath}`}
            >
              Back
            </TertiaryButton>
            <Group>
              <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
