import { useMediaQuery } from '@mantine/hooks'
import {
  BellIcon,
  CalendarIcon,
  ChevronRightIcon,
  ClipboardIcon,
  CreditCardIcon,
  DollarSignIcon,
  ExternalLinkText,
  FeatherIcon,
  GiftIcon,
  GlobeIcon,
  LifeBuoyIcon,
  MessageCircleIcon,
  SunIcon,
  TaskCard,
  Text,
  TruckIcon,
  TaskBar as TwilightTaskBar,
  UnstyledButton,
  UserIcon,
  VideoIcon,
  useMantineTheme,
} from '@shared/components'
import { PatientPortalTaskCard, TwilightIcon } from '@shared/types'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { usePortalDims } from '../../hooks'
import { sendTrackEvent } from '../../rudderstack'

type TaskBarProps = {
  tasks: PatientPortalTaskCard[]
}

export const TaskBar = ({ tasks }: TaskBarProps) => {
  const theme = useMantineTheme()
  const { isMobile } = usePortalDims()
  // Have to use this rather than isMobile - different sized breakpoints (xs vs md)
  const isSmallDevice = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`)
  const shouldExpandCardWidth = isSmallDevice && tasks.length === 1

  useEffect(() => {
    if (tasks.find(task => task.type === 'intake-visit-standby-list')) {
      sendTrackEvent('Standby List Task Seen', { type: 'intake-visit-standby-list' })
    }
  }, [tasks])

  if (tasks.length === 0) {
    return null
  }

  return (
    <TwilightTaskBar
      sx={{ display: 'grid' }}
      ml={isMobile ? 0 : theme.spacing.xs}
      header='Tasks'
      number={tasks.length}
      footer={
        <Text>
          {'Text '}
          <ExternalLinkText href='sms:2155852144'>(215) 585-2144</ExternalLinkText>
          {' for support'}
        </Text>
      }
    >
      {tasks.map(task => {
        return (
          <UnstyledButton
            component={Link}
            test-id={`task-card:${task.type}`}
            to={task.path}
            key={task.type}
            sx={{
              display: 'flex',
              flexGrow: shouldExpandCardWidth ? 1 : 0,
              ':focus': { outline: 'none', '& div': { textDecoration: 'underline' } },
              ...(task.disabled ? { pointerEvents: 'none' } : {}),
            }}
          >
            <TaskCard
              title={task.title}
              icon={!task.disabled && getIconFromStringName(task.icon)}
              footer={task.footer}
              fillWidth={shouldExpandCardWidth}
              requiredMessage={task.requiredMessage}
              disabled={task.disabled}
            />
          </UnstyledButton>
        )
      })}
    </TwilightTaskBar>
  )
}

const getIconFromStringName = (icon: TwilightIcon) => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (icon) {
    case 'Calendar':
      return <CalendarIcon />
    case 'User':
      return <UserIcon />
    case 'ChevronRight':
      return <ChevronRightIcon />
    case 'Clipboard':
      return <ClipboardIcon />
    case 'Globe':
      return <GlobeIcon />
    case 'LifeBuoy':
      return <LifeBuoyIcon />
    case 'MessageCircle':
      return <MessageCircleIcon />
    case 'DollarSign':
      return <DollarSignIcon />
    case 'CreditCard':
      return <CreditCardIcon />
    case 'Truck':
      return <TruckIcon />
    case 'Bell':
      return <BellIcon />
    case 'Sun':
      return <SunIcon />
    case 'Feather':
      return <FeatherIcon />
    case 'Gift':
      return <GiftIcon />
    case 'Video':
      return <VideoIcon />
  }
}
