import {
  Box,
  Divider,
  ExternalLinkText,
  PrimaryButton,
  SmartphoneIcon,
  Stack,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { phone } from '@shared/utils'
import React from 'react'
import * as FullStory from '../fullstory'
import { sendTrackEvent } from '../rudderstack'
import { SMS_SUPPORT } from './ContactSupport'

export const OPHELIA_REFERRALS_PHONE = '+12159875514'
export const OPHELIA_REFERRALS_PHONE_FORMATTED = phone(OPHELIA_REFERRALS_PHONE).arealessFormat

export const ContactReferrals = ({ isMobile = false }: { isMobile?: boolean }) => {
  const handleClick = () => {
    sendTrackEvent(`Partner Welcome Page - Have A Question Clicked`)
    FullStory.event(`Partner Welcome Page - Have A Question Clicked`)
  }

  useLifecycle({
    onMount: () => {
      sendTrackEvent(`Partner Welcome Page - Have A Question Visible`)
      FullStory.event(`Partner Welcome Page - Have A Question Visible`)
    },
  })

  return (
    <Stack spacing='md'>
      <Divider my='md' />
      <Stack spacing='sm'>
        <TitleTwo>Have questions around patient fit?</TitleTwo>
        <Text>
          Our team is available to answer your questions about whether Ophelia is well-suited for
          your patient.{' '}
          {!isMobile && (
            <Text span>
              Call our dedicated referrals line at{' '}
              <ExternalLinkText
                span
                href={`tel:${OPHELIA_REFERRALS_PHONE}`}
                onClick={handleClick}
                target='_blank'
              >
                {OPHELIA_REFERRALS_PHONE_FORMATTED}
              </ExternalLinkText>
              .
            </Text>
          )}
        </Text>
        {isMobile && (
          <Box mt='md'>
            <ExternalLinkText
              style={{ width: '100%' }}
              onClick={handleClick}
              href={`sms:${SMS_SUPPORT}?&body=REFER`}
              target='_blank'
            >
              <PrimaryButton fullWidth leftIcon={<SmartphoneIcon />}>
                Send us a text
              </PrimaryButton>
            </ExternalLinkText>
          </Box>
        )}
      </Stack>
      <Divider my='md' />
    </Stack>
  )
}
