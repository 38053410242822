import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { routes } from '../../../common/routes'
import { FinancialAgreementV2Page } from './FinancialAgreementV2Page'
import { FinancialAgreementV3Page } from './FinancialAgreementV3Page'
import { FinancialAgreementV4Page } from './FinancialAgreementV4Page'
import { FinancialAgreementV5Page } from './FinancialAgreementV5Page'

export const FinancialAgreementPage = () => {
  const { version } = useParams<{ version: string }>()

  switch (version) {
    case '5': {
      return <FinancialAgreementV5Page />
    }
    case '4': {
      return <FinancialAgreementV4Page />
    }
    case '3': {
      return <FinancialAgreementV3Page />
    }
    case '2': {
      return <FinancialAgreementV2Page />
    }
    default:
      return (
        <Navigate replace to={`${routes.portal.index}/${routes.portal.children.documents.index}`} />
      )
  }
}
