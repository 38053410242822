import {
  Flex,
  Group,
  Image,
  LogOutIcon,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
  UnstyledButton,
  useLifecycle,
} from '@shared/components'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ContactReferrals } from '../../common/components/ContactReferrals'
import { Config } from '../../common/config'
import { auth } from '../../common/firebase'
import * as FullStory from '../../common/fullstory'
import { useReferralPartner } from '../../common/hooks/use-partner'
import { logger } from '../../common/logger'
import { routes } from '../../common/routes'
import { sendPageEvent } from '../../common/rudderstack'
import { CallMeNowQueueStatus } from './CallMeNowQueueStatus'
import { PartnerLogo } from './PartnerLogo'

export const WelcomePartner = () => {
  const { partner, partnerPath } = useReferralPartner()

  useLifecycle({
    onMount: async () => {
      if (partner) {
        logger.info('Welcome', { tags: { workflow: 'onboarding' } })
        sendPageEvent(`Welcome ${partner?.name}`)
        FullStory.event('Partner Welcome Page Visited', {
          partnerName: `${partner.name}`,
        })
      }
      await auth.signOut()
    },
  })

  return (
    <Stack spacing='md' test-id='page:partner-welcome'>
      <Stack spacing='sm'>
        <PartnerLogo partner={partner} />
        <Text>Referring patients to Ophelia is simple. Choose how you want to get started.</Text>
      </Stack>
      <ResponsiveBox
        mobile={
          <Stack spacing='md' align='center'>
            <CardButton
              to={`${routes.referralsWithPatient.index}${partnerPath}`}
              imageSrc={`${Config.IMAGE_BASE_URL}buildings.png`}
              text='Patient is ready for a welcome call now'
              cmnPill={<CallMeNowQueueStatus />}
              test-id='button:onboard@mobile'
            />
            <CardButton
              to={`${routes.referrals.index}${partnerPath}`}
              imageSrc={`${Config.IMAGE_BASE_URL}text_message.png`}
              text='Submit a referral'
              test-id='button:refer@mobile'
            />
            <ContactReferrals isMobile />
            <TertiaryButton leftIcon={<LogOutIcon />} component={Link} to={routes.welcome.index}>
              {partner?.name ? `Not ${partner.name}?` : 'Back'}
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Stack spacing='md'>
            <Group noWrap position='apart' sx={{ alignItems: 'stretch' }}>
              <CardButton
                to={`${routes.referralsWithPatient.index}${partnerPath}`}
                imageSrc={`${Config.IMAGE_BASE_URL}buildings.png`}
                text='Patient is ready for a welcome call now'
                cmnPill={<CallMeNowQueueStatus />}
                test-id='button:onboard@desktop'
              />
              <CardButton
                to={`${routes.referrals.index}${partnerPath}`}
                imageSrc={`${Config.IMAGE_BASE_URL}text_message.png`}
                text='Submit a referral'
                test-id='button:refer@desktop'
              />
            </Group>
            <ContactReferrals />
            <TertiaryButton leftIcon={<LogOutIcon />} component={Link} to={routes.welcome.index}>
              {partner?.name ? `Not ${partner.name}?` : 'Back'}
            </TertiaryButton>
          </Stack>
        }
      />
    </Stack>
  )
}

const CardButton = ({
  imageSrc,
  text,
  to,
  cmnPill,
  ...props
}: {
  imageSrc: string
  text: string
  to: string
  cmnPill?: ReactNode
  'test-id'?: string
}) => {
  return (
    <Flex sx={{ flex: 'auto', width: '100%' }} p='sm'>
      <UnstyledButton
        test-id={props['test-id']}
        component={Link}
        to={to}
        sx={({ other: { colors, sizes }, radius }) => ({
          backgroundColor: colors.background[1],
          borderWidth: sizes.border.md,
          borderStyle: 'solid',
          borderColor: colors.actions[0],
          borderRadius: radius.sm,
          width: '100%',
          alignContent: 'center',
          textAlign: 'center',
        })}
      >
        <Stack spacing='xs' align='center' p='md'>
          <Image src={imageSrc} width={48} height={52} my='xs' />
          <Text>{text}</Text>
          {cmnPill}
        </Stack>
      </UnstyledButton>
    </Flex>
  )
}
