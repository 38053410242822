import { useMediaQuery } from '@mantine/hooks'
import { useMantineTheme } from '@shared/components'

export const PORTAL_HEADER_HEIGHT = 70
export const PORTAL_NAVBAR_WIDTH = 240

export const usePortalDims = () => {
  const theme = useMantineTheme()

  return {
    isMobile: useMediaQuery(`(max-width: ${theme.breakpoints.md})`),
    mobileMediaQuery: `@media (max-width: ${theme.breakpoints.md})`,
    isDesktop: useMediaQuery(`(min-width: ${theme.breakpoints.md})`),
    desktopMediaQuery: `@media (min-width: ${theme.breakpoints.md})`,
    desktopMaxWidth: theme.breakpoints.md,
    headerHeight: PORTAL_HEADER_HEIGHT,
    navbarWidth: PORTAL_NAVBAR_WIDTH,
  }
}
