import { Stack, Text, TitleFour } from '@shared/components'
import React from 'react'

export const TreatmentConsent = () => {
  return (
    <Stack spacing='lg'>
      <Stack>
        <Text>
          Ophelia offers buprenorphine/naloxone (Suboxone, Zubsolv, Bunavail or generic
          formulations) to treat opioid use disorder (OUD). The goal of this treatment is to reduce
          or prevent symptoms of withdrawal from opioids, improve how you feel, and decrease
          cravings that might prompt returning to opioid use.&nbsp;
          <Text span bold>
            By signing this form you agree that your treatment goals include reducing or eliminating
            opioid use.
          </Text>
        </Text>
        <Stack spacing={0}>
          <Text bold>What is buprenorphine?</Text>
          <Text>
            Buprenorphine is an FDA approved medication to treat OUD. At Ophelia, we use
            Buprenorphine for long-term maintenance therapy.
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text bold>How do I take buprenorphine?</Text>
          <Text>
            Buprenorphine tablets or films must be held under the tongue until they dissolve
            completely. The absorption process takes 5-20 minutes. It is suggested to not eat or
            drink before taking buprenorphine. Do NOT swallow buprenorphine like a pill.
            Buprenorphine will not be absorbed from the stomach if it is swallowed and there will be
            no effects from it. Your provider will talk about how to properly take Buprenorphine in
            more detail during your appointment.
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text bold>What risks does buprenorphine have? </Text>
          <Text>
            Treatment with buprenorphine has certain risks associated with it, including, but not
            limited to: sleepiness, constipation, sweating, nausea, itching, vomiting, dizziness,
            allergic reaction, slowing of breathing rate, slowing of reflexes, or reaction time. It
            is also possible that buprenorphine will not provide complete relief of craving and
            withdrawal. Sometimes this indicates the need for a change in dose or formulation. If
            buprenorphine is suddenly discontinued, some patients have no withdrawal symptoms, while
            others have symptoms such as insomnia, anxiety, muscle aches, stomach cramps, or
            diarrhea lasting several days. To minimize the possibility of opioid withdrawal,
            buprenorphine should be tapered gradually, usually over several weeks or months under
            close supervision with your Ophelia team.
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text bold>What kind of buprenorphine am I taking?</Text>
          <Text>
            The form of buprenorphine you will be taking is a combination of buprenorphine with a
            short-acting opioid blocker (naloxone). If you are physically dependent on opioids, you
            should be in moderate to severe withdrawal when you take the first dose of
            buprenorphine. If you are actively dependent on opioids and not in withdrawal when first
            taking buprenorphine, it may cause precipitated opioid withdrawal, which is severe, and
            may require hospitalization.
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text bold>What interactions does buprenorphine have?</Text>
          <Text>
            After you are stable on buprenorphine, it is expected that other opioids will have a
            lesser effect. Attempts to override buprenorphine by taking higher doses of opioids
            could result in overdose or death. You should not take any other medication without
            discussing it with your Ophelia clinician first. Combining buprenorphine with alcohol or
            some other medications may also be hazardous. The combination of buprenorphine with
            benzodiazepines (Valium, Xanax, Ativan) may increase the risk of overdose.
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text bold>What are alternatives to buprenorphine?</Text>
          <Text>
            Some hospitals that have specialized addiction treatment units can provide
            detoxification and residential treatment for opioid use and may use naltrexone
            injections (Vivitrol) to help prevent relapse. Naltrexone is a medication that blocks
            the effects of opioids and reduces craving but has no opioid effects of its own. Other
            forms of opioid maintenance therapy include methadone maintenance. You should discuss
            risks and benefits of all treatment options with your Ophelia provider.
          </Text>
        </Stack>
      </Stack>
      <Stack spacing='sm'>
        <Text bold>Consent to facsimile communication</Text>
        <Text>
          Most of our communication is via email, text, and phone, but from time to time, we may
          need to fax paperwork to your pharmacy, insurance provider, or any other facility as
          required during your treatment.
        </Text>
        <Text>
          I authorize Ophelia to disclose any information needed to confirm the validity of my
          prescription and for submission for payment for the prescription; to the dispensing
          pharmacy to whom I present my prescription or to whom my prescription is called/sent/faxed
          as well as to third party payors; and for the purpose of assuring the pharmacy of the
          validity of the prescription, so it can be legally dispensed, and for payment purposes.
          This consent is subject to revocation at any time except to the extent that the program
          which is to make the disclosure has already taken action in reliance on it. If not
          previously revoked, this consent will terminate upon termination of treatment. Each
          disclosure made with the patient’s written consent must be accompanied by the following
          written statement: This information has been disclosed to you from records protected by
          Federal confidentiality rules (42 CFR Part 2). The Federal rules prohibit you from making
          any further disclosure of this information unless further disclosure is expressly
          permitted by the written consent of the person to whom it pertains or as otherwise
          permitted by 42 CFR Part 2. A general authorization for the release of medical or other
          information is NOT sufficient for this purpose. The Federal rules restrict any use of the
          information to criminally investigate or prosecute any alcohol or drug abuse patient.
        </Text>
      </Stack>
      <Stack>
        <TitleFour>Patient responsibilities</TitleFour>
        <Text bold>
          I agree to avoid driving, working with machinery, or engaging in hazardous activities if
          intoxicated or experiencing sedation from buprenorphine.
        </Text>
        <Stack spacing={0}>
          <Text bold>I agree to store medication properly.</Text>
          <Text>
            Buprenorphine may be harmful or deadly to children, household members, guests, and pets.
            The pills/films should be stored in a safe place. If anyone besides you ingests the
            medication, you must call the poison control center or 911 immediately. It is solely
            your responsibility to protect the supply of medication. You agree to notify Ophelia
            immediately in case of lost or stolen medication. If a police report is filed, you must
            bring in a copy for our records.
          </Text>
        </Stack>
        <Stack spacing={0}>
          <Text bold>I agree to take buprenorphine only as prescribed.</Text>
          <Text>
            The indicated dose should be taken daily, and you must not adjust the dose on your own.
            If you think you may benefit from a dose change, you will contact Ophelia via phone or
            text for an appointment to discuss this with your provider. You also agree to update
            your Ophelia team about all other medications and treatments that you receive.{' '}
          </Text>
        </Stack>
        <Text bold>
          I agree that part of your treatment plan includes completing regular drug screens, and
          that ongoing drug screens that are positive for opioids or other substances will prompt a
          revision of the treatment plan.
        </Text>
        <Text bold>
          I agree that if my use of opioids and other substances repeatedly puts me or others in
          dangerous situations, or if I repeatedly struggle to adhere to treatment recommendations,
          my treatment with Ophelia may be paused and referrals for more intensive treatment will be
          provided.
        </Text>
        <Text bold>
          I understand that Ophelia is not a comprehensive medical provider and primarily provides
          specialized treatment for opioid use disorder. Ophelia offers a limited set of additional
          clinical services including support with smoking cessation and treatment for common mental
          health conditions. Ophelia offers treatment via a collaborative team of clinicians
          including a nurse and a psychiatric consultant who may discuss and coordinate my care
          while I am not present. My Ophelia provider will not treat any medical condition outside
          of Ophelia&apos;s clinical purview.
        </Text>
        <Text>
          I understand that to schedule an appointment, I will contact Ophelia at (215) 585-2144 or
          support@ophelia.com.&nbsp;
          <Text bold span>
            Please note that we charge a $20 fee for all visits that are not canceled or rescheduled
            within 24 hours of the scheduled start time.&nbsp;
          </Text>
          This policy is in place to protect our clinicians&apos; time, and to keep Ophelia
          affordable for all patients
        </Text>
        <Text bold>
          I understand that Ophelia is not able to support patients on issues outside of
          Ophelia&apos;s clinical purview or outside of Ophelia&apos;s business hours. Ophelia is
          not a hotline, crisis center, or triage site. While we try to respond to patients in a
          timely fashion, we cannot guarantee an immediate response. In case of an emergency, call
          911 or go to the nearest emergency room.
        </Text>
      </Stack>
    </Stack>
  )
}
