import { useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  DaysSelector,
  Divider,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  isAllSelected,
  useBanner,
  validateWith,
} from '@shared/components'
import {
  DAYS as ALL_WEEK_DAYS,
  AppointmentTypeString,
  BUSINESS_DAYS,
  DayOfWeek,
  ValueOf,
  getOpheliaHttpError,
  getVisitTypeName,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import isEqual from 'lodash/isEqual'
import React from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { patientApi } from '../common/api'
import { Skeletons } from '../common/components'
import { isRequired } from '../common/forms'
import { useMyMutation, useMyQuery, useOnboardingDims, usePortalQuery } from '../common/hooks'
import { AppointmentConfirmed } from './AppointmentConfirmed'
import { CannotConfirmAppointment } from './CannotConfirmAppointment'
import { ConfirmationReferralLink } from './ConfirmationReferralLink'

const AVAILABILITY_TYPE = {
  AllWeek: 'all_week' as const,
  SpecificTimes: 'specific_times' as const,
}

type AvailabilityType = ValueOf<typeof AVAILABILITY_TYPE>

type Availability = {
  morning: DayOfWeek[]
  afternoon: DayOfWeek[]
  evening: DayOfWeek[]
}

const INITIAL_AVAILABILITY: Availability = {
  morning: [],
  afternoon: [],
  evening: [],
}

const ALL_WEEK_AVAILABILITY: Availability = {
  morning: ALL_WEEK_DAYS,
  afternoon: ALL_WEEK_DAYS,
  evening: BUSINESS_DAYS,
}

export const ConfirmationApp = () => {
  const { appointmentId } = useParams()
  const { showBanner } = useBanner()
  const { isMobile } = useOnboardingDims()

  const {
    isLoading: isLoadingAppointmentInfo,
    data: appointmentInfo,
    refetch,
  } = usePortalQuery(
    'GET /appointments/:id/confirm',
    {
      params: { id: appointmentId as string },
    },
    {
      enabled: Boolean(appointmentId),
      onSuccess: appointmentInfo => {
        if (appointmentInfo.confirmed) {
          showBanner({
            type: 'success',
            label: `${getVisitTypeName(appointmentInfo.visitType)} confirmed`,
          })
        }
      },
      onError: error => {
        showBanner({
          type: 'error',
          label: getOpheliaHttpError(error, 'Something went wrong, please try again'),
        })
      },
    },
  )

  const options = [
    {
      value: 'quiet-place',
      label: 'I will be free from distraction and in a quiet place.',
    },
  ]

  const isWelcomeCall = Boolean(
    appointmentInfo?.visitType &&
      ['Free Consultation Call', 'Returning welcome call'].includes(appointmentInfo.visitType),
  )

  if (!isWelcomeCall) {
    // We don't charge for welcome-calls
    options.push({
      value: 'no-attendance-fee',
      label:
        "I understand that if I don't attend, or if I cancel less than 24 hours before my visit, I may be charged $20.",
    })
  }

  const confirmationForm = useForm<{
    visitConfirmations: string[]
  }>({
    initialValues: {
      visitConfirmations: [],
    },
    validate: {
      visitConfirmations: isAllSelected(options, 'Required'),
    },
  })

  const availabilityForm = useForm<Availability & { availabilityType: AvailabilityType | null }>({
    initialValues: {
      availabilityType: null,
      ...INITIAL_AVAILABILITY,
    },
    validate: {
      availabilityType: validateWith(isRequired),
    },
  })

  const hasAvailability =
    availabilityForm.values.availabilityType === AVAILABILITY_TYPE.AllWeek ||
    availabilityForm.values.morning.length > 0 ||
    availabilityForm.values.afternoon.length > 0 ||
    availabilityForm.values.evening.length > 0

  useMyQuery(
    'GET /patients/:patientId/settings/availability',
    {
      params: {
        patientId: appointmentInfo?.patientId || '',
      },
    },
    {
      enabled: !isWelcomeCall && Boolean(appointmentInfo?.patientId),
      onSuccess: data => {
        const availabilityData = data?.data?.availability

        // No need to seed the form if the patient has no availability set previously
        if (!availabilityData) {
          return
        }

        // If the patient has all-week availability, we don't need to set the specific times
        const availabilityType = isEqual(availabilityData, ALL_WEEK_AVAILABILITY)
          ? AVAILABILITY_TYPE.AllWeek
          : AVAILABILITY_TYPE.SpecificTimes

        availabilityForm.setValues({
          availabilityType,
          ...availabilityData,
        })
      },
    },
  )

  const availabilityMutation = useMyMutation('POST /patients/:patientId/settings/availability')

  const datetime = dayjs(appointmentInfo?.datetime)
  const appointmentDate = datetime.format('MMM D YYYY').toUpperCase()
  const appointmentTime = datetime.format('h:mma z')

  const {
    mutate: confirmAppointment,
    isLoading: isConfirmingAppointment,
    isSuccess: appointmentConfirmationWasSuccessful,
  } = useMutation(patientApi.getMutation('PUT /appointments/:id/confirm'), {
    onSuccess: () =>
      showBanner({
        type: 'success',
        label: `${getVisitTypeName(appointmentInfo?.visitType as AppointmentTypeString)} confirmed`,
      }),
    onError: () => {
      void refetch()
      showBanner({ type: 'error', label: 'Something went wrong, try again later' })
    },
  })

  // Confirming a welcome call doesn't require availability
  const onConfirmWelcomeCall = () => {
    const hasError = confirmationForm.validate().hasErrors

    if (hasError) {
      return
    }

    confirmAppointment({
      params: {
        id: appointmentId || '',
      },
    })
  }

  // Confirming a non-welcome call requires availability
  const onConfirmNonWelcomeCall = () => {
    const hasConfirmationFormErrors = confirmationForm.validate().hasErrors
    const hasAvailabilityFormErrors = availabilityForm.validate().hasErrors

    if (hasConfirmationFormErrors || hasAvailabilityFormErrors || !hasAvailability) {
      return
    }

    confirmAppointment({
      params: {
        id: appointmentId || '',
      },
    })

    const availability =
      availabilityForm.values.availabilityType === AVAILABILITY_TYPE.AllWeek
        ? ALL_WEEK_AVAILABILITY
        : {
            morning: availabilityForm.values.morning,
            afternoon: availabilityForm.values.afternoon,
            evening: availabilityForm.values.evening,
          }

    availabilityMutation.mutate({
      params: {
        patientId: appointmentInfo?.patientId || '',
      },
      data: {
        availability,
      },
    })
  }

  if (isLoadingAppointmentInfo || !appointmentInfo) {
    return <Skeletons />
  }

  if (appointmentInfo.status !== 'none') {
    return (
      <CannotConfirmAppointment isWelcomeCall={isWelcomeCall} appointmentInfo={appointmentInfo} />
    )
  }

  const appointmentTypeName = getVisitTypeName(appointmentInfo.visitType, true)
  // `calendar` for welcome calls isn't a provider name so don't display
  const providerText = isWelcomeCall ? '' : ` with ${appointmentInfo?.calendar}`

  if (appointmentInfo?.confirmed || appointmentConfirmationWasSuccessful) {
    return (
      <AppointmentConfirmed
        isWelcomeCall={isWelcomeCall}
        appointmentDate={appointmentDate}
        appointmentTypeName={appointmentTypeName}
        appointmentTime={appointmentTime}
        providerText={providerText}
        patientId={appointmentInfo.patientId}
      />
    )
  }

  const ctaText = isWelcomeCall ? 'Confirm visit' : 'Confirm visit and availability'

  const isAllMorningsSelected = isEqual(availabilityForm.values.morning, ALL_WEEK_DAYS)
  const isAllAfternoonsSelected = isEqual(availabilityForm.values.afternoon, ALL_WEEK_DAYS)
  const isAllEveningsSelected = isEqual(availabilityForm.values.evening, BUSINESS_DAYS)

  return (
    <Stack spacing='lg' test-id='page:appointment-confirmation'>
      <TitleTwo test-id='title:confirm-appointment'>
        {`Confirm your ${appointmentTypeName} for ${appointmentDate} at ${appointmentTime}${providerText}`}
      </TitleTwo>
      <CheckboxGroup {...confirmationForm.getInputProps('visitConfirmations')}>
        {options.map(({ value, label }) => (
          <Checkbox test-id={`checkbox:${value}`} key={value} value={value} label={label} />
        ))}
      </CheckboxGroup>
      <Divider />
      {!isWelcomeCall && (
        <Stack spacing='md'>
          <TitleTwo>Set your availability</TitleTwo>
          <Text>
            Please share your availability for future visits so we can try to accommodate your
            schedule.
          </Text>
          <RadioGroup {...availabilityForm.getInputProps('availabilityType')}>
            <Radio
              test-id='availability:all-week'
              value={AVAILABILITY_TYPE.AllWeek}
              label="I'm available anytime in the week"
            />
            <Radio
              value={AVAILABILITY_TYPE.SpecificTimes}
              label='I would like to set my availability'
            />
          </RadioGroup>
          {availabilityForm.values.availabilityType === AVAILABILITY_TYPE.SpecificTimes && (
            <>
              <Stack>
                {/* MORNINGS */}
                <Group position='apart'>
                  <Group spacing='sm'>
                    <Text bold>MORNINGS</Text>
                    <Text color={c => c.background[2]}>8am-Noon</Text>
                  </Group>
                  <TertiaryButton
                    onClick={() => {
                      availabilityForm.setValues({
                        morning: isAllMorningsSelected ? [] : ALL_WEEK_DAYS,
                      })
                    }}
                  >
                    {isAllMorningsSelected ? 'De-select all' : 'Select all'}
                  </TertiaryButton>
                </Group>
                <DaysSelector {...availabilityForm.getInputProps('morning')} />
              </Stack>
              <Divider />
              {/* AFTERNOONS */}
              <Stack>
                <Group position='apart'>
                  <Group spacing='sm'>
                    <Text bold>AFTERNOONS</Text>
                    <Text color={c => c.background[2]}>Noon-5pm</Text>
                  </Group>
                  <TertiaryButton
                    onClick={() => {
                      availabilityForm.setValues({
                        afternoon: isAllAfternoonsSelected ? [] : ALL_WEEK_DAYS,
                      })
                    }}
                  >
                    {isAllAfternoonsSelected ? 'De-select all' : 'Select all'}
                  </TertiaryButton>
                </Group>
                <DaysSelector {...availabilityForm.getInputProps('afternoon')} />
              </Stack>
              <Divider />
              {/* EVENINGS */}
              <Stack>
                <Group position='apart'>
                  <Group spacing='sm'>
                    <Text bold>EVENINGS</Text>
                    <Text color={c => c.background[2]}>5-9pm</Text>
                  </Group>
                  <TertiaryButton
                    onClick={() => {
                      availabilityForm.setValues({
                        evening: isAllEveningsSelected ? [] : BUSINESS_DAYS,
                      })
                    }}
                  >
                    {isAllEveningsSelected ? 'De-select all' : 'Select all'}
                  </TertiaryButton>
                </Group>
                <DaysSelector
                  {...availabilityForm.getInputProps('evening')}
                  disabledDays={['saturday', 'sunday']}
                />
              </Stack>
              {!hasAvailability && (
                <Text bold color={c => c.error[0]}>
                  Please select at least one availability above
                </Text>
              )}
            </>
          )}
        </Stack>
      )}
      <Stack spacing='lg'>
        <PrimaryButton
          test-id='button:confirm'
          fullWidth={isMobile}
          onClick={isWelcomeCall ? onConfirmWelcomeCall : onConfirmNonWelcomeCall}
          loading={isConfirmingAppointment}
        >
          {ctaText}
        </PrimaryButton>
        <ConfirmationReferralLink patientId={appointmentInfo.patientId} />
      </Stack>
    </Stack>
  )
}
