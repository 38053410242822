import { useBanner, useLifecycle } from '@shared/components'
import {
  GetPatientTaskResponse,
  PatientFormTaskType,
  PatientScheduleTaskType,
  PatientTaskType,
  PatientWorkflowTaskType,
  PutPatientTaskRequest,
  PutPatientTaskResponse,
  TaskFormStepType,
  TaskPayload,
  getOpheliaHttpError,
} from '@shared/types'
import { toTime } from '@shared/utils'
import merge from 'lodash/merge'
import { useCallback, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { patientApi } from '../../../common/api'
import { usePortalBreadcrumbs } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { DashboardState } from '../dashboardTypes'

const ONE_HUNDRED_PERCENT = 100
const STEP_QUERY_PARAM = 'step'

export type UsePatientTaskSubmit<T extends PatientTaskType> = (
  data: PutPatientTaskRequest<T>['data'],
) => Promise<PutPatientTaskResponse<T>>

export function usePatientTask<T extends PatientWorkflowTaskType | PatientScheduleTaskType>({
  type,
  breadcrumb,
  onSuccess,
}: {
  type: T
  breadcrumb: string
  onSuccess?: (data: GetPatientTaskResponse<T>) => void
}) {
  const navigate = useNavigate()
  usePortalBreadcrumbs({ type: 'override', text: breadcrumb })

  const { status, data } = useQuery<unknown, unknown, GetPatientTaskResponse<T>>(
    ...patientApi.getQuery('GET /task/:type', {
      params: { type },
    }),
    {
      cacheTime: toTime('0 sec').ms(),
      onSuccess: data => {
        onSuccess?.(data)
        if (data.type === 'redirect') {
          navigate(routes.portal.index, { replace: true })
        }
      },
    },
  )

  const savePatientTask = useMutation<
    PutPatientTaskResponse<PatientTaskType>,
    unknown,
    PutPatientTaskRequest<T>
  >(patientApi.getMutation('PUT /task/:type/:step'))

  const submit: UsePatientTaskSubmit<T> = data =>
    savePatientTask.mutateAsync({
      params: { type, step: '' as TaskFormStepType<T> },
      data,
    }) as Promise<PutPatientTaskResponse<T>>

  return {
    status: data?.type === 'redirect' ? 'loading' : status,
    data,
    submit,
    isSaving: savePatientTask.isLoading,
    response: savePatientTask.data,
  }
}

export function usePatientFormTask<T extends PatientFormTaskType>({
  type,
  totalSteps = 1,
  breadcrumb,
  stateOnComplete,
}: {
  type: T
  totalSteps?: number
  breadcrumb: string
  stateOnComplete?: DashboardState
}) {
  const [lastCompletedStep, setLastCompletedStep] = useState<number>(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const [payload, setPayload] = useState<TaskPayload<T>>()
  const { hideBanner, showBanner } = useBanner()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  usePortalBreadcrumbs({ type: 'override', text: breadcrumb })

  const currentStep = Number(searchParams.get(STEP_QUERY_PARAM))
  const isLastStep = currentStep === totalSteps

  useLifecycle({
    onMount: () => {
      const isCurrentStepInvalid = currentStep > lastCompletedStep || currentStep < 1
      if (isCurrentStepInvalid) {
        setSearchParams({ [STEP_QUERY_PARAM]: String(lastCompletedStep) }, { replace: true })
      }
    },
  })

  const { status, data } = useQuery<unknown, unknown, GetPatientTaskResponse<T>>(
    ...patientApi.getQuery('GET /task/:type', {
      params: { type },
    }),
    {
      onSuccess: data => {
        if (data.type === 'redirect') {
          navigate(routes.portal.index, { replace: true })
        } else {
          setPayload(data.data as TaskPayload<T>)
        }
      },
      /*
       * Never cache task data, tasks can only be completed once and always modify the data that
       * is fetched, also ensures data is latest if they return to the task after not completing.
       */
      cacheTime: toTime('0 sec').ms(),
    },
  )

  const savePatientTask = useMutation(patientApi.getMutation('PUT /task/:type/:step'))

  const submit = useCallback(
    async (step: TaskFormStepType<T>, data: Partial<TaskPayload<T>>) => {
      hideBanner()

      try {
        const updatedPayload = merge(payload, data)
        const { isTaskComplete, allRequiredTasksCompleted } = await savePatientTask.mutateAsync({
          params: { type, step },
          data: updatedPayload,
        })

        // Is last step saved successfully or task is complete after current step, redirect.
        if (isLastStep || isTaskComplete) {
          // Tasks could affect any data, invalidate all queries to ensure everything is up-to-date.
          void queryClient.invalidateQueries()
          navigate(routes.portal.index, {
            state: allRequiredTasksCompleted
              ? { showAnimation: 'all-tasks-complete' }
              : stateOnComplete,
          })
        } else {
          setPayload(updatedPayload)

          if (currentStep === lastCompletedStep) {
            setLastCompletedStep(value => value + 1)
          }

          setSearchParams({ step: String(currentStep + 1) })

          if (step === 'test-zoom') {
            void queryClient.invalidateQueries(['currentUserApi.retrieve'])
          }

          window.scrollTo(0, 0)
        }
      } catch (error) {
        showBanner({
          type: 'error',
          label: getOpheliaHttpError(error, 'Something went wrong, try again later'),
        })
      }
    },
    [
      hideBanner,
      payload,
      savePatientTask,
      type,
      isLastStep,
      queryClient,
      navigate,
      stateOnComplete,
      currentStep,
      lastCompletedStep,
      setSearchParams,
      showBanner,
    ],
  )

  return {
    type,
    payload,
    status: data?.type === 'redirect' ? 'loading' : status,
    isSaving: savePatientTask.isLoading,
    currentStep,
    submit,
    percentProgress: (currentStep / (totalSteps ?? 1)) * ONE_HUNDRED_PERCENT,
  }
}
