/*
 * Instead of specifying all the fields from the external invoice models in `ChargebeeInvoice` and `StripeInvoice` below,
 * we only specify the fields that we need for our code. We can always add / change fields by referencing the documents
 * linked here:
 * Chargebee Invoice model v2: https://apidocs.chargebee.com/docs/api/invoices?prod_cat_ver=2
 * Stripe Invoice model v2022-11-15: https://stripe.com/docs/api/invoices/object#invoice_object-status_transitions
 */

import { Appointment, DatabaseMetadata, Encounter, ISOString, Patient } from '.'

export type ChargebeeLineItem = {
  id: string
  amount: number
  description: string
  date_from: number
  date_to: number
}

export type ChargebeeLinkedPayment = {
  applied_amount: number
  txn_date: number
  txn_status: 'in_progress' | 'success' | 'voided' | 'failure' | 'timeout' | 'needs_attention'
}

export type ChargebeeCreditNote = {
  cn_total: number
  cn_date: number
  // NOTE: Only the `adjusted` cn_status means that the credit note was applied to the invoice
  cn_status: 'adjusted' | 'refunded' | 'voided' | 'refund_due'
}

export type ChargebeeInvoice = {
  id: string
  customer_id: string
  date: number
  due_date: number
  amount_paid: number
  amount_to_collect: number
  total: number
  status: 'paid' | 'posted' | 'payment_due' | 'not_paid' | 'voided' | 'pending'
  line_items: ChargebeeLineItem[]
  linked_payments: ChargebeeLinkedPayment[]
  adjustment_credit_notes: ChargebeeCreditNote[]
  paid_at: number
  voided_at?: number
  amount_due: number
}

export type StripeLineItem = {
  id: string
  amount: number
  description: string
  period: { start: number; end: number }
  price: {
    recurring: {
      interval: StripeSubscriptionInterval
      interval_count: number
    }
  } | null
}

export type StripeInvoice = {
  id: string
  created: number
  customer: string | { id: string } | null
  period_start: number
  period_end: number
  due_date: number | null
  amount_due: number
  amount_paid: number
  amount_remaining: number
  total: number
  subscription: string | { id: string } | null
  description: string | null
  payment_intent: string | { id: string } | null
  status: 'draft' | 'open' | 'paid' | 'uncollectible' | 'void' | 'deleted' | null
  lines: {
    data: StripeLineItem[]
  }
  status_transitions: {
    paid_at?: number
  }
  metadata?: {
    chargebee_invoice_id?: string
  }
}

export type StripeCreditNotes = {
  amount: number
  metadata?: {
    is_partial_payment?: 'yes' | 'no'
  }
  memo?: string
}

export const STRIPE_API_VERSIONS = Object.freeze({
  '2022-11-15': '2022-11-15',
})

export const CHARGEBEE_API_VERSIONS = Object.freeze({
  v2: 'v2',
})

export type InvoiceType = 'copay' | 'no-show' | 'subscription'
type InvoiceModelBase = {
  /**
   * Allow null for patient ID because there may be cases where backfill an invoice or create a new one
   * using a customer that does not map to a patient. These invoices are still valid and we need to just
   * find and fix the patient ID later.
   */
  patientId: Patient['oid'] | null
  /**
   * The ID of the invoice as given by the external platform.
   */
  externalId: string
  type?: InvoiceType | null
  appointmentId?: Appointment['oid']
  encounterId?: Encounter['oid']
  /**
   * The date on which we finalize the invoice
   */
  dueDate?: ISOString
  /**
   * @deprecated - use `stripePaymentIntents` instead
   */
  stripePaymentIntentId?: string
  /**
   * All paid invoices, regardless of platform, should have a at least one Stripe payment intent ID.
   * That is because Chargebee used Stripe as its payment gateway and the payment intents
   * are the only artifacts that were created in Stripe.
   */
  stripePaymentIntents?: string[]
  /**
   * @deprecated - this was a temporary field before we added the stripe invoice event queue
   */
  prevStripeEventUnix?: number
}

type InvoicePlatform = 'chargebee' | 'stripe'

export type InvoiceModel<T extends InvoicePlatform = InvoicePlatform> = InvoiceModelBase &
  (T extends 'chargebee'
    ? {
        platform: 'chargebee'
        rawExternalData?: ChargebeeInvoice
        apiVersion: keyof typeof CHARGEBEE_API_VERSIONS
      }
    : T extends 'stripe'
    ? {
        platform: 'stripe'
        rawExternalData?: StripeInvoice
        apiVersion: keyof typeof STRIPE_API_VERSIONS
      }
    : never)

export type Invoice<T extends InvoicePlatform = InvoicePlatform> = InvoiceModel<T> &
  DatabaseMetadata

export type DecoratedInvoiceLineItem = {
  id: string
  date: string
  description: string
  amount: number
}

export type InvoicePaymentStatus =
  | 'unpaid'
  | 'paid'
  | 'partiallyPaid'
  | 'pastDue'
  | 'writtenOff'
  | 'void'
  | 'deleted'

export type MappedInvoicePaymentStatus =
  | 'Unpaid'
  | 'Paid'
  | 'Partially paid'
  | 'Past due'
  | 'Written off'
  | 'Void'
  | 'Deleted'

export const InvoicePaymentStatusMapping: Record<InvoicePaymentStatus, MappedInvoicePaymentStatus> =
  {
    unpaid: 'Unpaid',
    paid: 'Paid',
    partiallyPaid: 'Partially paid',
    pastDue: 'Past due',
    writtenOff: 'Written off',
    void: 'Void',
    deleted: 'Deleted',
  }

export type InvoicePdfData = {
  description: string
  lineItems: DecoratedInvoiceLineItem[]
  invoiceDate: string
  dueDate: string | undefined
  amountPaid: number
  amountDue: number
  amountRemaining: number
  subscriptionInfo?: {
    start: string
    end: string
  } | null
  paymentStatus: InvoicePaymentStatus
  amountCredited?: number | undefined
  paymentDescriptions?: string[] | undefined
  appointmentType?: Appointment['type'] | undefined
  appointmentDate?: Appointment['datetime'] | undefined
  paymentDate?: string | undefined
}

export type DecoratedInvoice = Invoice & {
  invoicePdfData: InvoicePdfData
}

/*
 * This type is designed to be returned as a list item. It includes the basic data
 * clients need to display in the UI. Moreover, it formats the data in a common format
 * no matter what platform the invoice is originally from.
 * Most importantly, this type does not depend on any external API calls to be fulfilled!
 */
export type SubscriptionPeriod = {
  start: ISOString
  end: ISOString
}

export type InvoiceListItem = Invoice & {
  description: string
  paymentStatus: InvoicePaymentStatus
  invoiceDate: ISOString
  dueDate: ISOString
  amountDue: number
  amountDueInCents: number
  amountRemaining: number
  amountRemainingInCents: number
  totalInCents: number
  appointmentDate: Appointment['datetime'] | null
  appointmentType: Appointment['type'] | null
  paymentDate: ISOString | null
  subscriptionPeriod: SubscriptionPeriod | null
}

export type AggregateInvoiceInfo = {
  unpaidInvoices: InvoiceListItem[]
  paidInvoices: InvoiceListItem[]
  pastDueInvoices: InvoiceListItem[]
  totalBalanceDueInDollars: number
  pastBalanceDueInDollars: number
  overdueBalanceInDollars: number
  noShowBalanceInDollars: number
}

// Stripped down and formatted version of Stripe.Subscription
export type StripeSubscriptionStatus =
  | 'active'
  | 'canceled'
  | 'incomplete'
  | 'incomplete_expired'
  | 'past_due'
  | 'trialing'
  | 'unpaid'
  | 'paused'

export type StripeSubscriptionInterval = 'day' | 'week' | 'month' | 'year'
export type SubscriptionInterval = 'weekly' | 'biweekly' | 'monthly'
export type SubscriptionPauseCollectionBehavior = 'keep_as_draft' | 'void' | 'mark_uncollectible'

export type FormattedStripeSubscription = {
  id: string
  status: StripeSubscriptionStatus
  amountInDollars: number
  periodStart: string
  periodEnd: string
  nextDueDate: string
  /*
   * `interval` = billing frequency (ie. daily, weekly, monthly, annually)
   * `intervalCount` = number of intervals between billing
   * Ex. `interval` === 'week` && `intervalCount` === 2 = `biweekly`
   */
  interval: StripeSubscriptionInterval
  intervalCount: number
  // The `intervalString` is derived from the `interval` and `intervalCount`
  intervalString: SubscriptionInterval
  // Subscriptions that are not paused will have a `pauseBehavior` of `null`
  pauseBehavior: SubscriptionPauseCollectionBehavior | null
}

export type AppointmentDateAndType = {
  appointmentDate?: Appointment['datetime'] | undefined
  appointmentType?: Appointment['type'] | undefined
}

export type PaymentPlanFrequency = 'weekly' | 'biweekly' | 'monthly'

const STRIPE_INVOICE_EVENTS = ['invoice.created', 'invoice.deleted', 'invoice.updated'] as const

export type StripeInvoiceWebhookEventModel = {
  stripeEventId: string
  /*
   * The timestamp for the first event received for the given invoice.
   * Use first event timestamp so we know which events are at the front of the queue.
   */
  firstEventReceivedAtUnix: number
  lastEventReceivedAtUnix: number
  /*
   * A subset of the events we listen for in the Stripe dashboard.
   * See: https://dashboard.stripe.com/webhooks
   */
  stripeEventTypes: (typeof STRIPE_INVOICE_EVENTS)[number][]
  // The ID of the object for which the event occurred.
  stripeInvoiceId: string
}

export type StripeInvoiceWebhookEvent = StripeInvoiceWebhookEventModel & DatabaseMetadata

export type StripePayment = {
  paymentIntentId: string
  latestCharge: {
    chargeId: string
    amountInCents: number
    amountRefundedInCents: number
    createdAt: ISOString
    isPaid: boolean
    status: 'succeeded' | 'failed' | 'pending'
    paymentMethodDetails: {
      card: {
        brand: string
        last4: string
      }
    }
  } | null
}
