import { useForm } from '@mantine/form'
import { useTimeout } from '@mantine/hooks'
import {
  ChevronsLeftIcon,
  Drawer,
  Flex,
  PrimaryButton,
  Stack,
  Text,
  Tooltip,
  useBanner,
  validateWith,
} from '@shared/components'
import { dayjs, toTime } from '@shared/utils'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { WorkflowParams } from '../../common/components/prompt/WorkflowPrompt'
import { isAtleastOneWord, isRequired } from '../../common/forms'
import * as Fullstory from '../../common/fullstory'
import { useAuth, useFlags, usePortalDims, usePortalMutation } from '../../common/hooks'
import { routes } from '../../common/routes'
import { sendTrackEvent } from '../../common/rudderstack'
import { getSessionStorageItem } from '../../common/storage'
import { SkipOnboardingLocationStateType } from '../pages/SkipOnboardingWelcomeCallScheduled'
import { FirstNameInput } from './FirstNameInput'
import { YesNoRadio } from './YesNoRadio'

const FIRST_STEP = 0
export type SkipOnboardingForm = {
  lookingForInfo: 'yes' | 'no' | undefined
  haveTenMin: 'yes' | 'no' | undefined
  firstName: string
}

const steps = [
  {
    title: 'Are you looking for information about the treatment program?',
    subtitle: 'If you prefer, you can skip the questions and just chat with someone from our team.',
  },
  {
    title: 'Do you have 10 minutes right now?',
    subtitle: 'If so, we have someone available to give you a call to answer your questions.',
  },
  {
    title: "Share your name and we'll give you a call within 10 minutes",
    subtitle:
      'This is a free, informational call to answer your questions about the program. No cost, no commitment, no pressure.',
  },
] as const

export const SkipOnboardingDrawer = ({
  opened,
  onClose,
  setSkipOnboardingDrawerVisible,
  skipOnboardingButtonVisible,
}: {
  opened: boolean
  onClose: () => void
  setSkipOnboardingDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>
  skipOnboardingButtonVisible: boolean
}) => {
  const navigate = useNavigate()
  const { showBanner } = useBanner()
  const { isMobile } = usePortalDims()
  const [step, setStep] = useState(FIRST_STEP)
  const { callMeNowCta } = useFlags()
  const { sessionId } = useParams<WorkflowParams>()
  const { currentUser } = useAuth()
  const currentUserData = currentUser?.data

  useEffect(() => {
    if (opened) {
      sendTrackEvent('Skip Onboarding Drawer Open')
      Fullstory.event('Skip Onboarding Drawer Open')
    }
  }, [opened])

  const { start, clear } = useTimeout(() => {
    setStep(step + 1)
    clear()
  }, toTime('300 ms').ms())

  const skipOnboardingForm = useForm<SkipOnboardingForm>({
    initialValues: {
      lookingForInfo: undefined,
      haveTenMin: undefined,
      firstName: '',
    },
    validate: {
      firstName: validateWith(isRequired, isAtleastOneWord),
    },
  })

  const handleClose = () => {
    onClose()
    skipOnboardingForm.reset()
    setStep(FIRST_STEP)
  }

  const handleChange = (value: string) => {
    if (value === 'yes') {
      start()
    } else {
      handleClose()
    }
  }

  const createConsultationUrl =
    currentUserData?.statuses?.patient === 'discharged'
      ? 'POST /appointments/reenrollment'
      : 'POST /appointments/consultation'
  const createConsultation = usePortalMutation(createConsultationUrl)

  const handleSubmit = async () => {
    const validate = skipOnboardingForm.validate()

    if (validate.hasErrors) {
      return
    }

    if (!sessionId || !currentUserData) {
      return
    }

    await createConsultation.mutateAsync(
      {
        data: {
          firstName: skipOnboardingForm.values.firstName,
          timezone: dayjs.tz.guess(),
          workflowSessionId: sessionId,
          addressState:
            getSessionStorageItem('residence_state') || currentUserData.homeData?.state || '',
        },
      },
      {
        onError: () => {
          handleClose()
          showBanner({
            type: 'error',
            label: 'Unable to skip onboarding questionnaire at this time.',
            dismissable: true,
          })
        },
      },
    )

    handleClose()
    navigate(`${routes.onboarding.index}${routes.onboarding.children.welcome}`, {
      replace: true,
      state: {
        firstName: skipOnboardingForm.values.firstName,
      } as SkipOnboardingLocationStateType,
    })
  }

  if (isMobile) {
    return (
      <Drawer
        style={{
          overflowY: 'scroll',
        }}
        opened={callMeNowCta && opened}
        onClose={handleClose}
        size='sm'
        position={isMobile ? 'bottom' : 'right'}
        title={steps[step]?.title}
        trapFocus={step < 2 && false}
      >
        <Stack spacing='md' m='md'>
          <Text>{steps[step]?.subtitle}</Text>
          {step === 0 && (
            <YesNoRadio form={skipOnboardingForm} onChange={handleChange} path='lookingForInfo' />
          )}
          {step === 1 && (
            <YesNoRadio form={skipOnboardingForm} onChange={handleChange} path='haveTenMin' />
          )}
          {step === 2 && (
            <>
              <FirstNameInput
                form={skipOnboardingForm}
                path='firstName'
                disabled={createConsultation.isLoading}
              />
              <PrimaryButton
                fullWidth
                disabled={createConsultation.isLoading}
                loading={createConsultation.isLoading}
                onClick={handleSubmit}
              >
                Request a call now
              </PrimaryButton>
            </>
          )}
        </Stack>
      </Drawer>
    )
  }
  return (
    <>
      <Drawer
        opened={callMeNowCta && opened}
        onClose={handleClose}
        size='sm'
        position={isMobile ? 'bottom' : 'right'}
        title={steps[step]?.title}
        trapFocus={step < 2 && false}
      >
        <Stack spacing='md' m='md'>
          <Text>{steps[step]?.subtitle}</Text>
          {step === 0 && (
            <YesNoRadio form={skipOnboardingForm} onChange={handleChange} path='lookingForInfo' />
          )}
          {step === 1 && (
            <YesNoRadio form={skipOnboardingForm} onChange={handleChange} path='haveTenMin' />
          )}
          {step === 2 && (
            <>
              <FirstNameInput
                disabled={createConsultation.isLoading}
                form={skipOnboardingForm}
                path='firstName'
              />
              <PrimaryButton
                disabled={createConsultation.isLoading}
                loading={createConsultation.isLoading}
                onClick={handleSubmit}
              >
                Request a call now
              </PrimaryButton>
            </>
          )}
        </Stack>
      </Drawer>
      {callMeNowCta && skipOnboardingButtonVisible && (
        <Tooltip native label='Request a call now'>
          <PrimaryButton
            disabled={createConsultation.isLoading}
            loading={createConsultation.isLoading}
            radius='xl'
            leftIcon={
              <Flex
                sx={({ other: { sizes }, spacing }) => ({
                  paddingRight: spacing.md,
                  paddingLeft: sizes.gap.lg,
                })}
              >
                <ChevronsLeftIcon />
              </Flex>
            }
            onClick={() => setSkipOnboardingDrawerVisible(true)}
            sx={({ spacing }) => ({
              zIndex: 10,
              position: 'absolute',
              // Must equal padding added to the sidebar + padding added to the collapse button
              bottom: spacing.lg,
              right: 0,
              display: opened ? 'none' : 'flex',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              transition: 'all 0.4s ease',
              ':hover': { paddingRight: spacing.md },
            })}
          />
        </Tooltip>
      )}
    </>
  )
}
