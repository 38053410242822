import { MyLunaApi, OpheliaHttpError } from '@shared/types'
import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { myApi } from '../api'

export const useMyMutation = <T extends keyof MyLunaApi>(
  route: T,
  options?: Omit<
    UseMutationOptions<
      MyLunaApi[T]['res'],
      AxiosError<OpheliaHttpError>,
      Partial<MyLunaApi[T]['req']>
    >,
    'mutationFn'
  >,
) => {
  return useMutation<
    MyLunaApi[T]['res'],
    AxiosError<OpheliaHttpError>,
    Partial<MyLunaApi[T]['req']>
  >(myApi.getMutation(route), options)
}
