import { Divider, Stack, TitleTwo, useBanner } from '@shared/components'
import React, { useEffect } from 'react'
import { useFlags, usePortalQuery } from '../../../common/hooks'
import { BalanceCard } from './BalanceCard'
import { BalanceCardSkeleton } from './BalanceCardSkeleton'
import { InvoiceCard } from './InvoiceCard'
import { PaymentPlanSignup } from './PaymentPlanSignup'
import { StripePaymentCardSection } from './StripePaymentCardSection'
import { usePaymentPlan } from './use-payment-plan'

export const Billing = () => {
  const { showBanner } = useBanner()
  const { paymentPlanSignup: paymentPlanSignupFlag } = useFlags()

  const { data, isLoading } = usePortalQuery('GET /invoices')

  const { aggregateInvoiceInfo } = data || {}

  const {
    paidInvoices,
    unpaidInvoices,
    pastDueInvoices,
    totalBalanceDueInDollars,
    pastBalanceDueInDollars,
  } = aggregateInvoiceInfo || {
    paidInvoices: [],
    unpaidInvoices: [],
    pastDueInvoices: [],
    totalBalanceDueInDollars: 0,
    pastBalanceDueInDollars: 0,
  }

  const { isEligibleForPaymentPlan } = usePaymentPlan({ pastBalanceDueInDollars })

  useEffect(() => {
    if (pastBalanceDueInDollars > 0) {
      showBanner({
        type: 'warning',
        label: "You're behind on payments",
      })
    }
  }, [pastBalanceDueInDollars])

  return (
    <Stack test-id='page:billing' spacing='lg'>
      <TitleTwo>Billing & invoices</TitleTwo>
      {isLoading ? (
        <BalanceCardSkeleton />
      ) : (
        <BalanceCard
          pastDueInvoices={pastDueInvoices}
          unpaidInvoices={unpaidInvoices}
          totalBalanceDueInDollars={totalBalanceDueInDollars}
          pastBalanceDueInDollars={pastBalanceDueInDollars}
        />
      )}
      {isEligibleForPaymentPlan && paymentPlanSignupFlag && (
        <PaymentPlanSignup pastBalanceDueInDollars={pastBalanceDueInDollars} />
      )}
      <Divider my='md' />
      <StripePaymentCardSection />
      <Divider my='md' />
      <Stack>
        <TitleTwo>Unpaid Invoices</TitleTwo>
        {unpaidInvoices.map(unpaidInvoice => {
          return <InvoiceCard key={unpaidInvoice.oid} invoice={unpaidInvoice} />
        })}
      </Stack>
      <Divider my='md' />
      <Stack>
        <TitleTwo>Past Invoices</TitleTwo>
        {paidInvoices.map(paidInvoice => {
          return <InvoiceCard key={paidInvoice.oid} invoice={paidInvoice} />
        })}
      </Stack>
    </Stack>
  )
}
