import { UseFormReturnType } from '@mantine/form'
import { Group, PrimaryButton, ResponsiveBox, Stack, useLifecycle } from '@shared/components'
import { InsurancePlanId } from '@shared/types'
import { getPayersShortList } from '@shared/utils'
import React from 'react'
import { InsuranceCoverageForm } from '.'
import { InsuranceSelect } from '../common/components'
import { InsuranceSelectItem } from '../common/components/InsuranceSelectItem'
import * as FullStory from '../common/fullstory'
import { sendTrackEvent } from '../common/rudderstack'

type InsuranceSelectProps = {
  form: UseFormReturnType<
    InsuranceCoverageForm,
    (values: InsuranceCoverageForm) => InsuranceCoverageForm
  >
  handleChange: (value: InsurancePlanId) => void
  selectedPayer: string
  isLoading: boolean
  handleClickNext: () => void
}
export const InsuranceSelectStep = ({
  form,
  handleChange,
  selectedPayer,
  isLoading,
  handleClickNext,
}: InsuranceSelectProps) => {
  useLifecycle({
    onMount: () => {
      sendTrackEvent('Insurance Select Step Visible')
      FullStory.event('Insurance Select Step Visible')
    },
  })

  return (
    <ResponsiveBox
      desktop={
        <Stack spacing='lg'>
          <InsuranceSelect
            {...form.getInputProps('payer')}
            onChange={handleChange}
            value={selectedPayer}
            placeholder='Type to find your plan...'
            rightSection={<> </>}
            searchable
            clearable
            itemComponent={InsuranceSelectItem}
            data={getPayersShortList(undefined, true, true)}
          />
          <Group position='right'>
            <PrimaryButton
              disabled={isLoading}
              loading={isLoading}
              onClick={() => handleClickNext()}
            >
              Next
            </PrimaryButton>
          </Group>
        </Stack>
      }
      mobile={
        <Stack spacing='lg'>
          <InsuranceSelect
            {...form.getInputProps('payer')}
            onChange={handleChange}
            value={selectedPayer}
            placeholder='Type to find your plan...'
            rightSection={<> </>}
            searchable
            clearable
            itemComponent={InsuranceSelectItem}
            data={getPayersShortList(undefined, true, true)}
          />
          <PrimaryButton
            fullWidth
            disabled={isLoading}
            loading={isLoading}
            onClick={() => handleClickNext()}
          >
            Next
          </PrimaryButton>
        </Stack>
      }
    />
  )
}
