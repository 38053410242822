import { z } from 'zod'
import { ApiSchemas } from '../../../utils'

const GetInsurancePayersReqResSchemas = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        payerId: z.string(),
        name: z.string(),
      }),
    ),
  }),
}

export const MyInsurancePayersApi = {
  'GET /insurance-payers': GetInsurancePayersReqResSchemas,
} satisfies ApiSchemas
