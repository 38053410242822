import { Center, MyOpheliaWordmark, PrimaryButton, Stack, Text, TitleTwo } from '@shared/components'
import React from 'react'
import { routes } from '../routes'

type FallbackProps = {
  boundary?: keyof typeof routes | undefined
}

export const Fallback = ({ boundary }: FallbackProps) => {
  return (
    <Center sx={theme => ({ minHeight: '100vh', color: theme.white })}>
      <FallbackContent boundary={boundary} />
    </Center>
  )
}

export const FallbackContent = ({ boundary }: FallbackProps) => {
  const onReload = () => {
    const reloadTarget = boundary ? routes[boundary].index : '/'
    window.location.assign(reloadTarget)
  }

  return (
    <Stack spacing='lg' p='lg'>
      <Center sx={theme => ({ color: theme.primaryColor, height: theme.spacing.xl })}>
        <MyOpheliaWordmark />
      </Center>
      <Center>
        <TitleTwo>Oops, something went wrong.</TitleTwo>
      </Center>
      <Center>
        <Text>
          We are working on resolving this as quickly as possible. Thank you for your patience.
        </Text>
      </Center>
      <Center>
        <PrimaryButton onClick={onReload}>Reload page</PrimaryButton>
      </Center>
    </Stack>
  )
}
