import { Box, Grid, LinkText, Pill, Space, Text } from '@shared/components'
import { ConsentModel } from '@shared/types'
import { dayjs } from '@shared/utils'
import React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../common/routes'

export type ConsentEntryProps = {
  date?: string
  signed: boolean
  type: ConsentModel['type']
  version: number
}
const ConsentEntryPill = ({ signed }: { signed: boolean }) => {
  if (signed) {
    return <Pill status='success'>Signed</Pill>
  }

  return <Pill status='warning'>Not signed</Pill>
}

export const ConsentEntry = ({ date, type, version, signed }: ConsentEntryProps) => {
  const {
    index: documents,
    children: { financialAgreement, treatmentConsent, tpo },
  } = routes.portal.children.documents

  const getConsentPath = () => {
    switch (type) {
      case 'financial':
        return financialAgreement.replace(':version', version.toString())
      case 'treatment':
        return treatmentConsent
      case 'tpo':
        return tpo
    }
  }

  const getConsentName = () => {
    switch (type) {
      case 'financial':
        return 'Financial agreement'
      case 'treatment':
        return 'Treatment consent'
      case 'tpo':
        return 'TPO consent'
    }
  }

  const consentPath = getConsentPath()

  return (
    <Box
      sx={theme => ({
        borderWidth: theme.other.sizes.border.md,
        borderStyle: 'solid',
        borderColor: theme.colors.gray[7],
        borderRadius: theme.radius.sm,
        padding: theme.spacing.md,
      })}
    >
      <Text bold>{getConsentName()}</Text>
      <Space h='md' />
      <Grid>
        <Grid.Col span={12}>
          {signed && (
            <>
              <Text>{`Signed on ${dayjs(date).format('MMM D YYYY').toUpperCase()}`}</Text>
              <LinkText
                component={Link}
                to={{ pathname: `${routes.portal.index}/${documents}${consentPath}` }}
              >
                Review document
              </LinkText>
            </>
          )}
        </Grid.Col>
        <Grid.Col span={12}>
          <ConsentEntryPill signed={signed} />
        </Grid.Col>
      </Grid>
    </Box>
  )
}
