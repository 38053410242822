import { useForm } from '@mantine/form'
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  Text,
  TitleTwo,
  isAnySelected,
} from '@shared/components'
import { PromptResponseContextKey, ValueOf } from '@shared/types'
import React from 'react'
import { WidgetProps } from '../../PromptContentWidget'

type PastOpioidUseDisorderTreatmentForm = {
  options: PromptResponseContextKey[]
}

const pastOpioidUseDisorderTreatmentOptions = {
  past_opioid_use_treatment_no: 'past_opioid_use_treatment_no',
  past_opioid_use_treatment_yes_without_medication:
    'past_opioid_use_treatment_yes_without_medication',
  past_opioid_use_treatment_yes_with_buprenorphine:
    'past_opioid_use_treatment_yes_with_buprenorphine',
  past_opioid_use_treatment_yes_with_methadone: 'past_opioid_use_treatment_yes_with_methadone',
  past_opioid_use_treatment_yes_with_naltrexone: 'past_opioid_use_treatment_yes_with_naltrexone',
}

export type PastOpioidUseDisorderTreatmentOptions = ValueOf<
  typeof pastOpioidUseDisorderTreatmentOptions
>

export const PastOpioidUseDisorderTreatment = ({ onSubmit }: WidgetProps) => {
  const pastOpioidUseDisorderTreatmentForm = useForm<PastOpioidUseDisorderTreatmentForm>({
    initialValues: {
      options: [],
    },
    validate: {
      options: isAnySelected(Object.values(pastOpioidUseDisorderTreatmentOptions), 'Required'),
    },
  })

  const disableAllOtherOptions = () =>
    pastOpioidUseDisorderTreatmentForm.values.options.includes('past_opioid_use_treatment_no')

  const submitHandler = () => {
    const { hasErrors } = pastOpioidUseDisorderTreatmentForm.validate()

    if (hasErrors) {
      return
    }

    const answersArray = pastOpioidUseDisorderTreatmentForm.values.options.filter(option => {
      if (
        pastOpioidUseDisorderTreatmentForm.values.options.includes('past_opioid_use_treatment_no')
      ) {
        return option === 'past_opioid_use_treatment_no'
      }
      return true
    })

    onSubmit({
      value: {
        contextKey: answersArray,
      },
      past_opioid_use_treatment: {
        value: 'custom',
        contextKey: answersArray,
      },
    })
  }

  return (
    <Stack spacing='lg'>
      <Stack spacing='xs'>
        <TitleTwo>Have you ever been treated for opioid use disorder?</TitleTwo>
        <Text>Check all that apply.</Text>
      </Stack>
      <Stack spacing='md'>
        <Stack spacing='md'>
          <Box test-id='content:multiple-choice'>
            <CheckboxGroup {...pastOpioidUseDisorderTreatmentForm.getInputProps('options')}>
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={
                  pastOpioidUseDisorderTreatmentOptions.past_opioid_use_treatment_yes_without_medication
                }
                label='Yes, without medication (e.g. inpatient detox, rehab, intensive outpatient program)'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={
                  pastOpioidUseDisorderTreatmentOptions.past_opioid_use_treatment_yes_with_buprenorphine
                }
                label='Yes, with buprenorphine (e.g. Suboxone, Subutex, Sublocade) prescribed by a medical professional'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={
                  pastOpioidUseDisorderTreatmentOptions.past_opioid_use_treatment_yes_with_methadone
                }
                label='Yes, with methadone prescribed by a medical professional'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={
                  pastOpioidUseDisorderTreatmentOptions.past_opioid_use_treatment_yes_with_naltrexone
                }
                label='Yes, with naltrexone (e.g. Vivitrol) prescribed by a medical professional'
              />

              <Checkbox
                value={pastOpioidUseDisorderTreatmentOptions.past_opioid_use_treatment_no}
                label='No'
              />
            </CheckboxGroup>
            <ResponsiveBox
              mt='lg'
              mobile={
                <Stack spacing='lg' align='center'>
                  <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
                    Next
                  </PrimaryButton>
                </Stack>
              }
              desktop={
                <Group position='apart'>
                  <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                    Next
                  </PrimaryButton>
                </Group>
              }
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
