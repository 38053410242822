import { ReferralEvent, ReferralEventTrigger } from '@shared/types'
import { BaseApi } from '@shared/utils'
import { analytics } from '../rudderstack'

export class ReferralsApi extends BaseApi {
  private _generateEvent = ({ trigger }: { trigger: ReferralEventTrigger }): ReferralEvent => ({
    triggeredBy: trigger,
    datetime: new Date().toISOString(),
    anonymousId: analytics.getAnonymousId(),
    context: {},
  })

  createReferralForSessionIfNotExists = (arg: {
    sessionId: string
    referralPartnerId?: string | undefined
  }) => {
    const anonymousId = analytics.getAnonymousId()
    return this.json<{
      referralLink: string
      referralId: string
    }>({
      method: 'POST',
      url: `/referral/session/${arg.sessionId}`,
      data: { anonymousId, referralPartnerId: arg.referralPartnerId },
    })
  }

  addReferralClickEvent = (referralId: string) => {
    const referralEvent: ReferralEvent = this._generateEvent({ trigger: 'link_clicked' })

    return this.json<void>({
      method: 'POST',
      url: `/referral/history/${referralId}`,
      data: { referralEvent },
    })
  }

  addPatientToReferral = (referralId: string) => {
    const referralEvent: ReferralEvent = this._generateEvent({ trigger: 'new_patient_associated' })

    return this.json<void>({
      method: 'PUT',
      url: `/referral/${referralId}`,
      data: { referralEvent },
    })
  }
}
