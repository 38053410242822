import { useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  Grid,
  PhoneInput,
  TextInput,
  validateWith,
} from '@shared/components'
import { validCharacters } from '@shared/utils'
import React from 'react'
import { isFullName, isPhone, isRequired, isAtleastOneWord } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const EmergencyContactForm = ({
  initialValues,
  submit,
  saving,
}: TaskStepProps<'emergency-contact'>) => {
  const { validate, values, getInputProps } = useForm({
    initialValues,
    validate: {
      fullName: validateWith(isFullName),
      relationship: validateWith(isRequired, isAtleastOneWord),
      phoneNumber: validateWith(isRequired, isPhone),
    },
  })

  function onSubmit() {
    if (!validate().hasErrors) {
      submit('', values)
    }
  }

  return (
    <Grid>
      <Grid.Col span={12}>
        <TextInput
          label='Full name'
          test-id='input:full-name'
          placeholder='Full name'
          maxLength={64}
          disabled={saving}
          formatter={validCharacters.name}
          {...getInputProps('fullName')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Relationship'
          test-id='input:relationship'
          placeholder='Parent, partner, friend...'
          maxLength={64}
          disabled={saving}
          formatter={validCharacters.name}
          {...getInputProps('relationship')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <PhoneInput
          label='Phone number'
          test-id='input:phone-number'
          disabled={saving}
          {...getInputProps('phoneNumber')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <CheckboxGroup
          label='In addition to a medical emergency as determined by my treating clinician, I give Ophelia Health, Inc. permission to to contact the person listed above in case of (check all that apply):'
          test-id='checkbox-group:reasons'
          {...getInputProps('reasoning')}
        >
          <Checkbox
            value='no_contact_24_hours'
            label='If my care team cannot contact me within 24 hours'
            disabled={saving}
          />
          <Checkbox
            value='no_contact_48_hours'
            label='If my care team cannot contact me within 48 hours'
            disabled={saving}
          />
          <Checkbox
            value='as_determined'
            label='In any emergency or urgent situation as determined by my care team'
            disabled={saving}
          />
        </CheckboxGroup>
      </Grid.Col>
      <Grid.Col span={12}>
        <TaskButton onSubmit={onSubmit} loading={saving} isLastStep />
      </Grid.Col>
    </Grid>
  )
}
