import { useForm } from '@mantine/form'
import {
  Accordion,
  ArrowRightIcon,
  Grid,
  Group,
  Pill,
  PlusIcon,
  PrimaryButton,
  SelectItem,
  SmartphoneIcon,
  Stack,
  TertiaryButton,
  Text,
  TitleFour,
  TrashIcon,
} from '@shared/components'
import {
  GetPatientInsuranceDataResponse,
  InsurancePlanId,
  PatientInsurance,
  PatientProvidedInsuranceData,
} from '@shared/types'
import { getInsurancePlanId, getPayerIdAndProvider, toTime } from '@shared/utils'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { insuranceApi } from '../../../common/api'
import {
  FormHeader,
  FormLabel,
  FormSaveSection,
  Photo,
  Skeletons,
} from '../../../common/components'
import {
  InsuranceInput,
  InsuranceInputFieldMap,
  makeFormInsuranceRules,
} from '../../../common/components/InsuranceInput'
import { useMyQuery } from '../../../common/hooks'

/**
 * Discard provider and eligiblePayerId as they are used as a single value.
 */
type FormInsuranceInfo = Omit<PatientInsurance, 'provider' | 'eligiblePayerId'> & {
  insurancePlan: InsurancePlanId
}

const insuranceInputFieldMap: InsuranceInputFieldMap<FormInsuranceInfo> = {
  plan: 'insurancePlan',
  firstName: 'cardholderFirstName',
  lastName: 'cardholderLastName',
  birthday: 'cardholderBirthday',
  memberId: 'memberId',
  groupId: 'groupId',
  rxBin: 'rxBin',
  isPrimarySubscriber: 'patientIsPrimarySubscriber',
  subscriberFirstName: 'primarySubscriberFirstName',
  subscriberLastName: 'primarySubscriberLastName',
  subscriberBirthday: 'primarySubscriberBirthday',
  cardFront: 'insuranceCardFront',
  cardBack: 'insuranceCardBack',
}

export const InsuranceCardSection = ({
  editing,
  toggleEditing,
  openSection,
}: {
  editing: boolean
  toggleEditing: () => void
  openSection: () => void
}) => {
  const [hasSecondaryInsurance, setHasSecondaryInsurance] = useState(false)

  const insuranceDataQuery = useQuery(
    ['insuranceApi.getPatientInsuranceData'],
    () => {
      return insuranceApi.getPatientInsuranceData()
    },
    {
      onSuccess: insuranceData =>
        setHasSecondaryInsurance(Boolean(insuranceData.data?.secondaryInsuranceInfo?.memberId)),
    },
  )

  const status = insuranceDataQuery.data?.status

  const hasInsurance = insuranceDataQuery.data?.hasInsurance ?? false

  if (!editing && !hasInsurance && !insuranceDataQuery.isLoading) {
    return (
      <Stack spacing='lg' test-id='section:out-of-pocket'>
        <FormHeader title='Insurance' />
        <Stack>
          <Text>
            You’re currently paying $195/month, because we don’t have any insurance on file for you.
          </Text>
          <TertiaryButton
            test-id='button:add-insurance'
            onClick={() => {
              toggleEditing()
              openSection()
            }}
            rightIcon={<ArrowRightIcon />}
          >
            Add health insurance
          </TertiaryButton>
        </Stack>
      </Stack>
    )
  }

  if (editing) {
    return (
      <>
        <Accordion.Control test-id='accordion-item:insurance'>
          <Group position='left'>
            <Text bold transform='uppercase'>
              Insurance
            </Text>
            <InsuranceStatus status={status ?? 'none'} editing={editing} />
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <InsuranceCardSectionEdit
            insuranceData={insuranceDataQuery.data?.data}
            toggleEditing={toggleEditing}
            hasSecondaryInsurance={hasSecondaryInsurance}
            setHasSecondaryInsurance={setHasSecondaryInsurance}
          />
        </Accordion.Panel>
      </>
    )
  }

  return (
    <>
      <Accordion.Control test-id='accordion-item:insurance'>
        <Group position='left'>
          <Text bold transform='uppercase'>
            Insurance
          </Text>
          <InsuranceStatus status={status ?? 'none'} editing={editing} />
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Stack spacing='sm' test-id='section:insurance'>
          <TitleFour>Insurance</TitleFour>
          <InsuranceCardSectionBody
            status={status ?? 'none'}
            insurance={insuranceDataQuery.data?.data?.insuranceInfo}
            isLoading={insuranceDataQuery.isLoading}
            isLoadingError={insuranceDataQuery.isLoadingError}
          />
          {insuranceDataQuery.data?.data?.secondaryInsuranceInfo?.memberId && (
            <>
              <TitleFour>Other insurance</TitleFour>
              <InsuranceCardSectionBody
                status={status ?? 'none'}
                insurance={insuranceDataQuery.data?.data?.secondaryInsuranceInfo}
                isLoading={insuranceDataQuery.isLoading}
                isLoadingError={insuranceDataQuery.isLoadingError}
              />
            </>
          )}
          <Stack>
            {!hasSecondaryInsurance && (
              <TertiaryButton
                mt='md'
                onClick={() => {
                  toggleEditing()
                  setHasSecondaryInsurance(true)
                }}
                leftIcon={<PlusIcon />}
              >
                Other insurance
              </TertiaryButton>
            )}
            <PrimaryButton test-id='button:edit' mt='md' onClick={() => toggleEditing()}>
              Edit
            </PrimaryButton>
          </Stack>
        </Stack>
      </Accordion.Panel>
    </>
  )
}

const toPatientInsurance = (insuranceInfo: FormInsuranceInfo): PatientInsurance => {
  const { insurancePlan, ...rest } = insuranceInfo
  const { eligiblePayerId, provider } = getPayerIdAndProvider(insurancePlan)
  return { ...rest, eligiblePayerId, provider }
}

const InsuranceCardSectionBody = ({
  status,
  insurance,
  isLoading,
  isLoadingError,
}: {
  status: GetPatientInsuranceDataResponse['status']
  insurance: PatientInsurance | undefined
  isLoadingError: boolean
  isLoading: boolean
}) => {
  const {
    provider,
    memberId,
    groupId,
    rxBin,
    cardholderFirstName,
    cardholderLastName,
    cardholderBirthday,
    insuranceCardFront,
    insuranceCardBack,
  } = insurance ?? ({} as PatientInsurance)

  if (isLoadingError || isLoading) {
    return <Skeletons type='data' />
  }

  return (
    <Grid>
      {status === 'in-review' && (
        <Grid.Col span={12}>
          <Group>
            <SmartphoneIcon />
            <Text bold>
              We&apos;ll send you a text once we&apos;ve reviewed your health insurance information.
            </Text>
          </Group>
        </Grid.Col>
      )}
      <Grid.Col span={12} sm={6}>
        <Stack>
          <FormLabel title='Insurance plan'>{provider}</FormLabel>
          <FormLabel title='Cardholder name'>
            {`${cardholderFirstName ?? ''} ${cardholderLastName ?? ''}`.trim()}
          </FormLabel>
          <FormLabel title='Cardholder birthday'>{cardholderBirthday}</FormLabel>
        </Stack>
      </Grid.Col>
      <Grid.Col span={12} sm={6}>
        <Stack>
          <FormLabel title='Member ID'>{memberId}</FormLabel>
          <FormLabel title='Group ID'>{groupId}</FormLabel>
          <FormLabel title='Rx Bin'>{rxBin}</FormLabel>
        </Stack>
      </Grid.Col>
      <Grid.Col span={12} sm={4}>
        <Photo value={insuranceCardFront} label='Insurance card front' />
      </Grid.Col>
      <Grid.Col span={12} sm={4}>
        <Photo value={insuranceCardBack} label='Insurance card back' />
      </Grid.Col>
    </Grid>
  )
}

const InsuranceStatus = ({
  editing,
  status,
}: {
  editing: boolean
  status: GetPatientInsuranceDataResponse['status']
}) => {
  if (editing || status === 'none') {
    return null
  }

  switch (status) {
    case 'approved':
      return <Pill status='success'>Approved</Pill>
    case 'in-review':
      return <Pill status='none'>Under review</Pill>
    case 'rejected':
      return <Pill status='error'>Rejected</Pill>
  }
}

function getPatientInsuranceFormValues(
  insuranceInfo: PatientInsurance | undefined,
): FormInsuranceInfo {
  if (!insuranceInfo) {
    return createEmptyInsuranceInfo()
  }

  return {
    insurancePlan: getInsurancePlanId(insuranceInfo.eligiblePayerId, insuranceInfo.provider),
    cardholderFirstName: insuranceInfo.cardholderFirstName,
    cardholderLastName: insuranceInfo.cardholderLastName,
    cardholderBirthday: insuranceInfo.cardholderBirthday,
    patientIsPrimarySubscriber: insuranceInfo.patientIsPrimarySubscriber,
    primarySubscriberFirstName: insuranceInfo.primarySubscriberFirstName,
    primarySubscriberLastName: insuranceInfo.primarySubscriberLastName,
    primarySubscriberBirthday: insuranceInfo.primarySubscriberBirthday,
    memberId: insuranceInfo.memberId,
    groupId: insuranceInfo.groupId,
    rxBin: insuranceInfo.rxBin,
    insuranceCardFront: insuranceInfo.insuranceCardFront,
    insuranceCardBack: insuranceInfo.insuranceCardBack,
  }
}

function createEmptyInsuranceInfo(): FormInsuranceInfo {
  return {
    insurancePlan: '',
    cardholderFirstName: '',
    cardholderLastName: '',
    cardholderBirthday: '',
    patientIsPrimarySubscriber: false,
    primarySubscriberFirstName: '',
    primarySubscriberLastName: '',
    primarySubscriberBirthday: '',
    memberId: '',
    groupId: '',
    rxBin: '',
    insuranceCardFront: undefined,
    insuranceCardBack: undefined,
  }
}

const InsuranceCardSectionEdit = ({
  insuranceData,
  toggleEditing,
  hasSecondaryInsurance,
  setHasSecondaryInsurance,
}: {
  insuranceData: PatientProvidedInsuranceData | undefined
  toggleEditing: () => void
  hasSecondaryInsurance: boolean
  setHasSecondaryInsurance: (hasSecondaryInsurance: boolean) => void
}) => {
  const queryClient = useQueryClient()
  const updatePatientInsuranceData = useMutation(insuranceApi.updatePatientInsuranceData)

  const insurancePayers = useMyQuery('GET /insurance-payers', {
    staleTime: toTime('5 min').ms(),
  })

  const insurances = useMemo(
    () =>
      (insurancePayers.data?.data ?? []).map<SelectItem>(({ payerId, name }) => ({
        label: name,
        value: getInsurancePlanId(payerId, name),
      })),
    [insurancePayers.data],
  )

  const primaryInsuranceForm = useForm<FormInsuranceInfo>({
    initialValues: getPatientInsuranceFormValues(insuranceData?.insuranceInfo),
    validateInputOnBlur: ['cardholderBirthday', 'memberId'],
    validate: makeFormInsuranceRules(insuranceInputFieldMap, { insurances, withCards: true }),
  })

  const secondaryInsuranceForm = useForm<FormInsuranceInfo>({
    initialValues: getPatientInsuranceFormValues(insuranceData?.secondaryInsuranceInfo),
    validateInputOnBlur: ['cardholderBirthday', 'memberId'],
    validate: makeFormInsuranceRules(insuranceInputFieldMap, {
      insurances,
      ignore: !hasSecondaryInsurance,
      withCards: true,
    }),
  })

  if (insurancePayers.isError || insurancePayers.isLoading) {
    return <Skeletons type='form' />
  }

  const onSubmit = () => {
    if (
      !primaryInsuranceForm.validate().hasErrors &&
      !secondaryInsuranceForm.validate().hasErrors
    ) {
      updatePatientInsuranceData.mutate(
        {
          insuranceInfo: toPatientInsurance(primaryInsuranceForm.values),
          secondaryInsuranceInfo: hasSecondaryInsurance
            ? toPatientInsurance(secondaryInsuranceForm.values)
            : undefined,
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries('insuranceApi.getPatientInsuranceData')
            toggleEditing()
          },
        },
      )
    }
  }

  const saving = updatePatientInsuranceData.isLoading

  return (
    <Grid test-id='section:insurance-edit'>
      <Grid.Col span={12}>
        <TitleFour>Insurance</TitleFour>
      </Grid.Col>
      <InsuranceInput
        test-id='primary-insurance'
        form={primaryInsuranceForm}
        insurances={insurances}
        saving={saving}
        map={insuranceInputFieldMap}
        withCards
      />
      {!hasSecondaryInsurance && (
        <TertiaryButton
          mt='md'
          onClick={() => {
            setHasSecondaryInsurance(true)
            secondaryInsuranceForm.setValues(createEmptyInsuranceInfo())
          }}
          leftIcon={<PlusIcon />}
        >
          Other insurance
        </TertiaryButton>
      )}
      {hasSecondaryInsurance && (
        <>
          <Grid.Col span={12}>
            <Group position='apart'>
              <TitleFour>Other insurance</TitleFour>
              <TertiaryButton
                rightIcon={<TrashIcon />}
                onClick={() => setHasSecondaryInsurance(false)}
              >
                Remove
              </TertiaryButton>
            </Group>
          </Grid.Col>
          <InsuranceInput
            form={secondaryInsuranceForm}
            insurances={insurances}
            saving={saving}
            map={insuranceInputFieldMap}
            withCards
          />
        </>
      )}
      <Grid.Col span={12}>
        <FormSaveSection
          test-id='insurance'
          loading={saving}
          onCancel={() => {
            toggleEditing()
            setHasSecondaryInsurance(Boolean(insuranceData?.secondaryInsuranceInfo?.memberId))
          }}
          onSubmit={onSubmit}
        />
      </Grid.Col>
    </Grid>
  )
}
