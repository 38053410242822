import {
  Box,
  DaysSelector,
  Divider,
  Group,
  InputWrapper,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { Availability, DAYS, DayOfWeek, TimeOfDay } from '@shared/types'

import React from 'react'

const AvailabilitySelector = ({
  disabled,
  value,
  error,
  onChange,
}: {
  disabled: boolean
  value: Availability[]
  error?: string
  onChange: (availabilities: Availability[]) => void
}) => {
  const timesInfo: { time: TimeOfDay; timeLabel: string; timeRange: string }[] = [
    { time: 'morning', timeLabel: 'MORNINGS', timeRange: '8am-Noon' },
    { time: 'afternoon', timeLabel: 'AFTERNOONS', timeRange: 'Noon-6pm' },
    { time: 'evening', timeLabel: 'EVENINGS', timeRange: '6-9pm' },
  ]

  const updateAvailabilities = (timeOfDay: TimeOfDay, days: readonly DayOfWeek[]) => {
    const newlySelectedDays: Availability[] = days.map(day => ({
      time: timeOfDay,
      weekday: day,
    }))
    onChange([
      ...value.filter(availability => availability.time !== timeOfDay),
      ...newlySelectedDays,
    ])
  }
  return (
    <InputWrapper error={error} disabled={disabled}>
      <Stack spacing='lg'>
        <Text>Set your preferences:</Text>
        <Stack spacing='md' pb='sm'>
          {timesInfo.map(timeInfo => {
            const filteredAvailabilities = value
              .filter(availability => availability.time === timeInfo.time)
              .map(availability => availability.weekday)
            const disabledDays: DayOfWeek[] =
              timeInfo.time === 'evening' ? ['sunday', 'saturday'] : []
            return (
              <Stack key={timeInfo.time} sx={{ maxWidth: '320px' }}>
                <Group position='apart'>
                  <Group spacing='sm'>
                    <Text bold>{timeInfo.timeLabel}</Text>
                    <Text size='xs' color={({ text }) => text[1]} style={{ flex: 'none' }}>
                      {timeInfo.timeRange}
                    </Text>
                  </Group>
                  <Group>
                    <SelectorRightText
                      key={timeInfo.time}
                      availabilities={filteredAvailabilities}
                      disabledDays={disabledDays}
                      disabled={disabled}
                      updateAvailabilities={days => updateAvailabilities(timeInfo.time, days)}
                    />
                  </Group>
                </Group>
                <Box pl='xs'>
                  <DaysSelector
                    key={timeInfo.time}
                    value={filteredAvailabilities}
                    onChange={days => updateAvailabilities(timeInfo.time, days)}
                    disabledDays={disabledDays}
                    disabled={disabled}
                  />
                </Box>
                {timeInfo.time !== 'evening' && <Divider my='sm' size='md' />}
              </Stack>
            )
          })}
        </Stack>
      </Stack>
    </InputWrapper>
  )
}

const SelectorRightText = ({
  availabilities,
  disabledDays,
  disabled = false,
  updateAvailabilities,
}: {
  availabilities: DayOfWeek[]
  disabledDays: DayOfWeek[]
  disabled?: boolean
  updateAvailabilities: (days: readonly DayOfWeek[]) => void
}) => {
  if (availabilities.length) {
    return (
      <TertiaryButton disabled={disabled} onClick={() => updateAvailabilities([])}>
        Clear all
      </TertiaryButton>
    )
  }
  return (
    <TertiaryButton
      disabled={disabled}
      onClick={() => updateAvailabilities(DAYS.filter(day => !disabledDays.includes(day)))}
    >
      Select all
    </TertiaryButton>
  )
}

export { AvailabilitySelector }
