import { z } from 'zod'
import { HourValue } from './calendar'

/**
 * To help identify when reading date/time types its intention
 */

// 2023-07-11
export type YYYYMMDD = `${number}-${number}-${number}`

// 2023-07
export type YYYYMM = `${number}-${number}`

// 2023-07-11T17:56:14Z
export type ISOString = `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`

// 07/11/2023
export type MMDDYYYY = `${number}/${number}/${number}`

// July 11, 2023, 5:56 PM
export type MMMMDoYYYYhmma = `${string} ${string}, ${number}, ${number}:${number} ${string}`

export const YYYYMMDDSchema = z.custom<YYYYMMDD>(val => {
  return typeof val === 'string' && /\d{4}-\d{2}-\d{2}/.test(val)
})

export const ISOStringSchema = z.custom<ISOString>(val => {
  return typeof val === 'string' && new Date(val).toISOString() === val
})

export const MMDDYYYYSchema = z.custom<MMDDYYYY>(val => {
  return typeof val === 'string' && /\d{2}\/\d{2}\/\d{4}/.test(val)
})

export const HourValueSchema = z.custom<HourValue>(val => {
  return typeof val === 'string' && /\d{2}:\d{2}/.test(val)
})
