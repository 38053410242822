import { z } from 'zod'
import { ApiSchemas } from '../../utils'

const WORKFLOW_PROMPT_RESPONSE_VALUES = ['who_referred_you'] as const

const GetWorkflowPromptResponsesWhoReferredYouReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
      value: z.enum(WORKFLOW_PROMPT_RESPONSE_VALUES),
    }),
  }),
  res: z.object({
    data: z.object({
      response: z.string().nullable(),
    }),
  }),
}

export const WorkflowPromptResponsesRouteSchemas = {
  'GET /workflow-prompt-responses/:patientId/value/:value':
    GetWorkflowPromptResponsesWhoReferredYouReqRes,
} satisfies ApiSchemas
