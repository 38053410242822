import { useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  ArrowLeftIcon,
  Box,
  Group,
  PinInput,
  PrimaryButton,
  ResponsiveBox,
  SecureInput,
  Space,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useBanner,
  useLifecycle,
} from '@shared/components'
import React from 'react'
import { useMutation } from 'react-query'
import { authApi, currentUserApi } from '../../../common/api'
import { validateConfirmPassword, validatePassword } from '../../../common/password'
import { sendPageEvent } from '../../../common/rudderstack'

type SetPasswordForm = {
  password: string
  confirmPassword: string
}

export const SetPassword = ({ backToSignInPage }: { backToSignInPage: () => void }) => {
  const [isPincode, togglePincode] = useToggle([true, false])
  const { showBanner } = useBanner()

  useLifecycle({ onMount: () => sendPageEvent('Password Setup') })

  const setPassword = useMutation(currentUserApi.setPassword)
  const signInFirebaseAuth = useMutation(authApi.signInWithToken)
  const isLoading = setPassword.isLoading || signInFirebaseAuth.isLoading

  const onSetPassword = async () => {
    if (!form.validate().hasErrors) {
      try {
        const { token } = await setPassword.mutateAsync({
          plainNewPassword: form.values.password,
          passwordType: isPincode ? 'pin' : 'password',
        })

        await signInFirebaseAuth.mutateAsync(token)
      } catch {
        showBanner({ type: 'error', label: 'Something went wrong, try again later' })
      }
    }
  }

  const form = useForm<SetPasswordForm>({
    initialValues: { password: '', confirmPassword: '' },
    validate: {
      password: value => validatePassword(value, isPincode),
      confirmPassword: (value, values) => validateConfirmPassword(values.password, value),
    },
  })

  const togglePasswordType = () => {
    togglePincode()
    form.setValues({ password: '', confirmPassword: '' })
  }

  return (
    <Box test-id='page:set-password'>
      <TitleTwo>
        {isPincode ? 'Welcome to your patient portal' : 'Set a password for your patient portal'}
      </TitleTwo>
      <Space h='sm' />
      <Text>
        {isPincode
          ? "First, let's set your 4-digit PIN. You'll use this PIN to sign in to your patient portal moving forward."
          : "You'll use this password to sign in to your patient portal moving forward."}
      </Text>
      <Space h='lg' />
      {isPincode && (
        <Stack spacing='lg'>
          <PinInput
            test-id='input:pin'
            length={4}
            disabled={isLoading}
            mask
            {...form.getInputProps('password')}
          />
          <PinInput
            test-id='input:pin-confirm'
            length={4}
            disabled={isLoading}
            label='Confirm PIN'
            mask
            {...form.getInputProps('confirmPassword')}
          />
        </Stack>
      )}
      {!isPincode && (
        <Stack spacing='lg'>
          <SecureInput
            label='Password'
            test-id='input:password'
            placeholder='Password'
            disabled={isLoading}
            {...form.getInputProps('password')}
          />
          <SecureInput
            label='Confirm Password'
            test-id='input:password-confirm'
            placeholder='Re-type password'
            disabled={isLoading}
            {...form.getInputProps('confirmPassword')}
          />
        </Stack>
      )}
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg'>
            <PrimaryButton
              test-id='button:submit@mobile'
              fullWidth
              loading={isLoading}
              onClick={onSetPassword}
            >
              Submit
            </PrimaryButton>
            <TertiaryButton
              fullWidth
              test-id='button:switch-password-type@mobile'
              onClick={togglePasswordType}
            >
              {`Rather set a ${isPincode ? 'password' : 'PIN'}?`}
            </TertiaryButton>
            <TertiaryButton fullWidth leftIcon={<ArrowLeftIcon />} onClick={backToSignInPage}>
              Back to sign in page
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Stack spacing='lg'>
            <Group position='apart'>
              <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={backToSignInPage}>
                Back to sign in page
              </TertiaryButton>
              <PrimaryButton
                test-id='button:submit@desktop'
                loading={isLoading}
                onClick={onSetPassword}
              >
                Submit
              </PrimaryButton>
            </Group>
            <Group position='right'>
              <TertiaryButton
                test-id='button:switch-password-type@desktop'
                onClick={togglePasswordType}
              >
                {`Rather set a ${isPincode ? 'password' : 'PIN'}?`}
              </TertiaryButton>
            </Group>
          </Stack>
        }
      />
    </Box>
  )
}
