import { useScrollIntoView } from '@mantine/hooks'
import React, { useLayoutEffect } from 'react'

type Params = Parameters<typeof useScrollIntoView>[0] & {
  dependencyList?: React.DependencyList
}

export const useScrollIntoViewOnLayoutEffect = ({
  dependencyList = [],
  ...params
}: Params = {}) => {
  const scrollIntoViewParams = useScrollIntoView<HTMLDivElement>(params)

  useLayoutEffect(() => {
    scrollIntoViewParams.scrollIntoView()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList)

  return scrollIntoViewParams
}
