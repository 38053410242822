import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Group,
  PinInput,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  SecureInput,
  Space,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import React from 'react'
import { useMutation } from 'react-query'
import { SigninProps } from '.'
import { authApi, currentUserApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { useAuth } from '../../../common/hooks'
import { validatePassword } from '../../../common/password'
import { sendPageEvent } from '../../../common/rudderstack'
import { getLocalStorageItem, removeLocalStorageItem } from '../../../common/storage'

type PasswordForm = {
  password: string
}

export const Password = ({ backToSignInPage }: SigninProps) => {
  const { showBanner } = useBanner()
  const { isLoading: authIsLoading, currentUser } = useAuth()

  useLifecycle({ onMount: () => sendPageEvent('Password Login') })

  const loginWithPassword = useMutation(currentUserApi.loginWithPassword, {
    onError: error => {
      form.setFieldError('password', getOpheliaHttpError(error))
    },
  })

  const signInFirebaseAuth = useMutation(authApi.signInWithToken, {
    onError: () => showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
  })

  const requestPasswordReset = useMutation(currentUserApi.requestPasswordReset)
  const isLoading = authIsLoading || loginWithPassword.isLoading || signInFirebaseAuth.isLoading

  const onLoginWithPassword = async () => {
    if (!form.validate().hasErrors) {
      const { token } = await loginWithPassword.mutateAsync({
        password: form.values.password,
      })

      signInFirebaseAuth.mutate(token)
    }
  }

  const onResetPassword = async () => {
    await requestPasswordReset.mutateAsync()
    showBanner({ type: 'success', label: 'We sent a password reset link to your email' })
  }

  const isPincode = currentUser?.data?.account.passwordType === 'pin'
  const form = useForm<PasswordForm>({
    initialValues: { password: '' },
    validate: {
      password: value => validatePassword(value, isPincode),
    },
  })

  if (!currentUser?.data?.account.passwordType) {
    return <Skeletons />
  }

  // If there is a referral id in their local storage, remove it
  if (getLocalStorageItem('wxflow_ref')) {
    removeLocalStorageItem('wxflow_ref')
  }

  return (
    <Box>
      <TitleTwo>Welcome back</TitleTwo>
      <Space h='sm' />
      <Text>
        {isPincode
          ? 'Enter your 4-digit PIN to sign in to your patient portal.'
          : 'Enter your password to sign in to your patient portal.'}
      </Text>
      <Space h='lg' />
      {isPincode ? (
        <PinInput
          test-id='input:pin'
          length={4}
          disabled={isLoading}
          mask
          {...form.getInputProps('password')}
        />
      ) : (
        <Stack spacing='lg'>
          <SecureInput
            label='Password'
            test-id='input:password'
            placeholder='Password'
            disabled={isLoading}
            {...form.getInputProps('password')}
          />
        </Stack>
      )}
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg'>
            <PrimaryButton
              test-id='button:submit@mobile'
              fullWidth
              loading={isLoading}
              onClick={onLoginWithPassword}
            >
              Submit
            </PrimaryButton>
            <TertiaryButton
              test-id='button:reset-password'
              fullWidth
              disabled={isLoading}
              loading={requestPasswordReset.isLoading}
              onClick={onResetPassword}
            >
              {`Forgot your ${isPincode ? 'PIN' : 'password'}?`}
            </TertiaryButton>
            <TertiaryButton fullWidth leftIcon={<ArrowLeftIcon />} onClick={backToSignInPage}>
              Back to sign in page
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={backToSignInPage}>
              Back to sign in page
            </TertiaryButton>
            <Group>
              <SecondaryButton
                test-id='button:reset-password'
                loading={requestPasswordReset.isLoading}
                disabled={isLoading}
                onClick={onResetPassword}
              >
                {`Forgot your ${isPincode ? 'PIN' : 'password'}?`}
              </SecondaryButton>
              <PrimaryButton
                test-id='button:submit@desktop'
                loading={isLoading}
                onClick={onLoginWithPassword}
              >
                Submit
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Box>
  )
}
