import { z } from 'zod'
import { ApiSchemas } from '../../utils'
import { ISOStringSchema } from '../../../dates'

const GetDropInClinicById = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      datetimeStart: ISOStringSchema,
      datetimeEnd: ISOStringSchema,
      clinicianName: z.string(),
      confirmedAt: ISOStringSchema.nullish(),
    }),
  }),
}

const ConfirmDropInClinicById = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}
export const MyDropInClinicApi = {
  'GET /drop-in-clinic/:id': GetDropInClinicById,
  'POST /drop-in-clinic/:id/confirm': ConfirmDropInClinicById,
} satisfies ApiSchemas
