import { useForm } from '@mantine/form'
import {
  ResponsiveBox,
  Stack,
  Text,
  TitleTwo,
  isAnySelected,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey, ValueOf } from '@shared/types'
import React, { useState } from 'react'
import { isRequired } from '../../../../forms'
import { WidgetProps } from '../../PromptContentWidget'
import { CurrentPrescribedOpioidsUsed } from './CurrentPrescribedOpioidsUsed'
import { CurrentSubstancesUsed } from './CurrentSubstancesUsed'

const StepsData = {
  current_substances_used: {
    title: `Have you used any of the following substances in the past month?`,
    subtitle: `Do not include medications that were prescribed to you by a healthcare provider.`,
  },
  current_prescribed_opioids_used: {
    title: `In the past month, have you used opioid painkillers that were prescribed to you (e.g. Oxycontin, Percocet, Vicodin)?`,
    subtitle: '',
  },
}

type Steps = keyof typeof StepsData

export const substancesOptions = {
  substances_used_prescription_opioid_painkillers:
    'substances_used_prescription_opioid_painkillers',
  substances_used_heroin: 'substances_used_heroin',
  substances_used_fentanyl: 'substances_used_fentanyl',
  substances_used_methamphetamine: 'substances_used_methamphetamine',
  substances_used_cocaine: 'substances_used_cocaine',
  substances_used_other_stimulants: 'substances_used_other_stimulants',
  substances_used_benzodiazepines: 'substances_used_benzodiazepines',
  substances_used_kratom: 'substances_used_kratom',
  substances_used_ketamine: 'substances_used_ketamine',
  substances_used_alcohol: 'substances_used_alcohol',
  substances_used_cannabis: 'substances_used_cannabis',
  substances_used_none: 'substances_used_none',
} as const

export type SubstancesOptions = ValueOf<typeof substancesOptions>

export type SubstancesAndPrescribedOpioidsForm = {
  substancesUsedOptions: PromptResponseContextKey[]
  prescribedOpioidsUsed: PromptResponseContextKey | undefined
}

export const CurrentSubstancesAndPrescribedOpioidsUsed = ({ onSubmit }: WidgetProps) => {
  const [step, setStep] = useState<Steps>('current_substances_used')

  const substancesAndPrescribedOpioidsForm = useForm<SubstancesAndPrescribedOpioidsForm>({
    initialValues: {
      substancesUsedOptions: [],
      prescribedOpioidsUsed: undefined,
    },
    validate: {
      substancesUsedOptions: isAnySelected(Object.values(substancesOptions), 'Required'),
      prescribedOpioidsUsed: validateWith(isRequired),
    },
  })

  const disableAllOtherOptions = () =>
    substancesAndPrescribedOpioidsForm.values.substancesUsedOptions.includes('substances_used_none')

  const handleClickNext = () => {
    if (step === 'current_substances_used') {
      const substancesUsedValidtation =
        substancesAndPrescribedOpioidsForm.validateField('substancesUsedOptions')
      if (substancesUsedValidtation.hasError) {
        return
      }
      setStep('current_prescribed_opioids_used')
    }
    if (step === 'current_prescribed_opioids_used') {
      submitHandler()
    }
  }

  const submitHandler = () => {
    const { hasErrors } = substancesAndPrescribedOpioidsForm.validate()

    if (hasErrors) {
      return
    }

    substancesAndPrescribedOpioidsForm.values.substancesUsedOptions.push(
      substancesAndPrescribedOpioidsForm.values.prescribedOpioidsUsed as PromptResponseContextKey,
    )

    const answersArray = substancesAndPrescribedOpioidsForm.values.substancesUsedOptions.filter(
      option => {
        if (
          substancesAndPrescribedOpioidsForm.values.substancesUsedOptions.includes(
            'substances_used_none',
          )
        ) {
          return (
            option === 'substances_used_none' ||
            option === substancesAndPrescribedOpioidsForm.values.prescribedOpioidsUsed
          )
        }
        return true
      },
    )

    onSubmit({
      value: {
        contextKey: answersArray,
      },
      substances_and_prescribed_opioids_used: {
        value: 'custom',
        contextKey: answersArray,
      },
    })
  }

  return (
    <ResponsiveBox
      mobile={
        <Stack spacing='lg'>
          <Stack spacing='xs'>
            <TitleTwo>{StepsData[step]?.title}</TitleTwo>
            {StepsData[step]?.subtitle && <Text>{StepsData[step]?.subtitle}</Text>}
          </Stack>
          {step === 'current_substances_used' && (
            <CurrentSubstancesUsed
              disableAllOtherOptions={disableAllOtherOptions}
              form={substancesAndPrescribedOpioidsForm}
              handleClickNext={handleClickNext}
            />
          )}
          {step === 'current_prescribed_opioids_used' && (
            <CurrentPrescribedOpioidsUsed
              form={substancesAndPrescribedOpioidsForm}
              handleClickNext={handleClickNext}
            />
          )}
        </Stack>
      }
      desktop={
        <Stack>
          <TitleTwo>{StepsData[step]?.title}</TitleTwo>
          {StepsData[step]?.subtitle && <Text>{StepsData[step]?.subtitle}</Text>}
          {step === 'current_substances_used' && (
            <CurrentSubstancesUsed
              disableAllOtherOptions={disableAllOtherOptions}
              form={substancesAndPrescribedOpioidsForm}
              handleClickNext={handleClickNext}
            />
          )}
          {step === 'current_prescribed_opioids_used' && (
            <CurrentPrescribedOpioidsUsed
              form={substancesAndPrescribedOpioidsForm}
              handleClickNext={handleClickNext}
            />
          )}
        </Stack>
      }
    />
  )
}
