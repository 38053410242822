/* eslint-disable no-magic-numbers */
import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  DailyCalendar,
  DailyCalendarRadioGroup,
  Group,
  PrimaryButton,
  Radio,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TextInput,
  TitleTwo,
  isAnySelected,
  skipIfOtherField,
  useBanner,
  useLifecycle,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey, PromptResponsePayload } from '@shared/types'
import { dayjs, toTime, validCharacters } from '@shared/utils'
import last from 'lodash/last'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Skeletons } from '../..'
import { patientApi } from '../../../api'
import { isAtleastOneWord, isPhone, isRequired } from '../../../forms'
import { useAuth, useMyQuery } from '../../../hooks'
import { sendPageEvent } from '../../../rudderstack'
import { getSessionStorageItem } from '../../../storage'
import { CalendarMessage } from '../../CalendarMessage'
import { WidgetProps } from '../PromptContentWidget'

export const ScheduleIntakeVisit = ({ ...props }: WidgetProps) => {
  const { isAuthorized, currentUser } = useAuth()
  const { showBanner } = useBanner()
  const [date, setDate] = useState(dayjs())
  const currentUserState =
    currentUser?.data?.homeData?.state || getSessionStorageItem('residence_state') || ''

  const calendarsQuery = useMyQuery(
    'GET /calendars',
    {
      query: {
        state: currentUserState,
      },
    },
    {
      enabled: Boolean(currentUserState && isAuthorized),
    },
  )

  const calendarIdsData = calendarsQuery?.data?.data?.calendarIds || []

  const [dateSlotsKey, dateSlotsFn] = patientApi.getQuery('GET /appointments/calendar/intake', {
    query: {
      calendarIds: calendarIdsData,
    },
  })

  const { data: range, isLoading: isRangeLoading } = useQuery(
    dateSlotsKey,
    async () => {
      const availableRange = await dateSlotsFn()
      const now = dayjs()

      if (availableRange.length === 0) {
        return now.getBetween(now.add(5, 'days'), 'days').map(date => ({ date, available: false }))
      }

      const start = dayjs(availableRange[0]?.date).isToday() ? now : now.add(1, 'day')
      const range = start.getBetween(start.add(5, 'days'), 'days')

      return range.map(date => ({
        date,
        available: availableRange.some(slot => dayjs(slot.date).isSame(date)),
      }))
    },
    {
      enabled: isAuthorized && calendarIdsData.length > 0,
      cacheTime: toTime('10 sec').ms(),
      onSuccess: dates => {
        const slot = dates.find(slot => slot.available)
        setDate(dayjs(slot?.date ?? dates[0]?.date))
      },
      onError: () => showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
    },
  )

  if (isRangeLoading || calendarsQuery.isLoading) {
    return <Skeletons />
  }

  return (
    <IntakeVisitCalendar
      {...props}
      range={range}
      isRangeLoading={isRangeLoading || calendarsQuery.isLoading}
      date={date}
      setDate={setDate}
      calendarIds={calendarIdsData}
    />
  )
}

const IntakeVisitCalendar = ({
  ...props
}: WidgetProps & {
  range:
    | {
        date: string
        available: boolean
      }[]
    | undefined
  isRangeLoading: boolean
  date: dayjs.Dayjs
  setDate: React.Dispatch<React.SetStateAction<dayjs.Dayjs>>
  calendarIds: string[]
}) => {
  const navigate = useNavigate()
  const { currentUser, isAuthorized } = useAuth()
  const { showBanner, hideBanner } = useBanner()

  const isLastDay = dayjs(last(props.range)?.date).isSame(props.date, 'day')
  const isAvailable = Boolean(
    props.range?.find(slot => props.date.isSame(slot.date, 'day'))?.available,
  )

  const {
    data: timeslots,
    isLoading: isTimeSlotsLoading,
    refetch: refetchTimeSlots,
  } = useQuery(
    ...patientApi.getQuery('GET /appointments/calendar/intake/:date', {
      params: { date: props.date.format('YYYY-MM-DD') },
      query: {
        startDatetime: dayjs(props.date).toISOString(),
        calendarIds: props.calendarIds,
      },
    }),
    {
      cacheTime: toTime('10 sec').ms(),
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      // Don't fetch for last date, we show a message of booking too far
      enabled: isAuthorized && props.calendarIds.length > 0 && isAvailable && !isLastDay,
      onError: () => showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
    },
  )
  const { clearErrors, getInputProps, setValues, validate, values } = useForm({
    initialValues: {
      datetime: '',
      firstName:
        currentUser?.data?.personalData?.preferredName ||
        currentUser?.data?.personalData?.firstName ||
        '',
      lastName: currentUser?.data?.personalData?.lastName || '',
      // Force with custom phone if not authorized
      withCustomPhone: !isAuthorized,
      phone: '',
      ext: '',
      earlyNotification: false,
    },
    validate: {
      datetime: isAnySelected(timeslots?.map(slot => slot.time) ?? [], 'Required'),
      firstName: validateWith(isRequired, isAtleastOneWord),
      lastName: validateWith(isRequired, isAtleastOneWord),
      phone: validateWith(skipIfOtherField('withCustomPhone', 'is', false), isRequired, isPhone),
    },
  })

  const NEED_TO_BOOK_FURTHER_OUT = 'need_to_book_further_out' as PromptResponseContextKey

  const onSubmit = ({ needToBookFurtherOut }: { needToBookFurtherOut?: boolean }) => {
    if (needToBookFurtherOut) {
      return props.onSubmit({
        value: {
          contextKey: NEED_TO_BOOK_FURTHER_OUT,
        },
      })
    }

    if (validate().hasErrors) {
      return
    }

    const calendarId = timeslots?.find(slot => slot.time === values.datetime)?.calendarIDs[0]

    if (!calendarId) {
      showBanner({
        type: 'error',
        label: 'Something went wrong, please select a different appointment time',
      })
      return
    }

    const payload: PromptResponsePayload<'onboarding'> = {
      value: {
        contextKey: 'custom',
      },
      schedule_intake_visit: {
        contextKey: 'custom',
        value: values.datetime,
      },
      schedule_intake_visit_calendar: {
        contextKey: 'custom',
        value: String(calendarId),
      },
      schedule_intake_visit_first_name: {
        contextKey: 'custom',
        value: values.firstName,
      },
      schedule_intake_visit_last_name: {
        contextKey: 'custom',
        value: values.lastName,
      },
      schedule_intake_visit_timezone: {
        contextKey: 'custom',
        value: dayjs.tz.guess(),
      },
      schedule_intake_visit_address_state: {
        contextKey: 'custom',
        // Reconnect has no residence_state
        value: getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state || '',
      },
      schedule_intake_visit_early_notification_interest: {
        contextKey: 'custom',
        value: values.earlyNotification,
      },
    }

    if (values.withCustomPhone) {
      payload.schedule_intake_visit_phone = {
        contextKey: 'custom',
        value: values.phone,
      }
    }

    props.onSubmit(payload, { onError: refetchTimeSlots })
  }

  useLifecycle({
    onMount: () => {
      showBanner({
        type: 'success',
        label: 'We will verify your insurance soon',
        icon: <CheckCircleIcon />,
        description: 'If we cannot verify your insurance, we will let you know before the visit',
      })
      sendPageEvent('Intake Visit Calender')
    },
  })

  return (
    <Stack spacing='lg' test-id='content:schedule-intake-visit'>
      <Stack spacing='sm'>
        <TitleTwo>Choose a time for your 60-minute intake visit with a licensed clinician</TitleTwo>
      </Stack>
      <DailyCalendar
        nextDayPulse={timeslots?.length === 0}
        previousDayPulse={isLastDay}
        value={props.date.toString()}
        range={props.range?.map(slot => slot.date) ?? []}
        onChange={value => {
          clearErrors()
          setValues({ datetime: '' })
          props.setDate(dayjs(value))
          hideBanner()
        }}
      >
        {isLastDay ? (
          <CalendarMessage>
            Sorry, we can&apos;t book this far out right now. Can&apos;t find a time that works for
            you?{' '}
            <TertiaryButton
              test-id='button:back@mobile'
              fullWidth
              onClick={() => onSubmit({ needToBookFurtherOut: true })}
            >
              Schedule a welcome call with a team member
            </TertiaryButton>
          </CalendarMessage>
        ) : (
          <DailyCalendarRadioGroup
            size={6}
            loading={isTimeSlotsLoading}
            empty={<CalendarMessage>Sorry, we&apos;re booked on this day.</CalendarMessage>}
            {...getInputProps('datetime')}
          >
            {timeslots?.map(timeslot => {
              return (
                <Radio
                  key={timeslot.time}
                  value={timeslot.time}
                  label={dayjs(timeslot.time).format('h:mma z')}
                />
              )
            })}
          </DailyCalendarRadioGroup>
        )}
      </DailyCalendar>
      {values.datetime && (
        <Stack>
          <TextInput
            test-id='input:first-name'
            label='First name'
            placeholder='First name'
            formatter={validCharacters.name}
            {...getInputProps('firstName')}
          />
          <TextInput
            test-id='input:last-name'
            label='Last name'
            placeholder='Last name'
            formatter={validCharacters.name}
            {...getInputProps('lastName')}
          />
        </Stack>
      )}
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton
              test-id='button:book-intake@mobile'
              fullWidth
              onClick={() => onSubmit({ needToBookFurtherOut: false })}
            >
              Schedule
            </PrimaryButton>
            {props.onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                style={{ margin: 'auto' }}
                leftIcon={<ArrowLeftIcon />}
                onClick={() => navigate(-1)}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Stack>
            <Group position='apart'>
              {props.onBack && (
                <TertiaryButton
                  test-id='button:back@desktop'
                  leftIcon={<ArrowLeftIcon />}
                  onClick={() => navigate(-1)}
                >
                  Back
                </TertiaryButton>
              )}
              <Group position='right'>
                <PrimaryButton
                  test-id='button:book-intake@desktop'
                  onClick={() => onSubmit({ needToBookFurtherOut: false })}
                >
                  Schedule
                </PrimaryButton>
              </Group>
            </Group>
          </Stack>
        }
      />
    </Stack>
  )
}
