import React, { ReactNode } from 'react'
import * as Fullstory from '../common/fullstory'

type ErrorBoundaryProps = { fallback: ReactNode; children: ReactNode }
type ErrorBoundaryState = { hasError: boolean }

// This component is grabbed straight from React documentation: https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: Error, info: any) {
    /*
     * Example "componentStack":
     *   in ComponentThatThrows (created by App)
     *   in ErrorBoundary (created by App)
     *   in div (created by App)
     *   in App
     */
    Fullstory.event('Patient ErrorBoundary Rendered')
    Fullstory.log('error', info.componentStack)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback
    }

    return this.props.children
  }
}
