// Adapted from https://ui.mantine.dev/category/tables
import React from 'react'
import { Center, Group, SortIcon, Text, UnstyledButton, useMantineTheme } from '.'

export type ThSortableProps = {
  sortable: true
  children: React.ReactNode
  sorted: boolean
  reversed: boolean
  onSort: () => void
  center?: boolean
  style?: React.CSSProperties | undefined
}

export type ThUnsortableProps = {
  sortable: false
  children: React.ReactNode
  center?: boolean
  style?: React.CSSProperties | undefined
}

export type ThProps = ThSortableProps | ThUnsortableProps

const ThButton = (props: ThProps) => {
  const onSort = props.sortable ? props.onSort : () => void 0

  const {
    other: { sizes },
  } = useMantineTheme()

  const icon = props.sortable ? (
    <Center>
      <SortIcon
        color={colors => (props.sorted ? colors.actions[1] : colors.actions[2])}
        transform={props.sorted && props.reversed ? 'scale(1, -1)' : 'scale(1, 1)'}
      />
    </Center>
  ) : null

  return (
    <UnstyledButton
      onClick={onSort}
      disabled={!props.sortable}
      style={{ pointerEvents: props.sortable ? 'auto' : 'none' }}
    >
      <Group position='left' spacing={sizes.gap.sm} noWrap>
        <Text size='xs' bold style={{ whiteSpace: 'nowrap' }} color={colors => colors.text[0]}>
          {props.children}
        </Text>
        {icon}
      </Group>
    </UnstyledButton>
  )
}

export const Th = (props: ThProps) => {
  const {
    other: { sizes },
  } = useMantineTheme()

  return (
    <th
      className='mantine'
      style={{
        ...props.style,
        padding: `calc(${sizes.padding.xs} * 3)`,
      }}
    >
      {props.center ? (
        <Center>
          <ThButton {...props} />
        </Center>
      ) : (
        <ThButton {...props} />
      )}
    </th>
  )
}
