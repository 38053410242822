import { useDidUpdate } from '@mantine/hooks'
import {
  AlertIcon,
  ArrowRightIcon,
  Banner,
  Checkbox,
  CheckboxGroup,
  Divider,
  Group,
  PlusCircleIcon,
  PrimaryButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TertiaryButton,
  Text,
  TitleFour,
  TitleThree,
  TitleTwo,
} from '@shared/components'
import { getOpheliaHttpError, isInvoicePastDue, isPaymentNeededForInvoice } from '@shared/types'
import { formatDollarAmount } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as TaskCompletionAnimation from '../../../assets/complete.json'
import AnimationModal from '../../../common/components/AnimationModal'
import { StripeElementsProvider } from '../../../common/components/stripe/StripeElementsProvider'
import {
  useAuth,
  usePortalDims,
  usePortalMutation,
  usePortalQuery,
  useStripeClientSecret,
} from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { StripePaymentRequestButton } from './StripePaymentRequestButton'

const AddPaymentCardButton = () => (
  <TertiaryButton
    leftIcon={<PlusCircleIcon />}
    component={Link}
    to={`${routes.portal.index}/${routes.portal.children.billing.index}/${routes.portal.children.billing.children.paymentMethods.index}${routes.portal.children.billing.children.paymentMethods.children.add}`}
  >
    Add payment card
  </TertiaryButton>
)

const EditPaymentCardButton = ({ paymentMethodId }: { paymentMethodId: string }) => (
  <TertiaryButton
    rightIcon={<ArrowRightIcon />}
    component={Link}
    to={`${routes.portal.index}/${routes.portal.children.billing.index}/${
      routes.portal.children.billing.children.paymentMethods.index
    }${routes.portal.children.billing.children.paymentMethods.children.edit.replace(
      ':paymentMethodId',
      paymentMethodId,
    )}`}
  >
    Edit payment card
  </TertiaryButton>
)

const useLatestFinancialConsents = () => {
  const { data: v4 } = usePortalQuery('GET /consents/:type/:version', {
    params: { type: 'financial', version: '4' },
  })
  const { data: v5 } = usePortalQuery('GET /consents/:type/:version', {
    params: { type: 'financial', version: '5' },
  })

  return Boolean(v4 || v5)
}

export const AddPayment = () => {
  const clientSecretResult = useStripeClientSecret()
  const clientSecret = clientSecretResult.data?.clientSecret
  // Must have signed one of the latest financial consents to use wallet-based payment methods
  const isWalletPaymentEnabled = useLatestFinancialConsents()

  const { currentUser } = useAuth()
  const { isMobile } = usePortalDims()
  const navigate = useNavigate()
  const paymentMethodsQuery = usePortalQuery('GET /payment/methods')
  const paymentMethods = paymentMethodsQuery.data?.paymentMethods || []
  const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.isDefault)

  const [invoiceIdsToPay, setInvoiceIdsToPay] = React.useState<string[]>([])
  const [isNewDefault, setIsNewDefault] = React.useState(true)
  const [paymentMethodId, setPaymentMethodId] = React.useState<string>(
    defaultPaymentMethod?.id ?? '',
  )

  const invoicesQuery = usePortalQuery('GET /invoices')
  const invoicesData = invoicesQuery.data

  const invoicesForPayment = React.useMemo(() => {
    return orderBy(
      (invoicesData?.invoiceListItems || []).filter(invoice => {
        return isPaymentNeededForInvoice({
          paymentStatus: invoice.paymentStatus,
        })
      }),
      d => d.dueDate,
      'asc',
    )
  }, [invoicesData])

  const initInvoiceIdsToPay = React.useCallback(() => {
    // By default, select all invoices that are due
    setInvoiceIdsToPay(invoicesForPayment.map(invoice => invoice.oid))
  }, [invoicesForPayment])

  useEffect(() => {
    initInvoiceIdsToPay()
  }, [initInvoiceIdsToPay])

  const payInvoices = usePortalMutation('POST /pay-invoices', {
    onSuccess: async () => {
      await invoicesQuery.refetch()
      initInvoiceIdsToPay()
    },
    onError: async () => {
      /*
       * If the mutation fails, refetch the invoices to reset the UI
       * This is to update the status of any successfully paid invoices
       */
      await invoicesQuery.refetch()
      initInvoiceIdsToPay()
    },
  })

  const updateDefaultPaymentMethod = usePortalMutation('POST /payment/methods/default', {
    onSuccess: () => {
      void paymentMethodsQuery.refetch()
    },
  })

  const payNowOnClick = () => {
    payInvoices.mutate({
      data: {
        paymentMethodId,
        invoiceIds: invoiceIdsToPay,
      },
    })

    if (isNewDefault && paymentMethodId !== defaultPaymentMethod?.id) {
      updateDefaultPaymentMethod.mutate({
        data: {
          paymentMethodId,
        },
      })
    }
  }

  useDidUpdate(() => {
    const defaultPaymentMethod = paymentMethods.find(paymentMethod => paymentMethod.isDefault)
    setPaymentMethodId(defaultPaymentMethod?.id ?? '')
  }, [paymentMethods])

  const invoicesSelectedForPayment = invoicesForPayment.filter(invoice => {
    return invoiceIdsToPay.includes(invoice.oid)
  })

  const amountInCents = sumBy(invoicesSelectedForPayment, d => d.amountRemainingInCents)

  const hasPaymentMethod = paymentMethods.length > 0

  return (
    <>
      <Stack test-id='page:billing:add-payment' spacing='lg'>
        <TitleTwo>Add payment</TitleTwo>
        <Stack spacing='sm'>
          <Text>Select invoices to pay</Text>
          <CheckboxGroup value={invoiceIdsToPay} onChange={setInvoiceIdsToPay}>
            {invoicesForPayment.map(invoice => {
              const isPastDueInvoice = isInvoicePastDue(invoice)

              return (
                <Checkbox
                  key={invoice.oid}
                  value={invoice.oid}
                  label={
                    <Stack spacing='sm'>
                      <Group spacing='xs'>
                        <Text bold color={c => (isPastDueInvoice ? c.error[0] : c.background[5])}>
                          {formatDollarAmount({ amount: invoice.amountRemaining })}
                        </Text>
                        {isPastDueInvoice && <Text>past due</Text>}
                      </Group>
                      <Text>{invoice.description}</Text>
                    </Stack>
                  }
                />
              )
            })}
          </CheckboxGroup>
        </Stack>

        <>
          {amountInCents > 0 && (
            <Stack spacing='sm'>
              <TitleThree>TOTAL AMOUNT</TitleThree>
              <TitleTwo>
                {formatDollarAmount({
                  amount: amountInCents,
                  unit: 'cents',
                })}
              </TitleTwo>
            </Stack>
          )}
          <Divider />
          <Stack spacing='sm'>
            {hasPaymentMethod && <TitleFour>PAYING FROM</TitleFour>}
            <Stack spacing='md'>
              {isWalletPaymentEnabled && clientSecret && (
                <StripeElementsProvider clientSecret={clientSecret}>
                  <StripePaymentRequestButton
                    amountInCents={amountInCents}
                    stripeInvoiceIds={invoicesSelectedForPayment.map(invoice => {
                      return invoice.externalId
                    })}
                  />
                </StripeElementsProvider>
              )}
              <RadioGroup onChange={setPaymentMethodId} value={paymentMethodId || ''} spacing='md'>
                {!hasPaymentMethod && (
                  <Group spacing='xs'>
                    <AlertIcon />
                    <Text>No payment method on file</Text>
                  </Group>
                )}
                {paymentMethods.map(paymentMethod => {
                  return (
                    <Stack key={paymentMethod.id}>
                      <Radio
                        label={
                          <Group spacing='sm'>
                            <Text>{`${capitalize(
                              paymentMethod?.brand,
                            )} ending in ${paymentMethod?.last4}`}</Text>
                            {paymentMethod.isDefault && <Text>(default)</Text>}
                          </Group>
                        }
                        value={paymentMethod.id}
                      />
                      {paymentMethodId === paymentMethod.id && !paymentMethod.isDefault && (
                        <>
                          <Switch
                            pl='sm'
                            label='Set as new default'
                            checked={isNewDefault}
                            onChange={event => setIsNewDefault(event.target.checked)}
                          />
                          <Divider />
                        </>
                      )}
                    </Stack>
                  )
                })}
              </RadioGroup>
              {hasPaymentMethod && currentUser?.data?.onlyOnePaymentMethodAllowed ? (
                <EditPaymentCardButton paymentMethodId={paymentMethods?.at(0)?.id ?? ''} />
              ) : (
                <AddPaymentCardButton />
              )}
            </Stack>
          </Stack>
          <PrimaryButton
            loading={payInvoices.isLoading}
            disabled={!paymentMethodId || !invoiceIdsToPay.length}
            onClick={payNowOnClick}
            fullWidth={isMobile}
          >{`Pay now: ${formatDollarAmount({
            amount: amountInCents,
            unit: 'cents',
          })}`}</PrimaryButton>
        </>
        {payInvoices.isError && (
          <Banner
            type='error'
            label={getOpheliaHttpError(payInvoices.error, 'Unable to pay invoice(s)')}
          />
        )}
      </Stack>
      <AnimationModal
        onClose={() => {
          payInvoices.reset()
          navigate(`${routes.portal.index}/${routes.portal.children.billing.index}`)
        }}
        opened={payInvoices.isSuccess}
        animation={TaskCompletionAnimation}
        title='Thank you!'
        tagline='Your payment was successful'
        timeout='3 sec'
      />
    </>
  )
}
