import { z } from 'zod'
import { ApiSchemas } from '../../utils'

export const GetConsultationQueueSizeLimitReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.object({
      sizeLimit: z.number(),
    }),
  }),
}

export const GetCountWaitingInQueueReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.object({
      waiting: z.number(),
    }),
  }),
}
export const UpdateConsultationQueueSizeLimitReqRes = {
  req: z.object({
    body: z.object({
      sizeLimit: z.number(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}
export const ConsultationQueueSchemas = {
  'GET /consultation-queue/size': GetConsultationQueueSizeLimitReqRes,
  'GET /consultation-queue/waiting': GetCountWaitingInQueueReqRes,
  'PUT /consultation-queue/size': UpdateConsultationQueueSizeLimitReqRes,
} satisfies ApiSchemas
