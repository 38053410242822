import { Employee, OpheliaRoute, Patient } from '..'

export type TwilioApi = OpheliaRoute<
  'GET /patients',
  {
    req: {
      query: {
        phoneNumber: string
      }
    }
    res: {
      patient: Partial<Patient>
      pc?: Employee
    }
  }
> &
  OpheliaRoute<
    'POST /patients',
    {
      req: {
        data: {
          authorization: string
          callSid: string
          body: string
        }
      }
      res: never
    }
  > &
  OpheliaRoute<
    'POST /voip/call',
    {
      req: {
        data: {
          To: string
        }
      }
      res: string
    }
  >
