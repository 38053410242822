import {
  Box,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import React from 'react'
import { ContactSupport } from '../common/components'
import { setUserVars } from '../common/fullstory'
import { sendPageEvent } from '../common/rudderstack'
import { AccountRecoverySignInProps } from './AccountRecovery'

export const Phone = ({
  form,
  setCurrentStep,
  signInWithPhoneNumber,
}: AccountRecoverySignInProps) => {
  const { error: phoneInputError, ...phoneInputProps } = form.getInputProps('phoneNumber')

  const onSubmit = () => {
    signInWithPhoneNumber.reset()
    setUserVars({ phoneUsedForLogin: form.values.phoneNumber })

    if (!form.validateField('phoneNumber').hasError) {
      signInWithPhoneNumber.mutate(form.values.phoneNumber, {
        onSuccess: () => setCurrentStep('verification'),
      })
    }
  }

  useLifecycle({
    onMount: () => sendPageEvent('Phone Collection'),
  })

  return (
    <Box test-id='content:phone-number' component='div'>
      <Stack spacing='lg'>
        <Stack spacing='xs'>
          <TitleTwo>Update your phone number</TitleTwo>
          <Text>
            We&apos;ll text you a code to confirm your new number, then you&apos;ll be able to
            update your account and sign in.
          </Text>
        </Stack>
        <PhoneInput
          test-id='input:phone-number'
          label='New phone number'
          error={
            phoneInputError ||
            getOpheliaHttpError(signInWithPhoneNumber.error, 'Something went wrong')
          }
          {...phoneInputProps}
        />
        <ResponsiveBox
          mobile={
            <Stack spacing='lg'>
              <PrimaryButton
                test-id='button:submit@mobile'
                fullWidth
                onClick={onSubmit}
                loading={signInWithPhoneNumber.isLoading}
              >
                Update phone number
              </PrimaryButton>

              <ContactSupport />
            </Stack>
          }
          desktop={
            <Group position='right'>
              <PrimaryButton
                test-id='button:submit@desktop'
                onClick={onSubmit}
                loading={signInWithPhoneNumber.isLoading}
              >
                Update phone number
              </PrimaryButton>
            </Group>
          }
        />
      </Stack>
    </Box>
  )
}
