import { useForm } from '@mantine/form'
import { Grid, Select, TextInput, validateWith } from '@shared/components'
import { stateNames } from '@shared/types'
import { validCharacters } from '@shared/utils'
import React from 'react'
import { formAddressRules, isRequired, isAtleastOneWord } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const PreferredPharmacyForm = ({
  initialValues,
  submit,
  saving,
}: TaskStepProps<'preferred-pharmacy'>) => {
  const { validate, values, getInputProps } = useForm({
    initialValues,
    validate: {
      name: validateWith(isRequired, isAtleastOneWord),
      address: formAddressRules.address,
      city: formAddressRules.city,
      state: formAddressRules.state,
      zip: formAddressRules.zipCode,
    },
  })

  function onSubmit() {
    if (!validate().hasErrors) {
      submit('', values)
    }
  }

  return (
    <Grid>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Pharmacy name'
          placeholder='Pharmacy name'
          maxLength={64}
          disabled={saving}
          formatter={validCharacters.name}
          {...getInputProps('name')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Address'
          placeholder='Address'
          maxLength={64}
          disabled={saving}
          formatter={validCharacters.address}
          {...getInputProps('address')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='City'
          placeholder='City'
          maxLength={64}
          disabled={saving}
          formatter={validCharacters.city}
          {...getInputProps('city')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <Select
          label='State'
          placeholder='Select one...'
          data={stateNames}
          disabled={saving}
          searchable
          clearable
          filterDataOnExactSearchMatch
          {...getInputProps('state')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Zip'
          placeholder='5-digit zip code'
          maxLength={5}
          disabled={saving}
          formatter={validCharacters.numbers}
          inputMode='numeric'
          {...getInputProps('zip')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TaskButton onSubmit={onSubmit} loading={saving} isLastStep />
      </Grid.Col>
    </Grid>
  )
}
