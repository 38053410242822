import { useLifecycle } from '@shared/components'
import React from 'react'
import { useMutation } from 'react-query'
import { patientApi } from '../../../common/api'
import { FallbackContent, Skeletons } from '../../../common/components'
import { StripeElementsProvider } from '../../../common/components/stripe/StripeElementsProvider'
import { BillingAgreementStep } from './FinancialAgreementStep'
import { StripeBillingInfoStep } from './StripeBillingInfoStep'
import { TaskHeader } from './TaskHeader'
import { usePatientFormTask } from './use-patient-task'

export const PaymentInfoAndFinancialAgreementTask = () => {
  const { payload, status, currentStep, submit, isSaving } = usePatientFormTask({
    type: 'billing',
    totalSteps: 2,
    breadcrumb: 'Payment info & financial agreement',
  })

  const setupIntentMutation = useMutation(patientApi.getMutation('POST /payment/setup-intent'))

  // Creates a stripe setup intent on mount. For more info, see https://stripe.com/docs/payments/save-and-reuse?platform=web&ui=elements#retrieve-the-client-secret
  useLifecycle({
    onMount: () => {
      setupIntentMutation.mutate({})
    },
  })

  if (status === 'loading' || setupIntentMutation.isLoading) {
    return <Skeletons />
  }

  if (status === 'error' || !payload || setupIntentMutation.isError) {
    return <FallbackContent boundary='portal' />
  }

  // This task has changed order. Now, the financial agreement is shown before the patient enters their payment info.
  return (
    <>
      <TaskHeader title='Fill out your payment information' current={currentStep} total={2} />
      {currentStep === 1 && (
        <BillingAgreementStep
          initialValues={payload}
          submit={data => submit('agreement', data)}
          saving={isSaving}
        />
      )}

      {currentStep === 2 && setupIntentMutation.data?.clientSecret && (
        <StripeElementsProvider clientSecret={setupIntentMutation.data.clientSecret}>
          <StripeBillingInfoStep
            initialValues={payload}
            submit={data => submit('stripe-payment', data)}
            saving={isSaving}
          />
        </StripeElementsProvider>
      )}
    </>
  )
}
