import { useForm } from '@mantine/form'
import { Checkbox, Divider, Grid } from '@shared/components'
import React, { useEffect, useState } from 'react'
import { AddressForm, FormLabel, TimeZoneSelect } from '../../../common/components'
import { formAddressRules } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const AddressStep = ({ initialValues, submit, saving }: TaskStepProps<'profile'>) => {
  const [collapse, setCollapse] = useState<boolean>()
  const { values, validate, getInputProps } = useForm({
    initialValues,
    validate: {
      homeAddress: formAddressRules,
      shippingAddress: collapse ? {} : formAddressRules,
    },
  })

  useEffect(() => {
    setCollapse(values.useHomeAsShipping)
  }, [values.useHomeAsShipping])

  function onSubmit() {
    if (!validate().hasErrors) {
      submit('address', values)
    }
  }

  return (
    <Grid test-id='step:address'>
      <Grid.Col span={12}>
        <FormLabel title='Home Address'>
          This is the address we’ll use to determine your permanent residence.
        </FormLabel>
      </Grid.Col>
      <AddressForm
        label='Home address'
        addressInputProps={getInputProps('homeAddress.address')}
        addressTwoInputProps={getInputProps('homeAddress.addressTwo')}
        cityInputProps={getInputProps('homeAddress.city')}
        stateInputProps={getInputProps('homeAddress.state')}
        zipInputProps={getInputProps('homeAddress.zipCode')}
        disabled={saving}
        testIdSuffix='-home'
      />
      <Grid.Col span={12}>
        <FormLabel title='Shipping address'>
          This is the address we’ll use to send you urine drug screens and any other materials we
          might need to mail.
        </FormLabel>
      </Grid.Col>
      <Grid.Col span={12}>
        <Checkbox
          label='My shipping address is the same as my home address'
          disabled={saving}
          test-id='input:same-address'
          {...getInputProps('useHomeAsShipping', { type: 'checkbox' })}
        />
      </Grid.Col>
      {!collapse && (
        <AddressForm
          label='Shipping address'
          addressInputProps={getInputProps('shippingAddress.address')}
          addressTwoInputProps={getInputProps('shippingAddress.addressTwo')}
          cityInputProps={getInputProps('shippingAddress.city')}
          stateInputProps={getInputProps('shippingAddress.state')}
          zipInputProps={getInputProps('shippingAddress.zipCode')}
          disabled={saving}
          testIdSuffix='-shipping'
        />
      )}
      <Grid.Col span={12}>
        <Divider my='md' />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TimeZoneSelect
          test-id='input:time-zone'
          disabled={saving}
          {...getInputProps('timezone')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TaskButton onSubmit={onSubmit} isLastStep loading={saving} />
      </Grid.Col>
    </Grid>
  )
}
