import {
  Group,
  Menu,
  MoreVerticalIcon,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  Text,
  TitleTwo,
} from '@shared/components'
import { AppointmentInfo, getVisitTypeName } from '@shared/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '../../../common/components'
import { useAuth } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { VisitInfo } from './VisitInfo'

export const VisitCard = (visit: AppointmentInfo) => {
  const navigate = useNavigate()
  return (
    <Card title='Upcoming visit'>
      <Group position='apart' align='flex-start'>
        <TitleTwo>{getVisitTypeName(visit.visitType)}</TitleTwo>
        {visit.canReschedule && visit.visitType === 'Initial Visit' && (
          <Menu position='bottom-end'>
            <Menu.Target>
              <SecondaryButton size='xs' leftIcon={<MoreVerticalIcon />} />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                onClick={() =>
                  navigate(
                    `${routes.portal.index}/${routes.portal.children.reschedule.index}/${visit.id}`,
                  )
                }
              >
                Reschedule visit
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  navigate(
                    `${routes.portal.index}/${routes.portal.children.intakeVisitStandbyList}`,
                  )
                }
              >
                Edit preferences
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        )}
      </Group>
      <VisitInfo {...visit} />
    </Card>
  )
}

export const EmptyVisitCard = ({ title }: { title: string }) => {
  const { currentUser } = useAuth()
  const patientStatus = currentUser?.data?.statuses?.patient
  const isPatientDischarged = patientStatus === 'discharged'
  const navigate = useNavigate()

  const startReconnectFlow = () => {
    navigate(routes.reconnect.index)
  }

  if (!isPatientDischarged) {
    return (
      <Card title={title}>
        <Text>No upcoming visits</Text>
      </Card>
    )
  }

  return (
    <Card title={title}>
      <Text> No upcoming visits. Interested in starting with Ophelia again? </Text>
      <ResponsiveBox
        mobile={
          <PrimaryButton
            test-id='button:reschedule-call@mobile'
            fullWidth
            onClick={startReconnectFlow}
          >
            Reconnect
          </PrimaryButton>
        }
        desktop={
          <PrimaryButton test-id='button:reschedule-call@desktop' onClick={startReconnectFlow}>
            Reconnect
          </PrimaryButton>
        }
      />
    </Card>
  )
}
