import React from 'react'
import { BillingAgreementStep } from './FinancialAgreementStep'
import { TaskHeader } from './TaskHeader'
import { usePatientFormTask } from './use-patient-task'
import { FallbackContent, Skeletons } from '../../../common/components'
import { Box } from '@shared/components'

export const FinancialAgreementTask = () => {
  const { payload, status, currentStep, submit, isSaving } = usePatientFormTask({
    type: 'financial-agreement',
    totalSteps: 1,
    breadcrumb: 'Payment info & financial agreement',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <Box test-id='page:financial-agreement-task'>
      <TaskHeader title='Financial Agreement' current={currentStep} total={1} />
      <BillingAgreementStep
        initialValues={payload}
        submit={data => submit('', data)}
        saving={isSaving}
      />
    </Box>
  )
}
