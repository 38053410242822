import './polyfill'
// Intentional blank space before other imports to prevent sorting on save

import {
  BannerProvider,
  Global,
  MantineProvider,
  MonitoringProvider,
  Notifications,
  TWILIGHT_FONT_FAMILIES,
  TWILIGHT_FONT_FILE_NAMES,
  TWILIGHT_THEME,
} from '@shared/components'
import { isAxiosError } from 'axios'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import { LaunchDarklyProvider } from './common/components'
import { Config } from './common/config'
import { initCustomerIO } from './common/customerio'
import * as FullStory from './common/fullstory'
import { AuthProvider } from './common/hooks'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (isAxiosError(error)) {
          const errorStatus = error.response?.status
          // Only retry 5xx errors
          if (errorStatus && errorStatus < 500) {
            return false
          }
        }

        return failureCount < 3
      },
    },
  },
})

void FullStory.init()
initCustomerIO()

const { FONT_CDN_BASE_URL } = Config
const { regular, bold, pro } = TWILIGHT_FONT_FILE_NAMES

const container = document.getElementById('app')
const root = createRoot(container as HTMLElement)

root.render(
  <BrowserRouter>
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: TWILIGHT_FONT_FAMILIES.regular,
            src: `url(${new URL(`/fonts/${regular}`, FONT_CDN_BASE_URL)}) format('woff2')`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: TWILIGHT_FONT_FAMILIES.bold,
            src: `url(${new URL(`/fonts/${bold}`, FONT_CDN_BASE_URL)}) format('woff2')`,
            fontWeight: 700,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            src: `url(${new URL(`/fonts/${pro}`, FONT_CDN_BASE_URL)}) format('woff2')`,
            fontFamily: TWILIGHT_FONT_FAMILIES.pro,
            fontWeight: 900,
            fontStyle: 'normal',
          },
        },
      ]}
    />
    <MonitoringProvider client={FullStory.page} storage={window.sessionStorage}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <MantineProvider theme={TWILIGHT_THEME} withNormalizeCSS>
            <Notifications />
            <LaunchDarklyProvider>
              <BannerProvider>
                <App />
              </BannerProvider>
              <ReactQueryDevtools initialIsOpen={false} position='bottom-left' />
            </LaunchDarklyProvider>
          </MantineProvider>
        </AuthProvider>
      </QueryClientProvider>
    </MonitoringProvider>
  </BrowserRouter>,
)
