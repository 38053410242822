import {
  ArrowLeftIcon,
  Group,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  useLifecycle,
} from '@shared/components'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { referralsApi } from '../../../api'
import { useReferralPartner } from '../../../hooks/use-partner'
import { routes } from '../../../routes'

export const PartnerReferralSent = () => {
  const { partnerPath, partnerId } = useReferralPartner()

  const { sessionId } = useParams()

  useLifecycle({
    onMount: () => {
      if (sessionId) {
        void referralsApi.createReferralForSessionIfNotExists({
          sessionId,
          referralPartnerId: partnerId,
        })
      }
    },
  })

  return (
    <Stack spacing='md'>
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              style={{ margin: 'auto' }}
              component={Link}
              to={`${routes.welcome.index}${partnerPath}`}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              component={Link}
              to={`${routes.welcome.index}${partnerPath}`}
            >
              Back
            </TertiaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
