import { SearchIcon, Select, SelectProps } from '@shared/components'
import { INSURANCE_OTHER_OPTION, matchesSearch } from '@shared/utils'
import React from 'react'

type InsuranceSelectProps = {
  placeholder?: string
} & SelectProps
export const InsuranceSelect = ({ placeholder = 'Search...', ...props }: InsuranceSelectProps) => {
  return (
    <Select
      icon={<SearchIcon />}
      searchable
      clearable
      placeholder={placeholder}
      label='Insurance plan'
      filter={(value, selectItem) =>
        selectItem.value.includes(INSURANCE_OTHER_OPTION.value) ||
        matchesSearch({ searchValue: value, itemValue: selectItem.value })
      }
      {...props}
    />
  )
}
