import React from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { routes } from '../common/routes'
import { useQueryParams } from '../common/hooks'

export const RescheduleApp = () => {
  const { appointmentId } = useParams<{ appointmentId: string }>()
  const query = useQueryParams()
  query.append('source', 'ophelia')

  return (
    <Navigate
      replace
      to={`${routes.portal.index}/${
        routes.portal.children.reschedule.index
      }/${appointmentId}?${query.toString()}`}
    />
  )
}
