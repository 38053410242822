import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Group,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  Textarea,
} from '@shared/components'
import React, { useState } from 'react'
import { MotivationsForTreatmentDrawer } from '../MotivationsForTreatmentDrawer'
import { WidgetProps } from '../PromptContentWidget'

type MotivationsForTreatmentFormType = {
  motivations_description: string
}

export const MotivationsForTreatmentForm = ({ response, onBack, onSubmit }: WidgetProps) => {
  const [showExamples, setShowExamples] = useState<boolean>(false)

  const form = useForm<MotivationsForTreatmentFormType>({
    initialValues: {
      motivations_description: (response?.motivations_description?.value as string) || '',
    },
  })

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    onSubmit({
      value: {
        contextKey: 'custom',
      },
      motivations_description: {
        contextKey: 'custom',
        value: form.values.motivations_description,
      },
    })
  }

  return (
    <Stack spacing='md' test-id='content:motivations-for-treatment-form'>
      <Text>
        {`Your "why" is the biggest reason you're here. It can help carry you through challenging
        times. Take your time to jot it down below.`}
      </Text>
      <Textarea
        placeholder='Type here...'
        label='I want to join Ophelia so that...'
        {...form.getInputProps('motivations_description')}
        test-id='input:motivations_description'
      />
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' align='center'>
            <SecondaryButton
              test-id='button:examples'
              fullWidth
              onClick={() => setShowExamples(true)}
            >
              {`Examples of a "why"`}
            </SecondaryButton>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Submit
            </PrimaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <Group>
              <SecondaryButton test-id='button:examples' onClick={() => setShowExamples(true)}>
                {`Examples of a "why"`}
              </SecondaryButton>
              <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                Submit
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
      <MotivationsForTreatmentDrawer opened={showExamples} onClose={() => setShowExamples(false)} />
    </Stack>
  )
}
