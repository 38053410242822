import {
  Alert,
  ArrowRightIcon,
  Box,
  EM_DASH,
  HeartIcon,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  XIcon,
  emojiMap,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { PatientApi } from '@shared/types'
import { name } from '@shared/utils'
import head from 'lodash/head'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as TaskCompletionAnimation from '../../assets/complete.json'
import { getFutureAppointments, patientApi } from '../../common/api'
import { default as AnimationModal } from '../../common/components/AnimationModal'
import { ReferFriendModal } from '../../common/components/ReferFriendModal'
import { CardMasonry } from '../../common/components/card/CardMasonry'
import { useAuth, usePortalDims, usePortalMutation, usePortalQuery } from '../../common/hooks'
import { routes } from '../../common/routes'
import { sendPageEvent } from '../../common/rudderstack'
import { setSessionStorageItem } from '../../common/storage'
import { BalanceCard } from './billing/BalanceCard'
import { DashboardAnimation, DashboardState } from './dashboardTypes'
import { PrescriptionsCard } from './prescriptions/PrescriptionsCard'
import { VisitsCard } from './visits/VisitsCard'

export const Dashboard = () => {
  const { currentUser } = useAuth()
  const { isDesktop } = usePortalDims()
  const { banner, showBanner, hideBanner } = useBanner()
  const [showAnimation, setShowAnimation] = useState<DashboardAnimation>(null)
  const [showReferFriendsPopup, setShowReferFriendsPopup] = useState(false)
  const [dashboardAlerts, setDashboardAlerts] = useState<
    PatientApi['GET /dashboard-alerts']['res']
  >([])
  const navigate = useNavigate()

  const location = useLocation() as {
    state?: DashboardState
  }

  useLifecycle({
    onMount: () => {
      sendPageEvent('Dashboard')
      setShowAnimation(location.state?.showAnimation ?? null)
      setSessionStorageItem(
        'has_shown_refer_friends_interstitial',
        location.state?.showReferFriendsPopup ?? false,
      )
      setShowReferFriendsPopup(location.state?.showReferFriendsPopup ?? false)
    },
    onUnmount: () => {
      if (currentUser?.data?.statuses.patient !== 'discharged') {
        hideBanner?.()
      }
    },
  })

  const { data: configData } = usePortalQuery('GET /configs')
  usePortalQuery('GET /dashboard-alerts', undefined, {
    onSuccess: data => setDashboardAlerts(data),
  })

  const dismissDashboardAlert = usePortalMutation('POST /dashboard-alerts/dismiss/:type/:id')

  const welcomeName = name({
    first:
      currentUser?.data?.personalData?.preferredName || currentUser?.data?.personalData?.firstName,
  }).full()

  const futureVisitsQuery = useQuery(...useMemo(getFutureAppointments, []))
  const futureVisits = futureVisitsQuery.data

  const [prescriptionsQueryKey, prescriptionsQueryFn] = patientApi.getQuery('GET /prescriptions', {
    query: { type: 'current' },
  })
  const prescriptionsQuery = useQuery(prescriptionsQueryKey, prescriptionsQueryFn)
  const prescriptions = prescriptionsQuery.data || []

  const { data, isLoading: areInvoicesLoading } = usePortalQuery('GET /invoices')

  const { aggregateInvoiceInfo } = data || {}

  const { unpaidInvoices, pastDueInvoices, totalBalanceDueInDollars, pastBalanceDueInDollars } =
    aggregateInvoiceInfo || {
      unpaidInvoices: [],
      pastDueInvoices: [],
      totalBalanceDueInDollars: 0,
      pastBalanceDueInDollars: 0,
    }

  const taskCardsQuery = useQuery(...patientApi.getQuery('GET /tasks'))
  const tasks = taskCardsQuery.data?.tasks

  const isLoading =
    futureVisitsQuery.isLoading || prescriptionsQuery.isLoading || areInvoicesLoading

  const shouldShowBanner =
    !taskCardsQuery.isLoading && !futureVisitsQuery.isLoading && !banner && tasks && futureVisits

  useEffect(() => {
    if (shouldShowBanner) {
      const areRequiredTasksComplete =
        tasks.filter(task => task.requiredFor?.includes('intake-visit')).length === 0
      if (
        areRequiredTasksComplete &&
        head(futureVisits.filter(visit => visit.visitType === 'Initial Visit'))
      ) {
        showBanner({
          type: 'success',
          label: 'Nice! You finished all required tasks for your intake visit',
          dismissable: true,
        })
      }
    } else if (configData?.outageBanner?.enabled) {
      showBanner({
        type: 'warning',
        label: configData.outageBanner.message,
      })
    }
  }, [shouldShowBanner, showBanner, tasks, futureVisits, configData?.outageBanner])

  const SharedComponents = (
    <>
      <AnimationModal
        onClose={() => setShowAnimation(null)}
        opened={showAnimation === 'all-tasks-complete'}
        animation={TaskCompletionAnimation}
        title='Great job!'
        tagline="You've completed all tasks."
        timeout='3 sec'
      />
      <AnimationModal
        onClose={() => setShowAnimation(null)}
        opened={showAnimation === 'thank-you-note-sent'}
        animation={TaskCompletionAnimation}
        title='All done!'
        tagline='Your thank you note has been sent.'
        timeout='3 sec'
      />
      <ReferFriendModal
        opened={showReferFriendsPopup}
        onClose={() => setShowReferFriendsPopup(false)}
      />
    </>
  )

  const ReferFriendsAlert = (
    <Alert
      onClick={() => navigate(`${routes.portal.index}/${routes.portal.children.referrals}`)}
      key='refer-friends'
      variant='primary'
      title={<Text color={colors => colors.text[2]}>Refer friends, save lives</Text>}
      icon={<HeartIcon />}
      actionIcon={<ArrowRightIcon />}
    >
      <Text size='xs' color={colors => colors.text[2]}>
        Share Ophelia with a friend or loved one who isn&apos;t in treatment yet and tell them you
        can do it together.
      </Text>
    </Alert>
  )

  const DashboardAlerts = dashboardAlerts?.map(({ type, data }) => {
    if (type === 'thank-you-note') {
      return (
        <Alert
          variant='secondary'
          key={data.oid}
          title={<Text color={colors => colors.text[1]}>{data.message}</Text>}
          actionIcon={
            <TertiaryButton
              leftIcon={<XIcon />}
              onClick={() => {
                dismissDashboardAlert.mutate({ params: { type: 'thank-you-note', id: data.oid } })
                setDashboardAlerts(dashboardAlerts =>
                  dashboardAlerts.filter(dashboardAlert => dashboardAlert.data.oid !== data.oid),
                )
              }}
            />
          }
        >
          <Text size='xs' color={colors => colors.text[1]}>{`${EM_DASH} ${
            data.from || 'Your friend'
          }`}</Text>
        </Alert>
      )
    }

    return null
  })

  if (isDesktop) {
    return (
      <Stack spacing='lg' test-id='page:dashboard'>
        {DashboardAlerts}
        <TitleTwo>
          {`${emojiMap['waving hand']} Happy to see you`}
          {welcomeName && `, ${welcomeName}`}
        </TitleTwo>
        <CardMasonry isLoading={isLoading}>
          <VisitsCard visits={futureVisits ?? []} key='visits' />
          {ReferFriendsAlert}
          <PrescriptionsCard prescriptions={prescriptions} key='prescriptions' />
          <BalanceCard
            key='balance-card'
            unpaidInvoices={unpaidInvoices}
            pastDueInvoices={pastDueInvoices}
            totalBalanceDueInDollars={totalBalanceDueInDollars}
            pastBalanceDueInDollars={pastBalanceDueInDollars}
          />
        </CardMasonry>
        {SharedComponents}
      </Stack>
    )
  }

  return (
    <Stack spacing='lg' test-id='page:dashboard'>
      {DashboardAlerts}
      <TitleTwo>
        {`${emojiMap['waving hand']} Happy to see you`}
        {welcomeName && `, ${welcomeName}`}
      </TitleTwo>
      <CardMasonry isLoading={isLoading}>
        {[
          <VisitsCard visits={futureVisits ?? []} key='visits' />,
          <Box key='more-visits' style={{ float: 'left' }}>
            <TertiaryButton
              component={Link}
              to={routes.portal.children.visits}
              rightIcon={<ArrowRightIcon />}
            >
              All visits
            </TertiaryButton>
          </Box>,
          ReferFriendsAlert,
          <PrescriptionsCard prescriptions={prescriptions} key='prescriptions' />,
          <Box key='more-prescriptions' style={{ float: 'left' }}>
            <TertiaryButton
              component={Link}
              to={routes.portal.children.prescriptions}
              rightIcon={<ArrowRightIcon />}
            >
              All prescriptions
            </TertiaryButton>
          </Box>,
          <BalanceCard
            key='balance-card'
            unpaidInvoices={unpaidInvoices}
            pastDueInvoices={pastDueInvoices}
            totalBalanceDueInDollars={totalBalanceDueInDollars}
            pastBalanceDueInDollars={pastBalanceDueInDollars}
          />,
          <Box key='billing-insurance' style={{ float: 'left' }}>
            <TertiaryButton
              component={Link}
              to={routes.portal.children.billing.index}
              rightIcon={<ArrowRightIcon />}
            >
              Billing & invoices
            </TertiaryButton>
          </Box>,
        ]}
      </CardMasonry>
      {SharedComponents}
    </Stack>
  )
}
