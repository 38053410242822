import { useForm } from '@mantine/form'
import { Grid, Select, skipIfOtherField, TextInput, validateWith } from '@shared/components'
import { HDYHAUOptions } from '@shared/types'
import React from 'react'
import { isRequired } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const HDYHAUForm = ({
  initialValues,
  submit,
  saving,
}: TaskStepProps<'how-did-you-hear-about-us'>) => {
  const form = useForm({
    initialValues,
    validate: {
      howTheyHeardAboutUs: isRequired,
      nameOfHealthcareProvider: validateWith(
        skipIfOtherField('howTheyHeardAboutUs', 'not', 'provider'),
        isRequired,
      ),
      nameOfOrganization: validateWith(
        skipIfOtherField('howTheyHeardAboutUs', 'not', 'provider'),
        isRequired,
      ),
      whichConference: validateWith(
        skipIfOtherField('howTheyHeardAboutUs', 'not', 'conference'),
        isRequired,
      ),
      shareHelpfulInfo: validateWith(
        skipIfOtherField('howTheyHeardAboutUs', 'not', 'other'),
        isRequired,
      ),
    },
  })

  const onSubmit = function onSubmit() {
    if (!form.validate().hasErrors) {
      submit('', form.values)
    }
  }

  return (
    <Grid>
      <Grid.Col span={12}>
        <Select
          nothingFound='No options'
          placeholder='Select one...'
          data={HDYHAUOptions}
          {...form.getInputProps('howTheyHeardAboutUs')}
        />
      </Grid.Col>
      {form.values.howTheyHeardAboutUs === 'provider' && (
        <>
          <Grid.Col span={12} md={6}>
            <TextInput
              label='Name of health care provider'
              placeholder='Name of health care provider'
              disabled={saving}
              {...form.getInputProps('nameOfHealthcareProvider')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <TextInput
              label='Name of organization'
              placeholder='Name of organization'
              disabled={saving}
              {...form.getInputProps('nameOfOrganization')}
            />
          </Grid.Col>
        </>
      )}
      {form.values.howTheyHeardAboutUs === 'conference' && (
        <Grid.Col span={12} md={6}>
          <TextInput
            label='Which conference?'
            placeholder='Which conference?'
            disabled={saving}
            {...form.getInputProps('whichConference')}
          />
        </Grid.Col>
      )}
      {form.values.howTheyHeardAboutUs === 'other' && (
        <Grid.Col span={12} md={6}>
          <TextInput
            label='Share any helpful info'
            placeholder='Share any helpful info'
            disabled={saving}
            {...form.getInputProps('shareHelpfulInfo')}
          />
        </Grid.Col>
      )}
      <Grid.Col span={12}>
        <TaskButton onSubmit={onSubmit} isLastStep loading={saving} />
      </Grid.Col>
    </Grid>
  )
}
