import { z } from 'zod'
import { ApiSchemas } from '../../..'

const PostInternalNoteReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
    body: z.object({
      content: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const GetInternalNotesReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      content: z.string(),
    }),
  }),
}

export const NoteRouteSchemas = {
  'GET /patients/:patientId/notes/internal': GetInternalNotesReqRes,
  'POST /patients/:patientId/notes/internal': PostInternalNoteReqRes,
} satisfies ApiSchemas
