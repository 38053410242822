import dayjs from 'dayjs'
import { IANAZone } from '.'

const getTimeZoneLabel = (timezone: string): string =>
  timezone.replace('America/', '').split('_').join(' ')

export const timeZones = [
  'America/Adak',
  'America/Anchorage',
  'America/Boise',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/Indiana/Knox',
  'America/Indiana/Marengo',
  'America/Indiana/Petersburg',
  'America/Indiana/Tell_City',
  'America/Indiana/Vevay',
  'America/Indiana/Vincennes',
  'America/Indiana/Winamac',
  'America/Indianapolis',
  'America/Juneau',
  'America/Kentucky/Monticello',
  'America/Los_Angeles',
  'America/Louisville',
  'America/Menominee',
  'America/Metlakatla',
  'America/New_York',
  'America/Nome',
  'America/North_Dakota/Beulah',
  'America/North_Dakota/Center',
  'America/North_Dakota/New_Salem',
  'America/Phoenix',
  'America/Sitka',
  'America/Yakutat',
  'Pacific/Honolulu',
].map(value => ({
  value,
  label: getTimeZoneLabel(value),
}))

export function getTimeZones() {
  const today = dayjs()
  return timeZones
    .map(({ value, label }) => ({
      value,
      label,
      time: today.tz(value).format('h:mma z'),
    }))
    .sort((a, b) => today.tz(a.value).utcOffset() - today.tz(b.value).utcOffset())
}

export const getTimeZoneWithTime = (timezone: string): string =>
  `${getTimeZoneLabel(timezone)} - ${dayjs().tz(timezone).format('h:mma z')}`

export const getFormattedDatetimeWithTimeZone = ({
  datetime,
  timeZone,
}: {
  datetime: string
  timeZone: IANAZone
}): string => {
  return dayjs(datetime).tz(timeZone).format('ddd, MMM D, YYYY h:mma z')
}

export const getFormattedTimeWithTimeZone = ({
  datetime,
  timeZone,
}: {
  datetime: string
  timeZone: IANAZone
}): string => {
  return dayjs(datetime).tz(timeZone).format('h:mma z')
}
