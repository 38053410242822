import { Alert, FlagIcon, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
import React from 'react'

export const CareTransferIntakeTaskExpirationAlert = ({
  expirationDatetime,
}: {
  expirationDatetime: string
}) => {
  return (
    <Alert variant='warning' icon={<FlagIcon />}>
      <Text
        bold
        color={colors => colors.text[0]}
      >{`Your intake will be canceled if the tasks have not been completed by ${dayjs(
        expirationDatetime,
      ).format('h:mma z')} on ${dayjs(expirationDatetime).format('MM/DD/YYYY')}`}</Text>
    </Alert>
  )
}
