import { AxiosRequestConfig, Method } from 'axios'
import { ZodType, z } from 'zod'

// For stylistic + consistency reasons, only support the uppercase versions of the Axios HTTP methods
export type OpheliaMethod = Uppercase<Method>

type PathSegments<Path extends string> = Path extends `${infer SegmentA}/${infer SegmentB}`
  ? ParamOnly<SegmentA> | PathSegments<SegmentB>
  : ParamOnly<Path>

type ParamOnly<Segment extends string> = Segment extends `:${infer Param}` ? Param : never

type RouteParams<Path extends OpheliaOperation> = {
  [Key in PathSegments<Path>]: string
}

export type OpheliaSpec = Record<OpheliaOperation, OpheliaRequestResponse<OpheliaOperation>>

export type LunaSpec = Record<OpheliaOperation, LunaRequestResponse<OpheliaOperation>>

export type OpheliaRoute<T extends OpheliaOperation, V extends OpheliaRequestResponse<T>> = Record<
  T,
  V
>

export type LunaRoute<T extends OpheliaOperation, V extends LunaRequestResponse<T>> = Record<T, V>

export type OpheliaOperation = `${OpheliaMethod} /${string}`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OpheliaResponse = any

export type LunaResponse = {
  /**
   * As part of Luna's API, the response will always have a "data" field.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

export type OpheliaRequestClientParams = RouteParams<OpheliaOperation>

export type OpheliaRequestRouterParams<T> = Record<keyof T, string>

// This type is a copy of ParsedQs used by express
export type OpheliaClientRequestQuery = {
  [key: string]:
    | undefined
    | string
    | string[]
    | OpheliaClientRequestQuery
    | OpheliaClientRequestQuery[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OpheliaClientRequestBody = any

export type OpheliaRequest<T extends OpheliaOperation> = Partial<{
  params: RouteParams<T>
  query: OpheliaClientRequestQuery
  data: OpheliaClientRequestBody
}>

export type OpheliaRequestResponse<T extends OpheliaOperation> = {
  req: OpheliaRequest<T>
  res: OpheliaResponse
}

export type LunaRequestResponse<T extends OpheliaOperation> = {
  req: OpheliaRequest<T>
  res: LunaResponse
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface OpheliaClientRequestConfig<Params, Data, Query>
  // Omit the fields that are added during axios instantiation and route declaration (eg: 'GET /foo')
  extends Omit<AxiosRequestConfig<Data>, 'baseURL' | 'url' | 'method'> {
  params?: Params
  data?: Data
  query?: Query
}

export type RequestResponseSchema<Operation extends OpheliaOperation> = {
  req: ZodType<OpheliaRequestServer<Operation>>
  res: ZodType<LunaResponse>
  /*
   * When set to true, `requestSchemaStrictValidation` will cause any request validation
   * failures to throw an error and return a 400 status code to the client
   */
  requestSchemaStrictValidation?: boolean
}

export type ApiSchemas = {
  [K in OpheliaOperation]: RequestResponseSchema<K>
}

export type OpheliaRequestServer<T extends OpheliaOperation> = Partial<{
  params: RouteParams<T>
  query: OpheliaClientRequestQuery
  body: OpheliaClientRequestBody
}>

/*
 * This converts the server type to the client type since the client uses "data" and the server expects "body"
 * The Omit<T, 'data'> is to ensure that our zod schemas don't mistakenly include the "data" field instead of a "body" field
 */
type ServerToClient<T> = T extends { body: unknown }
  ? { data: T['body'] } & Omit<T, 'body'>
  : T extends { data: unknown }
  ? Omit<T, 'data'>
  : T

/*
 * This type takes in a key value pair of OpheliaOperation and the corresponding request and response schemas for that route
 * It returns a union of OpheliaRoutes ex: OpheliaRoute<'GET /patients', { req: { params: { patientId: string } }, res: Patient }>
 */

export type OpheliaApiDefinitionFromSchemas<Schemas extends ApiSchemas> = {
  [K in keyof Schemas]: K extends OpheliaOperation
    ? {
        req: ServerToClient<z.input<Schemas[K]['req']>> extends OpheliaRequest<K>
          ? ServerToClient<z.input<Schemas[K]['req']>>
          : never
        res: z.infer<Schemas[K]['res']>
        /*
         * When set to true, `requestSchemaStrictValidation` will cause any request validation
         * failures to throw an error and return a 400 status code to the client
         */
        requestSchemaStrictValidation?: boolean
      }
    : never
}
