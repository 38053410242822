export const GAD7_FORM_PROMPT_CONTEXT_KEYS = [
  'feeling_nervous',
  'not_being_able_to',
  'worrying_too_much',
  'trouble_relaxing',
  'being_so_restless',
  'becoming_easily_annoyed',
  'feeling_afraid',
] as const

export type Gad7FormPromptContextKey = (typeof GAD7_FORM_PROMPT_CONTEXT_KEYS)[number]

const FEELING_NERVOUS_PROMPT_RESPONSE_CONTEXT_KEYS = [
  'feeling_nervous_not_at_all',
  'feeling_nervous_several',
  'feeling_nervous_more_than_half',
  'feeling_nervous_nearly_every',
] as const

const NOT_BEING_ABLE_TO_PROMPT_RESPONSE_CONTEXT_KEYS = [
  'not_being_able_to_not_at_all',
  'not_being_able_to_several',
  'not_being_able_to_more_than_half',
  'not_being_able_to_nearly_every',
] as const

const WORRYING_TOO_MUCH_PROMPT_RESPONSE_CONTEXT_KEYS = [
  'worrying_too_much_not_at_all',
  'worrying_too_much_several',
  'worrying_too_much_more_than_half',
  'worrying_too_much_nearly_every',
] as const

const TROUBLE_RELAXING_PROMPT_RESPONSE_CONTEXT_KEYS = [
  'trouble_relaxing_not_at_all',
  'trouble_relaxing_several',
  'trouble_relaxing_more_than_half',
  'trouble_relaxing_nearly_every',
] as const

const BEING_SO_RESTLESS_PROMPT_RESPONSE_CONTEXT_KEYS = [
  'being_so_restless_not_at_all',
  'being_so_restless_several',
  'being_so_restless_more_than_half',
  'being_so_restless_nearly_every',
] as const

const BECOMING_EASILY_ANNOYED_PROMPT_RESPONSE_CONTEXT_KEYS = [
  'becoming_easily_annoyed_not_at_all',
  'becoming_easily_annoyed_several',
  'becoming_easily_annoyed_more_than_half',
  'becoming_easily_annoyed_nearly_every',
] as const

const FEELING_AFRAID_PROMPT_RESPONSE_CONTEXT_KEYS = [
  'feeling_afraid_not_at_all',
  'feeling_afraid_several',
  'feeling_afraid_more_than_half',
  'feeling_afraid_nearly_every',
] as const

export const GAD7_FORM_PROMPT_RESPONSE_CONTEXT_KEYS = [
  ...FEELING_NERVOUS_PROMPT_RESPONSE_CONTEXT_KEYS,
  ...NOT_BEING_ABLE_TO_PROMPT_RESPONSE_CONTEXT_KEYS,
  ...WORRYING_TOO_MUCH_PROMPT_RESPONSE_CONTEXT_KEYS,
  ...TROUBLE_RELAXING_PROMPT_RESPONSE_CONTEXT_KEYS,
  ...BEING_SO_RESTLESS_PROMPT_RESPONSE_CONTEXT_KEYS,
  ...BECOMING_EASILY_ANNOYED_PROMPT_RESPONSE_CONTEXT_KEYS,
  ...FEELING_AFRAID_PROMPT_RESPONSE_CONTEXT_KEYS,
] as const

export type Gad7FormPromptResponseContextKey =
  (typeof GAD7_FORM_PROMPT_RESPONSE_CONTEXT_KEYS)[number]
