import { PrimaryButton, Stack, TitleTwo } from '@shared/components'
import { PatientApi, arrayIncludes } from '@shared/types'
import React from 'react'
import { Link } from 'react-router-dom'
import { useOnboardingDims } from '../common/hooks'
import { routes } from '../common/routes'
import { PastVisitEntry } from '../portal/pages/visits/PastVisitEntry'
import { ConfirmationReferralLink } from './ConfirmationReferralLink'

export const CannotConfirmAppointment = ({
  isWelcomeCall,
  appointmentInfo,
}: {
  isWelcomeCall: boolean
  appointmentInfo: PatientApi['GET /appointments/:id/confirm']['res']
}) => {
  const { isMobile } = useOnboardingDims()
  const unattended = arrayIncludes(
    ['canceled', 'late-cancellation', 'no-show'],
    appointmentInfo.status,
  )

  return (
    <Stack spacing='lg' test-id='page:appointment-confirmation'>
      <TitleTwo test-id='title:cannot-confirm-appointment'>
        This visit can no longer be confirmed
      </TitleTwo>
      <PastVisitEntry {...appointmentInfo} slim />
      {!isWelcomeCall && (
        <PrimaryButton
          test-id='button:go-to-portal'
          fullWidth={isMobile}
          component={Link}
          to={`${routes.welcome.index}/${routes.welcome.children.signin}`}
        >
          Go to the portal
        </PrimaryButton>
      )}

      {(unattended || isMobile) && (
        <ConfirmationReferralLink patientId={appointmentInfo.patientId} />
      )}
    </Stack>
  )
}
