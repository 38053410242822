import { Avatar, Box, Group, Stack, Text, TitleFour, useMantineTheme } from '@shared/components'
import { Achievement, BadgeContentType } from '@shared/types'
import React from 'react'
import { AchievedIcon } from '../AchievedIcon'

export const SortedBadgesSection = ({
  title,
  subtitle,
  sortedBadges,
  achievements,
}: {
  title: string
  subtitle: string
  sortedBadges: BadgeContentType[]
  achievements: Achievement[]
}) => {
  const theme = useMantineTheme()

  return (
    <Stack>
      <TitleFour>{title}</TitleFour>
      <Text>{subtitle}</Text>
      <Group>
        {sortedBadges.map(badge => {
          const isBadgeAchieved = achievements.find(achievement => achievement.name === badge.value)
          if (isBadgeAchieved) {
            return (
              <AchievedIcon
                content={badge}
                achievement={isBadgeAchieved}
                key={isBadgeAchieved.oid}
              />
            )
          }
          return (
            <Avatar
              size='lg'
              mx='sm'
              key={badge.value}
              src=''
              backgroundColor='none'
              style={{
                borderWidth: theme.other.sizes.border.md,
                borderStyle: 'dashed',
                borderColor: theme.other.colors.actions[1],
              }}
            >
              {/* This box prevents the Avatar from showing the default UserIcon */}
              <Box></Box>
            </Avatar>
          )
        })}
      </Group>
    </Stack>
  )
}
