import { useForm } from '@mantine/form'
import {
  Box,
  Grid,
  Group,
  PlusIcon,
  SelectItem,
  Stack,
  TertiaryButton,
  TitleFour,
  TrashIcon,
} from '@shared/components'
import { InsurancePayer, TaskPayload } from '@shared/types'
import { getInsurancePlanId } from '@shared/utils'
import React, { useMemo, useState } from 'react'
import {
  InsuranceInput,
  InsuranceInputFieldMap,
  makeFormInsuranceRules,
} from '../../../common/components/InsuranceInput'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

const primaryInsuranceInputFieldMap: InsuranceInputFieldMap<TaskPayload<'insurance'>> = {
  plan: 'insurancePlan',
  firstName: 'cardholderFirstName',
  lastName: 'cardholderLastName',
  birthday: 'cardholderBirthday',
  memberId: 'insuranceMemberId',
  groupId: 'insuranceGroupId',
  rxBin: 'insuranceRxBin',
  isPrimarySubscriber: 'isPrimarySubscriber',
  subscriberFirstName: 'primarySubscriberFirstName',
  subscriberLastName: 'primarySubscriberLastName',
  subscriberBirthday: 'primarySubscriberBirthday',
  cardFront: 'frontInsuranceCard',
  cardBack: 'backInsuranceCard',
}

const secondaryInsuranceInputFieldMap: InsuranceInputFieldMap<TaskPayload<'insurance'>> = {
  plan: 'secondaryInsurancePlan',
  firstName: 'secondaryCardholderFirstName',
  lastName: 'secondaryCardholderLastName',
  birthday: 'secondaryCardholderBirthday',
  memberId: 'secondaryInsuranceMemberId',
  groupId: 'secondaryInsuranceGroupId',
  rxBin: 'secondaryInsuranceRxBin',
  isPrimarySubscriber: 'secondaryIsPrimarySubscriber',
  subscriberFirstName: 'secondaryPrimarySubscriberFirstName',
  subscriberLastName: 'secondaryPrimarySubscriberLastName',
  subscriberBirthday: 'secondaryPrimarySubscriberBirthday',
  cardFront: 'secondaryFrontInsuranceCard',
  cardBack: 'secondaryBackInsuranceCard',
}

export const InsuranceDetailsStep = ({
  insurancePayers,
  initialValues,
  submit,
  saving,
}: TaskStepProps<'insurance'> & { insurancePayers: InsurancePayer[] | undefined }) => {
  const [hasSecondaryInsurance, setHasSecondaryInsurance] = useState(
    Boolean(initialValues.secondaryInsurancePlan),
  )
  const insurances = useMemo(
    () =>
      (insurancePayers ?? []).map<SelectItem>(({ payerId, name }) => ({
        label: name,
        value: getInsurancePlanId(payerId, name),
      })),
    [insurancePayers],
  )

  const form = useForm({
    initialValues,
    validate: {
      ...makeFormInsuranceRules(primaryInsuranceInputFieldMap, { insurances, withCards: false }),
      ...makeFormInsuranceRules(secondaryInsuranceInputFieldMap, {
        insurances,
        ignore: !hasSecondaryInsurance,
        withCards: false,
      }),
    },
  })

  function onSubmit() {
    if (!form.validate().hasErrors) {
      submit('insurance', form.values)
    }
  }

  return (
    <Box test-id='step:insurance-info'>
      {hasSecondaryInsurance && <TitleFour mb='lg'>Primary insurance</TitleFour>}
      <Grid>
        <InsuranceInput
          test-id='primary-insurance'
          form={form}
          insurances={insurances}
          saving={saving}
          map={primaryInsuranceInputFieldMap}
        />
      </Grid>
      {hasSecondaryInsurance && (
        <>
          <Group my='lg' position='apart' align='center'>
            <TitleFour>Other insurance</TitleFour>
            <TertiaryButton
              onClick={() => {
                form.setValues({
                  secondaryInsurancePlan: '',
                  secondaryCardholderFirstName: '',
                  secondaryCardholderLastName: '',
                  secondaryCardholderBirthday: '',
                  secondaryInsuranceMemberId: '',
                  secondaryInsuranceGroupId: '',
                  secondaryInsuranceRxBin: '',
                  secondaryIsPrimarySubscriber: false,
                  secondaryPrimarySubscriberFirstName: '',
                  secondaryPrimarySubscriberLastName: '',
                  secondaryPrimarySubscriberBirthday: '',
                  secondaryFrontInsuranceCard: '',
                  secondaryBackInsuranceCard: '',
                })

                setHasSecondaryInsurance(!hasSecondaryInsurance)
              }}
              leftIcon={<TrashIcon />}
            >
              Remove
            </TertiaryButton>
          </Group>
          <Grid>
            <InsuranceInput
              form={form}
              insurances={insurances}
              saving={saving}
              map={secondaryInsuranceInputFieldMap}
            />
          </Grid>
        </>
      )}
      <Stack mt='md' spacing='lg'>
        <TaskButton onSubmit={onSubmit} isLastStep={false} loading={saving} />
        {!hasSecondaryInsurance && (
          <TertiaryButton
            onClick={() => setHasSecondaryInsurance(!hasSecondaryInsurance)}
            leftIcon={<PlusIcon />}
          >
            Other insurance
          </TertiaryButton>
        )}
      </Stack>
    </Box>
  )
}
