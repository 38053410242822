import { ArrowLeftIcon, Space, Stack, TertiaryButton, TitleTwo } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeletons } from '../../../common/components'
import { usePortalQuery } from '../../../common/hooks'
import { FinancialAgreementV5 } from '../../../documents/FinancialAgreementV5'
import { SignedAgreementInfo } from './SignedAgreementInfo'

export const FinancialAgreementV5Page = () => {
  const navigate = useNavigate()
  const consentQuery = usePortalQuery('GET /consents/:type/:version', {
    params: {
      type: 'financial',
      version: '5',
    },
  })

  if (consentQuery.isLoading) {
    return <Skeletons />
  }

  return (
    <Stack>
      <TitleTwo>Financial agreement</TitleTwo>
      <FinancialAgreementV5 />
      {consentQuery.data && (
        <>
          <Space h='sm' />
          <SignedAgreementInfo
            signature={consentQuery.data?.signature}
            date={consentQuery.data?.date}
            checkboxLabel='I acknowledge that I have read this authorization and I authorize the disclosure of my information by Ophelia to Stripe for the purpose described'
          />
        </>
      )}
      <TertiaryButton
        leftIcon={<ArrowLeftIcon />}
        onClick={() => {
          navigate(-1)
        }}
      >
        Back
      </TertiaryButton>
    </Stack>
  )
}
