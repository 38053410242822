import { Card, Skeleton } from '@shared/components'
import React from 'react'

export const BalanceCardSkeleton = () => {
  return (
    <Card>
      <Skeleton mb='sm' height={12} width='10%' radius='sm' />
      <Skeleton mb='md' height={24} width='5%' radius='sm' />
      <Skeleton mb='md' height={12} width='20%' radius='sm' />
      <Skeleton mb='md' height={36} width='10%' radius='sm' />
      <Skeleton mb='sm' height={12} width='10%' radius='sm' />
    </Card>
  )
}
