import { Stack, Text, TitleTwo } from '@shared/components'
import React from 'react'
import { Card } from '../../../common/components'
import { ShareReferralLinkButtons } from '../../../common/components/ShareReferralLinkButtons'
import { usePortalDims } from '../../../common/hooks'

export const Referrals = () => {
  const { isMobile } = usePortalDims()

  return (
    <Stack>
      <TitleTwo>Refer friends</TitleTwo>
      <Card title='Save lives'>
        <TitleTwo>Know someone struggling with opioids?</TitleTwo>
        <Text>
          Refer them to Ophelia for evidence-based medication assisted treatment from the comfort of
          their home.
        </Text>
        <ShareReferralLinkButtons
          orientation={isMobile ? 'vertical' : 'horizontal'}
          source='portal_refer_friends'
          showPersonalizationOptions
        />
      </Card>
    </Stack>
  )
}
