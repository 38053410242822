import noop from 'lodash/noop'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { filesApi, storageApi } from '../api'

export function useGcsFile(fileId: string | undefined, onError: () => void = noop) {
  const [file, setFile] = useState<File>()
  const isFileMissing = Boolean(fileId) && !file

  /*
   * If file ID is present but the file is missing, it means we need to load
   * the file from GCS, components can locally set a file to avoid GCS fetching.
   */
  useQuery(
    ['filesApi.getFile', fileId],
    async () => {
      const { signedUrl } = await filesApi.getFileRead(fileId as string)
      return storageApi.getFile({ fileId: fileId as string, url: signedUrl })
    },
    {
      onSuccess: file => {
        setFile(file)
      },
      onError,
      enabled: isFileMissing,
    },
  )

  return { file, setFile, isFileMissing }
}
