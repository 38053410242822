import { CheckIcon, ExternalLinkText, List, ListItem, Text } from '@shared/components'
import React from 'react'

export const OtherResources = () => {
  return (
    <List
      spacing='md'
      icon={<CheckIcon />}
      styles={theme => ({ itemIcon: { color: theme.other.colors.actions[0] } })}
    >
      <ListItem>
        <ExternalLinkText href='https://ophelia.com/blog/how-to-help-someone-receiving-addiction-treatment'>
          Looking to help a friend?
        </ExternalLinkText>
      </ListItem>
      <ListItem>
        <Text>SAMHSA&apos;s National Helpline</Text>
        <ExternalLinkText href='tel:'>1-800-662-HELP</ExternalLinkText>
      </ListItem>
      <ListItem>
        <Text>Free or reduced cost services</Text>
        <ExternalLinkText href=''>Aunt Bertha</ExternalLinkText>
      </ListItem>
      <ListItem>
        <Text>Suicide Prevention Hotline</Text>
        <ExternalLinkText href='tel:'>1-800-273-TALK</ExternalLinkText>
      </ListItem>
      <ListItem>
        <Text>Veteran&apos;s Crisis Line</Text>
        <ExternalLinkText href='tel:'>1-800-273-TALK</ExternalLinkText>
      </ListItem>
      <ListItem>
        <Text>National Domestic Violence Hotline</Text>
        <ExternalLinkText href='tel:'>1-800-799-SAFE</ExternalLinkText>
      </ListItem>
    </List>
  )
}
