import { useLifecycle } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import * as FullStory from '../../../common/fullstory'
import { sendPageEvent, sendTrackEvent } from '../../../common/rudderstack'
import { StandbyListTaskForm } from './StandbyListTaskForm'
import { TaskHeader } from './TaskHeader'
import { usePatientFormTask } from './use-patient-task'

export const StandbyListTask = () => {
  const { payload, status, submit, isSaving } = usePatientFormTask({
    type: 'intake-visit-standby-list',
    breadcrumb: 'Notification preferences',
  })

  useLifecycle({
    onMount: () => {
      sendPageEvent('Standby List Task', { type: 'intake-visit-standby-list' })
      sendTrackEvent('Standby List Task Opened', { type: 'intake-visit-standby-list' })
      FullStory.event('Standby List Task Opened', { type: 'intake-visit-standby-list' })
    },
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <>
      <TaskHeader title='Should we reach out if something opens up sooner?' />
      <StandbyListTaskForm initialValues={payload} submit={submit} saving={isSaving} />
    </>
  )
}
