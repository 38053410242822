import { Stack, TitleTwo } from '@shared/components'
import React, { PropsWithChildren } from 'react'
import { SessionProgress } from '../../../common/components'

export type TaskHeaderProps = {
  title: string
  current?: number
  total?: number
  ['test-id']?: string
}

export const TaskHeader = ({
  title,
  current,
  total,
  children,
  ...props
}: PropsWithChildren<TaskHeaderProps>) => (
  <Stack mb='lg' spacing='lg'>
    <Stack spacing='xs'>
      <TitleTwo test-id={props['test-id']}>{title}</TitleTwo>
      {children}
    </Stack>
    {current && total && <SessionProgress current={current} total={total} />}
  </Stack>
)
