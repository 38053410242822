import { useForm } from '@mantine/form'
import { Grid, PrimaryButton, isAnySelected } from '@shared/components'
import { timeZones } from '@shared/utils'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { currentUserApi } from '../../../common/api'
import { FormLabel, FormSaveSection, TimeZoneSelect } from '../../../common/components'
import { useAuth } from '../../../common/hooks'

type Form = { timezone: string | undefined }

export const TimeZoneSection = ({
  editing,
  toggleEditing,
}: {
  editing: boolean
  toggleEditing: () => void
}) => {
  const { currentUser } = useAuth()
  const timezone = currentUser?.data?.account.timezone

  return (
    <>
      {editing ? (
        <TimezoneSectionEdit toggleEditing={toggleEditing} timezone={timezone} />
      ) : (
        <Grid>
          <Grid.Col span={12} md={6}>
            <FormLabel>{timezone?.replaceAll('_', ' ')}</FormLabel>
          </Grid.Col>
          <Grid.Col span={12}>
            <PrimaryButton mt='md' onClick={() => toggleEditing()}>
              Edit
            </PrimaryButton>
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}

export const TimezoneSectionEdit = ({
  toggleEditing,
  timezone,
}: {
  toggleEditing: () => void
  timezone: string | undefined
}) => {
  const queryClient = useQueryClient()

  const { getInputProps, validate, values } = useForm<Form>({
    initialValues: { timezone },
    validate: { timezone: isAnySelected(timeZones, 'Required') },
  })

  const updateCurrentUser = useMutation(currentUserApi.update)

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }

    const { timezone } = values

    updateCurrentUser.mutate(
      { account: { timezone } },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(['currentUserApi.retrieve'])
          toggleEditing()
        },
      },
    )
  }

  const saving = updateCurrentUser.isLoading

  return (
    <Grid>
      <Grid.Col span={12} md={6}>
        <TimeZoneSelect disabled={saving} {...getInputProps('timezone')} />
      </Grid.Col>
      <Grid.Col span={12}>
        <FormSaveSection loading={saving} onSubmit={onSubmit} onCancel={toggleEditing} />
      </Grid.Col>
    </Grid>
  )
}
