import { SegmentEventName } from '@shared/types'
import * as analytics from 'rudder-sdk-js'
import { Config } from './config'

analytics.load(Config.REACT_APP_RS_WRITE_KEY, Config.REACT_APP_RS_DATA_PLANE_URL)
analytics.ready(() => ({}))
export const VISITED_MY_OPHELIA = 'visitedMyOphelia'
export const alias = (uid: string) => {
  analytics.alias(uid)
}

export const convertContextKeyToPageEventFormat = (event: string) => {
  const words = event.match(/[A-Za-z][a-z]*/g) || []
  return words.map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
}

export const sendPageEvent = (event: string, data: analytics.apiObject = {}) => {
  analytics.page(event, { domain: Config.APPLICATION_BASE_URL, ...data })
}

export const sendTrackEvent = (event: SegmentEventName, data: analytics.apiObject = {}) => {
  analytics.track(event, { domain: Config.APPLICATION_BASE_URL, ...data })
}

export const sendIdentifyEvent = ({
  uid,
  data = {},
}: {
  uid?: string
  data: analytics.apiObject
}) => {
  if (!uid) {
    return analytics.identify({ domain: Config.APPLICATION_BASE_URL, ...data })
  }
  return analytics.identify(uid, { domain: Config.APPLICATION_BASE_URL, ...data })
}

export { analytics }
