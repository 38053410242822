import {
  ArrowLeftIcon,
  Group,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  useLifecycle,
} from '@shared/components'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { referralsApi } from '../../../api'

export const ReferralEmailSent = () => {
  const navigate = useNavigate()
  const back = () => navigate('/welcome')
  const { sessionId } = useParams()

  useLifecycle({
    onMount: () => {
      if (sessionId) {
        void referralsApi.createReferralForSessionIfNotExists({ sessionId })
      }
    },
  })

  return (
    <Stack spacing='md'>
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              style={{ margin: 'auto' }}
              onClick={back}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={back}
            >
              Back
            </TertiaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
