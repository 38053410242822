import {
  ArrowRightIcon,
  Card,
  Group,
  PrimaryButton,
  Stack,
  Text,
  TitleThree,
  useBanner,
  useLifecycle,
  useMantineTheme,
} from '@shared/components'
import { PaymentPlanFrequency } from '@shared/types'
import { formatDollarAmount } from '@shared/utils'
import React, { useState } from 'react'
import * as FullStory from '../../../common/fullstory'
import { usePortalDims, usePortalMutation } from '../../../common/hooks'
import { usePaymentPlan } from './use-payment-plan'

type PaymentPlanOption = {
  type: PaymentPlanFrequency
  numPayments: number
  balancePerPaymentInDollars: number
}

const getPaymentPlanOptions = ({
  pastBalanceDueInDollars,
}: {
  pastBalanceDueInDollars: number
}): PaymentPlanOption[] => {
  let numBiweeklyPayments = 0
  let numWeeklyPayments = 0
  let numMonthlyPayments = 0

  const TWO = 2
  const FOUR = 4
  const EIGHT = 8

  if (pastBalanceDueInDollars < 200) {
    numBiweeklyPayments = TWO
    numWeeklyPayments = FOUR
  } else if (pastBalanceDueInDollars < 500) {
    numBiweeklyPayments = FOUR
    numWeeklyPayments = EIGHT
  } else {
    numBiweeklyPayments = EIGHT
    numMonthlyPayments = FOUR
  }

  return [
    {
      type: 'weekly' as PaymentPlanFrequency,
      numPayments: numWeeklyPayments,
      balancePerPaymentInDollars: pastBalanceDueInDollars / numWeeklyPayments,
    },
    {
      type: 'biweekly' as PaymentPlanFrequency,
      numPayments: numBiweeklyPayments,
      balancePerPaymentInDollars: pastBalanceDueInDollars / numBiweeklyPayments,
    },
    {
      type: 'monthly' as PaymentPlanFrequency,
      numPayments: numMonthlyPayments,
      balancePerPaymentInDollars: pastBalanceDueInDollars / numMonthlyPayments,
    },
  ].filter(({ numPayments }) => numPayments > 0)
}

type PaymentPlanSignupProps = {
  pastBalanceDueInDollars: number
}

export const PaymentPlanSignup = ({ pastBalanceDueInDollars }: PaymentPlanSignupProps) => {
  const { showBanner } = useBanner()
  const { isMobile } = usePortalDims()

  const { refetchPaymentPlanData } = usePaymentPlan({ pastBalanceDueInDollars })

  const {
    other: { colors },
  } = useMantineTheme()

  useLifecycle({
    onMount: () => {
      FullStory.event('[Experiment] Payment plan signup section seen')
    },
  })

  const [selectedPlanType, setSelectedPlanType] = useState<PaymentPlanFrequency | null>(null)

  const signUpForPaymentPlan = usePortalMutation('POST /payment-plan')

  const handleSignUp = ({
    type,
    numPayments,
    balancePerPaymentInDollars,
  }: {
    type: PaymentPlanFrequency
    numPayments: number
    balancePerPaymentInDollars: number
  }) => {
    setSelectedPlanType(type)

    signUpForPaymentPlan.mutate(
      {
        data: {
          paymentPlanFrequency: type,
          numPayments,
          balancePerPaymentInDollars,
        },
      },
      {
        onSuccess: async () => {
          await refetchPaymentPlanData()
          showBanner({
            type: 'success',
            label: 'A financial counselor will reach out with payment plan details',
          })
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
          setSelectedPlanType(null)
        },
      },
    )
  }

  const paymentPlanOptions = getPaymentPlanOptions({ pastBalanceDueInDollars })

  return (
    <Stack>
      <Stack spacing='xs'>
        <TitleThree>Medical bills can be overwhelming. We&apos;re here to help you 🌻</TitleThree>
        <Text>
          Pay {formatDollarAmount({ amount: pastBalanceDueInDollars })} over time with no interest
          payment plans
        </Text>
      </Stack>
      {paymentPlanOptions.map(({ type, numPayments, balancePerPaymentInDollars }) => {
        return (
          <Card
            key={type}
            style={{
              backgroundColor: colors.background[1],
              width: isMobile ? '100%' : 'fit-content',
            }}
          >
            <Group grow={isMobile}>
              <Stack spacing='xs' sx={{ textAlign: 'center' }}>
                <Text bold>
                  {formatDollarAmount({ amount: balancePerPaymentInDollars })}/{type}
                </Text>
                <Text size='xs'>{numPayments} payments</Text>
              </Stack>
              <PrimaryButton
                onClick={() => handleSignUp({ type, numPayments, balancePerPaymentInDollars })}
                rightIcon={<ArrowRightIcon />}
                loading={signUpForPaymentPlan.isLoading && selectedPlanType === type}
              >
                Start plan
              </PrimaryButton>
            </Group>
          </Card>
        )
      })}
    </Stack>
  )
}
