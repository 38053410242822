import { useMediaQuery } from '@mantine/hooks'
import { useMantineTheme } from '@shared/components'

export const ONBOARDING_DESKTOP_MAX_WIDTH = 640

export const useOnboardingDims = () => {
  const theme = useMantineTheme()
  const mobileMediaQuery = theme.fn.smallerThan('sm')
  const desktopMediaQuery = theme.fn.largerThan('sm')

  return {
    isMobile: useMediaQuery(mobileMediaQuery.replace('@media', '')),
    mobileMediaQuery,
    isDesktop: useMediaQuery(desktopMediaQuery.replace('@media', '')),
    desktopMediaQuery,
    desktopMaxWidth: ONBOARDING_DESKTOP_MAX_WIDTH,
  }
}
