import { useForm } from '@mantine/form'
import { Grid, Textarea, TextInput } from '@shared/components'
import { validCharacters } from '@shared/utils'
import React from 'react'
import { isRequired } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const ThankYouNoteForm = ({
  initialValues,
  submit,
  saving,
}: TaskStepProps<'thank-you-note'>) => {
  const { validate, getInputProps, values } = useForm({
    initialValues,
    validate: {
      message: isRequired,
    },
  })

  const onSubmit = function onSubmit() {
    if (!validate().hasErrors) {
      submit('', values)
    }
  }

  return (
    <Grid test-id='step:thank-you-note'>
      <Grid.Col span={12}>
        <TextInput
          label='Your name (optional)'
          placeholder='Your name'
          maxLength={64}
          formatter={validCharacters.name}
          test-id='input:name'
          {...getInputProps('name')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Textarea
          label='Thank you message'
          placeholder='2-3 sentences'
          maxLength={256}
          test-id='input:message'
          explanation="We'll share this message with the person who referred you the next time they sign in to myOphelia"
          {...getInputProps('message')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TaskButton
          onSubmit={onSubmit}
          isLastStep
          loading={saving}
          customButtonText='Send thank you note'
        />
      </Grid.Col>
    </Grid>
  )
}
