// eslint-disable-next-line no-restricted-imports
import {
  Box,
  FocusTrap,
  Group,
  Modal as MantineModal,
  ModalProps as MantineModalProps,
  Stack,
  useMantineTheme,
} from '@mantine/core'
import React, { PropsWithChildren, ReactNode } from 'react'
import { Divider } from './Divider'
import { TertiaryButton } from './buttons'
import { XIcon } from './icons'
import { TitleThree } from './typography'

export type ModalProps = Omit<
  MantineModalProps,
  'padding' | 'withCloseButton' | 'styles' | 'sx' | 'size' | 'centered'
> & {
  footer?: ReactNode
  onBack?: () => void
  size?: 'unset' | string
}

export type BetterModalProps = Omit<
  MantineModalProps,
  'padding' | 'withCloseButton' | 'styles' | 'sx' | 'size' | 'centered' | 'title'
> & {
  onBack?: () => void
  size?: 'unset' | string
}

export const Modal = ({
  children,
  title,
  footer,
  onClose,
  trapFocus = true,
  ...rest
}: ModalProps) => {
  return (
    <BetterModal onClose={onClose} {...rest}>
      {title && <ModalHeader onClose={onClose}>{title}</ModalHeader>}
      <ModalContent trapFocus={trapFocus}>{children}</ModalContent>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </BetterModal>
  )
}

export const BetterModal = ({ maw, ...props }: BetterModalProps) => {
  return (
    <MantineModal
      {...props}
      centered
      padding={0}
      withCloseButton={false}
      trapFocus={false}
      styles={({ other: { colors } }) => ({
        root: { '.mantine-Modal-inner': { padding: 0, bottom: '20%' } },
        body: { maxWidth: maw as string },
        content: { backgroundColor: colors.background[0] },
      })}
      onClose={props.opened ? props.onClose : () => {}}
    />
  )
}

export const ModalHeader = ({ children, onClose }: PropsWithChildren<{ onClose: () => void }>) => {
  const theme = useMantineTheme()
  return (
    <Stack
      pt={theme.other.sizes.padding.xl}
      pl={theme.other.sizes.padding.xl}
      pr={theme.other.sizes.padding.xl}
      style={{
        gap: theme.other.sizes.gap.md,
      }}
    >
      <Group position='apart' align='flex-start' noWrap>
        <TitleThree>
          <Group align='flex-start'>{children}</Group>
        </TitleThree>
        <TertiaryButton
          size='sm'
          onClick={onClose}
          leftIcon={<XIcon color={theme.other.colors.text[0]} />}
        />
      </Group>
      <Divider />
    </Stack>
  )
}

export const ModalContent = ({
  children,
  trapFocus = true,
}: PropsWithChildren<{ trapFocus?: boolean }>) => {
  const theme = useMantineTheme()
  return (
    <FocusTrap active={trapFocus}>
      <Box p={theme.other.sizes.gap.xl}>{children}</Box>
    </FocusTrap>
  )
}

export const ModalFooter = ({ children }: PropsWithChildren) => {
  const theme = useMantineTheme()
  return (
    <Box p={theme.other.sizes.gap.xl} bg={theme.other.colors.background[1]}>
      {children}
    </Box>
  )
}
