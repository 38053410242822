import { IANAZone, dayjs } from '@shared/utils'
import { getSessionStorageItem } from '../storage'
import { useAuth } from './use-auth'
import { useCallMeNowQueueStatus } from './use-call-me-now-queue-status'
import { useFlags } from './use-flags'
import { useMyQuery } from './use-my-query'

export const useCareTransferAvailability = () => {
  const { isQueueOpen } = useCallMeNowQueueStatus()
  const { currentUser } = useAuth()
  const { careTransferExperiment } = useFlags()

  // Only available on weekdays due to limited EC capacity on weekends
  const isTodayAWeekday =
    dayjs().tz(IANAZone.Eastern).day() >= 1 && dayjs().tz(IANAZone.Eastern).day() <= 5

  const activeStatesQuery = useMyQuery('GET /intake-states')

  const activeStatesData = activeStatesQuery?.data?.data?.intakeStates

  const residenceState =
    getSessionStorageItem('residence_state') || currentUser?.data?.homeData?.state || ''

  return {
    isCareTransferAvailable:
      careTransferExperiment &&
      isTodayAWeekday &&
      activeStatesData?.includes(residenceState) &&
      isQueueOpen,
  }
}
