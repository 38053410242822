import { useForm } from '@mantine/form'
import {
  Checkbox,
  List,
  ListItem,
  PrimaryButton,
  Stack,
  Text,
  TextInput,
  TitleTwo,
  useBanner,
  useLifecycle,
  validateWith,
} from '@shared/components'
import React from 'react'
import { ContactSupport } from '../common/components'
import { isAtleastOneWord, isRequired } from '../common/forms'
import * as FullStory from '../common/fullstory'
import { usePortalDims, usePortalMutation } from '../common/hooks'
import { FinancialAgreementV5 } from '../documents/FinancialAgreementV5'

type Props = {
  redirectUrl: string
  token: string
}

const FinancialAgreement = ({ redirectUrl, token }: Props) => {
  const { isMobile } = usePortalDims()
  const [checked, setChecked] = React.useState(false)

  const form = useForm({
    initialValues: {
      signature: '',
    },
    validate: {
      signature: validateWith(isRequired, isAtleastOneWord),
    },
  })

  const signMutation = usePortalMutation('POST /consents/financial', {
    // Do not block the patient from proceeding to visit, even if the mutation fails.
    onSettled: (_data, error) => {
      FullStory.event('Financial Consent Signed', {
        redirectUrl,
        isError: String(Boolean(error)),
      })

      window.location.replace(redirectUrl)
    },
  })

  const onSign = () => {
    if (form.validate().hasErrors) {
      return
    }

    signMutation.mutate({
      data: {
        token,
        signature: form.values.signature,
        version: 5,
      },
    })
  }

  return (
    <Stack>
      <TitleTwo>Financial agreement</TitleTwo>
      <Stack spacing='lg'>
        <FinancialAgreementV5 />
        <Checkbox
          onClick={() => setChecked(!checked)}
          checked={checked}
          label='I have read, understand, and agree to this consent for treatment and financial agreement'
        />
        <TextInput
          {...form.getInputProps('signature')}
          label='Signed by'
          placeholder='Type your full legal name'
          maxLength={64}
          disabled={signMutation.isLoading}
        />
        <PrimaryButton
          fullWidth={isMobile}
          onClick={onSign}
          disabled={!checked}
          loading={signMutation.isLoading}
        >
          Sign and agree
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}

export const FinancialConsentBeforeZoom = ({ redirectUrl, token }: Props) => {
  const { showBanner } = useBanner()
  const { isMobile } = usePortalDims()
  const [showAgreement, setShowAgreement] = React.useState(false)

  useLifecycle({
    onMount: () => {
      FullStory.event('Financial Consent Before Zoom', { redirectUrl })
      showBanner({
        label: 'We’ve updated our financial agreement',
        type: 'warning',
      })
    },
  })

  if (showAgreement) {
    return <FinancialAgreement redirectUrl={redirectUrl} token={token} />
  }

  return (
    <Stack align={isMobile ? 'center' : 'start'} spacing='lg'>
      <TitleTwo>Please sign the updated financial agreement before your appointment</TitleTwo>
      <Text>Your clinician is informed and will be waiting for you.</Text>
      <Text>
        Note that this is just an updated version of the same financial agreement you signed prior
        to your first visit with Ophelia. The main updates include:
      </Text>
      <List>
        <ListItem>Acknowledgement of our new third party payment processor, Stripe</ListItem>
        <ListItem>
          Acknowledgement of our re-enrollment policy for patients who choose to discontinue care
          with an outstanding balance
        </ListItem>
        <ListItem>
          (For patients using insurance) Authorization for automatic payment of co-payments or
          deductibles 10 days after an invoice is issued
        </ListItem>
      </List>
      <PrimaryButton
        fullWidth={isMobile}
        onClick={() => {
          setShowAgreement(true)
        }}
      >
        Review & sign
      </PrimaryButton>
      <ContactSupport />
    </Stack>
  )
}
