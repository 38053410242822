import { PrimaryButton, Stack, TitleTwo, useBanner, useLifecycle } from '@shared/components'
import React from 'react'
import { Link } from 'react-router-dom'
import { ContactSupport } from '../../common/components'
import { useOnboardingDims } from '../../common/hooks'
import { routes } from '../../common/routes'

export const Completed = () => {
  const { showBanner } = useBanner()
  const { isMobile } = useOnboardingDims()

  useLifecycle({
    onMount: () => {
      showBanner({
        type: 'success',
        label: 'Wellness assessments complete',
      })
    },
  })

  return (
    <Stack spacing='md'>
      <TitleTwo>Your wellness assessments are complete.</TitleTwo>
      <PrimaryButton
        fullWidth={isMobile}
        component={Link}
        to={`${routes.welcome.index}/${routes.welcome.children.signin}`}
      >
        Go to the portal
      </PrimaryButton>
      <ContactSupport />
    </Stack>
  )
}
