import { useForm } from '@mantine/form'
import { Checkbox, Grid, PrimaryButton } from '@shared/components'
import { AddressData, CurrentUserPatient, FormFieldValue } from '@shared/types'
import isEqual from 'lodash/isEqual'
import React, { useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { currentUserApi } from '../../../common/api'
import { AddressForm, FormLabel, FormSaveSection } from '../../../common/components'
import { formAddressRules } from '../../../common/forms'
import { useAuth } from '../../../common/hooks'

type Form = {
  homeAddress: FormFieldValue<'address'>
  shippingAddress: FormFieldValue<'address'>
  useHomeAsShipping: FormFieldValue<'checkbox'>
}

function fromShippingData(shippingData: AddressData | undefined): FormFieldValue<'address'> {
  return {
    address: shippingData?.address ?? '',
    addressTwo: shippingData?.aptSuite ?? '',
    city: shippingData?.city ?? '',
    state: shippingData?.state ?? '',
    zipCode: shippingData?.zip ?? '',
  }
}

function toShippingData(address: FormFieldValue<'address'>): AddressData {
  return {
    address: address.address,
    aptSuite: address.addressTwo,
    city: address.city,
    state: address.state,
    zip: address.zipCode,
  }
}

export const AddressSection = ({
  editing,
  toggleEditing,
}: {
  editing: boolean
  toggleEditing: () => void
}) => {
  const { currentUser } = useAuth()
  const { homeData, shippingData } = currentUser?.data ?? {}

  return (
    <>
      {editing ? (
        <AddressSectionEdit toggleEditing={toggleEditing} currentUser={currentUser?.data} />
      ) : (
        <Grid>
          <Grid.Col span={12} md={6}>
            <FormLabel title='Home address'>
              {[
                `${homeData?.address ?? ''} ${homeData?.aptSuite ?? ''}`.trim(),
                `${homeData?.city ?? ''}, ${homeData?.state ?? ''} ${homeData?.zip ?? ''}`.trim(),
              ]}
            </FormLabel>
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <FormLabel title='Shipping address'>
              {[
                `${shippingData?.address ?? ''} ${shippingData?.aptSuite ?? ''}`.trim(),
                `${shippingData?.city ?? ''}, ${shippingData?.state ?? ''} ${
                  shippingData?.zip ?? ''
                }`.trim(),
              ]}
            </FormLabel>
          </Grid.Col>
          <Grid.Col span={12}>
            <PrimaryButton mt='md' onClick={() => toggleEditing()}>
              Edit
            </PrimaryButton>
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}

const AddressSectionEdit = ({
  currentUser,
  toggleEditing,
}: {
  currentUser: CurrentUserPatient | undefined
  toggleEditing: () => void
}) => {
  const updateCurrentUser = useMutation(currentUserApi.update)
  const queryClient = useQueryClient()

  const homeAddress = fromShippingData(currentUser?.homeData)
  const shippingAddress = fromShippingData(currentUser?.shippingData)
  const emptyAddress = fromShippingData({})
  const useHomeAsShipping: boolean = isEqual(homeAddress, shippingAddress)

  const [collapse, setCollapse] = useState<boolean>()
  const { values, validate, getInputProps } = useForm<Form>({
    initialValues: {
      homeAddress,
      shippingAddress: useHomeAsShipping ? emptyAddress : shippingAddress,
      useHomeAsShipping,
    },
    validate: {
      homeAddress: formAddressRules,
      shippingAddress: collapse ? {} : formAddressRules,
    },
  })

  useEffect(() => {
    setCollapse(values.useHomeAsShipping)
  }, [values.useHomeAsShipping])

  function onSubmit() {
    if (!validate().hasErrors && currentUser) {
      const homeData = toShippingData(values.homeAddress)
      const shippingData = values.useHomeAsShipping
        ? homeData
        : toShippingData(values.shippingAddress)

      updateCurrentUser.mutate(
        {
          homeData,
          shippingData,
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries(['currentUserApi.retrieve'])
            toggleEditing()
          },
        },
      )
    }
  }

  const saving = updateCurrentUser.isLoading

  return (
    <Grid>
      <Grid.Col span={12}>
        <FormLabel title='Home Address'>
          This is the address we’ll use to determine your permanent residence.
        </FormLabel>
      </Grid.Col>
      <AddressForm
        label='Home address'
        addressInputProps={getInputProps('homeAddress.address')}
        addressTwoInputProps={getInputProps('homeAddress.addressTwo')}
        cityInputProps={getInputProps('homeAddress.city')}
        stateInputProps={getInputProps('homeAddress.state')}
        zipInputProps={getInputProps('homeAddress.zipCode')}
        disabled={saving}
      />
      <Grid.Col span={12}>
        <FormLabel title='Shipping address'>
          This is the address we’ll use to send you urine drug screens and any other materials we
          might need to mail.
        </FormLabel>
      </Grid.Col>
      <Grid.Col span={12}>
        <Checkbox
          label='My shipping address is the same as my home address'
          disabled={saving}
          {...getInputProps('useHomeAsShipping', { type: 'checkbox' })}
        />
      </Grid.Col>
      {!collapse && (
        <AddressForm
          label='Shipping address'
          addressInputProps={getInputProps('shippingAddress.address')}
          addressTwoInputProps={getInputProps('shippingAddress.addressTwo')}
          cityInputProps={getInputProps('shippingAddress.city')}
          stateInputProps={getInputProps('shippingAddress.state')}
          zipInputProps={getInputProps('shippingAddress.zipCode')}
          disabled={saving}
        />
      )}
      <Grid.Col span={12}>
        <FormSaveSection
          loading={updateCurrentUser.isLoading}
          onSubmit={onSubmit}
          onCancel={toggleEditing}
        />
      </Grid.Col>
    </Grid>
  )
}
