import { TwilightIcon } from '../twilight/icons'

export const PATIENT_WORKFLOW_TASK = [
  'phq-9-form',
  'phq-8-form',
  'gad-7-form',
  'barc-10-form',
  'asq-form',
  'medical-intake-form',
] as const

export type PatientWorkflowTaskType = (typeof PATIENT_WORKFLOW_TASK)[number]

export const PATIENT_FORM_TASK = [
  'billing',
  'emergency-contact',
  'insurance',
  'profile',
  'upload-id',
  'intake-visit-standby-list',
  'preferred-pharmacy',
  'treatment-consent',
  'treatment-consent-and-financial-agreement',
  'how-did-you-hear-about-us',
  'financial-agreement',
  'thank-you-note',
  'zoom',
  'tpo-consent',
] as const

export type PatientFormTaskType = (typeof PATIENT_FORM_TASK)[number]

export const PATIENT_SCHEDULE_TASK = ['schedule-intake-visit', 'schedule-follow-up-visit'] as const

export type PatientScheduleTaskType = (typeof PATIENT_SCHEDULE_TASK)[number]

export const PATIENT_TASK = [
  ...PATIENT_WORKFLOW_TASK,
  ...PATIENT_FORM_TASK,
  ...PATIENT_SCHEDULE_TASK,
] as const

export type PatientTaskType = (typeof PATIENT_TASK)[number]

export enum PortalRoute {
  ScheduleIntakeVisit = '/portal/task/schedule-intake-visit',
  ScheduleFollowUpVisit = '/portal/task/schedule-follow-up-visit',
  Profile = '/portal/task/profile',
  UploadId = '/portal/task/upload-id',
  Insurance = '/portal/task/insurance',
  Billing = '/portal/task/billing',
  FinancialAgreement = '/portal/task/financial-agreement',
  MedicalIntakeForm = '/portal/task/medical-intake-form',
  HowDidYouHearAboutUs = '/portal/task/how-did-you-hear-about-us',
  EmergencyContact = '/portal/task/emergency-contact',
  Zoom = '/portal/task/zoom',
  PreferredPharmacy = '/portal/task/preferred-pharmacy',
  IntakeVisitStandbyList = '/portal/intake-visit-standby-list',
  Phq9Form = '/portal/task/phq-9-form',
  Phq8Form = '/portal/task/phq-8-form',
  Gad7Form = '/portal/task/gad-7-form',
  Barc10Form = '/portal/task/barc-10-form',
  TreatmentConsent = '/portal/task/treatment-consent',
  TreatmentConsentAndFinancialAgreement = '/portal/task/treatment-consent-and-financial-agreement',
  AsqForm = '/portal/task/asq-form',
  ThankYouNote = '/portal/task/thank-you-note',
  TPOConsent = '/portal/task/tpo-consent',
}

export type PatientTaskRequiredFor = 'intake-visit'
export type PatientPortalTaskPriority = 0 | 1 | 2 | 3

export type PatientPortalTaskCard = {
  title: string
  type: PatientTaskType
  icon: TwilightIcon
  path: PortalRoute
  priority: PatientPortalTaskPriority
  requiredFor: PatientTaskRequiredFor[]
  disabled: boolean
  footer?: string | undefined
  requiredMessage?: string | undefined
  isComplete: boolean
  isApplicable: boolean
}
