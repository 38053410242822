import { Text } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { PreferredPharmacyForm } from './PreferredPharmacyForm'
import { TaskHeader } from './TaskHeader'
import { usePatientFormTask } from './use-patient-task'

export const PreferredPharmacyTask = () => {
  const { payload, status, submit, isSaving } = usePatientFormTask({
    type: 'preferred-pharmacy',
    breadcrumb: 'Preferred pharmacy',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <>
      <TaskHeader title='What is your preferred pharmacy for picking up medication?'>
        <Text>
          We&apos;ll confirm this information during your first visit before sending any
          prescriptions.
        </Text>
      </TaskHeader>
      <PreferredPharmacyForm initialValues={payload} submit={submit} saving={isSaving} />
    </>
  )
}
