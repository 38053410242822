export const OPIOID_TYPE_SELECT_ITEMS = [
  { label: 'Fentanyl', value: 'fentanyl' },
  { label: 'Heroin', value: 'heroin' },
  {
    label: 'Pressed pills (labeled as rx painkiller, but unknown content)',
    value: 'pressed_pills',
  },
  {
    label: 'Known prescription painkillers (oxycodone, percocet, vicodin, or similar)',
    value: 'prescription_painkillers',
  },
  { label: 'Buprenorphine', value: 'buprenorphine' },
  { label: 'Kratom', value: 'kratom' },
  { label: 'Methadone', value: 'methadone' },
  { label: 'Tramadol', value: 'tramadol' },
  { label: 'Other', value: 'other' },
] as const

export type OpioidType = (typeof OPIOID_TYPE_SELECT_ITEMS)[number]['value']

export const SUBSTANCE_USE_FREQUENCY_SELECT_ITEMS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Several times per week', value: 'several_times_per_week' },
  { label: 'Several times per month', value: 'several_times_per_month' },
  { label: 'Less than once per month', value: 'less_than_once_per_month' },
] as const

export type SubstanceUseFrequency = (typeof SUBSTANCE_USE_FREQUENCY_SELECT_ITEMS)[number]['value']

export const ROUTE_OF_USE_CHECKBOX_ITEMS = [
  { label: 'Oral (swallowing)', value: 'oral' },
  { label: 'Smoking', value: 'smoking' },
  { label: 'Intranasal (snorting)', value: 'intranasal' },
  { label: 'Injection (IV/shooting)', value: 'injection' },
  { label: 'Intrarectal (booty bumping)', value: 'intrarectal' },
] as const

export type RouteOfUse = (typeof ROUTE_OF_USE_CHECKBOX_ITEMS)[number]['value']

export const OTHER_STIMULANT_TYPE_SELECT_ITEMS = [
  { label: 'Methamphetamine', value: 'methamphetamine' },
  { label: 'Prescription stimulants (e.g. Adderall, Ritalin)', value: 'prescription_stimulants' },
  { label: 'Other (e.g. bath salts, captagon)', value: 'other' },
]

export type OtherStimulantType = (typeof OTHER_STIMULANT_TYPE_SELECT_ITEMS)[number]['value']

export type SubstanceUseNoteContent = {
  opioid?: {
    usedSinceLastVisit?: boolean
    primarySource?: OpioidType
    routeOfUse?: RouteOfUse[]
    frequencyOfUse?: SubstanceUseFrequency
  }
  cocaine?: {
    usedSinceLastVisit?: boolean
    routeOfUse?: RouteOfUse[]
    frequencyOfUse?: SubstanceUseFrequency
  }
  otherStimulant?: {
    usedSinceLastVisit?: boolean
    primarySource?: OtherStimulantType
    routeOfUse?: RouteOfUse[]
    frequencyOfUse?: SubstanceUseFrequency
  }
}
