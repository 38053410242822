import React, { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { Skeletons } from '../../common/components'
import { useReferralPartner } from '../../common/hooks/use-partner'

export const PartnerPage = ({ children }: { children: ReactNode }) => {
  const { loading, isPartnerValid, isPartnerRoute } = useReferralPartner()

  if (loading) {
    return <Skeletons />
  }

  if (isPartnerRoute && !isPartnerValid) {
    return <Navigate replace to='/' />
  }

  return <>{children}</>
}
