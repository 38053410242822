import {
  ArrowLeftIcon,
  PrimaryButton,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
} from '@shared/components'
import React from 'react'
import { Link } from 'react-router-dom'
import { useOnboardingDims } from '../common/hooks'
import { routes } from '../common/routes'
import { ConfirmationReferralLink } from './ConfirmationReferralLink'

export const AppointmentConfirmed = ({
  isWelcomeCall,
  appointmentDate,
  appointmentTypeName,
  appointmentTime,
  providerText,
  patientId,
}: {
  appointmentDate: string
  appointmentTypeName: string
  appointmentTime: string
  providerText: string
  patientId: string
  isWelcomeCall: boolean
}) => {
  const { isMobile } = useOnboardingDims()

  return (
    <Stack spacing='lg' test-id='page:appointment-confirmation'>
      <TitleTwo test-id='title:appointment-confirmed'>
        {`Your ${appointmentTypeName} for ${appointmentDate} at ${appointmentTime}${providerText} is confirmed`}
      </TitleTwo>
      {isWelcomeCall ? (
        <TertiaryButton
          test-id='button:back'
          fullWidth={isMobile}
          leftIcon={<ArrowLeftIcon />}
          component={Link}
          to={routes.welcome.index}
        >
          Back
        </TertiaryButton>
      ) : (
        <>
          <Text>
            If you don&apos;t attend, or if you cancel less than 24 hours before your visit, you may
            be charged $20.
          </Text>
          {!isWelcomeCall && (
            <Text>
              {`While we'll do our best to accommodate your availability for future visits, we cannot
              guarantee it.`}
            </Text>
          )}
          <PrimaryButton
            test-id='button:go-to-portal'
            fullWidth={isMobile}
            component={Link}
            to={`${routes.welcome.index}/${routes.welcome.children.signin}`}
          >
            Go to the portal
          </PrimaryButton>
        </>
      )}
      <ConfirmationReferralLink patientId={patientId} />
    </Stack>
  )
}
