import { Box, Text } from '@shared/components'
import React, { PropsWithChildren } from 'react'

export const CalendarMessage = ({ children }: PropsWithChildren) => {
  return (
    <Box
      p='md'
      sx={({ other: { colors }, radius }) => ({
        backgroundColor: colors.background[1],
        borderRadius: radius.sm,
      })}
    >
      <Text align='center'>{children}</Text>
    </Box>
  )
}
