import {
  BetterModal,
  Image,
  ModalContent,
  PrimaryButton,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
} from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Config } from '../config'
import { routes } from '../routes'

type ReferFriendModalProps = {
  onClose: () => void
}

export const ReferFriendModal = ({
  opened,
  onClose,
}: { opened: boolean } & ReferFriendModalProps) => {
  const navigate = useNavigate()

  return (
    <BetterModal maw='20.5rem' onClose={onClose} opened={opened} size='unset'>
      <ModalContent>
        <Stack test-id='modal:refer-friend-modal' p='md' spacing='md' align='center'>
          <Image fit='scale-down' w='4rem' h='4rem' src={`${Config.IMAGE_BASE_URL}support.png`} />
          <Stack spacing='0' align='center'>
            <TitleTwo>Refer friends,</TitleTwo>
            <TitleTwo>save lives</TitleTwo>
          </Stack>
          <Text align='center'>
            Share Ophelia with a friend or loved one who isn&apos;t in treatment yet and tell them
            you can do it together.
          </Text>
          <PrimaryButton fullWidth onClick={() => navigate(routes.portal.children.referrals)}>
            Refer a friend
          </PrimaryButton>
          <TertiaryButton test-id='modal:refer-friend-modal-close-button' onClick={onClose}>
            No thanks
          </TertiaryButton>
        </Stack>
      </ModalContent>
    </BetterModal>
  )
}
