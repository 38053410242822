import { EditIcon, TertiaryButton } from '@shared/components'
import React from 'react'

export const EditButton = ({ onClick, hide }: { onClick: () => void; hide?: boolean }) => {
  if (hide) {
    return null
  }

  return (
    <TertiaryButton onClick={onClick} rightIcon={<EditIcon />}>
      Edit
    </TertiaryButton>
  )
}
