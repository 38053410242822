const BARC10_FORM_PROMPT_CONTEXT_KEY = [
  'there_are_more_important_things',
  'in_general_i_am_happy',
  'i_have_enough_energy',
  'i_am_proud_of_the_community',
  'i_get_lots_of_support',
  'i_regard_my_life_as_challenging',
  'my_living_space_has_helped',
  'i_take_full_responsibility',
  'i_am_happy_dealing',
  'i_am_making_good_progress',
] as const

export type Barc10FormPromptContextKey = (typeof BARC10_FORM_PROMPT_CONTEXT_KEY)[number]

const THERE_ARE_MORE_IMPORTANT_THINGS_PROMPT_RESPONSE_CONTEXT_KEY = [
  'there_are_more_important_things_strongly_disagree',
  'there_are_more_important_things_disagree',
  'there_are_more_important_things_slightly_disagree',
  'there_are_more_important_things_slightly_agree',
  'there_are_more_important_things_agree',
  'there_are_more_important_things_strongly_agree',
] as const

const IN_GENERAL_I_AM_HAPPY_PROMPT_RESPONSE_CONTEXT_KEY = [
  'in_general_i_am_happy_strongly_disagree',
  'in_general_i_am_happy_disagree',
  'in_general_i_am_happy_slightly_disagree',
  'in_general_i_am_happy_slightly_agree',
  'in_general_i_am_happy_agree',
  'in_general_i_am_happy_strongly_agree',
] as const

const I_HAVE_ENOUGH_ENERGY_PROMPT_RESPONSE_CONTEXT_KEY = [
  'i_have_enough_energy_strongly_disagree',
  'i_have_enough_energy_disagree',
  'i_have_enough_energy_slightly_disagree',
  'i_have_enough_energy_slightly_agree',
  'i_have_enough_energy_agree',
  'i_have_enough_energy_strongly_agree',
] as const

const I_AM_PROUD_OF_THE_COMMUNITY_PROMPT_RESPONSE_CONTEXT_KEY = [
  'i_am_proud_of_the_community_strongly_disagree',
  'i_am_proud_of_the_community_disagree',
  'i_am_proud_of_the_community_slightly_disagree',
  'i_am_proud_of_the_community_slightly_agree',
  'i_am_proud_of_the_community_agree',
  'i_am_proud_of_the_community_strongly_agree',
] as const

const I_GET_LOTS_OF_SUPPORT_PROMPT_RESPONSE_CONTEXT_KEY = [
  'i_get_lots_of_support_strongly_disagree',
  'i_get_lots_of_support_disagree',
  'i_get_lots_of_support_slightly_disagree',
  'i_get_lots_of_support_slightly_agree',
  'i_get_lots_of_support_agree',
  'i_get_lots_of_support_strongly_agree',
] as const

const I_REGARD_MY_LIFE_AS_CHALLENGING_PROMPT_RESPONSE_CONTEXT_KEY = [
  'i_regard_my_life_as_challenging_strongly_disagree',
  'i_regard_my_life_as_challenging_disagree',
  'i_regard_my_life_as_challenging_slightly_disagree',
  'i_regard_my_life_as_challenging_slightly_agree',
  'i_regard_my_life_as_challenging_agree',
  'i_regard_my_life_as_challenging_strongly_agree',
] as const

const MY_LIVING_SPACE_HAS_HELPED_PROMPT_RESPONSE_CONTEXT_KEY = [
  'my_living_space_has_helped_strongly_disagree',
  'my_living_space_has_helped_disagree',
  'my_living_space_has_helped_slightly_disagree',
  'my_living_space_has_helped_slightly_agree',
  'my_living_space_has_helped_agree',
  'my_living_space_has_helped_strongly_agree',
] as const

const I_TAKE_FULL_RESPONSIBILITY_PROMPT_RESPONSE_CONTEXT_KEY = [
  'i_take_full_responsibility_strongly_disagree',
  'i_take_full_responsibility_disagree',
  'i_take_full_responsibility_slightly_disagree',
  'i_take_full_responsibility_slightly_agree',
  'i_take_full_responsibility_agree',
  'i_take_full_responsibility_strongly_agree',
] as const

const I_AM_HAPPY_DEALING_PROMPT_RESPONSE_CONTEXT_KEY = [
  'i_am_happy_dealing_strongly_disagree',
  'i_am_happy_dealing_disagree',
  'i_am_happy_dealing_slightly_disagree',
  'i_am_happy_dealing_slightly_agree',
  'i_am_happy_dealing_agree',
  'i_am_happy_dealing_strongly_agree',
] as const

const I_AM_MAKING_GOOD_PROGRESS_PROMPT_RESPONSE_CONTEXT_KEY = [
  'i_am_making_good_progress_strongly_disagree',
  'i_am_making_good_progress_disagree',
  'i_am_making_good_progress_slightly_disagree',
  'i_am_making_good_progress_slightly_agree',
  'i_am_making_good_progress_agree',
  'i_am_making_good_progress_strongly_agree',
] as const

export const BARC10_FORM_PROMPT_RESPONSE_CONTEXT_KEYS = [
  ...THERE_ARE_MORE_IMPORTANT_THINGS_PROMPT_RESPONSE_CONTEXT_KEY,
  ...IN_GENERAL_I_AM_HAPPY_PROMPT_RESPONSE_CONTEXT_KEY,
  ...I_HAVE_ENOUGH_ENERGY_PROMPT_RESPONSE_CONTEXT_KEY,
  ...I_AM_PROUD_OF_THE_COMMUNITY_PROMPT_RESPONSE_CONTEXT_KEY,
  ...I_GET_LOTS_OF_SUPPORT_PROMPT_RESPONSE_CONTEXT_KEY,
  ...I_REGARD_MY_LIFE_AS_CHALLENGING_PROMPT_RESPONSE_CONTEXT_KEY,
  ...MY_LIVING_SPACE_HAS_HELPED_PROMPT_RESPONSE_CONTEXT_KEY,
  ...I_TAKE_FULL_RESPONSIBILITY_PROMPT_RESPONSE_CONTEXT_KEY,
  ...I_AM_HAPPY_DEALING_PROMPT_RESPONSE_CONTEXT_KEY,
  ...I_AM_MAKING_GOOD_PROGRESS_PROMPT_RESPONSE_CONTEXT_KEY,
] as const

export type Barc10FormPromptResponseContextKey =
  (typeof BARC10_FORM_PROMPT_RESPONSE_CONTEXT_KEYS)[number]
