import { UseFormReturnType } from '@mantine/form'
import { LooseKeys } from '@mantine/form/lib/types'
import { Radio, RadioGroup } from '@shared/components'
import React from 'react'
import { SkipOnboardingForm } from './SkipOnboardingDrawer'

export const YesNoRadio = ({
  form,
  onChange,
  path,
}: {
  form: UseFormReturnType<SkipOnboardingForm, (values: SkipOnboardingForm) => SkipOnboardingForm>
  onChange: (value: string) => void
  path: LooseKeys<SkipOnboardingForm>
}) => {
  return (
    <RadioGroup {...form.getInputProps(path)} onChange={onChange}>
      <Radio label='Yes' value='yes' />
      <Radio label='No' value='no' />
    </RadioGroup>
  )
}
