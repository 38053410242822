import { useForm } from '@mantine/form'
import {
  ArrowRightIcon,
  Checkbox,
  CheckboxGroup,
  Grid,
  Group,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  isAllSelected,
  validateWith,
} from '@shared/components'
import { name } from '@shared/utils'
import React, { useRef } from 'react'
import { isAtleastOneWord, isRequired } from '../../../common/forms'
import { useAuth } from '../../../common/hooks'
import { FinancialAgreementV5 } from '../../../documents/FinancialAgreementV5'
import { TaskButton } from './TaskButton'
import { TaskSteplessProps } from './taskStepProps'

const READ_AGREEMENT = 'read-agreement'

export function BillingAgreementStep({
  initialValues,
  saving,
  submit,
}: TaskSteplessProps<'billing' | 'financial-agreement'>) {
  const { currentUser } = useAuth()
  const signRef = useRef<HTMLDivElement>(null)
  const { values, validate, getInputProps, setFieldValue } = useForm({
    initialValues: { signature: initialValues.signature, checks: [] as string[] },
    validate: {
      signature: validateWith(isRequired, isAtleastOneWord),
      checks: isAllSelected([READ_AGREEMENT], 'Required'),
    },
  })

  function onSubmit() {
    if (!validate().hasErrors) {
      submit({ signature: values.signature })
    }
  }

  return (
    <Stack test-id='step:financial-agreement'>
      <Group>
        <Text>Review the financial agreement</Text>
        <TertiaryButton
          onClick={() => signRef.current?.scrollIntoView({ behavior: 'smooth' })}
          rightIcon={<ArrowRightIcon />}
        >
          e-sign
        </TertiaryButton>
      </Group>
      <Text bold>{`Ophelia's Patient Financial Agreement`}</Text>
      <FinancialAgreementV5 />
      <CheckboxGroup {...getInputProps('checks')} label={<Text bold>Please confirm:</Text>}>
        <Checkbox
          test-id='checkbox:agree'
          label='I acknowledge that I have read this authorization and I authorize the disclosure of my information by Ophelia to Stripe for the purpose described.'
          disabled={saving}
          value={READ_AGREEMENT}
        />
      </CheckboxGroup>
      <Grid>
        <Grid.Col span={12} md={6}>
          <TextInput
            test-id='input:signature'
            {...getInputProps('signature')}
            label='Signed by'
            placeholder='Type your full legal name'
            maxLength={64}
            disabled={saving}
            onFocus={() => {
              if (!values.signature) {
                const patientName = name({
                  first: currentUser?.data?.personalData.firstName,
                  last: currentUser?.data?.personalData.lastName,
                }).full()
                setFieldValue('signature', patientName)
              }
            }}
          />
        </Grid.Col>
      </Grid>
      <TaskButton ref={signRef} onSubmit={onSubmit} loading={saving} />
    </Stack>
  )
}
