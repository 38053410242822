import { Box, Text } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { EmergencyContactForm } from './EmergencyContactForm'
import { TaskHeader } from './TaskHeader'
import { usePatientFormTask } from './use-patient-task'

export const EmergencyContactTask = () => {
  const { payload, status, submit, isSaving } = usePatientFormTask({
    type: 'emergency-contact',
    breadcrumb: 'Emergency contact',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <Box test-id='page:emergency-contact-task'>
      <TaskHeader title='Who can we contact in case of emergency?'>
        <Text>
          If you have a medical emergency during a visit, like a seizure or mental health crisis, we
          want to get you help.
        </Text>
      </TaskHeader>
      <EmergencyContactForm initialValues={payload} submit={submit} saving={isSaving} />
    </Box>
  )
}
