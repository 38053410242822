import {
  Divider,
  ExternalLinkText,
  PrimaryButton,
  Stack,
  Text,
  TitleTwo,
  useBanner,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SMS_SUPPORT, Skeletons } from '../common/components'
import {
  useInvalidateQuery,
  useMyMutation,
  useOnboardingDims,
  usePortalQuery,
} from '../common/hooks'
import { useCallMeNowQueueStatus } from '../common/hooks/use-call-me-now-queue-status'
export const EnterCallMeNowApp = () => {
  const navigate = useNavigate()
  const { appointmentId } = useParams()
  const { isMobile } = useOnboardingDims()
  const { showBanner, cleanBanner } = useBanner()
  const invalidateQuery = useInvalidateQuery()
  const { isQueueAvailable, isLoading: loadingConsultationCallQueueStatus } =
    useCallMeNowQueueStatus()

  const { isLoading: isLoadingAppointmentInfo, data: appointmentInfo } = usePortalQuery(
    'GET /appointments/:id/confirm',
    {
      params: { id: appointmentId as string },
    },
    {
      enabled: Boolean(appointmentId),
    },
  )

  useEffect(() => cleanBanner(), [isQueueAvailable, appointmentId])

  /**
   * If appointment is happening now, we want to show the message
   * that we will call them in 20-30 minutes, so we will delay showing
   * the expired message until 15 min past the appointment time.
   */
  const isHappeningNow = appointmentInfo?.status === 'happening-now'
  const isCanceled =
    appointmentInfo?.status === 'canceled' ||
    appointmentInfo?.status === 'no-show' ||
    appointmentInfo?.status === 'late-cancellation'

  const isInPast =
    dayjs(appointmentInfo?.datetime).isBefore(dayjs().add(30, 'minutes')) && !isHappeningNow

  const isExpired = isCanceled || isInPast

  const updateAppointment = useMyMutation(
    isExpired ? 'POST /appointments/:id/call-me-now' : 'PUT /appointments/:id/call-me-now',
  )

  const handleClick = () => {
    if (!appointmentId) {
      return
    }
    updateAppointment.mutate(
      {
        params: {
          id: appointmentId,
        },
      },
      {
        onSuccess: ({ data }) => {
          if (data?.newApptId) {
            navigate(`/call-me-now/${data.newApptId}`)
          }

          showBanner({
            type: 'success',
            label: 'Appointment updated successfully.',
          })
          void invalidateQuery('GET /appointments/:id/confirm', {
            params: { id: appointmentId },
          })
        },
        onError: () => {
          showBanner({
            type: 'error',
            label: 'Failed to request earlier appointment. Please try again.',
          })
        },
      },
    )
  }

  if (isLoadingAppointmentInfo || !appointmentInfo || loadingConsultationCallQueueStatus) {
    return <Skeletons />
  }

  if (isQueueAvailable) {
    if (isHappeningNow) {
      return (
        <Stack>
          <TitleTwo>We&apos;ll give you a call in 20-30 minutes</TitleTwo>
          <Text>
            If you have any questions, text{' '}
            <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>(215) 585-2144</ExternalLinkText>.
          </Text>
        </Stack>
      )
    }

    return (
      <Stack spacing='lg'>
        <TitleTwo>Prefer to talk to us sooner?</TitleTwo>
        <Text>Click below and we&apos;ll reach out in 20-30 minutes</Text>
        <Divider />
        <Stack spacing='lg'>
          <PrimaryButton
            onClick={handleClick}
            fullWidth={isMobile}
            loading={updateAppointment.isLoading}
            disabled={updateAppointment.isLoading}
          >
            Request a call now
          </PrimaryButton>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      <TitleTwo>Sorry, we are unavailable right now. Please try again later.</TitleTwo>
      <Text>
        If you have any questions, text{' '}
        <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>(215) 585-2144</ExternalLinkText>.
      </Text>
    </Stack>
  )
}
