import { Group, Text } from '@shared/components'
import { getStateAbbr } from '@shared/types'
import React, { forwardRef } from 'react'

export type InsuranceSelectItemProps = {
  label: string
  states: string[]
}

export const InsuranceSelectItem = forwardRef<HTMLDivElement, InsuranceSelectItemProps>(
  ({ label, states, ...others }: InsuranceSelectItemProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group position='apart'>
          <Text>{label}</Text>
          <Text size='xs' color={({ text }) => text[1]}>
            {states?.map(state => getStateAbbr(state)).join(', ')}
          </Text>
        </Group>
      </div>
    )
  },
)

InsuranceSelectItem.displayName = 'InsuranceSelectItem'
