import { ConcentricCirclesIcon, Group, Text, useMantineTheme } from '@shared/components'
import React from 'react'
import { useCallMeNowQueueStatus } from '../../common/hooks/use-call-me-now-queue-status'

export const CallMeNowQueueStatus = () => {
  const { isQueueAvailable, queueStatus } = useCallMeNowQueueStatus()

  const {
    other: { colors },
  } = useMantineTheme()

  if (!isQueueAvailable) {
    return null
  }

  if (queueStatus === 'closing soon') {
    return (
      <Group spacing='sm' noWrap>
        <ConcentricCirclesIcon size='xs' variant={colors.warning[0]} pulse />
        <Text size='xs' bold color={colors.warning[0]} style={{ whiteSpace: 'nowrap' }}>
          Welcome calls closing soon
        </Text>
      </Group>
    )
  }

  return (
    <Group spacing='sm' noWrap>
      <ConcentricCirclesIcon size='xs' variant={colors.success[0]} pulse />
      <Text size='xs' bold color={colors.success[0]} style={{ whiteSpace: 'nowrap' }}>
        Welcome calls available
      </Text>
    </Group>
  )
}
