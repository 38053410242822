import {
  Flex,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Text,
  TitleTwo,
  useBanner,
} from '@shared/components'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FallbackContent, Skeletons } from '../../../common/components'
import { usePortalDims, usePortalMutation, usePortalQuery } from '../../../common/hooks'
import { routes } from '../../../common/routes'

export const RemovePaymentMethod = () => {
  const { paymentMethodId } = useParams<{ paymentMethodId: string }>()
  const { showBanner } = useBanner()
  const navigate = useNavigate()
  const { isMobile } = usePortalDims()
  const { data: paymentMethod, isLoading: fetchPaymentMethodLoading } = usePortalQuery(
    'GET /payment/methods/:paymentMethodId',
    {
      params: { paymentMethodId: paymentMethodId ?? '' },
    },
    {
      enabled: Boolean(paymentMethodId),
    },
  )

  const removePaymentMethodMutation = usePortalMutation(
    'DELETE /payment/methods/:paymentMethodId',
    {
      onSuccess: () => {
        navigate(`${routes.portal.index}/${routes.portal.children.billing.index}`, {
          replace: true,
        })
      },
      onError: error => {
        showBanner({
          type: 'error',
          label: error.message ?? 'Unable to remove payment method',
        })
      },
    },
  )

  if (!paymentMethodId || (!paymentMethod && !fetchPaymentMethodLoading)) {
    return <FallbackContent boundary='portal' />
  }

  if (fetchPaymentMethodLoading) {
    return <Skeletons />
  }

  return (
    <Stack spacing='lg'>
      <Stack spacing='md'>
        <TitleTwo>Remove card</TitleTwo>
        <Text>
          Are you sure you want to remove {capitalize(paymentMethod?.brand)} ending in{' '}
          {paymentMethod?.last4}?
        </Text>
      </Stack>
      <Flex gap='sm' direction={isMobile ? 'column' : 'row'}>
        <PrimaryButton
          fullWidth={isMobile}
          disabled={removePaymentMethodMutation.isLoading}
          loading={removePaymentMethodMutation.isLoading}
          onClick={() =>
            removePaymentMethodMutation.mutate({
              params: { paymentMethodId },
            })
          }
        >
          Remove
        </PrimaryButton>
        <SecondaryButton
          disabled={removePaymentMethodMutation.isLoading}
          fullWidth={isMobile}
          component={Link}
          to={`${routes.portal.index}/${routes.portal.children.billing.index}`}
        >
          Cancel
        </SecondaryButton>
      </Flex>
    </Stack>
  )
}
