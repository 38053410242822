import type { StripeElements, StripeError } from '@stripe/stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { UseMutationOptions, useMutation } from 'react-query'

export type UseSubmitPaymentInfoMutationProps = {
  stripe: Stripe | null
  elements: StripeElements | null
  submit?: (paymentMethodId: string) => void
  options?: UseMutationOptions<void, StripeError>
}

export const useSubmitPaymentInfoMutation = ({
  stripe,
  elements,
  submit,
  options,
}: UseSubmitPaymentInfoMutationProps) =>
  useMutation<void, StripeError>(async () => {
    if (!stripe || !elements) {
      return
    }
    // elements.submit validates the payment info
    const { error: submitError } = await elements.submit()
    if (submitError) {
      throw submitError
    }

    // Adds the payment method to the customer in stripe
    const { error: confirmSetupError, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })

    if (confirmSetupError) {
      throw confirmSetupError
    }
    // setupIntent.payment_method is a PaymentMethod object only if confirmSetup is called with expand: ['payment_method']. Otherwise, it is a string or null
    const paymentMethodId =
      typeof setupIntent?.payment_method === 'object' && setupIntent?.payment_method !== null
        ? setupIntent.payment_method.id
        : setupIntent?.payment_method

    return submit?.(paymentMethodId ?? '')
  }, options)
