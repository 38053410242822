import {
  Avatar,
  Box,
  Group,
  ResponsiveBox,
  ScrollAreaAutosize,
  Stack,
  Text,
  TitleTwo,
  useMantineTheme,
} from '@shared/components'
import { Achievement, milestonesMap } from '@shared/types'
import { sortBy } from '@shared/utils'
import React from 'react'
import { AchievedIcon } from '../AchievedIcon'
import { LatestMilestoneCard } from './LatestMilestoneCard'
export const MilestonesSection = ({ achievements }: { achievements: Achievement[] }) => {
  const orderedMilestones = achievements
    .filter(achievement => achievement.type === 'milestone')
    .sort(sortBy({ key: 'dateAchieved', order: 'DESC' }))

  const latestMilestone = milestonesMap.find(
    milestone => milestone.value === orderedMilestones[0]?.name,
  )

  const theme = useMantineTheme()

  return (
    <ResponsiveBox
      desktop={
        <ScrollAreaAutosize
          h='85vh'
          style={{
            borderStyle: 'solid',
            borderColor: theme.other.colors.background[1],
            borderRadius: theme.radius.md,
          }}
        >
          <Stack spacing='md' p='md'>
            <TitleTwo>Milestones</TitleTwo>
            <Text>You keep showing up, and that’s a reason to say congrats!</Text>
            <Group>
              {milestonesMap.map(milestone => {
                const isMilestoneAchieved = achievements.find(
                  achievement => achievement.name === milestone.value,
                )
                if (isMilestoneAchieved) {
                  return (
                    <AchievedIcon
                      key={isMilestoneAchieved.oid}
                      achievement={isMilestoneAchieved}
                      content={milestone}
                    />
                  )
                }
                return (
                  <Avatar
                    size='lg'
                    mx='xs'
                    key={milestone.value}
                    src=''
                    backgroundColor='none'
                    style={{
                      borderWidth: theme.other.sizes.border.md,
                      borderStyle: 'dashed',
                      borderColor: theme.other.colors.actions[1],
                    }}
                  >
                    {/* This box prevents the Avatar from showing the default UserIcon */}
                    <Box></Box>
                  </Avatar>
                )
              })}
            </Group>
            {latestMilestone && <LatestMilestoneCard milestone={latestMilestone} />}
          </Stack>
        </ScrollAreaAutosize>
      }
      mobile={
        <Stack
          spacing='md'
          p='md'
          h='100%'
          style={{
            borderStyle: 'solid',
            borderColor: theme.other.colors.background[1],
            borderRadius: theme.radius.md,
          }}
        >
          <TitleTwo>Milestones</TitleTwo>
          <Text>You keep showing up, and that’s a reason to say congrats!</Text>
          <Group>
            {milestonesMap.map(milestone => {
              const isMilestoneAchieved = achievements.find(
                achievement => achievement.name === milestone.value,
              )
              if (isMilestoneAchieved) {
                return (
                  <AchievedIcon
                    key={isMilestoneAchieved.oid}
                    achievement={isMilestoneAchieved}
                    content={milestone}
                  />
                )
              }
              return (
                <Avatar
                  size='lg'
                  mx='xs'
                  key={milestone.value}
                  src=''
                  backgroundColor='none'
                  style={{
                    borderWidth: theme.other.sizes.border.md,
                    borderStyle: 'dashed',
                    borderColor: theme.other.colors.actions[1],
                  }}
                >
                  {/* This box prevents the Avatar from showing the default UserIcon */}
                  <Box></Box>
                </Avatar>
              )
            })}
          </Group>
          {latestMilestone && <LatestMilestoneCard milestone={latestMilestone} />}
        </Stack>
      }
    />
  )
}
