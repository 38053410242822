import { DatabaseMetadata, StateAbbr } from '.'

export type ReferralPartnerModel = {
  // e.g. "Bradford Recovery Center"
  name: string
  active: boolean
  states: StateAbbr[]
  assets: {
    logo: {
      // filename only, GCS bucket path will be prepended in business logic
      filename: string
    }
  }
  links: {
    homepage: string
    zoho: string
  }
  createdBy: string
  hasChaseList?: boolean
}

export type ReferralPartner = ReferralPartnerModel & DatabaseMetadata
