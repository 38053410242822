import { Divider, Group, Skeleton, Text } from '@shared/components'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import * as TaskCompletionAnimation from '../../../assets/complete.json'
import AnimationModal from '../../../common/components/AnimationModal'
import * as FullStory from '../../../common/fullstory'
import { usePortalMutation } from '../../../common/hooks'
import { logger } from '../../../common/logger'
import { routes } from '../../../common/routes'

export const StripePaymentRequestButton = ({
  amountInCents,
  stripeInvoiceIds,
}: {
  amountInCents: number
  stripeInvoiceIds: string[]
}) => {
  const [isSuccessfulPayment, setIsSuccessfulPayment] = React.useState(false)
  const [error, setError] = React.useState('')
  const navigate = useNavigate()
  const stripe = useStripe()

  const paymentIntent = usePortalMutation('POST /payment/intent')
  const clientSecret = paymentIntent.data?.clientSecret || ''

  const { data: paymentRequest, isFetching: paymentRequestIsLoading } = useQuery(
    ['stripe_payment_request', amountInCents, ...stripeInvoiceIds],
    async () => {
      const pr = stripe?.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          // This label is intentionally vague and approved by legal.
          label: 'Ophelia Health',
          amount: amountInCents,
        },
        // Avoid collecting any additional PHI via third party payment methods.
        requestPayerName: false,
        requestPayerEmail: false,
        requestPayerPhone: false,
        // For now, only Apple Pay and Google Pay are enabled.
        disableWallets: ['link', 'browserCard'],
      })

      const result = await pr?.canMakePayment()

      if (result) {
        FullStory.event('Rendered Stripe Payment Request Button Element', {
          enabled_wallets: JSON.stringify(result),
        })
        return pr
      }

      return null
    },
    {
      enabled: Boolean(stripe),
      // disable caching
      cacheTime: 0,
    },
  )

  useEffect(() => {
    if (!paymentRequest || !stripe || !clientSecret) {
      return
    }

    paymentRequest.on('cancel', () => {
      logger.debug('Payment canceled. This means the customer closed the payment interface.')
    })

    paymentRequest.on('paymentmethod', async event => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: event.paymentMethod.id,
        },
        {
          handleActions: false,
        },
      )

      if (confirmError) {
        /*
         * Report to the browser that the payment failed, prompting it to
         * re-show the payment interface, or show an error message and close
         * the payment interface.
         */
        event.complete('fail')
        const errorMessage =
          confirmError?.message || 'Payment failed, please try again or use a different card'
        logger.error(errorMessage)
        // User-facing error message
        setError(errorMessage)
      } else {
        /*
         * Report to the browser that the confirmation was successful, prompting
         * it to close the browser payment method collection interface.
         */
        event.complete('success')
        /*
         * Check if the PaymentIntent requires any actions and, if so, let Stripe.js
         * handle the flow. If using an API version older than "2019-02-11"
         * instead check for: `paymentIntent.status === "requires_source_action"`.
         */
        if (paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const res = await stripe.confirmCardPayment(clientSecret)
          const error = res?.error
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            logger.error(error?.message || 'Apple Pay failed (requires_action)')
            // User-facing error message
            setError('Payment failed, please try a different card')
          } else {
            // The payment has succeeded -- show a success message to your customer.
            setIsSuccessfulPayment(true)
          }
        } else {
          FullStory.event('Payment Succeeded Using Stripe Payment Request Button Element')
          // The payment has succeeded -- show a success message to your customer.
          setIsSuccessfulPayment(true)
        }
      }
    })
  }, [stripe, paymentRequest, clientSecret])

  // Do not render anything in this section if the browser env does not support a Payment Request Button
  if (!paymentRequest) {
    return null
  }

  return (
    <>
      {/* getting stripe payment button loading state */}
      {!paymentRequest && paymentRequestIsLoading && <Skeleton width='100%' height={40} />}
      {/* show stripe button when done loading  */}
      {paymentRequest && !paymentRequestIsLoading && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest,
            style: {
              paymentRequestButton: {
                // This makes the buttons stand out against the portal's black background
                theme: 'light',
              },
            },
          }}
          onClick={() => {
            // send FullStory event
            FullStory.event('Clicked Stripe Payment Request Button Element')
            // clear any previous errors
            setError('')
            // Use the button click to trigger the creation of a PaymentIntent on the server.
            paymentIntent.mutate({
              data: {
                amountInCents,
                stripeInvoiceIds,
                createdBy: 'stripe_payment_request_button',
              },
            })
          }}
        />
      )}
      {error && <Text color={colors => colors.error[0]}>{error}</Text>}
      {/* the stripe button needs to own the divider because only it knows whether it's visible or not */}
      <Group spacing='sm' position='center' noWrap>
        <Divider style={{ flexGrow: 1 }} />
        <Text color={colors => colors.text[1]}>Or pay with card</Text>
        <Divider style={{ flexGrow: 1 }} />
      </Group>
      {/* success modal */}
      <AnimationModal
        onClose={() => {
          navigate(`${routes.portal.index}/${routes.portal.children.billing.index}`)
        }}
        opened={isSuccessfulPayment}
        animation={TaskCompletionAnimation}
        title='Thank you!'
        tagline='Your payment was successful'
        timeout='5 sec'
      />
    </>
  )
}
