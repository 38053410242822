import { z } from 'zod'
import { ApiSchemas } from '../../utils'

const PostMergeAccountsInCustomerIOReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
    body: z.object({
      toPatientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const AccountMergingRouteSchemas = {
  'POST /patients/:patientId/merge-accounts/customerio': PostMergeAccountsInCustomerIOReqRes,
} satisfies ApiSchemas
