import { storage } from '@shared/components'
import { IntendedPaymentMethod } from '@shared/types'

type LocalStorage = {
  wxflow_ref: string
}

type SurveyStorageOption = 'show' | 'submitted'

type SessionStorage = {
  residence_state: string
  redirect_after_login: string
  has_shown_refer_friends_interstitial: boolean
  onboarding_workflow: { id: string; partner: string | undefined }
  scheduled_for_later_while_cmn_available_survey: SurveyStorageOption
  welcome_call_topics_survey: SurveyStorageOption
  referral_workflow_prompt_response: string
  provider_referral_workflow_prompt_response: string
  insurance_provider: string
  intended_payment_method: IntendedPaymentMethod
}

const {
  clearLocalStorage,
  clearSessionStorage,
  getLocalStorageItem,
  getSessionStorageItem,
  setLocalStorageItem,
  setSessionStorageItem,
  removeLocalStorageItem,
  removeSessionStorageItem,
} = storage<LocalStorage, SessionStorage>()

export {
  clearLocalStorage,
  clearSessionStorage,
  getLocalStorageItem,
  getSessionStorageItem,
  removeLocalStorageItem,
  removeSessionStorageItem,
  setLocalStorageItem,
  setSessionStorageItem,
}
