import { UseFormReturnType } from '@mantine/form'
import {
  Checkbox,
  DateInput,
  FormPredicate,
  FormSkipPredicate,
  Grid,
  SelectItem,
  TextInput,
  isAnySelected,
  validateWith,
} from '@shared/components'
import { validCharacters } from '@shared/utils'
import React from 'react'
import { isAtleastOneWord, isBirthday, isFileNotLoading, isRequired } from '../forms'
import { InsuranceSelect } from './InsuranceSelect'
import { UploadPhoto } from './upload'

type InsuranceInputProps<T> = {
  form: UseFormReturnType<T>
  map: InsuranceInputFieldMap<T>
  saving: boolean
  insurances: SelectItem[]
  withCards?: boolean
  'test-id'?: string
}

export type InsuranceInputFieldMap<T> = Record<
  | 'plan'
  | 'firstName'
  | 'lastName'
  | 'birthday'
  | 'memberId'
  | 'groupId'
  | 'rxBin'
  | 'isPrimarySubscriber'
  | 'subscriberFirstName'
  | 'subscriberLastName'
  | 'subscriberBirthday'
  | 'cardFront'
  | 'cardBack',
  keyof T
>

export function makeFormInsuranceRules<T extends object>(
  map: InsuranceInputFieldMap<T>,
  {
    insurances,
    ignore,
    withCards,
  }: { insurances: SelectItem[]; ignore?: boolean; withCards: boolean },
) {
  if (ignore) {
    return {}
  }

  const skipIfIsPrimarySubscriber: FormSkipPredicate<unknown, T> = (_, values) =>
    Boolean(values?.[map.isPrimarySubscriber])

  const validations: Record<string, FormPredicate<string, T>> = {
    [map.plan]: isAnySelected(insurances, 'Required'),
    [map.firstName]: validateWith(isRequired, isAtleastOneWord),
    [map.lastName]: validateWith(isRequired, isAtleastOneWord),
    [map.birthday]: validateWith(isRequired, isBirthday),
    [map.memberId]: validateWith(isRequired),
    [map.subscriberFirstName]: validateWith(
      skipIfIsPrimarySubscriber,
      isRequired,
      isAtleastOneWord,
    ),
    [map.subscriberLastName]: validateWith(skipIfIsPrimarySubscriber, isRequired, isAtleastOneWord),
    [map.subscriberBirthday]: validateWith(skipIfIsPrimarySubscriber, isRequired, isBirthday),
  }

  if (withCards) {
    validations[map.cardFront as string] = validateWith(isRequired, isFileNotLoading)
    validations[map.cardBack as string] = validateWith(isRequired, isFileNotLoading)
  }

  return validations
}

export function InsuranceInput<T>({
  form,
  insurances,
  saving,
  map,
  withCards,
  ...props
}: InsuranceInputProps<T>) {
  const testId = props['test-id'] ?? 'test'

  return (
    <>
      <Grid.Col span={12}>
        <InsuranceSelect
          test-id={`input:${testId}-provider`}
          disabled={saving}
          data={insurances}
          {...form.getInputProps(map.plan)}
          placeholder='Type to find your plan...'
          rightSection={<> </>}
          searchable
          clearable
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          test-id={`input:${testId}-cardholder-first-name`}
          disabled={saving}
          placeholder='First name'
          label='Cardholder first name'
          formatter={validCharacters.name}
          maxLength={64}
          {...form.getInputProps(map.firstName)}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          test-id={`input:${testId}-cardholder-last-name`}
          disabled={saving}
          placeholder='Last name'
          label='Cardholder last name'
          formatter={validCharacters.name}
          maxLength={64}
          {...form.getInputProps(map.lastName)}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <DateInput
          test-id={`input:${testId}-cardholder-birthday`}
          label="Cardholder's Birthday"
          {...form.getInputProps(map.birthday)}
          disabled={saving}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          test-id={`input:${testId}-member-id`}
          disabled={saving}
          placeholder='XXXXXXXXXX'
          label='Member ID'
          maxLength={64}
          {...form.getInputProps(map.memberId)}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          test-id={`input:${testId}-group-id`}
          disabled={saving}
          placeholder='Group ID'
          label='Group ID (optional)'
          maxLength={64}
          {...form.getInputProps(map.groupId)}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          test-id={`input:${testId}-rx-bin`}
          disabled={saving}
          placeholder='6-digit code'
          label='Rx Bin (optional)'
          maxLength={64}
          {...form.getInputProps(map.rxBin)}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Checkbox
          test-id={`checkbox:${testId}-is-subscriber`}
          disabled={saving}
          label='I am the primary subscriber'
          {...form.getInputProps(map.isPrimarySubscriber, {
            type: 'checkbox',
          })}
        />
      </Grid.Col>
      {!form.values[map.isPrimarySubscriber] && (
        <>
          <Grid.Col span={12} md={6}>
            <TextInput
              test-id={`input:${testId}-subscriber-first-name`}
              disabled={saving}
              placeholder='First name'
              label='Primary subscriber first name'
              formatter={validCharacters.name}
              maxLength={64}
              {...form.getInputProps(map.subscriberFirstName)}
            />
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <TextInput
              test-id={`input:${testId}-subscriber-last-name`}
              disabled={saving}
              placeholder='Last name'
              label='Primary subscriber last name'
              formatter={validCharacters.name}
              maxLength={64}
              {...form.getInputProps(map.subscriberLastName)}
            />
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <DateInput
              test-id={`input:${testId}-subscriber-birthday`}
              disabled={saving}
              label={`Primary subscriber's birthday`}
              {...form.getInputProps(map.subscriberBirthday)}
            />
          </Grid.Col>
          <Grid.Col span={12} md={6} />
        </>
      )}
      {withCards && (
        <>
          <Grid.Col span={12} md={6}>
            <UploadPhoto
              test-id={`input:${testId}-card-front`}
              disabled={saving}
              label='Insurance card front'
              {...form.getInputProps(map.cardFront)}
              setError={error => form.setFieldError(map.cardFront, error)}
            />
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <UploadPhoto
              test-id={`input:${testId}-card-back`}
              disabled={saving}
              label='Insurance card back'
              {...form.getInputProps(map.cardBack)}
              setError={error => form.setFieldError(map.cardBack, error)}
            />
          </Grid.Col>
        </>
      )}
    </>
  )
}
