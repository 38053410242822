import { z } from 'zod'
import { ApiSchemas } from '../../..'

const PostCloneEncounterReqRes = {
  req: z.object({
    params: z.object({
      encounterId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      patientId: z.string(),
    }),
  }),
}

export const EncountersRouteSchemas = {
  'POST /encounters/:encounterId/clone': PostCloneEncounterReqRes,
} satisfies ApiSchemas
