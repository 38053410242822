import { Group, PrimaryButton, SecondaryButton, Stack } from '@shared/components'
import React from 'react'
import { usePortalDims } from '../hooks'

type FormSaveSectionProps = {
  loading?: boolean
  disabled?: boolean
  onSubmit?: () => void
  onCancel: () => void
  'test-id'?: string
}

export const FormSaveSection = ({
  loading = false,
  onSubmit,
  onCancel,
  disabled = false,
  ...props
}: FormSaveSectionProps) => {
  const { isMobile } = usePortalDims()

  return isMobile ? (
    <Stack mt='md'>
      <SecondaryButton
        test-id={`button:${props['test-id']}-cancel`}
        disabled={loading}
        onClick={() => onCancel()}
        fullWidth
      >
        Cancel
      </SecondaryButton>
      {onSubmit && (
        <PrimaryButton
          test-id={`button:${props['test-id']}-save`}
          loading={loading}
          disabled={disabled}
          onClick={onSubmit}
          fullWidth
        >
          Save
        </PrimaryButton>
      )}
    </Stack>
  ) : (
    <Group mt='md'>
      <SecondaryButton
        test-id={`button:${props['test-id']}-cancel`}
        disabled={loading}
        onClick={() => onCancel()}
      >
        Cancel
      </SecondaryButton>
      {onSubmit && (
        <PrimaryButton
          test-id={`button:${props['test-id']}-save`}
          loading={loading}
          disabled={disabled}
          onClick={onSubmit}
        >
          Save
        </PrimaryButton>
      )}
    </Group>
  )
}
