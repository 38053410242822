import { ScrollAreaAutosize, Stack, TitleTwo, useMantineTheme } from '@shared/components'
import { Achievement, badgesMap } from '@shared/types'
import React from 'react'
import { SortedBadgesSection } from './SortedBadgesSection'

export const BadgesSection = ({ achievements }: { achievements: Achievement[] }) => {
  const referralsSent = badgesMap.filter(badge => badge.value.includes('referral_sent'))
  // const thankYouNotesReceived = badgesMap.filter(badge => badge.value.includes('thank_you'))
  const theme = useMantineTheme()

  return (
    <ScrollAreaAutosize
      h='85vh'
      style={{
        borderStyle: 'solid',
        borderColor: theme.other.colors.background[1],
        borderRadius: theme.radius.md,
      }}
    >
      <Stack spacing='md' p='md'>
        <TitleTwo>Badges</TitleTwo>
        <SortedBadgesSection
          key='referrals_sent'
          title='Your referrals'
          subtitle='Each one you’ve sent helps save a life'
          sortedBadges={referralsSent}
          achievements={achievements}
        />
        {/* This section is commented out until we get at least one person to submit a thank you note */}
        {/* <SortedBadgesSection
        key='thank_you_notes_received'
        title='Thank you notes received'
        subtitle='When a friend you referred starts treatment, they have the chance to thank you'
        sortedBadges={thankYouNotesReceived}
        achievements={achievements}
      /> */}
      </Stack>
    </ScrollAreaAutosize>
  )
}
