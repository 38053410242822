import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { routes } from '../common/routes'
import { PartnerPage } from '../welcome/pages/PartnerPage'
import * as Pages from './pages'

export const ReferralsApp = () => {
  return (
    <Routes>
      <Route index element={<Pages.Referrals />} />
      <Route
        path=':referralPartnerId'
        element={
          <PartnerPage>
            <Pages.Referrals />
          </PartnerPage>
        }
      />
      <Route
        path={`:referralPartnerId${routes.referrals.children.workflowPrompt}`}
        element={
          <PartnerPage>
            <Pages.Referrals />
          </PartnerPage>
        }
      />
      <Route path={routes.referrals.children.workflowPrompt} element={<Pages.Referrals />} />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}
