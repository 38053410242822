import { z } from 'zod'
import { ApiSchemas } from '../../../utils'

const ListInsurancePayersReqResSchemas = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        payerId: z.string(),
        name: z.string(),
        inNetworkStates: z.array(z.string()).or(z.literal('all')),
      }),
    ),
  }),
}

const GetInsurancePayerReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
    query: z.object({
      planType: z.string().optional(),
    }),
  }),
  res: z.object({
    data: z.object({
      isBundlePlan: z.boolean(),
    }),
  }),
}

export const EmrInsurancePayersApi = {
  'GET /insurance-payers': ListInsurancePayersReqResSchemas,
  'GET /insurance-payers/:id': GetInsurancePayerReqResSchemas,
} satisfies ApiSchemas
