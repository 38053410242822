import { isEmail, useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TextInput,
  skipIfEmpty,
  validateWith,
} from '@shared/components'
import { phone } from '@shared/utils'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { isFullName, isPhone, isRequired } from '../../../forms'
import { WidgetProps } from '../PromptContentWidget'

type ProviderContactForm = {
  fullName: string
  email: string
  phone?: string
  organization: string
}

export const ReferringProviderContactForm = ({ response, onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const onBack = () => navigate(-1)

  const form = useForm<ProviderContactForm>({
    validateInputOnBlur: ['email'],
    initialValues: {
      fullName: (response?.provider_full_name?.value as string) ?? '',
      email: (response?.provider_email?.value as string) ?? '',
      phone: (response?.provider_phone?.value as string) ?? '',
      organization: (response?.provider_organization?.value as string) ?? '',
    },
    validate: {
      fullName: validateWith(isFullName),
      email: validateWith(isRequired, isEmail),
      phone: validateWith(skipIfEmpty, isPhone),
      organization: validateWith(isRequired),
    },
  })

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    onSubmit({
      value: {
        contextKey: 'custom',
        value: form.values.fullName,
      },
      provider_full_name: {
        contextKey: 'custom',
        value: form.values.fullName,
      },
      provider_email: {
        contextKey: 'custom',
        value: form.values.email,
      },
      provider_phone: {
        contextKey: 'custom',
        value: phone(form.values.phone).normalized || '',
      },
      provider_organization: {
        contextKey: 'custom',
        value: form.values.organization,
      },
    })
  }

  return (
    <Box test-id='content:payment-method'>
      <Stack spacing='md'>
        <TextInput
          autoFocus
          type='text'
          label='Your full name'
          placeholder='e.g. John Smith'
          {...form.getInputProps('fullName')}
        />
        <TextInput
          type='email'
          label='Your email'
          placeholder='Email address'
          {...form.getInputProps('email')}
        />
        <PhoneInput label='Your phone (optional)' {...form.getInputProps('phone')} />
        <TextInput
          type='text'
          label='Healthcare setting name'
          placeholder='Healthcare setting name'
          {...form.getInputProps('organization')}
        />
        <ResponsiveBox
          mt='lg'
          mobile={
            <Stack spacing='lg' align='center'>
              <PrimaryButton fullWidth test-id='button:next@mobile' onClick={submitHandler}>
                Next
              </PrimaryButton>
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            </Stack>
          }
          desktop={
            <Group position='apart'>
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
              <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                Next
              </PrimaryButton>
            </Group>
          }
        />
      </Stack>
    </Box>
  )
}
