import { GetInputProps, Select, Stack } from '@shared/components'
import { toTime } from '@shared/utils'
import algoliasearch from 'algoliasearch'
import debounce from 'lodash/debounce'
import { default as React, useState } from 'react'
import { useQuery } from 'react-query'
import { Config } from '../config'

export type CityStateForm = { state: string; city: string }

export const CITY_STATE_UNKNOWN = 'city_state_unknown'
export const CITY_STATE_OTHER = 'city_state_other'

export type CityStateFormInputProps<T> = GetInputProps<T>

type StateObject = {
  state: string
  abbr: string
  active: boolean
  paused: boolean
}

type CityStateSelectGroupProps = {
  states: readonly StateObject[]
  inputProps: CityStateFormInputProps<CityStateForm>
}

// Returns dropdown for states, and dropdown of places for the state if state is selected
export const CityStateSelectGroup = ({ states, inputProps }: CityStateSelectGroupProps) => {
  const cityValue = inputProps('city').value
  const stateValue = inputProps('state').value

  const [cityQuery, setCityQuery] = useState(cityValue || '')

  const client = algoliasearch(Config.ALGOLIA_APPLICATION_ID, Config.ALGOLIA_SEARCH_KEY)

  const index = client.initIndex('places')
  const showCityDropdown = Boolean(
    stateValue && ![CITY_STATE_UNKNOWN, CITY_STATE_OTHER].includes(stateValue),
  )

  const { data } = useQuery(
    // Call algolia when state is changed, or user types cityQuery
    ['places', stateValue, cityQuery],
    async () => {
      const res = (await index.search(cityQuery, {
        hitsPerPage: 50,
        filters: `state_id:${stateValue}`,
        attributesToRetrieve: ['city'],
      })) as { hits: { city: string; objectID: string }[] }
      return res.hits
    },
    {
      enabled: showCityDropdown,
    },
  )

  const places = data || []

  const debouncedQueryUpdate = debounce((value: string) => {
    const query = [CITY_STATE_UNKNOWN, CITY_STATE_OTHER].includes(cityValue) ? '' : value
    setCityQuery(query)
  }, toTime('0.2 sec').ms())

  const resetCity = (value: string) => {
    inputProps('city').onChange('')
    setCityQuery('')

    inputProps('state').onChange(value)
  }

  return (
    <Stack>
      <Select
        searchable
        placeholder='Select one...'
        label='Which state is the healthcare provider or hospital located in?'
        data={[
          { value: CITY_STATE_UNKNOWN, label: `I don't know` },
          { value: CITY_STATE_OTHER, label: 'Other' },
          ...states.map((state: StateObject) => ({
            value: state.abbr,
            label: state.state,
          })),
        ]}
        {...inputProps('state')}
        onChange={resetCity}
      />
      {showCityDropdown && (
        <Select
          searchable
          placeholder='Type to search...'
          clearable
          label='Which city is the healthcare provider or hospital located in?'
          data={[
            { value: CITY_STATE_UNKNOWN, label: `I don't know` },
            { value: CITY_STATE_OTHER, label: 'Other' },
            ...places
              .filter(city => city.city)
              .map(city => ({
                value: city.city,
                label: city.city,
              })),
          ]}
          {...inputProps('city')}
          onSearchChange={debouncedQueryUpdate}
        />
      )}
    </Stack>
  )
}
