import React from 'react'
import { useNavigate } from 'react-router-dom'
import { WorkflowPrompt } from '../../common/components/prompt/WorkflowPrompt'
import { routes } from '../../common/routes'

export const Reconnect = () => {
  const navigate = useNavigate()

  return (
    <WorkflowPrompt
      workflowApi='workflow'
      workflowType='reconnect'
      showProgressNumbers={false}
      prefix={routes.reconnect.index}
      onComplete={() => {
        navigate(routes.scheduled.index)
      }}
    />
  )
}

export default Reconnect
