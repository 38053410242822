import dayjs, { PluginFunc } from 'dayjs'

type Inclusivity = '[]' | '()' | '[)' | '(]'

declare module 'dayjs' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Dayjs {
    getBetween(
      date: dayjs.ConfigType,
      unit: 'days' | 'months' | 'hours' | 'years',
      inclusivity?: Inclusivity,
    ): string[]
  }
}

/**
 * Gets the days or months between two dates (defaults as inclusive of both dates).
 */
const getBetween: PluginFunc<unknown> = (option, dayjsClass) => {
  dayjsClass.prototype.getBetween = function (
    date: dayjs.ConfigType,
    unit: 'days' | 'months' | 'hours' | 'years',
    inclusivity: Inclusivity = '[]',
  ) {
    const startOfUnit = this.startOf(unit)
    const endOfUnit = dayjs(date).endOf(unit)
    const unitsBetween = endOfUnit.diff(startOfUnit, unit)

    const startsExclusive = inclusivity.startsWith('(')
    const endsExclusive = inclusivity.endsWith(')')

    // If a date is exclusive on either side, adjust index to limit range
    const startIndex = startsExclusive ? 1 : 0
    const endIndex = endsExclusive ? unitsBetween - 1 : unitsBetween

    const units = []
    for (let i = startIndex; i <= endIndex; i++) {
      units.push(getFormatted(startOfUnit.add(i, unit), unit))
    }

    return units
  }
}

const getFormatted = (date: dayjs.Dayjs, unit: 'days' | 'months' | 'hours' | 'years') => {
  switch (unit) {
    case 'days':
      return date.format('YYYY-MM-DD')
    case 'months':
      return date.format('YYYY-MM')
    case 'years':
      return date.format('YYYY')
    default:
      return date.toISOString()
  }
}

export default getBetween
