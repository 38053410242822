import { z } from 'zod'
import { ApiSchemas } from '../../..'

/*
 * PostInboundMessageReqRes is the schema for the request and response of the POST /message endpoint
 * which is hit by Twilio each time an individual SMS message is received by our patient support phone number.
 */
export const PostInboundMessageReqRes = {
  req: z.object({
    body: z.object({
      Body: z.string(),
      From: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
  requestSchemaStrictValidation: true,
}

export const InboundMessageSchemas = {
  'POST /message': PostInboundMessageReqRes,
} satisfies ApiSchemas
