// eslint-disable-next-line no-restricted-imports
import {
  ScrollArea as MantineScrollArea,
  ScrollAreaProps as MantineScrollAreaProps,
} from '@mantine/core'
import React from 'react'

export type ScrollAreaProps = Omit<
  MantineScrollAreaProps,
  'sx' | 'nonce' | 'onResize' | 'onResizeCapture'
> & { constrict?: boolean }

export type ScrollAreaAutosizeProps = ScrollAreaProps & {
  maxHeight?: React.CSSProperties['maxHeight']
}

export const ScrollArea = React.forwardRef<HTMLDivElement, ScrollAreaProps>(
  ({ type = 'scroll', constrict, ...props }, ref) => {
    return (
      <MantineScrollArea
        {...props}
        viewportRef={ref}
        type={type}
        sx={({ other: { colors } }) => ({
          '.mantine-ScrollArea-thumb': { backgroundColor: colors.actions[0], zIndex: 1 },
          /*
           * The default display is table, and there isn't any styles prop to override the most inner component.
           * Not sure why the default is table, but block works best for horizontal resizing.
           */
          '.mantine-ScrollArea-viewport > div': constrict ? { display: 'block!important' } : {},
        })}
      />
    )
  },
)

ScrollArea.displayName = 'ScrollArea'

export const ScrollAreaAutosize = React.forwardRef<HTMLDivElement, ScrollAreaAutosizeProps>(
  ({ maxHeight = '100%', type = 'scroll', constrict, ...props }, ref) => {
    return (
      <MantineScrollArea.Autosize
        {...props}
        ref={ref}
        type={type}
        mah={maxHeight}
        sx={({ other: { colors } }) => ({
          '.mantine-ScrollArea-thumb': { backgroundColor: colors.actions[0], zIndex: 1 },
          /*
           * The default display is table, and there isn't any styles prop to override the most inner component.
           * Not sure why the default is table, but block works best for horizontal resizing.
           */
          '.mantine-ScrollArea-viewport > div': constrict ? { display: 'block!important' } : {},
        })}
      />
    )
  },
)

ScrollAreaAutosize.displayName = 'ScrollAreaAutosize'
