import {
  Banner,
  CircleIcon,
  List,
  ListItem,
  Loader,
  PrimaryButton,
  Stack,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import {
  TOKEN_EXPIRED_ERROR_MESSAGE,
  WELLNESS_CHECK_SEARCH_PARAMS,
  WellnessCheckWorkflowType,
  getOpheliaHttpError,
} from '@shared/types'
import React from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { ContactSupport } from '../../common/components'
import * as FullStory from '../../common/fullstory'
import { useOnboardingDims, usePortalQuery } from '../../common/hooks'
import { routes } from '../../common/routes'
import { WorkflowsTitleDictionary } from './WellnessCheck'

export const WellnessAssessmentQuestionLength: Record<WellnessCheckWorkflowType, number> = {
  phq_9_form: 9,
  phq_8_form: 8,
  barc_10_form: 10,
  gad_7_form: 7,
  asq_form: 0,
}

export const Start = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const token = params.get(WELLNESS_CHECK_SEARCH_PARAMS.Token)
  const { isMobile } = useOnboardingDims()

  useLifecycle({
    onMount: () => {
      FullStory.event('Combined Wellness Assessment Start Page Visited')
    },
  })

  const query = usePortalQuery('GET /wellness-assessment-url', {
    query: {
      token: token || '',
    },
  })

  const combinedWellnessAssessmentUrl = query.data?.combinedWellnessAssessmentUrl
  const wellnessAssessments = query.data?.wellnessAssessments || []
  const hasMultiple = wellnessAssessments.length > 1
  const callToAction = hasMultiple ? 'Begin assessments' : 'Begin assessment'

  // loading state
  if (query.isLoading) {
    return (
      <Stack align='center' justify='center'>
        <Loader />
      </Stack>
    )
  }

  // error state
  if (query.error) {
    const errorMessage = getOpheliaHttpError(query.error, 'Oops, something went wrong!')

    if (errorMessage === TOKEN_EXPIRED_ERROR_MESSAGE) {
      navigate(`${routes.wellnessCheck.index}${routes.wellnessCheck.children.expired}`)
      return null
    }

    return (
      <Banner
        type='error'
        label={getOpheliaHttpError(query.error, 'Oops, something went wrong!')}
      />
    )
  }

  // Empty state
  if (!combinedWellnessAssessmentUrl || wellnessAssessments.length === 0) {
    return (
      <Banner
        type='success'
        label="You don't have any wellness assessments to complete at this time."
      />
    )
  }

  return (
    <Stack spacing='md'>
      <TitleTwo>Please complete your wellness assessments before your next visit</TitleTwo>
      <Text>Your clinician has requested you complete the following assessments.</Text>
      <List spacing='sm'>
        {wellnessAssessments.map(assessment => {
          return (
            <ListItem
              key={assessment}
              icon={<CircleIcon color={c => c.actions[0]} size='sm' style={{ marginTop: 4 }} />}
            >
              <Text bold>{WorkflowsTitleDictionary[assessment]}</Text>
              <Text>{WellnessAssessmentQuestionLength[assessment]} questions</Text>
            </ListItem>
          )
        })}
      </List>
      <Text>
        Your answers will help us provide you with supportive care that’s tailored to your unique
        needs.
      </Text>
      <PrimaryButton
        fullWidth={isMobile}
        component={Link}
        to={{
          // The combined wellness assessment URL is a full URL, so we need to parse it to get the pathname and search.
          pathname: new URL(combinedWellnessAssessmentUrl).pathname,
          search: new URL(combinedWellnessAssessmentUrl).search,
        }}
      >
        {callToAction}
      </PrimaryButton>
      <ContactSupport />
    </Stack>
  )
}
