import { UseFormReturnType } from '@mantine/form'
import { LooseKeys } from '@mantine/form/lib/types'
import { Stack, TextInput } from '@shared/components'
import React from 'react'
import { SkipOnboardingForm } from './SkipOnboardingDrawer'

export const FirstNameInput = ({
  form,
  path,
  disabled,
}: {
  form: UseFormReturnType<SkipOnboardingForm, (values: SkipOnboardingForm) => SkipOnboardingForm>
  path: LooseKeys<SkipOnboardingForm>
  disabled: boolean
}) => {
  return (
    <Stack spacing='md'>
      <TextInput
        disabled={disabled}
        autoFocus
        label='First name'
        placeholder='First name'
        {...form.getInputProps(path)}
      />
    </Stack>
  )
}
