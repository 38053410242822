import { DatabaseMetadata } from '..'

export type OphelinkType =
  | 'friend_referral'
  | 'friend_referral_personalized'
  | '3_month_milestone'
  | '6_month_milestone'
  | '1_year_milestone'

export type OphelinkModel = {
  type: OphelinkType
  sourceUrl: string
}

export type Ophelink = OphelinkModel & DatabaseMetadata
