import {
  ArrowLeftIcon,
  CreditCardIcon,
  ExternalLinkText,
  Group,
  HelpCircleIcon,
  InfoIcon,
  List,
  ListItem,
  PhoneCallIcon,
  PrimaryButton,
  ResponsiveBox,
  Space,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
} from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as FullStory from '../../../../common/fullstory'
import { sendTrackEvent } from '../../../rudderstack'
import { WidgetProps } from '../PromptContentWidget'

export const WelcomeCallExplanation = ({ onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const back = () => navigate(-1)

  const handleClickFAQs = () => {
    sendTrackEvent('Visit FAQs Clicked')
    FullStory.event('Visit FAQs Clicked')
  }

  const submitHandler = () => {
    onSubmit({
      value: {
        contextKey: 'custom',
        value: 'welcome_call_explanation',
      },
    })
  }

  return (
    <Stack spacing='md' test-id='content:welcome-call-explanation'>
      <Stack spacing='xs'>
        <TitleTwo>You&apos;re ready for the next step!</TitleTwo>
      </Stack>
      <Stack spacing='sm'>
        <Text>
          Now let&apos;s book your free 15-minute welcome call with an Ophelia team member who will:
        </Text>
        <Space h='xs' />
        <List
          spacing='sm'
          sx={() => ({ paddingLeft: 0 })}
          styles={theme => ({ itemIcon: { color: theme.other.colors.text[1] } })}
        >
          <ListItem icon={<PhoneCallIcon />}>Chat with you about Ophelia</ListItem>
          <ListItem icon={<HelpCircleIcon />}>Answer all your questions</ListItem>
          <ListItem icon={<CreditCardIcon />}>Explain insurance & payment options</ListItem>
        </List>
        <Space h='xs' />
        <Text>
          If we&apos;re a good fit, we&apos;ll schedule your first appointment during this call.
        </Text>
      </Stack>

      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton fullWidth test-id='button:next@mobile' onClick={submitHandler}>
              Schedule welcome call
            </PrimaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={back}
              fullWidth
            >
              Back
            </TertiaryButton>
            <Group spacing='xs' position='center'>
              <InfoIcon size='sm' color={colors => colors.actions[1]} />
              <ExternalLinkText
                ml='xs'
                onClick={handleClickFAQs}
                href='https://ophelia.com/faq'
                target='_blank'
              >
                Visit our FAQs
              </ExternalLinkText>
            </Group>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <Group position='left'>
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={back}
              >
                Back
              </TertiaryButton>
              <ExternalLinkText
                onClick={handleClickFAQs}
                href='https://ophelia.com/faq'
                target='_blank'
              >
                Visit our FAQs
              </ExternalLinkText>
            </Group>
            <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
              Schedule welcome call
            </PrimaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
