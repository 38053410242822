import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Group,
  PinInput,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  Space,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  emojiMap,
  useBanner,
  useLifecycle,
} from '@shared/components'
import { getOpheliaHttpError } from '@shared/types'
import { AxiosError } from 'axios'
import React, { useState } from 'react'
import { UseMutationResult, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { authApi, currentUserApi, referralsApi } from '../../common/api'
import { useAuth, useScrollIntoViewOnLayoutEffect } from '../../common/hooks'
import { logger } from '../../common/logger'
import { sendPageEvent } from '../../common/rudderstack'
import { getLocalStorageItem } from '../../common/storage'

type VerificationProps = {
  isSignin?: boolean
  phoneNumber: string
  signInWithPhoneNumber: UseMutationResult<void, unknown, string, unknown>
}

export const Verification = ({
  isSignin,
  signInWithPhoneNumber,
  phoneNumber,
}: VerificationProps) => {
  const { targetRef } = useScrollIntoViewOnLayoutEffect()
  const { showBanner } = useBanner()
  const navigate = useNavigate()
  const auth = useAuth()
  const query = useQueryClient()
  const [submitAttempts, setSubmitAttempts] = useState(0)

  const addPatientToReferral = useMutation(referralsApi.addPatientToReferral, {
    onSuccess: () => {
      void query.invalidateQueries('/referrals/:referralId')
    },
    onError: error => {
      logger.exception(error)
    },
  })

  useLifecycle({
    onMount: () => {
      logger.info('Phone Verification', { tags: { workflow: 'onboarding' } })
      sendPageEvent('Phone Verification')
    },
  })

  const form = useForm<{ verificationCode: string }>({
    initialValues: { verificationCode: '' },
    validate: {
      verificationCode: value =>
        !authApi.parseVerificationCode(value).isValid && 'Must be a 6-digit code',
    },
  })

  // loginWithVerificationCode will return a JWT that we pass to signInWithToken
  const loginWithVerificationCode = useMutation<{ token: string }, AxiosError, string>(
    (code: string) => currentUserApi.loginWithOTP({ phone: phoneNumber, code }),
    {
      onSuccess: async data => {
        const referralId = getLocalStorageItem('wxflow_ref')
        await authApi.signInWithToken(data.token)

        if (referralId) {
          addPatientToReferral.mutate(referralId)
        }
      },
      onError: error => {
        logger.exception(error)
        form.setFieldError('verificationCode', getOpheliaHttpError(error))
      },
    },
  )

  const onConfirmVerificationCode = () => {
    setSubmitAttempts(submitAttempts + 1)
    if (!form.validateField('verificationCode').hasError) {
      loginWithVerificationCode.mutate(form.values.verificationCode)
    }
  }

  const onResendVerificationCode = async () => {
    try {
      await signInWithPhoneNumber.mutateAsync(phoneNumber)
      showBanner({ type: 'success', label: 'We resent your code', dismissable: true })
    } catch {
      showBanner({ type: 'error', label: 'Failed to send confirmation code', dismissable: true })
    }
  }

  // eslint-disable-next-line no-magic-numbers
  const hiddenNumber = `(***) *** - ${phoneNumber.slice(-4)}`
  const onBack = () => navigate(-1)
  const isLoading = auth.isLoading || loginWithVerificationCode.isLoading

  return (
    <Box test-id='page:code-verification' ref={targetRef}>
      <TitleTwo>{`${emojiMap['open lock']} For your security`}</TitleTwo>
      <Space h='sm' />
      <Text>
        {isSignin
          ? `We texted a code to ${hiddenNumber}. If you're an Ophelia patient, this code will bring you to your patient portal.`
          : `Please confirm your phone number by entering the confirmation code sent to ${hiddenNumber}.`}
      </Text>
      <Space h='lg' />
      <PinInput
        oneTimeCode
        test-id='input:code'
        disabled={isLoading}
        length={6}
        {...form.getInputProps('verificationCode')}
      />
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack align='center' spacing={32}>
            <PrimaryButton
              test-id='button:confirm@mobile'
              fullWidth
              loading={isLoading}
              onClick={onConfirmVerificationCode}
              disabled={signInWithPhoneNumber.isLoading}
            >
              Submit
            </PrimaryButton>
            <TertiaryButton
              test-id='button:send-code@mobile'
              loading={signInWithPhoneNumber.isLoading}
              onClick={onResendVerificationCode}
              disabled={isLoading}
            >
              Send code again
            </TertiaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <Group>
              <SecondaryButton
                test-id='button:send-code@desktop'
                loading={signInWithPhoneNumber.isLoading}
                onClick={onResendVerificationCode}
                disabled={isLoading}
              >
                Send code again
              </SecondaryButton>
              <PrimaryButton
                test-id='button:confirm@desktop'
                loading={isLoading}
                onClick={onConfirmVerificationCode}
                disabled={signInWithPhoneNumber.isLoading}
              >
                Submit
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Box>
  )
}
