export type AcuityAppointment = {
  id: number
  firstName: string
  lastName: string
  phone: string
  email: string
  type: string
  /**
   * June 17, 2013
   */
  date: string
  /**
   * 10:15am
   */
  time: string
  /**
   * 11:15am
   */
  endtime: string
  datetimeCreated: string
  dateCreated: string
  /**
   * 2013-06-17T10:15:00-0700
   */
  datetime: string
  appointmentTypeID: number
  /**
   * "60"
   */
  duration: string
  notes: string
  /**
   * "America/New_York",
   */
  timezone: string
  /**
   * "My Calendar",
   */
  calendar: string
  calendarTimezone: string
  calendarID: number
  location: string
  /**
   * Canceled appointments include a field, noShow which is true if the appointment
   * was marked as a no-show by an admin and false if the appointment is simply canceled.
   */
  noShow: boolean
  canceled: boolean
}

export type AcuityTimeSlot = {
  // "2016-02-04T13:00:00-0800"
  time: string
  calendarID?: number | undefined
  slotsAvailable: number
}

export type AcuityDateSlot = {
  // "2016-02-04"
  date: string
  calendarID?: number | undefined
}

export type AcuityBlock = {
  description: string
  /**
   * 2013-06-17T10:15:00-0700
   */
  until: string
  recurring: 'daily' | 'weekly' | null
  notes: string
  /**
   * 2013-06-17T10:15:00-0700
   */
  start: string
  /**
   * 2013-06-17T10:15:00-0700
   */
  end: string
  calendarID: number
  id: number
}

export type AcuityAddBlockResponse = {
  id: number
  notes: string
  description: string
}

export type AcuityAppointmentType = {
  id: number
  active: 'true' | 'false'
  name: string
  description: string
  duration: number
  price: string
  category: string
  color: string
  private: boolean
  type: string
  classSize: number | null
  paddingAfter: number
  paddingBefore: number
  calendarIDs: number[]
}
