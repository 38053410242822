import { useForm } from '@mantine/form'
import { Grid, validateWith } from '@shared/components'
import React from 'react'
import { UploadPhoto } from '../../../common/components'
import { isFileNotLoading, isRequired } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const UploadIdForm = ({ initialValues, saving, submit }: TaskStepProps<'upload-id'>) => {
  const { validate, values, setFieldError, getInputProps } = useForm({
    initialValues,
    validate: {
      idBack: validateWith(isRequired, isFileNotLoading),
      idFront: validateWith(isRequired, isFileNotLoading),
    },
  })

  function onSubmit() {
    if (!validate().hasErrors) {
      submit('', values)
    }
  }

  return (
    <Grid test-id='step:upload-id'>
      <Grid.Col span={12} sm={6}>
        <UploadPhoto
          label='Front of ID'
          test-id='input:id-front'
          {...getInputProps('idFront')}
          setError={error => setFieldError('idFront', error)}
          disabled={saving}
        />
      </Grid.Col>
      <Grid.Col span={12} sm={6}>
        <UploadPhoto
          label='Back of ID'
          test-id='input:id-back'
          {...getInputProps('idBack')}
          setError={error => setFieldError('idBack', error)}
          disabled={saving}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TaskButton onSubmit={onSubmit} loading={saving} isLastStep />
      </Grid.Col>
    </Grid>
  )
}
