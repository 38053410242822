// eslint-disable-next-line no-restricted-imports
import { ActionIconProps, ActionIcon as MantineActionIcon, useMantineTheme } from '@mantine/core'
import React from 'react'
import { getAttributes, getOutlineFocused, getOutlinePressed } from '../inputs'

export const ActionIcon = ({
  onClick,
  children,
  disabled,
  ...props
}: ActionIconProps & { onClick?: () => void }) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()

  const fontColor = colors.actionText

  return (
    <MantineActionIcon
      variant='filled'
      bg={colors.actions[0]}
      disabled={Boolean(disabled || props.loading)}
      onClick={onClick}
      {...(props as ActionIconProps)}
      {...getAttributes({ ['data-icon']: !children })}
      loaderProps={{ color: fontColor, size: sizes.icon.md }}
      styles={{
        root: {
          color: fontColor,
          border: 'none',
          ':focus': { outline: 'none', boxShadow: getOutlineFocused({ colors, sizes }) },
          ':before': { display: 'none' },
          ':hover': { backgroundColor: colors.actions[1] },
          ':active': { transform: 'unset', boxShadow: getOutlinePressed({ colors, sizes }) },
          ':disabled': { color: fontColor, backgroundColor: colors.actions[3] },
          '&[data-icon]': {
            padding: 'unset',
            '.mantine-Button-leftIcon': {
              margin: 'unset',
            },
          },
        },
      }}
    >
      {children}
    </MantineActionIcon>
  )
}
