// eslint-disable-next-line no-restricted-imports
import { Center } from '@mantine/core'
import React from 'react'
import { Radio, RadioProps } from './radio/Radio'

export const RoundRadio = ({
  disabled,
  children,
  fz = 'xs',
  ...props
}: Omit<RadioProps, 'label' | 'w' | 'h' | 'radius' | 'iconless' | 'sx'>) => (
  <Radio
    w={36}
    h={36}
    radius='xl'
    fz={fz}
    disabled={disabled}
    iconless
    opacity={disabled ? '50%' : '100%'}
    label={<Center>{children}</Center>}
    {...props}
  />
)
