import { Box, Divider, Group, Header, MyOpheliaWordmark } from '@shared/components'
import { REFERRAL_PARAM } from '@shared/types'
import React, { ReactNode } from 'react'
import { ContactSupportHeader, MoreOptions } from '..'
import { CallMeNowQueueStatus } from '../../../welcome/pages/CallMeNowQueueStatus'
import { useOnboardingDims, useQueryParams } from '../../hooks'

const BaseHeader = ({ children }: { children?: ReactNode | undefined }) => {
  const { desktopMediaQuery } = useOnboardingDims()

  return (
    <Header
      height={70}
      styles={theme => ({
        root: {
          color: theme.white,
          backgroundColor: theme.black,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderWidth: 0,
          paddingLeft: theme.spacing.lg,
          paddingRight: theme.spacing.lg,
          [desktopMediaQuery]: {
            borderWidth: theme.other.sizes.border.md,
            borderColor: theme.colors.gray[9],
          },
        },
      })}
    >
      <Box
        sx={theme => ({
          color: theme.other.colors.actions[0],
          width: `calc(${theme.spacing.lg} * 3)`,
        })}
      >
        <MyOpheliaWordmark />
      </Box>
      {children}
    </Header>
  )
}

export const OnboardingHeader = ({ showCallMeNowStatus }: { showCallMeNowStatus?: boolean }) => {
  const params = useQueryParams()
  const isReferral = params.has(REFERRAL_PARAM)

  const { isDesktop } = useOnboardingDims()

  return (
    <BaseHeader>
      {isDesktop ? (
        <Group noWrap position='apart'>
          {showCallMeNowStatus && <CallMeNowQueueStatus />}
          {isReferral && <MoreOptions />}
          {isReferral && <Divider orientation='vertical' size='sm' />}
          <ContactSupportHeader />
        </Group>
      ) : (
        showCallMeNowStatus && <CallMeNowQueueStatus />
      )}
    </BaseHeader>
  )
}
