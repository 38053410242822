import {
  Divider,
  ExternalLinkText,
  HomeIcon,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useBanner,
  useLifecycle,
  useMonitoring,
} from '@shared/components'
import { dayjs } from '@shared/utils'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SMS_SUPPORT } from '../../common/components'
import { auth } from '../../common/firebase'
import * as Fullstory from '../../common/fullstory'
import { useAuth, useLDClient, usePortalDims } from '../../common/hooks'
import { useReferralPartner } from '../../common/hooks/use-partner'
import { logger } from '../../common/logger'
import { routes } from '../../common/routes'
import { sendPageEvent, sendTrackEvent } from '../../common/rudderstack'
import { AppointmentDataProps } from './AppointmentScheduled'
import { CareTransferIntakeTaskExpirationAlert } from './CareTransferIntakeTaskExpirationAlert'
import { StartTasksSection } from './StartTasksSection'

export const IntakeScheduled = ({ appointmentData }: AppointmentDataProps) => {
  const { currentUser, isAuthorized } = useAuth()
  const { showBanner } = useBanner()
  const { isPartnerRoute, partner, partnerPath } = useReferralPartner()
  const navigate = useNavigate()
  const { ldClient } = useLDClient()
  const monitoring = useMonitoring()
  const { isMobile } = usePortalDims()

  useLifecycle({
    onMount: () => {
      showBanner({ type: 'success', label: 'Intake appointment scheduled' })
      if (isAuthorized) {
        monitoring.trace.end({
          key: 'click-get-started-to-intake-visit-scheduled',
        })
      }
    },
  })

  const handleStartTasks = () => {
    sendTrackEvent('Pre-WC Start Tasks Button Clicked')
    navigate(routes.portal.index)
  }

  useLifecycle({
    onMount: () => {
      if (ldClient) {
        ldClient.track('Care Transfer Intake Scheduled')
      }
      if (partner) {
        sendPageEvent(`Care Transfer Intake Scheduled For ${partner.name} Referral`)
        Fullstory.event('Care Transfer Intake Scheduled For Partner Referral', {
          partnerName: `${partner.name}`,
        })
      }
      logger.info('Care Transfer Intake Scheduled', { tags: { workflow: 'onboarding' } })
    },
  })

  const appointmentDate = dayjs(appointmentData.datetime)

  if (appointmentData.type === 'Initial Visit' && appointmentData.confirmed) {
    if (isAuthorized) {
      return (
        <Stack>
          <TitleTwo>
            {`${currentUser?.data?.personalData
              .firstName}, your intake visit was scheduled for ${dayjs(appointmentDate).format(
              'h:mma z',
            )} on ${dayjs(appointmentDate).format('dddd MMM D YYYY')}${
              appointmentData.employeeName ? ` with ${appointmentData.employeeName}` : ''
            }`}
          </TitleTwo>
          <Divider my='md' />
          <StartTasksSection isMobile={isMobile} handleStartTasks={handleStartTasks} />
          {appointmentData.patientTaskExpirationDatetime && (
            <CareTransferIntakeTaskExpirationAlert
              expirationDatetime={appointmentData.patientTaskExpirationDatetime}
            />
          )}
        </Stack>
      )
    }

    return (
      <Stack>
        <TitleTwo>{`Your intake visit was scheduled for ${dayjs(appointmentDate).format(
          'h:mma z',
        )} on ${dayjs(appointmentDate).format('dddd MMM D YYYY')}${
          appointmentData.employeeName ? ` with ${appointmentData.employeeName}` : ''
        }`}</TitleTwo>
        <Divider my='md' />
        <StartTasksSection isMobile={isMobile} handleStartTasks={handleStartTasks} />
        {appointmentData.patientTaskExpirationDatetime && (
          <CareTransferIntakeTaskExpirationAlert
            expirationDatetime={appointmentData.patientTaskExpirationDatetime}
          />
        )}
      </Stack>
    )
  }

  return (
    <Stack test-id='page:intake-visit-scheduled'>
      <TitleTwo>
        {`Your intake visit was scheduled for ${dayjs(appointmentDate).format(
          'h:mma z',
        )} on ${dayjs(appointmentDate).format('dddd MMM D YYYY')}${
          appointmentData.type === 'Initial Visit' && appointmentData.employeeName
            ? ` with ${appointmentData.employeeName}`
            : ''
        }`}
      </TitleTwo>
      <Text>
        We will send you a text, please make sure to confirm to keep your appointment. You will
        receive a Zoom link an hour before the visit. If you&apos;d like to reschedule, cancel, or
        have any questions, text{' '}
        <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>(215) 585-2144</ExternalLinkText>.
      </Text>
      <Divider my='md' />
      {isPartnerRoute ? (
        <TertiaryButton
          leftIcon={<HomeIcon />}
          onClick={async () => {
            if (isAuthorized) {
              await auth.signOut()
            }

            navigate(`${routes.welcome.index}${partnerPath}`)
          }}
        >
          Start a new referral
        </TertiaryButton>
      ) : (
        <>
          <StartTasksSection isMobile={isMobile} handleStartTasks={handleStartTasks} />
          {appointmentData.type === 'Initial Visit' &&
            appointmentData.patientTaskExpirationDatetime && (
              <CareTransferIntakeTaskExpirationAlert
                expirationDatetime={appointmentData.patientTaskExpirationDatetime}
              />
            )}
        </>
      )}
    </Stack>
  )
}
