import { useBanner, useLifecycle } from '@shared/components'
import { PromptContentBanner as PromptContentBannerType } from '@shared/types'

type PromptContentBannerProps = {
  data: PromptContentBannerType
}

export const PromptContentBanner = ({ data: { type, label } }: PromptContentBannerProps) => {
  const { showBanner } = useBanner()
  useLifecycle({ onMount: () => showBanner({ type, label }) })
  return null
}
