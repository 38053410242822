import { Box, PrimaryButton, Space, Stack, Text, VideoIcon } from '@shared/components'
import { dayjs } from '@shared/utils'
import React, { useState } from 'react'
import { ContactSupport, FallbackContent, Skeletons } from '../../../common/components'
import * as FullStory from '../../../common/fullstory'
import { useAuth } from '../../../common/hooks'
import { sendTrackEvent } from '../../../common/rudderstack'
import { TaskHeader } from './TaskHeader'
import { ZoomForm } from './ZoomForm'
import { usePatientFormTask } from './use-patient-task'

export const ZoomTask = () => {
  const [zoomLinkClicked, setZoomLinkClicked] = useState<boolean>(false)

  // This task has two steps (test-zoom and is-zoom-working) but they appear on the same page
  const { submit, status, isSaving, payload } = usePatientFormTask({
    totalSteps: 2,
    type: 'zoom',
    breadcrumb: 'Zoom',
  })

  const { currentUser } = useAuth()

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  const handleClickTestZoom = async () => {
    sendTrackEvent('Test Zoom Clicked')
    FullStory.event('Test Zoom Clicked')

    const payload = {
      zoomLastTested: dayjs().toISOString(),
    }

    await submit('test-zoom', payload)

    setZoomLinkClicked(true)
  }

  return (
    <Box test-id='page:zoom-task'>
      <Stack spacing='md'>
        <TaskHeader title="Let's make sure you're ready for your intake visit">
          <Text>
            Click the button below to test Zoom on your device. You will be navigated to a new tab
            and prompted to join a test meeting. Please return to this screen when you&apos;re done.
          </Text>
        </TaskHeader>
        <PrimaryButton
          component='a'
          target='_blank'
          test-id='button:test-zoom'
          href='https://zoom.us/test'
          onClick={() => handleClickTestZoom()}
          fullWidth
          leftIcon={<VideoIcon />}
        >
          Test Zoom
        </PrimaryButton>
        <Space />
        {currentUser?.data?.account?.zoomLastTested && (
          <ZoomForm
            initialValues={payload}
            submit={submit}
            saving={isSaving}
            zoomLinkClicked={zoomLinkClicked}
          />
        )}
        <Space />
        <ContactSupport />
      </Stack>
    </Box>
  )
}
