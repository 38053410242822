import { useClipboard } from '@mantine/hooks'
import {
  Card,
  Center,
  ClipboardIcon,
  Group,
  Image,
  PrimaryButton,
  ShareIcon,
  Stack,
  Text,
  TitleTwo,
  useMantineTheme,
} from '@shared/components'
import { MilestoneContentType } from '@shared/types'
import { toTime } from '@shared/utils'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { patientApi } from '../../../../common/api'
import { Config } from '../../../../common/config'
import * as FullStory from '../../../../common/fullstory'
import { useAuth, usePortalDims } from '../../../../common/hooks'
import { logger } from '../../../../common/logger'
import { analytics, sendTrackEvent } from '../../../../common/rudderstack'

const getTextContent = (milestoneName: MilestoneContentType['value']) => {
  switch (milestoneName) {
    case '3_months_in_treatment':
      return 'Guess what?! I’ve been in care with Ophelia for 3 months now. Learn more about my program: '
    case '6_months_in_treatment':
      return 'Still grooving! Learn more about my program: '
    case '12_months_in_treatment':
      return 'Can you believe it’s been a whole year?! Check out my program - it works! '
  }
}

const sendEvents = (milestoneName: MilestoneContentType['value']) => {
  const data: analytics.apiObject = {}
  data.milestoneName = milestoneName
  sendTrackEvent('Milestone Shared', data)
  FullStory.event('Milestone Shared')
}

const ShareMilestoneButton = ({
  handleShare,
  handleCopy,
  shareLoading,
  copyLoading,
  linkCopied,
}: {
  handleShare: () => Promise<void>
  handleCopy: () => Promise<void>
  shareLoading: boolean
  copyLoading: boolean
  linkCopied: boolean
}) => {
  const canShare = navigator.share !== undefined
  const { isMobile } = usePortalDims()
  if (canShare) {
    return (
      <PrimaryButton
        fullWidth={isMobile}
        rightIcon={<ShareIcon />}
        onClick={() => handleShare()}
        loading={shareLoading}
      >
        Share milestone
      </PrimaryButton>
    )
  }
  return (
    <PrimaryButton
      fullWidth={isMobile}
      rightIcon={<ClipboardIcon />}
      onClick={() => handleCopy()}
      loading={copyLoading}
    >
      {linkCopied ? 'Copied!' : 'Copy your share link'}
    </PrimaryButton>
  )
}

export const LatestMilestoneCard = ({ milestone }: { milestone: MilestoneContentType }) => {
  const clipboard = useClipboard({ timeout: toTime('5 sec').ms() })

  const {
    other: { colors, sizes },
  } = useMantineTheme()
  const { currentUser } = useAuth()
  const currentUserData = currentUser?.data
  const [shareLoading, setShareLoading] = useState(false)
  const [copyLoading, setCopyLoading] = useState(false)
  const [milestoneLink, setMilestoneLink] = useState<{
    linkUrl: string
  } | null>()

  const createShareMilestoneLinkMutation = useMutation(
    patientApi.getMutation('POST /milestone/link'),
    {
      onSuccess: data => setMilestoneLink(data),
    },
  )

  const createShareMilestoneLink = async () => {
    if (milestoneLink) {
      return milestoneLink
    }

    return createShareMilestoneLinkMutation.mutateAsync({
      data: {
        sharedByPatientId: currentUserData?.oid ?? '',
        milestoneName: milestone.value,
      },
    })
  }

  const handleShare = async () => {
    setShareLoading(true)
    const { linkUrl } = await createShareMilestoneLink()
    setShareLoading(false)

    const shareTextContent = [getTextContent, linkUrl].join('')

    try {
      void navigator.share({
        text: shareTextContent,
      })
    } catch (error) {
      logger.error(`Error sharing referral link: ${error}`)
    }

    sendEvents(milestone.value)
  }

  const handleCopy = async () => {
    setCopyLoading(true)
    const { linkUrl } = await createShareMilestoneLink()
    setCopyLoading(false)

    /*
     * iOS Safari does not allow for clipboard writes as a result of an asynchronous operation
     * it considers this as potentially malicious because clipboard writes should be the result
     * of user interaction and not asynchronous
     *
     * Since we don't want to generate a share link until the user has finished configuring it,
     * We keep it asynchronous, using this setTimeout workaround (which also works on any browser)
     * https://stackoverflow.com/a/77517883
     *
     * Note: There is also the navigator.clipboard.write() API which accepts a ClipboardItem, which
     * is allowed to be asynchronous on iOS. However, ClipboardItem only accepts blob MIME types, not
     * plaintext.
     */
    setTimeout(() => clipboard.copy(linkUrl), 0)

    sendEvents(milestone.value)
  }

  return (
    <Card
      key={milestone.value}
      style={{
        overflow: 'scroll',
        backgroundColor: colors.background[1],
        borderWidth: sizes.border.md,
        borderColor: colors.background[1],
        borderStyle: 'solid',
      }}
    >
      <Stack align='center' justify='center'>
        <Group position='left'>
          <Text bold>Latest milestone</Text>
        </Group>
        <Stack
          align='center'
          justify='center'
          spacing='md'
          p='sm'
          sx={{ textAlign: 'center', backgroundColor: colors.background[0] }}
        >
          <Center p='md' w='70%'>
            <Image src={`${Config.ACHIEVEMENTS_BASE_URL}${milestone.imageUrl}`} />
          </Center>
          <TitleTwo>{milestone.label}</TitleTwo>
          <Text>{milestone.description}</Text>
          <ShareMilestoneButton
            handleShare={handleShare}
            handleCopy={handleCopy}
            shareLoading={shareLoading}
            copyLoading={copyLoading}
            linkCopied={clipboard.copied}
          />
        </Stack>
      </Stack>
    </Card>
  )
}
