import { z } from 'zod'
import { ApiSchemas } from '../../utils'

const PostArchivePatientChartReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
    body: z.object({
      toPatientId: z.string(),
      type: z.enum(['quick_merge', 'manual_archive']),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const DataMigrationRouteSchemas = {
  'POST /patients/:patientId/archive': PostArchivePatientChartReqRes,
} satisfies ApiSchemas
