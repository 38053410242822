import { DatabaseMetadata, MMMMDoYYYYhmma } from '../../'
import { VisitNoteContent } from '../../api'
import {
  ConsultationVisitNoteContent,
  FollowupBHCMVisitNoteContent,
  InductionNoteContent,
  InitialBHCMVisitNoteContent,
  LegacyVisitNoteContent,
  PeerCheckinVisitNoteContent,
  ReenrollmentCallVisitNoteContent,
  UDSVisitNoteContent,
} from './content'

export * from './content'

export type VisitNoteType =
  | 'legacy'
  | 'induction'
  | 'consultation'
  | 'reenrollment'
  | 'peercheckin'
  | 'uds'
  | 'bhcm'
  | 'initialBhcm'
  | 'maintenance'
  | 'blank'

export type VisitNoteModel<T extends VisitNoteType = VisitNoteType> = {
  userId: string
  createdAt: string
  visitId: number
  updatedAt?: string
  doseSpotPrescriptionIds?: number[]
  type: T
  content: T extends 'legacy'
    ? LegacyVisitNoteContent
    : T extends 'induction'
    ? InductionNoteContent
    : T extends 'consultation'
    ? ConsultationVisitNoteContent
    : T extends 'reenrollment'
    ? ReenrollmentCallVisitNoteContent
    : T extends 'peercheckin'
    ? PeerCheckinVisitNoteContent
    : T extends 'uds'
    ? UDSVisitNoteContent
    : T extends 'bhcm'
    ? FollowupBHCMVisitNoteContent
    : T extends 'initialBhcm'
    ? InitialBHCMVisitNoteContent
    : VisitNoteContent
}

export type VisitNote = VisitNoteModel & DatabaseMetadata

export type VisitNoteVersionHistoryModel = Omit<VisitNoteModel, 'updatedAt'>

export type VisitNoteVersionHistory = VisitNoteVersionHistoryModel & DatabaseMetadata

export type VisitNoteAddendumModel = {
  content: string
  locked_by_calendar_id: number
  locked_by_user_id: string
  locked_by_name: string
  locked_at: MMMMDoYYYYhmma
}

export type VisitNoteAddendum = VisitNoteAddendumModel & DatabaseMetadata

/**
 * This value is used to indicate that a patient is not entering induction.
 * This string has historically been the only way to indicate whether a patient
 * is entering induction or not. Moreover, this string has changed over time.
 * It used to be "Patient Not Entering Induction" but was changed to "Patient not entering induction"
 * therefore expect both values to be present in the database unfortunately.
 */
export const NOT_ENTERING_INDUCTION_VALUE = 'Patient not entering induction' as const

export const isIntakeVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'induction'> =>
  vn.type === 'induction'

export const isExplicityNotInductionVisit = (vn: VisitNoteModel) =>
  isIntakeVisit(vn) && vn.content?.induction_email === NOT_ENTERING_INDUCTION_VALUE

export const isInductionVisit = (vn: VisitNoteModel) =>
  isIntakeVisit(vn) &&
  vn.content?.induction_email &&
  vn.content.induction_email.toLowerCase() !== NOT_ENTERING_INDUCTION_VALUE.toLowerCase()
export const isWelcomeCall = (vn: VisitNoteModel): vn is VisitNoteModel<'consultation'> =>
  vn.type === 'consultation'
export const isReenrollmentCall = (vn: VisitNoteModel): vn is VisitNoteModel<'reenrollment'> =>
  vn.type === 'reenrollment'
export const isFollowUpVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'maintenance'> =>
  vn.type === 'maintenance'
export const isCheckInCall = (vn: VisitNoteModel): vn is VisitNoteModel<'blank'> =>
  vn.type === 'blank'
export const isUDSVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'uds'> => vn.type === 'uds'
export const isFollowupWellnessVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'bhcm'> =>
  vn.type === 'bhcm'
export const isInitialWellnessVisit = (vn: VisitNoteModel): vn is VisitNoteModel<'initialBhcm'> =>
  vn.type === 'initialBhcm'

export * from './substanceUse'
