import { toTime } from '@shared/utils'
import { analytics } from '../rudderstack'
import { usePortalQuery } from './use-portal-query'

export const useCallMeNowQueueStatus = () => {
  const consultationCallQueueQuery = usePortalQuery(
    'GET /consultation/queue/status',
    {
      query: {
        anonymousId: analytics.getAnonymousId(),
      },
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: toTime('10 sec').ms(),
      refetchIntervalInBackground: false,
      keepPreviousData: true,
      refetchOnReconnect: true,
      cacheTime: 0,
      staleTime: 0,
    },
  )

  const consultationCallQueueAvailabilityStatus = consultationCallQueueQuery.data?.status

  const isQueueStatusFetched =
    !consultationCallQueueQuery.isError && !consultationCallQueueQuery.isLoading

  // Open = Queue is within open hours, but may be at capacity
  const isQueueOpen =
    isQueueStatusFetched &&
    ['open', 'full', 'closing soon'].includes(consultationCallQueueAvailabilityStatus || '')

  // Available = Queue can currently be entered by the user
  const isQueueAvailable =
    isQueueStatusFetched &&
    ['open', 'closing soon'].includes(consultationCallQueueAvailabilityStatus || '')

  return {
    isQueueOpen,
    isQueueAvailable,
    queueStatus: consultationCallQueueAvailabilityStatus,
    isLoading: consultationCallQueueQuery.isLoading,
  }
}
