import {
  PatientReleaseOfInformation,
  PatientReleaseOfInformationModel,
  RequireAtLeastOne,
} from '@shared/types'
import { BaseApi } from '@shared/utils'

export class ReleaseOfInformationApi extends BaseApi {
  GET_ALL_RELEASES_OF_INFORMATION_QUERY_KEY = 'releaseOfInformationApi.getAllReleasesOfInformation'
  GET_RELEASE_OF_INFORMATION_QUERY_KEY = 'releaseOfInformationApi.getReleaseOfInformation'

  createReleaseOfInformation = (roi: PatientReleaseOfInformationModel) =>
    this.json<{ id: string }, PatientReleaseOfInformationModel>({
      method: 'POST',
      url: '/release-of-information',
      data: roi,
    })

  getAllReleasesOfInformation = () =>
    this.json<PatientReleaseOfInformation[], null>({
      method: 'GET',
      url: '/release-of-information',
    })

  getReleaseOfInformation = (id: string) =>
    this.json<PatientReleaseOfInformation, null>({
      method: 'GET',
      url: `/release-of-information/${id}`,
    })

  updateReleaseOfInformation = ({
    id,
    data,
  }: {
    id: string
    data: RequireAtLeastOne<PatientReleaseOfInformationModel>
  }): Promise<void> => this.json({ method: 'PUT', url: `/release-of-information/${id}`, data })
}
