import { Box, Progress, Text } from '@shared/components'
import React from 'react'

export const SessionProgress = ({
  current,
  total,
  isLoading = false,
  showProgressNumbers = true,
}: {
  current: number
  total: number
  isLoading?: boolean
  showProgressNumbers?: boolean
}) => {
  /* eslint-disable-next-line no-magic-numbers */
  const percentProgress = (current / total) * 100
  const stepProgress = `${current} of ${total}`

  return (
    <Box sx={{ display: current <= 0 ? 'none' : 'flex', alignItems: 'center' }}>
      <Box sx={{ flex: 1 }}>
        <Progress value={percentProgress} animate={isLoading} />
      </Box>
      {showProgressNumbers && (
        <Box ml='lg'>
          <Text bold>{stepProgress}</Text>
        </Box>
      )}
    </Box>
  )
}
