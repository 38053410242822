import { PatientApi } from '@shared/types'
import { useQueryClient } from 'react-query'
import { patientApi } from '../api'

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient()

  return <OpheliaRoute extends keyof PatientApi>(
    route: OpheliaRoute,
    // Can pass the config in order to get a more granular query key to invalidate
    config?: Partial<PatientApi[OpheliaRoute]['req']>,
  ) => {
    const [queryKey] = patientApi.getQuery(route, config)
    return queryClient.invalidateQueries({
      queryKey,
    })
  }
}
