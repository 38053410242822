import { useToggle } from '@mantine/hooks'
import {
  Accordion,
  Divider,
  Group,
  LogOutIcon,
  Modal,
  PrimaryButton,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { usePortalDims } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { sendPageEvent } from '../../../common/rudderstack'
import { AddressSection } from './AddressSection'
import { ContactSection } from './ContactSection'
import { EmergencyContactSection } from './EmergencyContactSection'
import { InsuranceCardSection } from './InsuranceCardSection'
import { PersonalSection } from './PersonalSection'
import { TimeZoneSection } from './TimeZoneSection'

export const Account = () => {
  const { isMobile } = usePortalDims()
  const [section, setSection] = useState<string | null>(null)
  const [opened, setOpened] = useState<string | null>(null)
  const [editing, toggleEditing] = useToggle([false, true])

  const discard = () => {
    toggleEditing(false)
    setSection(opened)
    setOpened(null)
  }

  const cancelDiscard = () => {
    setOpened(null)
  }

  useLifecycle({
    onMount: () => sendPageEvent('Account'),
  })

  return (
    <Stack test-id='page:account' spacing='lg'>
      <Group position={isMobile ? 'apart' : 'left'}>
        <TitleTwo>Account</TitleTwo>
        <TertiaryButton rightIcon={<LogOutIcon />} component={Link} to={routes.signout.index}>
          Sign out
        </TertiaryButton>
      </Group>
      <ContactSection />
      <Divider />
      <Accordion
        value={section}
        onChange={value => {
          if (editing) {
            setOpened(value)
          } else {
            setSection(value)
          }
        }}
      >
        <Accordion.Item value='personal'>
          <Accordion.Control>
            <Text bold transform='uppercase'>
              Personal information
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <PersonalSection editing={editing} toggleEditing={toggleEditing} />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value='address'>
          <Accordion.Control>
            <Text bold transform='uppercase'>
              Home & shipping address
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <AddressSection editing={editing} toggleEditing={toggleEditing} />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value='time-zone'>
          <Accordion.Control>
            <Text bold transform='uppercase'>
              Time zone
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <TimeZoneSection editing={editing} toggleEditing={toggleEditing} />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value='emergency-contact'>
          <Accordion.Control>
            <Text bold transform='uppercase'>
              Emergency contact
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <EmergencyContactSection editing={editing} toggleEditing={toggleEditing} />
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value='insurance'>
          <InsuranceCardSection
            editing={editing}
            toggleEditing={toggleEditing}
            openSection={() => setSection('insurance')}
          />
        </Accordion.Item>
      </Accordion>
      <Divider />
      <Modal
        title='Discard unsaved changes?'
        onClose={cancelDiscard}
        opened={Boolean(opened)}
        footer={
          isMobile ? (
            <Stack>
              <SecondaryButton fullWidth onClick={cancelDiscard}>
                Cancel
              </SecondaryButton>
              <PrimaryButton fullWidth onClick={discard}>
                Discard
              </PrimaryButton>
            </Stack>
          ) : (
            <Group>
              <SecondaryButton onClick={cancelDiscard}>Cancel</SecondaryButton>
              <PrimaryButton onClick={discard}>Discard</PrimaryButton>
            </Group>
          )
        }
      >
        <Text>{`If you leave now, your progress won't be saved`}</Text>
      </Modal>
    </Stack>
  )
}
