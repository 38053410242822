import { useLifecycle } from '@shared/components'
import { usePortalMutation } from './use-portal-mutation'

export const useStripeClientSecret = () => {
  const setupIntentMutation = usePortalMutation('POST /payment/setup-intent')

  // Creates a stripe setup intent on mount. For more info, see https://stripe.com/docs/payments/save-and-reuse?platform=web&ui=elements#retrieve-the-client-secret
  useLifecycle({
    onMount: () => {
      setupIntentMutation.mutate({})
    },
  })

  return setupIntentMutation
}
