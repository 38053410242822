// eslint-disable-next-line no-restricted-imports
import { Box, DefaultProps, Group, Stack, useMantineTheme } from '@mantine/core'
import React, { ReactNode } from 'react'
import { ChevronRightIcon, ConcentricCirclesIcon, LockIcon } from './icons'
import { Text } from './typography'

const TaskCardContents = ({
  title,
  leftIcon,
  footer,
  disabled,
}: {
  title: string
  leftIcon?: React.ReactNode
  footer: string | undefined
  disabled: boolean
} & DefaultProps) => {
  return (
    <Stack
      justify='space-between'
      sx={({ radius, other: { sizes, colors } }) => ({
        flexGrow: 1,
        borderRadius: radius.xs,
        padding: sizes.padding.md,
        backgroundColor: disabled ? colors.background[3] : colors.actions[0],
        color: disabled ? colors.text[2] : colors.background[0],
      })}
    >
      <Group spacing='sm' noWrap align='flex-start'>
        {disabled ? <LockIcon /> : leftIcon}
        <Box style={{ flexGrow: 1 }}>
          <Text color='inherit' bold>
            {title}
          </Text>
        </Box>
        {!disabled && <ChevronRightIcon />}
      </Group>
      {footer && (
        <Text size='xs' color='inherit' bold>
          {footer}
        </Text>
      )}
    </Stack>
  )
}

type TaskCardProps = {
  title: string
  icon?: ReactNode | undefined
  footer?: string | undefined
  fillWidth?: boolean
  requiredMessage?: string | undefined
  disabled?: boolean
}

export const TaskCard = ({
  title,
  icon,
  footer,
  fillWidth = false,
  requiredMessage,
  disabled = false,
}: TaskCardProps) => {
  const theme = useMantineTheme()

  return (
    <Stack
      display='flex'
      h={fillWidth ? 'unset' : 200}
      mih={fillWidth ? `calc(${theme.spacing.lg} * 5)` : 'unset'}
      bg={theme.other.colors.background[0]}
      spacing={0}
      justify='space-between'
      style={{
        borderRadius: theme.radius.sm,
        borderColor: disabled ? theme.other.colors.background[2] : theme.other.colors.background[0],
        borderWidth: theme.other.sizes.border.md,
        borderStyle: 'solid',
        aspectRatio: fillWidth ? 'unset' : '1/1',
        flexGrow: fillWidth ? 1 : 0,
      }}
    >
      <TaskCardContents title={title} leftIcon={icon} footer={footer} disabled={disabled} />
      {requiredMessage && (
        <Group position='center' spacing='xs' p={theme.other.sizes.padding.md}>
          <ConcentricCirclesIcon size='sm' />
          <Text size='xs'>{requiredMessage}</Text>
        </Group>
      )}
    </Stack>
  )
}
