import { MyLunaApi, OpheliaHttpError } from '@shared/types'
import { AxiosError } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { myApi } from '../api'

export const useMyQuery = <OpheliaRoute extends keyof MyLunaApi>(
  route: OpheliaRoute,
  config?: Partial<MyLunaApi[OpheliaRoute]['req']>,
  options?: Omit<
    UseQueryOptions<MyLunaApi[OpheliaRoute]['res'], AxiosError<OpheliaHttpError>>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<MyLunaApi[OpheliaRoute]['res'], AxiosError<OpheliaHttpError>>(
    ...myApi.getQuery(route, config),
    options,
  )
}
