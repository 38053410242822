import { Stack, TitleTwo, useBanner, useLifecycle } from '@shared/components'
import React from 'react'
import { useLocation } from 'react-router-dom'
import * as Fullstory from '../../common/fullstory'
import { useLDClient } from '../../common/hooks'
import { logger } from '../../common/logger'

export type SkipOnboardingLocationStateType = {
  firstName: string
}
export const SkipOnboardingWelcomeCallScheduled = () => {
  const { showBanner } = useBanner()
  const { ldClient } = useLDClient()
  const location = useLocation()
  const locationState = location.state as SkipOnboardingLocationStateType
  const firstName = locationState?.firstName

  useLifecycle({
    onMount: () => {
      Fullstory.event('Onboarding Skipped To Book Instant Welcome Call')
      showBanner({ type: 'success', label: 'Welcome call confirmed' })
      if (ldClient) {
        ldClient.track('Welcome Call Scheduled')
      }
      logger.info('Welcome Call Scheduled', { tags: { workflow: 'onboarding' } })
    },
  })

  if (firstName) {
    return (
      <Stack>
        <TitleTwo>
          {firstName}, expect a call within the next 5 minutes from (215) 585-2144
        </TitleTwo>
      </Stack>
    )
  }

  return (
    <Stack>
      <TitleTwo>Expect a call within the next 5 minutes from (215) 585-2144</TitleTwo>
    </Stack>
  )
}
