import { Box } from '@shared/components'
import { toTime } from '@shared/utils'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { useMyQuery } from '../../../common/hooks'
import { InsuranceCardUploadStep } from './InsuranceCardUploadStep'
import { InsuranceDetailsStep } from './InsuranceDetailsStep'
import { TaskHeader } from './TaskHeader'
import { usePatientFormTask } from './use-patient-task'

export const InsuranceTask = () => {
  const { payload, status, currentStep, submit, isSaving } = usePatientFormTask({
    type: 'insurance',
    totalSteps: 2,
    breadcrumb: 'Insurance info',
  })

  const insurancePayers = useMyQuery('GET /insurance-payers', {
    staleTime: toTime('5 min').ms(),
  })
  if (status === 'loading' || insurancePayers.status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || insurancePayers.status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }
  return (
    <Box test-id='page:insurance-task'>
      <TaskHeader
        title='We need a bit more info about your health insurance'
        current={currentStep}
        total={2}
      />

      {currentStep === 1 && (
        <InsuranceDetailsStep
          insurancePayers={insurancePayers.data?.data}
          initialValues={payload}
          submit={submit}
          saving={isSaving}
        />
      )}
      {currentStep === 2 && (
        <InsuranceCardUploadStep initialValues={payload} submit={submit} saving={isSaving} />
      )}
    </Box>
  )
}
