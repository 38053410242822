import dayjs from 'dayjs'

export const getNumWeeksFromNow = (date?: string) => {
  if (!date) {
    return 0
  }

  /*
   * .week() gets the week number of the year, which is helpful because it allows us to determine if a date is
   * in this calendar week or a future/past week. For example, if today is Friday, and the intendedDay is next
   * Monday, the difference in weeks will be 1
   *
   * We also need to subtract 1 day to each date to account for dayjs().week() starting on Sunday
   */
  const currentWeekNumber = dayjs().subtract(1, 'day').week()
  const intendedWeekNumber = dayjs(date).subtract(1, 'day').week()
  const difference = intendedWeekNumber - currentWeekNumber

  return difference
}
