import { Accordion, Group, Text } from '@shared/components'
import { AppointmentInfo, getVisitTypeName } from '@shared/types'
import { dayjs } from '@shared/utils'
import React, { useState } from 'react'
import { Card } from '../../../common/components'
import { EmptyVisitCard, VisitCard } from './VisitCard'
import { VisitInfo } from './VisitInfo'

export const VisitsCard = ({ visits }: { visits: AppointmentInfo[] }) => {
  const [selectedVisit, setSelectedVisit] = useState<string | null>(null)

  if (!visits.length) {
    return <EmptyVisitCard title='Upcoming visits' />
  }

  if (visits.length === 1) {
    return <VisitCard {...visits[0]!} />
  }

  return (
    <Card title='Upcoming visits'>
      <Accordion value={selectedVisit} onChange={setSelectedVisit}>
        {visits.map(visit => {
          const visitIdStr = visit.id.toString()
          const isSelected = selectedVisit === visitIdStr
          return (
            <Accordion.Item value={visitIdStr} key={visit.id}>
              <Accordion.Control>
                <Group position='apart'>
                  <Text bold>{getVisitTypeName(visit.visitType)}</Text>
                  {!isSelected && (
                    <Text size='xs' color={({ actions }) => actions[3]}>
                      {dayjs(visit.datetime).format('MMM D YYYY').toUpperCase()}
                    </Text>
                  )}
                </Group>
              </Accordion.Control>
              <Accordion.Panel>
                <VisitInfo {...visit} />
              </Accordion.Panel>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </Card>
  )
}
