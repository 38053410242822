import { z } from 'zod'
import { DatabaseMetadata } from '.'

export const NON_TASK_FILE_CATEGORIES = [
  'ID_FRONT',
  'ID_BACK',
  'INSURANCE_FRONT',
  'INSURANCE_BACK',
  'INVOICE_PDF',
  'SECONDARY_INSURANCE_FRONT',
  'SECONDARY_INSURANCE_BACK',
  'PRIOR_AUTHORIZATION',
  'FORM_SUBMISSION',
  'PHYSICAL_EXAM',
  'ROI',
  'OTHER',
] as const

export const FILE_CATEGORIES = [...NON_TASK_FILE_CATEGORIES, 'TASK_ATTACHMENT'] as const

export type FileCategory = (typeof FILE_CATEGORIES)[number]
export const PRIMARY_INSURANCE_FILE_CATEGORIES: FileCategory[] = [
  'INSURANCE_FRONT',
  'INSURANCE_BACK',
]

export const SECONDARY_INSURANCE_FILE_CATEGORIES: FileCategory[] = [
  'SECONDARY_INSURANCE_FRONT',
  'SECONDARY_INSURANCE_BACK',
]

export const FILE_CATEGORIES_MAP = {
  ID_FRONT: 'ID Front',
  ID_BACK: 'ID Back',
  INSURANCE_FRONT: 'Insurance Front',
  INSURANCE_BACK: 'Insurance Back',
  SECONDARY_INSURANCE_FRONT: 'Secondary Insurance Front',
  SECONDARY_INSURANCE_BACK: 'Secondary Insurance Back',
  PRIOR_AUTHORIZATION: 'Prior Authorization',
  FORM_SUBMISSION: 'Form Submission',
  PHYSICAL_EXAM: 'Physical Exam',
  ROI: 'Release of Information',
  TASK_ATTACHMENT: 'Task Attachment',
  OTHER: 'Other',
} as Record<FileCategory, string>

export const PatientFileValidator = z.object({
  name: z.string(),
  type: z.string(),
  createdBy: z.string().optional(),
  createdAt: z.string().datetime().optional(),
})
type BasePatientFile = z.infer<typeof PatientFileValidator>

/*
 * Zod enforcement of the below gets quite complicated / not possible to
 * do fully accurately, so we switch to TS to keep the type fully accurate
 */
export type PatientFileCategoryDetails<T extends FileCategory = FileCategory> =
  T extends 'TASK_ATTACHMENT'
    ? {
        category: 'TASK_ATTACHMENT'
        details: {
          taskId: string
        }
      }
    : {
        /** some older files do not have category */
        category?: T
      }

export type PatientFileModel<T extends FileCategory = FileCategory> = BasePatientFile &
  PatientFileCategoryDetails<T>

export type PatientFile<T extends FileCategory = FileCategory> = PatientFileModel<T> &
  DatabaseMetadata
