import { Appointment, DatabaseMetadata, LevelOfCareStatus, Patient } from '.'

export const CARE_PATHWAY_RISK_FACTORS = [
  {
    label: 'Medication/dose adjustment',
    value: 'medication_or_dose_adjustment',
  },
  {
    label: 'Mental health exacerbation',
    value: 'mental_health_exacerbation',
  },
  {
    label: 'Cravings or use of illicit opioids',
    value: 'cravings_or_use_of_illicit_opioids',
  },
  {
    label: 'Other substance use',
    value: 'other_substance_use',
  },
  {
    label: 'Other',
    value: 'other',
  },
] as const

export type CarePathwayRiskFactor = (typeof CARE_PATHWAY_RISK_FACTORS)[number]['value']

export type CarePathwayOption = 'induction' | 'intermediate' | 'stable' | 'custom'

export type CarePathwayModel = {
  patientId: Patient['oid']
  appointmentId: Appointment['oid']
  selectedCarePathway: CarePathwayOption
  riskFactors: CarePathwayRiskFactor[]
  riskFactorsFreeText: string
  /**
   * The below fields were added after the initial release of the care pathways feature.
   * Therefore, it is possible that some care pathways were created without this field.
   */
  createdBy?: 'clinician' | 'system'
  selectedLevelOfCare?: LevelOfCareStatus
}

export type CarePathway = CarePathwayModel & DatabaseMetadata
