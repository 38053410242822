import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { HDYHAUForm } from './HDYHAUForm'
import { TaskHeader } from './TaskHeader'
import { usePatientFormTask } from './use-patient-task'

export const HDYHAUTask = () => {
  const { payload, status, isSaving, submit } = usePatientFormTask({
    type: 'how-did-you-hear-about-us',
    breadcrumb: 'How did you hear about us?',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <>
      <TaskHeader title='How did you hear about us?' />
      <HDYHAUForm initialValues={payload} saving={isSaving} submit={submit} />
    </>
  )
}
