import React, { Suspense, lazy, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import {
  Fallback,
  OnboardingShell,
  PortalAuth,
  PortalShell,
  ReconnectAuth,
  ResetPasswordAuth,
  Skeletons,
} from './common/components'
import { cio } from './common/customerio'
import { ErrorBoundary } from './common/errorBoundary'
import { routes } from './common/routes'

import { useLifecycle } from '@shared/components'
import { AccountRecoveryApp } from './accountRecovery'
import { VISITED_MY_OPHELIA, sendIdentifyEvent } from './common/rudderstack'
import { ConfirmationApp } from './confirmation'
import { DropInClinicApp } from './dropInClinic'
import { EnterCallMeNowApp } from './enterCallMeNow'
import { InsuranceCoverageApp } from './insuranceCoverage'
import { NotReadyApp } from './notReady'
import { OnboardingApp } from './onboarding'
import { PortalApp } from './portal'
import { ReconnectApp } from './reconnect'
import { ReferralsApp } from './referrals'
import { ReferralsWithPatientApp } from './referralsWithPatient'
import { RescheduleApp } from './reschedule'
import { ResetPasswordApp } from './resetPassword'
import { ScheduleApp } from './schedule'
import { ScheduledApp } from './scheduled'
import { SignOutApp } from './signout'
import { WelcomeApp } from './welcome'
import { WellnessCheckApp } from './wellnessCheck'
import { ZoomRedirectApp } from './zoomRedirect'

const CostAnalysisApp = lazy(() => import('./costAnalysis'))

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _cio: any
    FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined
  }
}

export const App = () => {
  const url = useLocation()

  useLifecycle({
    onMount: () => {
      /*
       * Rudderstack event to track if at any point the user has visited my.ophelia.com.
       * This is used to ensure that any prospective patients are permanently opted out of analytics marketing
       */
      sendIdentifyEvent({ data: { [VISITED_MY_OPHELIA]: true } })
    },
  })

  useEffect(() => {
    // This lets customer.io know which page the user has visited. Used for in-app messaging.
    cio.page()
  }, [url])

  return (
    <Routes>
      <Route
        path={`${routes.onboarding.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='onboarding' />}>
            <OnboardingShell showCallMeNowStatus>
              <OnboardingApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.portal.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='portal' />}>
            <PortalShell>
              <PortalAuth>
                <PortalApp />
              </PortalAuth>
            </PortalShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.scheduled.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='scheduled' />}>
            <OnboardingShell>
              <ScheduledApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.schedule.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='schedule' />}>
            <OnboardingShell>
              <ScheduleApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.reschedule.index}${routes.reschedule.children.appointment}`}
        element={<RescheduleApp />}
      />
      <Route
        path={`${routes.welcome.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='welcome' />}>
            <OnboardingShell
              showCallMeNowStatus={!url.pathname.match(/^\/welcome\/([^/]*\/?)$/)?.length}
            >
              <WelcomeApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.resetPassword.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='resetPassword' />}>
            <OnboardingShell>
              <ResetPasswordAuth>
                <ResetPasswordApp />
              </ResetPasswordAuth>
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.reconnect.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='reconnect' />}>
            <OnboardingShell>
              <ReconnectAuth>
                <ReconnectApp />
              </ReconnectAuth>
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.referrals.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='referrals' />}>
            <OnboardingShell>
              <ReferralsApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.referralsWithPatient.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='referralsWithPatient' />}>
            <OnboardingShell>
              <ReferralsWithPatientApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.options.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='options' />}>
            <OnboardingShell>
              <NotReadyApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.wellnessCheck.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='wellnessCheck' />}>
            <OnboardingShell>
              <WellnessCheckApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.callMeNow.index}`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='callMeNow' />}>
            <OnboardingShell>
              <EnterCallMeNowApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.confirmation.index}`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='confirmation' />}>
            <OnboardingShell>
              <ConfirmationApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.dropInClinic.index}`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='dropInClinic' />}>
            <OnboardingShell>
              <DropInClinicApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.meeting.index}`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='meeting' />}>
            <OnboardingShell>
              <ZoomRedirectApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.costAnalysis.index}`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='costAnalysis' />}>
            <Suspense fallback={<Skeletons />}>
              <CostAnalysisApp />
            </Suspense>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.insuranceCoverage.index}`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='insuranceCoverage' />}>
            <OnboardingShell>
              <Suspense fallback={<Skeletons />}>
                <InsuranceCoverageApp />
              </Suspense>
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.accountRecoveryRequest.index}/*`}
        element={
          <ErrorBoundary fallback={<Fallback boundary='accountRecoveryRequest' />}>
            <OnboardingShell>
              <AccountRecoveryApp />
            </OnboardingShell>
          </ErrorBoundary>
        }
      />
      <Route
        path={`${routes.signout.index}/*`}
        element={
          <OnboardingShell>
            <SignOutApp />
          </OnboardingShell>
        }
      />
      <Route path='*' element={<Navigate replace to={routes.welcome.index} />} />
    </Routes>
  )
}
