import {
  Group,
  Image,
  IMAGE_MIME_TYPE,
  PDFViewer,
  PDF_MIME_TYPE,
  PhotoFrame,
  Text,
} from '@shared/components'
import React from 'react'
import { useGcsFile } from '../../hooks'

type PhotoProps = {
  value: string | undefined
  label: string
}

export const Photo = ({ value, label }: PhotoProps) => {
  const { file, isFileMissing } = useGcsFile(value)

  return (
    <PhotoFrame loading={isFileMissing}>
      <PreviewPhoto file={file} label={label} />
    </PhotoFrame>
  )
}

export const PreviewPhoto = ({ file, label }: { file: File | undefined; label?: string }) => {
  if (file && IMAGE_MIME_TYPE.some(type => type === file.type)) {
    return <Image src={URL.createObjectURL(file)} fit='scale-down' />
  }

  if (file && PDF_MIME_TYPE.some(type => type === file.type)) {
    return <PDFViewer src={file} fit />
  }

  return (
    <Group p='lg' align='center' position='center' sx={{ width: '100%', height: '100%' }}>
      <Text>{label}</Text>
    </Group>
  )
}
