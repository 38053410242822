import {
  Avatar,
  AwardIcon,
  Box,
  CalendarIcon,
  ChevronRightIcon,
  CreditCardIcon,
  FileIcon,
  Group,
  HeartIcon,
  HomeIcon,
  Navbar,
  Overlay,
  Pill,
  Stack,
  TagIcon,
  TertiaryButton,
  Text,
  Transition,
} from '@shared/components'
import { name } from '@shared/utils'
import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAuth, usePortalDims, usePortalQuery } from '../../hooks'
import { routes } from '../../routes'
import { NavbarLink } from './NavbarLink'

const PortalNavbarMainContent = () => {
  const { currentUser } = useAuth()

  const achievements = usePortalQuery('GET /achievements', undefined, {
    enabled: Boolean(currentUser?.data?.oid),
  })
  const unseenAchievements = achievements?.data?.filter(
    achievement => achievement.firstSeenAt === null,
  )

  const currentUserName = name({
    first: currentUser?.data?.personalData?.firstName,
    last: currentUser?.data?.personalData?.lastName,
  }).full()

  return (
    <Stack spacing='lg'>
      <Group>
        <Avatar />
        <Box>
          <Text size='xs' bold>
            {currentUserName}
          </Text>
          <NavLink
            test-id='button:nav-account'
            to={routes.portal.children.account}
            style={{ textDecoration: 'none' }}
          >
            {({ isActive }) => (
              <Box
                sx={({ other: { sizes, colors, fontFamilies } }) => ({
                  width: 'fit-content',
                  lineHeight: 'normal',
                  borderBottomWidth: sizes.border.md,
                  borderBottomStyle: 'solid',
                  fontFamily: fontFamilies.bold,
                  borderBottomColor: isActive ? colors.actions[0] : 'transparent',
                  color: colors.actions[0],
                  ':hover': { color: colors.actions[1] },
                })}
              >
                Account
              </Box>
            )}
          </NavLink>
        </Box>
      </Group>
      <NavbarLink icon={<HomeIcon />} to={routes.portal.index} end>
        Dashboard
      </NavbarLink>
      <NavbarLink icon={<CalendarIcon />} to={routes.portal.children.visits}>
        Visits
      </NavbarLink>
      <NavbarLink icon={<TagIcon />} to={routes.portal.children.prescriptions}>
        Prescriptions
      </NavbarLink>
      <NavbarLink icon={<HeartIcon />} to={routes.portal.children.referrals}>
        Refer friends
      </NavbarLink>
      <NavbarLink icon={<AwardIcon />} to={routes.portal.children.achievements}>
        <Group noWrap mr={0}>
          Achievements
          {unseenAchievements && unseenAchievements.length > 0 && (
            <Pill variant='outline' status='success'>
              {unseenAchievements?.length}
            </Pill>
          )}
        </Group>
      </NavbarLink>
      <NavbarLink icon={<CreditCardIcon />} to={routes.portal.children.billing.index}>
        Billing & invoices
      </NavbarLink>
      <NavbarLink icon={<FileIcon />} to={routes.portal.children.documents.index}>
        Documents
      </NavbarLink>
    </Stack>
  )
}

type PortalNavbarProps = {
  isNavbarOpen: boolean
  toggleNavbar: (state: boolean) => void
}

export const PortalNavbar = ({ isNavbarOpen, toggleNavbar }: PortalNavbarProps) => {
  const location = useLocation()
  const { isDesktop, headerHeight, navbarWidth } = usePortalDims()

  const closeNavbar = () => toggleNavbar(false)

  useEffect(() => {
    closeNavbar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (isDesktop) {
    return (
      <Navbar
        p='lg'
        width={{ base: navbarWidth }}
        height='auto'
        sx={({ other: { colors } }) => ({
          backgroundColor: colors.background[1],
          border: 'unset',
          minHeight: '100%',
          overflowY: 'auto',
        })}
      >
        <PortalNavbarMainContent />
      </Navbar>
    )
  }

  return (
    <>
      <Transition mounted={isNavbarOpen} transition='fade' duration={400} timingFunction='ease'>
        {styles => (
          <Overlay
            onClick={closeNavbar}
            color='black'
            // eslint-disable-next-line no-magic-numbers
            style={{ ...styles, position: 'fixed', opacity: 0.6 * Number(styles.opacity) }}
            /**
             * Sets the Overlay above the Header.
             * NOTE: Must be set to 100 because of Mantine's default settings.
             */
            zIndex={100}
          />
        )}
      </Transition>
      <Transition
        mounted={isNavbarOpen}
        transition='slide-left'
        duration={400}
        timingFunction='ease'
      >
        {styles => (
          <Navbar
            style={styles}
            fixed
            height='100vh'
            position={{ right: 0 }}
            width={{ base: navbarWidth }}
            sx={({ other: { colors }, spacing }) => ({
              top: 0,
              border: 0,
              backgroundColor: colors.background[1],
              padding: `calc(${spacing.sm} * 3)`,
              overflowY: 'auto',
            })}
          >
            <Box style={{ height: headerHeight }}>
              <TertiaryButton onClick={closeNavbar} leftIcon={<ChevronRightIcon />} />
            </Box>
            <PortalNavbarMainContent />
          </Navbar>
        )}
      </Transition>
    </>
  )
}
