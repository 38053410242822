import { ISOString } from './dates'
import { ValueOf } from './utils'

export const DOSE_SPOT_RESULT_CODE_ERROR = 'ERROR'
export const DOSE_SPOT_RESULT_CODE_OK = 'OK'

export type DoseSpotSuccessfulResultResponse = {
  Result: {
    ResultCode: typeof DOSE_SPOT_RESULT_CODE_OK | typeof DOSE_SPOT_RESULT_CODE_ERROR
    ResultDescription: string
  }
}

export const isDoseSpotResultResponse = (
  response: unknown,
): response is DoseSpotSuccessfulResultResponse => {
  return typeof response === 'object' && response !== null && 'Result' in response
}

type DoseSpotErrorResultResponse = {
  Message: string
}

export type DoseSpotResultResponse = DoseSpotSuccessfulResultResponse | DoseSpotErrorResultResponse

export type DoseSpotTokenResponse = {
  access_token?: string
  token_type?: string
  expires_in?: number
  userName?: string
  error?: string
}

export type DoseSpotMedicationSearchResponse = {
  Items: DoseSpotMedicationSearchResult[]
} & DoseSpotResultResponse

export type DoseSpotPatient = {
  PatientId: number
  Prefix: string
  FirstName: string
  MiddleName: string
  LastName: string
  Suffix: string
  DateOfBirth: string
  Gender: number
  Email: string
  Address1: string
  Address2: string
  City: string
  State: string
  ZipCode: string
  PhoneAdditional1: string
  PhoneAdditional2: string
  PhoneAdditionalType1: string
  PhoneAdditionalType2: string
  PrimaryPhone: string
  PrimaryPhoneType: string
  Weight: number
  WeightMetric: number
  Height: number
  HeightMetric: number
  NonDoseSpotMedicalRecordNumber: string
  Active: boolean
  Encounter: string
  IsHospice: boolean
}

export type DoseSpotPatientSearchResponse = {
  Items: DoseSpotPatient[]
} & DoseSpotResultResponse

export type DoseSpotListPrescriptionsResponse = {
  Items: DoseSpotPrescription[]
} & DoseSpotResultResponse

export type CreatePatientBody = {
  FirstName: string
  LastName: string
  DateOfBirth: string | undefined
  Gender: number
  Address1: string | undefined
  Address2: string | undefined
  City: string | undefined
  State: string | undefined
  ZipCode: string | undefined
  PrimaryPhone: string
  PrimaryPhoneType: number
  Active: boolean
}

export type AddPrescriptionByCodeBody = {
  LexiGenProductId: number
  LexiDrugSynId: number
  LexiSynonymTypeId: number
  Refills: number
  DaysSupply?: number
  EffectiveDate?: string
  DispenseUnitId: number
  Quantity: string
  Directions: string
  PharmacyId: number
  PharmacyNotes: string
  NoSubstitutions: boolean
  DiagnosisId?: number
  Status: number
  NonDoseSpotPrescriptionId?: string
  OnBehalfOfUserId?: number
  // @dosespotV2Migration - including the DispensableDrugId in the v1 object facilitates migrating to the new API
  DispensableDrugId?: number
}

export type MarkPrescriptionsSignableBody = {
  Id: number[]
}

export type SendEPCSPrescriptionBody = {
  Pin: string
  OTP: string
}

export type SendEPCSPrescriptionsBody = {
  Pin: string
  OTP: string
  Id: number[]
}

export type DoseSpotPharmacyResponse = {
  Item: DoseSpotPharmacy
} & DoseSpotResultResponse

export type DoseSpotPharmaciesResponse = {
  Items: DoseSpotPharmacy[]
} & DoseSpotResultResponse

export type DoseSpotMedicationResponse = {
  Item: DoseSpotMedication
} & DoseSpotResultResponse

export type DoseSpotPrescriptionLogEntry = {
  Status: PrescriptionStatusId
  DateTimeStamp: string
  AdditionalInfo: string
  Ignored: boolean
  User: string
  WasRefillRequest: boolean
  MedicationStatus: MedicationStatusId
}

export type DoseSpotPrescriptionLogResponse = {
  Items: DoseSpotPrescriptionLogEntry[]
} & DoseSpotResultResponse

export const DispenseUnitType = {
  35: 'Applicator',
  39: 'Blister',
  44: 'Caplet',
  4: 'Capsule',
  32: 'Each',
  52: 'Film',
  8: 'Gram',
  33: 'Gum',
  54: 'Implant',
  57: 'Insert',
  11: 'Kit',
  90: 'Lancet',
  13: 'Lozenge',
  15: 'Milliliter',
  21: 'Packet',
  64: 'Pad',
  28: 'Patch',
  83: 'Pen Needle',
  24: 'Ring',
  73: 'Sponge',
  75: 'Stick',
  76: 'Strip',
  23: 'Suppository',
  77: 'Swab',
  26: 'Tablet',
  81: 'Troche',
  19: 'Unspecified',
  82: 'Wafer',
} as const

export type DispenseUnitId = keyof typeof DispenseUnitType

export const MedicationStatus = {
  0: 'Unknown',
  1: 'Active',
  2: 'Inactive',
  3: 'Discontinued',
  4: 'Deleted',
  5: 'Completed',
  6: 'Cancel Requested',
  7: 'Cancel Pending',
  8: 'Cancelled',
  9: 'Cancel Denied',
  10: 'Changed',
  11: 'Full Fill',
  12: 'Partial Fill',
  13: 'No Fill',
  // @dosespotV2Migration
  14: 'Unknown',
  15: 'CancelRequested',
  16: 'CancelPending',
  17: 'Cancelled',
  18: 'CancelDenied',
  29: 'Changed',
  20: 'FullFill',
  21: 'PartialFill',
  22: 'NoFill',
} as const

export type MedicationStatusId = keyof typeof MedicationStatus

export const PrescriptionStatus = {
  1: 'Entered',
  2: 'Printed',
  3: 'Sending',
  4: 'eRxSent',
  5: 'FaxSent',
  6: 'Error',
  7: 'Deleted',
  8: 'Requested',
  9: 'Edited',
  10: 'EPCS Error',
  11: 'EPCS Signed',
  12: 'Ready to Sign',
  13: 'Pharmacy Verified',
  // @dosespotV2Migration
  14: 'Unknown',
  15: 'EpcsError',
  16: 'EpcsSigned',
  17: 'ReadyToSign',
  18: 'PharmacyVerified',
} as const

export type PrescriptionStatusId = keyof typeof PrescriptionStatus

export type PrescriptionStatusString = ValueOf<typeof PrescriptionStatus>

export const readablePrescriptionStatusMap = {
  Entered: 'Initiated',
  Printed: 'Printed',
  Sending: 'Sending',
  eRxSent: 'Sent to pharmacy',
  FaxSent: 'Fax sent',
  Error: 'Something went wrong',
  Deleted: 'Deleted',
  Requested: 'Requested',
  Edited: 'Edited',
  'EPCS Error': 'Something went wrong',
  'EPCS Signed': 'Sending',
  'Ready to Sign': 'Ready to sign',
  'Pharmacy Verified': 'Received by pharmacy',
  Queued: 'Queued',
  Completed: 'Completed',
  'Cancel Requested': 'Cancel requested',
  'Cancel Pending': 'Cancel pending',
  Cancelled: 'Canceled',
  'Cancel Denied': 'Cancel denied',
  // @dosespotV2Migration
  Unknown: 'Something went wrong',
  EpcsError: 'Something went wrong',
  EpcsSigned: 'Sending',
  ReadyToSign: 'Ready to sign',
  PharmacyVerified: 'Received by pharmacy',
} as const

export type PrescriptionCustomStatus = keyof typeof readablePrescriptionStatusMap

export const PharmacySpecialty = {
  2048: 'EPCS',
  64: '24-Hour Pharmacy',
  32: 'Long-Term Care Pharmacy',
  1: 'Mail Order',
  8: 'Retail',
  16: 'Specialty Pharmacy',
} as const

export type PharmacySpecialtyId = keyof typeof PharmacySpecialty

export type DoseSpotComment = {
  Comment: string
}

export type DoseSpotNotificationCountResponse = {
  RefillRequestsCount: number
  TransactionErrorsCount: number
  PendingPrescriptionsCount: number
  PendingRxChangeCount: number
} & DoseSpotResultResponse

export type DoseSpotClinician = {
  ClinicianId: number
  Prefix: string
  FirstName: string
  MiddleName: string
  LastName: string
  Suffix: string
  DateOfBirth: string
  Email: string
  Address1: string
  Address2: string
  City: string
  State: string
  ZipCode: string
  PrimaryPhone: string
  PrimaryPhoneType: number
  PrimaryFax: string
  PhoneAdditional1: string
  PhoneAdditionalType1: number
  PhoneAdditional2: string
  PhoneAdditionalType2: number
  PhoneAdditional3: string
  PhoneAdditionalType3: number
  DEANumbers: {
    DEANumber: string
    State: string
    ClinicId: string
  }[]
  DEANumber: string
  NADEANumbers: {
    NADEANumber: string
    State: string
    ClinicId: string
  }[]
  MedicalLicenseNumber: {
    LicenseNumber: string
    State: string
    ClinicId: string
  }[]
  NPINumber: string
  IsProxyClinician: boolean
  IsReportingClinician: boolean
  Roles: number[]
  Confirmed: boolean
  Active: boolean
  EpcsRequested: boolean
  ClinicInfo: {
    ClinicId: number
    HasNewRx: boolean
    HasRefills: boolean
    HasRxChange: boolean
    HasCancel: boolean
    HasRxFill: boolean
    HasEpcs: boolean
  }[]
}

export type DoseSpotClinic = {
  ClinicId: number
  ClinicName: string
  ClinicNameLongForm: string
  Address1: string
  Address2: string
  City: string
  State: string
  ZipCode: string
  PrimaryPhone: string
  PrimaryPhoneType: number
  PrimaryFax: string
  PhoneAdditional1: string
  PhoneAdditionalType1: number
  PhoneAdditional2: string
  PhoneAdditionalType2: number
  PhoneAdditional3: string
  PhoneAdditionalType3: number
}

export type DoseSpotRefillRequest = {
  RefillId: string
  NewPatient: boolean
  Patient: DoseSpotPatient
  RequestedPrescription: DoseSpotPrescription
  DispensedPrescription: DoseSpotPrescription
  Clinician: DoseSpotClinician
  Clinic: DoseSpotClinic
  Pharmacy: DoseSpotPharmacy
  RequestedDate: string
}

export type DoseSpotRefillRequestsResponse = {
  Items: DoseSpotRefillRequest[]
} & DoseSpotResultResponse

type DoseSpotPharmacyTypeNumber = keyof typeof DoseSpotPharmacyType

export const DoseSpotPharmacyType = {
  1: 'Mail order',
  8: 'Retail',
  16: 'Specialty pharmacy',
  32: 'Long term care pharmacy',
  64: '24 hour pharmacy / any pharmacy type',
  2048: 'EPCS',
}

type DoseSpotPharmacyEligibilityStatusNumber = keyof typeof DoseSpotPharmacyEligibilityStatus

export const DoseSpotPharmacyEligibilityStatus = {
  1: 'Covered',
  2: 'Not covered',
  3: 'Out of pocket',
  4: 'Unknown',
  5: 'Unable to process',
}

type DoseSpotPharmacyEligibility = {
  PharmacyType: DoseSpotPharmacyTypeNumber
  EligibilityStatus: DoseSpotPharmacyEligibilityStatusNumber
  Name: string
}

export const DoseSpotPayerDemographicGender = {
  1: 'Male',
  2: 'Female',
  3: 'Unknown',
}

type DoseSpotPayerDemographicGenderNumber = keyof typeof DoseSpotPayerDemographicGender

export type DoseSpotPayerDemographic = {
  LastName: string
  FirstName: string
  MiddleName: string
  Suffix: string
  DateOfBirth: string
  Gender: DoseSpotPayerDemographicGenderNumber
  Address: {
    AddressLine1: string
    AddressLine2: string
    City: string
    State: string
    ZipCode: string
  }
}

type DoseSpotEligibility = {
  PatientEligibilityId: number
  PayerName: string
  PharmacyEligibilities: DoseSpotPharmacyEligibility[]
  PlanName: string
  CoverageId: string
  PayerId: string
  BIN: string
  PCN: string
  FormularyId: string
  AlternativesId: string
  CopayId: string
  /** MemberId is not in documentation but shows up in API responses */
  MemberId: string
  DemographicChanges: boolean
  Demographics: DoseSpotPayerDemographic
  ErrorMessage: string
  InfoMessage: string
}

export type DoseSpotEligibilitiesResponse = {
  Items: DoseSpotEligibility[]
} & DoseSpotResultResponse

export type DoseSpotCopay = {
  IsDrugSpecific: boolean
  PharmacyType: DoseSpotPharmacyTypeNumber
  FlatCopayAmount: number
  PercentCopayAmount: number
  FlatCopayAmountFirst: boolean
  MinimumCopayAmount: number
  MaximumCopayAmount: number
  DaysSupply: number
  CopayTier: number
  MaximumCopayTier: number
  OutOfPocketMinimum: number
  OutOfPocketMaximum: number
}

export const DoseSpotRTPBPharmacySpecialtyType = {
  0: 'Retail',
  1: 'Mail Order',
  2: 'Item90DayAtRetail',
}
type DoseSpotRTPBPharmacySpecialtyTypeNumber = keyof typeof DoseSpotRTPBPharmacySpecialtyType

type DoseSpotPresciptionBenefitsPharmacy = {
  PharmacyType: DoseSpotRTPBPharmacySpecialtyTypeNumber
  Identifications: Record<string, string>
  PharmacistLastName: string
  PharmacistFirstName: string
  PharmacistMiddleName: string
  PharmacistSuffix: string
  PharmacistPrefix: string
  Email: string
  PharmacyId: number
  StoreName: string
  Address1: string
  Address2: string
  City: string
  State: string
  ZipCode: string
  PrimaryPhone: string
  PrimaryPhoneType: number
  PrimaryFax: string
  PhoneAdditional1: string
  PhoneAdditionalType1: number
  PhoneAdditional2: string
  PhoneAdditionalType2: number
  PhoneAdditional3: string
  PhoneAdditionalType3: number
  PharmacySpecialties: PharmacySpecialtyId[]
  // For more info on Service Level, read section `4.2.16 Service Levels` in DoseSpot documentation
  ServiceLevel: number
  Latitude: number
  Longitude: number
}

const DoseSpotCodeListQualifierCode = {
  0: 'Item38',
  1: 'Item40',
  2: 'Item87',
  3: 'QS',
  4: 'CF',
  5: 'UQ',
}
type DoseSpotCodeListQualifierCodesNumber = keyof typeof DoseSpotCodeListQualifierCode

type DoseSpotPricingCoverageFactorsCoverageAlerts = {
  ReferenceCode: number
  ReferenceText: string
}

type DoseSpotPricingCoverageFactors = {
  DrugStatusCode: string
  DrugStatus: string
  QuantityPricedValue: string
  QuantityPricedCodeListQualifier: DoseSpotCodeListQualifierCodesNumber
  QuantityPricedUnitOfMeasureType: string
  QuantityPricedUnitOfMeasureTypeId: number
  DaysSupplyPriced: string
  PlanPayAmount: string
  EstimatedPatientPayAmount: string
  // OOP = Out-of-pocket
  OOPAppliedAmount: string
  OOPRemainingAmount: string
  DeductibleAppliedAmount: string
  DeductibleRemainingAmount: string
  CoverageAlerts: DoseSpotPricingCoverageFactorsCoverageAlerts[]
  FormularyStatus: string
  PriorAuthRequired: boolean
}

export type DoseSpotPatientSpecificPricingCoverage = {
  Pharmacy: DoseSpotPresciptionBenefitsPharmacy
  PricingCoverage: DoseSpotPricingCoverageFactors
}

type DoseSpotMedicationCoverage = {
  FormularyStatus: string
}

type DoseSpotPayerAlternativeDetail = {
  PatientSpecificPricingCoverages: DoseSpotPatientSpecificPricingCoverage[]
  Ndc: string
  DisplayName: string
  RxCUI: number
  LexiGenProductID: number
  LexiSynonymID: number
  LexiSynonymTypeID: number
  Coverage: DoseSpotMedicationCoverage
}

type DoseSpotAgeLimitRestrictions = {
  MinimumAge: number
  // 1 = days, 2 = years
  MinimumAgeUnit: 1 | 2
  MaximumAge: number
  MaximumAgeUnit: 1 | 2
}

export const DoseSpotQuanitityLimitRestrictionsAmountUnit = {
  1: 'Dollars',
  2: 'Days Supply',
  3: 'Fills',
  4: 'Quantity',
}

type DoseSpotQuanitityLimitRestrictionsAmountUnitNumber =
  keyof typeof DoseSpotQuanitityLimitRestrictionsAmountUnit

export const DoseSpotQuanitityLimitRestrictionsTimeUnit = {
  1: 'Day(s)',
  2: 'Lifetime',
  3: 'Dispensing',
  4: 'Specific Date Range',
  5: 'Calendar Year',
  6: 'Calendar Quarter',
  7: 'Calendar Month',
}

type DoseSpotQuanitityLimitRestrictionsTimeUnitNumber =
  keyof typeof DoseSpotQuanitityLimitRestrictionsTimeUnit

type DoseSpotQuanitityLimitRestrictions = {
  MaximumAmount: number
  AmountUnit: DoseSpotQuanitityLimitRestrictionsAmountUnitNumber
  TimePeriodUnit: DoseSpotQuanitityLimitRestrictionsTimeUnitNumber
  StartDate: string
  EndDate: string
  MaximumTimePeriodUnits: number
}

type DoseSpotStepMedicationRestrictions = {
  NDC: string
  // 0 = Unknown, 1 = SpecificMedication, 2 = PharmacologicalClass
  StepDrugType: 0 | 1 | 2
  StepOrder: number
  DisplayName: string
  RxCUI: number
  LexiGenProductID: number
  LexiSynonymID: number
  LexiSynonymTypeID: number
}

type DoseSpotCoverageMessage = {
  Message: string
  LongMessage: string
}

const DoseSpotCoverageResourceType = {
  1: 'AgeLimits',
  2: 'ProductCoverageExclusion',
  3: 'GenderLimits',
  4: 'MedicalNecessity',
  5: 'PriorAuthorization',
  6: 'QuantityLimits',
  7: 'StepTherapy',
  8: 'General',
  9: 'Copay',
  10: 'Formulary',
}
type DoseSpotCoverageResourceTypeNumber = keyof typeof DoseSpotCoverageResourceType

type DoseSpotCoverageResource = {
  Url: string
  ResourceType: DoseSpotCoverageResourceTypeNumber
}

export const DoseSpotCoverageGenderRestriction = {
  1: 'Male',
  2: 'Female',
  3: 'Unknown',
}

type DoseSpotCoverageGenderRestrictionNumber = keyof typeof DoseSpotCoverageGenderRestriction

export type DoseSpotCoverageRestrictions = {
  HasAgeLimit: boolean
  HasQuantityLimit: boolean
  HasCoverageRestrictions: boolean
  IsExcluded: boolean
  HasMedicalNecessity: boolean
  HasPriorAuthorization: boolean
  HasStepTherapy: boolean
  AgeLimitRestrictions: DoseSpotAgeLimitRestrictions[]
  GenderRestriction: DoseSpotCoverageGenderRestrictionNumber
  QuantityLimitRestrictions: DoseSpotQuanitityLimitRestrictions[]
  StepMedicationRestrictions: DoseSpotStepMedicationRestrictions[]
  Messages: DoseSpotCoverageMessage[]
  DrugResources: DoseSpotCoverageResource[]
  SummaryResources: DoseSpotCoverageResource[]
}

type DoseSpotRepresentativeFormularyCoverage = {
  CopayFactors: DoseSpotCopay[]
  FormularyStatus: string
  FormularyStatusText: string
  RealTimeFormularyCoverageRestrictions: DoseSpotCoverageRestrictions
}

export type DoseSpotCoverageAlternative = {
  Ndc: string
  DisplayName: string
  RxCUI: number
  LexiGenProductID: number
  LexiSynonymID: number
  LexiSynonymTypeID: number
  Details: DoseSpotPayerAlternativeDetail[]
  RepresentativeFormularyCoverage: DoseSpotRepresentativeFormularyCoverage
  PatientSpecificPricingCoverages: DoseSpotPatientSpecificPricingCoverage[]
}

export const DoseSpotRealTimeFormularyRequestStatus = {
  0: 'Queued',
  1: 'Sent',
  2: 'Success',
  // It's not documented, so no idea what 3 represents, but that's the status DS returns when information has populated.
  3: 'Complete',
  '1000': 'Unexpected error',
  '1001': 'Patient eligibility not found',
  '1002': 'Failed to get alternatives',
  '1003': 'Patient formulary not found',
  '1004': 'Failed to parse formulary data',
  '1005': 'Failed to process send',
  '1006': 'Failed to queue message',
  '1007': 'Data expired',
  '1008': 'Request expired',
  '1009': 'Surescripts responded with error code',
  '1010': 'Missing patient benefits inputs',
  '1011': 'Unknown Surescripts response schema',
  '1012': 'Surescripts responded with transaction error code',
}

type DoseSpotRealTimeFormularyRequestStatusNumber =
  keyof typeof DoseSpotRealTimeFormularyRequestStatus

export type DoseSpotDrugCoverageResponse = {
  Brand: boolean
  Otc: boolean
  FormularyStatus: string
  Copays: DoseSpotCopay[] | null
  Alternatives: DoseSpotCoverageAlternative[] | null
  Restrictions: DoseSpotCoverageRestrictions | null
  RealTimeFormularyRequestStatus: DoseSpotRealTimeFormularyRequestStatusNumber
} & DoseSpotResultResponse

export type DoseSpotPrescriptionBenefitsResponse = {
  Alternatives: DoseSpotCoverageAlternative[] | null
  RealTimeFormularyRequestStatus: DoseSpotRealTimeFormularyRequestStatusNumber
  PatientSpecificPricingCoverages: DoseSpotPatientSpecificPricingCoverage[] | null
  OptionalPharmacy: DoseSpotCopay[] | null
} & DoseSpotResultResponse

export type SurescriptsHistoryMedication = {
  PatientMedicationHistoryId: number
  PharmacyNotes: string
  NoSubstitutions: boolean
  EffectiveDate: string
  LastFillDate: string
  Payer: string
  DaysSupply: number
  Directions: string
  DispenseUnitDescription: string
  DispenseUnitId: number
  Quantity: string
  Refills: string
  WrittenDate: string
  DiagnosisCode: string
  DiagnosisQualifier: string
  DoseForm: string
  Route: string
  Strength: string
  GenericProductName: string
  GenericDrugName: string
  LexiGenProductId: number
  LexiDrugSynId: number
  LexiSynonymTypeId: number
  LexiGenDrugId: string
  RxCUI: string
  OTC: boolean
  NDC: string
  Schedule: string
  DisplayName: string
  MonographPath: string
  DrugClassification: string
  StateSchedules: { StateName: string; Schedule: number }[]
  Brand: boolean
  CompoundingIngredients: {
    LexiGenProductId: number
    LexiDrugSynId: number
    FreeText: string
    DispenseUnitId: number
    IsFreeTextEPCS: boolean
    Schedule: number
    StateSchedules: { StateName: string; Schedule: number }[]
  }[]
}

export type DoseSpotMedicationHistoryResponse = {
  Items: SurescriptsHistoryMedication[]
} & DoseSpotResultResponse

export const DoseSpotDenialReasonType: Record<number, string> = {
  3: 'DeniedNewRx',
  4: 'DeniedPatientUnknown',
  5: 'DeniedPatientNotUnderCare',
  6: 'DeniedPatientNoLongerUnderPatientCare',
  7: 'DeniedTooSoon',
  8: 'DeniedNeverPrescribed',
  9: 'DeniedHavePatientContact',
  10: 'DeniedRefillInappropriate',
  11: 'DeniedAlreadyPickedUp',
  12: 'DeniedAlreadyPickedUpPartialFill',
  13: 'DeniedNotPickedUp',
  14: 'DeniedChangeInappropriate',
  15: 'DeniedNeedAppointment',
  16: 'DeniedPrescriberNotAssociateWithLocation',
  17: 'DeniedNoPriorAuthAttempt',
  18: 'DeniedAlreadyHandler',
}

// Dosespot throws an error when attempting to create a patient with an address longer than 35 characters
export const DOSESPOT_ADDRESS_CHARACTER_LIMIT = 35
export const DOSESPOT_DIRECTIONS_CHAR_LIMIT = 1000
export const DOSESPOT_PHARMACY_NOTES_CHAR_LIMIT = 210

export type DoseSpotPrescription = {
  PrescriptionId: number
  WrittenDate: string
  Directions: string
  Quantity: string
  DispenseUnitId: DispenseUnitId
  DispenseUnitDescription: string
  Refills: string
  DaysSupply?: number
  pharmacy?: {
    address: string
    phone: string
    city: string
    state: string
    zip: string
    name: string
  }
  PharmacyId: number
  PharmacyNotes: string | null
  NoSubstitutions: boolean
  EffectiveDate: string
  LastFillDate: string | null
  PrescriberId: number
  PrescriberAgentId: number | null
  RxReferenceNumber: string | null
  Status: PrescriptionStatusId
  Formulary: boolean
  EligibilityId: number
  NonDoseSpotPrescriptionId: string | null
  ErrorIgnored: boolean
  SupplyId: number | null
  CompoundId: number | null
  FreeTextType: string | null
  ClinicId: number
  SupervisorId: number | null
  IsUrgent: boolean
  IsRxRenewal: boolean
  RxRenewalNote: string | null
  PatientMedicationId: number
  MedicationStatus: number
  Comment: string | null
  DateInactive: string | null
  Encounter: string | null
  DoseForm: string
  Route: string
  Strength: string
  GenericProductName: string
  GenericDrugName: string
  LexiGenProductId: number
  LexiDrugSynId: number
  LexiSynonymTypeId: number
  LexiGenDrugId: string
  RxCUI: string
  OTC: boolean
  NDC: string
  Schedule: string
  DisplayName: string
  MonographPath: string
  DrugClassification: string
  StateSchedules: { StateName: string; Schedule: number }[] | null
  Brand: boolean
  /*
   * @dosespotV2Migration - including as an optional field on this type, because we're going to map the DispensableDrugId to this object
   * when we call the v2 API
   */
  DispensableDrugId?: number
}

export type DoseSpotIdResponse = {
  Id: number
} & DoseSpotResultResponse

export type DoseSpotPharmacySearchRequestQuery = {
  name?: string
  city?: string
  state?: string
  zip?: string
  address?: string
  phoneOrFax?: string
  ncpdpiID?: string
  'specialty[0]'?: string
  'specialty[1]'?: string
}

export type DoseSpotPharmacy = {
  IsDefault?: boolean
  PharmacyId: number
  StoreName: string
  Address1: string
  Address2: string
  City: string
  State: string
  ZipCode: string
  PrimaryPhone: string
  PrimaryPhoneType: number
  PrimaryFax: string
  PhoneAdditional1: string
  PhoneAdditionalType1: number
  PhoneAdditional2: string
  PhoneAdditionalType2: number
  PhoneAdditional3: string
  PhoneAdditionalType3: number
  PharmacySpecialties: string[]
  ServiceLevel: number
  Latitude: number
  Longitude: number
}

export type DoseSpotMedicationSearchResult = {
  Name: string
  Strength: {
    Strength: string
    NDC: string
  }[]
  // @dosespotV2Migration - the following values are required to get a prescription in the v2 API, and the above values will be deprecated when we fully migrate to v2
  DispensableDrugId?: number
}

export type DoseSpotMedication = {
  DispenseUnitId: DispenseUnitId
  DoseForm: string
  Route: string
  Strength: string
  GenericProductName: string
  LexiGenProductId: number
  LexiDrugSynId: number
  LexiSynonymTypeId: number
  LexiGenDrugId: string
  RxCUI: string
  OTC: boolean
  NDC: string
  Schedule: string
  DisplayName: string
  MonographPath: string
  DrugClassification: string
  StateSchedules: {
    StateName: string
    Schedule: number
  }[]
  Brand: boolean
  // @dosespotV2Migration - including the DispensableDrugId in the v1 object facilitates migrating to the new API
  DispensableDrugId?: number
}

export type PrescriptionIdAndSchedule = {
  id: number
  schedule: string
}

export const DoseSpotClinicStates = [
  'ny',
  'fl',
  'pa',
  'az',
  'ca',
  'ct',
  'md',
  'me',
  'mt',
  'nj',
  'tx',
  'va',
  'vt',
  'wa',
  'default',
] as const

export type DoseSpotClinicState = (typeof DoseSpotClinicStates)[number]

export function isDoseSpotClinicState(state: string): state is DoseSpotClinicState {
  return DoseSpotClinicStates.includes(state as DoseSpotClinicState)
}

// @dosespotV2Migration
export type DoseSpotPrescriptionV2 = {
  ClinicId: number
  // Comments on the prescription
  Comment: string
  // Date when the prescription became inactive
  DateInactive: ISOString
  DaysSupply: number
  // Directions for using the medication
  Directions: string
  DispensableDrugId: number
  DisplayName: string
  DispenseUnitId: DispenseUnitId
  EligibilityId: number
  Encounter: string
  // Effective date of the prescription
  EffectiveDate: ISOString
  ErrorIgnored: boolean
  Formulary: boolean
  FreeTextType: 'Medication' | 'OtherType'
  IsRxRenewal: boolean
  IsUrgent: boolean
  // Last fill date of the prescription
  LastFillDate: ISOString
  MedicationStatus: ValueOf<typeof MedicationStatus>
  NonDoseSpotPrescriptionId: string
  NoSubstitutions: boolean
  PatientMedicationId: number
  PharmacyId: number
  PharmacyNotes: string
  PrescriptionId: number
  PrescriberAgentId: number
  PrescriberId: number
  Quantity: string
  Refills: string
  RxReferenceNumber: string
  RxRenewalNote: string
  Schedule: string
  Status: PrescriptionStatusId
  Strength: string
  SupervisorId: number
  SupplyId: number
  WrittenDate: ISOString
}

export type DoseSpotGetPrescriptionResponseV2 = {
  Prescription: DoseSpotPrescriptionV2
} & DoseSpotResultResponse

type DoseSpotPaginationV2 = {
  CurrentPage: number
  TotalPages: number
  PageSize: number
  TotalCount: number
  HasPrevious: boolean
  HasNext: boolean
}

type DoseSpotPaginationResponseV2 = {
  PageResult: DoseSpotPaginationV2
}

export type DoseSpotListPrescriptionsResponseV2 = {
  Items: DoseSpotPrescriptionV2[]
} & DoseSpotPaginationResponseV2 &
  DoseSpotResultResponse

export type DoseSpotPharmacyV2 = {
  Address1: string
  Address2: string
  City: string
  IsDefault: boolean
  Latitude: number
  Longitude: number
  NCPDPID: string
  PharmacyId: number
  PharmacySpecialties: string[]
  PhoneAdditional1: string
  PhoneAdditional2: string
  PhoneAdditional3: string
  PhoneAdditionalType1: number
  PhoneAdditionalType2: number
  PhoneAdditionalType3: number
  PrimaryFax: string
  PrimaryPhone: string
  PrimaryPhoneType: number
  ServiceLevel: number
  State: string
  StoreName: string
  ZipCode: string
}

export type DoseSpotPharmacyResponseV2 = {
  Item: DoseSpotPharmacyV2
} & DoseSpotResultResponse

export type DoseSpotPharmaciesResponseV2 = {
  Items: DoseSpotPharmacyV2[]
} & DoseSpotPaginationResponseV2 &
  DoseSpotResultResponse

export type DoseSpotPrescriptionRequestBodyV2 = {
  Comment?: string
  DaysSupply?: number
  Directions?: string
  DispensableDrugId: number
  DispenseUnitId?: number
  EffectiveDate?: ISOString
  EligibilityId?: number
  Encounter?: string
  FirstPrescriptionDiagnosis?: {
    PrimaryDiagnosisId?: number
    SecondaryDiagnosisId?: number
  }
  InactiveDate?: ISOString
  IsRefillReplace?: boolean
  IsUrgent?: boolean
  NonDoseSpotPrescriptionId?: string
  NoSubstitutions?: boolean
  PatientFormularyId?: number
  PharmacyId?: number
  PharmacyNotes?: string
  Quantity?: number
  Refills?: number
  RetailPharmacyToMailToPatient?: boolean
  RxReferenceNumber?: string
  SecondPrescriptionDiagnosis?: {
    PrimaryDiagnosisId?: number
    SecondaryDiagnosisId?: number
  }
  Status?: number
  SupervisorId?: number
}

export type DoseSpotPatientSearchResponseV2 = {
  Items: DoseSpotPatient[]
} & DoseSpotPaginationResponseV2 &
  DoseSpotResultResponse

export type SendEPCSPrescriptionsBodyV2 = {
  Pin: string
  OTP: string
  PrescriptionIds: number[]
}

export type DoseSpotGetPatientResponseV2 = {
  Item: DoseSpotPatient
} & DoseSpotResultResponse

export type DoseSpotMarkPrescriptionsSignableBodyV2 = {
  Prescriptions: {
    PrescriptionId: number
    ClinicianReferenceNumberOverrides?: {
      OverrideDeaNumber: string
      OverrideSupervisorDeaNumber: string
    }
  }[]
}

export type DoseSpotMedicationSearchResultV2 = {
  DispensableDrugId: number
  RoutedDoseFormDrugId: number
  NDC: string
  RXCUI: number
  NameWithRouteDoseForm: string
  Strength: string
  IsObsolete: boolean
}

export type DoseSpotMedicationSearchResponseV2 = {
  Items: DoseSpotMedicationSearchResultV2[]
} & DoseSpotPaginationResponseV2 &
  DoseSpotResultResponse

type DoseSpotMedicationV2 = {
  Brand: boolean
  DispensableDrugId: number
  DispenseUnitId: DispenseUnitId
  DisplayName: string
  DoseForm: string
  DrugClassification: string
  DrugName: string
  GenericDrugName: string
  IsObsolete: boolean
  NDC: string
  OTC: boolean
  RXCUI: number
  Route: string
  Schedule: number
  StateSchedule: [
    {
      StateName: string
      Schedule: number
    },
  ]
  Strength: string
}

export type DoseSpotMedicationResponseV2 = {
  Item: DoseSpotMedicationV2
} & DoseSpotResultResponse

export type DoseSpotRefillRequestV2 = {
  RefillId: string
  NewPatient: boolean
  Patient: DoseSpotPatient
  ClinicId: number
  ClinicianId: number
  RequestedPrescriptionId: number
  DispensedPrescriptionId: number
  OriginalPrescriptionId: number
  PharmacyId: number
  RequestedDate: string
}

export type DoseSpotRefillRequestsResponseV2 = {
  Items: DoseSpotRefillRequestV2[]
} & DoseSpotResultResponse

export type DoseSpotMedicationHistoryResponseV2 = {
  Items: MedicationHistoryV2[]
} & DoseSpotPaginationResponseV2 &
  DoseSpotResultResponse

type MedicationHistoryV2 = {
  Brand: boolean
  DaysSupply: number
  DiagnosisCode: string
  DiagnosisQualifier: string
  Directions: string
  DisplayName: string
  DispensableDrugId: number
  DispenseUnitDescription: string
  DispenseUnitId: number
  DoseForm: string
  DrugClassification: string
  EffectiveDate: ISOString
  ExpirationDate: ISOString
  GenericDrugName: string
  LastFillDate: ISOString
  NDC: string
  NoSubstitutions: boolean
  OTC: boolean
  Payer: string
  PatientMedicationHistoryId: number
  PharmacyNotes: string
  Quantity: string
  Refills: string
  Route: string
  RxCUI: string
  Schedule: string
  Strength: string
  WrittenDate: ISOString
}

type DoseSpotPrescriptionLogEntryV2 = {
  Status: ValueOf<typeof PrescriptionStatus>
  DateTimeStamp: string
  AdditionalInfo: string
  Ignored: boolean
  User: string
  WasRefillRequest: boolean
  MedicationStatus: ValueOf<typeof MedicationStatus>
}

export type DoseSpotPrescriptionLogResponseV2 = {
  Items: DoseSpotPrescriptionLogEntryV2[]
} & DoseSpotResultResponse
