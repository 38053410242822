import { useForm } from '@mantine/form'
import { Radio, RadioGroup, Stack } from '@shared/components'
import React from 'react'
import { isRequired } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskHeader } from './TaskHeader'
import { TaskStepProps } from './taskStepProps'

export const ZoomForm = ({
  initialValues,
  saving,
  submit,
}: TaskStepProps<'zoom'> & { zoomLinkClicked: boolean }) => {
  const { validate, values, getInputProps } = useForm({
    initialValues,
    validate: {
      isZoomWorking: isRequired,
    },
  })

  function onSubmit() {
    if (!validate().hasErrors) {
      submit('is-zoom-working', values)
    }
  }
  return (
    <>
      <TaskHeader title='Were you able to open Zoom with audio and video working properly?' />
      <Stack spacing='md' test-id='step:zoom'>
        <RadioGroup {...getInputProps('isZoomWorking')}>
          <Radio
            test-id='input:is-zoom-working-yes'
            label='Yes, Zoom is working on my device'
            value='yes'
          />
          <Radio
            test-id='input:is-zoom-working-no'
            label="No, I'm having trouble getting Zoom to work"
            value='no'
          />
        </RadioGroup>
        <TaskButton onSubmit={onSubmit} loading={saving} isLastStep />
      </Stack>
    </>
  )
}
