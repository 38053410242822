import { Box, Group } from '@shared/components'
import React from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  icon: React.ReactNode
  to: string
  children: React.ReactNode
  end?: boolean | undefined
}

export const NavbarLink = ({ icon, to, children, end = false }: Props) => {
  return (
    <NavLink
      test-id={`button:nav-${to.replace('/', '')}`}
      to={to}
      end={end}
      style={{ textDecoration: 'none' }}
    >
      {({ isActive }) => (
        <Group
          noWrap
          spacing='sm'
          sx={({ radius, other: { colors, fontFamilies } }) => ({
            color: colors.actions[0],
            borderRadius: radius.sm,
            ':hover': {
              color: colors.actions[1],
              '> div': {
                fontFamily: fontFamilies.bold,
                borderBottomColor: colors.actions[1],
              },
            },
          })}
        >
          {icon}
          <Box
            sx={({ other: { sizes, colors } }) => ({
              lineHeight: 'normal',
              borderBottomWidth: sizes.border.md,
              borderBottomStyle: 'solid',
              borderBottomColor: isActive ? colors.actions[0] : 'transparent',
              flexGrow: 1,
              color: colors.text[0],
            })}
          >
            {children}
          </Box>
        </Group>
      )}
    </NavLink>
  )
}
