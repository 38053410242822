import { Stack, Text, TitleTwo } from '@shared/components'
import { PromptContentHeader as PromptContentHeaderType } from '@shared/types'
import React from 'react'

type Props = {
  data: PromptContentHeaderType
}

export const PromptContentHeader = (props: Props) => {
  return (
    <Stack>
      <TitleTwo>{props.data.primaryText}</TitleTwo>
      {props.data?.secondaryText ? <Text>{props.data.secondaryText}</Text> : null}
    </Stack>
  )
}
