import { Avatar, Image, Tooltip } from '@shared/components'
import { Achievement, BadgeContentType, MilestoneContentType } from '@shared/types'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { Config } from '../../../common/config'
import { useAuth, usePortalMutation } from '../../../common/hooks'

export const AchievedIcon = ({
  achievement,
  content,
}: {
  achievement: Achievement
  content: BadgeContentType | MilestoneContentType
}) => {
  const { currentUser } = useAuth()
  const currentUserData = currentUser?.data

  const queryClient = useQueryClient()

  const markAchievementAsSeen = usePortalMutation('PUT /achievements/:achievementId/seen', {
    onSuccess: () => {
      void queryClient.invalidateQueries('GET /achievements')
    },
  })

  useEffect(() => {
    if (currentUserData?.oid) {
      markAchievementAsSeen.mutate({
        params: {
          achievementId: achievement.oid,
        },
      })
    }
  }, [currentUserData, achievement.oid])

  if (achievement.type === 'badge') {
    return (
      <Tooltip label={content.label}>
        <Avatar
          size='lg'
          mx='sm'
          key={content.value}
          src={`${Config.ACHIEVEMENTS_BASE_URL}${content.iconUrl}`}
          backgroundColor='none'
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip label={content.label}>
      <Avatar size='xl' mx='xs' key={content.value} src='' backgroundColor='none'>
        <Image src={`${Config.ACHIEVEMENTS_BASE_URL}${content.iconUrl}`} />
      </Avatar>
    </Tooltip>
  )
}
