import { Drawer, Stack, Text, emojiMap } from '@shared/components'
import React, { useEffect } from 'react'
import * as FullStory from '../../fullstory'
import { usePortalDims } from '../../hooks'

export const MotivationsForTreatmentDrawer = ({
  opened,
  onClose,
}: {
  opened: boolean
  onClose: () => void
}) => {
  const { isMobile } = usePortalDims()

  useEffect(() => {
    if (opened) {
      FullStory.event('Motivations for Treatment Example Drawer Opened')
    }
  }, [opened])

  const handleClose = () => {
    onClose()
  }

  return (
    <Drawer
      opened={opened}
      onClose={handleClose}
      size='sm'
      position={isMobile ? 'bottom' : 'right'}
      bottomHeight='50vh'
      title={`Examples of a "why"`}
    >
      <Stack spacing='md' m='md'>
        <Text>I want to change my relationship with opioids so that...</Text>
        <Text>{`${emojiMap.sparkles} I can be fully present for my family and friends.`}</Text>
        <Text>{`${emojiMap.sparkles} I can save money and invest in my future.`}</Text>
        <Text>{`${emojiMap.sparkles} I can regain control of my life and make positive choices.`}</Text>
      </Stack>
    </Drawer>
  )
}
