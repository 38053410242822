import { UseFormReturnType } from '@mantine/form'
import {
  ChevronLeftIcon,
  DateInput,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TextInput,
  useLifecycle,
} from '@shared/components'
import { PayerSearchOption } from '@shared/utils'
import React from 'react'
import { InsuranceCoverageForm } from '.'
import * as FullStory from '../common/fullstory'
import { sendTrackEvent } from '../common/rudderstack'

type CoverageSearchOptionsProps = {
  payerSearchOptions: PayerSearchOption[]
  form: UseFormReturnType<
    InsuranceCoverageForm,
    (values: InsuranceCoverageForm) => InsuranceCoverageForm
  >
  isLoading: boolean
  handleClickBack: () => void
  handleClickNext: () => void
}

export const SearchOptionInput = ({
  payerSearchOption,
  form,
  disabled,
}: {
  payerSearchOption: PayerSearchOption
  form: UseFormReturnType<
    InsuranceCoverageForm,
    (values: InsuranceCoverageForm) => InsuranceCoverageForm
  >
  disabled: boolean
}) => {
  switch (payerSearchOption) {
    case 'member_first_name':
      return (
        <TextInput
          disabled={disabled}
          label='First name'
          placeholder='First name'
          {...form.getInputProps('member_first_name')}
        />
      )
    case 'member_last_name':
      return (
        <TextInput
          disabled={disabled}
          label='Last name'
          placeholder='Last name'
          {...form.getInputProps('member_last_name')}
        />
      )
    case 'member_dob':
      return (
        <DateInput disabled={disabled} label='Birthday' {...form.getInputProps('member_dob')} />
      )
    case 'member_gender':
      return (
        <RadioGroup {...form.getInputProps('member_gender')}>
          <Radio label='Male' value='M' disabled={disabled}></Radio>
          <Radio label='Female' value='F' disabled={disabled}></Radio>
        </RadioGroup>
      )
    case 'member_id':
      return (
        <TextInput
          disabled={disabled}
          label='Member ID'
          placeholder='Member ID'
          {...form.getInputProps('member_id')}
        />
      )
    default:
      throw new Error()
  }
}
export const CoverageSearchOptions = ({
  payerSearchOptions,
  form,
  isLoading,
  handleClickBack,
  handleClickNext,
}: CoverageSearchOptionsProps) => {
  useLifecycle({
    onMount: () => {
      sendTrackEvent('Coverage Search Options Step Visible')
      FullStory.event('Coverage Search Options Step Visible')
    },
  })

  return (
    <ResponsiveBox
      desktop={
        <Stack spacing='lg'>
          <Stack spacing='sm'>
            {payerSearchOptions.map(payerSearchOption => (
              <SearchOptionInput
                key={payerSearchOption}
                disabled={isLoading}
                form={form}
                payerSearchOption={payerSearchOption}
              />
            ))}
          </Stack>
          <Group position='apart'>
            <TertiaryButton
              leftIcon={<ChevronLeftIcon />}
              disabled={isLoading}
              onClick={() => handleClickBack()}
            >
              Back
            </TertiaryButton>
            <PrimaryButton
              disabled={isLoading}
              loading={isLoading}
              onClick={() => handleClickNext()}
            >
              Check my coverage
            </PrimaryButton>
          </Group>
        </Stack>
      }
      mobile={
        <Stack spacing='lg'>
          <Stack spacing='sm'>
            {payerSearchOptions.map(payerSearchOption => (
              <SearchOptionInput
                key={payerSearchOption}
                disabled={isLoading}
                form={form}
                payerSearchOption={payerSearchOption}
              />
            ))}
          </Stack>
          <Stack spacing='sm'>
            <PrimaryButton
              fullWidth
              disabled={isLoading}
              loading={isLoading}
              onClick={() => handleClickNext()}
            >
              Check my coverage
            </PrimaryButton>
            <TertiaryButton
              leftIcon={<ChevronLeftIcon />}
              fullWidth
              disabled={isLoading}
              onClick={() => handleClickBack()}
            >
              Back
            </TertiaryButton>
          </Stack>
        </Stack>
      }
    />
  )
}
