import { z } from 'zod'
import { ApiSchemas } from '../../..'

const ListPayerEnrollmentsReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        payerId: z.string(),
        payerName: z.string().nullable(),
        payerStatus: z.string().nullable(),
        enrollments: z.array(
          z.object({
            clinicianId: z.string(),
          }),
        ),
      }),
    ),
  }),
}

export const PayerEnrollmentsRouteSchemas = {
  'GET /payer-enrollments': ListPayerEnrollmentsReqRes,
} satisfies ApiSchemas
