import { Group, ResponsiveBox, Stack, TitleTwo } from '@shared/components'
import { Achievement } from '@shared/types'
import React from 'react'
import { usePortalQuery } from '../../../common/hooks'
import { BadgesSection } from './badges/BadgesSection'
import { MilestonesSection } from './milestones/MilestonesSection'
export const AchievementsPage = () => {
  const achievementsQuery = usePortalQuery('GET /achievements')
  const achievements: Achievement[] = achievementsQuery.data || []

  return (
    <Stack>
      <TitleTwo>Achievements</TitleTwo>
      <ResponsiveBox
        mobile={
          <Stack>
            <MilestonesSection achievements={achievements} />
            <BadgesSection achievements={achievements} />
          </Stack>
        }
        desktop={
          <Group noWrap>
            <MilestonesSection achievements={achievements} />
            <BadgesSection achievements={achievements} />
          </Group>
        }
      />
    </Stack>
  )
}
