import {
  CalendarIcon,
  ClockIcon,
  EN_DASH,
  ExternalLinkText,
  LinkText,
  List,
  ListItem,
  PhoneIncomingIcon,
  PrimaryButton,
  Stack,
  Text,
  UserIcon,
  VideoIcon,
} from '@shared/components'
import { AppointmentInfo } from '@shared/types'
import { dayjs, phone, zoom } from '@shared/utils'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth, usePortalDims } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { sendTrackEvent } from '../../../common/rudderstack'
import { getFormattedDuration, isCall } from '../../../common/visits'
import { getCalendarName } from './utils'

export const VisitInfo = ({
  location,
  visitType,
  datetime,
  calendar,
  duration,
  id,
  canReschedule,
}: AppointmentInfo) => {
  const { currentUser } = useAuth()
  const { isMobile } = usePortalDims()

  const { zoomUrl, meetingCanceled } = zoom(location)
  const isWelcomeCall = ['Free Consultation Call', 'Returning welcome call'].includes(visitType)
  const visitDate = dayjs(datetime)

  const isZoomCall = !meetingCanceled && !isWelcomeCall

  return (
    <Stack spacing='sm'>
      <List spacing='xs' styles={theme => ({ itemIcon: { color: theme.other.colors.actions[3] } })}>
        <ListItem icon={<CalendarIcon />}>{visitDate.format('MMM D YYYY').toUpperCase()}</ListItem>
        <ListItem icon={<ClockIcon />}>{visitDate.format('h:mma z')}</ListItem>
        {!isWelcomeCall && <ListItem icon={<UserIcon />}>{getCalendarName(calendar)}</ListItem>}
        <ListItem icon={isCall(visitType) ? <PhoneIncomingIcon /> : <VideoIcon />}>
          {getFormattedDuration(duration, visitType)}
        </ListItem>
      </List>
      {!isZoomCall && (
        <Text>{`We’ll give you a call at ${
          phone(currentUser?.data?.personalData.phone).maskedFormat
        }`}</Text>
      )}
      {canReschedule ? (
        <Text>
          {'Need a different date or time? '}
          <LinkText
            component={Link}
            to={`../${routes.portal.index}/${routes.portal.children.reschedule.index}/${id}`}
          >
            Reschedule your visit
          </LinkText>
        </Text>
      ) : (
        <Text mt='sm'>
          {'Text '}
          <ExternalLinkText href='sms:2155852144'>(215) 585{EN_DASH}2144</ExternalLinkText>
          {' to reschedule or cancel'}
        </Text>
      )}
      {isZoomCall && (
        <PrimaryButton
          href={zoomUrl}
          target='_blank'
          component='a'
          rel='noopener noreferrer'
          onClick={() => sendTrackEvent('Join Zoom Clicked')}
          fullWidth={isMobile}
          mt='md'
        >
          Join Zoom
        </PrimaryButton>
      )}
    </Stack>
  )
}
