import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Checkbox,
  CheckboxGroup,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TextInput,
  isAnySelected,
} from '@shared/components'
import { ValueOf } from '@shared/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../routes'
import { WidgetProps } from '../PromptContentWidget'

const alternativeOptions = {
  anonymous_q_a: 'anonymous_q_a',
  oud_quiz: 'oud_quiz',
  newsletter: 'newsletter',
  ask_ec: 'ask_ec',
  other: 'other',
} as const

export type AlternativeGetStartedOptions = ValueOf<typeof alternativeOptions>

type Form = {
  options: AlternativeGetStartedOptions[]
  other: string
}
export const NotReadyAlternativeOptionsWidget = ({ onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const onBack = () => navigate(routes.welcome.index)

  const form = useForm<Form>({
    initialValues: {
      options: [],
      other: '',
    },
    validate: {
      options: isAnySelected(Object.values(alternativeOptions), 'Required'),
      other: (value, values) => {
        if (!values.options.includes('other')) {
          return false
        }
        if (!value) {
          return 'Required'
        }
      },
    },
  })

  const submitHandler = () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }

    onSubmit({
      value: {
        contextKey: 'custom',
      },
      alternative_options_anonymous_q_a: {
        contextKey: 'alternative_options_anonymous_q_a',
        value: form.values.options.includes(alternativeOptions.anonymous_q_a),
      },
      alternative_options_oud_quiz: {
        contextKey: 'alternative_options_oud_quiz',
        value: form.values.options.includes(alternativeOptions.oud_quiz),
      },
      alternative_options_newsletter: {
        contextKey: 'alternative_options_newsletter',
        value: form.values.options.includes(alternativeOptions.newsletter),
      },
      alternative_options_ask_ec: {
        contextKey: 'alternative_options_ask_ec',
        value: form.values.options.includes(alternativeOptions.ask_ec),
      },
      alternative_options_other: {
        contextKey: 'alternative_options_other',
        value: form.values.other || '',
      },
    })
  }

  return (
    <Stack spacing='md'>
      <Stack spacing='md'>
        <CheckboxGroup {...form.getInputProps('options')}>
          <Checkbox
            value={alternativeOptions.anonymous_q_a}
            label='I want to be informed about the next anonymous, online Q&A session with an Ophelia clinician so I can learn more'
          />
          <Checkbox
            value={alternativeOptions.oud_quiz}
            label='I want to take a quick, anonymous quiz to find out if opioids might be impacting me more than I realize'
          />
          <Checkbox
            value={alternativeOptions.newsletter}
            label='I want to receive Ophelia’s community newsletter to read inspiring stories and get expert guidance from clinicians.'
          />
          <Checkbox
            value={alternativeOptions.ask_ec}
            label='I want to speak with an Ophelia enrollment coordinator to ask a few questions'
          />
          <Checkbox value={alternativeOptions.other} label='Something else' />
        </CheckboxGroup>
        {form.values.options.includes(alternativeOptions.other) && (
          <TextInput
            {...form.getInputProps(alternativeOptions.other)}
            label='If you’re not ready to get started, what other tools might you like Ophelia to provide for you?'
            placeholder='1-2 sentences'
          />
        )}
        <ResponsiveBox
          mt='lg'
          mobile={
            <Stack spacing='lg' align='center'>
              <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
                Next
              </PrimaryButton>
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            </Stack>
          }
          desktop={
            <Group position='apart'>
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
              <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                Next
              </PrimaryButton>
            </Group>
          }
        />
      </Stack>
    </Stack>
  )
}
