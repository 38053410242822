import { useForm } from '@mantine/form'
import {
  ResponsiveBox,
  Stack,
  Text,
  TitleTwo,
  useLifecycle,
  validateWith,
} from '@shared/components'
import { InsurancePlanId } from '@shared/types'
import {
  INSURANCE_OTHER_OPTION_ID,
  PayerSearchOption,
  dayjs,
  getPayerIdAndProvider,
  getPayerSearchOptions,
} from '@shared/utils'
import React, { useState } from 'react'
import { isRequired } from '../common/forms'
import * as FullStory from '../common/fullstory'
import { useMyMutation } from '../common/hooks'
import { sendPageEvent } from '../common/rudderstack'
import { CoverageResults } from './CoverageResults'
import { CoverageSearchOptions } from './CoverageSearchOptions'
import { InsuranceSelectStep } from './InsuranceSelectStep'

const StepsData = {
  insurance_select: {
    title: `Let's check your insurance`,
    subtitle: `Find out if your care might be covered.`,
  },
  search_options: {
    title: `We need a little more information to check your coverage`,
    subtitle: `You can find this information on your insurance card or in your insurance provider portal.`,
  },
  coverage_results: {
    title: '',
    subtitle: '',
  },
}

type Steps = keyof typeof StepsData

export type InsuranceCoverageForm = {
  payer: string
  member_id: string
  member_first_name?: string | undefined
  member_last_name?: string | undefined
  member_dob?: string | undefined
  member_gender?: string | undefined
}

export const InsuranceCoverageApp = () => {
  const [step, setStep] = useState<Steps>('insurance_select')
  const [coverageResults, setCoverageResults] = useState<{ isActive: boolean } | undefined>()
  const [payerSearchOptions, setPayerSearchOptions] = useState<PayerSearchOption[]>([])
  const [selectedPayer, setSelectedPayer] = useState('')

  useLifecycle({
    onMount: () => {
      sendPageEvent('Insurance Coverage Page Visited')
      FullStory.event('Insurance Coverage Page Visited')
    },
  })

  const insuranceCoverageForm = useForm<InsuranceCoverageForm>({
    initialValues: {
      payer: '',
      member_id: '',
      member_first_name: '',
      member_last_name: '',
      member_dob: '',
      member_gender: '',
    },
    validate: {
      payer: validateWith(isRequired),
      member_id: validateWith(isRequired),
      member_first_name: (value?: string) => {
        if (payerSearchOptions.includes('member_first_name') && !value) {
          return 'Required'
        }
      },
      member_last_name: (value?: string) => {
        if (payerSearchOptions.includes('member_last_name') && !value) {
          return 'Required'
        }
      },
      member_dob: (value?: string) => {
        if (payerSearchOptions.includes('member_dob') && !value) {
          return 'Required'
        }
      },
      member_gender: (value?: string) => {
        if (payerSearchOptions.includes('member_gender') && !value) {
          return 'Required'
        }
      },
    },
  })

  const handleChange = (value: InsurancePlanId) => {
    if (!value) {
      insuranceCoverageForm.setFieldValue('payer', value)
      setSelectedPayer(value)
      return
    }
    insuranceCoverageForm.setFieldValue('payer', value)
    const payerSearchOptionsById = getPayerSearchOptions(value)
    setPayerSearchOptions(payerSearchOptionsById)
    setSelectedPayer(value)
  }

  const handleClickNext = async () => {
    if (step === 'insurance_select') {
      const payerValidation = insuranceCoverageForm.validateField('payer')
      if (payerValidation.hasError) {
        return
      }
      if (
        getPayerIdAndProvider(insuranceCoverageForm.values.payer as InsurancePlanId)
          .eligiblePayerId === INSURANCE_OTHER_OPTION_ID ||
        payerSearchOptions.length === 0
      ) {
        setCoverageResults({ isActive: false })
        setStep('coverage_results')
      } else {
        setStep('search_options')
      }
    }
    if (step === 'search_options') {
      await handleSubmit()
    }
  }

  const handleClickBack = () => {
    insuranceCoverageForm.reset()
    setCoverageResults(undefined)
    setSelectedPayer('')
    setStep('insurance_select')
  }

  const checkInsuranceCoverage = useMyMutation('POST /insurance-coverage')

  const handleSubmit = async () => {
    const validateForm = insuranceCoverageForm.validate()

    if (validateForm.hasErrors) {
      return
    }
    const formValues = insuranceCoverageForm.values

    if (!formValues.payer || !formValues.member_id) {
      return
    }

    const coverageCheckPayload = {
      payerId: getPayerIdAndProvider(formValues.payer as InsurancePlanId).eligiblePayerId ?? '',
      memberId: formValues.member_id,
      firstName: formValues.member_first_name ?? '',
      lastName: formValues.member_last_name ?? '',
      birthday: formValues.member_dob ? dayjs(formValues.member_dob).format('YYYY-MM-DD') : '',
      gender: formValues.member_gender ?? '',
    }

    const coverageResponse = await checkInsuranceCoverage.mutateAsync(
      {
        data: coverageCheckPayload,
      },
      {
        onError: () => {
          setCoverageResults({ isActive: false })
          setStep('coverage_results')
        },
      },
    )

    setCoverageResults(coverageResponse?.data)
    setStep('coverage_results')
  }

  return (
    <ResponsiveBox
      mobile={
        <Stack spacing='lg'>
          <Stack spacing='xs'>
            <TitleTwo>{StepsData[step]?.title}</TitleTwo>
            <Text>{StepsData[step]?.subtitle}</Text>
          </Stack>
          {step === 'insurance_select' && (
            <InsuranceSelectStep
              isLoading={checkInsuranceCoverage.isLoading}
              form={insuranceCoverageForm}
              handleChange={handleChange}
              selectedPayer={selectedPayer}
              handleClickNext={handleClickNext}
            />
          )}
          {step === 'search_options' && (
            <CoverageSearchOptions
              isLoading={checkInsuranceCoverage.isLoading}
              payerSearchOptions={payerSearchOptions}
              form={insuranceCoverageForm}
              handleClickNext={handleClickNext}
              handleClickBack={handleClickBack}
            />
          )}
          {step === 'coverage_results' && (
            <CoverageResults
              selectedPayer={selectedPayer}
              coverageResults={coverageResults}
              handleClickBack={handleClickBack}
            />
          )}
        </Stack>
      }
      desktop={
        <Stack m='xl'>
          <TitleTwo>{StepsData[step]?.title}</TitleTwo>
          <Text>{StepsData[step]?.subtitle}</Text>
          {step === 'insurance_select' && (
            <InsuranceSelectStep
              isLoading={checkInsuranceCoverage.isLoading}
              form={insuranceCoverageForm}
              handleChange={handleChange}
              selectedPayer={selectedPayer}
              handleClickNext={handleClickNext}
            />
          )}
          {step === 'search_options' && (
            <CoverageSearchOptions
              isLoading={checkInsuranceCoverage.isLoading}
              payerSearchOptions={payerSearchOptions}
              form={insuranceCoverageForm}
              handleClickNext={handleClickNext}
              handleClickBack={handleClickBack}
            />
          )}
          {step === 'coverage_results' && (
            <CoverageResults
              selectedPayer={selectedPayer}
              coverageResults={coverageResults}
              handleClickBack={handleClickBack}
            />
          )}
        </Stack>
      }
    />
  )
}
