import { RemoteConfigEntries, isError } from '@shared/types'
import { useQuery } from 'react-query'
import { isFirebaseError } from '../errors'
import { fetchAndActivateRemoteConfig, getRemoteConfigValue } from '../firebase'
import { logger } from '../logger'

export const useRemoteConfig = <T extends keyof RemoteConfigEntries>({ key }: { key: T }) => {
  return useQuery<RemoteConfigEntries[T]>(
    'remote-config',
    async () => {
      await fetchAndActivateRemoteConfig()
      const value = getRemoteConfigValue(key)
      // Get the value in "stringified" form
      const stringifiedValue = value.asString()
      if (stringifiedValue) {
        try {
          // Return the parsed value so that the type contract's data type is preserved
          return JSON.parse(stringifiedValue)
        } catch (error) {
          logger.error(`Error parsing remote config value: ${stringifiedValue}`, error as Error)
          throw error
        }
      }
    },
    {
      retry: 3,
      onError: error => {
        if (isFirebaseError(error)) {
          logger.error(`Error fetching remote config: ${error.code}`, error)
        } else if (isError(error)) {
          logger.error(`Error fetching remote config: ${error.message}`, error)
        } else {
          logger.error(`Unknown error fetching remote config`)
        }
      },
    },
  )
}
