import {
  ArrowLeftIcon,
  Box,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  emojiMap,
  useLifecycle,
} from '@shared/components'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import * as FullStory from '../../../../src/common/fullstory'
import { currentUserApi } from '../../../common/api'
import { ContactSupport } from '../../../common/components'
import { sendPageEvent, sendTrackEvent } from '../../../common/rudderstack'

const BUTTON_TEXT_TIMEOUT = 3500

type ConfirmationEmailSentProps = {
  submittedEmail: string
  backToSignInPage: () => void
}

export const ConfirmationEmailSent = ({
  submittedEmail,
  backToSignInPage,
}: ConfirmationEmailSentProps) => {
  const initialButtonState = { text: 'Send email again', disabled: false }
  const [buttonState, setButtonState] = useState<{ text: string; disabled: boolean }>(
    initialButtonState,
  )

  useLifecycle({
    onMount: () => {
      sendPageEvent('Confirmation Email Sent Page')
      FullStory.event('Confirmation Email Sent Page Visible')
    },
  })

  const resendConfirmationEmail = useMutation(currentUserApi.resendEmail)
  const resend = async () => {
    sendTrackEvent('Send Email Again Clicked')

    await resendConfirmationEmail.mutateAsync(
      {
        email: submittedEmail,
      },
      {
        onSuccess: () => {
          setButtonState({ text: 'Sent!', disabled: true })
          setTimeout(() => setButtonState(initialButtonState), BUTTON_TEXT_TIMEOUT)
        },
        onError: () => {
          setButtonState({ text: 'Try again', disabled: true })
          setTimeout(() => setButtonState(initialButtonState), BUTTON_TEXT_TIMEOUT)
        },
      },
    )
  }

  return (
    <Box test-id='page:confirm-email'>
      <Stack spacing='sm'>
        <TitleTwo>{`${emojiMap.envelope} Check your email`}</TitleTwo>
        <Text>
          For your security we need to confirm your email. Please follow the steps in the email to
          finish setting up your account and sign in to our portal.
        </Text>
      </Stack>
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg'>
            <PrimaryButton
              fullWidth
              loading={resendConfirmationEmail.isLoading || buttonState.disabled}
              onClick={resend}
            >
              {buttonState.text}
            </PrimaryButton>
            <TertiaryButton fullWidth leftIcon={<ArrowLeftIcon />} onClick={backToSignInPage}>
              Back to sign in page
            </TertiaryButton>
            <ContactSupport />
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={backToSignInPage}>
              Back to sign in page
            </TertiaryButton>
            <PrimaryButton
              loading={resendConfirmationEmail.isLoading || buttonState.disabled}
              onClick={resend}
            >
              {buttonState.text}
            </PrimaryButton>
          </Group>
        }
      />
    </Box>
  )
}
