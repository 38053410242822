import type { FormattedAxiosError } from '@shared/utils'
import * as FullStory from './fullstory'

type SimpleLoggerMethod = (msg: string, ctx?: object) => void

type ExceptionLoggerMethod = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exception: any,
  ctx?: object,
) => void

type Logger = {
  log: SimpleLoggerMethod
  warn: SimpleLoggerMethod
  debug: SimpleLoggerMethod
  info: SimpleLoggerMethod
  error: SimpleLoggerMethod
  exception: ExceptionLoggerMethod
}

const logMessage = (msg: string, ctx: object, level: FullStory.LogLevel) => {
  FullStory.log(level, msg)
  // eslint-disable-next-line no-console
  console[level](msg, ctx)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logException = (exception: any, ctx: object) => {
  try {
    FullStory.log('error', JSON.stringify({ exception, ctx }))
  } catch (_) {
    // eslint-disable-next-line no-console
    console.error('Could not log exception to Fullstory')
  }

  // eslint-disable-next-line no-console
  console.error(exception, ctx)
}

/**
 * The minimal interface for any logger.
 * Each corresponding logger method takes a single string parameter.
 * For unknown parameters that arise from general exceptions, use the `exception` method.
 * The logger implementation is responsible for deciding whether to send logs
 * to a telemetry service (ie, FullStory) or to local console.
 */
export const logger: Logger = {
  log: (msg, ctx) => {
    logMessage(msg, { level: 'log', ...ctx }, 'log')
  },
  warn: (msg, ctx) => {
    logMessage(msg, { level: 'warning', ...ctx }, 'warn')
  },
  debug: (msg, ctx) => {
    logMessage(msg, { level: 'debug', ...ctx }, 'debug')
  },
  info: (msg, ctx) => {
    logMessage(msg, { level: 'info', ...ctx }, 'info')
  },
  error: (msg, ctx) => {
    logMessage(msg, { level: 'error', ...ctx }, 'error')
  },
  exception: (exception, ctx = {}) => {
    logException(exception, ctx)
  },
}

export const logFullstoryError = ({ errorMessage, ...extra }: FormattedAxiosError) => {
  logger.error(errorMessage, { extra })
}
