import {
  TWILIGHT_FONT_FAMILIES,
  TWILIGHT_FONT_FILE_NAMES,
  useMantineTheme,
} from '@shared/components'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React from 'react'
import { Config } from '../../config'

const stripePromise = loadStripe(Config.STRIPE_PUBLISHABLE_KEY)
export const StripeElementsProvider = ({
  clientSecret,
  children,
}: {
  clientSecret: string
  children: React.ReactNode
}) => {
  const theme = useMantineTheme()
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        fonts: [
          {
            src: `url(${new URL(
              `/fonts/${TWILIGHT_FONT_FILE_NAMES.bold}`,
              Config.FONT_CDN_BASE_URL,
            )}) format('woff2')`,
            family: TWILIGHT_FONT_FAMILIES.bold,
            style: 'normal',
          },
        ],
        appearance: {
          theme: 'night',
          variables: {
            fontFamily: TWILIGHT_FONT_FAMILIES.bold,
            colorText: theme.other.colors.text[0],
            colorPrimary: theme.other.colors.actions[0],
          },
        },
      }}
    >
      {children}
    </Elements>
  )
}
