import { Box, TitleTwo, useBanner, useLifecycle } from '@shared/components'
import { states } from '@shared/types'
import React from 'react'
import { SignupProps } from '.'
import { logger } from '../../../common/logger'
import { sendPageEvent } from '../../../common/rudderstack'

export const AcceptVerify = ({ form }: { form: SignupProps['form'] }) => {
  const selectedState = states.find(state => state.abbr === form.values.state)
  const { showBanner } = useBanner()

  useLifecycle({
    onMount: () => {
      showBanner({ type: 'success', label: "Thanks, we've got your number" })
      logger.info('Accept Verify', { tags: { workflow: 'onboarding' } })
      sendPageEvent('Accept Verify')
    },
  })

  return (
    <Box test-id='page:launch-verify'>
      <TitleTwo>
        We&apos;ll text you when we start accepting new patients in {selectedState?.state}
      </TitleTwo>
    </Box>
  )
}
