import { useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  PrimaryButton,
  Stack,
  TextInput,
  validateWith,
  isAllSelected,
} from '@shared/components'
import { name } from '@shared/utils'
import React from 'react'
import { isRequired, isAtleastOneWord } from '../../../common/forms'
import { useAuth, usePortalDims } from '../../../common/hooks'
import { TreatmentConsent } from '../../../documents/TreatmentConsent'
import { TaskStepProps } from './taskStepProps'

const AGREE_TO_CONSENT = 'agree-to-consent'
export const TreatmentConsentStep = ({
  submit,
  saving,
  initialValues,
}: TaskStepProps<'treatment-consent'>) => {
  const { isMobile } = usePortalDims()

  const form = useForm({
    initialValues,
    validate: {
      signature: validateWith(isRequired, isAtleastOneWord),
      checks: isAllSelected([AGREE_TO_CONSENT], 'Required'),
    },
  })
  const { currentUser } = useAuth()

  const onSubmit = () => {
    if (!form.validate().hasErrors) {
      submit('', form.values)
    }
  }

  return (
    <Stack test-id='step:treatment-consent'>
      <TreatmentConsent />
      <Stack>
        <CheckboxGroup {...form.getInputProps('checks')}>
          <Checkbox
            test-id='input:agree-to-consent'
            label='I have read, understand, and agree to this consent for treatment.'
            value={AGREE_TO_CONSENT}
          />
        </CheckboxGroup>
        <TextInput
          test-id='input:signature'
          label='Signed by'
          placeholder='Type your full legal name'
          {...form.getInputProps('signature')}
          onFocus={() => {
            if (!form.values.signature) {
              const patientName = name({
                first: currentUser?.data?.personalData.firstName,
                last: currentUser?.data?.personalData.lastName,
              }).full()

              form.setFieldValue('signature', patientName)
            }
          }}
        />
        <PrimaryButton
          test-id='button:sign'
          fullWidth={isMobile}
          onClick={onSubmit}
          loading={saving}
        >
          Sign and agree
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}
