import { useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  Grid,
  PhoneInput,
  PrimaryButton,
  TextInput,
  validateWith,
} from '@shared/components'
import { EmergencyContactReasoning, PatientEmergencyContact } from '@shared/types'
import { phone, validCharacters } from '@shared/utils'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { currentUserApi } from '../../../common/api'
import { FormLabel, FormSaveSection } from '../../../common/components'
import { isFullName, isPhone, isRequired, isAtleastOneWord } from '../../../common/forms'
import { useAuth } from '../../../common/hooks'

type Form = {
  phoneNumber: string
  relationship: string
  reasoning: EmergencyContactReasoning[]
  fullName?: string
  firstName?: string
  lastName?: string
}

export const EmergencyContactSection = ({
  editing,
  toggleEditing,
}: {
  editing: boolean
  toggleEditing: () => void
}) => {
  const { currentUser } = useAuth()
  const emergencyContact = currentUser?.data?.emergencyContact

  return (
    <>
      {editing ? (
        <EmergencyContactSectionEdit
          toggleEditing={toggleEditing}
          emergencyContact={emergencyContact}
        />
      ) : (
        <Grid>
          <Grid.Col span={12} md={6}>
            <FormLabel>
              {emergencyContact?.fullName
                ? [
                    emergencyContact?.fullName,
                    emergencyContact?.relationship,
                    phone(emergencyContact?.phoneNumber || '').formatted,
                  ]
                : [
                    emergencyContact?.firstName,
                    emergencyContact?.lastName,
                    emergencyContact?.relationship,
                    phone(emergencyContact?.phoneNumber || '').formatted,
                  ]}
            </FormLabel>
          </Grid.Col>
          <Grid.Col span={12}>
            <PrimaryButton mt='md' onClick={() => toggleEditing()}>
              Edit
            </PrimaryButton>
          </Grid.Col>
        </Grid>
      )}
    </>
  )
}

export const EmergencyContactSectionEdit = ({
  toggleEditing,
  emergencyContact,
}: {
  toggleEditing: () => void
  emergencyContact: PatientEmergencyContact | undefined | null
}) => {
  const queryClient = useQueryClient()

  const { getInputProps, validate, values } = useForm<Form>(
    emergencyContact?.fullName
      ? {
          initialValues: {
            relationship: emergencyContact?.relationship ?? '',
            phoneNumber: emergencyContact?.phoneNumber ?? '',
            reasoning: emergencyContact?.reasoning ?? [],
            fullName: emergencyContact?.fullName ?? '',
          },
          validate: {
            fullName: validateWith(isFullName),
            relationship: validateWith(isRequired, isAtleastOneWord),
            phoneNumber: validateWith(isRequired, isPhone),
          },
        }
      : {
          initialValues: {
            relationship: emergencyContact?.relationship ?? '',
            phoneNumber: emergencyContact?.phoneNumber ?? '',
            reasoning: emergencyContact?.reasoning ?? [],
            firstName: emergencyContact?.firstName ?? '',
            lastName: emergencyContact?.lastName ?? '',
          },
          validateInputOnBlur: ['phoneNumber'],
          validate: {
            firstName: validateWith(isRequired, isAtleastOneWord),
            lastName: validateWith(isRequired, isAtleastOneWord),
            relationship: validateWith(isRequired, isAtleastOneWord),
            phoneNumber: validateWith(isRequired, isPhone),
          },
        },
  )

  const updateCurrentUser = useMutation(currentUserApi.update)

  const onSubmit = () => {
    if (validate().hasErrors) {
      return
    }

    const { fullName, firstName, lastName, relationship, phoneNumber } = values

    const emergencyContactObject = fullName
      ? {
          fullName,
          relationship,
          phoneNumber,
        }
      : {
          firstName,
          lastName,
          relationship,
          phoneNumber,
        }

    updateCurrentUser.mutate(
      {
        emergencyContact: emergencyContactObject,
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(['currentUserApi.retrieve'])
          toggleEditing()
        },
      },
    )
  }

  const saving = updateCurrentUser.isLoading

  return (
    <Grid>
      {emergencyContact?.fullName ? (
        <Grid.Col span={12} md={6}>
          <TextInput
            label='Full name'
            placeholder='Full name'
            maxLength={64}
            disabled={saving}
            formatter={validCharacters.name}
            {...getInputProps('fullName')}
          />
        </Grid.Col>
      ) : (
        <>
          <Grid.Col span={12} md={6}>
            <TextInput
              label='First name'
              placeholder='First name'
              maxLength={64}
              disabled={saving}
              formatter={validCharacters.name}
              {...getInputProps('firstName')}
            />
          </Grid.Col>
          <Grid.Col span={12} md={6}>
            <TextInput
              label='Last name'
              placeholder='Last name'
              maxLength={64}
              disabled={saving}
              formatter={validCharacters.name}
              {...getInputProps('lastName')}
            />
          </Grid.Col>
        </>
      )}
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Relationship'
          placeholder='Parent, partner, friend...'
          maxLength={64}
          disabled={saving}
          formatter={validCharacters.name}
          {...getInputProps('relationship')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <PhoneInput label='Phone number' disabled={saving} {...getInputProps('phoneNumber')} />
      </Grid.Col>
      <Grid.Col span={12}>
        <CheckboxGroup
          label='In addition to a medical emergency as determined by my treating clinician, I give Ophelia Health, Inc. permission to to contact the person listed above in case of (check all that apply):'
          {...getInputProps('reasoning')}
        >
          <Checkbox
            value='no_contact_24_hours'
            label='If my care team cannot contact me within 24 hours'
            disabled={saving}
          />
          <Checkbox
            value='no_contact_48_hours'
            label='If my care team cannot contact me within 48 hours'
            disabled={saving}
          />
          <Checkbox
            value='as_determined'
            label='In any emergency or urgent situation as determined by my care team'
            disabled={saving}
          />
        </CheckboxGroup>
      </Grid.Col>
      <Grid.Col span={12}>
        <FormSaveSection loading={saving} onSubmit={onSubmit} onCancel={toggleEditing} />
      </Grid.Col>
    </Grid>
  )
}
