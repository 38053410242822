import {
  Alert,
  CreditCardIcon,
  Divider,
  PlusCircleIcon,
  Stack,
  TertiaryButton,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { toTime } from '@shared/utils'
import debounce from 'lodash/debounce'
import React, { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { patientApi } from '../../../common/api'
import { useAuth, usePortalMutation, usePortalQuery } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { StripePaymentMethodItem } from './StripePaymentMethodItem'

export const StripePaymentCardSection = () => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()
  const { data, refetch: refetchPaymentMethods } = usePortalQuery('GET /payment/methods')

  const paymentMethods = data?.paymentMethods

  const { current: debouncedSetDefaultPaymentMethod } = useRef(
    debounce(
      (paymentMethodId: string) =>
        setDefaultPaymentMethodMutation.mutateAsync({ data: { paymentMethodId } }),
      toTime('1 second').ms(),
    ),
  )
  const { current: debouncedRefetchPaymentMethods } = useRef(
    debounce(() => refetchPaymentMethods(), toTime('1 second').ms()),
  )

  useLifecycle({
    onUnmount: () => {
      // In case the user navigates away before the debounce has finished, run the update
      return debouncedSetDefaultPaymentMethod.flush()
    },
  })

  const setDefaultPaymentMethodMutation = usePortalMutation('POST /payment/methods/default', {
    onSettled: () => {
      // This needs to be debounced to ensure that the payment methods are only refetched after the final mutation
      void debouncedRefetchPaymentMethods()
    },
  })

  const isDefaultOnChange = (checked: boolean, paymentMethodId: string) => {
    if (checked) {
      // Optimistically update the UI with the new default payment method
      const previousDefaultPaymentMethod = paymentMethods?.find(
        paymentMethod => paymentMethod.isDefault,
      )
      const newQueryData = paymentMethods?.map(paymentMethod => {
        if (paymentMethod.id === paymentMethodId) {
          return { ...paymentMethod, isDefault: true }
        }
        if (paymentMethod.id === previousDefaultPaymentMethod?.id) {
          return { ...paymentMethod, isDefault: false }
        }
        return paymentMethod
      })
      const [queryKey] = patientApi.getQuery('GET /payment/methods')
      queryClient.setQueryData(queryKey, newQueryData)
      void debouncedSetDefaultPaymentMethod(paymentMethodId)
    }
  }

  return (
    <Stack>
      <TitleTwo>
        {currentUser?.data?.onlyOnePaymentMethodAllowed ? 'Payment card' : 'Payment cards'}
      </TitleTwo>
      {data?.isPaymentMethodOptional && (
        <Alert
          test-id='alert:payment-method-optional'
          icon={<CreditCardIcon />}
          variant='secondary'
        >
          {data.paymentMethodOptionalMessage}
        </Alert>
      )}
      {paymentMethods?.map((paymentMethod, index) => (
        <>
          <StripePaymentMethodItem
            paymentMethod={paymentMethod}
            key={paymentMethod.id}
            showRemoveButton={paymentMethods.length > 1}
            isDefaultOnChange={(checked: boolean) => {
              isDefaultOnChange(checked, paymentMethod.id)
            }}
          />
          {index === paymentMethods.length - 1 ? null : (
            <Divider key={`${paymentMethod.id}_divider`} />
          )}
        </>
      ))}
      {currentUser?.data?.onlyOnePaymentMethodAllowed ? null : (
        <Stack align='center'>
          <TertiaryButton
            leftIcon={<PlusCircleIcon />}
            component={Link}
            to={`${routes.portal.index}/${routes.portal.children.billing.index}/${routes.portal.children.billing.children.paymentMethods.index}${routes.portal.children.billing.children.paymentMethods.children.add}`}
          >
            Payment card
          </TertiaryButton>
        </Stack>
      )}
    </Stack>
  )
}
