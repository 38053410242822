/*
 * Note: when we send attribute keys to our feature
 * flagging system, they need to be sufficiently vague
 * that no one could infer what Ophelia does from the
 * attribute name. For example, use `ct` instead of
 * `careTransfer`.
 *
 * For internal purposes, we can use more descriptive
 * attribute keys.
 */
export const vaguePatientAttributeKeys = ['ct'] as const

export type VaguePatientAttributeKey = (typeof vaguePatientAttributeKeys)[number]

export const descriptivePatientAttributeKeys = ['is_care_transfer'] as const

export type DescriptivePatientAttributeKey = (typeof descriptivePatientAttributeKeys)[number]

// Mapping of descriptive attribute names to vague keys
export const patientAttributeKeyMapping: {
  [key in DescriptivePatientAttributeKey]: VaguePatientAttributeKey
} = {
  is_care_transfer: 'ct',
}

export type PatientAttribute = {
  attributeName: DescriptivePatientAttributeKey
  attributeValue: string | boolean | number
}

/*
 * Patients will be sorted into test or control cohorts for each
 * experiment once they have the requisite attributes stored in
 * our feature flagging system. If the patient is not yet sorted
 * into a cohort, we will return 'not_sorted' or undefined
 */
export type ExperimentCohortOption = 'test' | 'control' | 'not_sorted'

/*
 * To ensure that a patient is in an experiment cohort, we'll check
 * that the cohort is either `test` or `control`
 */
export const experimentCohorts = ['test', 'control'] as const

export type ExperimentCohort = (typeof experimentCohorts)[number]

/*
 * Experiments are a subset of feature flags. We will store a list
 * of experiments here so that we can fetch values for experiments
 * that patients are missing values for
 */
export const experimentNames = [''] as const

export type ExperimentName = (typeof experimentNames)[number]
