import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Checkbox,
  CheckboxGroup,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  Select,
  Stack,
  TertiaryButton,
  TextInput,
  isAllSelected,
  validateWith,
} from '@shared/components'
import { states } from '@shared/types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { isAtleastOneWord, isEmail, isInactiveState, isPhone, isRequired } from '../../../forms'
import { useReferralPartner } from '../../../hooks/use-partner'
import { routes } from '../../../routes'
import { WidgetProps } from '../PromptContentWidget'

enum AcknowledgementValues {
  Email = 'email',
}

type ReferralContactFormType = {
  email: string
  state: string
  firstName: string
  phone: string
  acknowledgement: string[]
}
export const ReferralContactForm = ({ onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const { partnerPath } = useReferralPartner()
  const onBack = () => navigate(`${routes.welcome.index}${partnerPath}`)

  const form = useForm<ReferralContactFormType>({
    validateInputOnBlur: ['firstName', 'email', 'referringProviderEmail', 'phone', 'state'],
    initialValues: {
      email: '',
      firstName: '',
      phone: '',
      state: '',
      acknowledgement: [],
    },
    validate: {
      firstName: validateWith(isRequired, isAtleastOneWord),
      email: validateWith(isRequired, isEmail),
      phone: validateWith(isRequired, isPhone),
      state: validateWith(isRequired, isInactiveState),
      acknowledgement: isAllSelected(Object.values(AcknowledgementValues), 'Required'),
    },
  })

  const submitHandler = () => {
    if (form.validate().hasErrors) {
      return
    }

    onSubmit({
      value: {
        contextKey: 'custom',
        value: form.values.email,
      },
      referral_email: {
        contextKey: 'custom',
        value: form.values.email,
      },
      referral_first_name: {
        contextKey: 'custom',
        value: form.values.firstName,
      },
      referral_phone: {
        contextKey: 'custom',
        value: form.values.phone,
      },
      referral_state: {
        contextKey: 'custom',
        value: form.values.state,
      },
      email_acknowledgement: {
        contextKey: 'custom',
        value: true,
      },
    })
  }

  return (
    <Stack spacing='md'>
      <TextInput
        label="Referral's email address"
        placeholder='Email address'
        {...form.getInputProps('email')}
      />
      <TextInput
        label="Referral's first name"
        placeholder='First name'
        {...form.getInputProps('firstName')}
      />
      <PhoneInput
        label="Referral's phone number"
        {...form.getInputProps('phone')}
        explanation="We'll only use this number to call your referral if they don't respond to our email outreach"
      />
      <Select
        clearable
        placeholder='Select one...'
        label="Referral's state of residence"
        data={[
          ...states
            .filter(state => state.active)
            .map(state => ({
              value: state.abbr,
              label: state.state,
            })),
          { value: 'other', label: 'Other' },
        ]}
        {...form.getInputProps('state')}
      />
      <CheckboxGroup {...form.getInputProps('acknowledgement')}>
        <Checkbox
          value={AcknowledgementValues.Email}
          label='I acknowledge that Ophelia will reach out to the patient and mention that I referred them'
        />
      </CheckboxGroup>
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' align='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Next
            </PrimaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
              Next
            </PrimaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
