import { CheckIcon, List, ListItem, ListProps } from '@shared/components'
import React from 'react'

export const CardChecklist = ({
  list,
  ...rest
}: { list: React.ReactNode[] } & Omit<ListProps, 'children'>) => {
  const listMap = list.map((item, i) => (
    <ListItem
      // eslint-disable-next-line react/no-array-index-key
      key={i}
    >
      {item}
    </ListItem>
  ))

  return (
    <List
      {...rest}
      icon={<CheckIcon />}
      styles={theme => ({ itemIcon: { color: theme.other.colors.actions[0] } })}
    >
      {listMap}
    </List>
  )
}
