import { z } from 'zod'
import { ApiSchemas } from '../../..'

/*
 * PostIngestEventReqRes accepts a very simplified version of a Rudderstack event
 * in its request body payload.
 *
 * For now, this is only used to store and sometimes re-calculate a site visitor's
 * lead score.
 *
 * The site visitor scoring model only requires event name and userKey.
 *
 * A userKey can be any unique identifier for a user, in the case of Rudderstack
 * it would be their anonymousId.
 *
 * If calculateSiteVisitorScore is set to true, the API will ALSO re-calculate the
 * visitor score and return it in the response.
 */
export const PostSaveSiteVisitorEventAndScoreReqRes = {
  req: z.object({
    body: z.object({
      payload: z.object({
        event: z.string(),
        type: z.enum(['track', 'page']),
        userKey: z.string(),
        datetime: z.string(),
      }),
      calculateSiteVisitorScore: z.boolean().optional(),
    }),
  }),
  res: z.object({
    data: z.object({
      // siteVisitorScore only returned if calculateSiteVisitorScore flag is true
      siteVisitorScore: z.number().nullable(),
    }),
  }),
  requestSchemaStrictValidation: true,
}

export const EventRouteSchemas = {
  'POST /events/site-visitor': PostSaveSiteVisitorEventAndScoreReqRes,
} satisfies ApiSchemas
