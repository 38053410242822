import { InsuranceModel } from '.'
import { InsuranceData, InsuranceQueueStatus } from './api'

export const insuranceInReviewStatus: (InsuranceQueueStatus | undefined)[] = [
  InsuranceQueueStatus.New,
]

export const ELIGIBLE_ACTIVE_PLAN_CODES = ['1', '2', '3', '4', '5']
export type InsurancePlanId = '' | 'other_none_of_these' | `${string}__${string}`

export const hasMedicaidPlanType = (insurance: {
  basicInsuranceData: { planType?: InsuranceData['planType'] } | null
}) => {
  return insurance.basicInsuranceData?.planType === 'MC'
}

export const hasMedicarePlanType = (insurance: InsuranceModel) => {
  return insurance.basicInsuranceData?.planType === 'MB'
}

export const hasCommercialPlanType = (insurance: InsuranceModel) => {
  return Boolean(
    insurance.basicInsuranceData?.planType &&
      insurance.basicInsuranceData?.planType !== 'MC' &&
      insurance.basicInsuranceData?.planType !== 'MB',
  )
}

export const MEDICARE_PLAN_TYPES = ['MB'] as const
export const MEDICAID_PLAN_TYPES = ['MC'] as const
export const COMMERCIAL_PLAN_TYPES = ['12', '13', '14', 'CH', 'CI', 'FI', 'HM', 'VA', 'WC'] as const
