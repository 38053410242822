import {
  ArrowLeftIcon,
  Group,
  PhoneInput,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TitleTwo,
  useLifecycle,
} from '@shared/components'
import { states } from '@shared/types'
import React from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import { SignupProps } from '.'
import { patientApi } from '../../../common/api'
import { useScrollIntoViewOnLayoutEffect } from '../../../common/hooks'
import { logger } from '../../../common/logger'
import { routes } from '../../../common/routes'
import { sendPageEvent } from '../../../common/rudderstack'

export const LaunchNotify = ({ form, setCurrentStep }: SignupProps) => {
  const { error: phoneInputError, ...phoneInputProps } = form.getInputProps('phoneNumber')

  const { targetRef } = useScrollIntoViewOnLayoutEffect({ dependencyList: [form.values.state] })

  const selectedState = states.find(state => state.abbr === form.values.state)

  const savePhone = useMutation(patientApi.getMutation('POST /forms/waitlist'))

  const onSubmit = () => {
    const { hasError } = form.validateField('phoneNumber')
    if (!hasError) {
      savePhone.mutate(
        { data: { state: form.values.state, phone: form.values.phoneNumber } },
        { onSuccess: () => setCurrentStep('launch-verify') },
      )
    }
  }

  useLifecycle({
    onMount: () => {
      logger.info('Launch Notify', { tags: { workflow: 'onboarding' } })
      sendPageEvent('Launch Notify')
    },
  })

  return (
    <Stack spacing='lg' test-id='content:launch-notify' ref={targetRef}>
      <TitleTwo>Get notified when we launch in {selectedState?.state}</TitleTwo>
      <PhoneInput
        test-id='input:phone-number'
        label='Phone number'
        error={phoneInputError}
        explanation={`By clicking 'Next', you consent for us to send you texts. Standard rates apply.`}
        {...phoneInputProps}
      />
      <ResponsiveBox
        mobile={
          <Stack spacing='lg'>
            <Stack spacing='sm'>
              <PrimaryButton
                test-id='button:next@mobile'
                fullWidth
                onClick={onSubmit}
                loading={savePhone.isLoading}
              >
                Next
              </PrimaryButton>
            </Stack>
            <TertiaryButton
              test-id='button:back@mobile'
              fullWidth
              leftIcon={<ArrowLeftIcon />}
              component={Link}
              to={routes.welcome.index}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              component={Link}
              to={routes.welcome.index}
            >
              Back
            </TertiaryButton>
            <PrimaryButton
              test-id='button:next@desktop'
              onClick={onSubmit}
              loading={savePhone.isLoading}
            >
              Next
            </PrimaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
