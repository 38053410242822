import { autoUpdate, computePosition } from '@floating-ui/dom'
import { matchesSearch } from '@shared/utils'
import { MentionOptions } from '@tiptap/extension-mention'
import { PluginKey } from '@tiptap/pm/state'
import { ReactRenderer } from '@tiptap/react'
import { MentionData } from '.'
import { MentionList, MentionListForwardRef } from './MentionList'

const MAXIMUM_RESULTS_LENGTH = 6

export const suggestion = (mentionData: MentionData[]): MentionOptions['suggestion'] => ({
  items: ({ query }: { query: string }) => {
    return (
      mentionData
        // Filter if any word in the starts with the query - allows for first name or last name matching
        .filter(item => matchesSearch({ itemValue: item.label, searchValue: query }))
        .slice(0, MAXIMUM_RESULTS_LENGTH)
    )
  },
  pluginKey: new PluginKey('userMention'),
  render: () => {
    let reactRenderer: ReactRenderer<MentionListForwardRef> | undefined
    let cleanup: (() => void) | undefined

    return {
      onStart(props) {
        if (!props.clientRect) {
          return
        }

        reactRenderer = new ReactRenderer(MentionList, { props, editor: props.editor })
        const htmlElement = reactRenderer.element as HTMLElement
        // Add the element to the DOM, floating-ui will set the styling
        document.body.appendChild(htmlElement)

        const virtualElement = {
          getBoundingClientRect() {
            return props.clientRect?.() || new DOMRect(0, 0)
          },
        }

        cleanup = autoUpdate(virtualElement, htmlElement, () => {
          void computePosition(virtualElement, htmlElement, { placement: 'bottom-start' }).then(
            ({ x, y }) => {
              Object.assign(htmlElement.style, {
                left: `${x}px`,
                top: `${y}px`,
                position: 'absolute',
                zIndex: 300,
              })
            },
          )
        })
      },

      onUpdate(props) {
        reactRenderer?.updateProps(props)
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          reactRenderer?.updateProps({ ...props, hide: true })
          return true
        }

        if (reactRenderer?.ref) {
          return reactRenderer.ref.onKeyDown(props)
        }

        return false
      },

      onExit() {
        try {
          cleanup?.()
          if (reactRenderer) {
            document.body.removeChild(reactRenderer.element)
            reactRenderer.destroy()
          }
        } catch {
          // If cannot remove, it's because it doesn't exist
        }
      },
    }
  },
})
