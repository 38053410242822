import { Select, SelectProps } from '@shared/components'
import { getTimeZones } from '@shared/utils'
import React from 'react'

export const TimeZoneSelect = (props: Omit<SelectProps, 'data' | 'placeholder' | 'searchable'>) => {
  return (
    <Select
      searchable
      label='Time zone'
      placeholder='Select one...'
      data={getTimeZones().map(({ value, label, time }) => ({
        value,
        label: `${label} - ${time}`,
      }))}
      {...props}
    />
  )
}
