import { z } from 'zod'
import { ApiSchemas } from '../../..'

const MessageEntry = z.object({
  externalId: z.string(),
  // If datetime is null, it means the message is not yet fully sent
  datetime: z.string().nullable(),
  body: z.string(),
  author: z.string().optional(),
  direction: z.enum(['inbound', 'outbound']),
  status: z.enum(['scheduled', 'sending', 'sent', 'failed', 'canceled']),
  errorMessage: z.string().optional(),
  media: z.array(
    z.object({ externalId: z.string(), url: z.string(), type: z.enum(['image', 'blob']) }),
  ),
})

const SmsEntry = MessageEntry

const CallEntry = z.object({
  externalId: z.string(),
  startTime: z.string(),
  duration: z.number(),
  author: z.string().optional(),
  direction: z.enum(['inbound', 'outbound']),
  status: z.enum([
    'queued',
    'ringing',
    'in-progress',
    'completed',
    'busy',
    'failed',
    'no-answer',
    'canceled',
  ]),
  errorMessage: z.string().optional(),
})

const EmailEntry = MessageEntry.merge(
  z.object({
    threadId: z.string(),
  }),
)

const GetSmsCommunicationsForContactReqRes = {
  req: z.object({
    query: z
      .object({
        startDatetime: z.string().optional(),
        endDatetime: z.string().optional(),
      })
      .and(
        z
          .object({
            patientId: z.string(),
          })
          .or(
            z.object({
              phoneNumber: z.string(),
            }),
          ),
      ),
  }),
  res: z.object({
    data: z.object({
      messages: z.array(SmsEntry),
    }),
  }),
}

const GetCallsCommunicationsForContactReqRes = {
  req: z.object({
    query: z
      .object({
        startDatetime: z.string().optional(),
        endDatetime: z.string().optional(),
      })
      .and(
        z
          .object({
            patientId: z.string(),
          })
          .or(
            z.object({
              phoneNumber: z.string(),
            }),
          ),
      ),
  }),
  res: z.object({
    data: z.object({
      calls: z.array(CallEntry),
    }),
  }),
}

const PostSmsCommunicationsForContactReqRes = {
  req: z.object({
    body: z
      .object({
        body: z.string(),
        // attachmentFilePath is the GCS-bucket URL of a file uploaded using POST /communications/sms/attachment
        attachmentFilePath: z.string().optional(),
      })
      .and(
        z
          .object({
            patientId: z.string(),
          })
          .or(
            z.object({
              phoneNumber: z.string(),
            }),
          ),
      ),
  }),
  res: z.object({
    data: z.null(),
  }),
}

/**
 * This endpoint requests an upload URL for a file
 * meant to be used as an attachment in a SMS message.
 *
 * The GCS file created during this endpoint will
 * have a random UUID as its name, and will be stored
 * in the following path in our default storage bucket:
 * /employees/:employeeId/attachments/sms/:random-file-id
 *
 * The uploadUrl is valid for one minute.
 */
const PostPrepareSmsAttachmentReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.object({
      filePath: z.string(),
      uploadUrl: z.string(),
    }),
  }),
}

const GetEmailCommunicationsForContactReqRes = {
  req: z.object({
    query: z
      .object({
        startDatetime: z.string().optional(),
        endDatetime: z.string().optional(),
      })
      .and(
        z.union([
          z.object({
            patientId: z.string(),
          }),
          z.object({
            email: z.string(),
          }),
        ]),
      ),
  }),
  res: z.object({
    data: z.object({
      messages: z.array(EmailEntry),
    }),
  }),
}

export const CommunicationsRouteSchemas = {
  'GET /communications/calls': GetCallsCommunicationsForContactReqRes,
  'GET /communications/email': GetEmailCommunicationsForContactReqRes,
  'GET /communications/sms': GetSmsCommunicationsForContactReqRes,
  'POST /communications/sms': PostSmsCommunicationsForContactReqRes,
  'POST /communications/sms/attachment': PostPrepareSmsAttachmentReqRes,
} satisfies ApiSchemas
