// eslint-disable-next-line no-restricted-imports
import { MantineTheme } from '@mantine/core'
import {
  NotificationProps as MantineNotificationProps,
  showNotification as mantineShowNotification,
} from '@mantine/notifications'

type NotificationProps = MantineNotificationProps & {
  variant: NotificationVariant
  soundUrl?: string
}

export type NotificationVariant = 'success' | 'error' | 'warning' | 'neutral'

const getVariantColor = (variant: NotificationVariant, theme: MantineTheme) => {
  switch (variant) {
    case 'success':
      return theme.other.colors.success[0]
    case 'error':
      return theme.other.colors.error[0]
    case 'warning':
      return theme.other.colors.warning[0]
    case 'neutral':
      return theme.other.colors.actions[0]
  }
}

const getBackgroundColor = (variant: NotificationVariant, theme: MantineTheme) => {
  switch (variant) {
    case 'success':
      return theme.other.colors.success[1]
    case 'error':
      return theme.other.colors.error[1]
    case 'warning':
      return theme.other.colors.warning[1]
    case 'neutral':
      return theme.other.colors.background[1]
  }
}

const getTextColor = (
  variant: NotificationVariant,
  theme: MantineTheme,
  type: 'title' | 'description',
) => {
  if (type === 'description' || variant === 'neutral') {
    return theme.other.colors.text[1]
  }
  return theme.other.colors.text[0]
}

const ACTIVE_TIME_MS = 5000

export const showNotification = ({
  id = '',
  title,
  message,
  autoClose = ACTIVE_TIME_MS,
  variant,
  soundUrl,
}: NotificationProps) => {
  mantineShowNotification({
    id,
    title,
    message,
    autoClose,
    py: 'sm',
    px: 'sm',
    styles: theme => ({
      root: {
        '::before': {
          backgroundColor: getVariantColor(variant, theme),
        },
        borderColor: theme.other.colors.background[3],
        borderRadius: `calc(${theme.other.sizes.border.md} * 4)`,
        borderWidth: theme.other.sizes.border.md,
        backgroundColor: getBackgroundColor(variant, theme),
      },
      body: {
        paddingLeft: theme.other.sizes.padding.md,
      },
      title: {
        color: getTextColor(variant, theme, 'title'),
        fontSize: theme.fontSizes.md,
        fontWeight: 'bold',
      },
      description: {
        color: getTextColor(variant, theme, 'title'),
        fontSize: theme.fontSizes.md,
      },
      closeButton: {
        color: theme.other.colors.text[0],
        border: theme.other.sizes.border.md,
        position: 'absolute',
        top: 8,
        right: 8,
      },
    }),
  })

  if (soundUrl) {
    const alertSound = new Audio(soundUrl)
    alertSound.muted = false
    void alertSound.play()
  }
}

export { hideNotification } from '@mantine/notifications'
