import { Box, Text } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { AddressStep } from './AddressStep'
import { PersonalInfoStep } from './PersonalInfoStep'
import { TaskHeader } from './TaskHeader'
import { usePatientFormTask } from './use-patient-task'

export const ProfileTask = () => {
  const { payload, status, currentStep, submit, isSaving } = usePatientFormTask({
    type: 'profile',
    totalSteps: 2,
    breadcrumb: 'Finish profile',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <Box test-id='page:profile-task'>
      <TaskHeader title={`Let's finish up your profile`} current={currentStep} total={2}>
        <Text>You&apos;ll be able to edit most of this information later.</Text>
      </TaskHeader>
      {currentStep === 1 && (
        <PersonalInfoStep initialValues={payload} submit={submit} saving={isSaving} />
      )}
      {currentStep === 2 && (
        <AddressStep initialValues={payload} submit={submit} saving={isSaving} />
      )}
    </Box>
  )
}
