/**
 * The calendar name is usually the clinician name, which we
 * want to show here. It can be a few different things for the
 * Welcome Call though, so adding a catch here to standardize
 * that to a user-friendly name.
 */
export const getCalendarName = (calendar: string) => {
  // TODO find a way to use consts here
  if (['Consultation Call Queue', 'Call support'].includes(calendar)) {
    return 'Enrollment Team'
  }
  return calendar
}
