import { Text, Box, Stack } from '@shared/components'
import React from 'react'

export const Card = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <Stack
      spacing={0}
      sx={theme => ({
        borderRadius: theme.radius.sm,
        borderColor: theme.other.colors.background[1],
        borderWidth: theme.other.sizes.border.md,
        borderStyle: 'solid',
        // Fixes issues with text not being contained
        overflow: 'hidden',
      })}
    >
      <Box
        sx={theme => ({
          padding: `calc(${theme.spacing.xs} * 3)`,
          backgroundColor: theme.other.colors.background[1],
        })}
      >
        <Text bold>{title}</Text>
      </Box>
      <Stack p='md'>{children}</Stack>
    </Stack>
  )
}
