import { z } from 'zod'
import { ApiSchemas } from '../../..'
import { PAYER_CONFIG_CATEGORIES } from '../../../payerConfigs'
import { nonEmptyArray, nonEmptyString } from '../../../zodUtils'

const PlanTypes = z.enum(['medicare', 'medicaid', 'commercial'])
const RenderingProvider = z.enum(['pc_on_account'])
const ServiceLineType = z.enum(['1_to_3_months', '4_months', 'intake', 'subsequent'])

const ListPayerConfigsReqRes = {
  req: z.object({}),
  res: z.object({
    data: z.array(
      z.object({
        oid: z.string(),
        name: z.string(),
        changePayerId: z.string(),
        eligiblePayerName: z.string(),
        category: z.enum(PAYER_CONFIG_CATEGORIES),
        planTypes: z.array(PlanTypes),
      }),
    ),
  }),
}

const GetPayerConfigReqRes = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      oid: z.string(),
      name: z.string(),
      changePayerId: z.string(),
      eligiblePayerId: z.string(),
      eligiblePayerName: z.string(),
      category: z.enum(PAYER_CONFIG_CATEGORIES),
      renderingProvider: RenderingProvider,
      planTypes: z.array(PlanTypes),
      placeOfService: z.string(),
      serviceLines: z.array(
        z.object({
          planType: PlanTypes,
          code: z.string(),
          amount: z.string(),
          modifier: z.string().optional(),
          type: ServiceLineType,
        }),
      ),
    }),
  }),
}

const UpdatePayerConfigReqRes = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
    body: z
      .object({
        name: nonEmptyString,
        changePayerId: nonEmptyString,
        eligiblePayerId: nonEmptyString,
        eligiblePayerName: nonEmptyString,
        category: z.enum(PAYER_CONFIG_CATEGORIES),
        renderingProvider: RenderingProvider,
        planTypes: nonEmptyArray(PlanTypes),
        placeOfService: nonEmptyString,
        serviceLines: nonEmptyArray(
          z.object({
            planType: PlanTypes,
            code: nonEmptyString,
            amount: nonEmptyString,
            modifier: z.string().optional(),
            type: ServiceLineType,
          }),
        ),
      })
      .partial(),
  }),
  res: z.object({
    data: z.null(),
  }),
  requestSchemaStrictValidation: true,
}

const CreatePayerConfigReqRes = {
  req: z.object({
    body: z.object({
      name: nonEmptyString,
      changePayerId: nonEmptyString,
      eligiblePayerId: nonEmptyString,
      eligiblePayerName: nonEmptyString,
      category: z.enum(PAYER_CONFIG_CATEGORIES),
      renderingProvider: RenderingProvider,
      planTypes: nonEmptyArray(PlanTypes),
      placeOfService: nonEmptyString,
      serviceLines: nonEmptyArray(
        z.object({
          planType: PlanTypes,
          code: nonEmptyString,
          amount: nonEmptyString,
          modifier: z.string().optional(),
          type: ServiceLineType,
        }),
      ),
    }),
  }),
  res: z.object({
    data: z.object({
      id: z.string(),
    }),
  }),
  requestSchemaStrictValidation: true,
}

const DeletePayerConfigReqRes = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const PayerConfigsRouteSchemas = {
  'GET /payer-configs': ListPayerConfigsReqRes,
  'GET /payer-configs/:id': GetPayerConfigReqRes,
  'POST /payer-configs': CreatePayerConfigReqRes,
  'PUT /payer-configs/:id': UpdatePayerConfigReqRes,
  'DELETE /payer-configs/:id': DeletePayerConfigReqRes,
} satisfies ApiSchemas
