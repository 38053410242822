import { useForm, UseFormReturnType } from '@mantine/form'
import { Box, skipIfOtherField, useBanner, useLifecycle, validateWith } from '@shared/components'
import { phone as formatPhone } from '@shared/utils'
import React, { useCallback, useEffect } from 'react'
import { useMutation, UseMutationResult } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { currentUserApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { isPhone, isRequired } from '../../../common/forms'
import { useReferralPartner } from '../../../common/hooks/use-partner'
import { logger } from '../../../common/logger'
import { SignUpForm } from '../types'
import { Verification } from '../Verification'
import { AcceptVerify } from './AcceptVerify'
import { LaunchVerify } from './LaunchVerify'
import { Residence } from './Residence'

export const signupSteps = ['residence', 'verification', 'launch-verify', 'accept-verify'] as const

export type SignupStep = (typeof signupSteps)[number]
const STEP_QUERY_PARAM = 'step'

export type SignupProps = {
  setCurrentStep: (step: SignupStep) => void
  form: UseFormReturnType<SignUpForm>
  signInWithPhoneNumber: UseMutationResult<void, unknown, string, unknown>
}

export const Signup = () => {
  const { hideBanner } = useBanner()
  const [searchParams, setSearchParams] = useSearchParams()
  const { isPartnerRoute, partner } = useReferralPartner()

  useLifecycle({
    onMount: () => logger.info('Signup', { tags: { workflow: 'onboarding' } }),
  })

  const currentStep = searchParams.get(STEP_QUERY_PARAM) as SignupStep
  const setCurrentStep = useCallback(
    (step: SignupStep, options: { replace?: boolean } = { replace: false }) => {
      setSearchParams({ [STEP_QUERY_PARAM]: step }, options)
    },
    [setSearchParams],
  )

  const form = useForm<SignUpForm>({
    initialValues: {
      state: partner?.states?.length === 1 ? partner.states[0]! : '',
      phoneNumber: '',
      phoneNumberSkipped: false,
    },
    validate: {
      state: validateWith(isRequired),
      phoneNumber: validateWith(
        skipIfOtherField('phoneNumberSkipped', 'is', true),
        isRequired,
        isPhone,
      ),
    },
  })

  useEffect(() => {
    if (currentStep !== 'residence' && (!form.values.state || !form.values.phoneNumber)) {
      setCurrentStep('residence', { replace: true })
    }
  }, [currentStep, form.values.phoneNumber, form.values.state, setCurrentStep, isPartnerRoute])

  const { setValues, setFieldValue, reset } = form

  useEffect(() => {
    // Clean information at step
    if (currentStep === 'residence') {
      reset()
    }

    // Clean information at step
    if (currentStep === 'verification') {
      setFieldValue('verificationCode', '')
    }

    // Cleans up any leftover banner from the previous step
    return hideBanner
  }, [currentStep, hideBanner, reset, setFieldValue, setValues, isPartnerRoute])

  const signInWithPhoneNumber = useMutation((phone: string) =>
    currentUserApi.requestOTP({ phone: formatPhone(phone).normalized }),
  )

  if (currentStep === 'residence') {
    return (
      <Box test-id='page:sign-up'>
        <Residence
          onlyShowLicensedStates={isPartnerRoute}
          setCurrentStep={setCurrentStep}
          form={form}
          signInWithPhoneNumber={signInWithPhoneNumber}
        />
      </Box>
    )
  }

  if (currentStep === 'accept-verify') {
    return <AcceptVerify form={form} />
  }

  if (currentStep === 'launch-verify') {
    return <LaunchVerify form={form} />
  }

  if (currentStep === 'verification') {
    return (
      <Verification
        phoneNumber={form.values.phoneNumber}
        signInWithPhoneNumber={signInWithPhoneNumber}
      />
    )
  }

  return <Skeletons />
}
