import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  ResponsiveBox,
  Stack,
  TertiaryButton,
} from '@shared/components'
import {
  PromptContentSingleSelect as PromptContentSingleSelectType,
  PromptResponseContextKey,
  PromptResponseModel,
} from '@shared/types'
import React from 'react'
import { isRequired } from '../../forms'

type Props = {
  value: PromptResponseContextKey | undefined
  options: PromptContentSingleSelectType['options']
  onSubmit: (data: PromptResponseModel['payload']) => void
  onBack: (() => void) | undefined
}

type Form = {
  contextKey: PromptResponseContextKey | undefined
}

export const PromptContentSingleSelect = (props: Props) => {
  const form = useForm<Form>({
    initialValues: { contextKey: props.value },
    validate: { contextKey: isRequired },
  })

  const onSubmit = () => {
    if (form.validate().hasErrors) {
      return
    }

    props.onSubmit({ value: { contextKey: form.values.contextKey as PromptResponseContextKey } })
  }

  return (
    <Box test-id='content:single-select'>
      <RadioGroup test-id='radio-group:single-select' {...form.getInputProps('contextKey')}>
        {props.options.map(option => {
          return (
            <Radio key={option.contextKey} value={option.contextKey} label={option.primaryText} />
          )
        })}
      </RadioGroup>
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={onSubmit}>
              Next
            </PrimaryButton>
            {props.onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={props.onBack}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          props.onBack ? (
            <Group position='apart'>
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={props.onBack}
              >
                Back
              </TertiaryButton>
              <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
                Next
              </PrimaryButton>
            </Group>
          ) : (
            <PrimaryButton test-id='button:next@desktop' onClick={onSubmit}>
              Next
            </PrimaryButton>
          )
        }
      />
    </Box>
  )
}
