// Consent types

export const CONSENT_TYPE_WITH_CONSENT_OBJECT = ['financial', 'tpo', 'treatment'] as const

export type ConsentTypeWithConsentObject = (typeof CONSENT_TYPE_WITH_CONSENT_OBJECT)[number]

export const CONSENT_TYPE_WITH_NO_CONSENT_OBJECT = [
  'terms-of-use',
  'telehealth',
  'text-message',
  'privacy-policy',
] as const

export type ConsentTypeWithNoConsentObject = (typeof CONSENT_TYPE_WITH_NO_CONSENT_OBJECT)[number]

export const CONSENT_TYPES = [
  ...CONSENT_TYPE_WITH_CONSENT_OBJECT,
  ...CONSENT_TYPE_WITH_NO_CONSENT_OBJECT,
]

export type ConsentType = (typeof CONSENT_TYPES)[number]

export const isConsentType = (type: string | ConsentType) => {
  return CONSENT_TYPES.includes(type as ConsentType)
}

export const consentTypeIncludesConsentObject = (type: string | ConsentType) => {
  return CONSENT_TYPE_WITH_CONSENT_OBJECT.includes(type as ConsentTypeWithConsentObject)
}

// Label map

export const ConsentLabelMap = {
  treatment: 'Consent for treatment',
  telehealth: 'Telehealth consent',
  'terms-of-use': 'Terms of use',
  'text-message': 'Text message consent',
  tpo: 'TPO consent',
  financial: 'Financial agreement',
  'privacy-policy': 'Privacy policy',
} as const

export type ConsentLabelMapKey = keyof typeof ConsentLabelMap

// Consent versions

export const FINANCIAL_CONSENT_VERSIONS = [2, 3, 4, 5] as const
export type FinancialConsentVersion = (typeof FINANCIAL_CONSENT_VERSIONS)[number]

export const TREATMENT_CONSENT_VERSIONS = [1] as const
export type TreatmentConsentVersion = (typeof TPO_CONSENT_VERSIONS)[number]

export const TPO_CONSENT_VERSIONS = [1] as const
export type TpoConsentVersion = (typeof TPO_CONSENT_VERSIONS)[number]

export const consentVersionMap: Record<ConsentTypeWithConsentObject, readonly number[]> = {
  financial: FINANCIAL_CONSENT_VERSIONS,
  treatment: TREATMENT_CONSENT_VERSIONS,
  tpo: TPO_CONSENT_VERSIONS,
}

export const getConsentVersions = ({ consentType }: { consentType: ConsentType }) => {
  switch (consentType) {
    case 'financial':
      return FINANCIAL_CONSENT_VERSIONS
    case 'tpo':
      return TPO_CONSENT_VERSIONS
    case 'treatment':
      return TREATMENT_CONSENT_VERSIONS
    default:
      return []
  }
}

export const getLatestConsentVersion = ({ consentType }: { consentType: ConsentType }) => {
  return Math.max(...getConsentVersions({ consentType }))
}
