import {
  DEFAULT_LOAD_MORE_CHUNK_SIZE,
  Group,
  INPUT_SIZES,
  NumberInput,
  SecondaryButton,
  Select,
  SortIcon,
  Stack,
  Text,
  TitleThree,
  TitleTwo,
  useLifecycle,
  useLoadMore,
} from '@shared/components'
import { SortOrder, sortBy } from '@shared/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getFutureAppointments, getPastAppointments } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { CardMasonry } from '../../../common/components/card/CardMasonry'
import { usePortalDims } from '../../../common/hooks'
import { sendPageEvent } from '../../../common/rudderstack'
import { PastVisitEntry } from './PastVisitEntry'
import { EmptyVisitCard, VisitCard } from './VisitCard'

export const Visits = () => {
  useLifecycle({
    onMount: () => sendPageEvent('Visits'),
  })

  const futureVisitsQuery = useQuery(...useMemo(getFutureAppointments, []))
  const futureVisits = futureVisitsQuery.data || []

  const pastVisitsQuery = useQuery(...useMemo(getPastAppointments, []))
  const pastVisits = useMemo(() => pastVisitsQuery.data || [], [pastVisitsQuery.data])
  const pastVisitsLoadMoreResult = useLoadMore(pastVisits.length)

  useEffect(() => {
    // Update displayed data count when pastVisitsQuery finishes loading
    pastVisitsLoadMoreResult.setDisplayedDataCount(DEFAULT_LOAD_MORE_CHUNK_SIZE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastVisitsQuery.isLoading])

  const [sortOrder, setSortOrder] = useState<SortOrder>('DESC')
  // For mobile sorting because a button is used instead of a select
  const toggleSetSortOrder = () => setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')

  const displayedPastVisits = useMemo(() => {
    const sortedPastVisits = pastVisits.sort(sortBy({ key: 'datetime', order: sortOrder }))
    return sortedPastVisits.slice(0, pastVisitsLoadMoreResult.displayedDataCount)
    // Need to trigger recalculation of displayedPastVisits when pastVisitsQuery finishes loading
  }, [sortOrder, pastVisitsLoadMoreResult.displayedDataCount, pastVisits])

  const { isMobile } = usePortalDims()

  return (
    <Stack test-id='page:visits' spacing='lg'>
      <TitleTwo>Visits</TitleTwo>
      <CardMasonry isLoading={futureVisitsQuery.isLoading || futureVisitsQuery.isLoadingError}>
        {futureVisits.length
          ? futureVisits
              ?.sort(sortBy({ key: 'datetime', order: 'DESC' }))
              .map(visit => <VisitCard {...visit} key={visit.id} />)
          : [<EmptyVisitCard title='Upcoming visit' key='visit' />]}
      </CardMasonry>
      {(pastVisitsQuery.isLoading || pastVisitsQuery.isLoadingError) && (
        <Skeletons type='entries' />
      )}
      {Boolean(pastVisits.length) && (
        <>
          <TitleThree>Past & canceled visits</TitleThree>
          <Stack>
            <Group position='apart'>
              <Group spacing='sm'>
                <Text>Showing</Text>
                <NumberInput
                  sx={{ width: INPUT_SIZES.lg }}
                  textAlign='center'
                  value={pastVisitsLoadMoreResult.displayedDataCount}
                  onChange={val =>
                    pastVisitsLoadMoreResult.setDisplayedDataCount(
                      val || pastVisitsLoadMoreResult.displayedDataCount,
                    )
                  }
                  min={1}
                  max={pastVisits.length}
                />
                <Text>of {pastVisits.length} items</Text>
              </Group>
              {isMobile ? (
                <SecondaryButton
                  size='sm'
                  onClick={toggleSetSortOrder}
                  leftIcon={<SortIcon transform={sortOrder === 'ASC' ? 'unset' : 'scale(1 -1)'} />}
                />
              ) : (
                <Select
                  // eslint-disable-next-line no-magic-numbers
                  sx={theme => ({ width: `calc(${theme.spacing.md} * 7)` })}
                  value={sortOrder}
                  onChange={val => setSortOrder(val as SortOrder)}
                  data={[
                    { value: 'DESC', label: 'Latest' },
                    { value: 'ASC', label: 'Oldest' },
                  ]}
                />
              )}
            </Group>
            {displayedPastVisits.map(visit => (
              <PastVisitEntry {...visit} key={visit.id} />
            ))}
            {pastVisitsLoadMoreResult.hasMoreData && (
              <SecondaryButton fullWidth={isMobile} onClick={pastVisitsLoadMoreResult.loadMore}>
                Load more
              </SecondaryButton>
            )}
          </Stack>
        </>
      )}
    </Stack>
  )
}
