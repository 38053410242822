import { dayjs } from './date'

/**
 * Visit note content contains a `locked_at` field which, prior to July 2022, was stored in this format: "January 22nd, 2022, 9:52 am"
 * After July 2022, it began being stored as an ISO8601 timestamp, which is the default for Ophelia in general.
 *
 * dayjs does not have the ability to parse the prior format, so this util simply returns those strings as is, and formats ISO strings similarly.
 */
export const getVisitNoteLockedAtString = ({
  lockedAt,
  timezone,
}: {
  lockedAt?: string
  timezone?: string
}) => {
  const formattedLockedAtString = dayjs(lockedAt)
    .tz(timezone)
    .format('MMMM D, YYYY h:mma z') as string

  return dayjs(lockedAt).isValid() ? formattedLockedAtString : lockedAt
}
