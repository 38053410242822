import { UseFormReturnType } from '@mantine/form'
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
} from '@shared/components'
import React from 'react'
import {
  SubstancesAndPrescribedOpioidsForm,
  substancesOptions,
} from './CurrentSubstancesAndPrescribedOpioidsUsed'

type CurrentSubstancesUsedProps = {
  disableAllOtherOptions: () => boolean
  form: UseFormReturnType<
    SubstancesAndPrescribedOpioidsForm,
    (values: SubstancesAndPrescribedOpioidsForm) => SubstancesAndPrescribedOpioidsForm
  >
  handleClickNext: () => void
}
export const CurrentSubstancesUsed = ({
  form,
  handleClickNext,
  disableAllOtherOptions,
}: CurrentSubstancesUsedProps) => {
  return (
    <Stack spacing='md'>
      <Stack spacing='md'>
        <Box test-id='content:multiple-choice'>
          <CheckboxGroup {...form.getInputProps('substancesUsedOptions')}>
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_prescription_opioid_painkillers}
              label='Opioid painkillers (e.g. Oxycontin, Percocet, Vicodin)'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_heroin}
              label='Heroin'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_fentanyl}
              label='Fentanyl'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_methamphetamine}
              label='Methamphetamine (i.e. meth, crystal meth, speed)'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_cocaine}
              label='Cocaine'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_other_stimulants}
              label='Other stimulants (e.g. Adderall, Ritalin)'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_benzodiazepines}
              label='Benzodiazepines (e.g. Xanax, Klonopin)'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_kratom}
              label='Kratom'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_ketamine}
              label='Ketamine'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_alcohol}
              label='Alcohol'
            />
            <Checkbox
              disabled={disableAllOtherOptions()}
              value={substancesOptions.substances_used_cannabis}
              label='Cannabis (i.e. weed, marijuana)'
            />
            <Checkbox value={substancesOptions.substances_used_none} label='None of the above' />
          </CheckboxGroup>
          <ResponsiveBox
            mt='lg'
            mobile={
              <Stack spacing='lg' align='center'>
                <PrimaryButton test-id='button:next@mobile' fullWidth onClick={handleClickNext}>
                  Next
                </PrimaryButton>
              </Stack>
            }
            desktop={
              <Group position='apart'>
                <PrimaryButton test-id='button:next@desktop' onClick={handleClickNext}>
                  Next
                </PrimaryButton>
              </Group>
            }
          />
        </Box>
      </Stack>
    </Stack>
  )
}
