import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { routes } from '../common/routes'
import * as Pages from './pages'

export const ScheduleApp = () => {
  return (
    <Routes>
      <Route path={routes.schedule.children.self} element={<Pages.ScheduleCalendarHold />} />
      <Route path='*' element={<Navigate replace to={routes.welcome.index} />} />
    </Routes>
  )
}
