import { BaseApi, toTime } from '@shared/utils'
import axios from 'axios'
import * as Fullstory from '../../common/fullstory'

const axiosInstance = axios.create({
  timeout: toTime('1 min').ms(),
})

axios.interceptors.response.use(undefined, error => {
  Fullstory.log('error', JSON.stringify(error))
  return Promise.reject(error)
})

export class StorageApi extends BaseApi {
  addFile = ({ signedUrl, file }: { signedUrl: string; file: File }) => {
    return axiosInstance({
      method: 'PUT',
      url: signedUrl,
      data: file,
      headers: { 'Content-Type': file.type },
    })
  }

  getFile = async ({ url, fileId }: { url: string; fileId: string }) => {
    const response = await axiosInstance({
      method: 'GET',
      url,
      responseType: 'blob',
    })

    const type = response.headers['content-type']
    if (!type) {
      throw new Error(`File has no content type!`)
    }

    return new File([response.data], fileId, { type })
  }
}
