import { Box } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { TaskHeader } from './TaskHeader'
import { TreatmentConsentStep } from './TreatmentConsentStep'
import { usePatientFormTask } from './use-patient-task'

export const TreatmentConsentTask = () => {
  const { submit, status, isSaving, payload } = usePatientFormTask({
    totalSteps: 1,
    type: 'treatment-consent',
    breadcrumb: 'Consent for treatment',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <Box test-id='page:treatment-consent-task'>
      <TaskHeader title='Consent for treatment' />
      <TreatmentConsentStep submit={submit} saving={isSaving} initialValues={payload} />
    </Box>
  )
}
