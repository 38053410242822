import {
  Box,
  CalendarIcon,
  ClockIcon,
  Flex,
  Grid,
  List,
  ListItem,
  PhoneIncomingIcon,
  Space,
  Text,
  UserIcon,
  VideoIcon,
} from '@shared/components'
import { AppointmentStatus, AppointmentTypeString, getVisitTypeName } from '@shared/types'
import { dayjs } from '@shared/utils'
import React from 'react'
import { AppointmentPillStatus } from '../../../common/components'
import { getFormattedDuration, isCall } from '../../../common/visits'
import { getCalendarName } from './utils'

export const PastVisitEntry = ({
  visitType,
  datetime,
  duration,
  calendar,
  status,
}: {
  visitType: AppointmentTypeString
  datetime: string
  duration: string
  calendar: string
  status: AppointmentStatus
  slim?: boolean
}) => {
  const visitDate = dayjs(datetime)
  const isWelcomeCall = ['Free Consultation Call', 'Returning welcome call'].includes(visitType)

  return (
    <Box
      sx={({ other: { colors, sizes }, radius, spacing }) => ({
        borderStyle: 'solid',
        borderColor: colors.background[1],
        borderWidth: sizes.border.md,
        borderRadius: radius.sm,
        padding: spacing.md,
      })}
    >
      <Grid gutter='xs'>
        <Grid.Col span={6} mb='md'>
          <Text bold>{getVisitTypeName(visitType)}</Text>
        </Grid.Col>
        <Grid.Col span={6} mb='md'>
          <Flex align='flex-end' justify='flex-end'>
            <AppointmentPillStatus status={status} />
          </Flex>
        </Grid.Col>
        <Space h='md' />
        <Grid.Col span={12} sm={8} md={2.5}>
          <List styles={({ other: { colors } }) => ({ itemIcon: { color: colors.actions[3] } })}>
            <ListItem icon={<CalendarIcon />}>
              {visitDate.format('MMM D YYYY').toUpperCase()}
            </ListItem>
          </List>
        </Grid.Col>
        <Grid.Col span={12} sm={4} md={2.5}>
          <List styles={({ other: { colors } }) => ({ itemIcon: { color: colors.actions[3] } })}>
            <ListItem icon={<ClockIcon />}>{visitDate.format('h:mma z')}</ListItem>
          </List>
        </Grid.Col>
        <Grid.Col span={12} sm={8} md={4.5}>
          <List styles={({ other: { colors } }) => ({ itemIcon: { color: colors.actions[3] } })}>
            {!isWelcomeCall && <ListItem icon={<UserIcon />}>{getCalendarName(calendar)}</ListItem>}
          </List>
        </Grid.Col>
        <Grid.Col span={12} sm={4} md={2.5}>
          <List styles={({ other: { colors } }) => ({ itemIcon: { color: colors.actions[3] } })}>
            <ListItem icon={isCall(visitType) ? <PhoneIncomingIcon /> : <VideoIcon />}>
              {getFormattedDuration(duration, visitType)}
            </ListItem>
          </List>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
