import { AuthenticationMethod, CurrentUserPatient } from '@shared/types'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Skeletons } from '.'
import { useAuth, useFlags } from '../hooks'
import { routes } from '../routes'
import { setSessionStorageItem } from '../storage'

export const isPasswordAuthenticated = (authenticationMethod: AuthenticationMethod) => {
  const methods: AuthenticationMethod[] = ['pin', 'password', 'impersonation']
  return methods.includes(authenticationMethod)
}

export const isAllowedInPortal = (
  currentUser: CurrentUserPatient | undefined,
  authenticationMethod: AuthenticationMethod,
) => {
  const methods: AuthenticationMethod[] = ['pin', 'password', 'impersonation']
  const isPasswordAuthenticated = methods.includes(authenticationMethod)
  return (
    currentUser?.account.readyForPortal &&
    !currentUser?.account.requiresOnboarding &&
    isPasswordAuthenticated
  )
}

export const ResetPasswordAuth = ({ children }: { children: JSX.Element }) => {
  const { isAuthorized, authenticationMethod, currentUser } = useAuth()

  if (isAuthorized) {
    if (currentUser?.data && isAllowedInPortal(currentUser.data, authenticationMethod)) {
      return <Navigate replace to={routes.portal.index} />
    }
  }

  return children
}

export const PortalAuth = ({ children }: { children: JSX.Element }) => {
  const { isAuthorized, authenticationMethod, isLoading, currentUser } = useAuth()
  const router = useLocation()
  const { redirectLeadsToInsuranceTasks } = useFlags()

  if (isLoading) {
    return <Skeletons />
  }

  // All unauthorized users need to be sent to /welcome to sign up or sign in
  if (isAuthorized) {
    const redirectToInsuranceTask =
      currentUser?.data?.statuses.patient === 'lead' && redirectLeadsToInsuranceTasks

    /*
     * If an authorized user needs to schedule a welcome call, send them back through
     * onboarding
     */

    if (redirectToInsuranceTask) {
      setSessionStorageItem(
        'redirect_after_login',
        `${routes.portal.index}/${routes.portal.children.task.index}${routes.portal.children.task.children.insurance}`,
      )
    }

    if (currentUser?.data?.account.requiresOnboarding) {
      return <Navigate replace to={routes.onboarding.index} />
    }

    if (!isPasswordAuthenticated(authenticationMethod)) {
      return <Navigate replace to={`${routes.welcome.index}/${routes.welcome.children.signin}`} />
    }

    /*
     * If an authorized user is still a Lead, or not password authenticated,
     * they should be sent to /welcome
     */
    if (!isAllowedInPortal(currentUser?.data, authenticationMethod)) {
      return <Navigate replace to={routes.welcome.index} />
    }
  } else {
    setSessionStorageItem('redirect_after_login', router.pathname + router.search)
    return <Navigate replace to={`${routes.welcome.index}/${routes.welcome.children.signin}`} />
  }

  // If none of these conditionals hit, the user is ready for the portal
  return children
}

export const ReconnectAuth = ({ children }: { children: JSX.Element }) => {
  const { isAuthorized, isLoading, currentUser } = useAuth()

  if (isLoading) {
    return <Skeletons />
  }

  if (!isAuthorized) {
    return <Navigate replace to={routes.welcome.index} />
  }

  if (currentUser?.data?.statuses.patient !== 'discharged') {
    return <Navigate replace to={routes.portal.index} />
  }

  return children
}
