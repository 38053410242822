// eslint-disable-next-line no-restricted-imports
import { Box, Stack, useMantineTheme } from '@mantine/core'
import { SuggestionKeyDownProps } from '@tiptap/suggestion'
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { MentionData } from '.'
import { Text } from '../../typography'

export type MentionListForwardRef = {
  onKeyDown: ({ event }: SuggestionKeyDownProps) => boolean
}
type MentionListProps = {
  items: MentionData[]
  command: (item: MentionData) => void
  hide: boolean
}

export const MentionList = forwardRef<MentionListForwardRef, MentionListProps>(
  (props: MentionListProps, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const {
      radius,
      colors,
      other: { colors: otherColors, sizes },
    } = useMantineTheme()

    const selectItem = (index: number) => {
      const item = props.items[index]

      if (item) {
        props.command(item)
      }
    }

    const upHandler = () => {
      setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
    }

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % props.items.length)
    }

    const enterHandler = () => {
      selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          downHandler()
          return true
        }

        if (event.key === 'Enter') {
          enterHandler()
          return true
        }

        return false
      },
    }))

    return (
      <Stack
        spacing={0}
        bg={colors.dark[0]}
        style={{
          visibility: props.hide ? 'hidden' : 'visible',
          borderStyle: 'solid',
          borderWidth: sizes.border.md,
          borderColor: otherColors.actions[0],
          borderRadius: radius.md,
          overflow: 'clip',
        }}
      >
        {props.items.length ? (
          props.items.map((item, index) => (
            <Box
              key={item.id}
              bg={index === selectedIndex ? colors.dark[1] : colors.dark[0]}
              p='sm'
              style={{ cursor: 'pointer' }}
              onClick={() => selectItem(index)}
            >
              <Text color={otherColors.text[0]} size='md'>
                {item.label}
              </Text>
            </Box>
          ))
        ) : (
          <Box p='sm'>
            <Text color={otherColors.text[0]} size='md'>
              No result
            </Text>
          </Box>
        )}
      </Stack>
    )
  },
)

MentionList.displayName = 'MentionList'
