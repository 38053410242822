import React, { PropsWithChildren, ReactNode } from 'react'
import { MantineColor, colorToHex } from '../inputs'
// eslint-disable-next-line no-restricted-imports
import { Group, Stack, useMantineTheme } from '@mantine/core'
import { Accordion } from '../Accordion'
import { Divider } from '../Divider'
import { useTimelineCardOutlineStyle } from './TimelineCard'

export const AccordionTimelineCard = ({
  leftSide,
  rightSide,
  children,
  styles,
  onExpand,
}: PropsWithChildren<{
  leftSide: ReactNode
  rightSide?: ReactNode
  styles?: MantineColor<{ borderColor?: string; backgroundColor?: string; dividerColor?: string }>
  onExpand?: (value: boolean) => void
}>) => {
  const {
    other: { colors, sizes },
  } = useMantineTheme()
  const { borderColor, backgroundColor, dividerColor } = colorToHex(styles, colors) ?? {}
  const { classes } = useTimelineCardOutlineStyle({ backgroundColor, borderColor })

  return (
    <Accordion className={classes.card} onChange={value => onExpand?.(value === 'card')}>
      <Accordion.Item value='card'>
        <Accordion.Control>
          <Group position='apart' spacing='sm' noWrap>
            {leftSide}
            {rightSide}
          </Group>
        </Accordion.Control>
        <Accordion.Panel>
          <Divider
            style={{ borderColor: colorToHex(dividerColor, colors) ?? colors.background[3] }}
          />
          <Stack p={sizes.gap.lg} style={{ gap: sizes.gap.lg }}>
            {children}
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}
