import {
  Alert,
  Box,
  ChevronLeftIcon,
  Group,
  Pill,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
  ThumbsUpIcon,
  TitleTwo,
  emojiMap,
  useLifecycle,
} from '@shared/components'
import { PayersShortList } from '@shared/utils'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import * as FullStory from '../common/fullstory'
import { routes } from '../common/routes'
import { sendTrackEvent } from '../common/rudderstack'

type CoverageResultsProps = {
  coverageResults?: { isActive: boolean } | undefined
  selectedPayer: string
  handleClickBack: () => void
}
export const CoverageResults = ({
  coverageResults,
  selectedPayer,
  handleClickBack,
}: CoverageResultsProps) => {
  const navigate = useNavigate()

  useLifecycle({
    onMount: () => {
      sendTrackEvent('Coverage Results Step Visible')
      FullStory.event('Coverage Results Step Visible')
    },
  })

  const isInNetwork = selectedPayer.split('__')[0] !== 'other_none_of_these'
  const isActive = coverageResults?.isActive
  const payerLabel = PayersShortList.find(payer => payer.value === selectedPayer.split('__')[0])
    ?.label

  const isZeroDollar = Boolean(
    PayersShortList.find(payer => payer.value === selectedPayer.split('__')[0])
      ?.hasMedicaidPlanType,
  )

  const handleClickSchedule = () => {
    sendTrackEvent('Schedule My Free Intro Call Clicked')
    FullStory.event('Schedule My Free Intro Call Clicked')
    navigate(`${routes.welcome.index}`, { state: { checkedInsurance: true } })
  }

  useEffect(() => {
    if (isZeroDollar && isActive && isInNetwork) {
      sendTrackEvent('Zero Dollar Alert Visible')
      FullStory.event('Zero Dollar Alert Visible')
    }
  }, [isZeroDollar, isInNetwork, isActive])
  return (
    <ResponsiveBox
      desktop={
        <Stack spacing='lg'>
          <Pill variant='filled' status='success'>
            <Text>Coverage check complete</Text>
          </Pill>
          <Stack spacing='xs'>
            {isInNetwork ? (
              <>
                <TitleTwo>{`${emojiMap['party popper']} We accept your insurance`}</TitleTwo>
                <Text>
                  We are in-network with{' '}
                  <Text span>{payerLabel ? payerLabel : 'your insurance'}</Text>
                  {isActive && (
                    <Text span>
                      {' '}
                      and your coverage is active, so your care is likely to be covered by your
                      insurance.
                    </Text>
                  )}
                  {!isActive && (
                    <Text span>
                      , but we need to contact your insurance to verify a few things.
                    </Text>
                  )}
                </Text>
              </>
            ) : (
              <>
                <TitleTwo>
                  Based on the info you provided, we can&apos;t tell whether we accept your
                  insurance
                </TitleTwo>
                <Text>We&apos;ll need to contact your insurance to verify a few things.</Text>
              </>
            )}
          </Stack>
          {isInNetwork && isActive && isZeroDollar && (
            <Alert
              title={
                <Text bold color={colors => colors.text[3]}>
                  Most people with {payerLabel} pay $0 for care
                </Text>
              }
              icon={<ThumbsUpIcon />}
              variant='primary'
            >
              <Box> </Box>
            </Alert>
          )}
          <Stack spacing='xs'>
            <TitleTwo>Ready to get started?</TitleTwo>
            <Text>
              The next step is speaking with someone from our team to get your questions answered
              and get your intake visit scheduled.
            </Text>
          </Stack>
          <Group position='apart'>
            <TertiaryButton leftIcon={<ChevronLeftIcon />} onClick={() => handleClickBack()}>
              Back
            </TertiaryButton>
            <PrimaryButton onClick={() => handleClickSchedule()}>
              Schedule my free intro call
            </PrimaryButton>
          </Group>
        </Stack>
      }
      mobile={
        <Stack spacing='lg'>
          <Pill variant='filled' status='success'>
            <Text>Coverage check complete</Text>
          </Pill>
          <Stack spacing='xs'>
            {isInNetwork ? (
              <>
                <TitleTwo>{`${emojiMap['party popper']} We accept your insurance`}</TitleTwo>
                <Text>
                  We are in-network with{' '}
                  <Text span>{payerLabel ? payerLabel : 'your insurance'}</Text>
                  {isActive && (
                    <Text span>
                      {' '}
                      and your coverage is active, so your care is likely to be covered by your
                      insurance.
                    </Text>
                  )}
                  {!isActive && (
                    <Text span>
                      , but we need to contact your insurance to verify a few things.
                    </Text>
                  )}
                </Text>
              </>
            ) : (
              <>
                <TitleTwo>
                  Based on the info you provided, we can&apos;t tell whether we accept your
                  insurance
                </TitleTwo>
                <Text>We&apos;ll need to contact your insurance to verify a few things.</Text>
              </>
            )}
          </Stack>
          {isInNetwork && isActive && isZeroDollar && (
            <Alert
              title={
                <Text bold color={colors => colors.text[3]}>
                  Most people with {payerLabel} pay $0 for care
                </Text>
              }
              icon={<ThumbsUpIcon />}
              variant='primary'
            >
              <Box> </Box>
            </Alert>
          )}
          <Stack spacing='xs'>
            <TitleTwo>Ready to get started?</TitleTwo>
            <Text>
              The next step is speaking with someone from our team to get your questions answered
              and get your intake visit scheduled.
            </Text>
          </Stack>
          <Stack spacing='sm'>
            <PrimaryButton fullWidth onClick={() => handleClickSchedule()}>
              Schedule my free intro call
            </PrimaryButton>
            <TertiaryButton
              fullWidth
              leftIcon={<ChevronLeftIcon />}
              onClick={() => handleClickBack()}
            >
              Back
            </TertiaryButton>
          </Stack>
        </Stack>
      }
    />
  )
}
