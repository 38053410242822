import { Checkbox, Group, Stack, Text } from '@shared/components'
import { dayjs } from '@shared/utils'
import React from 'react'

export type SignedAgreementInfoProps = {
  signature: string
  date: string
  checkboxLabel: string
}
export const SignedAgreementInfo = ({
  signature,
  date,
  checkboxLabel,
}: SignedAgreementInfoProps) => {
  return (
    <Stack spacing='lg'>
      <Checkbox label={checkboxLabel} checked disabled />
      <Group spacing='xl'>
        <Stack spacing='xs'>
          <Text bold>Signed by</Text>
          <Text>{signature}</Text>
        </Stack>
        <Stack spacing='xs'>
          <Text bold>Signed on</Text>
          <Text>{dayjs(date).format('MM/DD/YYYY')}</Text>
        </Stack>
      </Group>
    </Stack>
  )
}
