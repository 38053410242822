import { isEmail, useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Checkbox,
  CheckboxGroup,
  Divider,
  ExternalLinkText,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
  TextInput,
  TitleTwo,
  isAllSelected,
  useBanner,
  useLifecycle,
  validateWith,
} from '@shared/components'
import React, { useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { SigninStep } from '.'
import * as FullStory from '../../../../src/common/fullstory'
import { currentUserApi } from '../../../common/api'
import { isRequired } from '../../../common/forms'
import { useAuth } from '../../../common/hooks'
import { sendPageEvent } from '../../../common/rudderstack'
import { ConsentValues } from '../types'

type SetEmailForm = {
  email: string
  consents: string[]
}

type SetEmailProps = {
  setCurrentStep: (step: SigninStep) => void
  setSubmittedEmail: (email: string) => void
  backToSignInPage: () => void
}

export const SetEmail = ({
  setCurrentStep,
  setSubmittedEmail,
  backToSignInPage,
}: SetEmailProps) => {
  const { hasEmail, currentUser, isLoading: authIsLoading } = useAuth()
  const queryClient = useQueryClient()
  const { showBanner } = useBanner()

  useLifecycle({
    onMount: () => {
      sendPageEvent('Set Email')
      FullStory.event('Set Email Visible')
    },
  })

  const setEmailAndConsents = useMutation(currentUserApi.setEmailAndConsents)

  const submit = async () => {
    if (form.validate().hasErrors) {
      return
    }

    setSubmittedEmail(form.values.email)
    await setEmailAndConsents.mutateAsync(
      {
        email: form.values.email,
        emailOnFail: true,
        consents: {
          privacyPolicy: true,
          termsOfUse: true,
          telehealthInformedConsent: true,
          textMessageConsent: true,
        },
      },
      {
        onSuccess: data => {
          if (data.status === 'confirmation-sent') {
            setCurrentStep('email-sent')
          } else {
            void queryClient.invalidateQueries('currentUserApi.retrieve')
          }
        },
        onError: () =>
          showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
      },
    )
  }

  const consents = useMemo(() => {
    const consents = [ConsentValues.Email, ConsentValues.Terms]
    if (!currentUser?.data?.consents?.textMessageConsent) {
      consents.push(ConsentValues.Text)
    }
    return consents
  }, [currentUser?.data?.consents?.textMessageConsent])

  const form = useForm<SetEmailForm>({
    initialValues: {
      email: currentUser?.data?.personalData?.email || '',
      consents: [],
    },
    validate: {
      email: validateWith(isRequired, isEmail),
      consents: isAllSelected(consents, 'Required'),
    },
  })

  const isLoading = setEmailAndConsents.isLoading || authIsLoading

  return (
    <Stack test-id='page:set-email' spacing='lg'>
      <Group spacing='sm'>
        <TitleTwo>Set an email for your patient portal</TitleTwo>
        <Text>
          We need your email address so we can reach you if something happens with your phone.
        </Text>
      </Group>
      <TextInput
        test-id='input:email'
        label='Email'
        type='email'
        placeholder='Email'
        maxLength={256}
        disabled={hasEmail || isLoading}
        {...form.getInputProps('email')}
      />
      <CheckboxGroup {...form.getInputProps('consents')}>
        <Checkbox
          value={ConsentValues.Email}
          test-id='checkbox:consent-emails'
          label='I consent for Ophelia to send me emails'
          disabled={isLoading}
        />
        {consents.includes(ConsentValues.Text) && (
          <Checkbox
            value={ConsentValues.Text}
            test-id='checkbox:consent-texts'
            disabled={isLoading}
            label='I consent for Opehlia to send me texts (standard rates apply)'
          />
        )}
        <Divider my='md' />
        <Text>
          {'Please review our '}
          <ExternalLinkText href='https://ophelia.com/terms-of-use'>terms</ExternalLinkText>
          {' and '}
          <ExternalLinkText href='https://ophelia.com/privacy-policy'>
            privacy policy
          </ExternalLinkText>
          {' and consent to '}
          <ExternalLinkText href='https://ophelia.com/telehealth-informed-consent'>
            telehealth
          </ExternalLinkText>
        </Text>
        <Checkbox
          value={ConsentValues.Terms}
          test-id='checkbox:agree-terms'
          disabled={isLoading}
          label="I agree to Ophelia's terms, privacy policy and consent to telehealth"
        />
      </CheckboxGroup>
      <ResponsiveBox
        mobile={
          <Stack spacing='lg'>
            <PrimaryButton
              test-id='button:submit@mobile'
              fullWidth
              onClick={submit}
              loading={isLoading}
            >
              Submit email
            </PrimaryButton>
            <TertiaryButton fullWidth leftIcon={<ArrowLeftIcon />} onClick={backToSignInPage}>
              Back to sign in page
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton leftIcon={<ArrowLeftIcon />} onClick={backToSignInPage}>
              Back to sign in page
            </TertiaryButton>
            <PrimaryButton test-id='button:submit@desktop' onClick={submit} loading={isLoading}>
              Submit email
            </PrimaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
