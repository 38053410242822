import { BaseApi } from '@shared/utils'

export class AppointmentsApi extends BaseApi {
  isConsultationCallQueueAvailable = () => {
    return this.json<{ isAvailable: boolean }>({
      method: 'GET',
      url: '/appointments/schedule/consultation/queue',
    })
  }
}
