import { useForm, UseFormReturnType } from '@mantine/form'
import { Box, useBanner, validateWith } from '@shared/components'
import { phone as formatPhone } from '@shared/utils'
import React, { useCallback, useEffect } from 'react'
import { useMutation, UseMutationResult } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { currentUserApi } from '../common/api'
import { Skeletons } from '../common/components'
import { auth } from '../common/firebase'
import { isPhone, isRequired } from '../common/forms'
import { useAuth } from '../common/hooks'
import { routes } from '../common/routes'
import { Verification } from '../welcome/pages/Verification'
import { AccountRecoveryRequest } from './AccountRecoveryRequest'
import { AccountRecoveryUpdate } from './AccountRecoveryUpdate'
import { Phone } from './Phone'

const accountRecoverySigninSteps = ['phone', 'verification', 'email-request', 'update'] as const

export type AccountRecoverySigninStep = (typeof accountRecoverySigninSteps)[number]
const STEP_QUERY_PARAM = 'step'

export type AccountRecoverySignInProps = {
  setCurrentStep: (step: AccountRecoverySigninStep, options?: { replace: boolean }) => void
  form: UseFormReturnType<{ phoneNumber: string }>
  signInWithPhoneNumber: UseMutationResult<void, unknown, string, unknown>
  backToSignInPage: () => void
}

export const AccountRecovery = () => {
  const { isAuthorized, isLoading } = useAuth()
  const { hideBanner } = useBanner()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const currentStep = searchParams.get(STEP_QUERY_PARAM) as AccountRecoverySigninStep
  const setCurrentStep = useCallback(
    (step: AccountRecoverySigninStep, options: { replace: boolean } = { replace: false }) => {
      const params: { [STEP_QUERY_PARAM]: AccountRecoverySigninStep; interstitial?: string } = {
        [STEP_QUERY_PARAM]: step,
      }
      const interstitialParam = searchParams.get('interstitial')
      if (interstitialParam) {
        params.interstitial = interstitialParam
      }
      setSearchParams(params, options)
    },
    [setSearchParams, searchParams],
  )

  const form = useForm<{ phoneNumber: string }>({
    initialValues: { phoneNumber: '' },
    validate: { phoneNumber: validateWith(isRequired, isPhone) },
  })

  useEffect(() => {
    if (!isAuthorized && !isLoading) {
      // If invalid step found as query param
      if (!accountRecoverySigninSteps.includes(currentStep)) {
        setCurrentStep('phone', { replace: true })
        return
      }

      // If information missing during verification step
      if (currentStep !== 'phone' && !form.values.phoneNumber) {
        setCurrentStep('phone', { replace: true })
        return
      }
    }

    if (isAuthorized) {
      return setCurrentStep('email-request', { replace: true })
    }
  }, [currentStep, form.values.phoneNumber, isAuthorized, setCurrentStep, isLoading])

  const { setValues, setFieldValue } = form

  useEffect(() => {
    // Clean information at step
    if (currentStep === 'phone') {
      setValues({ phoneNumber: '' })
    }

    // Clean information at step
    if (currentStep === 'verification') {
      setFieldValue('verificationCode', '')
    }

    // Cleans up any leftover banner from the previous step
    return hideBanner
  }, [currentStep, hideBanner, setFieldValue, setValues])

  const signInWithPhoneNumber = useMutation((phone: string) =>
    currentUserApi.requestOTP({ phone: formatPhone(phone).normalized }),
  )

  const backToSignInPage = async () => {
    await auth.signOut()
    navigate(routes.welcome.children.signin)
  }

  if (currentStep === 'phone') {
    return (
      <Box test-id='page:account-recovery-request'>
        <Phone
          form={form}
          setCurrentStep={setCurrentStep}
          signInWithPhoneNumber={signInWithPhoneNumber}
          backToSignInPage={backToSignInPage}
        />
      </Box>
    )
  }

  if (currentStep === 'verification') {
    return (
      <Box test-id='page:account-recovery-request'>
        <Verification
          phoneNumber={form.values.phoneNumber}
          signInWithPhoneNumber={signInWithPhoneNumber}
        />
      </Box>
    )
  }

  if (currentStep === 'email-request') {
    return (
      <Box test-id='page:account-recovery-request'>
        <AccountRecoveryRequest />
      </Box>
    )
  }

  if (currentStep === 'update') {
    return (
      <Box test-id='page:account-recovery-request'>
        <AccountRecoveryUpdate />
      </Box>
    )
  }

  return <Skeletons />
}
