import { useForm } from '@mantine/form'
import {
  Alert,
  FlagIcon,
  Radio,
  RadioGroup,
  Stack,
  Text,
  TitleTwo,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import { default as React } from 'react'
import { isRequired } from '../../../forms'
import { PromptContentNavigation } from '../PromptContentNavigation'
import { WidgetProps } from '../PromptContentWidget'

type HowToGetStartedForm = {
  howToGetStarted: PromptResponseContextKey | undefined
}

export const HowToGetStarted = ({ onBack, onSubmit, response }: WidgetProps) => {
  const howToGetStartedForm = useForm<HowToGetStartedForm>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      howToGetStarted: response?.value.contextKey as PromptResponseContextKey | undefined,
    },
    validate: {
      howToGetStarted: validateWith(isRequired),
    },
  })

  const INTERESTED_IN_SUBUTEX = 'interested_in_subutex' as PromptResponseContextKey

  const submitHandler = ({ interestedInSubutex }: { interestedInSubutex: boolean }) => {
    if (interestedInSubutex) {
      return onSubmit({
        value: {
          contextKey: INTERESTED_IN_SUBUTEX,
        },
      })
    }
    const { hasErrors } = howToGetStartedForm.validate()

    if (hasErrors) {
      return
    }
    if (!howToGetStartedForm.values.howToGetStarted) {
      return
    }

    onSubmit({
      value: {
        contextKey: howToGetStartedForm.values.howToGetStarted,
      },
    })
  }

  return (
    <Stack spacing='lg' test-id='content:how-to-get-started'>
      <TitleTwo>How would you like to get started?</TitleTwo>
      <RadioGroup
        test-id='radio-group:how-to-get-started'
        {...howToGetStartedForm.getInputProps('howToGetStarted')}
      >
        <Radio
          value='get_started_with_intake_visit'
          label='Skip the welcome call and connect directly with a clinician'
          description='Schedule a 60-minute Zoom visit with a licensed clinician to create a personalized plan'
        />
        <Radio
          value='get_started_with_welcome_call'
          label='Welcome call with a team member'
          description='Learn more about the program on a 15-minute free introductory call before meeting with a clinician'
        />
      </RadioGroup>
      {howToGetStartedForm.values.howToGetStarted === 'get_started_with_intake_visit' && (
        <Alert
          variant='warning'
          icon={<FlagIcon />}
          onClick={() => submitHandler({ interestedInSubutex: true })}
        >
          <Text>{`If you're interested in Subutex, please book a welcome call first`}</Text>
        </Alert>
      )}
      <PromptContentNavigation
        onBack={onBack}
        onSubmit={() => submitHandler({ interestedInSubutex: false })}
      />
    </Stack>
  )
}
