import { Box } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { WorkflowPrompt } from '../../common/components/prompt/WorkflowPrompt'
import { routes } from '../../common/routes'

export const NotReadyFlow = () => {
  const navigate = useNavigate()

  return (
    <Box>
      <WorkflowPrompt
        workflowApi='not_ready_flow'
        workflowType='not_ready_flow'
        showProgress={false}
        showBackButton
        prefix={routes.options.index}
        onComplete={() => {
          navigate(routes.welcome.index)
        }}
      />
    </Box>
  )
}
