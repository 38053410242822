import { z } from 'zod'
import { ApiSchemas } from '../../utils'

export const GetRelatedTasksReqRes = {
  req: z.object({
    params: z.object({
      taskId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      relatedTasks: z.object({ id: z.string() }).array(),
    }),
  }),
}

export const AddRelatedTaskReqRes = {
  req: z.object({
    params: z.object({
      taskId: z.string(),
    }),
    body: z.object({
      relatedTaskIdToAdd: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const DeleteRelatedTaskReqRes = {
  req: z.object({
    params: z.object({
      taskId: z.string(),
    }),
    body: z.object({
      relatedTaskIdToRemove: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const EmrTasksRouteSchemas = {
  'GET /tasks/:taskId/related': GetRelatedTasksReqRes,
  'POST /tasks/:taskId/related': AddRelatedTaskReqRes,
  'DELETE /tasks/:taskId/related': DeleteRelatedTaskReqRes,
} satisfies ApiSchemas
