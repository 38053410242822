import { z } from 'zod'
import { ApiSchemas } from '../../utils'
import { ISOStringSchema, YYYYMMDDSchema } from '../../../dates'

const GetCalendarHoldByIdReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      days: z.array(YYYYMMDDSchema),
      appointmentDetails: z
        .object({
          clinicianName: z.string(),
          timezone: z.string(),
          datetime: z.string(),
        })
        .nullable(),
    }),
  }),
}

const GetAvailabilityForCalendarHold = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
    query: z.object({
      day: YYYYMMDDSchema,
    }),
  }),
  res: z.object({
    data: z.object({
      availability: z.array(
        z.object({
          datetime: z.string(),
        }),
      ),
    }),
  }),
}

const GetDaysForCalendarHold = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      availableDays: z.array(YYYYMMDDSchema),
      startDay: YYYYMMDDSchema.nullable(),
    }),
  }),
}

const CreateCalendarHoldAppointmentReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
    body: z.object({
      datetime: ISOStringSchema,
    }),
  }),
  res: z.object({
    data: z.object({
      clinicianName: z.string(),
      timezone: z.string(),
      datetime: z.string(),
    }),
  }),
}

export const MyCalendarHoldsApi = {
  'GET /calendar-holds/:id': GetCalendarHoldByIdReqResSchemas,
  'GET /calendar-holds/:id/availability': GetAvailabilityForCalendarHold,
  'GET /calendar-holds/:id/available-days': GetDaysForCalendarHold,
  'POST /calendar-holds/:id/appointments': CreateCalendarHoldAppointmentReqResSchemas,
} satisfies ApiSchemas
