/* eslint-disable no-magic-numbers */
// eslint-disable-next-line no-restricted-imports
import { MantineThemeOverride } from '@mantine/core'
import { baseTheme, Colors, createHomogenousColorList, Fonts } from './base'

export const TWILIGHT_COLORS: Colors = {
  success: ['#00B0AD', '#004645'],
  warning: ['#FFB656', '#664922'],
  error: ['#FE7171', '#662D2D'],
  actions: ['#A0ADD8', '#7B88B7', '#808194', '#B3B1BD'],
  actionText: '#000000',
  text: ['#FFFFFF', '#B3B1BD', '#2A2B2E', '#000000'],
  background: [
    '#000000',
    '#2A2B2E',
    '#808194',
    '#B3B1BD',
    '#E5E1E6',
    '#F6F4F6',
    '#FFFFFF',
    '#F1F1DE',
  ],
}

// These are imported in the <Global> component.
export const TWILIGHT_FONT_FAMILIES: Fonts = {
  regular: 'Helvetica Now Text Regular',
  bold: 'Helvetica Now Text Bold',
  pro: 'Helvetica Neue LT Pro',
}

export const TWILIGHT_FONT_FILE_NAMES: Fonts = {
  regular: 'HelveticaNowTextRegular.woff2',
  bold: 'HelveticaNowTextBold.woff2',
  pro: 'HelveticaNeueLTPro.woff2',
}

export const TWILIGHT_THEME: MantineThemeOverride = {
  ...baseTheme,
  colorScheme: 'dark',
  fontFamily: `${TWILIGHT_FONT_FAMILIES.regular}, Helvetica, Arial, sans-serif`,
  colors: {
    // Dark is the default dark, with the exception of the first two.
    dark: [
      '#FFFFFF',
      '#F6F4F6',
      '#C1C2C5',
      '#A6A7AB',
      '#909296',
      '#5c5f66',
      '#373A40',
      '#2C2E33',
      '#25262b',
      '#1A1B1E',
    ],
    primary: createHomogenousColorList(TWILIGHT_COLORS.actions[0]),
    // NOTE: this overrides all the RED colors
    red: createHomogenousColorList(TWILIGHT_COLORS.error[0]),
    // NOTE: this overrides all the GREEN colors
    green: createHomogenousColorList(TWILIGHT_COLORS.success[0]),
    // NOTE: this overrides all the YELLOW colors
    yellow: createHomogenousColorList(TWILIGHT_COLORS.warning[0]),
  },
  headings: {
    fontFamily: TWILIGHT_FONT_FAMILIES.pro,
    sizes: {
      h1: {
        fontSize: '2.25rem',
      },
      h2: {
        fontSize: '1.75rem',
      },
      h3: {
        fontSize: '1.25rem',
      },
      h4: {
        fontSize: '1rem',
      },
    },
  },
  fontSizes: { xs: '0.75rem', sm: '1rem', md: '1rem', lg: '1rem', xl: '1.125rem' },
  spacing: {
    xs: '0.25rem',
    sm: '0.75rem',
    md: '1rem',
    lg: '2rem',
    xl: '4rem',
  },
  other: {
    colors: TWILIGHT_COLORS,
    fontFamilies: TWILIGHT_FONT_FAMILIES,
    themeName: 'twilight',
    sizes: {
      iconbutton: { xs: '1rem', sm: '1.25rem', md: '1.25rem', lg: '2rem' },
      button: { xs: '1.625rem', sm: '2.313rem', md: '3rem' },
      input: { md: '3rem' },
      padding: { xs: '0.25rem', sm: '0.5rem', md: '0.75rem', lg: '1rem', xl: '1.5rem' },
      gap: { sm: '0.25rem', md: '0.5rem', lg: '0.75rem', xl: '1rem' },
      icon: { sm: '1rem', md: '1.5rem', lg: '2rem', xl: '3rem' },
      border: { md: '0.125rem', lg: '0.25rem' },
      outline: { sm: '0.094rem', md: '0.125rem', lg: '0.188rem' },
    },
  },
}
