// eslint-disable-next-line no-restricted-imports
import { Box, Stack, Tooltip, useMantineTheme } from '@mantine/core'
import { Editor } from '@tiptap/react'
import { SuggestionKeyDownProps } from '@tiptap/suggestion'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'
import { SmartPhrase } from '.'
import { Text } from '../../typography'

export type SmartPhraseListForwardRef = {
  onKeyDown: ({ event }: SuggestionKeyDownProps) => boolean
}

type SmartPhraseListProps = {
  items: SmartPhrase[]
  command: (item: SmartPhrase) => void
  hide: boolean
  editor: Editor
  handleClose: () => void
}

export const SmartPhraseList = forwardRef<SmartPhraseListForwardRef, SmartPhraseListProps>(
  (props: SmartPhraseListProps, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const {
      radius,
      colors,
      other: { colors: otherColors, sizes },
    } = useMantineTheme()

    const elementRefs = useRef<(HTMLDivElement | null)[]>([])
    const modalRef = useRef<HTMLDivElement>(null)
    useOnClickOutside(modalRef, props.handleClose)

    const selectItem = (index: number) => {
      const item = props.items[index]

      if (item) {
        props.command(item)
        props.editor.commands.focus()
      }
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        if (event.key === 'ArrowUp') {
          setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
          return true
        }

        if (event.key === 'ArrowDown') {
          setSelectedIndex((selectedIndex + 1) % props.items.length)
          return true
        }

        if (event.key === 'Enter') {
          // Prevent 'Enter' from submitting form
          event.stopPropagation()
          selectItem(selectedIndex)
          return true
        }

        return false
      },
    }))

    useEffect(() => {
      // This enables scrolling with arrow keys
      const selectedItem = elementRefs.current[selectedIndex]
      if (selectedItem) {
        selectedItem.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
        })
        selectedItem.focus()
      }
    }, [selectedIndex])

    return (
      <Stack
        spacing={0}
        bg={colors.dark[0]}
        mr='sm'
        ref={modalRef}
        style={{
          visibility: props.hide ? 'hidden' : 'visible',
          borderStyle: 'solid',
          borderWidth: sizes.border.md,
          borderColor: otherColors.actions[0],
          borderRadius: radius.md,
          overflow: 'clip',
          maxHeight: '500px',
          overflowY: 'scroll',
          maxWidth: '100%',
          wordBreak: 'break-word',
        }}
      >
        {props.items.length ? (
          props.items.map((item, index) => (
            <Tooltip key={item.id} multiline label={item.text} w='100%'>
              <Box
                bg={index === selectedIndex ? colors.dark[1] : colors.dark[0]}
                p='sm'
                style={{ cursor: 'pointer' }}
                onClick={() => selectItem(index)}
                ref={ref => {
                  elementRefs.current = { ...elementRefs.current, [index]: ref }
                }}
              >
                <Text bold color={otherColors.text[0]} size='md' lineClamp={1}>
                  {item.label}
                </Text>
                <Text color={otherColors.text[0]} size='xs' lineClamp={1}>
                  {item.text}
                </Text>
              </Box>
            </Tooltip>
          ))
        ) : (
          <Box p='sm'>
            <Text color={otherColors.text[0]} size='md'>
              No result
            </Text>
          </Box>
        )}
      </Stack>
    )
  },
)

SmartPhraseList.displayName = 'SmartPhraseList'
