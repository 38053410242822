import {
  LevelOfCareStatus,
  Patient,
  PatientModel,
  PatientSource,
  PatientStatus,
  StateName,
  getStateAbbr,
} from '.'

export const hasStatus = (
  patient?: { statuses: { patient: PatientStatus } },
  ...statuses: PatientStatus[]
) => {
  return statuses.some(status => status === patient?.statuses?.patient)
}

export const hasHomeState = (
  patient?: { homeData?: Patient['homeData'] },
  ...states: StateName[]
) => {
  return states.some(state => state === patient?.homeData?.state)
}

export const getStateAbbrForPatient = (
  patient:
    | {
        homeData?: { state?: string }
        shippingData?: { state?: string }
      }
    | undefined
    | null,
) => getStateAbbr(patient?.homeData?.state ?? patient?.shippingData?.state ?? '')

export const hasLevelOfCare = (
  patient?: Patient | PatientModel | null,
  ...levelsOfCare: LevelOfCareStatus[]
) => {
  return levelsOfCare.some(levelOfCare => levelOfCare === patient?.statuses?.levelOfCare)
}

export const levelOfCareToDaysBetweenVisit: Record<LevelOfCareStatus, number> = {
  weekly: 7,
  biweekly: 14,
  monthly: 28,
  'n/a': 120,
}

const offlineSources: PatientSource[] = ['Bucks County Correctional Facility', 'Livengrin']

export const isPatientOnboardingOffline = (patient: Patient | PatientModel | null | undefined) =>
  patient?.account?.source && offlineSources.includes(patient.account.source)
