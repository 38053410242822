export type BadgeType =
  | '1_referral_sent'
  | '3_referral_sent'
  | '5_referral_sent'
  | '1_thank_you_received'
  | '3_thank_you_received'
  | '6_thank_you_received'

export type BadgeContentType = {
  label: string
  value: BadgeType
  description: string
  imageUrl: string
  iconUrl: string
  message: string
}

const referralBadges = 'badges/referrals/'

export const badgesMap: BadgeContentType[] = [
  {
    label: 'One referral sent',
    value: '1_referral_sent',
    description: 'This is a badge description',
    imageUrl: ``,
    iconUrl: `${referralBadges}1_icon.png`,
    message: 'Congratulations! You did a thing!',
  },
  {
    label: 'Three referrals sent',
    value: '3_referral_sent',
    description: 'This is a badge description',
    imageUrl: '',
    iconUrl: `${referralBadges}3_icon.png`,
    message: 'Congratulations! You did a thing!',
  },
  {
    label: 'Five referrals sent',
    value: '5_referral_sent',
    description: 'This is a badge description',
    imageUrl: '',
    iconUrl: `${referralBadges}5_icon.png`,
    message: 'Congratulations! You did a thing!',
  },
  {
    label: 'One Thank You Received',
    value: '1_thank_you_received',
    description: 'This is a badge description',
    imageUrl: 'www.google.com',
    iconUrl: 'www.google.com',
    message: 'Congratulations! You did a thing!',
  },
  {
    label: 'Three Thank Yous Received',
    value: '3_thank_you_received',
    description: 'This is a badge description',
    imageUrl: 'www.google.com',
    iconUrl: 'www.google.com',
    message: 'Congratulations! You did a thing!',
  },
  {
    label: 'Six Thank Yous Received',
    value: '6_thank_you_received',
    description: 'This is a badge description',
    imageUrl: 'www.google.com',
    iconUrl: 'www.google.com',
    message: 'Congratulations! You did a thing!',
  },
]
