import { useForm } from '@mantine/form'
import { Box, Divider, Grid, Text, TitleFour, validateWith } from '@shared/components'
import React from 'react'
import { UploadPhoto } from '../../../common/components'
import { isFileNotLoading, isRequired } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const InsuranceCardUploadStep = ({
  initialValues,
  submit,
  saving,
}: TaskStepProps<'insurance'>) => {
  const hasSecondaryInsurance = Boolean(initialValues.secondaryInsurancePlan)
  const primaryInsuranceValidation = {
    frontInsuranceCard: validateWith(isRequired, isFileNotLoading),
    backInsuranceCard: validateWith(isRequired, isFileNotLoading),
  }
  const secondaryInsuranceValidation = {
    secondaryFrontInsuranceCard: validateWith(isRequired, isFileNotLoading),
    secondaryBackInsuranceCard: validateWith(isRequired, isFileNotLoading),
  }
  const insuranceCardValidation = hasSecondaryInsurance
    ? {
        ...primaryInsuranceValidation,
        ...secondaryInsuranceValidation,
      }
    : {
        ...primaryInsuranceValidation,
      }

  const { values, validate, getInputProps, setFieldError } = useForm({
    initialValues,
    validate: insuranceCardValidation,
  })

  function onSubmit() {
    if (!validate().hasErrors) {
      submit('card', values)
    }
  }

  return (
    <Box test-id='step:insurance-card-upload'>
      <Grid>
        <Grid.Col span={12}>
          <Text>Make sure your full insurance card is in frame and legible.</Text>
        </Grid.Col>
        {hasSecondaryInsurance && (
          <Grid.Col span={12}>
            <TitleFour>Primary insurance</TitleFour>
          </Grid.Col>
        )}
        <Grid.Col span={12} md={6}>
          <UploadPhoto
            test-id='input:primary-insurance-card-front'
            label='Front of insurance card'
            setError={error => setFieldError('frontInsuranceCard', error)}
            disabled={saving}
            {...getInputProps('frontInsuranceCard')}
          />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <UploadPhoto
            test-id='input:primary-insurance-card-back'
            label='Back of insurance card'
            setError={error => setFieldError('backInsuranceCard', error)}
            disabled={saving}
            {...getInputProps('backInsuranceCard')}
          />
        </Grid.Col>
        {!hasSecondaryInsurance && (
          <Grid.Col span={12}>
            <TaskButton onSubmit={onSubmit} isLastStep loading={saving} />
          </Grid.Col>
        )}
      </Grid>
      {hasSecondaryInsurance && (
        <>
          <Divider my='lg' />
          <Grid>
            <Grid.Col span={12}>
              <TitleFour>Other insurance</TitleFour>
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <UploadPhoto
                label='Front of insurance card'
                setError={error => setFieldError('secondaryFrontInsuranceCard', error)}
                disabled={saving}
                {...getInputProps('secondaryFrontInsuranceCard')}
              />
            </Grid.Col>
            <Grid.Col span={12} md={6}>
              <UploadPhoto
                label='Back of insurance card'
                setError={error => setFieldError('secondaryBackInsuranceCard', error)}
                disabled={saving}
                {...getInputProps('secondaryBackInsuranceCard')}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <TaskButton onSubmit={onSubmit} isLastStep loading={saving} />
            </Grid.Col>
          </Grid>
        </>
      )}
    </Box>
  )
}
