import { ReferralPartner } from '@shared/types'
import { useParams } from 'react-router-dom'
import { usePortalQuery } from './use-portal-query'

export const useReferralPartner = () => {
  const { referralPartnerId } = useParams<{ referralPartnerId: ReferralPartner['oid'] }>()

  const referralPartnerQuery = usePortalQuery(
    'GET /referralPartners/:referralPartnerId',
    {
      params: { referralPartnerId: referralPartnerId ?? '' },
    },
    {
      /*
       * Referral Partner data should not change very frequently,
       * so we'll set the stale/cache time to 15 min
       */
      enabled: Boolean(referralPartnerId),
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  const partner = referralPartnerQuery.data

  return {
    partner,
    partnerId: partner?.oid,
    partnerPath: referralPartnerId ? `/${referralPartnerId}` : '',
    isPartnerRoute: Boolean(referralPartnerId),
    isPartnerValid: Boolean(partner && referralPartnerId === partner.oid),
    // Only show loading state on the first fetch
    loading: referralPartnerQuery.isFetching && !referralPartnerQuery.isRefetching,
  }
}
