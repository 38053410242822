import { Stack, TitleThree, TitleTwo, useLifecycle } from '@shared/components'
import { sortBy } from '@shared/utils'
import React from 'react'
import { useQuery } from 'react-query'
import { patientApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { CardMasonry } from '../../../common/components/card/CardMasonry'
import { sendPageEvent } from '../../../common/rudderstack'
import { PastPrescriptionEntry } from './PastPrescriptionEntry'
import { EmptyPrescriptionCard, PrescriptionCard } from './PrescriptionCard'

export const Prescriptions = () => {
  useLifecycle({
    onMount: () => sendPageEvent('Prescriptions'),
  })

  const [currentPrescriptionsQueryKey, currentPrescriptionsQueryFn] = patientApi.getQuery(
    'GET /prescriptions',
    {
      query: { type: 'current' },
    },
  )
  const currentPrescriptionsQuery = useQuery(
    currentPrescriptionsQueryKey,
    currentPrescriptionsQueryFn,
  )
  const currentPrescriptions = currentPrescriptionsQuery.data ?? []

  const [pastPrescriptionsQueryKey, pastPrescriptionsQueryFn] = patientApi.getQuery(
    'GET /prescriptions',
    {
      query: { type: 'historical' },
    },
  )
  const pastPrescriptionsQuery = useQuery(pastPrescriptionsQueryKey, pastPrescriptionsQueryFn)
  const pastPrescriptions = pastPrescriptionsQuery.data ?? []

  return (
    <Stack test-id='page:prescriptions' spacing='lg'>
      <TitleTwo>Prescriptions</TitleTwo>
      <CardMasonry
        isLoading={currentPrescriptionsQuery.isLoading || currentPrescriptionsQuery.isLoadingError}
      >
        {currentPrescriptions.length
          ? currentPrescriptions
              ?.sort(sortBy({ key: 'writtenDate', order: 'DESC' }))
              .map(prescription => (
                <PrescriptionCard key={prescription.prescriptionId} prescription={prescription} />
              ))
          : [<EmptyPrescriptionCard title='Active prescription' key='prescription' />]}
      </CardMasonry>
      {(pastPrescriptionsQuery.isLoading || pastPrescriptionsQuery.isLoadingError) && (
        <Skeletons type='entries' />
      )}
      {Boolean(pastPrescriptions.length) && (
        <>
          <TitleThree>Past prescriptions</TitleThree>
          <Stack>
            {pastPrescriptions
              ?.sort(sortBy({ key: 'writtenDate', order: 'DESC' }))
              .map(prescription => (
                <PastPrescriptionEntry
                  key={prescription.prescriptionId}
                  prescription={prescription}
                />
              ))}
          </Stack>
        </>
      )}
    </Stack>
  )
}
