import {
  ChevronRightIcon,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  Text,
} from '@shared/components'
import React from 'react'
import { Link } from 'react-router-dom'
import { PartnerLogo } from '../../../../welcome/pages/PartnerLogo'
import { useReferralPartner } from '../../../hooks/use-partner'
import { routes } from '../../../routes'

export const ReferringProviderWithPatientConfirmation = () => {
  const { partner, partnerPath } = useReferralPartner()

  return (
    <Stack spacing='lg' test-id='page:partner-with-patient-confirmation'>
      <Stack spacing='md'>
        <PartnerLogo partner={partner} />
        <Text>
          The rest of our questions are for the patient, so they should take over from here, but
          please stay with them to help complete the next steps.
        </Text>
      </Stack>
      <ResponsiveBox
        mobile={
          <PrimaryButton
            fullWidth
            rightIcon={<ChevronRightIcon />}
            component={Link}
            to={`${routes.welcome.index}${partnerPath}/${routes.welcome.children.signup}`}
            test-id='button:start@mobile'
          >
            Get started
          </PrimaryButton>
        }
        desktop={
          <Group noWrap position='right'>
            <PrimaryButton
              rightIcon={<ChevronRightIcon />}
              component={Link}
              to={`${routes.welcome.index}${partnerPath}/${routes.welcome.children.signup}`}
              test-id='button:start@desktop'
            >
              Get started
            </PrimaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
