import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Checkbox,
  CheckboxGroup,
  Group,
  PrimaryButton,
  ResponsiveBox,
  SecondaryButton,
  Select,
  Stack,
  TertiaryButton,
  TextInput,
  TitleTwo,
  isAnySelected,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmail, isRequired } from '../../../forms'
import { setSessionStorageItem } from '../../../storage'
import { WidgetProps } from '../PromptContentWidget'

enum PartnershipInterestValues {
  Interest = 'interest',
}

type ReferralContactFormType = {
  referralType: string
  email: string
  organization: string
  partnershipInterest: string[]
}

const FRIEND: PromptResponseContextKey = 'type_of_referral_friend'
const COLLEAGUE: PromptResponseContextKey = 'type_of_referral_colleague'
const SPOUSE_PARTNER: PromptResponseContextKey = 'type_of_referral_spouse_partner'
const FAMILY: PromptResponseContextKey = 'type_of_referral_family'
const PROVIDER: PromptResponseContextKey = 'type_of_referral_provider'
const PROVIDER_WITH_PATIENT: PromptResponseContextKey = 'type_of_referral_provider_with_patient'
const SELF: PromptResponseContextKey = 'type_of_referral_self'

const TypesOfReferrals: { label: string; value: PromptResponseContextKey }[] = [
  {
    label: 'Healthcare provider',
    value: PROVIDER,
  },
  {
    label: 'Family member',
    value: FAMILY,
  },
  {
    label: 'Friend',
    value: FRIEND,
  },
  {
    label: 'Spouse/partner',
    value: SPOUSE_PARTNER,
  },
  {
    label: 'Colleague',
    value: COLLEAGUE,
  },
  {
    label: 'I’m interested in Ophelia for myself',
    value: SELF,
  },
]

export const TypeOfReferral = ({ onSubmit }: WidgetProps) => {
  const navigate = useNavigate()
  const onBack = () => navigate(-1)
  const { sessionId, promptId } = useParams()

  const form = useForm<ReferralContactFormType>({
    validateInputOnBlur: ['referralType'],
    initialValues: {
      referralType: '',
      email: '',
      organization: '',
      partnershipInterest: [],
    },
    validate: {
      email: validateWith(
        skipIfOtherField('referralType', 'is', FAMILY),
        skipIfOtherField('referralType', 'is', FRIEND),
        skipIfOtherField('referralType', 'is', SPOUSE_PARTNER),
        skipIfOtherField('referralType', 'is', COLLEAGUE),
        skipIfOtherField('referralType', 'is', SELF),
        isRequired,
        isEmail,
      ),
      organization: validateWith(
        skipIfOtherField('referralType', 'is', FAMILY),
        skipIfOtherField('referralType', 'is', FRIEND),
        skipIfOtherField('referralType', 'is', SPOUSE_PARTNER),
        skipIfOtherField('referralType', 'is', COLLEAGUE),
        skipIfOtherField('referralType', 'is', SELF),
        isRequired,
      ),
      referralType: isAnySelected(TypesOfReferrals, 'Required'),
    },
  })

  const submitHandler = ({ isWithPatient = false }: { isWithPatient?: boolean }) => {
    if (form.validate().hasErrors) {
      return
    }
    if (form.values.referralType === SELF) {
      return navigate('/welcome')
    }

    if (isWithPatient && sessionId) {
      setSessionStorageItem('referral_workflow_prompt_response', `${sessionId}_${promptId}`)
    }

    const friendReferralArray: PromptResponseContextKey[] = [
      FRIEND,
      SPOUSE_PARTNER,
      FAMILY,
      COLLEAGUE,
    ]

    if (friendReferralArray.includes(form.values.referralType as PromptResponseContextKey)) {
      return onSubmit({
        value: {
          contextKey: form.values.referralType as PromptResponseContextKey,
        },
      })
    }

    onSubmit({
      value: {
        contextKey: isWithPatient ? PROVIDER_WITH_PATIENT : PROVIDER,
      },
      provider_email: {
        contextKey: 'custom',
        value: form.values.email,
      },
      provider_organization: {
        contextKey: 'custom',
        value: form.values.organization,
      },
      partnership_interest: {
        contextKey: 'custom',
        value: form.values.partnershipInterest.includes(PartnershipInterestValues.Interest),
      },
    })
  }

  return (
    <Stack spacing='md'>
      <TitleTwo>First, how are you acquainted with the person you’re referring?</TitleTwo>
      <Select
        data={TypesOfReferrals}
        label='Relationship to referral'
        placeholder='Select one...'
        {...form.getInputProps('referralType')}
      />

      {form.values.referralType === PROVIDER && (
        <Stack spacing='md'>
          <TextInput
            type='email'
            placeholder='Work email'
            label='Work email'
            {...form.getInputProps('email')}
          ></TextInput>
          <TextInput
            placeholder='Organization name'
            label='Organization name'
            {...form.getInputProps('organization')}
          ></TextInput>
          <CheckboxGroup {...form.getInputProps('partnershipInterest')}>
            <Checkbox
              value={PartnershipInterestValues.Interest}
              label='I’m interested in partnering with Ophelia to refer more patients'
            />
          </CheckboxGroup>
        </Stack>
      )}
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' align='center'>
            <PrimaryButton
              test-id='button:next@mobile'
              fullWidth
              onClick={() => submitHandler({ isWithPatient: false })}
            >
              Next
            </PrimaryButton>
            {form.values.referralType === PROVIDER && (
              <SecondaryButton
                test-id='button:with-the-patient@desktop'
                onClickCapture={() => submitHandler({ isWithPatient: true })}
              >
                I&apos;m with the patient
              </SecondaryButton>
            )}
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <Group position='right'>
              {form.values.referralType === PROVIDER && (
                <SecondaryButton
                  test-id='button:with-the-patient@desktop'
                  onClickCapture={() => submitHandler({ isWithPatient: true })}
                >
                  I&apos;m with the patient
                </SecondaryButton>
              )}
              <PrimaryButton
                test-id='button:next@desktop'
                onClick={() => submitHandler({ isWithPatient: false })}
              >
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
