// eslint-disable-next-line @typescript-eslint/ban-types
export type DeepPartial<T> = T extends Function
  ? T
  : T extends (infer InferrerArrayMember)[]
  ? DeepPartialArray<InferrerArrayMember>
  : T extends object
  ? DeepPartialObject<T>
  : T | undefined

export type DeepPartialArray<T> = DeepPartial<T>[]

export type DeepPartialObject<T> = {
  [Key in keyof T]?: DeepPartial<T[Key]>
}

/** Type that requires at least one of properties in Keys to be defined on T. */
export type RequireAtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> & DeepPartial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

export type ValueOf<T> = T[keyof T]

export type PaginatedListResponse<T> = {
  items: T[]
  pageCount: number
  totalCount: number
  nextPageToken: string | null
  prevPageToken: string | null
  isFirstPage: boolean
  isLastPage: boolean
}

/**
 * This is a typescript hack to allow us to get intellisense on string literals, but still allow any string to be used.
 */
export type OtherString = string & {}

export function arrayIncludes<T extends U, U>(array: T[] | readonly T[], value: U): value is T {
  return array.includes(value as T)
}

export function keys<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[]
}

// This gets all public properties of a class. This is useful for mocking classes in tests.
export type PublicProperties<TClass> = Pick<TClass, keyof TClass>

/**
 * This creates the dot notation for nested keys in an object.
 * Example: NestedKeyOf<{ a: { b: { c: string } } }> = 'a' | 'a.b' | 'a.b.c'
 * Copied from: https://medium.com/xgeeks/typescript-utility-keyof-nested-object-fa3e457ef2b2
 */
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`
}[keyof ObjectType & (string | number)]

export const stringifyNumber = (value: number) => value.toString() as `${number}`
