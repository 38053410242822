const OPHELIA_ZOOM_HOST = 'ophelia.zoom.us'

export const validateZoomUrl = (zoomUrl: string | null) => {
  try {
    const url = new URL(zoomUrl || '')
    return url.host === OPHELIA_ZOOM_HOST ? zoomUrl : null
  } catch {
    return null
  }
}
