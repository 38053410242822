import {
  CurrentUserPatient,
  PasswordType,
  SetEmailAndConsentsRequestBody,
  SetEmailAndConsentsResponse,
  UpdateMeRequestBody,
} from '@shared/types'
import { BaseApi } from '@shared/utils'

export class CurrentUserApi extends BaseApi {
  retrieve = () => {
    return this.json<CurrentUserPatient>({
      method: 'GET',
      url: '/me',
    })
  }

  update = (data: UpdateMeRequestBody) => {
    return this.json<void>({
      method: 'PUT',
      url: '/me',
      data,
    })
  }

  setEmailAndConsents = (data: SetEmailAndConsentsRequestBody) => {
    return this.json<SetEmailAndConsentsResponse>({
      method: 'PUT',
      url: '/email',
      data,
    })
  }

  resendEmail = (data: { email: string }) => {
    return this.json<void>({
      method: 'POST',
      url: '/email/resend',
      data,
    })
  }

  setPassword = (data: { plainNewPassword: string; passwordType: PasswordType }) => {
    return this.json<{ token: string }>({
      method: 'PUT',
      url: '/password',
      data,
    })
  }

  resetPassword = (data: { plainNewPassword: string; passwordType: PasswordType }) => {
    return this.json<{ token: string }>({
      method: 'PUT',
      url: '/password/reset',
      data,
    })
  }

  requestOTP = (params: { phone: string }) => {
    return this.json<void>({
      method: 'GET',
      url: '/login/otp',
      params,
    })
  }

  loginWithOTP = (data: { phone: string; code: string }) => {
    return this.json<{ token: string }>({
      method: 'POST',
      url: '/login/otp',
      data,
    })
  }

  loginWithPassword = (data: { password: string }) => {
    return this.json<{ token: string }>({
      method: 'POST',
      url: '/login',
      data,
    })
  }

  requestPasswordReset = () => {
    return this.json<void>({
      method: 'POST',
      url: '/password/reset',
    })
  }

  getPasswordResetToken = (tokenId: string) => {
    return this.json<{ token: string }>({
      method: 'GET',
      url: `/password/reset/token/${tokenId}`,
    })
  }
}
