import { GetInputProps } from '@mantine/form/lib/types'
import { Grid, Select, TextInput } from '@shared/components'
import { stateNames } from '@shared/types'
import { validCharacters } from '@shared/utils'
import React from 'react'

type InputProps = ReturnType<GetInputProps<unknown>>

type AddressFormProps = {
  label?: string
  disabled?: boolean
  addressInputProps: InputProps
  addressTwoInputProps: InputProps
  cityInputProps: InputProps
  stateInputProps: InputProps
  zipInputProps: InputProps
  testIdSuffix?: string
}

export const AddressForm = ({
  label,
  disabled = false,
  addressInputProps,
  addressTwoInputProps,
  cityInputProps,
  stateInputProps,
  zipInputProps,
  testIdSuffix,
}: AddressFormProps) => {
  return (
    <>
      <Grid.Col span={12} md={6}>
        <TextInput
          label={label ?? 'Address'}
          placeholder='Address'
          maxLength={64}
          disabled={disabled}
          formatter={validCharacters.address}
          test-id={`input:address-one${testIdSuffix}`}
          {...addressInputProps}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Address 2 (optional)'
          placeholder='Unit, room, etc.'
          maxLength={64}
          disabled={disabled}
          formatter={validCharacters.address}
          test-id={`input:address-two${testIdSuffix}`}
          {...addressTwoInputProps}
        />
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <TextInput
          label='City'
          maxLength={64}
          placeholder='City'
          disabled={disabled}
          formatter={validCharacters.city}
          test-id={`input:city${testIdSuffix}`}
          {...cityInputProps}
        />
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <Select
          label='State'
          placeholder='Select one...'
          data={stateNames}
          disabled={disabled}
          searchable
          clearable
          filterDataOnExactSearchMatch
          test-id={`input:state${testIdSuffix}`}
          {...stateInputProps}
        />
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <TextInput
          label='Zip'
          placeholder='5-digit zip code'
          maxLength={5}
          disabled={disabled}
          formatter={validCharacters.numbers}
          inputMode='numeric'
          test-id={`input:zip-code${testIdSuffix}`}
          {...zipInputProps}
        />
      </Grid.Col>
    </>
  )
}
