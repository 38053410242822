import { z } from 'zod'

import { DAYS_READONLY } from '../../../account'
import { ISOStringSchema, YYYYMMDDSchema } from '../../../dates'
import {
  PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY,
  PULLING_FORWARD_FOLLOW_UP_STANDBY_APPOINTMENT,
  PULLING_FORWARD_NEXT_APPOINTMENT,
  PUSHING_BACK_NEXT_APPOINTMENT,
} from '../../../models/employee'
import { CallOutReasons } from '../../emr'
import { ApiSchemas } from '../../utils'

export const CreateAppointment = {
  req: z.object({
    body: z.object({
      patientId: z.string(),
      appointmentType: z.string(),
      calendarId: z.string(),
      datetime: ISOStringSchema,
      metadata: z
        .object({
          shouldSendUdsReminder: z.boolean().optional(),
          patientEligibleForHoldRequestedSpecificAppointmentTime: z.boolean().optional(),
        })
        .optional(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const GetHoldsForCalendarReqRes = {
  req: z.object({
    params: z.object({
      calendarId: z.string(),
    }),
    query: z.object({
      start: YYYYMMDDSchema,
      end: YYYYMMDDSchema,
    }),
  }),
  res: z.object({
    data: z.array(
      z.object({
        holdId: z.string(),
        patientId: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        intendedDay: YYYYMMDDSchema,
        clinicianName: z.string(),
        calendarId: z.number(),
      }),
    ),
  }),
}

export const GetHoldsForPatientReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.array(
      z.object({
        oid: z.string(),
        clinicianName: z.string(),
        appointmentTypeId: z.number(),
        intendedDay: YYYYMMDDSchema,
        scheduledAt: ISOStringSchema,
        sendUdsReminder: z.boolean(),
      }),
    ),
  }),
}

export const DeleteCalendarHoldReqRes = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
  requestSchemaStrictValidation: true,
}

export const PostCalendarHoldReqRes = {
  req: z.object({
    body: z.object({
      calendarId: z.number(),
      numWeeks: z.number(),
      patientId: z.string(),
      appointmentTypeId: z.number(),
      sendUdsReminder: z.boolean(),
      availability: z
        .object({
          morning: z.array(z.enum(DAYS_READONLY)),
          afternoon: z.array(z.enum(DAYS_READONLY)),
          evening: z.array(z.enum(DAYS_READONLY)),
        })
        .optional(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const PutCalendarHoldReqRes = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
    body: z.object({
      calendarId: z.number(),
      numWeeks: z.number(),
      patientId: z.string(),
      appointmentTypeId: z.number(),
      sendUdsReminder: z.boolean(),
      availability: z
        .object({
          morning: z.array(z.enum(DAYS_READONLY)),
          afternoon: z.array(z.enum(DAYS_READONLY)),
          evening: z.array(z.enum(DAYS_READONLY)),
        })
        .optional(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const PutCalendarHoldDetailsReqRes = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
    body: z.object({
      calendarId: z.number(),
      intendedDay: YYYYMMDDSchema,
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const SaveCallOutToDbReqRes = {
  req: z.object({
    body: z.object({
      outFrom: ISOStringSchema,
      outTo: ISOStringSchema,
      couldBeOutLonger: z.enum(['yes', 'no']),
      reason: z.enum(CallOutReasons),
      reasonFreeText: z.string().optional(),
      calendarId: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const GetClinicianCapacityReqRes = {
  req: z.object({
    params: z.object({
      employeeId: z.string(),
    }),
    query: z.object({
      startDate: YYYYMMDDSchema,
      endDate: YYYYMMDDSchema,
      appointmentTypeId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      numMinutesOfForecastedCapacityIncludingHolds: z.number(),
      hasCapacityForHold: z.boolean(),
    }),
  }),
}

export const getAvailableCliniciansForPatientReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      clinicians: z.array(
        z.object({
          oid: z.string(),
          name: z.string(),
          role: z.string(),
          profilePhotoURL: z.string().optional(),
        }),
      ),
    }),
  }),
}

export const GetClinicianAvailabilityReqRes = {
  req: z.never(),
  res: z.object({
    data: z.object({
      availability: z
        .object({
          status: z
            .enum([
              PULLING_FORWARD_NEXT_APPOINTMENT,
              PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY,
              PULLING_FORWARD_FOLLOW_UP_STANDBY_APPOINTMENT,
              PUSHING_BACK_NEXT_APPOINTMENT,
            ])
            .nullable(),
          appointmentId: z.string().nullable(),
          expiresAt: ISOStringSchema.nullable(),
        })
        .nullable(),
      currentAppointment: z
        .object({
          startsAt: ISOStringSchema,
          duration: z.string(),
          appointmentId: z.number(),
          patientId: z.string(),
          patientName: z.string(),
        })
        .nullable(),
      nextAppointment: z
        .object({
          startsAt: ISOStringSchema,
          duration: z.string(),
          appointmentId: z.number(),
          patientId: z.string(),
          patientName: z.string(),
        })
        .nullable(),
      remainingAppointmentsToday: z.array(
        z.object({
          startsAt: ISOStringSchema,
          duration: z.string(),
          appointmentId: z.number(),
          patientId: z.string(),
          patientName: z.string(),
        }),
      ),
    }),
  }),
}

export const UpdateClinicianAvailabilityReqRes = {
  req: z.object({
    body: z.object({
      availabilityStatus: z.enum([
        PULLING_FORWARD_NEXT_APPOINTMENT,
        PULLING_FORWARD_ANOTHER_APPOINTMENT_SCHEDULED_TODAY,
        PULLING_FORWARD_FOLLOW_UP_STANDBY_APPOINTMENT,
        PUSHING_BACK_NEXT_APPOINTMENT,
      ]),
      appointmentId: z.string(),
      pushBackByMinutes: z.number().optional(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const SchedulingRouteSchemas = {
  'POST /scheduling/appointment': CreateAppointment,
  'POST /scheduling/appointment/admin': CreateAppointment,
  'GET /scheduling/calendars/:calendarId/holds': GetHoldsForCalendarReqRes,
  'GET /scheduling/patients/:patientId/holds': GetHoldsForPatientReqRes,
  'POST /scheduling/call-out': SaveCallOutToDbReqRes,
  'POST /scheduling/holds': PostCalendarHoldReqRes,
  'DELETE /scheduling/holds/:id': DeleteCalendarHoldReqRes,
  'PUT /scheduling/holds/:id': PutCalendarHoldReqRes,
  'GET /scheduling/:patientId/clinicians': getAvailableCliniciansForPatientReqRes,
  'PUT /scheduling/holds-details/:id': PutCalendarHoldDetailsReqRes,
  'GET /scheduling/employees/:employeeId/capacity': GetClinicianCapacityReqRes,
  'GET /scheduling/availability': GetClinicianAvailabilityReqRes,
  'POST /scheduling/availability': UpdateClinicianAvailabilityReqRes,
} satisfies ApiSchemas
