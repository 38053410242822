import { useForm } from '@mantine/form'
import {
  DateInput,
  Grid,
  Select,
  TextInput,
  validateWith,
  isAnySelected,
  skipIfEmpty,
} from '@shared/components'
import { pronouns, Sex } from '@shared/types'
import { validCharacters } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import React, { useMemo } from 'react'
import { isBirthday, isRequired, isAtleastOneWord } from '../../../common/forms'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const PersonalInfoStep = ({ initialValues, submit, saving }: TaskStepProps<'profile'>) => {
  const sexData = useMemo(
    () =>
      (['male', 'female'] as Sex[]).map(value => ({
        value,
        label: capitalize(value),
      })),
    [],
  )

  const pronounsData = useMemo(
    () => pronouns.map(value => ({ value, label: capitalize(value) })),
    [],
  )

  const { values, validate, getInputProps } = useForm({
    initialValues,
    validate: {
      legalFirstName: validateWith(isRequired, isAtleastOneWord),
      legalLastName: validateWith(isRequired, isAtleastOneWord),
      preferredName: validateWith(skipIfEmpty, isAtleastOneWord),
      birthday: validateWith(isRequired, isBirthday),
      sex: isAnySelected(sexData, 'Required'),
    },
  })

  function onSubmit() {
    if (!validate().hasErrors) {
      submit('personal', values)
    }
  }

  const disableChange = Boolean(
    initialValues.legalFirstName &&
      initialValues.legalLastName &&
      initialValues.birthday &&
      initialValues.sex,
  )

  return (
    <Grid test-id='step:personal-info'>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Legal first name'
          placeholder='Legal first name'
          warning={
            !disableChange &&
            'You may not be able to change your legal first name later, so make sure it’s correct now'
          }
          maxLength={64}
          disabled={saving || disableChange}
          formatter={validCharacters.name}
          test-id='input:first-name'
          {...getInputProps('legalFirstName')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Legal last name'
          placeholder='Legal last name'
          warning={
            !disableChange &&
            'You may not be able to change your legal last name later, so make sure it’s correct now'
          }
          maxLength={64}
          disabled={saving || disableChange}
          formatter={validCharacters.name}
          test-id='input:last-name'
          {...getInputProps('legalLastName')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Preferred name (optional)'
          placeholder='Preferred name'
          maxLength={64}
          disabled={saving}
          formatter={validCharacters.name}
          test-id='input:preferred-name'
          {...getInputProps('preferredName')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <DateInput
          label='Birthday'
          {...getInputProps('birthday')}
          disabled={saving || disableChange}
          warning={
            !disableChange &&
            'You may not be able to change your birthday later, so make sure it’s correct now'
          }
          test-id='input:birthday'
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <Select
          label='Pronouns'
          placeholder='Select one...'
          data={pronounsData}
          disabled={saving}
          {...getInputProps('pronouns')}
          test-id='input:pronouns'
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <Select
          label='Sex assigned at birth'
          placeholder='Select one...'
          data={sexData}
          warning={
            !disableChange &&
            'You may not be able to change your sex assigned at birth later, so make sure it’s correct now'
          }
          disabled={saving || disableChange}
          test-id='input:birth-sex'
          {...getInputProps('sex')}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TaskButton test-id='button:submit' onSubmit={onSubmit} loading={saving} />
      </Grid.Col>
    </Grid>
  )
}
