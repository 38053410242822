import { useForm } from '@mantine/form'
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  Text,
  TitleTwo,
  isAnySelected,
} from '@shared/components'
import { PromptResponseContextKey, ValueOf } from '@shared/types'
import React from 'react'
import { WidgetProps } from '../../PromptContentWidget'

type PsychDiagnosesHistoryForm = {
  options: PromptResponseContextKey[]
}

const psychDiagnosesHistoryOptions = {
  past_psych_diagnoses_depressive_disorder: 'past_psych_diagnoses_depressive_disorder',
  past_psych_diagnoses_anxiety_disorder: 'past_psych_diagnoses_anxiety_disorder',
  past_psych_diagnoses_bipolar_disorder: 'past_psych_diagnoses_bipolar_disorder',
  past_psych_diagnoses_ptsd: 'past_psych_diagnoses_ptsd',
  past_psych_diagnoses_panic_disorder: 'past_psych_diagnoses_panic_disorder',
  past_psych_diagnoses_adhd_add: 'past_psych_diagnoses_adhd_add',
  past_psych_diagnoses_ocd: 'past_psych_diagnoses_ocd',
  past_psych_diagnoses_schizophrenia: 'past_psych_diagnoses_schizophrenia',
  past_psych_diagnoses_personality_disorder: 'past_psych_diagnoses_personality_disorder',
  past_psych_diagnoses_no_conditions: 'past_psych_diagnoses_no_conditions',
}

export type PsychDiagnosesHistoryOptions = ValueOf<typeof psychDiagnosesHistoryOptions>

export const PsychDiagnosesHistory = ({ onSubmit }: WidgetProps) => {
  const psychDiagnosesHistoryForm = useForm<PsychDiagnosesHistoryForm>({
    initialValues: {
      options: [],
    },
    validate: {
      options: isAnySelected(Object.values(psychDiagnosesHistoryOptions), 'Required'),
    },
  })

  const disableAllOtherOptions = () =>
    psychDiagnosesHistoryForm.values.options.includes('past_psych_diagnoses_no_conditions')

  const submitHandler = () => {
    const { hasErrors } = psychDiagnosesHistoryForm.validate()

    if (hasErrors) {
      return
    }
    const answersArray = psychDiagnosesHistoryForm.values.options.filter(option => {
      if (psychDiagnosesHistoryForm.values.options.includes('past_psych_diagnoses_no_conditions')) {
        return option === 'past_psych_diagnoses_no_conditions'
      }
      return true
    })

    onSubmit({
      value: {
        contextKey: answersArray,
      },
      psych_diagnoses_history: {
        contextKey: answersArray,
        value: 'custom',
      },
    })
  }

  return (
    <Stack spacing='lg'>
      <Stack spacing='xs'>
        <TitleTwo>Have you ever been diagnosed with any of the following?</TitleTwo>
        <Text>Check all that apply.</Text>
      </Stack>
      <Stack spacing='md'>
        <Stack spacing='md'>
          <Box test-id='content:multiple-choice'>
            <CheckboxGroup {...psychDiagnosesHistoryForm.getInputProps('options')}>
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_depressive_disorder}
                label='Depression (Major depressive disorder)'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_anxiety_disorder}
                label='Anxiety disorder'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_bipolar_disorder}
                label='Bipolar disorder'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_ptsd}
                label='Post-traumatic stress disorder (PTSD)'
              />

              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_panic_disorder}
                label='Panic disorder'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_adhd_add}
                label='Attention deficit hyperactivity disorder (ADHD/ ADD)'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_ocd}
                label='Obsessive compulsive disorder (OCD)'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_schizophrenia}
                label='Schizophrenia or schizoaffective disorder'
              />
              <Checkbox
                disabled={disableAllOtherOptions()}
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_personality_disorder}
                label='Borderline personality disorder'
              />

              <Checkbox
                value={psychDiagnosesHistoryOptions.past_psych_diagnoses_no_conditions}
                label='I have not been diagnosed with any of these conditions'
              />
            </CheckboxGroup>
            <ResponsiveBox
              mt='lg'
              mobile={
                <Stack spacing='lg' align='center'>
                  <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
                    Next
                  </PrimaryButton>
                </Stack>
              }
              desktop={
                <Group position='apart'>
                  <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                    Next
                  </PrimaryButton>
                </Group>
              }
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
