import { ApiSchemas, OpheliaApiDefinitionFromSchemas } from '../utils'
import { CustomerDataPlatformLunaSchemas } from './customerDataPlatform'
import { EmrLunaSchemas } from './emr'
import { InboundCommunicationsLunaSchemas } from './inboundCommunications'
import { MyLunaSchemas } from './my'
export * from './customerDataPlatform'
export * from './emr'
export * from './inboundCommunications'
export * from './messageQueue'
export * from './my'

export const LunaSchemas = {
  my: MyLunaSchemas,
  emr: EmrLunaSchemas,
  inboundCommunications: InboundCommunicationsLunaSchemas,
  customerDataPlatform: CustomerDataPlatformLunaSchemas,
} satisfies {
  my: ApiSchemas
  emr: ApiSchemas
  inboundCommunications: ApiSchemas
  customerDataPlatform: ApiSchemas
}

export type LunaApiType = keyof typeof LunaSchemas
type AllLunaSchemas = (typeof LunaSchemas)[LunaApiType]

export type LunaApi = OpheliaApiDefinitionFromSchemas<AllLunaSchemas>

// Typescript can't seem to handle a simple `type LunaOperation = keyof AllLunaSchemas`, it resolves to never
export type LunaOperation =
  | keyof (typeof LunaSchemas)['my']
  | keyof (typeof LunaSchemas)['emr']
  | keyof (typeof LunaSchemas)['inboundCommunications']
  | keyof (typeof LunaSchemas)['customerDataPlatform']
