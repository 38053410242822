import { DischargeNoteModel } from './models'

export type PatientSelfDeterminesToStopReason =
  | 'financial'
  | 'incarceration'
  | 'discontinue_medication'
  | 'dissatisfaction_with_services'
  | 'platform_barriers'
  | 'accessibility_barriers'
  | 'transfer_to_hloc'
  | 'hospitalization'
  | 'relocation_out_of_state'
  | 'completed_medication'

export type HLOCCategory = 'residential' | 'inpatient' | 'intensive_outpatient'

export type HLOCSubcategory =
  | 'clinically_managed_low_intensity_residential'
  | 'clinicially_managed_high_intensity_residential'
  | 'intensive_outpatient'
  | 'medically_managed_residential'
  | 'high_intensity_outpatient'
  | 'medically_managed_intensive_outpatient'

export type PrescribingClinicianPreference = 'same_provider' | 'any_provider' | 'different_provider'

export type AlternateProviderStatus =
  | 'alternative_provider_connected'
  | 'alternative_provider_planning'
  | 'alternative_provider_not_planning'
  | 'other'

export type HLOCMedication =
  | 'transferring_to_outpatient_or_mat_buprenorphine'
  | 'transferring_to_outpatient_or_mat_methadone'
  | 'transferring_to_outpatient_or_mat_xr_naltrexone'

export type DischargeVisitStatus =
  | 'scheduled_and_attended'
  | 'scheduled_and_not_attended'
  | 'offered_and_declined'

export const reasonsForDischarge = [
  'failure_to_pay',
  'administrative_non_compliance_aggressive_threatening',
  'not_responding',
  'death',
  'patient_self_determines_to_stop',
  'medical_or_psychiatric_worsening',
] satisfies (keyof DischargeNoteModel)[]

export const dischargeVisitStatusMapping: { [Key in DischargeVisitStatus]: string } = {
  scheduled_and_attended: 'Yes, scheduled and attended',
  scheduled_and_not_attended: 'No, scheduled and not attended',
  offered_and_declined: 'No, offered and patient declined',
}

export const hlocMedicationMapping: {
  [Key in HLOCMedication]: string
} = {
  transferring_to_outpatient_or_mat_buprenorphine: 'Buprenorphine',
  transferring_to_outpatient_or_mat_methadone: 'Methadone',
  transferring_to_outpatient_or_mat_xr_naltrexone: 'XR-Naltrexone',
}

export const alternativeProviderStatusMapping: {
  [Key in AlternateProviderStatus]: string
} = {
  alternative_provider_connected:
    'Patient is connected to an alternative provider to continue treatment',
  alternative_provider_planning:
    'Patient is planning to connect to an alternative provider to continue treatment',
  alternative_provider_not_planning:
    'Patient is not planning to continue treatment with an alternative provider',
  other: 'Other',
}

export const hlocCategoryMapping: {
  [Key in HLOCCategory]: string
} = {
  inpatient: 'Level 4 - Inpatient',
  residential: 'Level 3 - Residential',
  intensive_outpatient: 'Level 2 - Intensive outpatient (IOP)/High intensity outpatient (HIOP)',
}

export const hlocSubcategoryMapping: {
  [Key in HLOCSubcategory]: string
} = {
  medically_managed_residential: 'Level 3.7 - Medically managed residential',
  clinicially_managed_high_intensity_residential:
    'Level 3.5 - Clinically managed high-intensity residential',
  clinically_managed_low_intensity_residential:
    'Level 3.1 - Clinically managed low-intensity residential',
  high_intensity_outpatient: 'Level 2.5 - High intensity outpatient (HIOP)',
  medically_managed_intensive_outpatient: 'Level 2.7 - Medically managed intensive outpatient',
  intensive_outpatient: 'Level 2.1 - Intensive outpatient (IOP)',
}

export const patientSelfDeterminesToStopReasonMapping: {
  [Key in PatientSelfDeterminesToStopReason]: string
} = {
  financial: 'Financial',
  incarceration: 'Pending incarceration in >30 days',
  discontinue_medication: 'Desire to discontinue medication (side effects, taper, etc.)',
  dissatisfaction_with_services: 'Dissatisfaction with services',
  platform_barriers: 'Platform barriers (internet access, prefers in-person)',
  accessibility_barriers:
    'Accessibility barriers (Rx issues, pharmacy stock, distance to pharmacy)',
  transfer_to_hloc: 'Transfer to higher level of care',
  hospitalization: 'Hospitalization',
  relocation_out_of_state: 'Relocation out of state',
  completed_medication:
    'Completed treatment course with medication and patient is not interested in continuing treatment',
}

export const prescribingClinicianPreferenceMapping: {
  [Key in PrescribingClinicianPreference]: string
} = {
  same_provider: 'Patient prefers to see the same provider upon returning',
  any_provider: 'Neutral — patient can see soonest available provider',
  different_provider: 'Patient prefers to see a different provider',
}

export const dischargeNoteMapping: Partial<Record<keyof DischargeNoteModel, string>> = {
  failure_to_pay: 'Failure to pay',
  administrative_non_compliance_aggressive_threatening:
    'Verbally aggressive, abusive, or threatening to Ophelia staff',
  not_responding: 'Not Responding',
  death: 'Death',
  patient_self_determines_to_stop:
    'Patient self-determines to pause or stop treatment with Ophelia',
  medical_or_psychiatric_worsening:
    'Medical or psychiatric worsening that requires higher level of care (HLOC) at this time',
  additional_explanation_for_discharge: 'Other',
  eligible_to_return_additional_details: 'Details of patient eligibility',
  recommended_hloc: 'Recommended higher level of care',
  transferring_to_outpatient_or_mat: 'Transferring to outpatient or MAT',
  reason_for_discharge_other: 'Other',
  referral_request_inpatient_program: 'Inpatient program',
  referral_request_residential_program: 'Residential program',
  referral_request_intensive_outpatient_program: 'Intensive outpatient program',
  referral_request_methadone_program: 'Methadone program (OTP)',
  referral_request_mental_health_psychiatric: 'Mental health/psychiatric clinic/services',
  referral_request_pcp: 'PCP',
  referral_request_buprenorphine_clinic: 'Other buprenorphine clinic',
  referral_request_other: 'Other',
  thirty_day_prescription_current_maintenance_dose:
    'Thirty-day prescription at the current maintenance dose',
  thirty_day_prescription_taper: 'Thirty-day prescription with the plan to taper off buprenorphine',
  naloxone_prescribed: 'Naloxone prescribed',
  medication_plan_other: 'Other',
}
