import React, { createContext, useContext } from 'react'

type TraceKey =
  | 'click-get-started-to-welcome-call-scheduled'
  | 'click-get-started-to-intake-visit-scheduled'

type Trace = {
  start: (arg: { key: TraceKey }) => void
  end: (arg: { key: TraceKey }) => void
}

export type Monitoring = {
  trace: Trace
}

export const MonitoringContext = createContext<Monitoring | null>(null)

type Client = (arg: { [key: string]: string | number }) => void

const getTraceStartKey = (key: TraceKey) => `${key}-trace-start`
const getTraceEndKey = (key: TraceKey) => `${key}-trace-end`

export const MonitoringProvider = (props: {
  children: React.ReactNode
  client: Client
  storage: Storage
}) => {
  const trace = {
    start: ({ key }: { key: TraceKey }) => {
      const startKey = props.storage.getItem(getTraceStartKey(key))

      // If we already started, don't start again
      if (startKey) {
        return
      }

      props.storage.setItem(getTraceStartKey(key), Date.now().toString())
    },
    end: ({ key }: { key: TraceKey }) => {
      const startKey = props.storage.getItem(getTraceStartKey(key))

      // If we never started, don't end
      if (!startKey) {
        return
      }

      const endKey = props.storage.getItem(getTraceEndKey(key))

      // If we already ended, don't end again
      if (endKey) {
        return
      }

      const startTime = parseInt(startKey, 10)
      const endTime = Date.now()

      props.storage.setItem(getTraceEndKey(key), endTime.toString())

      props.client({
        key,
        durationMs: endTime - startTime,
      })
    },
  }

  return (
    <MonitoringContext.Provider
      value={{
        trace,
      }}
    >
      {props.children}
    </MonitoringContext.Provider>
  )
}

export const useMonitoring = () => {
  const context = useContext(MonitoringContext)
  if (!context) {
    throw new Error('useMonitoring must be used within a MonitoringProvider')
  }
  return context
}
