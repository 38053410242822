import {
  Box,
  CheckIcon,
  ExternalLinkText,
  List,
  ListItem,
  Text,
  TitleTwo,
} from '@shared/components'
import React from 'react'

export const AsqMessage = () => {
  return (
    <Box p='sm'>
      <TitleTwo my='sm'>Someone from Ophelia may reach out to you in the next 24 hours</TitleTwo>
      <Text my='sm'>
        If you need immediate medical assistance, please go to the nearest emergency department or
        call 911.
      </Text>
      <Text my='sm'>In the meantime, here are some other resources that may help.</Text>
      <List
        my='lg'
        spacing='md'
        icon={<CheckIcon />}
        styles={theme => ({ itemIcon: { color: theme.other.colors.actions[0] } })}
      >
        <ListItem>
          National Suicide Prevention Hotline — call{' '}
          <ExternalLinkText href='tel:988'>988</ExternalLinkText> or visit{' '}
          <ExternalLinkText href='https://988lifeline.org/'>
            suicidepreventionlifeline.org
          </ExternalLinkText>
        </ListItem>
        <ListItem>
          Self-Harm Hotline{' '}
          <ExternalLinkText href='tel:8003668288'>1-800-366-8288</ExternalLinkText>
        </ListItem>
        <ListItem>
          Crisis Text Line — text REASON to{' '}
          <ExternalLinkText href='sms:741741'>741741</ExternalLinkText> or visit{' '}
          <ExternalLinkText href='https://www.crisistextline.org/'>
            crisistextline.org
          </ExternalLinkText>
        </ListItem>
      </List>
    </Box>
  )
}
