import {
  Divider,
  ExternalLinkText,
  Group,
  ResponsiveBox,
  ShoppingBagIcon,
  SmartphoneIcon,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { phone } from '@shared/utils'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as FullStory from '../fullstory'
import { routes } from '../routes'
import { sendTrackEvent } from '../rudderstack'

export const SMS_SUPPORT = '+12155852144'
export const EMAIL_SUPPORT = 'support@ophelia.com'
export const OPHELIA_PHONE_FORMATTED = phone(SMS_SUPPORT).arealessFormat

export const ContactSupport = ({
  text = 'Need help? Text us at',
}: {
  text?: string | undefined
}) => (
  <ResponsiveBox
    mobile={
      <Stack spacing='sm' align='center'>
        <Text>{text}</Text>
        <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>
          <TertiaryButton fullWidth leftIcon={<SmartphoneIcon />}>
            {OPHELIA_PHONE_FORMATTED}
          </TertiaryButton>
        </ExternalLinkText>
      </Stack>
    }
    desktop={
      <Group spacing='sm' position='center'>
        <Text>{text}</Text>
        <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>
          <TertiaryButton fullWidth leftIcon={<SmartphoneIcon />}>
            {OPHELIA_PHONE_FORMATTED}
          </TertiaryButton>
        </ExternalLinkText>
      </Group>
    }
  />
)

export const LearnMore = () => (
  <ResponsiveBox
    mobile={
      <Stack spacing='sm' align='center'>
        <Text>Not ready?</Text>
        <ExternalLinkText href='https://ophelia.com'>Learn about Ophelia</ExternalLinkText>
      </Stack>
    }
    desktop={
      <Group spacing='sm' position='center'>
        <Text>Not ready?</Text>
        <ExternalLinkText href='https://ophelia.com'>Learn about Ophelia</ExternalLinkText>
      </Group>
    }
  />
)

export const MoreOptions = () => {
  const navigate = useNavigate()
  const handleClickNotReady = () => {
    sendTrackEvent('Not Ready Clicked')
    FullStory.event('Not Ready Clicked')
    navigate(routes.options.index)
  }

  return (
    <ResponsiveBox
      mobile={
        <Stack spacing='sm' align='center'>
          <Text>Not ready to start right now?</Text>
          <TertiaryButton onClick={handleClickNotReady} leftIcon={<ShoppingBagIcon />}>
            We’ve got options for you
          </TertiaryButton>
        </Stack>
      }
      desktop={
        <Group noWrap spacing='sm' position='center'>
          <Text>Not ready to start right now?</Text>
          <TertiaryButton onClick={handleClickNotReady} leftIcon={<ShoppingBagIcon />}>
            We’ve got options for you
          </TertiaryButton>
        </Group>
      }
    />
  )
}

export const ContactSupportHeader = () => (
  <Group spacing='sm'>
    <Text>Need help?</Text>
    <ExternalLinkText href={`sms:${SMS_SUPPORT}`}>
      <TertiaryButton>{OPHELIA_PHONE_FORMATTED}</TertiaryButton>
    </ExternalLinkText>
    <Divider orientation='vertical' size='sm' />
    <ExternalLinkText href={`mailto:${EMAIL_SUPPORT}`}>
      <TertiaryButton>{EMAIL_SUPPORT}</TertiaryButton>
    </ExternalLinkText>
  </Group>
)
