import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { routes } from '../common/routes'
import { AccountRecovery } from './AccountRecovery'
import { AccountRecoveryUpdate } from './AccountRecoveryUpdate'

export const AccountRecoveryApp = () => {
  return (
    <Routes>
      <Route index element={<AccountRecovery />} />
      <Route
        path={routes.accountRecoveryRequest.children.update}
        element={<AccountRecoveryUpdate />}
      />
    </Routes>
  )
}
