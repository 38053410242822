import { z } from 'zod'
import { MessageSchema } from '../../../messageQueue'

export const MessageQueueWorkerSchemaReq = z.object({
  body: MessageSchema,
})

export const MessageQueueWorkerSchemas = {
  req: MessageQueueWorkerSchemaReq,
  res: z.object({
    data: z.null(),
  }),
}
