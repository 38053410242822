import { z } from 'zod'
import { ApiSchemas } from '../../utils'

const GetAppointmentByIdReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      id: z.number(),
    }),
  }),
}

const UpdateAppointmentByIdForCallMeNowReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

const CreateAppointmentByIdForCallMeNowReqResSchemas = {
  req: z.object({
    params: z.object({
      id: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      newApptId: z.string(),
    }),
  }),
}

export const MyAppointmentsApi = {
  'GET /appointments/:id': GetAppointmentByIdReqResSchemas,
  'PUT /appointments/:id/call-me-now': UpdateAppointmentByIdForCallMeNowReqResSchemas,
  'POST /appointments/:id/call-me-now': CreateAppointmentByIdForCallMeNowReqResSchemas,
} satisfies ApiSchemas
