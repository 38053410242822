import {
  ClipboardIcon,
  Group,
  ResponsiveBox,
  SmartphoneIcon,
  Stack,
  TertiaryButton,
  Text,
} from '@shared/components'
import { Patient } from '@shared/types'
import React from 'react'
import { useFriendReferral } from '../common/hooks'

export const ConfirmationReferralLink = ({ patientId }: { patientId: Patient['oid'] }) => {
  const { handleCopy, handleShare, shareLoading, copyLoading, referralLinkCopied } =
    useFriendReferral({
      source: 'portal_appointment_confirmation',
      referrerPatientId: patientId,
    })
  const canShare = navigator.share !== undefined

  const actionButton = canShare ? (
    <TertiaryButton
      fullWidth
      leftIcon={<SmartphoneIcon />}
      onClick={() => handleShare()}
      loading={shareLoading}
    >
      Send them your referral link
    </TertiaryButton>
  ) : (
    <TertiaryButton
      fullWidth
      leftIcon={<ClipboardIcon />}
      onClick={() => handleCopy()}
      loading={copyLoading}
    >
      {referralLinkCopied ? 'Copied!' : 'Copy your referral link'}
    </TertiaryButton>
  )

  return (
    <ResponsiveBox
      mobile={
        <Stack spacing='sm' align='center'>
          <Text>Want to share Ophelia with a friend?</Text>
          {actionButton}
        </Stack>
      }
      desktop={
        <Group spacing='sm' position='center'>
          <Text>Want to share Ophelia with a friend?</Text>
          {actionButton}
        </Group>
      }
    />
  )
}
