import { useDisclosure, useMediaQuery, useToggle } from '@mantine/hooks'
import {
  DEFAULT_LOAD_MORE_CHUNK_SIZE,
  DocumentCard,
  DocumentCardProps,
  FilePlusIcon,
  Group,
  INPUT_SIZES,
  Modal,
  NumberInput,
  PillStatus,
  PrimaryButton,
  SecondaryButton,
  Select,
  SortIcon,
  Stack,
  Text,
  TitleThree,
  useLoadMore,
  useMantineTheme,
} from '@shared/components'
import { PatientReleaseOfInformation, ReleaseOfInformationStatus } from '@shared/types'
import { SortOrder, dayjs, sortBy } from '@shared/utils'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { releaseOfInformationApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { useGcsFile } from '../../../common/hooks'
import { routes } from '../../../common/routes'

const releaseOfInformationStatusMap: Record<ReleaseOfInformationStatus, PillStatus> = {
  Active: 'success',
  'Under review': 'warning',
  'Needs changes': 'none',
  Expired: 'error',
  Revoked: 'none',
  'In progress': 'none',
}

const ReleaseOfInformationDocumentCard = ({
  roi,
  openRevokeModal,
}: {
  roi: PatientReleaseOfInformation
  openRevokeModal: (roiId: string) => void
}): JSX.Element => {
  const signedOnDate = dayjs(roi.signedOnDate).format('MMM D YYYY').toUpperCase()
  const expirationDate = dayjs(roi.expirationDate).format('MMM D YYYY').toUpperCase()

  const { file } = useGcsFile(roi.fileId)
  const previewDocument = () => {
    if (file) {
      window.open(URL.createObjectURL(file), '_blank')
    }
  }

  const navigate = useNavigate()

  let dateText: DocumentCardProps['dateText']
  let actions: DocumentCardProps['actions'] = []
  switch (roi.status) {
    case 'Active':
      dateText = [`Signed on ${signedOnDate}`, `Expires on ${expirationDate}`]
      actions = [
        { label: 'Preview document', callback: previewDocument },
        { label: 'Revoke document', callback: () => openRevokeModal(roi.oid) },
      ]
      break
    case 'Expired':
      dateText = [`Signed on ${signedOnDate}`, `Expired on ${expirationDate}`]
      actions = [{ label: 'Preview document', callback: previewDocument }]
      break
    case 'In progress':
      break
    case 'Needs changes':
      actions = [
        {
          label: 'Edit document',
          callback: () =>
            navigate({
              pathname: `${routes.portal.index}/${routes.portal.children.documents.index}${routes.portal.children.documents.children.releaseOfInformation}`,
              search: createSearchParams({ id: roi.oid }).toString(),
            }),
        },
      ]
      break
    case 'Revoked':
      dateText = [`Revoked on ${dayjs(roi.revokedOnDate).format('MMM D YYYY').toUpperCase()}`]
      actions = [{ label: 'Preview document', callback: previewDocument }]
      break
    case 'Under review':
      dateText = [`Signed on ${signedOnDate}`]
  }

  return (
    <DocumentCard
      status={[roi.status, releaseOfInformationStatusMap[roi.status]]}
      title={`For ${roi.recipient}`}
      bodyText={
        // Latest change request is at the end of an array
        roi.status === 'Needs changes' && roi.changeRequests
          ? roi.changeRequests[roi.changeRequests.length - 1]!.changesRequested
          : undefined
      }
      dateText={dateText}
      actions={actions}
    />
  )
}

export const ReleaseOfInformation = (): JSX.Element => {
  const {
    isLoading,
    isFetching,
    data: rois,
  } = useQuery(
    releaseOfInformationApi.GET_ALL_RELEASES_OF_INFORMATION_QUERY_KEY,
    releaseOfInformationApi.getAllReleasesOfInformation,
  )

  const navigate = useNavigate()

  const { displayedDataCount, setDisplayedDataCount, hasMoreData, loadMore } = useLoadMore(
    rois?.length ?? 0,
  )

  // Update displayed data count when query finishes fetching data
  useEffect(() => {
    setDisplayedDataCount(DEFAULT_LOAD_MORE_CHUNK_SIZE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching])

  const [sortOrder, toggleSortOrder] = useToggle<SortOrder>(['DESC', 'ASC'])
  const sortOptions: { value: SortOrder; label: 'Latest' | 'Oldest' }[] = [
    { value: 'DESC', label: 'Latest' },
    { value: 'ASC', label: 'Oldest' },
  ]

  const displayedRois = useMemo(
    () =>
      rois
        // Don't show ROIs that haven't at least been submitted for review
        ?.filter(roi => roi.status !== 'In progress')
        .sort(sortBy({ key: 'signedOnDate', order: sortOrder }))
        .slice(0, displayedDataCount),
    [rois, displayedDataCount, sortOrder],
  )

  const [opened, { open, close }] = useDisclosure(false)
  const [roiToRevoke, setRoiToRevoke] = useState<string>()
  const openRevokeModal = (roiId: string) => {
    open()
    setRoiToRevoke(roiId)
  }

  const queryClient = useQueryClient()
  const { mutate: revokeRoi } = useMutation(
    (id: string) =>
      releaseOfInformationApi.updateReleaseOfInformation({
        id,
        data: {
          status: 'Revoked',
          revokedOnDate: new Date().toISOString(),
        },
      }),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries(
          releaseOfInformationApi.GET_ALL_RELEASES_OF_INFORMATION_QUERY_KEY,
        )
        close()
      },
    },
  )

  const theme = useMantineTheme()
  // smallerThan returns a string with '@media' in it
  const isMobile = useMediaQuery(theme.fn.smallerThan('sm').replace('@media', ''))

  return (
    <Stack spacing={isMobile ? 'md' : 'lg'}>
      <Stack>
        <TitleThree>Release of information (ROI)</TitleThree>
        <PrimaryButton
          leftIcon={<FilePlusIcon />}
          fullWidth={isMobile}
          onClick={() =>
            navigate(
              `${routes.portal.index}/${routes.portal.children.documents.index}${routes.portal.children.documents.children.releaseOfInformation}`,
            )
          }
        >
          New ROI
        </PrimaryButton>
      </Stack>
      {/* Revoking ROIs triggers refetching  */}
      {(isLoading || isFetching) && <Skeletons type='entries' />}
      {!isLoading && !isFetching && displayedRois && displayedRois.length > 0 && (
        <Stack>
          {rois && rois.length > DEFAULT_LOAD_MORE_CHUNK_SIZE && (
            <Group position='apart'>
              <Group spacing='sm'>
                <Text>Showing</Text>
                <NumberInput
                  sx={{ width: INPUT_SIZES.lg }}
                  textAlign='center'
                  value={displayedDataCount}
                  onChange={val => setDisplayedDataCount(val || displayedDataCount)}
                  min={1}
                  max={rois.length}
                />
                <Text>of {rois.length} items</Text>
              </Group>
              {isMobile ? (
                <SecondaryButton
                  onClick={() => toggleSortOrder()}
                  leftIcon={
                    <SortIcon transform={sortOrder === 'ASC' ? undefined : 'scale(1 -1)'} />
                  }
                />
              ) : (
                <Select
                  // eslint-disable-next-line no-magic-numbers
                  sx={theme => ({ width: `calc(${theme.spacing.md} * 7)` })}
                  value={sortOrder}
                  onChange={val => toggleSortOrder(val as SortOrder)}
                  data={sortOptions}
                />
              )}
            </Group>
          )}
          {displayedRois.map(roi => (
            <ReleaseOfInformationDocumentCard
              key={roi.oid}
              roi={roi}
              openRevokeModal={openRevokeModal}
            />
          ))}
          {hasMoreData && (
            <SecondaryButton fullWidth={isMobile} onClick={loadMore}>
              Load more
            </SecondaryButton>
          )}
        </Stack>
      )}
      <Modal
        opened={opened}
        onClose={close}
        title='Revoke release of information'
        footer={
          <Stack>
            <SecondaryButton fullWidth onClick={close}>
              No, don&apos;t revoke
            </SecondaryButton>
            <PrimaryButton
              fullWidth
              onClick={roiToRevoke ? () => revokeRoi(roiToRevoke) : undefined}
            >
              Yes, revoke
            </PrimaryButton>
          </Stack>
        }
      >
        <Text>
          By revoking this document, Ophelia will no longer share your protected health information
          with the person or organization stated in the document. Are you sure you want to proceed?
        </Text>
      </Modal>
    </Stack>
  )
}
