import { useLifecycle } from '@shared/components'
import random from 'lodash/random'
import React, { PropsWithChildren } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { isAllowedInPortal } from '../common/components'
import { useAuth, usePortalMutation } from '../common/hooks'
import { useReferralPartner } from '../common/hooks/use-partner'
import { routes } from '../common/routes'
import { getSessionStorageItem, removeSessionStorageItem } from '../common/storage'
import * as Pages from './pages'
import { PartnerPage } from './pages/PartnerPage'

function Redirect() {
  const postSegmentEvent = usePortalMutation('POST /segment-events')

  useLifecycle({
    onMount: () => {
      const isRedirectToInsuranceTask =
        getSessionStorageItem('redirect_after_login') ===
        `${routes.portal.index}/${routes.portal.children.task.index}${routes.portal.children.task.children.insurance}`

      if (isRedirectToInsuranceTask) {
        void postSegmentEvent.mutate({
          data: {
            event: 'Lead Redirected To Insurance Task',
          },
        })
      }
    },
    onUnmount: () => removeSessionStorageItem('redirect_after_login'),
  })

  const hasAlreadyShownReferFriendsPopup = getSessionStorageItem(
    'has_shown_refer_friends_interstitial',
  )
  // 50% of displaying the refer friends popup when routing from welcome
  // eslint-disable-next-line no-magic-numbers
  const showReferFriendsPopup = !hasAlreadyShownReferFriendsPopup && random(1, 100) <= 50

  return (
    <Navigate
      replace
      to={getSessionStorageItem('redirect_after_login') ?? routes.portal.index}
      state={{ showReferFriendsPopup }}
    />
  )
}

const WelcomeAuth = ({
  children,
  signUpPage = false,
}: PropsWithChildren<{ signUpPage?: boolean }>) => {
  const { isAuthorized, currentUser, authenticationMethod } = useAuth()
  const { partnerPath } = useReferralPartner()

  if (isAuthorized) {
    if (currentUser?.data?.account.requiresOnboarding) {
      if (
        !currentUser?.data?.homeData?.state &&
        location.pathname.includes(routes.welcome.children.signin)
      ) {
        /*
         * This person tried to sign in, but we don't recognize this phone,
         * so they might have an account under another phone.
         * Show them the account recovery prompt to avoid them creating a dupe account.
         */
        return (
          <Navigate
            replace
            to={`${routes.accountRecoveryRequest.index}?step=email-request&interstitial=1`}
          />
        )
      }

      if (currentUser?.data?.homeData?.state || getSessionStorageItem('residence_state')) {
        return <Navigate to={`${routes.onboarding.index}${partnerPath}`} replace />
      } else if (!location.pathname.includes(routes.welcome.children.signup)) {
        return (
          <Navigate
            to={
              partnerPath
                ? `${routes.welcome.index}${partnerPath}`
                : `${routes.welcome.index}/${routes.welcome.children.signup}?step=residence`
            }
            replace
          />
        )
      }
    } else if (isAllowedInPortal(currentUser?.data, authenticationMethod) || signUpPage) {
      return <Redirect />
    }
  }

  return <>{children}</>
}

export const WelcomeApp = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <WelcomeAuth>
            <Pages.Welcome />
          </WelcomeAuth>
        }
      />
      <Route
        path={`:referralPartnerId/${routes.welcome.children.signup}`}
        element={
          <PartnerPage>
            <WelcomeAuth>
              <Pages.Signup />
            </WelcomeAuth>
          </PartnerPage>
        }
      />
      <Route
        path={routes.welcome.children.signin}
        element={
          <WelcomeAuth>
            <Pages.Signin />
          </WelcomeAuth>
        }
      />
      <Route
        path={routes.welcome.children.signup}
        element={
          <WelcomeAuth signUpPage>
            <Pages.Signup />
          </WelcomeAuth>
        }
      />
      <Route
        path=':referralPartnerId'
        element={
          <PartnerPage>
            <WelcomeAuth>
              <Pages.WelcomePartner />
            </WelcomeAuth>
          </PartnerPage>
        }
      />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}
