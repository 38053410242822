import { OpheliaHttpError, PatientApi } from '@shared/types'
import { AxiosError } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { patientApi } from '../api'

export const usePortalQuery = <OpheliaRoute extends keyof PatientApi>(
  route: OpheliaRoute,
  config?: Partial<PatientApi[OpheliaRoute]['req']>,
  options?: Omit<
    UseQueryOptions<PatientApi[OpheliaRoute]['res'], AxiosError<OpheliaHttpError>>,
    'queryKey' | 'queryFn'
  >,
) => {
  return useQuery<PatientApi[OpheliaRoute]['res'], AxiosError<OpheliaHttpError>>(
    ...patientApi.getQuery(route, config),
    options,
  )
}
