import { ExternalLinkText, Grid, TextInput, Text } from '@shared/components'
import { phone } from '@shared/utils'
import React from 'react'
import { useAuth } from '../../../common/hooks'

export const ContactSection = () => {
  const { currentUser } = useAuth()

  return (
    <Grid>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Phone number'
          value={phone(currentUser?.data?.personalData.phone || '').formatted}
          disabled
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput value={currentUser?.data?.personalData.email} label='Email address' disabled />
      </Grid.Col>
      <Grid.Col span={12}>
        <Text size='xs'>
          Text&nbsp;
          <ExternalLinkText href='sms:2155852144'>(215) 585-2144</ExternalLinkText>
          &nbsp;if you’d like to change something that you can’t edit here.
        </Text>
      </Grid.Col>
    </Grid>
  )
}
