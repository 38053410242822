import { Stack, Text } from '@shared/components'
import React from 'react'

export const FinancialAgreementV3 = () => {
  return (
    <Stack>
      <Stack>
        <Text bold>Self-Pay:</Text>
        <Text>
          You may choose to be solely responsible for the cost of your care, by paying the monthly
          rate of $195 per month with a credit or debit card. If you have health insurance but are
          choosing not to use or provide your insurance, you can self-pay for services directly.
        </Text>
        <Text>
          If you do not want to self-pay, you have another choice. You can call your health care
          program and ask them to find a different health care provider for you. This is your
          decision and Ophelia respects your right to pick a health care provider that makes sense
          for you.
        </Text>
        <Text bold>Insurance:</Text>
        <Text>
          If you plan to use health insurance, you must provide Ophelia with accurate and complete
          information concerning your primary and secondary insurance medical benefits as well as
          current photo identification at each visit. Ophelia may or may not be a participating
          provider with your health care program – or your health care program may say that the
          services are “not covered”- meaning that it will not pay for the types of services Ophelia
          provides. Therefore, we cannot guarantee that your care will be covered and/or paid for by
          your insurance company.
        </Text>
        <Text>
          If Ophelia is participating with your benefit plan, you agree to Ophelia submitting claims
          on your behalf for services provided, and that you will be responsible for the cost of any
          co-payments, deductibles and services not covered.
        </Text>
        <Text>
          You may be required to pay a co-payment for services rendered at Ophelia based on your
          insurance provider and plan. Copays may be included for any eligible service including
          clinical visits, collaborative care, non-face-to-face services, or other service lines.
          The potential coinsurance amount and deductible will vary depending on your health plan
          and specific coverage. You are responsible for potential cost sharing expenses. For more
          information, please contact your health insurance provider.
        </Text>
        <Text>
          If Ophelia is considered non-participating or out-of-network with your particular benefit
          plan or the services are not covered, you agree that you will be responsible for the cost
          of all care rendered to you by Ophelia.
        </Text>
        <Text>
          If you need help paying for services, please ask Ophelia about its financial assistance
          policy.
        </Text>
        <Text>
          Please read this Financial Agreement very carefully – it is a legal agreement. You should
          not sign this Financial Agreement if you do not understand what it means.
        </Text>
        <Text bold>
          I agree that I will be financially responsible for payments to Ophelia for its services,
          as follows:
        </Text>
        <Stack pl='lg'>
          <Text>
            If I don’t have health insurance or do not want to use my insurance, I will personally
            pay all costs using the self-pay program.
          </Text>
          <Text>
            If I have insurance that Ophelia is participating in, I authorize Ophelia to submit
            claims and receive payment on my behalf for any services provided to me. I authorize
            Ophelia to release my information as needed for the purpose of benefit, eligibility, and
            payment determinations. I will personally pay Ophelia for any co-payments or
            deductibles, and for any services that are not covered by my insurance plan.
          </Text>
          <Text>
            If I have insurance, but Ophelia is not a provider in my plan, or my plan does not cover
            the services I want from Ophelia, I agree to personally pay for the services I receive
            from Ophelia.
          </Text>
          <Text>
            I agree that my credit card on file can be used to automatically pay any out of pocket
            expenses.
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}
