import { Text, TitleTwo } from '@shared/components'
import { PrescriptionInfo as PrescriptionInfoType } from '@shared/types'
import React from 'react'
import { Card } from '../../../common/components'
import { PrescriptionInfo, PrescriptionPill } from './PrescriptionInfo'

export const PrescriptionCard = ({ prescription }: { prescription: PrescriptionInfoType }) => {
  return (
    <Card title='Active prescription'>
      <PrescriptionPill prescription={prescription} />
      <TitleTwo style={{ textTransform: 'capitalize' }}>{prescription.genericDrugName}</TitleTwo>
      <PrescriptionInfo prescription={prescription} />
    </Card>
  )
}

export const EmptyPrescriptionCard = ({ title }: { title: string }) => (
  <Card title={title}>
    <Text>No active prescriptions</Text>
  </Card>
)
