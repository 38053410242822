import { PrimaryButton, Stack, Text, TitleTwo } from '@shared/components'
import React from 'react'
import { Link } from 'react-router-dom'
import { ContactSupport } from '../../common/components'
import { useOnboardingDims } from '../../common/hooks'
import { routes } from '../../common/routes'

export const Expired = () => {
  const { isMobile } = useOnboardingDims()

  return (
    <Stack spacing='md'>
      <TitleTwo>Sorry the link has expired.</TitleTwo>
      <Text>Please log into your portal to complete the wellness check.</Text>
      <PrimaryButton
        fullWidth={isMobile}
        component={Link}
        to={`${routes.welcome.index}/${routes.welcome.children.signin}`}
      >
        Go to the portal
      </PrimaryButton>
      <ContactSupport />
    </Stack>
  )
}
