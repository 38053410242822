import { Stack, TitleTwo, useLifecycle } from '@shared/components'
import React from 'react'
import { useParams } from 'react-router-dom'
import { FallbackContent, Skeletons } from '../../../common/components'
import { StripeElementsProvider } from '../../../common/components/stripe/StripeElementsProvider'
import { usePortalMutation, usePortalQuery } from '../../../common/hooks'
import { EditPaymentMethodForm } from './EditPaymentMethodForm'

export const EditPaymentMethod = () => {
  const setupIntentMutation = usePortalMutation('POST /payment/setup-intent')
  const { paymentMethodId } = useParams<{ paymentMethodId: string }>()
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } =
    usePortalQuery('GET /payment/methods')
  useLifecycle({
    onMount: () => {
      setupIntentMutation.mutate({})
    },
  })

  if (setupIntentMutation.isLoading || isPaymentMethodsLoading) {
    return (
      <>
        <TitleTwo>Edit card</TitleTwo>
        <Skeletons />
      </>
    )
  }
  if (setupIntentMutation.data && paymentMethodId) {
    return (
      <Stack>
        <TitleTwo>Edit card</TitleTwo>
        <StripeElementsProvider clientSecret={setupIntentMutation.data?.clientSecret}>
          <EditPaymentMethodForm
            paymentMethodId={paymentMethodId}
            totalNumberOfPaymentMethods={paymentMethods?.paymentMethods.length ?? 0}
          />
        </StripeElementsProvider>
      </Stack>
    )
  }
  return <FallbackContent boundary='portal' />
}
