import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Box,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Select,
  Stack,
  TertiaryButton,
  TextInput,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { PromptResponsePayload, ReferralOption } from '@shared/types'
import React from 'react'
import { isRequired } from '../../../forms'
import { WidgetProps } from '../PromptContentWidget'

type HowDidYouHearAboutUsOption = {
  value: ReferralOption
  label: string
  nextSteps?: HowDidYouHearAboutUsNextStep[]
}

type HowDidYouHearAboutUsNextStep = {
  label: string
  placeholder: string
  value: string
}

type HDYHAUForm = {
  howTheyHeardAboutUs: ReferralOption
  nameOfHealthcareProvider?: string
  nameOfOrganization?: string
  whichConference?: string
  shareHelpfulInfo?: string
}

export const HowDidYouHearAboutUs = ({ onBack, onSubmit }: WidgetProps) => {
  const form = useForm<HDYHAUForm>({
    initialValues: {
      howTheyHeardAboutUs: '' as ReferralOption,
      nameOfHealthcareProvider: '',
      nameOfOrganization: '',
      whichConference: '',
      shareHelpfulInfo: '',
    },
    validate: {
      howTheyHeardAboutUs: validateWith(isRequired),
      whichConference: validateWith(
        skipIfOtherField('howTheyHeardAboutUs', 'not', 'conference'),
        isRequired,
      ),
      shareHelpfulInfo: validateWith(
        skipIfOtherField('howTheyHeardAboutUs', 'not', 'other'),
        isRequired,
      ),
    },
  })

  const submitHandler = () => {
    const { hasErrors } = form.validate()
    if (hasErrors) {
      return
    }

    const formValue = form.values.howTheyHeardAboutUs

    const responseObj: PromptResponsePayload = {
      value: {
        contextKey: 'custom',
        value: formValue,
      },
    }

    if (formValue === 'conference') {
      responseObj.how_did_you_hear_about_us_which_conference = {
        contextKey: 'custom',
        value: form.values.whichConference || '',
      }
    } else if (formValue === 'other') {
      responseObj.how_did_you_hear_about_us_other = {
        contextKey: 'custom',
        value: form.values.shareHelpfulInfo || '',
      }
    } else if (formValue === 'provider') {
      responseObj.how_did_you_hear_about_us_provider_name = {
        contextKey: 'custom',
        value: form.values.nameOfHealthcareProvider || '',
      }
      responseObj.how_did_you_hear_about_us_provider_org = {
        contextKey: 'custom',
        value: form.values.nameOfOrganization || '',
      }
    }

    onSubmit(responseObj)
  }

  const options: HowDidYouHearAboutUsOption[] = [
    {
      value: 'provider',
      label: 'A health care provider referred me',
      nextSteps: [
        {
          label: 'Name of healthcare provider (optional)',
          placeholder: 'Name of healthcare provider',
          value: 'nameOfHealthcareProvider',
        },
        {
          label: 'Name of organization (optional)',
          placeholder: 'Name of organization',
          value: 'nameOfOrganization',
        },
      ],
    },
    {
      value: 'friend',
      label: 'A friend or someone I know referred me',
    },
    {
      value: 'online',
      label: 'Online/Google search',
    },
    {
      value: 'social_media',
      label: 'Social media',
    },
    {
      value: 'news_article',
      label: 'News article/press release',
    },
    {
      value: 'direct_mail',
      label: 'Direct mail',
    },
    {
      value: 'conference',
      label: 'At a conference',
      nextSteps: [
        {
          label: 'Which conference? (required)',
          placeholder: 'Which conference?',
          value: 'whichConference',
        },
      ],
    },
    {
      value: 'other',
      label: 'Other',
      nextSteps: [
        {
          label: 'Share any helpful info (required)',
          placeholder: 'Share any helpful info',
          value: 'shareHelpfulInfo',
        },
      ],
    },
    {
      value: 'no_answer',
      label: 'Prefer not to answer',
    },
  ]

  const selectedOption = options.filter(
    option => option.value === form.values.howTheyHeardAboutUs,
  )[0]

  const showDropdown = () => {
    const selectInputs = selectedOption?.nextSteps
      ? selectedOption.nextSteps.map(step => {
          return (
            <TextInput
              mt={20}
              key={step.label}
              label={step.label}
              placeholder={step.placeholder}
              {...form.getInputProps(step.value as keyof HDYHAUForm)}
            />
          )
        })
      : ''

    return <Box mt={10}>{selectInputs}</Box>
  }

  return (
    <div>
      <Select
        nothingFound='No options'
        test-id='input:how-did-you-hear-about-us'
        placeholder='Select one..'
        data={options.map(option => ({
          value: option.value,
          label: option.label,
        }))}
        {...form.getInputProps('howTheyHeardAboutUs')}
      />

      {selectedOption?.nextSteps && showDropdown()}
      <ResponsiveBox
        mt='lg'
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Next
            </PrimaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              style={{ margin: 'auto' }}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
              Next
            </PrimaryButton>
          </Group>
        }
      />
    </div>
  )
}
