import { useForm } from '@mantine/form'
import { useMediaQuery, useScrollIntoView } from '@mantine/hooks'
import {
  Box,
  Checkbox,
  CheckboxGroup,
  DateInput,
  Divider,
  Grid,
  PhoneInput,
  PrimaryButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  TextInput,
  Textarea,
  TitleTwo,
  isAllSelected,
  isAnySelected,
  isDateRelativeRange,
  isPhoneFormat,
  skipIfOtherField,
  useBanner,
  useMantineTheme,
  validateWith,
} from '@shared/components'
import {
  PatientReleaseOfInformationModel,
  ReleaseOfInformationDisclosurePurposeMap,
  ReleaseOfInformationTypeMap,
} from '@shared/types'
import { dayjs } from '@shared/utils'
import React, { useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { patientApi, releaseOfInformationApi } from '../../../common/api'
import { AddressForm, Skeletons } from '../../../common/components'
import { formAddressRules, isDate, isEmail, isPhone, isRequired } from '../../../common/forms'
import { useAuth } from '../../../common/hooks'
import { routes } from '../../../common/routes'

type ContactMethod = 'phone' | 'fax' | 'email'
const nonPhoneContactMethods: ['fax', 'email'] = ['fax', 'email']

type DatesOfServiceOption = 'service-start-date' | 'specific-dates'

// Fields only used in the form are made optional so there's no type error when they're deleted on submit
type Form = PatientReleaseOfInformationModel & { infoContactMethods?: ContactMethod[] } & {
  otherInfoType?: string
} & { datesOfServiceOption?: DatesOfServiceOption } & { otherDisclosurePurpose?: string } & {
  acknowledgement?: 'checked'[]
}

/** Map fields where "Other" is an option to their corresponding text input */
type OtherField = 'infoTypes' | 'disclosurePurposes'
const otherFormFieldsMap: Record<OtherField, 'otherInfoType' | 'otherDisclosurePurpose'> = {
  infoTypes: 'otherInfoType',
  disclosurePurposes: 'otherDisclosurePurpose',
}

/** Returns a blank form with the recipient and contact info fields set if given the optional ROI argument. */
const getInitialFormValues = (roi?: PatientReleaseOfInformationModel): Form => {
  const initialFormValues = {
    recipient: '',
    organizationContact: '',
    contactInfo: {
      address: '',
      address2: '',
      city: '',
      zipCode: '',
      phone: '',
    },
    infoContactMethods: [] as ContactMethod[],
    fax: '',
    email: '',
    infoTypes: [] as string[],
    datesOfService: {
      start: '',
      end: '',
    },
    disclosurePurposes: [] as string[],
    expirationDate: '',
    signature: '',
  } as Form

  if (!roi) {
    return initialFormValues
  }

  initialFormValues.recipient = roi.recipient
  initialFormValues.recipientType = roi.recipientType
  initialFormValues.contactInfo = roi.contactInfo

  if (roi.canContactPhone) {
    initialFormValues.infoContactMethods?.push('phone')
  }

  // Fax and email are unneeded for emergency contacts
  if (
    initialFormValues.recipientType !== 'Emergency contact' &&
    roi.recipientType !== 'Emergency contact'
  ) {
    // Add contact method associated form fields if it exists
    nonPhoneContactMethods.forEach(contactMethod => {
      if (roi[contactMethod]) {
        initialFormValues[contactMethod] = roi[contactMethod]
        initialFormValues.infoContactMethods?.push(contactMethod)
      }
    })
  }

  return initialFormValues
}

export const ReleaseOfInformationForm = (): JSX.Element => {
  const { isLoading: isLoadingAuth, currentUser } = useAuth()
  const personalData = currentUser?.data?.personalData

  const [searchParams] = useSearchParams()
  const roiId = searchParams.get('id')
  const { isLoading: isGettingRoi, data: roiToChange } = useQuery(
    [releaseOfInformationApi.GET_RELEASE_OF_INFORMATION_QUERY_KEY, roiId],
    () => releaseOfInformationApi.getReleaseOfInformation(roiId as string),
    { enabled: Boolean(roiId), retry: false },
  )

  const {
    getInputProps,
    values,
    isDirty,
    validateField,
    validate,
    setValues,
    setFieldValue,
    clearFieldError,
  } = useForm<Form>({
    validateInputOnBlur: true,
    initialValues: getInitialFormValues(),
    validate: {
      recipientType: validateWith<string, unknown>(isRequired),
      recipient: validateWith(isRequired),
      contactInfo: { phone: validateWith(isRequired, isPhone), ...formAddressRules },
      infoContactMethods: isAnySelected(['phone', 'fax', 'email'], 'Required'),
      fax: validateWith(
        skipIfOtherField('infoContactMethods', 'omit', ['fax']),
        isRequired,
        isPhoneFormat('Invalid fax number'),
      ),
      email: validateWith(
        skipIfOtherField('infoContactMethods', 'omit', ['email']),
        isRequired,
        isEmail,
      ),
      infoTypes: isAnySelected(Object.values(ReleaseOfInformationTypeMap), 'Required'),
      otherInfoType: validateWith(skipIfOtherField('infoTypes', 'omit', ['Other']), isRequired),
      datesOfServiceOption: validateWith(isRequired),
      datesOfService: {
        start: validateWith(isRequired, isDate),
        end: validateWith(
          skipIfOtherField('datesOfServiceOption', 'not', 'specific-dates'),
          isRequired,
          isDate,
          (value?: string, values?: Form) =>
            dayjs(value) < dayjs(values?.datesOfService.start)
              ? 'Must be on or after "From" date'
              : undefined,
          (value?: string, values?: Form) =>
            values?.expirationDate && dayjs(value) > dayjs(values?.expirationDate)
              ? '"To" date cannot be after expiration date'
              : undefined,
        ),
      },
      disclosurePurposes: isAnySelected(
        Object.values(ReleaseOfInformationDisclosurePurposeMap),
        'Required',
      ),
      otherDisclosurePurpose: validateWith(
        skipIfOtherField('disclosurePurposes', 'omit', ['Other']),
        isRequired,
      ),
      expirationDate: validateWith(
        isRequired,
        isDate,
        isDateRelativeRange(
          { min: { value: 1, unit: 'day' }, max: { value: 1, unit: 'year' } },
          'Date should be in the future, up to a year',
        ),
      ),
      acknowledgement: isAllSelected(['checked'], 'Required'),
      signature: validateWith(isRequired),
    },
  })

  const autofillDatesOfService = values.datesOfServiceOption === 'service-start-date'

  useEffect(() => {
    // Check length so "Complete medical record" can be unselecteded if it's the only type selected
    if (values.infoTypes.length > 1 && values.infoTypes.includes('Complete medical record')) {
      setFieldValue('infoTypes', ['Complete medical record'])
    }
  }, [setFieldValue, values.infoTypes])

  // Use earliest welcome call to autofill start date of service
  const { isLoading: isLoadingWelcomeCalls, data: welcomeCalls } = useQuery(
    ...patientApi.getQuery('GET /appointments', {
      query: { type: 'Free Consultation Call' },
    }),
    {
      onSuccess: welcomeCalls => {
        /*
         * First welcome call is the earliest b/c this query is sorted by `datetime` by default.
         * Default to today if there are no welcome calls.
         */
        setFieldValue(
          'datesOfService.start',
          welcomeCalls?.[0]
            ? dayjs(welcomeCalls[0].datetime).format('MM/DD/YYYY')
            : dayjs().format('MM/DD/YYYY'),
        )
      },
    },
  )
  const isLoading = isLoadingAuth || isGettingRoi || isLoadingWelcomeCalls
  const serviceStartDate = welcomeCalls?.[0]
    ? dayjs(welcomeCalls[0].datetime).format('MM/DD/YYYY')
    : dayjs().format('MM/DD/YYYY')

  // Update initial form values once ROI query is finished loading
  useEffect(() => {
    setValues(getInitialFormValues(roiToChange))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGettingRoi])

  useEffect(() => {
    if (autofillDatesOfService) {
      // Fields will be read-only so clear any errors
      clearFieldError('datesOfService.start')
      clearFieldError('datesOfService.end')

      // Autofill
      setFieldValue('datesOfService.start', serviceStartDate)
      setFieldValue('datesOfService.end', values.expirationDate)
    }
    // Validate end date of service if it becomes editable (on-blur won't be triggered) and isn't empty
    if (values.datesOfServiceOption === 'specific-dates' && isDirty('datesOfService.end')) {
      validateField('datesOfService.end')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.datesOfServiceOption, serviceStartDate, values.expirationDate])

  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const onSuccess = () => {
    void queryClient.invalidateQueries(
      releaseOfInformationApi.GET_ALL_RELEASES_OF_INFORMATION_QUERY_KEY,
    )
    navigate(`${routes.portal.index}/${routes.portal.children.documents.index}`)
  }

  const { isLoading: isCreatingRoi, mutate: createRoi } = useMutation(
    patientApi.getMutation('POST /release-of-information'),
    { onSuccess },
  )

  const { isLoading: isUpdatingRoi, mutate: updateRoi } = useMutation(
    releaseOfInformationApi.updateReleaseOfInformation,
    { onSuccess },
  )

  const { showBanner } = useBanner()
  const { scrollIntoView: scrollToBanner, targetRef } = useScrollIntoView<HTMLDivElement>()
  const onSubmit = () => {
    if (values.recipientType) {
      if (validate().hasErrors) {
        showBanner({ type: 'error', label: 'Correct the errors below to proceed' })
        scrollToBanner()
        return
      }

      // Could be filled out if switched from "Person"
      if (values.recipientType !== 'Organization') {
        delete values['organizationContact' as keyof Form]
      }

      values.canContactPhone = Boolean(values.infoContactMethods?.includes('phone'))

      /*
       * Fax and email are unneeded for emergency contacts.
       * Delete contact methods that could've been filled out but
       * didn't end up being selected.
       */
      if (values.recipientType !== 'Emergency contact') {
        nonPhoneContactMethods.forEach(contactMethod => {
          if (!values.infoContactMethods?.includes(contactMethod)) {
            delete values[contactMethod]
          }
        })
      }

      // Cast because Object.keys returns string[]
      const otherFields = Object.keys(otherFormFieldsMap) as OtherField[]
      // If "Other" option is checked, replace its value with the text input
      otherFields.forEach(field => {
        const otherIndex = values[field].indexOf('Other')
        if (otherIndex !== -1) {
          values[field][otherIndex] = values[
            otherFormFieldsMap[field]
            // Cast as string because validation ensures this isn't undefined
          ] as string
        }
        delete values[otherFormFieldsMap[field]]
      })

      // Convert date fields to ISO format
      values.datesOfService.start = dayjs(values.datesOfService.start).toISOString()
      values.datesOfService.end = dayjs(values.datesOfService.end).toISOString()
      values.expirationDate = dayjs(values.expirationDate).toISOString()

      // Delete fields only needed to fill out the form
      delete values.infoContactMethods
      delete values.datesOfServiceOption
      delete values.acknowledgement

      // Set fields not on form
      values.status = 'Under review'
      values.signedOnDate = dayjs().toISOString()

      if (roiId && roiToChange) {
        updateRoi({ id: roiId, data: values })
        return
      }
      createRoi({ data: values })
      return
    }

    validateField('recipientType')
  }

  const theme = useMantineTheme()
  // smallerThan returns a string with '@media' in it
  const isMobile = useMediaQuery(theme.fn.smallerThan('sm').replace('@media', ''))

  return (
    <Stack spacing='lg'>
      <Box ref={targetRef}>
        <TitleTwo>Authorization for release of information</TitleTwo>
        <Text>
          Fill out the form below to allow Ophelia to share your protected health information with
          an organization or person of your choosing.
        </Text>
      </Box>
      {isLoading && <Skeletons type='form' />}
      {!isLoading && (
        <>
          <Stack>
            <Text>
              I, {personalData?.firstName} {personalData?.lastName}, born on{' '}
              {dayjs(personalData?.birthday ?? '')
                .format('MMM D YYYY')
                .toUpperCase()}
              , authorize Ophelia Health, Inc. to disclose information from my patient record to:
            </Text>
            <RadioGroup {...getInputProps('recipientType')}>
              <Radio value='Organization' label='An organization' />
              <Radio value='Person' label='A person' />
            </RadioGroup>
            {values.recipientType && (
              <TextInput
                label={`${values.recipientType} name`}
                placeholder={`e.g. ${
                  values.recipientType === 'Organization' ? 'Acme' : 'John Smith'
                }`}
                explanation={
                  values.recipientType === 'Person'
                    ? 'We will be sharing information only with this specific individual and nobody else'
                    : undefined
                }
                {...getInputProps('recipient')}
              />
            )}
          </Stack>
          {values.recipientType === 'Organization' && (
            <TextInput
              label='Is there a specific person or department at that organization information should be given directly to? (optional)'
              placeholder='Name of person / department'
              {...getInputProps('organizationContact')}
            />
          )}
          {values.recipientType && (
            <>
              <Divider />
              <Text>Contact information for the {values.recipientType.toLowerCase()}:</Text>
              <Grid>
                <AddressForm
                  addressInputProps={getInputProps('contactInfo.address')}
                  addressTwoInputProps={getInputProps('contactInfo.address2')}
                  cityInputProps={getInputProps('contactInfo.city')}
                  stateInputProps={getInputProps('contactInfo.state')}
                  zipInputProps={getInputProps('contactInfo.zipCode')}
                />
                <Grid.Col>
                  <PhoneInput label='Phone number' {...getInputProps('contactInfo.phone')} />
                </Grid.Col>
              </Grid>
              <Divider />
              <Stack>
                <Text>How should we share information? (choose all that apply)</Text>
                <CheckboxGroup {...getInputProps('infoContactMethods')}>
                  <Checkbox value='phone' label='Phone' />
                  <Checkbox value='fax' label='Fax' />
                  <Checkbox value='email' label='Email' />
                </CheckboxGroup>
                {values.infoContactMethods?.includes('phone') && (
                  <PhoneInput disabled label='Phone number' value={values.contactInfo.phone} />
                )}
                {values.infoContactMethods?.includes('fax') && (
                  <PhoneInput label='Fax number' {...getInputProps('fax')} />
                )}
                {values.infoContactMethods?.includes('email') && (
                  <TextInput
                    label='Email'
                    placeholder='e.g. user@email.com'
                    {...getInputProps('email')}
                  />
                )}
              </Stack>
              <Divider />
              <Text>
                Please include the following information from my Patient Record (chose all that
                apply):
              </Text>
              <Stack spacing='sm'>
                <CheckboxGroup {...getInputProps('infoTypes')}>
                  {(
                    Object.keys(
                      ReleaseOfInformationTypeMap,
                    ) as (keyof typeof ReleaseOfInformationTypeMap)[]
                  ).map(key => (
                    <Checkbox
                      disabled={
                        key !== 'CompleteMedicalRecord' &&
                        values.infoTypes.includes('Complete medical record')
                      }
                      key={key}
                      value={ReleaseOfInformationTypeMap[key]}
                      label={ReleaseOfInformationTypeMap[key]}
                    />
                  ))}
                </CheckboxGroup>
                {values.infoTypes.includes('Other') && (
                  <Textarea placeholder='Type here...' {...getInputProps('otherInfoType')} />
                )}
              </Stack>
              <Divider />
              <Stack>
                <Text>The purpose of disclosure (the reason the records are needed) is for:</Text>
                <Stack spacing='sm'>
                  <CheckboxGroup {...getInputProps('disclosurePurposes')}>
                    {(
                      Object.keys(
                        ReleaseOfInformationDisclosurePurposeMap,
                      ) as (keyof typeof ReleaseOfInformationDisclosurePurposeMap)[]
                    ).map(key => (
                      <Checkbox
                        key={key}
                        value={ReleaseOfInformationDisclosurePurposeMap[key]}
                        label={ReleaseOfInformationDisclosurePurposeMap[key]}
                      />
                    ))}
                    {values.disclosurePurposes.includes('Other') && (
                      <Textarea
                        placeholder='Type here...'
                        {...getInputProps('otherDisclosurePurpose')}
                      />
                    )}
                  </CheckboxGroup>
                </Stack>
              </Stack>
              <Divider />
              <Grid>
                <Grid.Col>
                  <Text>
                    This authorization expires on the following date or whenever Ophelia Health,
                    Inc. is no longer providing me with services:
                  </Text>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <DateInput
                    label='Expiration date'
                    explanation='Up to one year in the future'
                    {...getInputProps('expirationDate')}
                  />
                </Grid.Col>
              </Grid>
              <Divider />
              <Stack>
                <Text>I authorize my patient records(s) to be released from:</Text>
                <RadioGroup {...getInputProps('datesOfServiceOption')}>
                  <Radio
                    value='service-start-date'
                    label="My service start date until this release of information's expiration"
                  />
                  <Radio value='specific-dates' label='Specific dates' />
                </RadioGroup>
                {values.datesOfServiceOption && (
                  <Grid>
                    <Grid.Col sm={6}>
                      <DateInput
                        disabled={autofillDatesOfService}
                        label='From'
                        {...getInputProps('datesOfService.start')}
                      />
                    </Grid.Col>
                    <Grid.Col sm={6}>
                      <DateInput
                        disabled={autofillDatesOfService}
                        label='To'
                        {...getInputProps('datesOfService.end')}
                      />
                    </Grid.Col>
                  </Grid>
                )}
              </Stack>
              <Divider />
              <Grid>
                <Grid.Col>
                  <CheckboxGroup {...getInputProps('acknowledgement')}>
                    <Checkbox
                      value='checked'
                      label='I understand that federal and state regulations (e.g. HIPAA, 42 CFR Part 2) protect my privacy and confidentiality. I can revoke this Authorization in writing at any time as long as Ophelia has not already taken action in reliance on it. I recognize that the re-disclosure or further sharing or exchange of my Patient Record(s) as shown above may occur without my written consent by someone who receives my Patient Record(s) under this Authorization. I know that I have the right to request an accounting of the disclosures of my Patient Records. I understand also that Ophelia will not condition my treatment on signing this authorization except as permitted by law. I have read, understand and agree with this Authorization and freely authorize the use and disclosure of my Patient Record(s) as shown above.'
                    />
                  </CheckboxGroup>
                </Grid.Col>
                <Grid.Col sm={6}>
                  <TextInput
                    label='Type your name to electronically sign this authorization'
                    placeholder='Type your full legal name'
                    {...getInputProps('signature')}
                  />
                </Grid.Col>
              </Grid>
            </>
          )}
          <PrimaryButton
            fullWidth={isMobile}
            onClick={onSubmit}
            loading={isCreatingRoi || isUpdatingRoi}
          >
            Sign and submit
          </PrimaryButton>
        </>
      )}
    </Stack>
  )
}
