import { UseFormReturnType } from '@mantine/form'
import { Group, PrimaryButton, Radio, RadioGroup, ResponsiveBox, Stack } from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import { SubstancesAndPrescribedOpioidsForm } from './CurrentSubstancesAndPrescribedOpioidsUsed'

const PRESCRIBED_OPIOIDS_USED_YES: PromptResponseContextKey = 'prescribed_opioids_used_yes'
const PRESCRIBED_OPIOIDS_USED_NO: PromptResponseContextKey = 'prescribed_opioids_used_no'

type CurrentSubstancesUsedProps = {
  form: UseFormReturnType<
    SubstancesAndPrescribedOpioidsForm,
    (values: SubstancesAndPrescribedOpioidsForm) => SubstancesAndPrescribedOpioidsForm
  >
  handleClickNext: () => void
}

export const CurrentPrescribedOpioidsUsed = ({
  form,
  handleClickNext,
}: CurrentSubstancesUsedProps) => {
  return (
    <Stack spacing='lg'>
      <RadioGroup test-id='radio-group' {...form.getInputProps('prescribedOpioidsUsed')}>
        <Radio value={PRESCRIBED_OPIOIDS_USED_YES} label='Yes' />
        <Radio value={PRESCRIBED_OPIOIDS_USED_NO} label='No' />
      </RadioGroup>
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={handleClickNext}>
              Next
            </PrimaryButton>
          </Stack>
        }
        desktop={
          <Group position='apart'>
            <Group position='right'>
              <PrimaryButton test-id='button:next@desktop' onClick={handleClickNext}>
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
