import { z } from 'zod'
import { ApiSchemas } from '../../..'

const PostInsuranceCoverageReqResSchemas = {
  req: z.object({
    body: z.object({
      payerId: z.string(),
      memberId: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      birthday: z.string(),
      gender: z.string(),
    }),
  }),
  res: z.object({
    data: z.object({
      isActive: z.boolean(),
    }),
  }),
}

export const MyInsuranceCoverageApi = {
  'POST /insurance-coverage': PostInsuranceCoverageReqResSchemas,
} satisfies ApiSchemas
