import { ArrowLeftIcon, Space, Stack, TertiaryButton, TitleTwo } from '@shared/components'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { patientApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { TreatmentConsent } from '../../../documents/TreatmentConsent'
import { SignedAgreementInfo } from './SignedAgreementInfo'

export const TreatmentConsentPage = () => {
  const navigate = useNavigate()
  const [consentQueryKey, consentsQueryFunction] = patientApi.getQuery(
    'GET /consents/:type/:version',
    {
      params: {
        type: 'treatment',
        version: '1',
      },
    },
  )

  const consentQuery = useQuery(consentQueryKey, consentsQueryFunction)

  if (consentQuery.isLoading) {
    return <Skeletons />
  }

  return (
    <Stack>
      <TitleTwo>Consent for treatment</TitleTwo>
      <TreatmentConsent />
      {consentQuery.data && (
        <>
          <Space h='sm' />
          <SignedAgreementInfo
            signature={consentQuery.data?.signature}
            date={consentQuery.data?.date}
            checkboxLabel='I have read, understand, and agree to this consent for treatment.'
          />
        </>
      )}
      <TertiaryButton
        leftIcon={<ArrowLeftIcon />}
        onClick={() => {
          navigate(-1)
        }}
      >
        Back
      </TertiaryButton>
    </Stack>
  )
}
