import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { TaskHeader } from './TaskHeader'
import { TaskWorkflowPrompt } from './TaskWorkflowPrompt'
import { usePatientTask } from './use-patient-task'

export const Phq8FormTask = () => {
  const { status } = usePatientTask({
    type: 'phq-8-form',
    breadcrumb: 'PHQ-8 form',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error') {
    return <FallbackContent boundary='portal' />
  }

  return (
    <>
      <TaskHeader title='Depression wellness check' />
      <TaskWorkflowPrompt taskType='phq-8-form' />
    </>
  )
}
