import { Image, TitleTwo } from '@shared/components'
import { ReferralPartner } from '@shared/types'
import React from 'react'
import { Config } from '../../common/config'

export const PartnerLogo = ({ partner }: { partner: ReferralPartner | undefined }) => {
  if (!partner) {
    return null
  }

  /*
   * If the partner does not have a logo, we'll fallback to
   * just show their name in the header
   */
  if (!partner.assets.logo.filename) {
    return <TitleTwo>{partner.name}</TitleTwo>
  }

  return (
    <Image
      src={`${Config.REFERRAL_PARTNER_ASSETS_BASE_URL}${partner.oid}/${partner.assets.logo.filename}`}
      height='3.5rem'
      width='auto'
    />
  )
}
