import {
  ArrowLeftIcon,
  ChevronRightIcon,
  Group,
  PrimaryButton,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
} from '@shared/components'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { routes } from '../../../../common/routes'

export const ImWithThePatient = () => {
  const navigate = useNavigate()
  const onBack = () => navigate(-1)

  return (
    <Stack spacing='lg' test-id='page:partner-onboarding'>
      <Stack spacing='md'>
        <TitleTwo>Let&apos;s get started</TitleTwo>
        <Text>
          The rest of our questions are for the patient, so they should take over from here, but
          please stay with them to help complete the next steps.
        </Text>
      </Stack>
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' align='center'>
            <PrimaryButton
              fullWidth
              rightIcon={<ChevronRightIcon />}
              component={Link}
              to={`${routes.welcome.index}/${routes.welcome.children.signup}`}
              test-id='button:start@mobile'
            >
              Get started
            </PrimaryButton>
            <TertiaryButton
              test-id='button:back@mobile'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
          </Stack>
        }
        desktop={
          <Group noWrap position='apart'>
            <TertiaryButton
              test-id='button:back@desktop'
              leftIcon={<ArrowLeftIcon />}
              onClick={onBack}
            >
              Back
            </TertiaryButton>
            <PrimaryButton
              rightIcon={<ChevronRightIcon />}
              component={Link}
              to={`${routes.welcome.index}/${routes.welcome.children.signup}`}
              test-id='button:start@desktop'
            >
              Get started
            </PrimaryButton>
          </Group>
        }
      />
    </Stack>
  )
}
