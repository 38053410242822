import { Box } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { WorkflowPrompt } from '../../common/components/prompt/WorkflowPrompt'
import { useReferralPartner } from '../../common/hooks/use-partner'
import { routes } from '../../common/routes'

export const ReferralsWithPatient = () => {
  const { partnerPath } = useReferralPartner()
  const navigate = useNavigate()

  return (
    <Box test-id='page:referrals-with-patient'>
      <WorkflowPrompt
        workflowApi='referral-with-patient'
        workflowType='patient_referral_form_partner_with_patient'
        showProgress={false}
        showBackButton
        prefix={`${routes.referralsWithPatient.index}${partnerPath}`}
        onComplete={() => {
          navigate(`${routes.welcome.index}${partnerPath}`)
        }}
      />
    </Box>
  )
}
