import React, { PropsWithChildren, useState } from 'react'

type BreadcrumbsType = 'override'

export type BreadcrumbsSettings<T extends BreadcrumbsType = BreadcrumbsType> = T extends 'override'
  ? {
      type: 'override' | 'tail'
      text: string
    }
  : never

type BreadcrumbsContextType = {
  breadcrumbs: BreadcrumbsSettings | undefined
  setBreadcrumbs: (context: BreadcrumbsSettings | undefined) => void
}

export const PortalBreadcrumbsContext = React.createContext<BreadcrumbsContextType>({
  breadcrumbs: undefined,
  setBreadcrumbs: () => void 0,
})

export const PortalBreadcrumbsProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbsSettings>()

  return (
    <PortalBreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </PortalBreadcrumbsContext.Provider>
  )
}
