// Pulled from https://docs.google.com/spreadsheets/d/1T7JT9GVB7Ehdazt7mFlcHsQEjhj5hAbJb8rdkZOwj6Y/edit#gid=0

import capitalize from 'lodash/capitalize'
import { OtherString } from '.'
import { EMRTaskNoteType, EMRTaskPod, EMRTaskPriority, OutageTaskPriority } from './models/tasks'

/**
 * Task Categories are for high-level categorization of EMR tasks,
 * mostly used for labeling tasks that are used for vastly different
 * things, for instance 'communications' is used for tasks that are
 * related to inbound SMS or calls, and are not meant to be used in issue
 * analytics or rendered on the patient journey.
 *
 * As `category` is a field added after the creation of EmrTasks, any
 * null or missing task category denotes that it is an actual clinicial issue
 * as this was the initial / only use case for EmrTasks.
 */
export const EmrTaskCategories = ['communications']
export type TaskCategory = (typeof EmrTaskCategories)[number]

export const EmrTaskTypes = [
  'systems_failure',
  'merged_accounts',
  'duplicate_accounts',
  'change_personal_information',
  'change_status_to_not_responding',
  'verification',
  'in_network_check',
  'documentation_of_care',
  'tpo_internal',
  'tpo_external',
  'non_tpo_request',
  'subpoena_court_order',
  'compliance_consult',
  'medication_administration',
  'medical_concern',
  'shipment',
  'photo_submission',
  'photo_request',
  'quest_requisition',
  'labcorp_requisition',
  'resource_request',
  'support_plan',
  'pausing_care',
  'self_determined_end_of_care',
  'failure_to_pay',
  'failure_to_pay_addressed',
  'alternative_provider',
  'higher_level_of_care',
  'higher_level_of_care_urgent',
  'out_of_state',
  'resend_prescription',
  'bridge_prescription',
  'general_refill_request',
  'prescription_investigation',
  'early_fill',
  'pharmacy_transfer',
  'coverage_investigation',
  'submission',
  'appeal',
  'approval',
  'schedule_intake',
  'reschedule_intake',
  'intake_attendance_policy_discussion',
  'schedule_follow_up',
  'reschedule_follow_up',
  'follow_up_attendance_policy_discussion',
  'clinician_transfer',
  'payment_plan',
  'change_next_billing_date',
  'financial_assistance',
  'payment_policy_discussion',
  'forgive_invoice',
  'claim_status',
  'clinical_discharge',
  'co_payment',
  'oon_claim',
  'non_adherent',
  'disengagement',
  'mbc_form',
  'schedule_wellness_visit',
  'reschedule_wellness_visit',
  'insurance_inactive',
  'coverage_of_benefits',
  'superbill_request',
  'eligibility_review',
  'outstanding_balance',
  'credentialing',
  'refund',
  're_enrollment',
  'review_time',
  'clinical_contact',
  'discharge_cocm_patient',
  'account_balance_payment_agreement',
  'winback_call',
  'account_balance_payment_non_compliance',
  'process_discharge',
  'restore_visit',
  'files_update',
  'uds_update',
  'schedule_hold',
  'appointment_reschedule_failure',
  'death_known_overdose',
  'death_known_other',
  'death_unknown',
  'schedule_uds',
  'reschedule_uds',
  'schedule_check_in',
  'reschedule_check_in',
  'crs_resource_request',
  'emr_tech_issue',
  'emr_feature_request',
  'clinician_feedback',
  'review_insurance',
  'insurance_history',
  'call_back_requested',
] as const
export type TaskType = (typeof EmrTaskTypes)[number]

export const ConsultationCallTaskTypes = [
  'scheduled',
  'call_me_now',
  'returning_scheduled',
  'returning_call_me_now',
  'intake_cancelation_reason',
  'support_call',
] as const
export type ConsultationCallTaskType = (typeof ConsultationCallTaskTypes)[number]

export const OutageTaskTypes = ['outage'] as const
export type OutageTaskType = (typeof OutageTaskTypes)[number]

export const InboundCommunicationsTaskTypes = ['inbound_sms'] as const
export type InboundCommunicationsTaskType = (typeof InboundCommunicationsTaskTypes)[number]

export type EMRTaskType =
  | TaskType
  | ConsultationCallTaskType
  | OutageTaskType
  | InboundCommunicationsTaskType
export const AllEmrTaskTypes = [
  ...EmrTaskTypes,
  ...ConsultationCallTaskTypes,
  ...OutageTaskTypes,
  ...InboundCommunicationsTaskTypes,
] as const
type SupervisorType = 'supervisor' | 'medical_supervisor'

export type EMRTaskTypeMapType = {
  value: EMRTaskType
  label: string
  priority: EMRTaskPriority | OutageTaskPriority
  cocm_billable: boolean
  est_time_in_mins: number
  category: string
  clinician_visible: boolean
  defaultPod?: EMRTaskPod | SupervisorType
  active: boolean
}

export type TaskTypeMapType = {
  value: TaskType
  label: string
  priority: EMRTaskPriority
  cocm_billable: boolean
  est_time_in_mins: number
  category: string
  clinician_visible: boolean
  defaultPod?: EMRTaskPod | SupervisorType
  hideIssueInEmr?: boolean
  active: boolean
}

export type ConsultationCallTaskTypeMapType = {
  value: ConsultationCallTaskType
  label: string
  priority: EMRTaskPriority
  cocm_billable: boolean
  est_time_in_mins: number
  category: string
  clinician_visible: boolean
  defaultPod?: EMRTaskPod
  active: boolean
}

export type OutageTaskTypeMapType = {
  value: OutageTaskType
  label: string
  priority: OutageTaskPriority
  cocm_billable: boolean
  est_time_in_mins: number
  category: string
  clinician_visible: boolean
  defaultPod?: EMRTaskPod | SupervisorType
  active: boolean
}

export type InboundCommunicationsTaskMapType = {
  value: InboundCommunicationsTaskType
  label: string
  priority: EMRTaskPriority
  cocm_billable: boolean
  est_time_in_mins: number
  category: string
  clinician_visible: boolean
  defaultPod?: EMRTaskPod | SupervisorType
  active: boolean
}

export const TaskTypeMap = [
  {
    value: 'systems_failure',
    label: 'Systems failure',
    defaultPod: 'engineer',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.technical',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'merged_accounts',
    label: 'Merged accounts',
    defaultPod: 'engineer',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.technical',
    clinician_visible: false,
    active: false,
  },
  {
    value: 'duplicate_accounts',
    label: 'Duplicate accounts',
    defaultPod: 'engineer',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.technical',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'change_personal_information',
    label: 'Update patient information',
    defaultPod: 'pes',
    priority: 1,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'change_status_to_not_responding',
    label: 'Change status to not responding',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'verification',
    label: 'Verification',
    defaultPod: 'fc',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.insurance_investigation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'in_network_check',
    label: 'In-network check',
    defaultPod: 'fc',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'patient_data.insurance_investigation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'documentation_of_care',
    label: 'Documentation of care',
    defaultPod: 'pss',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'documentation',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'tpo_internal',
    label: 'TPO request - internal',
    defaultPod: 'pss',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 5,
    category: 'documentation.roi',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'tpo_external',
    label: 'TPO request - external',
    defaultPod: 'legal',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'documentation.roi',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'non_tpo_request',
    label: 'Non-TPO request',
    defaultPod: 'legal',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 5,
    category: 'documentation.roi',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'subpoena_court_order',
    label: 'Subpoena / court order',
    defaultPod: 'legal',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'documentation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'compliance_consult',
    label: 'Compliance consult',
    defaultPod: 'legal',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'documentation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'medication_administration',
    label: 'Medication administration',
    defaultPod: 'tn',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'medical',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'medical_concern',
    label: 'Medical concern',
    defaultPod: 'tn',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'medical',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'shipment',
    label: 'Shipment',
    defaultPod: 'pes',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'photo_submission',
    label: 'Photo submission',
    defaultPod: 'tn',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: false,
    active: true,
  },

  {
    value: 'photo_request',
    label: 'Photo request',
    defaultPod: 'pes',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'quest_requisition',
    label: 'Quest requisition',
    defaultPod: 'tn',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'labcorp_requisition',
    label: 'LabCorp requisition',
    defaultPod: 'tn',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.uds',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'death_known_overdose',
    label: 'Known overdose',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.death',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'death_known_other',
    label: 'Other cause of death (not OD)',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.death',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'death_unknown',
    label: 'Cause of death unknown',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'medical.death',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'resource_request',
    label: 'Resource request',
    defaultPod: 'pss',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'support.resource_management',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'crs_resource_request',
    label: 'CRS Resource request',
    defaultPod: 'pss',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'support.crs_resource_management',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'support_plan',
    label: 'Support plan',
    defaultPod: 'pss',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'support.behavioral_support',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'clinical_discharge',
    label: 'Clinical discharge',
    defaultPod: 'scc',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'pausing_care',
    label: 'Pausing care',
    defaultPod: 'pes',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'self_determined_end_of_care',
    label: 'Self-determined end of care',
    defaultPod: 'pss',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'failure_to_pay',
    label: 'Failure to pay',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'failure_to_pay_addressed',
    label: 'Failure to pay addressed',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'alternative_provider',
    label: 'Alternative provider',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'discontinuation.transfer',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'higher_level_of_care',
    label: 'Higher level of care referral (standard)',
    defaultPod: 'pss',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'discontinuation.transfer',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'higher_level_of_care_urgent',
    label: 'Higher level of care referral (urgent)',
    defaultPod: 'pss',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'discontinuation.transfer',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'out_of_state',
    label: 'Out-of-state',
    priority: 3,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'discontinuation.transfer',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'resend_prescription',
    label: 'Resend prescription',
    defaultPod: 'pss',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'prescription.queuing',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'bridge_prescription',
    label: 'Bridge prescription',
    defaultPod: 'tn',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'prescription.queuing',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'general_refill_request',
    label: 'General refill request',
    defaultPod: 'tn',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'prescription.queuing',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'prescription_investigation',
    label: 'Prescription investigation',
    priority: 5,
    defaultPod: 'pss',
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'prescription.pharmacy_engagement',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'early_fill',
    label: 'Early fill',
    defaultPod: 'tn',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'prescription.pharmacy_engagement',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'pharmacy_transfer',
    label: 'Pharmacy transfer',
    defaultPod: 'pss',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 5,
    category: 'prescription.pharmacy_engagement',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'coverage_investigation',
    label: 'Coverage investigation',
    defaultPod: 'pss',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'prescription.prior_authorization',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'submission',
    label: 'Submission',
    defaultPod: 'pss',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'prescription.prior_authorization',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'appeal',
    label: 'Appeal',
    defaultPod: 'pss',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'prescription.prior_authorization',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'approval',
    label: 'Approval',
    defaultPod: 'pss',
    priority: 2,
    cocm_billable: true,
    est_time_in_mins: 20,
    category: 'prescription.prior_authorization',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'schedule_intake',
    label: 'Schedule intake visit',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.intake',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'reschedule_intake',
    label: 'Reschedule intake visit',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.intake',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'intake_attendance_policy_discussion',
    label: 'Intake attendance support',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.intake',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'schedule_follow_up',
    label: 'Schedule follow-up visit',
    defaultPod: 'pes',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.follow_up',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'reschedule_follow_up',
    label: 'Reschedule follow-up visit',
    defaultPod: 'pes',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.follow_up',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'follow_up_attendance_policy_discussion',
    label: 'Follow-up attendance support',
    defaultPod: 'pes',
    priority: 2,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'scheduling.follow_up',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'clinician_transfer',
    label: 'Clinician transfer',
    defaultPod: 'lcc',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'scheduling.follow_up',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'clinician_feedback',
    label: 'Clinician feedback',
    defaultPod: 'engineer',
    priority: 5,
    cocm_billable: false,
    est_time_in_mins: 5,
    category: 'engineering',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'discharge_cocm_patient',
    label: 'Ineligible',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'cocm.cocm_discharge',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'payment_plan',
    label: 'Payment plan',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'refund',
    label: 'Refund',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'outstanding_balance',
    label: 'Outstanding balance',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'account_balance_payment_agreement',
    label: 'Account balance payment agreement',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 10,
    category: 'billing',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'call_back_requested',
    label: 'Call back requested',
    priority: 5,
    cocm_billable: false,
    est_time_in_mins: 10,
    category: 'billing',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'change_next_billing_date',
    label: 'Change next billing date',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'financial_assistance',
    label: 'Financial assistance',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    defaultPod: 'fc',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'payment_policy_discussion',
    label: 'Payment policy discussion',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'forgive_invoice',
    label: 'Forgive invoice',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'superbill_request',
    label: 'Superbill request',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.subscription',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'claim_status',
    label: 'Claim status',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'co_payment',
    label: 'Co-payment',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'credentialing',
    label: 'Credentialing',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    defaultPod: 'lcc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'eligibility_review',
    label: 'Eligibility review',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'coverage_of_benefits',
    label: 'Coverage of benefits',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'insurance_inactive',
    label: 'Insurance inactive',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing.insurance',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'non_adherent',
    label: 'Patient outreach due to non-adherence',
    priority: 2,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'patient_outreach.non_adherent',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'disengagement',
    label: 'Patient outreach due to disengagement',
    priority: 2,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'patient_outreach.disengagement',
    clinician_visible: false,
    active: true,
  },
  {
    value: 're_enrollment',
    label: 'Re-enrollment',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.re_enrollment',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'winback_call',
    label: 'Winback call',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.winback_call',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'mbc_form',
    label: 'MBC form',
    priority: 4,
    cocm_billable: true,
    est_time_in_mins: 10,
    category: 'cocm.panel_management',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'review_time',
    label: 'Review time',
    priority: 5,
    cocm_billable: true,
    est_time_in_mins: 3,
    category: 'cocm.panel_management',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'clinical_contact',
    label: 'Clinical contact',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'cocm.panel_management',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'account_balance_payment_non_compliance',
    label: 'ABP non-compliance',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'billing',
    defaultPod: 'fc',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'process_discharge',
    label: 'Process discharge',
    defaultPod: 'pss',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'discontinuation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'restore_visit',
    label: 'Restore visit',
    defaultPod: 'supervisor',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'data_change',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'files_update',
    label: 'Files update',
    defaultPod: 'supervisor',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'data_change',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'uds_update',
    label: 'UDS update',
    defaultPod: 'medical_supervisor',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'data_change',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'schedule_hold',
    label: 'Schedule hold',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.failure',
    defaultPod: 'pes',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'appointment_reschedule_failure',
    label: 'Appointment reschedule failure',
    priority: 3,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.failure',
    defaultPod: 'scc',
    clinician_visible: true,
    active: true,
  },
  {
    value: 'schedule_uds',
    label: 'Schedule UDS visit',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.uds',
    defaultPod: 'pes',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'reschedule_uds',
    label: 'Reschedule UDS visit',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.uds',
    defaultPod: 'pes',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'schedule_check_in',
    label: 'Schedule check-in call',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.check_in',
    defaultPod: 'pes',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'reschedule_check_in',
    label: 'Reschedule check-in call',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'scheduling.check_in',
    defaultPod: 'pes',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'emr_tech_issue',
    label: 'EMR tech issue',
    defaultPod: 'engineer',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'engineering',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'emr_feature_request',
    label: 'EMR feature request',
    defaultPod: 'product',
    priority: 1,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'product',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'review_insurance',
    label: 'Review insurance',
    defaultPod: 'ec',
    priority: 5,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.care_transfer',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'insurance_history',
    label: 'Insurance History',
    priority: 1,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'Billing',
    clinician_visible: false,
    defaultPod: 'fc',
    active: true,
  },
] satisfies TaskTypeMapType[]

export const ConsultationCallTaskTypeMap = [
  {
    value: 'call_me_now',
    label: 'Call me now',
    priority: 5,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.welcome_call',
    clinician_visible: false,
    defaultPod: 'ec',
    active: true,
  },
  {
    value: 'scheduled',
    label: 'Welcome call',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.welcome_call',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'returning_call_me_now',
    label: 'Returning call me now',
    priority: 5,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.re_enrollment',
    clinician_visible: false,
    defaultPod: 'ec',
    active: true,
  },
  {
    value: 'returning_scheduled',
    label: 'Returning welcome call',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.re_enrollment',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'intake_cancelation_reason',
    label: 'Intake Cancelation Reason',
    priority: 2,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.documentation',
    clinician_visible: false,
    active: true,
  },
  {
    value: 'support_call',
    label: 'Support Call',
    priority: 4,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'enrollment.support_call',
    clinician_visible: false,
    active: true,
  },
] satisfies ConsultationCallTaskTypeMapType[]

export const OutageTaskTypeMap = [
  {
    value: 'outage',
    label: 'Outage',
    priority: 6,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'engineering',
    defaultPod: 'engineer',
    clinician_visible: false,
    active: true,
  },
] satisfies OutageTaskTypeMapType[]

export const InboundCommunicationsTaskTypeMap = [
  {
    value: 'inbound_sms',
    label: 'SMS',
    priority: 5,
    cocm_billable: false,
    est_time_in_mins: 0,
    category: 'communication.inbound',
    clinician_visible: false,
    active: true,
  },
] satisfies InboundCommunicationsTaskMapType[]

export const EMRTaskTypeMap = [
  ...TaskTypeMap,
  ...ConsultationCallTaskTypeMap,
  ...OutageTaskTypeMap,
  ...InboundCommunicationsTaskTypeMap,
] satisfies EMRTaskTypeMapType[]

type TaskWithDefaultPod<T> = T extends { defaultPod: EMRTaskPod } ? T : never

type EMRTaskTypeWithDefaultPod = TaskWithDefaultPod<(typeof EMRTaskTypeMap)[number]>['value']

export const getPodByTaskType = (taskType: EMRTaskTypeWithDefaultPod) => {
  // Type assertion is safe since we can only pass in EMRTaskTypeWithDefaultPod
  const taskTypeMap = EMRTaskTypeMap.find(task => task.value === taskType) as TaskWithDefaultPod<
    (typeof EMRTaskTypeMap)[number]
  >
  return taskTypeMap.defaultPod
}

// Types for minor updating tasks (used for filtering)
export const updateTaskTypes: EMRTaskNoteType[] = ['assignee_changed', 'status_changed']

export const getPriorityForEmrTask = (taskType: EMRTaskType): EMRTaskPriority => {
  // Safe to typecast because we know that all task types have a priority
  return EMRTaskTypeMap.find(type => type.value === taskType)?.priority as EMRTaskPriority
}

export const getFormattedTaskCategory = (category: string) =>
  category.includes('.')
    ? category
        .split('.')
        .map(text => capitalize(text).replaceAll('_', ' '))
        .join(' | ')
    : capitalize(category).replaceAll('_', ' ')
export const EMRTaskCategories = EMRTaskTypeMap.map(task => getFormattedTaskCategory(task.category))
export type EMRTaskCategoryType = (typeof EMRTaskCategories)[number]

export const getNonVisitEventTitleForTask = (taskType: EMRTaskTypeMapType) => {
  const subcategory = taskType.category.includes('.')
    ? capitalize(taskType.category.split('.')[1]).replace(/_/g, ' ')
    : ''

  return subcategory ? `${taskType.label} | ${subcategory}` : `${taskType.label}`
}
export const NonVisitEventTitlesForTasks = EMRTaskTypeMap.map(task =>
  getNonVisitEventTitleForTask(task),
)

export const getEmrTechIssueDescriptionTemplate = (pageUrl: string) => {
  return [
    `<p>Describe the issue: </p>`,
    `<p>Error message seen, if applicable: </p>`,
    `<p>Issue occurring on this page: ${pageUrl} </p>`,
  ].join('')
}

export type NonVisitEventTitleForTaskType = OtherString
