import { List, ListItem, Stack, Text } from '@shared/components'
import React from 'react'

export const FinancialAgreementV5 = () => {
  return (
    <Stack>
      <Text bold>Self Pay and Insurance</Text>
      <Text bold>Self-Pay:</Text>
      <Text>
        You may choose to be solely responsible for the cost of your care, by paying the monthly
        rate of $195 per month with a credit or debit card. If you have health insurance but are
        choosing not to use or provide your insurance, you can self-pay for services directly.
      </Text>
      <Text>
        If you do not want to self-pay, you have another choice. You can call your health care
        program and ask them to find a different health care provider for you. This is your decision
        and Ophelia respects your right to pick a health care provider that makes sense for you.
      </Text>

      <Text bold>Insurance:</Text>
      <Text>
        If you plan to use health insurance, you must provide Ophelia with accurate and complete
        information concerning your primary and secondary insurance medical benefits as well as
        current photo identification at each visit. Ophelia may or may not be a participating
        provider with your health care program &ndash; or your health care program may say that the
        services are &ldquo;not covered&rdquo;- meaning that it will not pay for the types of
        services Ophelia provides. Therefore, we cannot guarantee that your care will be covered
        and/or paid for by your insurance company.
      </Text>

      <Text>
        If Ophelia is participating with your benefit plan, you agree to Ophelia submitting claims
        on your behalf for services provided, and that you will be responsible for the cost of any
        co-payments, deductibles and services not covered.
      </Text>

      <Text>
        You may be required to pay a co-payment for services rendered at Ophelia based on your
        insurance provider and plan. Copays may be included for any eligible service including
        clinical visits, collaborative care, non-face-to-face services, or other service lines. The
        potential coinsurance amount and deductible will vary depending on your health plan and
        specific coverage. You are responsible for potential cost sharing expenses. For more
        information, please contact your health insurance provider.
      </Text>

      <Text>
        If Ophelia is considered non-participating or out-of-network with your particular benefit
        plan or the services are not covered, you agree that you will be responsible for the cost of
        all care rendered to you by Ophelia.
      </Text>

      <Text bold>No-Show Fees:</Text>

      <Text>
        Regardless of your choice to self-pay or use health insurance, you may be charged a separate
        no-show fee of $20 for each scheduled visit you fail to attend or cancel within 24 hours of
        the visit.
      </Text>

      <Text bold>Financial Assistance:</Text>

      <Text>
        If you need help paying for services, please ask Ophelia about its financial assistance
        policy.
      </Text>

      <Text bold>Financial Agreement:</Text>

      <Text>
        Please read this Financial Agreement very carefully &ndash; it is a legal agreement. You
        should not sign this Financial Agreement if you do not understand what it means.
      </Text>

      <Text>
        By signing below, I agree that I will be financially responsible for payments to Ophelia for
        its services, as follows:
      </Text>

      <List spacing='md'>
        <ListItem>
          <Text>
            If I don&rsquo;t have health insurance or do not want to use my insurance, I will
            personally pay all costs using the self-pay program.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            If I have insurance that Ophelia is participating in, I authorize Ophelia to submit
            claims and receive payment on my behalf for any services provided to me. I authorize
            Ophelia to release my information as needed for the purpose of benefit, eligibility, and
            payment determinations. I will personally pay Ophelia for any co-payments or
            deductibles, and for any services that are not covered by my insurance plan.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            If I have insurance, but Ophelia is not a provider in my plan, or my plan does not cover
            the services I want from Ophelia, I agree to personally pay for the services I receive
            from Ophelia.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            Regardless of my choice to self-pay or use health insurance, I agree to personally pay
            Ophelia for any no-show fees charged due to missed visits or visits canceled within 24
            hours of the scheduled time.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            If I discontinue care for any reason with a balance due to Ophelia, I understand that
            prior to re-enrolling and being scheduled with a clinician, I will be required to pay
            50% of my total balance and to agree to an account balance payment agreement for the
            remainder. If my total balance is less than $390, I will need to pay no more than $195
            before re-enrolling.
          </Text>
        </ListItem>
      </List>

      <Text></Text>

      <Text bold>Authorization for Automatic Payment</Text>

      <Text>
        By signing below, I agree that my credit card on file can be used to automatically pay any
        out of pocket expenses, as follows:
      </Text>

      <List spacing='md'>
        <ListItem>
          <Text>
            If I have chosen to participate in the self-pay program, Ophelia will charge my card on
            file up to $195 automatically on the subscription renewal date.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            If I am using my health insurance, for any co-payments or deductibles, Ophelia will
            provide me notice of the amount due ten days prior to charging the amount to my payment
            method on file, or if I have more than one payment method on file, to my chosen default
            payment method. A statement detailing the services billed will be available to me in my
            patient portal.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            For any no-show fees due, Ophelia will charge my card $20 automatically on the date of
            service for which the no-show fee was charged.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            If the payment due date authorized herein falls on a weekend or holiday, I understand
            that the payment may be executed on the next business day. I understand that because
            this is an electronic transaction, these funds may be charged to my credit card each
            period as soon as the scheduled transaction date. If the amount due is not timely paid,
            my service may be canceled, as set forth in the Terms of Service. I understand that
            Ophelia may at its discretion attempt to reinitiate any rejected or unsuccessful charge
            up to three (3) times. I understand that my financial institution may impose fees in
            connection with rejected payments, and I agree that Ophelia does not have any liability
            for such fees.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            I understand Authorization for Automatic Payment may be withdrawn by contacting Ophelia
            at (215)-585-2144 and speaking with a member of the support team.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            I understand that this authorization in no way limits any right I may have under federal
            law to stop payment of a preauthorized electronic transfer by contacting my financial
            institution. I agree to notify Ophelia of any changes to the Payment Method, or
            termination of this authorization, at least three (3) days prior to the next billing
            date.
          </Text>
        </ListItem>
      </List>
      <Text bold>Acknowledgement of Third Party Payment Processor - Stripe</Text>
      <Text>
        {`Stripe ("Stripe") processes payments, and provides other payment-related services for Ophelia Health, Inc.
        ("Ophelia").`}
      </Text>
      <Text>
        I authorize Ophelia Health to disclose information including my name, address, date(s) of
        service, subscription and service-related information, account balance, and payment
        information to Stripe for Ophelia Health to obtain reimbursement for the services provided
        to me, communicate with Stripe regarding payment for services provided to me, and related
        health care operations purposes. I understand that this Authorization is voluntary and that
        Ophelia Health may not condition my treatment, payment, enrollment or eligibility for
        benefits on whether I have signed this Authorization except as permitted by law. I may
        revoke this Authorization by notifying Ophelia Health in writing, except to the extent that
        action has been taken in reliance upon my Authorization. The information disclosed pursuant
        to this Authorization may be subject to redisclosure by the recipient, including to any
        third party payment service option I choose through Stripe, and no longer protected by
        federal law; however, federal law 42 CFR Part 2 prohibits the recipient from re-disclosing
        my substance use disorder information except as permitted by law. I understand that if I
        agree to sign this Authorization, I have the right to obtain a signed copy of the
        Authorization. This Authorization will expire one (1) year from the date of my
        acknowledgement.
      </Text>
    </Stack>
  )
}
