import { Box, Grid, Skeleton } from '@shared/components'
import React from 'react'

type SkeletonsProps = {
  type?: 'form' | 'entries' | 'data'
}

export const Skeletons = ({ type }: SkeletonsProps) => {
  if (type === 'entries') {
    return (
      <Box>
        <Skeleton mb='lg' height={24} width='65%' radius='sm' />
        <Skeleton mb='md' height={96} radius='sm' />
        <Skeleton mb='md' height={96} radius='sm' />
        <Skeleton mb='md' height={96} radius='sm' />
      </Box>
    )
  }

  if (type === 'data') {
    return (
      <Grid>
        <Grid.Col span={12} md={6}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={16} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={16} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={16} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={16} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={16} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={16} width='100%' radius='sm' />
        </Grid.Col>
      </Grid>
    )
  }

  if (type === 'form') {
    return (
      <Grid>
        <Grid.Col span={12} md={6}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={48} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={48} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={4}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={48} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={4}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={48} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12} md={4}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={48} width='100%' radius='sm' />
        </Grid.Col>
        <Grid.Col span={12}>
          <Skeleton mb='sm' height={16} width={180} radius='sm' />
          <Skeleton height={48} width='100%' radius='sm' />
        </Grid.Col>
      </Grid>
    )
  }

  return (
    <Box>
      <Skeleton mb='sm' height={32} width='65%' radius='sm' />
      <Skeleton mb='lg' height={16} width='75%' radius='sm' />
      <Skeleton mb='sm' height={24} radius='sm' />
      <Skeleton mb='sm' height={24} radius='sm' />
      <Skeleton height={24} radius='sm' />
    </Box>
  )
}
