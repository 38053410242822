import React, { PropsWithChildren } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Skeletons } from '../common/components'
import { useAuth } from '../common/hooks'
import { useReferralPartner } from '../common/hooks/use-partner'
import { routes } from '../common/routes'
import { PartnerPage } from '../welcome/pages/PartnerPage'
import * as Pages from './pages'

export const ScheduledAuth = ({
  children,
  notAuthorizedRedirect = false,
}: PropsWithChildren<{ notAuthorizedRedirect?: boolean }>) => {
  const { isLoading, currentUser, isAuthorized } = useAuth()
  const { partnerPath } = useReferralPartner()

  if (isLoading) {
    return <Skeletons />
  }

  if (notAuthorizedRedirect && !isAuthorized) {
    return <Navigate replace to={`${routes.welcome.index}${partnerPath}`} />
  }

  if (isAuthorized) {
    /*
     * An authorized user with an incomplete workflow needs to go
     * to /onboarding to complete it
     */
    if (currentUser?.data?.account.requiresOnboarding) {
      return <Navigate replace to={`${routes.onboarding.index}${partnerPath}`} />
    }
  }

  return <>{children}</>
}

export const ScheduledApp = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <ScheduledAuth notAuthorizedRedirect>
            <Pages.AppointmentScheduled />
          </ScheduledAuth>
        }
      />
      <Route
        path={routes.scheduled.children.workflowSessionId}
        element={
          <ScheduledAuth>
            <Pages.AppointmentScheduled />
          </ScheduledAuth>
        }
      />
      <Route
        path={`/:referralPartnerId${routes.scheduled.children.workflowSessionId}`}
        element={
          <PartnerPage>
            <ScheduledAuth>
              <Pages.AppointmentScheduled />
            </ScheduledAuth>
          </PartnerPage>
        }
      />
      <Route path='*' element={<NavigateToHome />} />
    </Routes>
  )
}

const NavigateToHome = () => {
  const { partnerPath } = useReferralPartner()
  return <Navigate replace to={`${routes.welcome.index}${partnerPath}`} />
}
