import { PropsWithChildren, default as React } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Skeletons, isAllowedInPortal } from '../common/components'
import { useAuth } from '../common/hooks'
import { routes } from '../common/routes'
import { PartnerPage } from '../welcome/pages/PartnerPage'
import * as Pages from './pages'

const OnboardingAuth = ({
  children,
  requireAuthorization = false,
}: PropsWithChildren<{ requireAuthorization?: boolean }>) => {
  const { isAuthorized, authenticationMethod, currentUser, isLoading } = useAuth()

  if (isLoading) {
    return <Skeletons />
  }

  if (requireAuthorization && !isAuthorized) {
    return <Navigate replace to={`${routes.welcome.index}`} />
  }

  if (isAuthorized) {
    /*
     * There is no use case for loading /onboarding for a patient who
     * has already completed their onboarding workflow
     */
    if (currentUser?.data?.account.waitingForWelcomeCall) {
      return <Navigate replace to={`${routes.scheduled.index}`} />
    }

    // Whenever a user is ready for the portal, they should be sent there
    if (
      !currentUser?.data?.account.requiresOnboarding &&
      isAllowedInPortal(currentUser?.data, authenticationMethod)
    ) {
      return <Navigate replace to={routes.portal.index} />
    }
  }

  return <>{children}</>
}

export const OnboardingApp = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <OnboardingAuth requireAuthorization>
            <Pages.OnboardingWorkflowPrompt />
          </OnboardingAuth>
        }
      />
      <Route
        path=':referralPartnerId'
        element={
          <PartnerPage>
            <OnboardingAuth>
              <Pages.OnboardingWorkflowPrompt />
            </OnboardingAuth>
          </PartnerPage>
        }
      />
      <Route
        path={`:referralPartnerId${routes.onboarding.children.workflowSession}`}
        element={
          <PartnerPage>
            <OnboardingAuth>
              <Pages.OnboardingWorkflowPrompt />
            </OnboardingAuth>
          </PartnerPage>
        }
      />
      <Route
        path={`:referralPartnerId${routes.onboarding.children.workflowPrompt}`}
        element={
          <PartnerPage>
            <OnboardingAuth>
              <Pages.OnboardingWorkflowPrompt />
            </OnboardingAuth>
          </PartnerPage>
        }
      />
      <Route
        path={routes.onboarding.children.workflowPrompt}
        element={
          <OnboardingAuth requireAuthorization>
            <Pages.OnboardingWorkflowPrompt />
          </OnboardingAuth>
        }
      />
      <Route
        path={routes.onboarding.children.workflowSession}
        element={
          <OnboardingAuth requireAuthorization>
            <Pages.OnboardingWorkflowPrompt />
          </OnboardingAuth>
        }
      />
      <Route
        path={routes.onboarding.children.welcome}
        element={
          <OnboardingAuth requireAuthorization>
            <Pages.SkipOnboardingWelcomeCallScheduled />
          </OnboardingAuth>
        }
      />
      <Route path='*' element={<Navigate replace to={routes.onboarding.index} />} />
    </Routes>
  )
}
