// eslint-disable-next-line no-restricted-imports
import { List as MantineList, ListProps as MantineListProps, useMantineTheme } from '@mantine/core'
import React from 'react'
import { colorToHex } from './inputs'

export const List = ({ icon = '•', color, ...rest }: MantineListProps) => {
  const {
    spacing,
    other: { colors },
  } = useMantineTheme()
  return (
    <MantineList
      sx={{
        color: colorToHex(color, colors) ?? colors.text[0],
      }}
      icon={icon}
      pl={spacing.xs}
      {...rest}
    />
  )
}

export const ListItem = MantineList.Item
export type ListProps = MantineListProps
