import { Box, List, ListItem, Text } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { TaskHeader } from './TaskHeader'
import { UploadIdForm } from './UploadIdForm'
import { usePatientFormTask } from './use-patient-task'

export const UploadIdTask = () => {
  const { payload, status, submit, isSaving } = usePatientFormTask({
    type: 'upload-id',
    breadcrumb: 'Upload ID',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <Box test-id='page:upload-id-task'>
      <TaskHeader title='Upload photos of your government issued ID'>
        <>
          <Text>Examples of a government-issued ID include:</Text>
          <List listStyleType='disc'>
            <ListItem>Driver’s license</ListItem>
            <ListItem>Passport</ListItem>
            <ListItem>Military ID</ListItem>
          </List>
          <Text>
            Make sure all 4 corners of your ID are in the photo and that your ID includes your
            photo, your date of birth, and an expiration date.
          </Text>
        </>
      </TaskHeader>
      <UploadIdForm initialValues={payload} submit={submit} saving={isSaving} />
    </Box>
  )
}
