import { useForm } from '@mantine/form'
import { getHotkeyHandler, useDebouncedValue, useScrollIntoView } from '@mantine/hooks'
import {
  Box,
  ColorSwatch,
  Flex,
  Group,
  Loader,
  PrimaryButton,
  SendIcon,
  Stack,
  Text,
  Textarea,
  TitleThree,
  UnstyledButton,
  XIcon,
  useLifecycle,
  useMantineTheme,
  validateWith,
} from '@shared/components'
import { ConversationModel, Message, MessageModel } from '@shared/types'
import { dayjs, sortBy, toTime } from '@shared/utils'
import minBy from 'lodash/minBy'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import {
  FirebaseUser,
  addDoc,
  auth,
  collection,
  db,
  doc,
  getDoc,
  onAuthStateChanged,
  onSnapshot,
  setDoc,
  signInAnonymously,
} from '../common/firebase'
import { inputCharacterLimitExceeded, isRequired } from '../common/forms'
import * as FullStory from '../common/fullstory'

export const CHATBOX_SEARCH_PARAM_KEY = 'chatbox'
export const CHATBOX_SEARCH_PARAM_VALUE = 'true'

const ChatInput = ({
  user,
  setUser,
  setMessages,
}: {
  user: FirebaseUser | null
  setUser: React.Dispatch<React.SetStateAction<FirebaseUser | null>>
  setMessages: React.Dispatch<React.SetStateAction<MessageModel[] | null>>
}) => {
  const messageForm = useForm<{ message: string }>({
    initialValues: {
      message: '',
    },
    validate: {
      message: validateWith(
        isRequired,
        inputCharacterLimitExceeded({
          characterLimit: 280,
        }),
      ),
    },
  })

  const createAnonymousUser = useMutation(
    async () => {
      const userCredential = await signInAnonymously(auth)
      return userCredential.user
    },
    {
      onSuccess: user => setUser(user),
      onError: () => {
        // TODO handle error
      },
    },
  )

  const addMessage = useMutation(
    (data: { uid: string; message: MessageModel }) => {
      return addDoc(collection(db, `conversations/${data.uid}/messages`), data.message)
    },
    {
      onError: () => {
        // TODO handle error
      },
    },
  )

  // Use a debounced loader so that users cannot spam the send button
  const [addMessageIsLoading] = useDebouncedValue(addMessage.isLoading, toTime('1 second').ms(), {
    leading: true,
  })

  const isDisabled = messageForm.values.message.length === 0
  const isLoading = createAnonymousUser.isLoading || addMessageIsLoading

  const handleMessageSubmission = async () => {
    if (isLoading) {
      return
    }

    if (messageForm.validate().hasErrors) {
      return
    }

    const messageData: MessageModel = {
      userType: 'user',
      message: messageForm.values.message,
      createdAt: dayjs().toISOString(),
      slackTs: null,
    }

    let currentUser = user

    /*
     * Eagerly set message to prevent waiting for firebase to respond to the auth user creation.
     * This message will be overwritten with the database response after the user is created.
     */
    if (!currentUser) {
      setMessages(prevMessages => (prevMessages || []).concat(messageData))
      currentUser = await createAnonymousUser.mutateAsync()
    }

    messageForm.reset()
    addMessage.mutate({
      uid: currentUser.uid,
      message: messageData,
    })
  }

  return (
    <Flex gap='sm' align='flex-end'>
      <Textarea
        style={{ flexGrow: 1 }}
        placeholder='Write a message'
        minRows={1}
        autosize
        onKeyDown={getHotkeyHandler([['Enter', handleMessageSubmission]])}
        {...messageForm.getInputProps('message')}
      />
      <PrimaryButton
        leftIcon={<SendIcon />}
        disabled={isDisabled}
        loading={isLoading}
        onClick={handleMessageSubmission}
      />
    </Flex>
  )
}

const MessageRow = ({
  message,
  userType,
  createdAt,
  shouldDisplayHeader,
}: Partial<MessageModel> & { shouldDisplayHeader: boolean }) => {
  const isSiteVisitor = userType === 'user'

  const {
    spacing,
    other: { colors, sizes },
  } = useMantineTheme()

  const messageTextColor = isSiteVisitor ? colors.text[3] : colors.text[0]
  const headerTextColor = colors.actions[2]
  // NOTE: Dhwani approved using this color, despite it not being in our design library
  const backgroundColor = isSiteVisitor ? colors.actions[0] : '#37383D'
  const sectionAlignment = isSiteVisitor ? 'flex-end' : 'flex-start'
  const textAlignment = isSiteVisitor ? 'right' : 'left'

  return (
    <Stack spacing='xs' align={sectionAlignment}>
      {shouldDisplayHeader && (
        <Group spacing='xs'>
          {!isSiteVisitor && (
            <ColorSwatch
              color={colors.success[0]}
              size={sizes.icon.sm}
              sx={{ border: `${spacing.xs} solid ${colors.success[1]}` }}
            />
          )}
          <Text bold size='xs' color={headerTextColor}>
            {isSiteVisitor ? 'You' : 'Ophelia'}
          </Text>
          {createdAt && (
            <Text size='xs' color={headerTextColor}>
              {dayjs(createdAt).format('h:mm a')}
            </Text>
          )}
        </Group>
      )}
      <Box
        px='sm'
        py='xs'
        sx={{
          backgroundColor,
          borderRadius: sizes.padding.xs,
          wordBreak: 'break-word',
          maxWidth: '75%',
        }}
      >
        <Text
          color={messageTextColor}
          align={textAlignment}
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {message}
        </Text>
      </Box>
    </Stack>
  )
}

type ChatboxProps = {
  onClose: () => void
}

const getChatboxReferrer = () => {
  const searchParams = new URLSearchParams(window.location.search)
  // By default, assume that the user was referred to the chatbox from the portal
  let referrer: ConversationModel['referrer'] = 'portal'

  // if chatbox param = true, then the user was referred to the chatbox from www or community
  if (searchParams.get(CHATBOX_SEARCH_PARAM_KEY) === CHATBOX_SEARCH_PARAM_VALUE) {
    // NOTE: eventually add a utm_source to www
    referrer = 'www'

    // if the utm_source is also community, then the user was specifically referred to the chatbox from the community
    if (searchParams.get('utm_source') === 'community') {
      referrer = 'community'
    }
  }

  return referrer
}

const getChatboxTitle = (referrer: ConversationModel['referrer']) => {
  switch (referrer) {
    case 'portal':
      return "Chat with Ophelia's team"
    case 'www':
      return "Chat with Ophelia's team"
    case 'community':
      return "We've got a lot of resources"
    default:
      return "Chat with Ophelia's team"
  }
}

export const Chatbox = (props: ChatboxProps) => {
  const referrer = getChatboxReferrer()
  const [user, setUser] = useState<FirebaseUser | null>(null)
  // Initialize messages to null so that we can display a loading state until the messages are fetched (empty list or not)
  const [messages, setMessages] = useState<MessageModel[] | null>(null)
  const [initialTimestamp, setInitialTimestamp] = useState<dayjs.Dayjs | null>(null)
  const { scrollIntoView, targetRef, scrollableRef } = useScrollIntoView<HTMLDivElement>()

  useEffect(() => {
    const isUserAvailable = user?.uid

    if (!isUserAvailable) {
      return
    }

    // Subscribe to message once authenticated user is available
    const unsubscribe = onSnapshot(
      collection(db, `conversations/${user.uid}/messages`),
      snapshot => {
        const messages = snapshot.docs.map(doc => doc.data() as Message)
        if (messages) {
          // Set the timestamp of the initial message if it hasn't been set yet
          if (!initialTimestamp) {
            const firstMessage = minBy(messages, m => m.createdAt)
            setInitialTimestamp(dayjs(firstMessage?.createdAt).subtract(1, 'minute'))
          }
          // Set the messages in state
          setMessages(messages)
        }
      },
    )

    return () => unsubscribe()
  }, [user, initialTimestamp])

  useEffect(() => {
    scrollIntoView()
  }, [messages, scrollIntoView])

  // Check if the user already has a session when the page loads
  useLifecycle({
    onMount: () => {
      FullStory.event('Chatbox Opened', {
        referrer,
      })

      const unsubscribe = onAuthStateChanged(auth, async currentUser => {
        if (!currentUser) {
          return
        }

        // Only anonymous users are allowed to use the chatbox for now
        if (!currentUser.isAnonymous) {
          return
        }

        // Check whether the user already has a conversation
        const conversationDoc = doc(db, 'conversations', currentUser.uid)
        const hasConversation = (await getDoc(conversationDoc)).exists()

        // If the user does not have a conversation, initialize one
        if (!hasConversation) {
          /*
           * Initialize conversation using the user's uid.
           * This needs to happen before writing the first message to the database,
           * as there is an `onCreate` subscriber on the conversation document that
           * will start a thread in Slack.
           */
          await setDoc(doc(db, 'conversations', currentUser.uid), {
            referrer,
            createdAt: dayjs().toISOString(),
            active: true,
          } as ConversationModel)
          FullStory.event('Chatbox Conversation Started', {
            referrer,
            conversationId: currentUser.uid,
          })
        }

        // Set the user in state
        setUser(currentUser)
      })

      return () => unsubscribe()
    },
  })

  return (
    <Stack style={{ height: '100%' }}>
      <Flex
        p='md'
        align='center'
        sx={({ other: { colors } }) => ({
          background: `linear-gradient(to right, ${colors.actions[0]}, white)`,
        })}
      >
        <TitleThree style={{ flexGrow: 1 }} color={c => c.text[3]}>
          {getChatboxTitle(referrer)}
        </TitleThree>
        <UnstyledButton onClick={props.onClose} style={{ flexGrow: 0 }}>
          <XIcon color={c => c.background[0]} />
        </UnstyledButton>
      </Flex>
      <Stack
        px='md'
        spacing='md'
        ref={scrollableRef}
        sx={{
          height: 'auto',
          overflowY: 'scroll',
          flexGrow: 1,
        }}
      >
        {/* This initial message is shown right away when there is no user OR when there are messages already */}
        {(!user || Boolean(messages)) && (
          <MessageRow
            message='Hi 👋 How can I help today?'
            userType='employee'
            createdAt={initialTimestamp?.toISOString() || ''}
            shouldDisplayHeader
          />
        )}
        {/* TODO: Update the code to group messages by user and have the spacing between user messages be md, and the spacing between messages by the same user be sm or xs */}
        <Stack spacing='md'>
          {messages?.sort(sortBy({ key: 'createdAt', order: 'ASC' })).map((message, index) => {
            const isFirstMessage = index === 0
            const isSameUser = messages[index - 1]?.userType === message.userType
            const isSentInSameMinute =
              dayjs(message.createdAt).diff(messages[index - 1]?.createdAt, 'minutes') < 1

            const shouldDisplayHeader = isFirstMessage || !isSameUser || !isSentInSameMinute
            return (
              <MessageRow
                key={message.createdAt}
                shouldDisplayHeader={shouldDisplayHeader}
                {...message}
              />
            )
          })}
          {/* This is a dummy box for scrolling to the bottom of the chatbox */}
          <Box ref={targetRef} />
        </Stack>
        {/* The loading is shown when there is no user AND there are no messages loaded yet */}
        {user && messages === null && (
          <Stack align='center'>
            <Loader size='sm' />
          </Stack>
        )}
      </Stack>
      <Stack px='md' pb='md'>
        <ChatInput user={user} setUser={setUser} setMessages={setMessages} />
      </Stack>
    </Stack>
  )
}
