// eslint-disable-next-line no-restricted-imports
import { Box, Tabs as MantineTabs, TabsProps as MantineTabsProps, TabProps } from '@mantine/core'
import React from 'react'

export type TabsProps = Omit<MantineTabsProps, 'styles'>

const Tabs = ({ children, ...props }: TabsProps) => {
  return (
    <MantineTabs
      {...props}
      styles={theme => ({
        root: { display: 'flex', flexDirection: 'column' },
        panel: {
          display: 'flex',
          flex: 1,
          alignItems: 'flex-start',
          marginTop: theme.other.sizes.padding.lg,
        },
        tab: {
          fontFamily: theme.other.fontFamilies.regular,
          paddingTop: `calc(${theme.other.sizes.padding.md} + ${theme.other.sizes.outline.md})`,
          paddingBottom: theme.other.sizes.padding.md,
          paddingLeft: `calc(${theme.other.sizes.padding.lg} + ${theme.other.sizes.outline.md})`,
          paddingRight: `calc(${theme.other.sizes.padding.lg} + ${theme.other.sizes.outline.md})`,
          borderStyle: 'solid',
          borderBottomWidth: theme.other.sizes.outline.md,
          borderColor: theme.other.colors.actions[0],
          color: theme.other.colors.text[0],
          '& svg': { stroke: theme.other.colors.background[4] },
          '&[data-active=true]': {
            fontFamily: theme.other.fontFamilies.bold,
            paddingTop: theme.other.sizes.padding.md,
            paddingBottom: `calc(${theme.other.sizes.padding.md} + ${theme.other.sizes.outline.md})`,
            paddingLeft: theme.other.sizes.padding.lg,
            paddingRight: theme.other.sizes.padding.lg,
            backgroundColor: theme.other.colors.background[0],
            color: theme.other.colors.text[0],
            pointerEvents: 'none',
            borderWidth: theme.other.sizes.outline.md,
            borderBottomWidth: 0,
            '& svg': { stroke: theme.other.colors.actions[0] },
          },
          '&:hover': {
            fontFamily: theme.other.fontFamilies.bold,
            borderColor: theme.other.colors.actions[0],
            backgroundColor: theme.other.colors.background[1],
            '& svg': { stroke: theme.other.colors.actions[0] },
            cursor: 'pointer',
          },
          '&:focus': { outline: 'none' },
        },
        tabsList: {
          borderBottomColor: theme.other.colors.actions[0],
          gap: theme.other.sizes.padding.md,
        },
      })}
    >
      {children}
    </MantineTabs>
  )
}

const Tab = ({ children, ...rest }: TabProps) => {
  /**
   * There is an invisible text component which is always bold in order to
   * reserve the space needed when hovered or active, avoiding shifting
   */
  return (
    <MantineTabs.Tab {...rest}>
      <Box display='grid'>
        <Box
          sx={({ other: { fontFamilies } }) => ({
            gridRowStart: 1,
            gridColumnStart: 1,
            visibility: 'hidden',
            fontFamily: fontFamilies.bold,
          })}
        >
          {children}
        </Box>
        <Box
          sx={({ other: { fontFamilies } }) => ({
            gridRowStart: 1,
            gridColumnStart: 1,
            visibility: 'hidden',
            fontFamily: fontFamilies.regular,
          })}
        >
          {children}
        </Box>
        <Box style={{ gridRowStart: 1, gridColumnStart: 1 }}>{children}</Box>
      </Box>
    </MantineTabs.Tab>
  )
}

Tabs.Tab = Tab
Tabs.List = MantineTabs.List
Tabs.Panel = MantineTabs.Panel

export { Tabs }
