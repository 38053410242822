import { z } from 'zod'
import { ISOStringSchema } from '../../../dates'
import { ApiSchemas } from '../../utils'

const GetInsuranceNotesReqRes = {
  req: z.object({
    query: z.object({
      patientId: z.string(),
      insuranceId: z.string(),
    }),
  }),
  res: z.object({
    data: z.array(
      z.object({
        timestamp: ISOStringSchema,
        clinicianName: z.string(),
        text: z.string(),
      }),
    ),
  }),
}

const PostInsuranceNoteReqRes = {
  req: z.object({
    body: z.object({
      patientId: z.string(),
      insuranceId: z.string(),
      text: z.string(),
    }),
  }),
  res: z.object({
    data: z.null(),
  }),
}

export const InsuranceNotesRouteSchemas = {
  'GET /insurance-notes': GetInsuranceNotesReqRes,
  'POST /insurance-notes': PostInsuranceNoteReqRes,
} satisfies ApiSchemas
