// eslint-disable-next-line no-restricted-imports
import { createStyles, CSSObject, MantineThemeOther } from '@mantine/core'
import { colorToHex, MantineColor } from '../inputs'

type IconSize = keyof MantineThemeOther['sizes']['icon']

export type StyleProps = {
  // Changes the icon's colors to a variant used for actions (like buttons)
  styled?: boolean
  color?: MantineColor | undefined
  size?: IconSize
  xTransform?: number
  yTransform?: number
}

export type StyledCSS = (secondaryColor: string) => CSSObject

export const useIconStyle = createStyles(
  (
    { other: { themeName, colors, sizes } },
    {
      styled,
      color,
      size,
      xTransform = 0,
      yTransform = 0,
      styling,
    }: Required<StyleProps> & { styling: StyledCSS | undefined },
  ) => {
    const transform = `translate(${xTransform}px, ${yTransform}px)`

    if (!styled && color) {
      const iconColor = colorToHex(color, colors)

      if (iconColor) {
        return {
          icon: {
            flex: 'none',
            width: sizes.icon[size]!,
            height: sizes.icon[size]!,
            color: iconColor,
            transform,
            ...styling?.(iconColor),
          },
        }
      }
    }

    if (themeName === 'daybreak' || themeName === 'equinox') {
      const primaryColor = colors.text[0]
      const secondaryColor = colors.actions[0]

      return {
        icon: {
          flex: 'none',
          width: sizes.icon[size]!,
          height: sizes.icon[size]!,
          color: primaryColor,
          transform,
          ...styling?.(secondaryColor),
        },
      }
    }

    const primaryColor = colors.actions[0]
    const secondaryColor = colors.background[1]

    // Default to twilight
    return {
      icon: {
        width: sizes.icon[size]!,
        height: sizes.icon[size]!,
        color: primaryColor,
        transform,
        ...styling?.(secondaryColor),
      },
    }
  },
)
