import { Pill } from '@shared/components'
import type { AppointmentStatus } from '@shared/types'
import React from 'react'

export const AppointmentPillStatus = ({ status }: { status: AppointmentStatus }) => {
  switch (status) {
    case 'no-show':
      return <Pill status='error'>No show</Pill>
    case 'canceled':
      return <Pill status='warning'>Canceled</Pill>
    case 'late-cancellation':
      return <Pill status='error'>Late Cancellation</Pill>
    case 'attended':
      return <Pill status='success'>Attended</Pill>
    case 'happening-now':
      return <Pill status='success'>Happening now</Pill>
    case 'happening-soon':
      return <Pill status='success'>Happening soon</Pill>
    default:
      return null
  }
}
