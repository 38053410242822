import {
  Box,
  ClockIcon,
  CreditCardIcon,
  emojiMap,
  HomeIcon,
  Image,
  List,
  ListItem,
  PrimaryButton,
  Stack,
  Text,
  TitleThree,
  TitleTwo,
  TrendingDownIcon,
} from '@shared/components'
import React from 'react'
import { Link } from 'react-router-dom'
import { Config } from '../../common/config'
import { routes } from '../../common/routes'

export const ReferralIntro = () => {
  return (
    <Stack spacing='lg'>
      <TitleTwo>{emojiMap['home with garden']} Get opioid addiction treatment, at home</TitleTwo>
      <List
        spacing='sm'
        sx={() => ({ paddingLeft: 0 })}
        styles={theme => ({ itemIcon: { color: theme.other.colors.text[1] } })}
      >
        <ListItem icon={<TrendingDownIcon />}>
          Reduce opioid cravings and withdrawal symptoms
        </ListItem>
        <ListItem icon={<ClockIcon />}>Suboxone prescriptions, if eligible</ListItem>
        <ListItem icon={<CreditCardIcon />}>
          Accepted by Medicaid, insurance and cash plans
        </ListItem>
        <ListItem icon={<HomeIcon />}>100% remote care from your couch</ListItem>
      </List>
      <PrimaryButton
        test-id='button:continue@mobile'
        component={Link}
        to={routes.welcome.children.signup}
        fullWidth
      >
        Book your free 15-min welcome call
      </PrimaryButton>
    </Stack>
  )
}

const HowOpheliaWorksInfoBox = ({
  title,
  description,
  image,
}: {
  title: string
  description: string
  image: React.ReactNode
}) => (
  <Box
    p='md'
    sx={({ other: { colors } }) => ({
      backgroundColor: colors.background[1],
    })}
  >
    <Stack spacing='sm' align='center'>
      <Box pb='sm'>{image}</Box>
      <TitleThree>{title}</TitleThree>
      <Text>{description}</Text>
    </Stack>
  </Box>
)

export const ReferralHowOpheliaWorks = () => {
  return (
    <Stack spacing='md'>
      <TitleTwo>How Ophelia works</TitleTwo>
      <HowOpheliaWorksInfoBox
        title='Meet your team virtually'
        description='Connect with a dedicated clinical care team via video appointment. No travel required.'
        image={
          <Image src={`${Config.IMAGE_BASE_URL}videochat.png`} w={75} h={72} fit='scale-down' />
        }
      />
      <HowOpheliaWorksInfoBox
        title='Get a Suboxone prescription, if eligible'
        description='Patients will receive a customized treatment plan and support to ensure medications are filled.'
        image={
          <Image src={`${Config.IMAGE_BASE_URL}prescription.png`} w={50} h={72} fit='scale-down' />
        }
      />
      <HowOpheliaWorksInfoBox
        title='Receive on-going support'
        description='Access to a dedicated team 7 days/week by phone, text or email'
        image={<Image src={`${Config.IMAGE_BASE_URL}support.png`} w={72} h={72} fit='scale-down' />}
      />
    </Stack>
  )
}

export const ReferralInsurance = () => {
  return (
    <Stack spacing='lg'>
      <Stack spacing='xs'>
        <TitleTwo>Accepted by most insurance</TitleTwo>
        <Text>
          Want to pay without insurance? Receive the same evidence-based treatment for $195/month.
        </Text>
      </Stack>
      <Stack spacing='lg'>
        <Image src={`${Config.IMAGE_BASE_URL}geisinger.png`} w={240} h={56} fit='scale-down' />
        <Image src={`${Config.IMAGE_BASE_URL}highmark.png`} w={240} h={47} fit='scale-down' />
        <Image src={`${Config.IMAGE_BASE_URL}medicare.png`} w={240} h={72} fit='scale-down' />
      </Stack>
      <PrimaryButton
        test-id='button:continue@mobile'
        component={Link}
        to={routes.welcome.children.signup}
        fullWidth
      >
        Book your free 15-min welcome call
      </PrimaryButton>
    </Stack>
  )
}
