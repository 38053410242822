import {
  ArrowRightIcon,
  Group,
  Pill,
  SecondaryButton,
  Stack,
  Switch,
  Text,
} from '@shared/components'
import { StripePaymentMethod } from '@shared/types'
import { dayjs } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth, usePortalDims } from '../../../common/hooks'
import { routes } from '../../../common/routes'

export type StripePaymentMethodItemProps = {
  paymentMethod: StripePaymentMethod
  isDefaultOnChange: (checked: boolean) => void
  showRemoveButton: boolean
}

export const StripePaymentMethodItem = ({
  paymentMethod,
  isDefaultOnChange,
  showRemoveButton,
}: StripePaymentMethodItemProps) => {
  const { isMobile } = usePortalDims()
  const { currentUser } = useAuth()
  const {
    portal: {
      index: portalIndex,
      children: {
        billing: {
          index: billingIndex,
          children: {
            paymentMethods: {
              index: paymentMethodsIndex,
              children: { remove: removePaymentMethodRoute, edit: editPaymentMethodRoute },
            },
          },
        },
      },
    },
  } = routes

  // Cards expire at the end of the month of the expiration date
  const cardExpirationDate = dayjs(`${paymentMethod.expYear}-${paymentMethod.expMonth}-01`).endOf(
    'month',
  )

  const isExpired = cardExpirationDate.isBefore(dayjs())
  return (
    <Stack spacing='md'>
      <Stack spacing='sm'>
        <Text bold>
          {capitalize(paymentMethod.brand)} **** {paymentMethod.last4}
        </Text>
        <Group>
          <Text>
            Exp {paymentMethod.expMonth}/{paymentMethod.expYear}
          </Text>
          {isExpired && <Pill status='error'>Expired </Pill>}
        </Group>
        {(paymentMethod.isDefault || !isExpired) &&
          !currentUser?.data?.onlyOnePaymentMethodAllowed && (
            <Switch
              label='Default'
              checked={paymentMethod.isDefault}
              onChange={event => {
                isDefaultOnChange(event.target.checked)
              }}
            />
          )}
      </Stack>
      <Group>
        <SecondaryButton
          sx={isMobile ? { flexBasis: 1, flexGrow: 1 } : {}}
          component={Link}
          to={`${portalIndex}/${billingIndex}/${paymentMethodsIndex}${editPaymentMethodRoute.replace(
            ':paymentMethodId',
            paymentMethod.id,
          )}`}
          rightIcon={<ArrowRightIcon />}
        >
          Edit card
        </SecondaryButton>
        {showRemoveButton && (
          <SecondaryButton
            sx={isMobile ? { flexBasis: 1, flexGrow: 1 } : {}}
            component={Link}
            to={`${portalIndex}/${billingIndex}/${paymentMethodsIndex}${removePaymentMethodRoute.replace(
              ':paymentMethodId',
              paymentMethod.id,
            )}`}
          >
            Remove
          </SecondaryButton>
        )}
      </Group>
    </Stack>
  )
}
