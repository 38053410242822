import { validatePassword as validatePasswordUtil } from '@shared/utils'

export const validatePassword = (password: string, isPincode: boolean) => {
  const validation = validatePasswordUtil(password, isPincode ? 'pin' : 'password')
  switch (validation) {
    case 'empty-pin':
      return 'Must enter PIN'
    case 'long-pin':
    case 'short-pin':
      return 'PIN must be 4 digits'
    case 'invalid-pin':
      return 'PIN must only contain numbers'
    case 'empty-password':
      return 'Must enter password'
    case 'short-password':
      return 'Password must be 8 characters long'
    case 'valid':
  }
}

export const validateConfirmPassword = (password: string, confirmPassword: string) =>
  password === confirmPassword ? null : 'Passwords must match.'
