import { Stack, useBanner } from '@shared/components'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React from 'react'
import { Skeletons } from '../../../common/components'
import { useSubmitPaymentInfoMutation } from '../../../common/hooks/use-submit-payment-info-mutation'
import { TaskButton } from './TaskButton'
import { TaskSteplessProps } from './taskStepProps'

export const StripeBillingInfoStep = ({ saving, submit }: TaskSteplessProps<'billing'>) => {
  const elements = useElements()
  const stripe = useStripe()
  const { showBanner } = useBanner()

  const submitPaymentInfoMutation = useSubmitPaymentInfoMutation({
    stripe,
    elements,
    submit: (paymentMethodId: string) => {
      submit({ paymentMethodId })
    },
    options: {
      onError: error => {
        if (error.message) {
          showBanner({
            type: 'error',
            label: error.message,
          })
        }
      },
    },
  })

  function onSubmit() {
    submitPaymentInfoMutation.mutate()
  }

  if (!stripe || !elements) {
    return <Skeletons />
  }

  return (
    <Stack>
      <PaymentElement />
      <TaskButton
        onSubmit={onSubmit}
        loading={saving || submitPaymentInfoMutation.isLoading}
        isLastStep
      />
    </Stack>
  )
}
