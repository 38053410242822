import { useToggle } from '@mantine/hooks'
import {
  AppShell,
  BannerPortal,
  Box,
  Stack,
  Sx,
  useBanner,
  useMantineTheme,
} from '@shared/components'
import React, { PropsWithChildren, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useAuth, useFlags, usePortalDims, usePortalQuery } from '../../hooks'
import { PortalBreadcrumbs } from './PortalBreadcrumbs'
import { PortalBreadcrumbsProvider } from './PortalContext'
import { PortalHeader } from './PortalHeader'
import { PortalNavbar } from './PortalNavbar'
import { TaskBar } from './TaskBar'

export const PortalShell = ({ children }: { children: React.ReactNode }) => {
  const { isAuthorized } = useAuth()
  const [isNavbarOpen, toggleNavbar] = useToggle([false, true])
  const { headerHeight } = usePortalDims()
  const location = useLocation()
  const { patientAppTaskUi } = useFlags()
  const { isMobile } = usePortalDims()
  const theme = useMantineTheme()
  const { banner, cleanBanner } = useBanner()

  useEffect(() => {
    if (banner && !banner.persistAfterNavigation) {
      return cleanBanner
    }
    // Clean up all state when page changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanBanner, location.pathname])

  const taskCardsQuery = usePortalQuery('GET /tasks', undefined, {
    /*
     * The query will refetch on window focus and/or reconnect if the data is stale.
     * This is done so patients always see the most up-to-date list of incomplete tasks.
     */
    enabled: Boolean(isAuthorized),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
  })

  const taskCards = taskCardsQuery.data?.tasks || []
  const hasNotifications = patientAppTaskUi && taskCards.length > 0

  return (
    <PortalBreadcrumbsProvider>
      <AppShell
        fixed={false}
        header={
          <PortalHeader
            isNavbarOpen={isNavbarOpen}
            toggleNavbar={toggleNavbar}
            hasNotifications={hasNotifications}
          />
        }
        navbar={<PortalNavbar isNavbarOpen={isNavbarOpen} toggleNavbar={toggleNavbar} />}
        styles={({ other: { colors } }) => ({
          root: {
            minHeight: '100vh',
            color: colors.text[0],
            backgroundColor: colors.background[0],
            display: 'flex',
            flexDirection: 'column',
          },
          body: { flexGrow: 1 },
          main: { padding: 0, display: 'flex', flexGrow: 1, flexDirection: 'column' },
        })}
      >
        {isMobile && (
          <Content>
            <Box sx={{ height: headerHeight, display: 'flex' }}>
              <PortalBreadcrumbs />
            </Box>
          </Content>
        )}
        <BannerPortal mb='md' />
        <Stack
          spacing={theme.spacing.lg}
          sx={theme => ({
            flexGrow: 1,
            display: 'flex',
            paddingBottom: `calc(${theme.spacing.md} * 2)`,
          })}
        >
          <Routes>
            <Route path='/' element={<TaskBar tasks={taskCards} />} />
          </Routes>
          <Content sx={{ flex: 1 }}>{children}</Content>
        </Stack>
      </AppShell>
    </PortalBreadcrumbsProvider>
  )
}

const Content = ({ children, sx }: PropsWithChildren<{ sx?: Sx }>) => {
  const { desktopMaxWidth, desktopMediaQuery, mobileMediaQuery } = usePortalDims()

  return (
    <Box sx={[{ display: 'flex', justifyContent: 'center' }, sx]}>
      <Box
        sx={({ spacing }) => ({
          flexGrow: 1,
          flexDirection: 'column',
          [mobileMediaQuery]: {
            marginLeft: `calc(${spacing.md} * 2)`,
            marginRight: `calc(${spacing.md} * 2)`,
          },
          [desktopMediaQuery]: {
            maxWidth: desktopMaxWidth,
            marginLeft: `calc(${spacing.md} * 3)`,
            marginRight: `calc(${spacing.md} * 3)`,
          },
        })}
      >
        {children}
      </Box>
    </Box>
  )
}
