import { AppointmentTypeString } from '@shared/types'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Skeletons } from '../../common/components'
import { usePortalQuery } from '../../common/hooks'
import { IntakeScheduled } from './IntakeScheduled'
import { WelcomeCallScheduled } from './WelcomeCallScheduled'

export type AppointmentDataProps = {
  appointmentData:
    | {
        datetime: string
        confirmed: boolean
        type: Extract<AppointmentTypeString, 'Initial Visit'>
        employeeName: string
        patientTaskExpirationDatetime: string
      }
    | {
        datetime: string
        callMeNow: boolean
        type: Extract<AppointmentTypeString, 'Free Consultation Call' | 'Returning welcome call'>
      }
}

export const AppointmentScheduled = () => {
  const { workflowSessionId } = useParams<{ workflowSessionId: string }>()
  const appointmentQuery = usePortalQuery(
    'GET /appointments/onboarding',
    {
      query: { workflowSessionId: workflowSessionId as string },
    },
    {
      enabled: Boolean(workflowSessionId),
    },
  )

  const appointmentData = appointmentQuery.data

  if (appointmentQuery.isLoading || !appointmentQuery.data) {
    return <Skeletons />
  }

  if (
    appointmentData?.type === 'Free Consultation Call' ||
    appointmentData?.type === 'Returning welcome call'
  ) {
    return <WelcomeCallScheduled appointmentData={appointmentData} />
  } else if (appointmentData?.type === 'Initial Visit') {
    return <IntakeScheduled appointmentData={appointmentData} />
  }

  throw new Error('Appointment type not supported')
}
