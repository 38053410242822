import { initialize, LDClient } from 'launchdarkly-js-client-sdk'
import React from 'react'
import { useQuery } from 'react-query'
import { Config } from '../config'
import { logger } from '../logger'
import { analytics } from '../rudderstack'

export const LDContext = React.createContext<{
  ldClient: LDClient | undefined
  isLdClientLoading: boolean
}>({
  ldClient: undefined,
  isLdClientLoading: false,
})

const retrieveLdClient = async () => {
  const ldClient = initialize(
    Config.LAUNCH_DARKLY_CLIENT_ID,
    { key: analytics.getAnonymousId() ?? 'tempKey' },
    {
      logger: {
        error: logger.error,
        // Add stubs for non-error logs, as these are otherwise too noisy.
        warn: () => void 0,
        debug: () => void 0,
        info: () => void 0,
      },
      sendEventsOnlyForVariation: true,
    },
  )

  await ldClient.waitUntilReady()
  return ldClient
}

export const LaunchDarklyProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading } = useQuery(['retrieveLdClient'], retrieveLdClient)
  return (
    <LDContext.Provider value={{ ldClient: data, isLdClientLoading: isLoading }}>
      {children}
    </LDContext.Provider>
  )
}
