import { isAxiosError } from 'axios'
import { ValueOf } from './utils'

export const HTTP_STATUS_CODES = {
  'Bad Request': 400,
  Unauthorized: 401,
  Forbidden: 403,
  'Not Found': 404,
  Conflict: 409,
  Gone: 410,
  'Unprocessable Entity': 422,
  'Too Many Requests': 429,
  'Internal Server Error': 500,
  'Bad Gateway': 502,
} as const

export type HttpCode = keyof typeof HTTP_STATUS_CODES
export type HttpStatus = ValueOf<typeof HTTP_STATUS_CODES>
export type HttpErrorAdditionalInfo = Record<string, string | number | boolean>

/**
 * This is the end-to-end error type. It does not include
 * sensitive info like 'stack' which leaks server details.
 */
export type OpheliaHttpError = {
  message: string
  isOpheliaHttpError: boolean
  additionalInfo?: HttpErrorAdditionalInfo
}

export const isError = (error: unknown): error is Error => {
  return error instanceof Error
}

export const getErrorMessage = (error: unknown, fallback: string): string => {
  if (isError(error)) {
    return error.message
  }

  return fallback
}

export const isOpheliaHttpError = (error: unknown): error is OpheliaHttpError => {
  return typeof error === 'object' && error !== null && 'isOpheliaHttpError' in error
}

export function getOpheliaHttpError(error: unknown): string | undefined
export function getOpheliaHttpError(error: unknown, fallback: string): string
export function getOpheliaHttpError(error: unknown, fallback?: string) {
  if (!error) {
    return undefined
  }

  if (isAxiosError(error)) {
    const data = error.response?.data
    if (isOpheliaHttpError(data)) {
      return data.message
    }
  }

  if (isOpheliaHttpError(error)) {
    return error.message
  }

  return fallback
}

export function getOpheliaHttpErrorAdditionalInfo(
  error: unknown,
  fallback?: HttpErrorAdditionalInfo,
): HttpErrorAdditionalInfo | undefined {
  if (!error) {
    return undefined
  }

  if (isAxiosError(error)) {
    const data = error.response?.data
    if (isOpheliaHttpError(data)) {
      return data.additionalInfo
    }
  }

  if (isOpheliaHttpError(error)) {
    return error.additionalInfo
  }

  return fallback
}
