import {
  AppShell,
  BannerPortal,
  Container,
  Dialog,
  MessageCircleIcon,
  PrimaryButton,
  useBanner,
} from '@shared/components'
import React, { PropsWithChildren, ReactElement, ReactNode, useEffect, useState } from 'react'
import { useLocation, useMatch, useSearchParams } from 'react-router-dom'
import { CHATBOX_SEARCH_PARAM_KEY, CHATBOX_SEARCH_PARAM_VALUE, Chatbox } from '../../../chatBox'
import * as FullStory from '../../fullstory'
import {
  useAuth,
  useOnboardingDims,
  usePortalDims,
  usePortalQuery,
  useScrollIntoViewOnLayoutEffect,
} from '../../hooks'
import { getFullPath } from '../../routes'
import { OnboardingHeader } from './Header'

type BaseShellProps = {
  header: ReactElement
  footer: ReactElement
  children: ReactNode
}

const BaseShell = ({ header, footer, children }: BaseShellProps) => {
  const location = useLocation()
  const { desktopMediaQuery, mobileMediaQuery } = useOnboardingDims()
  const { targetRef } = useScrollIntoViewOnLayoutEffect({
    dependencyList: [location.pathname],
    duration: 0,
  })
  const { cleanBanner } = useBanner()

  useEffect(() => {
    return cleanBanner
    // Clean up all state when page changes
  }, [cleanBanner, location.pathname])

  return (
    <AppShell
      fixed={false}
      ref={targetRef}
      header={header}
      footer={footer}
      styles={theme => ({
        root: {
          minHeight: '100vh',
          color: theme.other.colors.text[0],
          backgroundColor: theme.other.colors.background[0],
          display: 'flex',
          flexDirection: 'column',
        },
        main: { padding: 0 },
        body: {
          [mobileMediaQuery]: { flexGrow: 1 },
          [desktopMediaQuery]: { flexGrow: 0 },
        },
      })}
    >
      <BannerPortal mb='md' />
      <Container
        sx={theme => ({
          [desktopMediaQuery]: {
            marginTop: `calc(${theme.spacing.md} * 3)`,
            padding: theme.spacing.lg,
            borderWidth: theme.other.sizes.border.md,
            borderStyle: 'solid',
            /* eslint-disable-next-line no-magic-numbers */
            borderColor: theme.other.colors.background[1],
            borderRadius: theme.radius.sm,
            /* eslint-disable-next-line no-magic-numbers */
            maxWidth: `calc(${theme.spacing.md} * 40)`,
          },
          [mobileMediaQuery]: {
            paddingTop: theme.spacing.md,
            paddingLeft: theme.spacing.lg,
            paddingBottom: theme.spacing.md,
            paddingRight: theme.spacing.lg,
          },
        })}
      >
        {children}
      </Container>
    </AppShell>
  )
}

export const OnboardingShell = ({
  children,
  showCallMeNowStatus = false,
}: PropsWithChildren<{ showCallMeNowStatus?: boolean }>) => {
  return (
    <BaseShell
      footer={<ChatboxShell />}
      header={<OnboardingHeader showCallMeNowStatus={showCallMeNowStatus} />}
    >
      {children}
    </BaseShell>
  )
}

const useChatboxRoutes = () => {
  const welcome = useMatch(getFullPath('welcome'))
  const signup = useMatch(getFullPath('welcome.children.signup'))
  return [welcome, signup].some(Boolean)
}

export const ChatboxShell = () => {
  const auth = useAuth()
  /**
   * Chatbox is not acessible to authorized users (ie: authenticated users with patient documents)
   * But it is currently accessible to non-authenticated users and anonymously authenticated users
   */
  const isAllowed = !auth.isAuthorized
  const isChatboxRoute = useChatboxRoutes()
  const [open, setOpen] = useState(false)
  const { headerHeight, isDesktop } = usePortalDims()
  const [searchParams] = useSearchParams()
  const hasChatboxSearchParam =
    searchParams.get(CHATBOX_SEARCH_PARAM_KEY) === CHATBOX_SEARCH_PARAM_VALUE

  // Fetch chatbox status from server
  const chatboxStatusQuery = usePortalQuery('GET /chatbox/status', undefined, {
    onSuccess: isAvailable => {
      if (isAvailable) {
        FullStory.event('Chatbox Available')
      }
    },
  })
  const isAvailable = Boolean(chatboxStatusQuery.data)

  // Open chatbox automatically if search param is present AND chatbox status is active
  useEffect(() => {
    if (hasChatboxSearchParam && isAvailable) {
      setOpen(true)
    }
  }, [hasChatboxSearchParam, isAvailable])

  return (
    <>
      <Dialog
        keepMounted={false}
        opened={open}
        style={{
          padding: 0,
          maxWidth: '100%',
          overflow: 'hidden',
          width: isDesktop ? '390px' : '100vw',
          height: isDesktop ? '475px' : '100%',
        }}
        position={
          isDesktop
            ? {
                right: 25,
                bottom: 110,
              }
            : {
                top: headerHeight,
                bottom: 0,
              }
        }
      >
        {/* Do not mount Chatbox until dialog is opened - else wasteful Firestore connections. */}
        {open && <Chatbox onClose={() => setOpen(false)} />}
      </Dialog>
      {isChatboxRoute && isAvailable && isAllowed && (
        <PrimaryButton
          onClick={() => setOpen(status => !status)}
          style={{
            position: 'fixed',
            bottom: 50,
            right: 25,
            borderRadius: '50%',
          }}
          leftIcon={<MessageCircleIcon />}
        />
      )}
    </>
  )
}
