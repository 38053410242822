import { PHQ_SUICIDALITY_RESPONSE_CONTEXT_KEYS } from '../../cocm'

export const PHQ9_FORM_PROMPT_CONTEXT_KEYS = [
  'little_interest',
  'feeling_down',
  'trouble_falling_asleep',
  'feeling_tired',
  'poor_appetite',
  'feeling_bad_about_yourself',
  'trouble_concentrating',
  'moving_or_speaking_slowly',
  'thoughts_that_you_would',
] as const

export type Phq9FormPromptContextKey = (typeof PHQ9_FORM_PROMPT_CONTEXT_KEYS)[number]

export type Phq8FormPromptContextKey = Exclude<Phq9FormPromptContextKey, 'thoughts_that_you_would'>

const LITTLE_INTEREST_RESPONSE_CONTEXT_KEYS = [
  'little_interest_not_at_all',
  'little_interest_several',
  'little_interest_more_than_half',
  'little_interest_nearly_every',
] as const

const FEELING_DOWN_REPONSE_CONTEXT_KEYS = [
  'feeling_down_not_at_all',
  'feeling_down_several',
  'feeling_down_more_than_half',
  'feeling_down_nearly_every',
] as const

const TROUBLE_FALLING_ASLEEP_REPONSE_CONTEXT_KEYS = [
  'trouble_falling_asleep_not_at_all',
  'trouble_falling_asleep_several',
  'trouble_falling_asleep_more_than_half',
  'trouble_falling_asleep_nearly_every',
] as const

const FEELING_TIRED_REPONSE_CONTEXT_KEYS = [
  'feeling_tired_not_at_all',
  'feeling_tired_several',
  'feeling_tired_more_than_half',
  'feeling_tired_nearly_every',
] as const

const POOR_APPETITE_REPONSE_CONTEXT_KEYS = [
  'poor_appetite_not_at_all',
  'poor_appetite_several',
  'poor_appetite_more_than_half',
  'poor_appetite_nearly_every',
] as const

const FEELING_BAD_ABOUT_YOURSELF_REPONSE_CONTEXT_KEYS = [
  'feeling_bad_about_yourself_not_at_all',
  'feeling_bad_about_yourself_several',
  'feeling_bad_about_yourself_more_than_half',
  'feeling_bad_about_yourself_nearly_every',
] as const

const TROUBLE_CONCENTRATING_REPONSE_CONTEXT_KEYS = [
  'trouble_concentrating_not_at_all',
  'trouble_concentrating_several',
  'trouble_concentrating_more_than_half',
  'trouble_concentrating_nearly_every',
] as const

const MOVING_OR_SPEAKING_SLOWLY_REPONSE_CONTEXT_KEYS = [
  'moving_or_speaking_slowly_not_at_all',
  'moving_or_speaking_slowly_several',
  'moving_or_speaking_slowly_more_than_half',
  'moving_or_speaking_slowly_nearly_every',
] as const

export const PHQ9_FORM_PROMPT_RESPONSE_CONTEXT_KEYS = [
  ...LITTLE_INTEREST_RESPONSE_CONTEXT_KEYS,
  ...FEELING_DOWN_REPONSE_CONTEXT_KEYS,
  ...TROUBLE_FALLING_ASLEEP_REPONSE_CONTEXT_KEYS,
  ...FEELING_TIRED_REPONSE_CONTEXT_KEYS,
  ...POOR_APPETITE_REPONSE_CONTEXT_KEYS,
  ...FEELING_BAD_ABOUT_YOURSELF_REPONSE_CONTEXT_KEYS,
  ...TROUBLE_CONCENTRATING_REPONSE_CONTEXT_KEYS,
  ...MOVING_OR_SPEAKING_SLOWLY_REPONSE_CONTEXT_KEYS,
  ...PHQ_SUICIDALITY_RESPONSE_CONTEXT_KEYS,
] as const

export const PHQ8_FORM_PROMPT_RESPONSE_CONTEXT_KEYS = [
  ...LITTLE_INTEREST_RESPONSE_CONTEXT_KEYS,
  ...FEELING_DOWN_REPONSE_CONTEXT_KEYS,
  ...TROUBLE_FALLING_ASLEEP_REPONSE_CONTEXT_KEYS,
  ...FEELING_TIRED_REPONSE_CONTEXT_KEYS,
  ...POOR_APPETITE_REPONSE_CONTEXT_KEYS,
  ...FEELING_BAD_ABOUT_YOURSELF_REPONSE_CONTEXT_KEYS,
  ...TROUBLE_CONCENTRATING_REPONSE_CONTEXT_KEYS,
  ...MOVING_OR_SPEAKING_SLOWLY_REPONSE_CONTEXT_KEYS,
] as const

export type Phq9FormPromptResponseContextKey =
  (typeof PHQ9_FORM_PROMPT_RESPONSE_CONTEXT_KEYS)[number]

// PHQ8 is the same as PHQ9 except for it excludes the last question
export type Phq8FormPromptResponseContextKey =
  (typeof PHQ8_FORM_PROMPT_RESPONSE_CONTEXT_KEYS)[number]
