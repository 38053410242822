import { OpheliaHttpError, PatientApi } from '@shared/types'
import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation } from 'react-query'
import { patientApi } from '../api'

export const usePortalMutation = <T extends keyof PatientApi>(
  route: T,
  options?: Omit<
    UseMutationOptions<
      PatientApi[T]['res'],
      AxiosError<OpheliaHttpError>,
      Partial<PatientApi[T]['req']>
    >,
    'mutationFn'
  >,
) => {
  return useMutation<
    PatientApi[T]['res'],
    AxiosError<OpheliaHttpError>,
    Partial<PatientApi[T]['req']>
  >(patientApi.getMutation(route), options)
}
