import { UseFormReturnType, useForm } from '@mantine/form'
import {
  Group,
  PrimaryButton,
  SecondaryButton,
  Stack,
  Switch,
  TextInput,
  skipIfOtherField,
  validateWith,
} from '@shared/components'
import { Referral } from '@shared/types'
import React, { useEffect } from 'react'
import { isRequired } from '../forms'
import { UseFriendReferralArgs, useAuth, useFriendReferral } from '../hooks'

type FriendReferralPersonalizationForm = {
  showReferrerMessage: boolean
  referrerMessage: string
  showReferrerName: boolean
  referrerName: string
}

const ReferralPersonalizationForm = ({
  form,
}: {
  form: UseFormReturnType<FriendReferralPersonalizationForm>
}) => {
  const { currentUser } = useAuth()

  useEffect(() => {
    const userFirstName =
      currentUser?.data?.personalData.preferredName || currentUser?.data?.personalData.firstName

    if (form.values.showReferrerName && userFirstName) {
      form.setFieldValue('referrerName', userFirstName)
    } else {
      form.setFieldValue('referrerName', 'Your friend')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.values.showReferrerName,
    currentUser?.data?.personalData.preferredName,
    currentUser?.data?.personalData.firstName,
  ])

  return (
    <Stack>
      <Switch
        label='Add a note to show my support'
        {...form.getInputProps('showReferrerMessage')}
      />

      {form.values.showReferrerMessage && (
        <Stack>
          <Switch
            label={`Include my name with my note (so my friend knows I'm here for them)`}
            {...form.getInputProps('showReferrerName')}
          />

          <TextInput
            label='Write your note of support'
            placeholder='2-3 sentences'
            explanation={`We'll share your message with your friend when they first click on your referral link`}
            {...form.getInputProps('referrerMessage')}
          />
        </Stack>
      )}
    </Stack>
  )
}

export const ShareReferralLinkButtons = ({
  orientation,
  source,
  showPersonalizationOptions = false,
}: { orientation: 'vertical' | 'horizontal'; showPersonalizationOptions?: boolean } & Pick<
  UseFriendReferralArgs,
  'source'
>) => {
  const { currentUser, firebaseUser } = useAuth()
  const { handleCopy, handleShare, shareLoading, copyLoading, referralLinkCopied } =
    useFriendReferral({ source, referrerPatientId: firebaseUser?.uid ?? '' })

  const form = useForm<FriendReferralPersonalizationForm>({
    initialValues: {
      showReferrerMessage: false,
      referrerMessage: '',
      showReferrerName: true,
      referrerName: currentUser?.data?.personalData?.firstName || 'Your friend',
    },
    validate: {
      referrerMessage: validateWith(
        skipIfOtherField('showReferrerMessage', 'is', false),
        isRequired,
      ),
    },
  })

  const getReferralPersonalizationData = (): Referral['personalizedReferral'] => {
    if (form.values.showReferrerMessage) {
      return {
        showReferrerMessage: true,
        referrerMessage: form.values.referrerMessage,
        referrerName: form.values.referrerName,
      }
    }

    return {
      showReferrerMessage: false,
    }
  }

  const onShare = () => {
    if (form.validate().hasErrors) {
      return
    }

    void handleShare(getReferralPersonalizationData())
  }

  const onCopy = () => {
    if (form.validate().hasErrors) {
      return
    }

    void handleCopy(getReferralPersonalizationData())
  }

  const buttonText = referralLinkCopied ? 'Link copied!' : 'Copy your link'
  const fullWidth = orientation === 'vertical'

  if (navigator.share !== undefined) {
    const Wrapper = orientation === 'vertical' ? Stack : Group

    return (
      <Wrapper w={orientation === 'vertical' ? '100%' : 'fit-content'}>
        {showPersonalizationOptions && <ReferralPersonalizationForm form={form} />}
        <PrimaryButton fullWidth={fullWidth} loading={shareLoading} onClick={onShare}>
          Share your link
        </PrimaryButton>
        <SecondaryButton
          test-id='button:copy-referral-link@mobile'
          fullWidth={fullWidth}
          onClick={onCopy}
          loading={copyLoading}
        >
          {buttonText}
        </SecondaryButton>
      </Wrapper>
    )
  }

  return (
    <Stack>
      {showPersonalizationOptions && <ReferralPersonalizationForm form={form} />}
      <PrimaryButton
        test-id='button:copy-referral-link@mobile'
        fullWidth={fullWidth}
        onClick={onCopy}
        loading={copyLoading}
      >
        {buttonText}
      </PrimaryButton>
    </Stack>
  )
}
