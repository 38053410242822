import { z } from 'zod'
import { FILE_CATEGORIES } from '../../../patientFiles'

const GetByIdReqRes = {
  req: z.object({
    params: z.object({
      patientId: z.string(),
      fileId: z.string(),
    }),
  }),
  res: z.object({
    data: z
      .object({
        category: z.enum(FILE_CATEGORIES).optional(),
        oid: z.string(),
      })
      .or(z.null()),
  }),
  requestSchemaStrictValidation: true,
}

export const PatientFilesRouteSchemas = {
  'GET /patients/:patientId/files/:fileId': GetByIdReqRes,
} as const
