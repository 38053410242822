import {
  Box,
  DownloadCloudIcon,
  Pill,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  TitleThree,
  useMantineTheme,
} from '@shared/components'
import { InvoiceListItem, isInvoicePaid, isInvoicePastDue } from '@shared/types'
import { dayjs, formatDollarAmount } from '@shared/utils'
import React from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { patientApi } from '../../../common/api'
import { useAuth, usePortalDims } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { downloadFileFromUrl } from './utils'

type InvoiceCardProps = {
  invoice: InvoiceListItem
}

export const InvoiceCard = ({ invoice }: InvoiceCardProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const navigate = useNavigate()
  const { isMobile } = usePortalDims()

  const isPastDue = isInvoicePastDue(invoice)
  const isPaid = isInvoicePaid(invoice)

  const { isLoading: isCreatingInvoicePdf, mutate: createInvoicePdf } = useMutation(
    patientApi.getMutation('POST /invoices/pdf'),
    {
      onSuccess: file => {
        downloadFileFromUrl(file)
      },
    },
  )

  const { currentUser } = useAuth()
  const patient = currentUser?.data

  if (!patient) {
    return null
  }

  const amountToDisplay = isPaid ? invoice.amountDue : invoice.amountRemaining

  return (
    <Box
      sx={({ other: { colors, sizes }, radius, spacing }) => ({
        borderStyle: 'solid',
        borderColor: colors.background[1],
        borderWidth: sizes.border.md,
        borderRadius: radius.sm,
        padding: spacing.md,
      })}
    >
      <Stack spacing='md'>
        {isPaid && (
          <Pill variant='outline' status='success'>
            Paid
          </Pill>
        )}
        <Stack spacing='xs'>
          {isPastDue && <Text size='xs'>Past due</Text>}
          <TitleThree color={isPastDue ? colors.error[0] : 'inherit'}>
            {formatDollarAmount({ amount: amountToDisplay })}
          </TitleThree>
          <Text>{invoice.description}</Text>
          <Text>
            {isPaid
              ? `Paid on ${dayjs(invoice.paymentDate).format('MMMM D, YYYY')}`
              : `Due ${dayjs(invoice.dueDate).format('MMMM D, YYYY')}`}
          </Text>
        </Stack>
        <TertiaryButton
          rightIcon={<DownloadCloudIcon />}
          loading={isCreatingInvoicePdf}
          onClick={() => {
            createInvoicePdf({ data: { invoiceId: invoice.oid } })
          }}
        >
          Download
        </TertiaryButton>
        {!isPaid && (
          <SecondaryButton
            fullWidth={isMobile}
            onClick={() => {
              navigate(
                `${routes.portal.index}/${routes.portal.children.billing.index}${routes.portal.children.billing.children.addPayment}`,
                { replace: true },
              )
            }}
          >
            Add payment
          </SecondaryButton>
        )}
      </Stack>
    </Box>
  )
}
