import {
  Alert,
  CheckCircleIcon,
  Group,
  PrimaryButton,
  ShieldIcon,
  Stack,
  Text,
  TitleThree,
  TitleTwo,
  useMantineTheme,
} from '@shared/components'
import { InvoiceListItem, isAutoPayEnabled } from '@shared/types'
import { dayjs, formatDollarAmount } from '@shared/utils'
import maxBy from 'lodash/maxBy'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '../../../common/components'
import { useAuth, usePortalDims, usePortalQuery } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { calculateDischargePayment, getAutoPayInfo } from './utils'

type BalanceCardProps = {
  pastDueInvoices: InvoiceListItem[]
  unpaidInvoices: InvoiceListItem[]
  totalBalanceDueInDollars: number
  pastBalanceDueInDollars: number
}

const BalanceInfo = ({
  pastDueInvoices,
  totalBalanceDueInDollars,
  pastBalanceDueInDollars,
}: {
  pastDueInvoices: InvoiceListItem[]
  totalBalanceDueInDollars: number
  pastBalanceDueInDollars: number
}) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const mostRecentPastDueInvoice = maxBy(pastDueInvoices, 'dueDate')

  if (totalBalanceDueInDollars === 0) {
    return <TitleThree>No payment due 🙌</TitleThree>
  }

  /*
   *If the total balance is the same as the past due amount, we only want to show the past due amount.
   *The exception is if the past due amount is 0, in which case we want to show the total balance.
   */
  const displayTotalBalanceDueInDollars =
    totalBalanceDueInDollars !== pastBalanceDueInDollars || pastBalanceDueInDollars === 0

  const displayPastDueAmount = pastBalanceDueInDollars > 0

  return (
    <>
      {displayTotalBalanceDueInDollars && (
        <Stack spacing='xs'>
          <Text>Total balance</Text>
          <TitleTwo>{formatDollarAmount({ amount: totalBalanceDueInDollars })}</TitleTwo>
        </Stack>
      )}
      {displayPastDueAmount && (
        <Stack spacing='xs'>
          <Text>Past due</Text>
          <TitleTwo color={pastBalanceDueInDollars > 0 ? colors.error[0] : 'inherit'}>
            {formatDollarAmount({ amount: pastBalanceDueInDollars })}
          </TitleTwo>
          <Text>Due on {dayjs(mostRecentPastDueInvoice?.dueDate).format('MMMM D, YYYY')}</Text>
        </Stack>
      )}
    </>
  )
}

export const BalanceCard = ({
  pastDueInvoices,
  unpaidInvoices,
  totalBalanceDueInDollars,
  pastBalanceDueInDollars,
}: BalanceCardProps) => {
  const {
    other: { colors },
  } = useMantineTheme()

  const navigate = useNavigate()
  const { isMobile } = usePortalDims()

  const { data: activeSubscription } = usePortalQuery('GET /subscription')

  const { currentUser } = useAuth()
  const patient = currentUser?.data

  if (!patient) {
    return null
  }

  const isOnAutoPay = isAutoPayEnabled(patient)

  const { date: dischargeDate, displayDischargeBanner } = patient.discharge || {}

  const { nextAutopayDate, nextAutopayAmount } =
    getAutoPayInfo({ isOnAutoPay, activeSubscription, unpaidInvoices }) || {}

  const dischargePayment = calculateDischargePayment({ dischargeDate, pastDueInvoices })

  return (
    <Card title='Account balance'>
      <Stack>
        <BalanceInfo
          pastDueInvoices={pastDueInvoices}
          totalBalanceDueInDollars={totalBalanceDueInDollars}
          pastBalanceDueInDollars={pastBalanceDueInDollars}
        />
        {displayDischargeBanner && dischargePayment > 0 && (
          <Alert
            variant='secondary'
            icon={<ShieldIcon />}
            style={{ maxWidth: isMobile ? '100%' : 'fit-content' }}
          >
            Make a payment of {formatDollarAmount({ amount: dischargePayment })} by{' '}
            {dayjs(patient?.discharge?.date).format('MMMM D, YYYY')} to avoid discharge from
            treatment
          </Alert>
        )}
        {totalBalanceDueInDollars > 0 && (
          <PrimaryButton
            fullWidth={isMobile}
            onClick={() => {
              navigate(
                `${routes.portal.index}/${routes.portal.children.billing.index}${routes.portal.children.billing.children.addPayment}`,
                { replace: true },
              )
            }}
          >
            Review & pay
          </PrimaryButton>
        )}
        {isOnAutoPay && (
          <Stack spacing='sm'>
            <Group spacing='xs'>
              <CheckCircleIcon size='sm' color={colors.success[0]} />
              <Text size='xs'>Auto-pay on</Text>
            </Group>
            {nextAutopayDate && nextAutopayAmount && (
              <Text>
                Next monthly automatic payment of{' '}
                {formatDollarAmount({ amount: nextAutopayAmount })} is scheduled for{' '}
                {dayjs(nextAutopayDate).format('MMMM D, YYYY')}
              </Text>
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  )
}
