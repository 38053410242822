import { Stack, useBanner, useLifecycle } from '@shared/components'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { Navigate } from 'react-router-dom'
import { authApi, currentUserApi, patientApi } from '../common/api'
import { Skeletons } from '../common/components'
import * as FullStory from '../common/fullstory'
import { useQueryParams } from '../common/hooks'
import { logger } from '../common/logger'
import { routes } from '../common/routes'
import { sendPageEvent } from '../common/rudderstack'

export const AccountRecoveryUpdate = () => {
  const { showBanner } = useBanner()
  const query = useQueryParams()

  const accountRecoveryTokenId = query.get('token')
  useQuery(
    ['currentUserApi.getPasswordResetToken', accountRecoveryTokenId],
    async () => currentUserApi.getPasswordResetToken(accountRecoveryTokenId || ''),
    {
      onSuccess: data => signInFirebaseAuth.mutate(data?.token),
      onError: () => showBanner({ type: 'error', label: 'Your account recovery link is invalid' }),
      enabled: Boolean(accountRecoveryTokenId),
    },
  )

  const updateAccountPhone = useMutation(patientApi.getMutation('PUT /account/recovery'), {
    onSuccess: () => {
      logger.info('Successfully updated phone for account recovery')
    },
    onError: error => {
      logger.error(`Error updating phone for account recovery: ${error}`)
    },
  })

  const signInFirebaseAuth = useMutation(authApi.signInWithToken, {
    onSuccess: () => updateAccountPhone.mutate({}),
    onError: error => showBanner({ type: 'error', label: authApi.getSanitizedErrorMessage(error) }),
  })

  useLifecycle({
    onMount: () => {
      sendPageEvent('Account Recovery Update Attempted')
      FullStory.event('Account Recovery Update Attempted')
    },
  })

  if (!updateAccountPhone?.isError && !updateAccountPhone?.isSuccess) {
    return (
      <Stack test-id='page:account-recovery-update'>
        <Skeletons />
      </Stack>
    )
  }

  return (
    <Navigate
      replace
      to={`${routes.welcome.index}/${routes.welcome.children.signin}?accountRecoveryResult=${
        updateAccountPhone.isSuccess ? 'success' : 'error'
      }`}
    />
  )
}
