import { Stack, TitleTwo, useLifecycle } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { StripeElementsProvider } from '../../../common/components/stripe/StripeElementsProvider'
import { usePortalMutation } from '../../../common/hooks'
import { EditPaymentMethodForm } from './EditPaymentMethodForm'

export const AddPaymentMethod = () => {
  const setupIntentMutation = usePortalMutation('POST /payment/setup-intent')
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } =
    usePortalMutation('GET /payment/methods')
  useLifecycle({
    onMount: () => {
      setupIntentMutation.mutate({})
    },
  })

  if (setupIntentMutation.isLoading || isPaymentMethodsLoading) {
    return (
      <>
        <TitleTwo>Add payment card</TitleTwo>
        <Skeletons />
      </>
    )
  }
  if (setupIntentMutation.data) {
    return (
      <Stack>
        <TitleTwo>Add payment card</TitleTwo>
        <StripeElementsProvider clientSecret={setupIntentMutation.data?.clientSecret}>
          <EditPaymentMethodForm
            totalNumberOfPaymentMethods={paymentMethods?.paymentMethods.length ?? 0}
          />
        </StripeElementsProvider>
      </Stack>
    )
  }
  return <FallbackContent boundary='portal' />
}
