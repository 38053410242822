import { Divider, Stack, TitleTwo, useLifecycle } from '@shared/components'
import React from 'react'
import { useQuery } from 'react-query'
import { patientApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { sendPageEvent } from '../../../common/rudderstack'
import { ConsentEntry } from './ConsentEntry'
import { ReleaseOfInformation } from './ReleaseOfInformation'

const LATEST_FINANCIAL_AGREEMENT_VERSION = 3
const LATEST_TREATMENT_CONSENT_VERSION = 1
const LATEST_TPO_AGREEMENT_VERSION = 1
export const Documents = () => {
  useLifecycle({
    onMount: () => sendPageEvent('Documents'),
  })

  const [consentsQueryKey, consentsQueryFunction] = patientApi.getQuery('GET /consents')
  const consentsQuery = useQuery(consentsQueryKey, consentsQueryFunction)

  const isMostRecentFinancialAgreementSigned = consentsQuery.data?.some(
    consent =>
      consent.type === 'financial' && consent.version === LATEST_FINANCIAL_AGREEMENT_VERSION,
  )

  const isMostRecentTreatmentConsentSigned = consentsQuery.data?.some(
    consent => consent.type === 'treatment' && consent.version === LATEST_TREATMENT_CONSENT_VERSION,
  )

  const isMostRecentTpoConsentSigned = consentsQuery.data?.some(
    consent => consent.type === 'tpo' && consent.version === LATEST_TPO_AGREEMENT_VERSION,
  )

  return (
    <Stack test-id='page:documents' spacing='lg'>
      <TitleTwo>Documents</TitleTwo>
      {consentsQuery.isLoading ? (
        <Skeletons />
      ) : (
        <>
          {!isMostRecentFinancialAgreementSigned && (
            <ConsentEntry
              type='financial'
              version={LATEST_FINANCIAL_AGREEMENT_VERSION}
              signed={false}
            />
          )}
          {!isMostRecentTreatmentConsentSigned && (
            <ConsentEntry
              type='treatment'
              version={LATEST_TREATMENT_CONSENT_VERSION}
              signed={false}
            />
          )}
          {!isMostRecentTpoConsentSigned && (
            <ConsentEntry type='tpo' version={LATEST_TPO_AGREEMENT_VERSION} signed={false} />
          )}
          {consentsQuery.data?.map(consent => (
            <ConsentEntry key={consent.oid} {...consent} signed />
          ))}
        </>
      )}
      <>
        <Divider />
        <ReleaseOfInformation />
      </>
    </Stack>
  )
}
