import {
  ArrowLeftIcon,
  Box,
  Space,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
} from '@shared/components'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { patientApi } from '../../../common/api'
import { Skeletons } from '../../../common/components'
import { SignedAgreementInfo } from './SignedAgreementInfo'

export const FinancialAgreementV2Page = () => {
  const navigate = useNavigate()

  const [consentQueryKey, consentsQueryFunction] = patientApi.getQuery(
    'GET /consents/:type/:version',
    {
      params: {
        type: 'financial',
        version: '2',
      },
    },
  )

  const consentQuery = useQuery(consentQueryKey, consentsQueryFunction)

  if (consentQuery.isLoading) {
    return <Skeletons />
  }

  return (
    <Box p='md'>
      <TitleTwo>Financial agreement</TitleTwo>
      <Space h='md' />
      <Stack>
        <Text>
          I agree that I will be financially responsible for payments to Ophelia for its services,
          as follows:
        </Text>
        <Text>
          If I don’t have health insurance or do not want to use my insurance, I will personally pay
          all costs using the self-pay program.
        </Text>

        <Text>
          If I have insurance that Ophelia is participating in, I authorize Ophelia to submit claims
          and receive payment on my behalf for any services provided to me. I authorize Ophelia to
          release my information as needed for the purpose of benefit, eligibility, and payment
          determinations. I will personally pay Ophelia for any co-payments or deductibles, and for
          any services that are not covered by my insurance plan.
        </Text>

        <Text>
          If I have insurance, but Ophelia is not a provider in my plan, or my plan does not cover
          the services I want from Ophelia, I agree to personally pay for the services I receive
          from Ophelia.
        </Text>

        <Text>
          I agree that my credit card on file can be used to automatically pay any out of pocket
          expenses.
        </Text>
      </Stack>
      <Space h='lg' />
      <Stack spacing='lg'>
        {consentQuery.data && (
          <SignedAgreementInfo
            signature={consentQuery.data.signature}
            date={consentQuery.data.date}
            checkboxLabel="I have read and understand Ophelia's financial policies and this Financial Agreement"
          />
        )}
        <TertiaryButton
          leftIcon={<ArrowLeftIcon />}
          onClick={() => {
            navigate(-1)
          }}
        >
          Back
        </TertiaryButton>
      </Stack>
    </Box>
  )
}
