import { FormattedStripeSubscription, InvoiceListItem } from '@shared/types'
import { dayjs } from '@shared/utils'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'

export const downloadFileFromUrl = ({
  fileName,
  signedUrl,
}: {
  fileName: string
  signedUrl: string
}) => {
  const link = document.createElement('a')
  link.download = fileName
  link.href = signedUrl
  link.click()
}

export const calculateDischargePayment = ({
  dischargeDate,
  pastDueInvoices,
}: {
  dischargeDate: string | undefined
  pastDueInvoices: InvoiceListItem[]
}) => {
  // Patients need to pay off copays that will be at least 90 days old by the discharge date
  const COPAY_PAST_DUE_DAYS = 90
  const pastDueCopays = pastDueInvoices
    .filter(invoice => invoice.type === 'copay')
    .filter(invoice =>
      dayjs(invoice.dueDate).isBefore(dayjs(dischargeDate).subtract(COPAY_PAST_DUE_DAYS, 'days')),
    )
  const pastDueCopayAmount = sumBy(pastDueCopays, d => d.amountDue)

  // Patients need to pay off subscriptions that will be at least 30 days old by the discharge date
  const SUBSCRIPTION_PAST_DUE_DAYS = 30
  const pastDueSubscriptions = pastDueInvoices
    .filter(invoice => invoice.type === 'subscription')
    .filter(invoice =>
      dayjs(invoice.dueDate).isBefore(
        dayjs(dischargeDate).subtract(SUBSCRIPTION_PAST_DUE_DAYS, 'days'),
      ),
    )
  const pastDueSubscriptionAmount = sumBy(pastDueSubscriptions, d => d.amountDue)

  return pastDueCopayAmount + pastDueSubscriptionAmount
}

export const getAutoPayInfo = ({
  isOnAutoPay,
  activeSubscription,
  unpaidInvoices,
}: {
  isOnAutoPay: boolean
  activeSubscription: FormattedStripeSubscription | undefined
  unpaidInvoices: InvoiceListItem[]
}) => {
  if (!isOnAutoPay) {
    return null
  }

  const nextCopayInvoice = orderBy(unpaidInvoices, d => d.dueDate, 'asc')
    .filter(invoice => invoice.type === 'copay')
    .find(invoice => dayjs(invoice.dueDate).isAfter(dayjs()))

  const nextCopayPaymentDate = nextCopayInvoice?.dueDate
  // Sum up all the co-pays that are due on the same day
  const nextCopayPaymentAmount = sumBy(
    unpaidInvoices
      .filter(invoice => invoice.type === 'copay')
      .filter(invoice => dayjs(invoice.dueDate).isSame(nextCopayInvoice?.dueDate, 'day')),
    d => d.amountDue,
  )

  if (!activeSubscription) {
    return {
      nextAutopayDate: nextCopayPaymentDate,
      nextAutopayAmount: nextCopayPaymentAmount,
    }
  }

  const { nextDueDate: nextSubscriptionPaymentDate, amountInDollars: subscriptionAmount } =
    activeSubscription

  if (dayjs(nextCopayPaymentDate).isBefore(dayjs(nextSubscriptionPaymentDate))) {
    return {
      nextAutopayDate: nextCopayPaymentDate,
      nextAutopayAmount: nextCopayPaymentAmount,
    }
  }

  if (dayjs(nextCopayPaymentDate).isSame(dayjs(nextSubscriptionPaymentDate), 'day')) {
    return {
      nextAutopayDate: nextCopayPaymentDate,
      nextAutopayAmount: nextCopayPaymentAmount + subscriptionAmount,
    }
  }

  return {
    nextAutopayDate: nextSubscriptionPaymentDate,
    nextAutopayAmount: subscriptionAmount,
  }
}
