import { ArrowLeftIcon, Box, Group, ResponsiveBox, Stack, TertiaryButton } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ShareReferralLinkButtons } from '../../ShareReferralLinkButtons'

export const CopyFriendReferralLink = () => {
  const navigate = useNavigate()
  const back = () => navigate('/welcome')

  return (
    <Box test-id='page:copy-referral-link'>
      <Stack spacing='md'>
        <ResponsiveBox
          mt='lg'
          mobile={
            <Stack justify='center'>
              <ShareReferralLinkButtons source='referral_flow' orientation='vertical' />
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                style={{ margin: 'auto' }}
                onClick={back}
              >
                Back
              </TertiaryButton>
            </Stack>
          }
          desktop={
            <Group position='apart'>
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={back}
              >
                Back
              </TertiaryButton>
              <ShareReferralLinkButtons source='referral_flow' orientation='horizontal' />
            </Group>
          }
        />
      </Stack>
    </Box>
  )
}
