import { z } from 'zod'
import { ApiSchemas } from '../../utils'

const GetIntakeStates = {
  req: z.object({
    query: z.object({
      clinicianIds: z.array(z.string()).optional(),
    }),
  }),
  res: z.object({
    data: z.object({
      intakeStates: z.array(z.string()),
    }),
  }),
}

const GetCalendars = {
  req: z.object({
    query: z.object({
      clinicianIds: z.array(z.string()).optional(),
      state: z.string().optional(),
    }),
  }),
  res: z.object({
    data: z.object({
      calendarIds: z.array(z.string()),
    }),
  }),
}

export const MyIntakeStatesApi = {
  'GET /intake-states': GetIntakeStates,
  'GET /calendars': GetCalendars,
} satisfies ApiSchemas
