import { useForm } from '@mantine/form'
import { Radio, RadioGroup, Stack, Text, isAnySelected } from '@shared/components'
import { TaskPayload } from '@shared/types'
import React from 'react'
import { AvailabilitySelector } from './AvailabilitySelector'
import { TaskButton } from './TaskButton'
import { TaskStepProps } from './taskStepProps'

export const StandbyListTaskForm = ({
  initialValues,
  submit,
  saving = false,
}: TaskStepProps<'intake-visit-standby-list'>) => {
  const { Preference } = TaskPayload.IntakeVisitStandbyList
  const { OptIn } = TaskPayload.IntakeVisitStandbyList

  const { validate, values, getInputProps, setFieldValue } = useForm({
    initialValues,
    validate: {
      optIn: isAnySelected([OptIn.Yes, OptIn.No], 'Required'),
      availabilities: (value, values) =>
        value.length || values.optIn === OptIn.No || values.preference === Preference.Unset
          ? null
          : 'Select at least one',
    },
  })

  function onSubmit() {
    if (!validate().hasErrors) {
      submit('', values)
    }
  }

  return (
    <Stack>
      <Stack spacing='lg'>
        <RadioGroup {...getInputProps('optIn')}>
          <Radio value={OptIn.Yes} label='Yes' disabled={saving} />
          <Radio value={OptIn.No} label='No, thanks' disabled={saving} />
        </RadioGroup>
        {values.optIn === OptIn.Yes && (
          <Stack spacing='lg'>
            <Stack>
              <Stack spacing='xs'>
                <Text>When are you available for a sooner visit?</Text>
                <Text size='xs'>You can come back and change this later.</Text>
              </Stack>
              <RadioGroup {...getInputProps('preference')}>
                <Radio
                  value={Preference.Unset}
                  label="I'm flexible, reach out about all sooner visits"
                  disabled={saving}
                />
                <Radio
                  value={Preference.Set}
                  label="I'm less flexible, reach out about sooner visits based on my preferences"
                  disabled={saving}
                />
              </RadioGroup>
            </Stack>
            {values.preference === Preference.Set && (
              <AvailabilitySelector
                {...getInputProps('availabilities')}
                disabled={saving}
                onChange={value => setFieldValue('availabilities', value)}
              />
            )}
          </Stack>
        )}
      </Stack>
      <TaskButton onSubmit={onSubmit} loading={saving} isLastStep />
    </Stack>
  )
}
