import { useForm } from '@mantine/form'
import {
  ArrowRightIcon,
  ExternalLinkText,
  Grid,
  PrimaryButton,
  Select,
  Text,
  TextInput,
  validateWith,
  isAnySelected,
} from '@shared/components'
import { CurrentUserPatient, pronouns, Pronouns, Sex } from '@shared/types'
import { validCharacters } from '@shared/utils'
import capitalize from 'lodash/capitalize'
import React, { useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { currentUserApi } from '../../../common/api'
import { FormLabel, FormSaveSection, Photo, UploadPhoto } from '../../../common/components'
import { isFileNotLoading, isRequired } from '../../../common/forms'
import { useAuth } from '../../../common/hooks'
import { routes } from '../../../common/routes'

type Form = {
  firstName: string
  lastName: string
  preferredName: string
  birthday: string
  pronouns: Pronouns | null
  sex: Sex | null
  idCardFront: string | undefined
  idCardBack: string | undefined
}

export const PersonalSection = ({
  editing,
  toggleEditing,
}: {
  editing: boolean
  toggleEditing: () => void
}) => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  return editing ? (
    <PersonalSectionEdit currentUser={currentUser?.data} toggleEditing={toggleEditing} />
  ) : (
    <Grid>
      <Grid.Col span={12} md={6}>
        <FormLabel title='Legal name'>
          {`${currentUser?.data?.personalData?.firstName ?? ''} ${
            currentUser?.data?.personalData?.lastName ?? ''
          }`.trim()}
        </FormLabel>
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <FormLabel title='Preferred name'>
          {currentUser?.data?.personalData?.preferredName}
        </FormLabel>
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <FormLabel title='Birthday'>{currentUser?.data?.personalData?.birthday}</FormLabel>
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <FormLabel title='Pronouns'>
          {capitalize(currentUser?.data?.personalData?.pronouns)}
        </FormLabel>
      </Grid.Col>
      <Grid.Col span={12}>
        <FormLabel title='Sex assigned at birth'>
          {capitalize(currentUser?.data?.personalData?.sex)}
        </FormLabel>
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <Photo value={currentUser?.data?.files?.idCardFront} label='ID card front' />
      </Grid.Col>
      <Grid.Col span={12} md={4}>
        <Photo value={currentUser?.data?.files?.idCardBack} label='ID card back' />
      </Grid.Col>
      <Grid.Col span={12}>
        {currentUser?.data?.account.profileComplete ? (
          <PrimaryButton mt='md' onClick={() => toggleEditing()}>
            Edit
          </PrimaryButton>
        ) : (
          <PrimaryButton
            mt='md'
            onClick={() =>
              navigate(
                `${routes.portal.index}/${routes.portal.children.task.index}${routes.portal.children.task.children.profile}`,
              )
            }
            rightIcon={<ArrowRightIcon />}
          >
            Finish profile
          </PrimaryButton>
        )}
      </Grid.Col>
    </Grid>
  )
}

export const PersonalSectionEdit = ({
  currentUser,
  toggleEditing,
}: {
  currentUser: CurrentUserPatient | undefined
  toggleEditing: () => void
}) => {
  const queryClient = useQueryClient()

  const sexData = useMemo(
    () =>
      (['male', 'female'] as Sex[]).map(value => ({
        value,
        label: capitalize(value),
      })),
    [],
  )

  const pronounsData = useMemo(
    () => pronouns.map(value => ({ value, label: capitalize(value) })),
    [],
  )

  const { validate, values, getInputProps, setFieldError } = useForm<Form>({
    initialValues: {
      firstName: currentUser?.personalData.firstName ?? '',
      lastName: currentUser?.personalData.lastName ?? '',
      preferredName: currentUser?.personalData.preferredName ?? '',
      birthday: currentUser?.personalData.birthday ?? '',
      pronouns: currentUser?.personalData.pronouns ?? null,
      sex: currentUser?.personalData.sex ?? null,
      idCardFront: currentUser?.files?.idCardFront,
      idCardBack: currentUser?.files?.idCardBack,
    },
    validateInputOnBlur: ['birthday'],
    validate: {
      sex: isAnySelected(sexData, 'Required'),
      idCardBack: validateWith(isRequired, isFileNotLoading),
      idCardFront: validateWith(isRequired, isFileNotLoading),
    },
  })

  const updateCurrentUser = useMutation(currentUserApi.update)

  function onSubmit() {
    if (!validate().hasErrors && currentUser) {
      return updateCurrentUser.mutate(
        {
          personalData: {
            pronouns: values.pronouns as Pronouns,
            preferredName: values.preferredName,
          },
          files: {
            idCardFront: values.idCardFront,
            idCardBack: values.idCardBack,
          },
        },
        {
          onSuccess: () => {
            void queryClient.invalidateQueries(['currentUserApi.retrieve'])
            toggleEditing()
          },
        },
      )
    }
  }

  const saving = updateCurrentUser.isLoading

  return (
    <Grid>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Legal name'
          placeholder='Legal name'
          value={`${currentUser?.personalData.firstName} ${currentUser?.personalData.lastName}`}
          disabled
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput
          label='Preferred name'
          placeholder='Preferred name'
          maxLength={64}
          disabled={saving}
          formatter={validCharacters.name}
          {...getInputProps('preferredName')}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <TextInput label='Birthday' {...getInputProps('birthday')} disabled />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <Select
          data={pronounsData}
          placeholder='Select one...'
          label='Pronouns'
          {...getInputProps('pronouns')}
          disabled={saving}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Select
          data={sexData}
          label='Sex assigned at birth'
          placeholder='Select one...'
          {...getInputProps('sex')}
          disabled
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Text size='xs'>
          Text&nbsp;
          <ExternalLinkText size='xs' href='sms:2155852144'>
            (215) 585-2144
          </ExternalLinkText>
          &nbsp;if you’d like to change something that you can’t edit here.
        </Text>
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <UploadPhoto
          label='ID card front'
          {...getInputProps('idCardFront')}
          setError={error => setFieldError('idCardFront', error)}
          disabled={saving}
        />
      </Grid.Col>
      <Grid.Col span={12} md={6}>
        <UploadPhoto
          label='ID card back'
          {...getInputProps('idCardBack')}
          setError={error => setFieldError('idCardBack', error)}
          disabled={saving}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <FormSaveSection loading={saving} onSubmit={onSubmit} onCancel={toggleEditing} />
      </Grid.Col>
    </Grid>
  )
}
