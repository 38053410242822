import React, { useEffect } from 'react'
import { ScheduleBookingStep } from './ScheduleBookingStep'
import { FallbackContent, Skeletons } from '../../../../common/components'
import { TaskHeader } from '../TaskHeader'
import { dayjs } from '@shared/utils'
import { Box, PrimaryButton, Text, useBanner } from '@shared/components'
import { Link } from 'react-router-dom'
import { routes } from '../../../../common/routes'
import { usePatientTask } from '../use-patient-task'
import { event } from '../../../../common/fullstory'

export const ScheduleIntakeVisitTask = () => {
  const { status, data, submit, isSaving, response } = usePatientTask({
    type: 'schedule-intake-visit',
    breadcrumb: 'Schedule your intake visit',
    onSuccess: data => {
      if (data.slots.length === 0) {
        event('No Schedule Availability')
      }
    },
  })

  const { showBanner } = useBanner()
  const appointment = response?.payload

  useEffect(() => {
    if (appointment) {
      showBanner({ type: 'success', label: 'Intake visit scheduled' })
    }
  }, [appointment, showBanner])

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !data) {
    return <FallbackContent boundary='portal' />
  }

  if (appointment) {
    const appointmentDate = dayjs(appointment.datetime)

    return (
      <>
        <TaskHeader
          test-id='title:intake-visit-scheduled'
          title={`Your intake visit for ${appointmentDate.format(
            'MMM D YYYY',
          )} at ${appointmentDate.format('h:mma z')} with ${appointment.calendar} is scheduled`}
        >
          <Text>
            If you don&apos;t attend, or if you cancel less than 24 hours before your visit, you may
            be charged $20.
          </Text>
          <Text>
            Now head to your patient portal and complete all the required tasks. Once you&apos;ve
            done so, you can sign up to get notified if something opens up sooner.
          </Text>
        </TaskHeader>
        <PrimaryButton test-id='button:dashboard' component={Link} to={routes.portal.index}>
          Go to the dashboard
        </PrimaryButton>
      </>
    )
  }

  return (
    <Box test-id='page:schedule-intake-visit-task'>
      <ScheduleBookingStep
        title='Schedule your intake visit'
        type='Initial Visit'
        payload={data}
        submit={submit}
        isSaving={isSaving}
      />
    </Box>
  )
}
