import { Box } from '@shared/components'
import React from 'react'
import { FallbackContent, Skeletons } from '../../../common/components'
import { TaskHeader } from './TaskHeader'
import { TpoConsentStep } from './TpoConsentStep'
import { usePatientFormTask } from './use-patient-task'

export const TpoConsentTask = () => {
  const { submit, status, isSaving, payload } = usePatientFormTask({
    totalSteps: 1,
    type: 'tpo-consent',
    breadcrumb: 'TPO consent',
  })

  if (status === 'loading') {
    return <Skeletons />
  }

  if (status === 'error' || !payload) {
    return <FallbackContent boundary='portal' />
  }

  return (
    <Box test-id='page:tpo-consent-task'>
      <TaskHeader title='Consent to Exchange Information for Treatment, Payment, and Healthcare Operations Purposes' />
      <TpoConsentStep submit={submit} saving={isSaving} initialValues={payload} />
    </Box>
  )
}
