import { useForm } from '@mantine/form'
import {
  ArrowLeftIcon,
  Group,
  PrimaryButton,
  Radio,
  RadioGroup,
  ResponsiveBox,
  Stack,
  TertiaryButton,
  TitleTwo,
  validateWith,
} from '@shared/components'
import { PromptResponseContextKey } from '@shared/types'
import React from 'react'
import * as FullStory from '../../../../../src/common/fullstory'
import { isRequired } from '../../../forms'
import { useLDClient } from '../../../hooks'
import { analytics } from '../../../rudderstack'
import { getSessionStorageItem } from '../../../storage'
import { WidgetProps } from '../PromptContentWidget'

type Form = {
  prescribedBy: PromptResponseContextKey | undefined
}

const MEDICAL_PROFESSIONAL =
  'suboxone_prescribed_by_medical_professional' as PromptResponseContextKey
const NONE = 'suboxone_prescribed_by_none' as PromptResponseContextKey
export const SuboxonePrescribedBy = ({ response, onBack, onSubmit }: WidgetProps) => {
  const patientState = getSessionStorageItem('residence_state')
  const { ldClient } = useLDClient()

  const form = useForm<Form>({
    initialValues: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      prescribedBy: response?.value.contextKey as PromptResponseContextKey | undefined,
    },
    validate: {
      prescribedBy: validateWith(isRequired),
    },
  })

  const submitHandler = async () => {
    const { hasErrors } = form.validate()

    if (hasErrors) {
      return
    }
    if (!form.values.prescribedBy) {
      return
    }

    if (patientState) {
      const shouldIdentifyAsCareTransfer = form.values.prescribedBy === MEDICAL_PROFESSIONAL

      if (shouldIdentifyAsCareTransfer) {
        FullStory.event('Patient Is In Care Transfer Segment')
        await ldClient?.identify({
          key: analytics.getAnonymousId(),
          custom: {
            ct: true,
          },
        })
      }

      onSubmit({
        value: {
          contextKey: form.values.prescribedBy,
        },
        patient_address_state: {
          contextKey: 'custom',
          value: patientState,
        },
      })
    } else {
      onSubmit({
        value: {
          contextKey: form.values.prescribedBy,
        },
      })
    }
  }

  return (
    <Stack spacing='lg' test-id='content:suboxone-prescribed-by'>
      <TitleTwo>Is your Suboxone prescribed by a medical professional?</TitleTwo>
      <RadioGroup {...form.getInputProps('prescribedBy')}>
        <Radio
          test-id='radio:suboxone-prescribed-by:yes'
          label='Yes'
          value={MEDICAL_PROFESSIONAL}
        />
        <Radio test-id='radio:suboxone-prescribed-by:no' label='No' value={NONE} />
      </RadioGroup>
      <ResponsiveBox
        mobile={
          <Stack spacing='lg' justify='center'>
            <PrimaryButton test-id='button:next@mobile' fullWidth onClick={submitHandler}>
              Next
            </PrimaryButton>
            {onBack && (
              <TertiaryButton
                test-id='button:back@mobile'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            )}
          </Stack>
        }
        desktop={
          <Group position='apart'>
            {onBack && (
              <TertiaryButton
                test-id='button:back@desktop'
                leftIcon={<ArrowLeftIcon />}
                onClick={onBack}
              >
                Back
              </TertiaryButton>
            )}
            <Group position='right'>
              <PrimaryButton test-id='button:next@desktop' onClick={submitHandler}>
                Next
              </PrimaryButton>
            </Group>
          </Group>
        }
      />
    </Stack>
  )
}
