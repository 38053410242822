/* eslint-disable multiline-comment-style */
import { useForm } from '@mantine/form'
import { useToggle } from '@mantine/hooks'
import {
  BellIcon,
  Box,
  Center,
  DailyCalendar,
  DailyCalendarRadioGroup,
  Group,
  Image,
  PrimaryButton,
  Radio,
  Stack,
  Switch,
  TertiaryButton,
  Text,
  TitleTwo,
  isAnySelected,
  useBanner,
  useLifecycle,
} from '@shared/components'
import {
  AcuityAppointment,
  AppointmentInfo,
  AppointmentTypeString,
  STANDBY_APPOINTMENT_FIELD,
  getVisitTypeName,
} from '@shared/types'
import { dayjs, toTime } from '@shared/utils'
import React, { useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, Navigate, useParams } from 'react-router-dom'
import { getFutureAppointments, patientApi } from '../../../common/api'
import { ContactSupport, Skeletons } from '../../../common/components'
import { CalendarMessage } from '../../../common/components/CalendarMessage'
import { Config } from '../../../common/config'
import { event } from '../../../common/fullstory'
import { useAuth, usePortalBreadcrumbs, usePortalDims, useQueryParams } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { sendPageEvent, sendTrackEvent } from '../../../common/rudderstack'
import { PastVisitEntry } from '../visits/PastVisitEntry'
import { RescheduledIntakeVisitConfirmation } from './RescheduleVisitConfirmation'

export const RescheduleIntakeVisit = () => {
  useLifecycle({
    onMount: () => {
      sendPageEvent('Standby List Reschedule')
      sendTrackEvent('Standby Notification Clicked', { type: 'initialVisit' })
    },
  })

  const { data: futureVisitsQuery, isLoading: isAppointmentsLoading } = useQuery(
    ...useMemo(getFutureAppointments, []),
  )

  const visits = futureVisitsQuery || []

  const upcomingVisit = visits.find(visit => visit.visitType === 'Initial Visit')

  if (isAppointmentsLoading) {
    return <Skeletons />
  }

  if (!upcomingVisit?.id) {
    return <Navigate replace to='/' />
  }

  return (
    <Navigate
      replace
      to={`${routes.portal.index}/${routes.portal.children.reschedule.index}/${upcomingVisit.id}`}
    />
  )
}

export const RescheduleVisit = () => {
  usePortalBreadcrumbs({ type: 'tail', text: 'Reschedule visit' })

  useLifecycle({
    onMount: () => {
      sendPageEvent('Portal Reschedule Page')
    },
  })

  const { appointmentId } = useParams<{ appointmentId: string }>()
  const { isMobile } = usePortalDims()

  const { data: appointmentInfo, isLoading: isAppointmentInfoLoading } = useQuery(
    ...patientApi.getQuery('GET /appointments/:id', {
      params: { id: appointmentId as string },
    }),
  )

  if (isAppointmentInfoLoading) {
    return <Skeletons />
  }

  if (!appointmentInfo) {
    return <Navigate replace to='/' />
  }

  if (!appointmentInfo.canReschedule) {
    return (
      <Stack>
        <TitleTwo>
          {appointmentInfo.status === 'happening-soon'
            ? "It's too close to your visit time to reschedule"
            : "This visit can't be rescheduled"}
        </TitleTwo>
        <PastVisitEntry {...appointmentInfo} />
        {appointmentInfo.status === 'happening-soon' ||
        appointmentInfo.status === 'happening-now' ? (
          <ContactSupport />
        ) : (
          <PrimaryButton
            component={Link}
            to={`${routes.portal.index}/${routes.portal.children.visits}`}
            fullWidth={isMobile}
          >
            Reschedule a different visit
          </PrimaryButton>
        )}
      </Stack>
    )
  }

  return <RescheduleCalendar appointmentInfo={appointmentInfo} />
}

const useAppointmentCalendar = ({
  id,
  visitType,
}: {
  id: number
  visitType: AppointmentTypeString
}) => {
  const { showBanner } = useBanner()
  const [date, setDate] = useState(dayjs())
  const [showPreferences, toggleShowPreferences] = useToggle()
  const [rescheduledVisit, setRescheduledVisit] = useState<AcuityAppointment>()
  const query = useQueryParams()

  const {
    data: range,
    isLoading: isRangeLoading,
    isError: isRangeError,
  } = useQuery(
    ...patientApi.getQuery('GET /appointments/:id/reschedule/calendar', {
      params: { id: String(id) },
      query: { showPreferences: showPreferences ? 'true' : 'false' },
    }),
    {
      cacheTime: toTime('10 sec').ms(),
      onSuccess: dates => {
        if (dates.length === 0) {
          event('No Reschedule Availability', {
            type: visitType,
            source: query.get('source') ?? '',
          })
          sendTrackEvent('Standby Appointment No Slots Open On Calendar Load', {
            type: visitType,
          })
        } else {
          setDate(dayjs(dates[0]!.date))
        }
      },
      onError: () => showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
    },
  )

  const {
    data: timeslots,
    isLoading: isTimeSlotsLoading,
    isError: isTimeSlotsError,
    refetch: refetchTimeSlots,
  } = useQuery(
    ...patientApi.getQuery('GET /appointments/:id/reschedule/calendar/:date', {
      params: { id: String(id), date: date.format('YYYY-MM-DD') },
      query: { showPreferences: showPreferences ? 'true' : 'false' },
    }),
    {
      cacheTime: toTime('10 sec').ms(),
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      onError: () => showBanner({ type: 'error', label: 'Something went wrong, try again later' }),
    },
  )

  const submitMutation = useMutation(patientApi.getMutation('PUT /appointments/:id/reschedule'), {
    onSuccess: appointment => {
      setRescheduledVisit(appointment)
      showBanner({
        type: 'success',
        label: `${getVisitTypeName(visitType)} rescheduled`,
      })
    },
    onError: () => {
      void refetchTimeSlots()
      showBanner({ type: 'error', label: 'Something went wrong, please try again' })
    },
  })

  return {
    timeslots,
    // Show loading state if fetching error
    isTimeSlotsLoading: isTimeSlotsLoading || isTimeSlotsError,
    refetchTimeSlots,
    range,
    // Show loading state if fetching error
    isRangeLoading: isRangeLoading || isRangeError,
    date,
    setDate,
    showPreferences,
    toggleShowPreferences,
    submitMutation,
    rescheduledVisit,
  }
}

const RescheduleCalendar = ({ appointmentInfo }: { appointmentInfo: AppointmentInfo }) => {
  const { currentUser } = useAuth()
  const { isMobile } = usePortalDims()
  const { hideBanner } = useBanner()

  const {
    timeslots,
    isTimeSlotsLoading,
    range,
    isRangeLoading,
    date,
    setDate,
    showPreferences,
    toggleShowPreferences,
    submitMutation,
    rescheduledVisit,
  } = useAppointmentCalendar({
    id: appointmentInfo.id,
    visitType: appointmentInfo.visitType,
  })

  const { getInputProps, validate, values, clearErrors, setValues } = useForm({
    initialValues: { datetime: '' },
    validate: {
      datetime: isAnySelected(timeslots?.map(slot => slot.time) ?? [], 'Required'),
    },
  })

  if (rescheduledVisit) {
    if (appointmentInfo.visitType === 'Initial Visit') {
      return <RescheduledIntakeVisitConfirmation rescheduledVisit={rescheduledVisit} />
    }

    const datetime = dayjs(rescheduledVisit.datetime)

    return (
      <Stack>
        <TitleTwo>
          {`Your ${getVisitTypeName(appointmentInfo.visitType, true)} for ${datetime.format(
            'MMM D YYYY',
          )} at ${datetime.format('h:mma z')} with ${rescheduledVisit.calendar} is scheduled`}
        </TitleTwo>
        <Text>{`If you don't attend, or if you cancel less than 24 hours before your visit, you may be charged $20.`}</Text>
      </Stack>
    )
  }

  if (isRangeLoading) {
    return <Skeletons />
  }

  if (!range?.length) {
    if (showPreferences) {
      return (
        <Stack>
          <TitleTwo>No available visits match your preferences</TitleTwo>
          <Switch
            label='Show only my preferred times'
            checked={showPreferences}
            onChange={() => toggleShowPreferences()}
          />
          <Center>
            <Group spacing='md'>
              <TertiaryButton
                component={Link}
                to={`${routes.portal.index}/${routes.portal.children.intakeVisitStandbyList}`}
                leftIcon={<BellIcon />}
              >
                Edit preferences
              </TertiaryButton>
            </Group>
          </Center>
        </Stack>
      )
    }

    if (appointmentInfo.visitType === 'Initial Visit') {
      return (
        <Stack>
          <TitleTwo>We don&apos;t have any sooner visits right now</TitleTwo>
          <Text>
            Next time you receive a notification for an available visit, try to access the portal as
            soon as possible to claim it. Be aware that other patients may also be looking for
            openings.
          </Text>
        </Stack>
      )
    }

    return (
      <Stack>
        <TitleTwo>Unfortunately, your clinician is currently booked</TitleTwo>
        <Box
          p='md'
          sx={theme => ({
            backgroundColor: theme.other.colors.background[1],
          })}
        >
          <Group spacing='md' noWrap>
            <Image src={`${Config.IMAGE_BASE_URL}text_message.png`} width={48} height={52} />
            <Text>Text us for help finding a better time.</Text>
          </Group>
        </Box>
      </Stack>
    )
  }

  const preferenceField = STANDBY_APPOINTMENT_FIELD[appointmentInfo.visitType]
  const hasPreferencesSet = Boolean(
    preferenceField &&
      currentUser?.data?.account.settings?.notifications?.standbyList[preferenceField]
        ?.availabilities,
  )

  const currentDatetime = dayjs(appointmentInfo.datetime)
  const onSubmit = () => {
    if (!validate().hasErrors) {
      const chosenSlot = timeslots?.find(slot => {
        return slot.time === values.datetime
      })

      if (chosenSlot) {
        submitMutation.mutate({
          params: { id: String(appointmentInfo.id) },
          data: {
            datetime: chosenSlot.time,
            calendarID: chosenSlot.calendarIDs[0],
          },
        })
      }
    }
  }

  return (
    <Stack>
      {appointmentInfo.visitType === 'Initial Visit' ? (
        <TitleTwo>
          {`Select a new time for your ${getVisitTypeName(appointmentInfo.visitType, true)}`}
        </TitleTwo>
      ) : (
        <TitleTwo>{`Can't make it ${currentDatetime.format('MMM D')} at ${currentDatetime.format(
          'h:mma z',
        )}? Select a new time for your next ${getVisitTypeName(
          appointmentInfo.visitType,
          true,
        )}`}</TitleTwo>
      )}
      {hasPreferencesSet && (
        <Switch
          label='Show only my preferred times'
          checked={showPreferences}
          onChange={() => toggleShowPreferences()}
        />
      )}
      <Stack spacing='lg'>
        <DailyCalendar
          value={date.toString()}
          range={range.map(slot => slot.date)}
          disabled={submitMutation.isLoading}
          onChange={value => {
            clearErrors()
            setValues({ datetime: '' })
            setDate(dayjs(value))
            hideBanner()
          }}
        >
          <DailyCalendarRadioGroup
            size={6}
            loading={isTimeSlotsLoading}
            empty={<CalendarMessage>Sorry, we&apos;re booked on this day.</CalendarMessage>}
            {...getInputProps('datetime')}
          >
            {timeslots?.map(slot => (
              <Radio
                key={slot.time}
                disabled={submitMutation.isLoading}
                value={slot.time}
                label={dayjs(slot.time).format('h:mma z')}
              />
            ))}
          </DailyCalendarRadioGroup>
        </DailyCalendar>
        <PrimaryButton fullWidth={isMobile} loading={submitMutation.isLoading} onClick={onSubmit}>
          Schedule
        </PrimaryButton>
        <ContactSupport text="Don't see a time that works? Text us at" />
      </Stack>
    </Stack>
  )
}
