import { useLifecycle } from '@shared/components'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeletons } from '../common/components'
import { auth } from '../common/firebase'
import { useQueryParams } from '../common/hooks'
import { routes } from '../common/routes'

const REDIRECT_QUERY_PARAM = 'redirect'

export const SignOutApp = () => {
  const query = useQueryParams()
  const navigate = useNavigate()

  useLifecycle({
    onMount: async () => {
      await auth.signOut()
      const redirect = query.get(REDIRECT_QUERY_PARAM)
      navigate(redirect ?? routes.welcome.index, { replace: true })
    },
  })

  return <Skeletons />
}
