import { CallOutReason, DatabaseMetadata, Employee, ISOString } from '.'

// @deprecated - moved to Luna
export type CallOutModel = {
  employeeId: Employee['oid']
  outFrom: ISOString
  outTo: ISOString
  rescheduleFrom: ISOString
  rescheduleTo: ISOString
  reason: CallOutReason
  reasonFreeText?: string
  /*
   * The call-out form may have been filled out by someone other than the clinician
   * calling out, so store the id of the user who filled out the form
   */
  calledOutById: Employee['oid']
  /*
   * We're going to process the call out using a subscriber, so we'll add a processedAt field
   * once the call out has been processed
   */
  processedAt?: ISOString
}

export type CallOut = CallOutModel & DatabaseMetadata
