import { BadgeContentType, badgesMap } from './badgeTypes'
import { MilestoneContentType, milestonesMap } from './milestoneTypes'

export const achievements: (BadgeContentType | MilestoneContentType)[] = [
  ...badgesMap,
  ...milestonesMap,
]

export const achievementValues = achievements.map(a => a.value)
export type AchievementValueType = (typeof achievementValues)[number]
