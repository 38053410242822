import { Accordion, Space, Text } from '@shared/components'
import { PrescriptionInfo as PrescriptionInfoType } from '@shared/types'
import React, { useState } from 'react'
import { Card } from '../../../common/components'
import { EmptyPrescriptionCard, PrescriptionCard } from './PrescriptionCard'
import { PrescriptionInfo, PrescriptionPill } from './PrescriptionInfo'

export const PrescriptionsCard = ({ prescriptions }: { prescriptions: PrescriptionInfoType[] }) => {
  const [selectedPrescription, setSelectedPrescriptions] = useState<string | null>(null)

  if (!prescriptions.length) {
    return <EmptyPrescriptionCard title='Active prescriptions' />
  }

  if (prescriptions.length === 1) {
    return <PrescriptionCard prescription={prescriptions[0]!} />
  }

  return (
    <Card title='Active prescriptions'>
      <Accordion value={selectedPrescription} onChange={setSelectedPrescriptions}>
        {prescriptions.map(prescription => {
          const prescriptionIdStr = prescription.prescriptionId.toString()
          return (
            <Accordion.Item value={prescriptionIdStr} key={prescription.prescriptionId}>
              <Accordion.Control>
                <Text bold transform='capitalize'>
                  {prescription.genericDrugName}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                <PrescriptionPill prescription={prescription} />
                <Space h='md' />
                <PrescriptionInfo prescription={prescription} />
              </Accordion.Panel>
            </Accordion.Item>
          )
        })}
      </Accordion>
    </Card>
  )
}
