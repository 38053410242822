// eslint-disable-next-line no-restricted-imports
import { Flex, MantineTheme, createStyles, keyframes } from '@mantine/core'
import { dayjs } from '@shared/utils'
import React, { PropsWithChildren, ReactNode } from 'react'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { SecondaryButton } from '../buttons'
import { Text } from '../typography'

type DailyCalendarProps = {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  tz?: string | undefined | null
  range: string[]
  header?: ReactNode
  step?: number
  ['test-id']?: string
  nextDayPulse?: boolean
  previousDayPulse?: boolean
}

const PULSE_ANIMATION_THREE_QUARTERS = 0.75

export const getPulseAnimation = (theme: MantineTheme) =>
  keyframes('pulse', {
    '0%': {
      boxShadow: `0 0 0 ${theme.fn.rgba(
        theme.other.colors.actions[0],
        PULSE_ANIMATION_THREE_QUARTERS,
      )}`,
    },
    '70%': {
      boxShadow: `0 0 0 ${theme.spacing.sm} ${theme.fn.rgba(theme.black, 1)}`,
    },
    '100%': { boxShadow: `0 0 0 0 ${theme.fn.rgba(theme.black, 1)}` },
  })

const useStyles = createStyles(theme => ({
  pulse: {
    // eslint-disable-next-line no-magic-numbers
    boxShadow: `0 0 0 ${theme.fn.rgba(theme.other.colors.actions[0], 0.75)}`,
    animation: `${getPulseAnimation(theme)} 2s infinite`,
  },
}))

export const DailyCalendar = ({
  value,
  range,
  children,
  onChange,
  disabled = false,
  header,
  step = 1,
  nextDayPulse = false,
  previousDayPulse = false,
  ...props
}: PropsWithChildren<DailyCalendarProps>) => {
  const { classes, cx } = useStyles()
  const currentIndex = range.findIndex(date => dayjs(date).isSame(value, 'day'))
  const showPrevious = currentIndex > step - 1
  const showNext = currentIndex !== -1 && currentIndex < range.length - step

  return (
    <Flex direction='column' gap='md' test-id={props['test-id']}>
      <Flex justify='space-between' align='center'>
        <Flex
          sx={{ visibility: showPrevious ? 'visible' : 'hidden', borderRadius: '100%' }}
          className={cx({ [classes.pulse]: previousDayPulse })}
        >
          <SecondaryButton
            size='sm'
            radius='xl'
            onClick={() => onChange(range[currentIndex - step]!)}
            disabled={disabled}
            leftIcon={<ArrowLeft />}
            test-id='button:previous-day'
          />
        </Flex>
        <Flex align='center' direction='column'>
          {header ?? (
            <>
              <Text test-id='label:date'>{dayjs(value).format('MMM D YYYY').toUpperCase()}</Text>
              <Text>{dayjs(value).isToday() ? 'Today' : dayjs(value).format('dddd')}</Text>
            </>
          )}
        </Flex>
        <Flex
          sx={{ visibility: showNext ? 'visible' : 'hidden', borderRadius: '100%' }}
          className={cx({ [classes.pulse]: nextDayPulse })}
        >
          <SecondaryButton
            size='sm'
            radius='xl'
            onClick={() => onChange(range[currentIndex + step]!)}
            disabled={disabled}
            leftIcon={<ArrowRight />}
            test-id='button:next-day'
          />
        </Flex>
      </Flex>
      {children}
    </Flex>
  )
}
