import { PrimaryButton } from '@shared/components'
import React, { forwardRef } from 'react'
import { usePortalDims } from '../../../common/hooks'

type TaskButtonProps = {
  onSubmit: () => void
  loading: boolean
  isLastStep?: boolean
  disabled?: boolean
  customButtonText?: string
}

export const TaskButton = forwardRef<HTMLDivElement, TaskButtonProps>(
  ({ onSubmit, loading, isLastStep, disabled = false, customButtonText }, ref) => {
    const { isMobile } = usePortalDims()
    const getButtonText = () => {
      if (customButtonText) {
        return customButtonText
      }
      return isLastStep ? 'Save & complete' : 'Next'
    }

    return (
      <PrimaryButton
        ref={ref}
        test-id='button:next'
        loading={loading}
        onClick={onSubmit}
        fullWidth={isMobile}
        disabled={disabled}
        mt='md'
      >
        {getButtonText()}
      </PrimaryButton>
    )
  },
)

TaskButton.displayName = 'taskButton'
