import { Grid, Skeleton, Stack } from '@shared/components'
import React from 'react'
import { usePortalDims } from '../../hooks'

export const CardMasonry = ({
  isLoading = false,
  children,
}: {
  isLoading?: boolean
  children: JSX.Element[]
}) => {
  const { isDesktop } = usePortalDims()

  if (isLoading) {
    return (
      <Grid gutter='lg'>
        <Grid.Col span={12} md={6}>
          <Stack spacing='lg'>
            <Skeleton height={200} mb='sm' radius='sm' />
            <Skeleton height={250} mb='sm' radius='sm' />
          </Stack>
        </Grid.Col>
        {isDesktop && (
          <Grid.Col span={12} md={6}>
            <Stack spacing='lg'>
              <Skeleton height={250} mb='sm' radius='sm' />
              <Skeleton height={200} mb='sm' radius='sm' />
            </Stack>
          </Grid.Col>
        )}
      </Grid>
    )
  }

  if (isDesktop && children.length > 1) {
    const left = children.filter((_, index) => index % 2 === 0)
    const right = children.filter((_, index) => index % 2 === 1)

    return (
      <Grid gutter='lg'>
        <Grid.Col span={12} md={6}>
          <Stack spacing='lg'>{left}</Stack>
        </Grid.Col>
        <Grid.Col span={12} md={6}>
          <Stack spacing='lg'>{right}</Stack>
        </Grid.Col>
      </Grid>
    )
  }

  return <Stack spacing='lg'>{children}</Stack>
}
