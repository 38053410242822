import { List, ListItem, Stack, Text } from '@shared/components'
import React from 'react'

export const TpoConsentV1 = () => {
  return (
    <Stack>
      <Text bold>
        By signing this Consent, I authorize Ophelia Medical Group FL, P.A., Ophelia Medical Group
        CA, P.C., Ophelia Medical Group NJ, P.C., and any other member of its affiliated covered
        entity (collectively, “Ophelia Medical Group”) to exchange my information with my treating
        providers, other people assisting in my treatment or coordination of care, health plans,
        third-party payers, and people helping to operate Ophelia Medical Group’s programs
        (collectively, “Recipients”) for purposes of treatment, payment, and health care operations.
      </Text>
      <Text>
        The information exchanged may include my presence in substance use disorder treatment,
        treatment plans, progress notes, physician orders, history and physical, psychiatrist
        assessments, biopsychosocial assessments, discharge planning and summary, laboratory test
        results (ex. urine drug screens), medications, and other information related to my care and
        treatment at Ophelia Medical Group.
      </Text>

      <Text bold>Statement of Consent</Text>
      <Text>I understand that:</Text>

      <List spacing='md'>
        <ListItem>
          <Text>
            Information exchanged pursuant to this Consent may be subject to redisclosure by the
            Recipient of such information and may no longer be protected by 42 C.F.R. Part 2, the
            federal law protecting the confidentiality of substance use disorder records at Ophelia
            Medical Group. If the Recipient of my information is a covered entity or business
            associate that receives it for purposes of treatment, payment, or health care
            operations, my information may be redisclosed in accordance with the Health Insurance
            Portability and Accountability Act and its implementing regulations (HIPAA), except for
            uses and disclosures for civil, criminal, administrative, and legislative proceedings
            against me.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            I may revoke this Consent in writing at any time by mailing Ophelia Medical Group, Attn:
            Privacy Officer, 228 Park Ave S, Suite 15314, New York, NY 10003, by telephone at (215)
            585-2144, or by email at support@ophelia.com, except to the extent that action has
            already been taken in reliance on it.
          </Text>
        </ListItem>
        <ListItem>
          <Text>
            If I refuse to sign this Consent, Ophelia Medical Group may determine it cannot provide
            me with ongoing treatment.
          </Text>
        </ListItem>
        <ListItem>
          <Text bold>This Consent does not expire and remains in effect until I revoke it.</Text>
        </ListItem>
      </List>
    </Stack>
  )
}
