import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PhoneIcon,
  PrimaryButton,
  SecondaryButton,
  Stack,
  TertiaryButton,
  Text,
  TitleTwo,
  emojiMap,
  useLifecycle,
} from '@shared/components'
import { states } from '@shared/types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SignupProps } from '.'
import { Config } from '../../../common/config'
import * as FullStory from '../../../common/fullstory'
import { useScrollIntoViewOnLayoutEffect } from '../../../common/hooks'
import { routes } from '../../../common/routes'
import { sendPageEvent, sendTrackEvent } from '../../../common/rudderstack'
import { CallUsNowNextStep } from './CallUsNowNextStep'

export const CallUsNow = ({
  form,
  setCurrentStep,
  signInWithPhoneNumber,
  setDisableCallUsNow,
}: SignupProps & { setDisableCallUsNow: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const [callStarted, setCallStarted] = useState(false)
  const { targetRef } = useScrollIntoViewOnLayoutEffect({ dependencyList: [form.values.state] })

  const selectedStateIndex = states.findIndex(state => state.abbr === form.values.state)

  // Dial an extension with the selected state encoded into the second and third digit
  const selectedStateExtension =
    selectedStateIndex === -1 ? '' : String(selectedStateIndex).padStart(2, '0')
  const extension = '9'
  const phoneUri = `tel:${Config.OPHELIA_PHONE_NUMBER}p${extension}p${selectedStateExtension}`

  const onCall = () => {
    sendTrackEvent('Call Us Now Pressed', { state: form.values.state })
    FullStory.event('Call us now CTA pressed', { state: form.values.state })
    setCallStarted(true)
  }

  useLifecycle({
    onMount: () => {
      sendPageEvent('Call us now')
      FullStory.event('Call us now visible')
    },
  })

  if (callStarted) {
    return (
      <CallUsNowNextStep
        form={form}
        setCurrentStep={setCurrentStep}
        signInWithPhoneNumber={signInWithPhoneNumber}
        onBack={() => setCallStarted(false)}
      />
    )
  }

  return (
    <Stack ref={targetRef}>
      <TitleTwo>{`${emojiMap['party popper']} We serve your state, now let's get your first visit with a clinician scheduled`}</TitleTwo>
      <Text>
        Ophelia is an online opioid use disorder treatment provider. Take 5 minutes now to chat with
        someone from our team and get your questions answered. At the end of the call, they&apos;ll
        schedule your first visit with a clinician.
      </Text>
      <Stack mt='md' spacing='lg'>
        <Stack spacing='sm'>
          <PrimaryButton
            test-id='button:call-us@mobile'
            fullWidth
            leftIcon={<PhoneIcon />}
            onClick={onCall}
            component='a'
            href={phoneUri}
          >
            Call us now
          </PrimaryButton>
        </Stack>
        <SecondaryButton
          test-id='button:schedule-free-intro@mobile'
          fullWidth
          rightIcon={<ArrowRightIcon />}
          onClick={() => setDisableCallUsNow(true)}
        >
          Schedule a call for later
        </SecondaryButton>
        <TertiaryButton
          test-id='button:back@mobile'
          fullWidth
          leftIcon={<ArrowLeftIcon />}
          component={Link}
          to={routes.welcome.index}
        >
          Back
        </TertiaryButton>
      </Stack>
    </Stack>
  )
}
