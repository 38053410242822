import { useForm } from '@mantine/form'
import {
  Checkbox,
  CheckboxGroup,
  PrimaryButton,
  Stack,
  Text,
  TextInput,
  isAllSelected,
  validateWith,
} from '@shared/components'
import { name } from '@shared/utils'
import React from 'react'
import { isAtleastOneWord, isRequired } from '../../../common/forms'
import { useAuth, usePortalDims } from '../../../common/hooks'
import { TpoConsentV1 } from '../../../documents/TpoConsentV1'
import { TaskStepProps } from './taskStepProps'

const AGREE_TO_CONSENT = 'agree-to-consent'
export const TpoConsentStep = ({ submit, saving, initialValues }: TaskStepProps<'tpo-consent'>) => {
  const { isMobile } = usePortalDims()

  const form = useForm({
    initialValues,
    validate: {
      signature: validateWith(isRequired, isAtleastOneWord),
      checks: isAllSelected([AGREE_TO_CONSENT], 'Required'),
    },
  })
  const { currentUser } = useAuth()

  const onSubmit = () => {
    if (!form.validate().hasErrors) {
      submit('', form.values)
    }
  }
  return (
    <Stack test-id='step:tpo-consent' spacing='lg'>
      <Stack>
        <Text>
          Patient Name:{' '}
          {name({
            first: currentUser?.data?.personalData?.firstName,
            last: currentUser?.data?.personalData?.lastName,
          }).full()}
        </Text>
        <Text>DOB: {currentUser?.data?.personalData.birthday}</Text>
        <TpoConsentV1 />
      </Stack>
      <Stack>
        <CheckboxGroup {...form.getInputProps('checks')}>
          <Checkbox
            test-id='input:agree-to-consent'
            label='I have read, understand, and agree to this consent to Exchange Information for Treatment, Payment, and Healthcare Operations Purposes.'
            value={AGREE_TO_CONSENT}
          />
        </CheckboxGroup>
        <TextInput
          test-id='input:signature'
          label='Signed by'
          placeholder='Type your full legal name'
          {...form.getInputProps('signature')}
          onFocus={() => {
            if (!form.values.signature) {
              const patientName = name({
                first: currentUser?.data?.personalData.firstName,
                last: currentUser?.data?.personalData.lastName,
              }).full()
              form.setFieldValue('signature', patientName)
            }
          }}
        />
        <PrimaryButton
          test-id='button:sign'
          fullWidth={isMobile}
          onClick={onSubmit}
          loading={saving}
        >
          Sign and agree
        </PrimaryButton>
      </Stack>
    </Stack>
  )
}
