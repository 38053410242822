import {
  GetFileReadResponse,
  GetFileWriteResponse,
  GetPatientFilesQueryParams,
  PatientFile,
} from '@shared/types'
import { BaseApi } from '@shared/utils'

export class FilesApi extends BaseApi {
  getFiles = (params: GetPatientFilesQueryParams) => {
    return this.json<PatientFile[]>({ method: 'GET', url: '/files', params })
  }

  getFileWrite = () => {
    return this.json<GetFileWriteResponse>({
      method: 'GET',
      url: '/file/write',
    })
  }

  getFileRead = (fileId: string) => {
    return this.json<GetFileReadResponse>({
      method: 'GET',
      url: `/file/read/${fileId}`,
    })
  }

  deleteFile = (fileId: string) => {
    return this.json<GetFileReadResponse>({
      method: 'PUT',
      url: `/file/delete/${fileId}`,
    })
  }
}
