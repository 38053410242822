import { Group, Text } from '@shared/components'
import React, { PropsWithChildren } from 'react'
import { usePortalDims } from '../hooks'

type FormHeaderProps = {
  title: string
}

export const FormHeader = ({ title, children }: PropsWithChildren<FormHeaderProps>) => {
  const { isMobile } = usePortalDims()

  return (
    <Group spacing='lg' position={isMobile ? 'apart' : 'left'}>
      <Text bold transform='uppercase'>
        {title}
      </Text>
      {children}
    </Group>
  )
}
