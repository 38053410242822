import { MyLunaApi, OpheliaClient, PatientApi } from '@shared/types'
import {
  BaseApiConfig,
  LOCAL_TIMEZONE_HEADER,
  addAxiosInterceptors,
  dayjs,
  toTime,
} from '@shared/utils'
import axios from 'axios'
import { Config } from '../config'
import { auth } from '../firebase'
import * as FullStory from '../fullstory'
import { logFullstoryError } from '../logger'
import { AppointmentsApi } from './appointments'
import { AuthApi } from './auth'
import { CurrentUserApi } from './currentUser'
import { FilesApi } from './files'
import { InsuranceApi } from './insurance'
import { ReferralsApi } from './referrals'
import { ReleaseOfInformationApi } from './releaseOfInformation'
import { StorageApi } from './storage'

const apiAxiosInstance = axios.create({
  baseURL: Config.API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: toTime('1 min').ms(),
})

addAxiosInterceptors(apiAxiosInstance, {
  onResponseRejected: logFullstoryError,
  responseRejection: { omit: ['request'] },
  headers: {
    [LOCAL_TIMEZONE_HEADER]: dayjs.tz.guess(),
    'fullstory-session-url': () => FullStory.getCurrentSessionURL(true),
    'client-location-pathname': () => window.location.pathname,
  },
})

const apiConfig: BaseApiConfig = {
  axiosInstance: apiAxiosInstance,
}
export const authApi = new AuthApi({ auth, apiConfig })
export const currentUserApi = new CurrentUserApi(apiConfig)
export const filesApi = new FilesApi(apiConfig)
export const appointmentsApi = new AppointmentsApi(apiConfig)
export const insuranceApi = new InsuranceApi(apiConfig)
export const storageApi = new StorageApi(apiConfig)
export const referralsApi = new ReferralsApi(apiConfig)
export const releaseOfInformationApi = new ReleaseOfInformationApi(apiConfig)

// Set up the Ophelia Type Contract for the PatientApi
export const patientApi = new OpheliaClient<PatientApi>({
  axiosInstance: apiAxiosInstance,
})

// Export any re-usable queries here
export const getFutureAppointments = () =>
  patientApi.getQuery('GET /appointments', {
    query: {
      start: dayjs().toISOString(),
      canceled: 'no',
    },
  })

export const getPastAppointments = () =>
  patientApi.getQuery('GET /appointments', {
    query: {
      end: dayjs().toISOString(),
    },
  })

/**
 * Set up an axios client to make requests to the my-luna-api.
 * This is a separate client from the patientApi because it has a different base URL.
 * The long term goal is to move all requests to this client.
 */

const myAxiosInstance = axios.create({
  baseURL: Config.MY_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: toTime('1 min').ms(),
})

addAxiosInterceptors(myAxiosInstance, {
  onResponseRejected: logFullstoryError,
  responseRejection: { omit: ['request'] },
  headers: {
    [LOCAL_TIMEZONE_HEADER]: dayjs.tz.guess(),
    'fullstory-session-url': () => FullStory.getCurrentSessionURL(true),
    'client-location-pathname': () => window.location.pathname,
  },
})

export const myAuthApi = new AuthApi({
  auth,
  apiConfig: {
    axiosInstance: myAxiosInstance,
  },
})

// Set up the Ophelia Type Contract for the PatientApi
export const myApi = new OpheliaClient<MyLunaApi>({
  axiosInstance: myAxiosInstance,
})
