import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { routes } from '../common/routes'
import * as Pages from './pages'

export const NotReadyApp = () => {
  return (
    <Routes>
      <Route index element={<Pages.NotReadyFlow />} />
      <Route path={`${routes.options.children.workflowPrompt}`} element={<Pages.NotReadyFlow />} />
      <Route path='*' element={<Navigate replace to='/' />} />
    </Routes>
  )
}
